
.mobile-show,
.mobile-portrait-show,
.mobile-landscape-show,
.mobile-show-inline,
.mobile-show-inline-block,
.mobile-show-list-item,
.device-xlg-show {
	display: none;
}

.mobile-only-show,
.mobile-only-show-inline,
.mobile-only-show-inline-block,
.mobile-only-show-list-item,

.sm-col-only-show,
.sm-col-only-show-inline,
.sm-col-only-show-inline-block,
.md-col-only-show,
.md-col-only-show-inline,
.md-col-only-show-inline-block,
.lg-col-only-show,
.lg-col-only-show-inline,
.lg-col-only-show-inline-block {
	display: none;
}

.main-min-height-minus-header-and-footer() {
	#main {
		.min-height-100vh(428px); // 428px is arbitrary chosen to have a minimum page height on desktop with the header and footer at the bottom as much as possible without unwanted side scrollbar
	}
}
.desktop-main-min-height-minus-header-and-footer() {
	@media (min-width: (@screen-sm-min)) {
		.main-min-height-minus-header-and-footer();
	}
}
.any-col-config(@rule_set) {
	&,
	.nb-thumbs-cols-xlg-4 &,
	.nb-thumbs-cols-xlg-5 &,
	.nb-thumbs-cols-xlg-6 &,
	
	.nb-thumbs-cols-lg-3 &,
	.nb-thumbs-cols-lg-4 &,
	.nb-thumbs-cols-lg-5 &,
	
	.nb-thumbs-cols-sm-2 &,
	.nb-thumbs-cols-sm-3 &,
	.nb-thumbs-cols-sm-4 &,
	
	.nb-thumbs-cols-xs-1 &,
	.nb-thumbs-cols-xs-2 &,
	.nb-thumbs-cols-xs-3 &{
		@rule_set();
	}
}


/* ==== min 480px = max 767px ==== */
@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
	.sm-col-only-hide { display: none; }
	.sm-col-only-show { display: block; }
	.sm-col-only-show-inline { display: inline; }
	.sm-col-only-show-inline-block { display: inline-block; }
	
	.mozaique {
		&.cust-nb-cols {
			.thumb-block {
				&,
				.nb-thumbs-cols-sm-3 & {
					.thumb-block-size-mixin(3);
					.thumb-under-2-lines();
					
					.thumb-under {
						p {
							font-size: 13px;
						}
					}
				}
				
				.nb-thumbs-cols-sm-2 & {
					.thumb-block-size-mixin(2);
					
					.thumb-under {
						p {
							font-size: 16px;
						}
					}
				}
				
				.nb-thumbs-cols-sm-4 & {
					.thumb-block-size-mixin(4);
				}
				
				.nb-thumbs-cols-sm-3 &,
				.nb-thumbs-cols-sm-4 & {
					.sprfluous {
						display: none;
						
						& + .icf-ticket-red {
							margin-left: 4px;
						}
					}
				}
				
				.max-line-1 & {
					&:nth-child(n+4) {
						display: none;
					}
					
					.nb-thumbs-cols-sm-2 &,
					.nb-thumbs-cols-sm-4 & {
						&:nth-child(4) {
							display: block;
						}
					}
				}
				
				.max-line-2 & {
					&:nth-child(n+7) {
						display: none;
					}
					
					.nb-thumbs-cols-sm-4 & {
						&:nth-child(7),
						&:nth-child(8) {
							display: block;
						}
					}
				}

				.nb-thumbs-cols-sm-100 & {
					.nb-thumbs-cols-100(1/2.6, 18, 18);
					align-items: center;
					display: flex;
				}
				.nb-thumbs-cols-sm-100 &.thumb-nat-exo-ad {
					display: block;
					margin: 0;
					width: unit((100/2.6), %);
				}
			}
		}
	}
	
	#related-videos,
	#profile-tabs .activity-event.premium-preview,
	#related-playlists {
		.mozaique {
			.thumb-block {
				.thumb-block-size-mixin(3);
				.thumb-under-2-lines();
				
				.thumb-under {
					p {
						font-size: 13px;
					}
				}
				
				.sprfluous {
					display: none;
					
					& + .icf-ticket-red {
						margin-left: 4px;
					}
				}
			}
		}
		.thumb-block {
			&.after-12,
			&.after-15 {
				display: none;
			}
		}
		&.with-ad {
			&.enlarged-player {
				.thumb-block {
					&.after-10 {
						display: none;
					}
				}
			}
			&.with-footer-ad {
				.thumb-block:not(.thumb-nat-exo-ad) {
					.thumb-under {
						min-height: 0;
					}
				}
				.thumb-block {
					&.with-add-nth-child-3-plus-1{
						clear: left;
					}
					&.video-ad {
						width: 100% / 3 * 2;
					}
					&.after-12 {
						display: inline-block;
					}
					&.after-13 {
						display: none;
					}
				}
			}
		}
	}
}

/* ==== min 480px = max 650px === */
@media (min-width: @screen-xs-min) and (max-width: 650px) {
	.mozaique {
		&.cust-nb-cols {
			.thumb-block {
				html:not(.nb-thumbs-cols-sm-2):not(.nb-thumbs-cols-sm-4) & {
					.sprfluous {
						display: none;
						
						& + .icf-ticket-red {
							margin-left: 4px;
						}
					}
				}
			}
		}
	}
}

/*!* ==== min 561px ==== *!
@media (min-width: 561px) {
	.social-login-mobile-show {
		display: none;
	}
	.social-login-mobile-hide {
		display: block;
	}
}*/

/* ==== min 768px = Just "desktop" ==== */
@media (min-width: @screen-sm-min) {
	#profile-title {
		&.has-banner {
			.profile-pic {
				.premium-page.not-premium-logged & {
					display: none;
				}
			}
			.right-wrapper {
				position: absolute;
				right: 8px;
				top: 4px;
				
				#profile-action {
					background: fadeOut(#000, 40%);
					border-radius: 4px;
					color: #fff;
				}
			}
		}
		h4 {
			.content-filters {
				& > *:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
	}
}

/* ==== min 768px = max 991px ==== */
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.md-col-only-hide { display: none; }
	.md-col-only-show { display: block; }
	.md-col-only-show-inline { display: inline; }
	.md-col-only-show-inline-block { display: inline-block; }

	.related-content {
		.related-content__btns {
			&.mobile-hide {
				width: 25%;
			}
		}
	}

	#related-videos,
	#profile-tabs .activity-event.premium-preview,
	#related-playlists {
		.thumb-block {
			.thumb-block-size-mixin(4);
			
			.sprfluous {
				display: inline;
			}
			
			&.after-12,
			&.after-15 {
				display: none;
			}
		}
		
		&.with-ad {
			&.enlarged-player {
				.thumb-block {
					&.after-12 {
						display: inline-block;
					}
					
					&.after-14 {
						display: none;
					}
				}
			}
			
			&.with-footer-ad {
				.thumb-block:not(.thumb-nat-exo-ad) {
					.thumb-under {
						min-height: 0;
					}
				}
				
				.thumb-block {
					&.with-add-nth-child-4-plus-1 {
						clear: left;
					}
					
					&.video-ad {
						width: 50%;
					}
					
					&.after-10,
					&.after-12 {
						display: inline-block;
					}
					
					&.after-14 {
						display: none;
					}
				}
			}
		}
	}

	#related-videos {
		.show-more {
			&.multiple-related {
				width: 75%;
			}
		}
	}
	
	.mozaique {
		&.cust-nb-cols {
			.thumb-block {
				.nb-thumbs-cols-lg-5 & {
					.sprfluous {
						display: none;
						
						& + .icf-ticket-red {
							margin-left: 4px;
						}
					}
				}
			}
		}
	}
	
	#create-account-frame {
		.info-pannel {
			h3 {
				font-size: 18px;
				line-height: 1.4;
				margin-bottom: 10px;
			}
			.advantage {
				margin: 5px 0;
				width: auto;
				float: none;
				p {
					font-size: 13px;
				}
			}
			.btn-default {
				margin-top: 10px;
				padding: 7px;
				font-size: 14px;
				white-space: normal;
			}
		}
		#signup-form_step2_profilepic {
			.step2-profile-pic {
				margin: 0 auto 10px;
				width: 80px;
				float: none;
			}
		}
	}
}

/* ==== min 768px = max 1439px ==== */
@media (min-width: @screen-sm-min) and (max-width: @screen-lg-max) {
	.mozaique {
		&.cust-nb-cols {
			.thumb-block {
				.nb-thumbs-cols-lg-3 & {
					.thumb-block-size-mixin(3);
					
					.thumb-under {
						p {
							font-size: 14px;
						}
					}
					
					.thumb-under-1-line();
				}
				
				.nb-thumbs-cols-lg-4 & {
					.thumb-block-size-mixin(4);
				}
				
				.nb-thumbs-cols-lg-5 & {
					.thumb-block-size-mixin(5);
					
					.thumb-under {
						p {
							font-size: 12px;
						}
					}
				}
				
				.max-line-1 & {
					&:nth-child(n+5) {
						display: none;
					}
					
					.nb-thumbs-cols-lg-3 & {
						&:nth-child(n+4) {
							display: none;
						}
					}
					
					.nb-thumbs-cols-lg-5 & {
						&:nth-child(5) {
							display: block;
						}
					}
				}
				
				.max-line-2 & {
					&:nth-child(n+9) {
						display: none;
					}
					
					.nb-thumbs-cols-lg-3 & {
						&:nth-child(n+7) {
							display: none;
						}
					}
					
					.nb-thumbs-cols-lg-5 & {
						&:nth-child(9),
						&:nth-child(10) {
							display: block;
						}
					}
				}
			}
		}
	}
}

/* ==== min 768px = max 850px ==== */
@media (min-width: @screen-sm-min) and (max-width: 850px) {
	.mozaique {
		&.cust-nb-cols {
			.thumb-block {
				html:not(.nb-thumbs-cols-lg-3):not(.nb-thumbs-cols-lg-5) & {
					.sprfluous {
						display: none;
						
						& + .icf-ticket-red {
							margin-left: 4px;
						}
					}
				}
			}
		}
	}
}

/* ==== min 930px = Screen over the standard ==== */
@media (min-width: @screen-legacy) {
	#page.video-page {
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				height: 516px;
				padding-bottom: 0;
				embed,
				.embed-responsive-item {
					height: 516px;
					position: static;
				}
			}
		}
		.player-enlarged {
			#video-player-bg {
				#html5video.embed-responsive,
				#player {
					height: auto;
					padding-bottom: 40%;
					@media (min-height: 680px) {
						padding-bottom: 45%;
					}
					@media (min-height: 760px) {
						padding-bottom: 50%;
					}
					@media (min-height: 840px) {
						padding-bottom: 55%;
					}
					embed,
					.embed-responsive-item {
						height: 100%;
						position: absolute;
					}
				}
			}
		}
	}
	
	.activity-event {
		.act-achievement {
			font-size: 60px;
		}
	}
}

/* ==== min 992px = MD screens ==== */
@media (min-width: @screen-md-min) {
	.lg-col-only-hide { display: none; }
	.lg-col-only-show { display: block; }
	.lg-col-only-show-inline { display: inline; }
	.lg-col-only-show-inline-block { display: inline-block; }
	
	#profile-search-form {
		&.display-compact {
			.form-row-keywords {
				.control-label {
					width: 11.1111%;
				}
				.col-md-8 {
					width: 100% - 11.1111%;
				}
			}
		}
	}
	
	#video-tabs.disp-tabComments {
		#tabComments {
			float: left;
			width: 40%;
		}
		& ~ #video-sponsor-links,
		& ~ #related-videos,
		& ~ .related-content {
			float: left;
			width: 60%;
		}
		& ~ #related-videos,
		& ~ .related-content {
			#related-videos,
			#related-playlists {
				.mozaique {
					.thumb-block {
						.thumb-block-size-mixin(3);
					}
				}
			}
		}
	}
	
	.old-ie #video-tabs.disp-tabComments,
	.legacy-ie #video-tabs.disp-tabComments,
	.ie #video-tabs.disp-tabComments,
	#content.player-enlarged ~ #video-tabs.disp-tabComments,
	#video-tabs.disp-tabComments.nb-comments-0 {
		#tabComments,
		& ~ #video-sponsor-links,
		& ~ #related-videos,
		& ~ .related-content {
			float: none;
			width: 100%;
		}
		& ~ .related-content {
			#related-videos,
			#related-playlists {
				.mozaique {
					.thumb-block {
						.thumb-block-size-mixin(5);
					}
				}
			}
		}
	}
	
	.ranks {
		display: flex;
		
		.ranking {
			padding-left: 20px;
			
			&:first-child {
				padding-left: 0;
			}
		}
	}
	
	.x-overlay,
	#page.section-manage {
		&:not(.with-premium-info-frame) {
			#signin-popup-form {
				& > .row {
					width: 95%;
				}
			}
			
		}
	}
	
	#profile-tabs {
		.xv-slim-tabs {
			& > li + li {
				margin-left: 30px;
			}
		}
	}
}

/* ==== min 992px = max 1439px = MD screens ==== */
@media (min-width: @screen-md-min) and (max-width: @screen-lg-max) {
	.related-content {
		.related-content__btns {
			&.mobile-hide {
				width: 20%;
			}
		}
	}

	#related-videos,
	#profile-tabs .activity-event.premium-preview,
	#related-playlists {
		.thumb-block {
			&.after-15 {
				display: none;
			}
			
			.thumb-block-size-mixin(5);
			
			.sprfluous {
				display: inline;
			}
		}
		
		&.with-ad {
			&.enlarged-player {
				.thumb-block {
					&.after-12 {
						display: inline-block;
					}
					&.after-13,
					&.after-14 {
						display: none;
					}
				}
			}
			&.with-footer-ad {
				.thumb-block:not(.thumb-nat-exo-ad) {
					.thumb-under {
						min-height: 0;
					}
				}
				.thumb-block {
					&.with-add-nth-child-5-plus-1 {
						clear: left;
					}
					&.after-10,
					&.after-12 {
						display: inline-block;
					}
					&.after-13,
					&.after-14 {
						display: none;
					}
					&.video-ad {
						width: 100% / 5 * 2;
					}
				}
			}
		}
	}

	#related-videos {
		.show-more {
			&.multiple-related {
				width: 80%;
			}
		}
	}
}

/* ==== min 1024px = Very large screens ==== */
@media (min-width: 1024px) {
	#header .white-stripe h2 {
		font-size: 18px;
		.alt {
			font-size: 15px;
		}
	}
	
	#tags li,
	.tags-list li {
		width: 20%;
	}
	
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.thumb-block-size-mixin(5);
			}
		}
		&.thumbs-small {
			.thumb-block {
				.thumb-block-size-mixin(10);
			}
		}
	}
	
	#profile-title {
		&.has-banner-slider {
			.embed-responsive {
				height: 200px;
				
				.banner-slider-container {
					height: 200px;
				}
				
				&.banner-slider {
					height: 200px;
					
					.banner-slide:not(.banner-personal) {
						&.ticket-red {
							.ticket-in {
								height: 200px;
							}
							
							.ticket-back .icon-f {
								font-size: 202px;
							}
						}
					}
				}
			}
			
			& > .top-right {
				top: 207px;
			}
		}
	}
}

/* ==== min 1120px = Intermediate large screens ==== */
@media (min-width: 1120px) {
	#header .white-stripe h2 {
		font-size: 20px;
		.alt {
			font-size: 18px;
		}
	}
	
	#list-page li {
		width: 25%;
	}
	
	.mozaique {
		&.thumbs-small {
			.thumb-block {
				.thumb-block-size-mixin(12);
			}
		}
	}
	
	.language-switcher-popup {
		.x-popup-content {
			a {
				font-size: 12px;
				padding-bottom: 3px;
				padding-top: 3px;
			}
		}
	}
	
	.x-overlay,
	#page.section-manage {
		&:not(.with-premium-info-frame) {
			#signin-popup-form {
				& > .row {
					width: 90%;
				}
			}
		}
	}
}

/* ==== min 1280px = Max screen size ==== */
@media (min-width: @screen-max) {
	#profile-title {
		margin: 0;
	}
	
	.users-videos .tab-description {
		padding: 0;
	}
}

/* ==== min 1280px = max 1439px = Max screen size ==== */
@media (min-width: @screen-max) and (max-width: @screen-lg-max) {
	#page.video-page .player-enlarged {
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				@media (min-height: 680px) {
					padding-bottom: 45%;
				}
				@media (min-height: 760px) {
					padding-bottom: 50%;
				}
				@media (min-height: 840px) {
					padding-bottom: 55%;
				}
			}
		}
	}
	
	#profile-tabs {
		.sticky-tabs {
			&.is-sticky {
				left: 50%;
				padding-left: 0;
				padding-right: 0;
				transform: translateX(-50%);
				width: calc(~"100% - (@{body-out-padding} * 2)");
			}
		}
	}
}

/* ==== min 1440px ==== */
@media (min-width: @screen-xlg-min) {
	
	body {
		padding-left: @screen-xlg-side-padding;
		padding-top: @body-out-padding;
		padding-right: @screen-xlg-side-padding;
		#browser-too-old {
			margin-top: -@body-out-padding;
		}
	}
	
	#page {
		max-width: @screen-xlg-max;
	}
	
	#chat-window,
	#chat-window.zoom1,
	#chat-window.zoom2 {
		right: 20px;
	}
	
	#x-x-messages-btn {
		right: 20px;
		
		&.chat-window-side {
			right: 60px;
		}
	}
	
	#x-messages-menu {
		right: 52px;
		
		&.chat-window-side {
			right: 92px;
		}
	}
	
	#x-messages-menu-settings {
		right: @body-out-padding;
	}
	#x-messages {
		padding: 0 @body-out-padding 0 10px;
		width: 410px;
	}
	
	.device-xlg-hide {
		display: none;
	}
	.device-xlg-show {
		display: block;
	}
	#page.video-page {
		.player-enlarged {
			#video-player-bg {
				#html5video.embed-responsive,
				#player {
					padding-bottom: 30%;
					@media (min-height: 800px) {
						padding-bottom: 35%;
					}
					@media (min-height: 950px) {
						padding-bottom: 42%;
					}
					@media (min-height: 1000px) {
						padding-bottom: 45%;
					}
				}
			}
		}
	}
	
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.thumb-block-size-mixin(6);
			}
		}
		&.cust-nb-cols {
			.thumb-block {
				&,
				.nb-thumbs-cols-xlg-5 & {
					.thumb-under-2-lines();
					.thumb-block-size-mixin(5);
					
					.thumb-under {
						p {
							font-size: 14px;
						}
					}
				}
				
				.nb-thumbs-cols-xlg-4 & {
					.thumb-block-size-mixin(4);
				}
				
				.nb-thumbs-cols-xlg-6 & {
					.thumb-block-size-mixin(6);
					
					.thumb-under {
						p {
							font-size: 13px;
						}
					}
				}
				
				.max-line-1 & {
					&:nth-child(n+6) {
						display: none;
					}
					
					.nb-thumbs-cols-xlg-4 & {
						&:nth-child(n+5) {
							display: none;
						}
					}
					
					.nb-thumbs-cols-xlg-6 & {
						&:nth-child(6) {
							display: block;
						}
					}
				}
				
				.max-line-2 & {
					&:nth-child(n+11) {
						display: none;
					}
					
					.nb-thumbs-cols-xlg-4 & {
						&:nth-child(n+9) {
							display: none;
						}
					}
					
					.nb-thumbs-cols-xlg-6 & {
						&:nth-child(11),
						&:nth-child(12) {
							display: block;
						}
					}
				}
			}
		}
	}
	
	#related-videos,
	#profile-tabs .activity-event.premium-preview,
	#related-playlists {
		.mozaique {
			.thumb-block {
				.thumb-block-size-mixin(6);
				
				.thumb-under {
					p {
						font-size: 13px;
					}
				}
				
				.sprfluous {
					display: inline;
					
					& + .icf-ticket-red {
						margin-left: 4px;
					}
				}
			}
		}
		.thumb-block {
			&.after-12,
			&.after-15 {
				display: none;
			}
		}
		&.with-ad {
			&.enlarged-player {
				.thumb-block {
					&.after-12,
					&.after-15 {
						display: block;
					}
					&.after-16 {
						display: none;
					}
				}
			}
			&.with-footer-ad {
				.thumb-block:not(.thumb-nat-exo-ad) {
					.thumb-under {
						min-height: 0;
					}
				}
				.thumb-block {
					&.with-add-nth-child-6-plus-1{
						clear: left;
					}
					&.video-ad {
						width: 100% / 6 * 2;
					}
					&.after-12,
					&.after-15 {
						display: inline-block;
					}
					&.after-16 {
						display: none;
					}
				}
			}
		}
	}
	
	.old-ie #video-tabs.disp-tabComments,
	.legacy-ie #video-tabs.disp-tabComments,
	.ie #video-tabs.disp-tabComments,
	#content.player-enlarged ~ #video-tabs.disp-tabComments,
	#video-tabs.disp-tabComments.nb-comments-0 {
		& ~ #related-videos,
		& ~ .related-content {
			#related-videos,
			#related-playlists {
				.mozaique {
					.thumb-block {
						.thumb-block-size-mixin(6);
					}
				}
			}
		}
	}
	
	#profile-title {
		&.has-banner {
			.embed-responsive {
				background-color: #000;
				height: 200px;
				padding-bottom: 0;
				text-align: center;
				
				.embed-responsive-item {
					display: inline-block;
					height: 200px;
					position: static;
					width: auto;
				}
			}
		}
		&.has-banner-slider {
			.embed-responsive {
				text-align: center;
				
				.personal-slider {
					display: inline-block;
					height: 200px;
					position: static;
					width: auto;
				}
			}
		}
		
		//.embed-responsive {
		//	.embed-responsive-item {
		//		&.access-red {
		//			.btn {
		//				font-size: 52px;
		//			}
		//		}
		//	}
		//}
	}
}

/* ==== min 1440px = max 2080px ==== */
@media (min-width: @screen-xlg-min) and (max-width: (@screen-xlg-max + (@screen-xlg-side-padding * 2))) {
	#profile-tabs {
		.sticky-tabs {
			&.is-sticky {
				max-width: 100%;
				padding-left: 80px;
				padding-right: 80px;
			}
		}
	}
}

/* ==== min 1900px ==== */
@media (min-width: 1900px) {
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.thumb-block-size-mixin(7);
				
				.thumb-under {
					p {
						font-size: 14px;
					}
				}
			}
		}
	}
	
	#video-tabs.disp-tabComments {
		#tabComments {
			width: 33.33%
		}
		& ~ #video-sponsor-links {
			width: 66.67%;
		}
		& ~ #related-videos,
		& ~ .related-content {
			width: 66.67%;

			#related-videos {
				width: 100%;
			}

			.mozaique {
				.thumb-block {
					.thumb-block-size-mixin(4);
				}
			}
		}
	}
}

/* ==== min 2081px ==== */
@media (min-width: (@screen-xxlg-min + (@screen-xlg-side-padding * 2))) {
	#profile-tabs {
		.sticky-tabs {
			&.is-sticky {
				left: 50%;
				max-width: @screen-xlg-max;
				padding-left: 0;
				padding-right: 0;
				transform: translateX(-50%);
			}
		}
	}
}

/* ==== min 2121px ==== */
@media (min-width: (@screen-xxlg-min + (@screen-xlg-side-padding * 4) + 40)) {
	body {
		#chat-window,
		#chat-window.zoom1,
		#chat-window.zoom2 {
			margin-right: -(@screen-xlg-max / 2 + @screen-xlg-side-padding * 2);
			right: 50%;
			min-width: inherit;
		}
		
		#x-x-messages-btn,
		#x-messages-menu-settings {
			margin-right: -(@screen-xlg-max / 2 + @screen-xlg-side-padding * 2);
			right: 50%;
			
			&.chat-window-side {
				margin-right: -(@screen-xlg-max / 2 + @screen-xlg-side-padding * 2 - 40);
				right: 50%;
			}
		}
		
		#x-messages {
			margin-right: -(@screen-xlg-max / 2 + @screen-xlg-side-padding * 2 + @body-out-padding);
			right: 50%;
		}
		
		#x-messages-menu {
			margin-right: -(@screen-xlg-max / 2 + @screen-xlg-side-padding * 2 - 32);
			right: 50%;
			
			&.chat-window-side {
				margin-right: -(@screen-xlg-max / 2 + @screen-xlg-side-padding * 2 - 32 - 40);
				right: 50%;
			}
		}
	}
}

/* ==== max 1199px ==== */
@media (max-width: @screen-md-max) {
	.video-page #tabShareAndEmbed .share-toolbox {
		margin: 0 auto;
	}
	
	@media @media-ratio-horizontal {
		body.body--with-old-header .nav-elem .main-categories {
			max-height: 62px;
		}
	}
}

/* ==== max 999px ==== */
@media (max-width: 999px) {
	body.body--with-old-header .nav-elem .main-categories {
		max-height: 124px;
	}
	
	@media @media-ratio-horizontal {
		body.body--with-old-header .nav-elem .main-categories {
			max-height: 62px;
		}
	}
	
	.signin-popup-form {
		&.straight,
		&.gay,
		&.trans,
		&.shemale {
			.x-body {
				background-image: none;
			}
		}
	}
	.account-page.anonymous #page.exec-manage-login_create {
		&.straight,
		&.gay,
		&.trans,
		&.shemale {
			background-image: none;
		}
	}
	#signin-popup-form {
		#signup-form_step2_marketing {
			width: 100%;
			
			h3,
			.marketing-mentions {
				text-align: center;
			}
		}
	}
}

/* ==== max 991px  ==== */
@media (max-width: @screen-sm-max) {
	
	.account-page.anonymous #page.exec-manage-login_create.with-premium-info-frame,
	.x-overlay.with-premium-info-frame {
		.login-create-section-title {
			font-size: 30px;
		}
		#premium-info-frame .login-create-section-sub-title,
		#signin-popup-form fieldset legend {
			font-size: 20px;
		}
	}
}

/* ==== max 929px = Screen just under the standard ==== */
@media (max-width: @screen-legacy-min) {
	
	#tabAboutMe {
		.rating-box {
			.rating-bar-block {
				min-width: 55px;
			}
		}
	}
	
	#premium-popup-form #premium-modes {
		 .mode {
			 .price, .slogan {
				 font-size: 1.2rem;
			 }
		 }
	 }
}

/* ==== max 870px = Intermediate small screens ==== */
@media (max-width: 870px) {
	#header .main-menu p {
		display: none;
	}
	
	#tabAboutMe {
		.rating-box {
			.rating-bar-block {
				min-width: 30px;
			}
		}
	}
}

/* ==== max 767px = MAINSTREAM OF MOBILE WIDE SCREEN AND TABLETS ==== */
@media (max-width: @screen-all-mobile) {
	@import "responsive/resp_elements.less";
	@import "responsive/resp_header.less";
	@import "responsive/resp_page.less";
	@import "responsive/resp_modules.less";
	@import "responsive/resp_search-tags.less";
	@import "responsive/resp_video.less";
	@import "responsive/resp_profiles.less";
	@import "responsive/resp_nav.less";
}

/* ==== max 640px ==== */
@media (max-width: 640px) {
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.any-col-config({ .thumb-block-size-mixin(2); });
				.thumb-under-1-line();
				padding-left: @video-thumb-padding-left;
				padding-right: @video-thumb-padding-right;
				
				&.thumb-block-big-button {
					&:not(.profile-video-list-thumb-block-big-button) {
						.thumb-block-size-mixin(1);
						padding: 0;
						
						.thumb-inside {
							padding-bottom: 0;
							height: auto;
						}
						
						.big-button-container {
							position: relative;
						}
						
						a {
							margin: 5px 0;
							padding: 5px;
							text-align: center;
						}
					}
				}
			}
		}
		&.thumbs-small{
			.thumb-block {
				.thumb-block-size-mixin(4);
			}
		}
	}
	
	
	#video-tabs {
		#video-views-votes .nb-views {
			.views-full {
				display: none;
			}
			
			.nb-views-number {
				display: inline;
			}
		}
	}
	
	#page .resps-tabs,
	#home-tabs {
		.tab-buttons {
			float: none;
			li .btn {
				.navbadge {
					left: auto;
					right: 8px;
					top: 2px;
				}
				&.premium {
					.ticket-in {
						.navbadge {
							left: auto;
							right: -6px;
							top: 3px;
						}
					}
				}
				&.home-regular{
					.label{
						display: inline;
					}
					.navbadge {
						right: 1px;
					}
				}
			}
			.label {
				display: none;
			}
		}
	}
	
	.activity-event {
		.act-achievement {
			font-size: 30px;
		}
	}
}

/* ==== max 560px ==== */
@media (max-width: 560px) {
	
	#video-views-votes .vote-actions .btn,
	#video-tabs .tab-buttons .btn {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	#signin-popup-form,
	.language-switcher-row {
		.col-xs-4,
		.col-xs-5,
		.col-xs-6,
		.col-xs-7,
		.col-xs-8 {
			width: 100%;
		}
	}
	
	/*.social-login-mobile-show {
		display: block;
	}
	.social-login-mobile-hide {
		display: none;
	}*/
	
	#create-account-frame {
		h2 {
			padding: 0;
			background: none;
		}
		
		.info-pannel-frame {
			padding-left: 15px;
		}
		
		.info-pannel {
			border-left: 0;
			border-top: 1px solid #fff;
			padding-left: 0;
			padding-top: 5px;
			margin-top: 0;
			
			h3 {
				margin-bottom: 15px;
				font-size: 18px;
			}
			
			.advantage {
				width: 50%;
				float: left;
				margin: 5px 0;
				
				p {
					font-size: 13px;
					font-weight: normal;
				}
			}
			
			.btn-default {
				margin-top: 15px;
			}
		}
	}
	
	#signin-popup-form {
		.login-mobile-show {
			display: block;
		}
		.login-mobile-hide {
			display: none;
		}
	}
	
	.signin-popup-form {
		.x-body {
			&:before,
			&:after {
				display: none;
			}
		}
	}
	
	
	.x-overlay.with-premium-info-frame {
		&,
		&.straight,
		&.gay,
		&.trans,
		&.shemale {
			.x-body {
				background-image: none;
			}
		}
	}
	
	.x-overlay.with-premium-info-frame,
	.account-page.anonymous #page.exec-manage-login_create.with-premium-info-frame {
		&,
		&.straight,
		&.gay,
		&.trans,
		&.shemale {
			background-image: none;
		}
		
		#premium-info-frame {
			background-position: right top;
			background-repeat: no-repeat;
			background-size: 100% auto;
			padding-bottom: 10px;
			margin-top: 10px;
		}
		
		&.straight {
			#premium-info-frame {
				background-image: url('../../img/skins/default/form-signup-straight-2.jpg');
			}
		}
		
		&.gay {
			#premium-info-frame {
				background-image: url('../../img/skins/default/form-signup-gay-2.jpg');
			}
		}
		
		&.trans,
		&.shemale {
			#premium-info-frame {
				background-image: url('../../img/skins/default/form-signup-trans-2.jpg');
			}
		}
	}
	
	.account-page.anonymous #page.exec-manage-login_create.with-premium-info-frame {
		padding-left: 15px;
		padding-right: 15px;
	}
	
	.account-page.anonymous #page.exec-manage-login_create {
		&:before,
		&:after {
			display: none;
		}
	}
	
	#premium-popup-form {
		.premium-plans {
			.plan {
				.label {
					font-size: 1.2rem;
					margin-bottom: 0.2rem;
					&.strong {
						font-size: 1.5rem;
						margin-top: .4rem;
					}
				}
				.details {
					font-size: 0.85em;
				}
			}
		}
		#premium-modes {
			.mode {
				.price, .slogan {
					font-size: 1.2rem;
				}
			}
		}
	}
}

/* ==== max 479px = MAINSTREAM OF MOBILE PHONES ==== */
@media (max-width: @screen-xxs-max) {
	@import "responsive/mobile_only.less";
}

/* ==== max 399px ==== */
@media (max-width: 399px) {
	#page .resps-tabs,
	#home-tabs {
		.stripe {
			font-size: 11px;
			line-height: 12px;
			padding-top: 0;
			text-align: right;
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			&.mobile-only-hide {
				display: none;
			}
		}
	}
	
	
	#premium-popup-form {
		#xvideored-logo {
			max-width: 200px;
		}
	}
	
	#page {
		&.video-page {
			#video-tabs .tab-buttons {
				.btn {
					padding-left: 7px;
					padding-right: 7px;
				}
			}
		}
	}
}

/* ==== max 359px ==== */
@media (max-width: 359px) {
	#tabPhotos {
		.img-block {
			width: 50%;
		}
	}
	.min-mobile-only-hide {
		display: none;
	}
	#tabAboutMe {
		#pfinfo-col-pict {
			float: none;
			margin-bottom: 5px;
			text-align: right;
			.rating-box {
				.rating-bar-block {
					min-width: 70px;
				}
			}
		}
	}
	#video-playlist #playlist-content .mozaique .thumb-block .thumb-overlay p {
		font-size: 18px;
	}
	#create-account-frame {
		.info-pannel {
			h3 {
				font-size: 17px;
			}
			.advantage {
				width: auto;
				float: none;
			}
			.btn-default {
				margin-top: 10px;
				padding: 7px;
				font-size: 14px;
				white-space: normal;
			}
		}
	}
}

/* ==== max 339px ==== */
@media (max-width: 339px) {
	#page .resps-tabs,
	#home-tabs {
		.tab-buttons li .btn {
			&,
			&:hover,
			&.active {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
}