
@import '../iconfont.less';

.checkbox {
	.custom-checkbox-form &:not(.toggle) {
		label {
			padding-left: 30px;
			position: relative;
		}
		
		input {
			font-size: 18px;
			
			background: @input-bg;
			border-radius: 0.3em;
			color: @input-color;
			height: 1em;
			margin: 0.1em 0 0 -1.6em;
			position: absolute;
			transform: scale(0.8);
			width: 1em;
			
			&::before,
			&::after {
				border-radius: 0.2em;
				bottom: 0;
				content: "";
				left: 0;
				position: absolute;
				right: 0;
				text-align: center;
				top: 0;
				width: 100%;
			}
			
			&::before {
				background: @input-bg;
				box-shadow: 0 0 1px 1px fadeOut(@input-border, 50%);
				height: 1em;
				transform: scale(1.25);
				z-index: 2;
			}
			
			&::after {
				.font-family-iconfont();
				color: @input-color;
				content: @icf-check;
				font-size: 0.8em;
				line-height: 1.25em;
				transform: scale(0);
				transition: transform 0.15s ease;
				z-index: 3;
			}
			
			&:focus {
				&::before {
					box-shadow: 0 0 1px 1px @input-border;
				}
			}
			
			&:checked {
				&::after {
					transform: scale(1.25);
				}
			}
			
			&:disabled {
				&::before {
					background: multiply(@input-bg, #666);
				}
				&::after {
					color: multiply(@input-color, #666);
					opacity: 0.7;
				}
			}
			
			& + .checkbox-error-box {
				display: none!important;
			}
			
			&.validator-error {
				&::before {
					box-shadow: 0 0 0 0.15em @brand-danger;
				}
			}
		}
	}
}