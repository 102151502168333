
@base-fanclub-height: 210px;
.activity-event {
	.fan-club-posts {
		display: flex;
		flex-direction: column;
	}

	.fan-club-post {
		display: flex;
		overflow: hidden;
		flex-wrap: nowrap;
		height: @base-fanclub-height;
		margin-bottom: 3px;
		.text-left {
			// Text
			& > .text-block {
				height: @base-fanclub-height;
				width: 100%;

				display: flex;
				flex-direction: column;
				gap: 5px;

				.fan-club-time-diff {
					display: flex;
					width: 100%;
					color: #aaa;
				}

				.icf-ticket-red {
					color: @theme-color;
					display: inline-block;
					font-size: 28px;
					height: 29px;
					margin: -5px 3px;
					margin-right: 6px;
					vertical-align: text-top;
				}

				.text-content {
					display: flex;
					flex-direction: column;
					overflow: hidden;
					font-size: 110%;
					.text-title {
						font-weight: bold;
						margin-bottom: 4px;
					}
					&.text-bigger .text-paragraph {
						text-align: center;
						flex-grow: 1;
						justify-content: center;
						align-items: center;
						padding-bottom: 18px;

						font-size: 166%;
						font-weight: bold;
					}
				}

				.extendable-text {
					mask-image: -webkit-gradient(linear, left 63%, left 85%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
					&:hover {
						cursor: pointer;
						mask-image: -webkit-gradient(linear, left 63%, left 90%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
					}
					&.extended {
						overflow-y: scroll;
						mask-image: none;
					}
				}
			}
		}

		.content-center {
			display: flex;
			overflow: hidden;
			flex-wrap: wrap;
			flex-grow: 1;
			justify-content: center;

			.frame-block {
				height: @base-fanclub-height;
				display: none;

				// Video
				&[id].tb_full_init {
					flex-direction: column;
					justify-content: center;
					.thumb-inside {
						max-height: 100%;
					}
					.thumb-under {
						max-height: 26%;
					}
				}

				// Picture
				&.img-block {
					.embed-responsive {
						position: relative;
						width: 100%;
						height: 100%;

						.cover-thumb {
							position: absolute;
							width: 84%;
							padding: 3%;
						}
					}
				}
			}
		}

		.see-more-pics {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.33);
			color: white;

			display: none;
			justify-content: center;
			align-items: center;
			& > span {
				font-size: 220%;
			}
		}
	}

	.set-nb-frames(@nb) {
		.text-left {
			width: 100% * (1/@nb);
		}
		.content-center {
			width: 100% - (100% * (1/@nb));

			.frame-block {
				width: 100% * (1/(@nb - 1));
				&:nth-child(-n+@{nb}) {
					display: flex;
				}
				&:not(:nth-child(-n+@{nb})) {
					display: none;
				}
				&:nth-child(@{nb}) {
					.see-more-pics {
						display: flex;
					}
				}
				&:not(:nth-child(@{nb})) {
					.see-more-pics {
						display: none;
					}
				}
			}
		}
	}

	@six_frames_res: 1440px;
	@seven_frames_res: 1920px;

	// Amount of frames / min resolution
	@frames_res: {
		3: 480px;
		4: 768px;
		5: 992px;
		6: @six_frames_res;
		7: @seven_frames_res;
	}
	.fan-club-post {
		each(@frames_res, {
			@media (min-width: @value) {
				@keyn: @index + 2;
				@keywidth: @index + 1;
				.text-left {
					width: calc(~"100% / @{key}");
				}
				.content-center {
					width: calc(~"100% - 100% / @{key}");
					.frame-block {
						width: calc(~"100% / (@{keywidth})");
						&:nth-child(-n+@{keyn}) {
							display: flex;
						}
						&:nth-child(@{keyn}) {
							.see-more-pics {
								display: flex;
							}
						}
						&:not(:nth-child(@{keyn})) {
							.see-more-pics {
								display: none;
							}
						}
					}
				}
			}
		});
	}
	.fan-club-post {
		@media (min-width: 480px) {
			&.frames-2 {
				.content-center .frame-block {
					width: 100%;
				}
			}
		}
		&.frames-3 {
			.content-center .frame-block {
				width: 50%;
			}
		}
	}
	.fan-club-post .content-center {
		.picture-block,
		.thumb-block {
			img {
				height: 100%;
				width: 100%;
				object-fit: contain;
				border: none;
				// background-color: rgba(0, 0, 0, 0.1);
			}
		}

	}

	.mobile-clear-text {
		display: none!important;
	}
	// Mobile 2x2 mode
	@media (max-width: 479px) {
		.mobile-clear-text {
			display: flex!important;
		}
		.fan-club-post {
			position: relative;
			height: @base-fanclub-height * 2;
			padding-bottom: @base-fanclub-height * 2;
			.text-left,
			.content-center .frame-block {
				height: 210px;
				width: 50%;
			}
			.content-center {
				position: absolute;
				left:0;
				top:0;
				width: 100%;
				.frame-block {
					&:nth-child(-n+4) {
						display: flex;
					}
					&:nth-child(4) {
						.see-more-pics {
							display: flex;
						}
					}
				}
				.img-block {
					align-items: center;
				}
			}

			&.frames-1 {
				.text-left {
					width: 100%;
				}
				.content-center {
					display: none;
				}
			}
		}
	}

	.fan-club-post.frames-1,
	.fan-club-post.frames-2 {
		height: @base-fanclub-height;

		@media (max-width: 479px) {
			padding-bottom: @base-fanclub-height;
		}
	}
	.fan-club-post.text-only.frames-1:not(.post-condensed) {
		height: auto;
		.text-left {
			.frame-block.text-block {
				height: auto;
			}
		}

		@media (max-width: 479px) {
			padding-bottom: 0;
			.text-left {
				height: auto;
			}
		}
	}

	// Post condensing
	@media (min-width: @six_frames_res) {
		.fan-club-posts {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
	@media (min-width: @six_frames_res) and (max-width: (@seven_frames_res - 1)) {
		.fan-club-post:not(.post-condensed) {
			height: @base-fanclub-height;
			width: 100%;
		}
		.fan-club-post.post-condensed {
			width: 100% * (1/2);
			&.frames-2 {
				.set-nb-frames(2);
				.content-center .frame-block {
					width: 100%;
				}
			}
			&.frames-3 {
				.set-nb-frames(4);
				.content-center .frame-block {
					width: 50%;
				}
			}
			&.frames-4 {
				.set-nb-frames(4);
			}
			.frame-block:nth-child(-n+3) {
				display: flex;
			}
			.frame-block:not(:nth-child(-n+3)) {
				display: none;
			}
			&.post-condensed-second.has-video {
				width: 100% * (2/3);
				.set-nb-frames(4);
			}
			&.post-condensed-smaller {
				width: 100% * (1/3);
				&.frames-2,
				&.frames-3 {
					.set-nb-frames(2);
					.content-center .frame-block {
						width: 100%;
					}
				}
			}
		}
	}
	@media (min-width: @seven_frames_res) {
		.fan-club-post {
			width: 100%;
			&.post-condensed {
				width: 100% * (3/7);
				.set-nb-frames(3);
				&.frames-2 {
					.content-center .frame-block {
						width: 100%;
					}
				}
				&.post-condensed-second {
					width: 100% * (4/7);
					.set-nb-frames(4);
					&.frames-2 {
						.content-center .frame-block {
							width: 100%;
						}
					}
					&.frames-3 {
						.content-center .frame-block {
							width: 50%;
						}
					}
				}
			}
		}
	}

	// Text-only has no content
	.fan-club-post.text-only {
		.text-left {
			width: 100%;
		}
		.content-center {
			width: 0%;
		}
	}

	// Remove video details at the bottom
	.fan-club-post.has-video {
		.content-center {
			.thumb-block {
				// Title is appended at the start of the text post
				// Badges are positionned on top of the thumb, the rest is hidden
				.thumb-under {
					display: none;
				}
			}
		}
	}

	// All Gallery
	.fan-club-post {
		.p-allgallery-back {
			display: none;
			position: fixed;
			z-index: 1000;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			background-color: rgba(0,0,0,.5);

			.p-allgallery-exit {
				position: absolute;
				top: 0;
				right: 0;
				height: 3vh;
				width: 3vh;
				font-size: 2.5vh;
				margin: 2vh;

				color: white;
				text-shadow: 0 0 4px BLACK;

				&:hover {
					cursor: pointer;
				}
			}

			.p-allgallery {
				display: flex;
				position: fixed;
				z-index: 250;

				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				display: flex;
				margin: 5.5vh 5.5vw;
				padding: 1vh 1vw;

				background-color: @text-invert-color;
				border: 1px solid grey;
				border-radius: 3px;
				box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.62);

				justify-content: flex-start;
				flex-wrap: wrap;
				overflow: scroll;
				overflow-x: hidden;

				.thumb {
					display: flex;
					flex-basis: 33.333%;
					@media (max-width: 1800px) {
						flex-basis: 50%;
					}
					max-height: 50%;
					padding: 0.5vh 0.25vw;

					.fit-image {
						height: 100%;
						width: 100%;

						img {
							height: 100%;
							width: 100%;
							object-fit: contain;

							&:hover {
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
}