/* ==== TYPE ==== */

::-moz-selection,
::selection {
    background: @theme-color;
    color: #fff;
    text-shadow: none;
}

ul {
	margin: 0 0 3px;
	list-style-type: none;
}
p {
	margin: 0 0 3px;
}
h1 {
	margin: 10px 0;
}
h2 {
	margin: 8px 0;
}
h3 {
	margin: 6px 0;
	small,
	.small {
		font-size: 85%;
	}
}
h4 {
	margin: 4px 0;
	small,
	.small {
		font-size: 85%;
	}
}
h5 {
	margin: 10px 0 0;
	small,
	.small {
		font-size: 85%;
	}
}

.btn {
	margin: 0 2px 4px 0;
	border-width: 1px;
	transition: box-shadow ease 0.2s, background ease 0.2s, border ease 0.2s;
	&.text-danger {
		color: @red-color;
	}
	&:hover,
	&:active,
	&.active {
		&.text-danger,
		.text-danger {
			color: #fff;
		}
	}
	&.btn-link {
		box-shadow: none;
	}
	&.btn-xs {
		border-bottom-width: 1px;
		padding-bottom: 1px;
	}
	&.btn-lg {
		font-size: 15px;
		padding-bottom: 10px;
	}
	&.light-label {
		font-weight: normal;
	}
	&.wrap {
		white-space: normal;
	}
	.btn-group & {
		margin-right: 0;
		
		&:last-child {
			margin-right: 2px;
		}
	}
	
	.input-group-btn & {
		margin-right: 0;
		
		& + .btn {
			margin-left: -1px;
		}
	}
	
	.input-group-btn:first-child & {
		margin-right: -1px;
	}
}
.input-group-btn .btn.btn-group {
	margin: 0;
}

a {
	text-decoration: underline;
	background-color: transparent;
	cursor: pointer;
	&.hg {
		color: @red-color;
		font-weight: bold;
		&:hover {
			color: @theme-color;
		}
	}
	&.nu {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&.btn,
	.btn & {
		text-decoration: none;
	}
}

.text-highlight {
	color: @red-color;
}

.text-disabled {
	color: @gray;
}

.normal-weight {
    font-weight: normal;
}

.bg-title {
	margin: 10px 0;
    padding: 2px 8px;
    border: 1px solid #000;
    overflow: hidden;
	line-height: @line-height-base;
	font-weight: normal;
    .text-danger {
        float: right;
        padding: 1px;
        font-size: 13px;
    }
    .right-note {
        float: right;
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
    }
	&.black,
	&.grey-dark {
		font-weight: bold;
		background: #000;
		color: #fff;
		a,
		a:visited,
		small {
			color: #fff;
		}
		a:hover {
			color: @red-color;
		}
	}
	&.grey-dark {
		background: #333;
		color: #fff;
	}
	&.grey {
		background: @gray-bg;
		border-color: @gray-bg;
		color: @gray-dark;
	}
	&.top {
		margin-top: 0;
	}
	&.no-border {
		border: 0;
	}
	a.nounderline {
		text-decoration: none;
	}
	.right {
		float: right;
	}
}


// GAY Flag
.icon-flag-gay {
	display: inline-block;
	height: 12px;
	position: relative;
	vertical-align: middle;
	width: 22px;
	* {
		display: block;
		left: 2px;
		height: 2px;
		position: absolute;
		width: 18px;
		z-index: 1;
	}
	.r {
		background: #e40203;
		top: 0;
	}
	.o {
		background: #f98c00;
		top: 2px;
	}
	.y {
		background: #fced00;
		top: 4px;
	}
	.g {
		background: #1b8026;
		top: 6px;
	}
	.b {
		background: #294dfe;
		top: 8px;
	}
	.v {
		background: #740787;
		top: 10px;
	}
}
