/* ==== FLAGS ==== */
.flag-small {
	display: inline;
	*zoom: 1;
	display: inline-block;
	width: 16px;
	height: 11px;
	line-height: 11px;
	background-image: url("../../../v3/img/flags/flat/flags-16.png");
	background-position: 0 0;
	background-repeat: no-repeat;
	
	&.flag-ad { background-position: 0 -2px	}
	&.flag-ae { background-position: -16px -2px	}
	&.flag-af { background-position: -32px -2px	}
	&.flag-ag { background-position: -48px -2px	}
	&.flag-ai { background-position: -64px -2px	}
	&.flag-al { background-position: -80px -2px	}
	&.flag-am { background-position: -96px -2px	}
	&.flag-an { background-position: -112px -2px }
	&.flag-ao { background-position: -128px -2px }
	&.flag-aq { background-position: -144px -2px }
	&.flag-ar { background-position: -160px -2px }
	&.flag-as { background-position: -176px -2px }
	&.flag-at { background-position: -192px -2px }
	&.flag-au { background-position: -208px -2px }
	&.flag-aw { background-position: -224px -2px }
	&.flag-ax { background-position: -240px -2px }
	&.flag-az { background-position: 0 -18px }
	&.flag-ba { background-position: -16px -18px }
	&.flag-bb { background-position: -32px -18px }
	&.flag-bd { background-position: -48px -18px }
	&.flag-be { background-position: -64px -18px }
	&.flag-bf { background-position: -80px -18px }
	&.flag-bg { background-position: -96px -18px }
	&.flag-bh { background-position: -112px -18px }
	&.flag-bi { background-position: -128px -18px }
	&.flag-bj { background-position: -144px -18px }
	&.flag-bl { background-position: -160px -18px }
	&.flag-bm { background-position: -176px -18px }
	&.flag-bn { background-position: -192px -18px }
	&.flag-bo { background-position: -208px -18px }
	&.flag-br { background-position: -224px -18px }
	&.flag-bs { background-position: -240px -18px }
	&.flag-bt { background-position: 0 -34px }
	&.flag-bw { background-position: -16px -34px }
	&.flag-by { background-position: -32px -34px }
	&.flag-bz { background-position: -48px -34px }
	&.flag-ca { background-position: -64px -34px }
	&.flag-cc { background-position: -80px -34px }
	&.flag-cd { background-position: -96px -34px }
	&.flag-cf { background-position: -112px -34px }
	&.flag-cg { background-position: -128px -34px }
	&.flag-ch { background-position: -144px -34px }
	&.flag-ci { background-position: -160px -34px }
	&.flag-ck { background-position: -176px -34px }
	&.flag-cl { background-position: -192px -34px }
	&.flag-cm { background-position: -208px -34px }
	&.flag-cn { background-position: -224px -34px }
	&.flag-co { background-position: -240px -34px }
	&.flag-cr { background-position: 0 -50px }
	&.flag-cu { background-position: -16px -50px }
	&.flag-cv { background-position: -32px -50px }
	&.flag-cw { background-position: -48px -50px }
	&.flag-cx { background-position: -64px -50px }
	&.flag-cy { background-position: -80px -50px }
	&.flag-cz { background-position: -96px -50px }
	&.flag-de { background-position: -112px -50px }
	&.flag-dj { background-position: -128px -50px }
	&.flag-dk { background-position: -144px -50px }
	&.flag-dm { background-position: -160px -50px }
	&.flag-do { background-position: -176px -50px }
	&.flag-dz { background-position: -192px -50px }
	&.flag-ec { background-position: -208px -50px }
	&.flag-ee { background-position: -224px -50px }
	&.flag-eg { background-position: -240px -50px }
	&.flag-eh { background-position: 0 -66px }
	&.flag-er { background-position: -16px -66px }
	&.flag-es { background-position: -32px -66px }
	&.flag-et { background-position: -48px -66px }
	&.flag-eu { background-position: -64px -66px }
	&.flag-fi { background-position: -80px -66px }
	&.flag-fj { background-position: -96px -66px }
	&.flag-fk { background-position: -112px -66px }
	&.flag-fm { background-position: -128px -66px }
	&.flag-fo { background-position: -144px -66px }
	&.flag-fr { background-position: -160px -66px }
	&.flag-ga { background-position: -176px -66px }
	&.flag-gb { background-position: -192px -66px }
	&.flag-gd { background-position: -208px -66px }
	&.flag-ge { background-position: -224px -66px }
	&.flag-gg { background-position: -240px -66px }
	&.flag-gh { background-position: 0 -82px }
	&.flag-gi { background-position: -16px -82px }
	&.flag-gl { background-position: -32px -82px }
	&.flag-gm { background-position: -48px -82px }
	&.flag-gn { background-position: -64px -82px }
	&.flag-gq { background-position: -80px -82px }
	&.flag-gr { background-position: -96px -82px }
	&.flag-gs { background-position: -112px -82px }
	&.flag-gt { background-position: -128px -82px }
	&.flag-gu { background-position: -144px -82px }
	&.flag-gw { background-position: -160px -82px }
	&.flag-gy { background-position: -176px -82px }
	&.flag-hk { background-position: -192px -82px }
	&.flag-hn { background-position: -208px -82px }
	&.flag-hr { background-position: -224px -82px }
	&.flag-ht { background-position: -240px -82px }
	&.flag-hu { background-position: 0 -98px }
	&.flag-ic { background-position: -16px -98px }
	&.flag-id { background-position: -32px -98px }
	&.flag-ie { background-position: -48px -98px }
	&.flag-il { background-position: -64px -98px }
	&.flag-im { background-position: -80px -98px }
	&.flag-in { background-position: -96px -98px }
	&.flag-iq { background-position: -112px -98px }
	&.flag-ir { background-position: -128px -98px }
	&.flag-is { background-position: -144px -98px }
	&.flag-it { background-position: -160px -98px }
	&.flag-je { background-position: -176px -98px }
	&.flag-jm { background-position: -192px -98px }
	&.flag-jo { background-position: -208px -98px }
	&.flag-jp { background-position: -224px -98px }
	&.flag-ke { background-position: -240px -98px }
	&.flag-kg { background-position: 0 -114px }
	&.flag-kh { background-position: -16px -114px }
	&.flag-ki { background-position: -32px -114px }
	&.flag-km { background-position: -48px -114px }
	&.flag-kn { background-position: -64px -114px }
	&.flag-kp { background-position: -80px -114px }
	&.flag-kr { background-position: -96px -114px }
	&.flag-kw { background-position: -112px -114px }
	&.flag-ky { background-position: -128px -114px }
	&.flag-kz { background-position: -144px -114px }
	&.flag-la { background-position: -160px -114px }
	&.flag-lb { background-position: -176px -114px }
	&.flag-lc { background-position: -192px -114px }
	&.flag-li { background-position: -208px -114px }
	&.flag-lk { background-position: -224px -114px }
	&.flag-lr { background-position: -240px -114px }
	&.flag-ls { background-position: 0 -130px }
	&.flag-lt { background-position: -16px -130px }
	&.flag-lu { background-position: -32px -130px }
	&.flag-lv { background-position: -48px -130px }
	&.flag-ly { background-position: -64px -130px }
	&.flag-ma { background-position: -80px -130px }
	&.flag-mc { background-position: -96px -130px }
	&.flag-md { background-position: -112px -130px }
	&.flag-me { background-position: -128px -130px }
	&.flag-mf { background-position: -144px -130px }
	&.flag-mg { background-position: -160px -130px }
	&.flag-mh { background-position: -176px -130px }
	&.flag-mk { background-position: -192px -130px }
	&.flag-ml { background-position: -208px -130px }
	&.flag-mm { background-position: -224px -130px }
	&.flag-mn { background-position: -240px -130px }
	&.flag-mo { background-position: 0 -146px }
	&.flag-mp { background-position: -16px -146px }
	&.flag-mq { background-position: -160px -66px }
	&.flag-mr { background-position: -48px -146px }
	&.flag-ms { background-position: -64px -146px }
	&.flag-mt { background-position: -80px -146px }
	&.flag-mu { background-position: -96px -146px }
	&.flag-mv { background-position: -112px -146px }
	&.flag-mw { background-position: -128px -146px }
	&.flag-mx { background-position: -144px -146px }
	&.flag-my { background-position: -160px -146px }
	&.flag-mz { background-position: -176px -146px }
	&.flag-na { background-position: -192px -146px }
	&.flag-nc { background-position: -208px -146px }
	&.flag-ne { background-position: -224px -146px }
	&.flag-nf { background-position: -240px -146px }
	&.flag-ng { background-position: 0 -162px }
	&.flag-ni { background-position: -16px -162px }
	&.flag-nl { background-position: -32px -162px }
	&.flag-no { background-position: -48px -162px }
	&.flag-np { background-position: -64px -162px }
	&.flag-nr { background-position: -80px -162px }
	&.flag-nu { background-position: -96px -162px }
	&.flag-nz { background-position: -112px -162px }
	&.flag-om { background-position: -128px -162px }
	&.flag-pa { background-position: -144px -162px }
	&.flag-pe { background-position: -160px -162px }
	&.flag-pf { background-position: -176px -162px }
	&.flag-pg { background-position: -192px -162px }
	&.flag-ph { background-position: -208px -162px }
	&.flag-pk { background-position: -224px -162px }
	&.flag-pl { background-position: -240px -162px }
	&.flag-pn { background-position: 0 -178px }
	&.flag-pr { background-position: -16px -178px }
	&.flag-ps { background-position: -32px -178px }
	&.flag-pt { background-position: -48px -178px }
	&.flag-pw { background-position: -64px -178px }
	&.flag-py { background-position: -80px -178px }
	&.flag-qa { background-position: -96px -178px }
	&.flag-ro { background-position: -112px -178px }
	&.flag-rs { background-position: -128px -178px }
	&.flag-ru { background-position: -144px -178px }
	&.flag-rw { background-position: -160px -178px }
	&.flag-sa { background-position: -176px -178px }
	&.flag-sb { background-position: -192px -178px }
	&.flag-sc { background-position: -208px -178px }
	&.flag-sd { background-position: -224px -178px }
	&.flag-se { background-position: -240px -178px }
	&.flag-sg { background-position: 0 -194px }
	&.flag-sh { background-position: -16px -194px }
	&.flag-si { background-position: -32px -194px }
	&.flag-sk { background-position: -48px -194px }
	&.flag-sl { background-position: -64px -194px }
	&.flag-sm { background-position: -80px -194px }
	&.flag-sn { background-position: -96px -194px }
	&.flag-so { background-position: -112px -194px }
	&.flag-sr { background-position: -128px -194px }
	&.flag-ss { background-position: -144px -194px }
	&.flag-st { background-position: -160px -194px }
	&.flag-sv { background-position: -176px -194px }
	&.flag-sy { background-position: -192px -194px }
	&.flag-sz { background-position: -208px -194px }
	&.flag-tc { background-position: -224px -194px }
	&.flag-td { background-position: -240px -194px }
	&.flag-tf { background-position: 0 -210px }
	&.flag-tg { background-position: -16px -210px }
	&.flag-th { background-position: -32px -210px }
	&.flag-tj { background-position: -48px -210px }
	&.flag-tk { background-position: -64px -210px }
	&.flag-tl { background-position: -80px -210px }
	&.flag-tm { background-position: -96px -210px }
	&.flag-tn { background-position: -112px -210px }
	&.flag-to { background-position: -128px -210px }
	&.flag-tr { background-position: -144px -210px }
	&.flag-tt { background-position: -160px -210px }
	&.flag-tv { background-position: -176px -210px }
	&.flag-tw { background-position: -192px -210px }
	&.flag-tz { background-position: -208px -210px }
	&.flag-ua { background-position: -224px -210px }
	&.flag-ug { background-position: -240px -210px }
	&.flag-us { background-position: 0 -226px }
	&.flag-uy { background-position: -16px -226px }
	&.flag-uz { background-position: -32px -226px }
	&.flag-va { background-position: -48px -226px }
	&.flag-vc { background-position: -64px -226px }
	&.flag-ve { background-position: -80px -226px }
	&.flag-vg { background-position: -96px -226px }
	&.flag-vi { background-position: -112px -226px }
	&.flag-vn { background-position: -128px -226px }
	&.flag-vu { background-position: -144px -226px }
	&.flag-wf { background-position: -160px -226px }
	&.flag-ws { background-position: -176px -226px }
	&.flag-ye { background-position: -192px -226px }
	&.flag-yt { background-position: -208px -226px }
	&.flag-za { background-position: -224px -226px }
	&.flag-zm { background-position: -240px -226px }
	&.flag-zw { background-position: 0 -242px }
	&.flag-xk { background-position: -144px -242px }
	&.flag-none { background-position: -16px -242px }
	&.flag-question {
		background-color: #000;
		background-image: none;
		color: #fff;
		font-size: 16px;
		line-height: 11px;
		position: relative;
		text-align: center;
		*zoom: ~"expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '?')";
		&:before {
			color: #fff;
			content: "?";
			font-size: 11px;
			font-weight: bold;
			left: 0;
			line-height: 11px;
			position: absolute;
			text-align: center;
			top: 0;
			width: 100%;
			z-index: 1;
		}
	}
	
	&.flag-c1 { background-position: -112px -242px }
	&.flag-c2 { background-position: -80px -242px }
	&.flag-c3 { background-position: -96px -242px }
	&.flag-c4 { background-position: -64px -242px }
	&.flag-c5 { background-position: -32px -242px }
	&.flag-c6 { background-position: -48px -242px }
	&.flag-c7 { background-position: -160px -242px }
	&.flag-p3 { background-position: -176px -242px }
	
	&.flag-p1,
	&.flag-p2,
	&.flag-p4,
	&.flag-p5,
	&.flag-p6,
	&.flag-p7,
	&.flag-p8,
	&.flag-p9,
	&.flag-m1,
	&.flag-s1 {
		box-sizing: content-box;
		position: relative;
		
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 8px;
			height: 8px;
			margin: -4px 0 0 -4px;
			border-radius: 50%;
			background: #ffffff;
		}
	}
	
	&.flag-p5,
	&.flag-s1 {
		&:before {
			width: 10px;
			height: 10px;
			margin: -5px 0 0 -5px;
		}
	}
	
	&.flag-p1,
	&.flag-p9 {
		&:before {
			width: 6px;
			height: 6px;
			margin: -3px 0 0 -3px;
		}
	}
	
	&.flag-p1 {
		&:before {
			background: #909090;
		}
	}
	&.flag-p2 {
		&:before {
			background: #ffe7c3;
		}
	}
	&.flag-p4 {
		&:before {
			background: #DB4C14;
		}
	}
	&.flag-p5 {
		&:before {
			background: #ff9900;
		}
	}
	&.flag-p6 {
		&:before {
			background: #ffc800;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 12px;
			height: 1px;
			margin-left: -6px;
			transform: rotate(-20deg);
			background: #ffffff;
		}
	}
	&.flag-p7 {
		&:before {
			background: #99aaff;
		}
	}
	&.flag-p8 {
		&:before {
			background: #4122ee;
		}
	}
	&.flag-p9 {
		&:before {
			background: #e7b067;
		}
	}
	&.flag-m1 {
		&:before {
			background: #dedede;
		}
	}
	&.flag-s1 {
		&:before {
			background: #ffdd00;
		}
	}
}