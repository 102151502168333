
#premium-popup-form {
	.other-offers-icons {
		vertical-align: text-bottom;
		
		.icon-f {
			& + .icon-f {
				margin-left: 4px;
			}
		}
	}
	
}