

#mobile-login-btn {
	.init-ok-btn();
}

#main-cat-switcher {
	background: @gray-lighter;
	color: @gray-dark;
	.init-ok-btn();
	margin: 4px;
	padding: 4px 6px 3px 12px;
	
	.icf-caret-down {
		color: @gray-light;
		font-size: 10px;
	}
}

#xv-search-form {
	height: 40px;
	overflow: hidden;
}

#home-search {
	div.cont {
		padding: 1px 0 1px 10px;
		overflow: hidden;
	}
}
#xv-search-form,
#home-search {
	.search-input {
		background: @search-header-inp-bg;
		border: 1px solid @search-header-inp-border;
		border-right: 0;
		border-radius: 0;
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
		color: @search-header-inp-color;
		font-size: 17px;
		height: 36px;
		margin: 2px 0;
		padding: 4px 10px;
		
		[dir="rtl"] & {
			border-left: 0;
			border-right: 2px solid @search-header-inp-border;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 3px;
			border-top-left-radius: 0;
			border-top-right-radius: 3px;
		}
		
		&:focus {
			box-shadow: none;
		}
	}
	
	.search-submit {
		background: @search-header-submit-bg;
		border: 2px solid @search-header-inp-border;
		border-left: 0;
		border-radius: 0;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		color: @search-header-inp-color;
		font-size: 16px;
		font-weight: normal;
		height: 36px;
		margin: 0;
		padding: 0 10px;
		position: relative;
		transition: background 0.2s;
		
		[dir="rtl"] & {
			border-left: 2px solid @search-header-inp-border;
			border-right: 0;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 0;
			border-top-left-radius: 3px;
			border-top-right-radius: 0;
		}
		
		&:hover {
			background: @search-header-submit-hover-bg;
		}
		
		&:focus {
			outline: none;
			background: @search-header-submit-active-bg;
		}
		
		span {
			margin: 6px 3px;
		}
	}
}

.search-autocomplete {
	background: @text-invert-color;
	border: 1px solid #000;
	color: #666;
	display: none;
	font-size: 14px;
	left: 0;
	position: absolute;
	top: 0;
	z-index: @zindex-autocomplete;
	
	.title {
		padding: 2px 0;
		background: #222;
		color: #fff;
		font-weight: bold;
	}
	
	ul {
		margin: 0;
		padding: 0;
		max-height: 160px;
		overflow-y: auto;
		position: relative;
	}
	
	li {
		cursor: pointer;
		padding: 2px 0;
		
		a {
			text-decoration: none;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			
			.nbres {
				float: right;
				margin-left: 6px;
				margin-top: 3px;
				background: @gray-light;
				color: @text-invert-color;
				font-size: 12px;
				font-weight: bold;
				line-height: 16px;
				padding: 0 4px;
				border-radius: 3px;
			}
			
			.keywords {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			
			.highlighted {
				background: lighten(@theme-color, 50%);
			}
		}
		
		&:hover {
			background: @gray-bg;
		}
		
		&.selected {
			background: @gray-light2;
		}
		
		&.more {
			margin-top: 20px;
			position: relative;
			
			&::before {
				background: @text-invert-color;
				content: @icf-plus-square;
				color: @gray-bg;
				display: block;
				.font-family-iconfont;
				font-size: 18px;
				left: 50%;
				line-height: 18px;
				margin: 0 -15px;
				position: absolute;
				text-align: center;
				top: -18px;
				width: 30px;
				z-index: 2;
			}
			
			&::after {
				background: @gray-lighter;
				content: ' ';
				height: 2px;
				left: 0;
				position: absolute;
				top: -9px;
				width: 100%;
				z-index: 1;
			}
		}
	}
	
	.pornstars {
		li {
			line-height: 16px;

			a {
				display: flex;
			}
			
			.thumb {
				display: block;
				float: left;
				min-width: 40px;
				width: 40px;
				min-height: 22.5px;
				height: 22.5px;
				margin-right: 5px;
				text-align: center;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: auto 100%;
			}

			.sub {
				padding: 0px 4px;
				background: @search-sub-bg;
				color: @text-invert-color;
				@media (max-width: @screen-xs-max) {
					background: initial;
					color: @text-color;
				}
				border-radius: 4px;
				font-size: 12px;
				line-height: 19px;
				vertical-align: middle;
				margin-top: 3px;
				margin-left: auto;
				.sub-icon {
					margin-left: 2px;
    				font-size: 10px;
				}
			}

			.name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			
			.name,
			.rank {
				padding-top: 3px;
				vertical-align: middle;
			}
			
			.name,
			.rank-pos,
			.rank-type,
			.sub {
				font-weight: bold;
			}
			
			.rank-pos,
			.rank-type {
				color: @theme-color;
			}
		}
	}
}

#language-switcher {
	display: inline-block;
	.init-ok-btn();
	margin: 3px 2px 3px 4px;
	text-decoration: none;
	vertical-align: text-bottom;
	
	.flag {
		vertical-align: text-bottom;
	}
}

#language-switcher-list {
	background: @tooltip-bg;
	display: none;
	margin: 0;
	padding: 10px 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: @zindex-mobile-menu;
	
	&.desktop_opened {
		display: block;
	}
	
	&:before {
		border-color: transparent;
		border-bottom-color: @tooltip-bg;
		border-style: solid;
		border-width: 0 5px 5px;
		content: " ";
		right: 39px;
		position: absolute;
		top: -5px;
	}
	
	li {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	
	a {
		background: @tooltip-bg;
		color: @tooltip-color;
		display: block;
		font-size: 14px;
		height: 28px;
		line-height: 26px;
		padding: 0 20px;
		text-align: left;
		text-decoration: none;
		transition: background 0.2s;
		white-space: nowrap;
		
		&, * {
			cursor: pointer;
		}
		
		&:hover {
			background: lighten(@tooltip-bg, 10%);
		}
		
		.flag-small {
			margin-right: 4px;
		}
	}
}

#listing-settings {
	text-align: right;
	.init-ok-btn();
	
	.pagination + & {
		float: right;
		margin-top: -31px;
	}
	
	button.btn {
		background: @text-color;
		color: @text-invert-color;
		border-radius: 0;
		border-color: #000;
		box-shadow: none;
		cursor: inherit;
		
		&:hover {
			background: @theme-color;
			border-color: @theme-color;
			color: #fff;
		}
		
		.icon,
		.icon-f {
			display: none;
		}
	}
}

.stripe + #listing-settings {
	.listing-settings-float-mixin(-30px, 26px);
}

.listing-settings-popup {
	z-index: @zindex-listing-settings-under-header;
	
	&.in-header {
		z-index: @zindex-listing-settings;
	}
	
	&.x-popup.below.right .x-popup-arrow {
		right: 9px;
		
		body.body--with-old-header & {
			right: 13px;
		}
	}
	
	.x-popup-content {
		padding: 8px 0;
		max-width: calc(~"100vw - 4px");
		.site-settings .icon-flag-gay {
			margin-top: -2px;
		}
	}
	
	body & ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	
	.site-settings {
		.language-switcher,
		.main-cat-switcher,
		.log-as-menu,
		.theme-switcher,
		.currency-switcher,
		.dev-menu {
			display: none;
		}
	}
	
	&.show-lang,
	&.show-theme,
	&.show-main-cat,
	&.show-log-as,
	&.show-currency,
	&.show-dev {
		.site-settings > ul {
			display: none;
		}
	}
	
	&.show-lang .site-settings .language-switcher,
	&.show-theme .site-settings .theme-switcher,
	&.show-main-cat .site-settings .main-cat-switcher,
	&.show-log-as .site-settings .log-as-menu,
	&.show-currency .site-settings .currency-switcher,
	&.show-dev .site-settings .dev-menu {
		display: block;
	}
	
	
	button,
	a.btn,
	.language-switcher a,
	.main-cat-switcher a,
	.title,
	#theme-switcher-list span {
		border: 0;
		background: none;
		display: block;
		font-size: 16px;
		line-height: 24px;
		text-align: left;
		text-decoration: none;
		transition: padding 0.5s;
		padding: 6px 20px;
		width: 100%;
		
		html.big-ui & {
			padding-bottom: 10px;
			padding-top: 10px;
		}
	}
	
	a.btn {
		font-weight: normal;
		padding-top: 4px;
		padding-bottom: 4px;
		
		html.big-ui & {
			padding-bottom: 10px;
			padding-top: 10px;
		}
	}
	
	//a.page-link,
	a.log-as-link,
	button.log-as-btn {
		position: relative;
		
		&:before {
			.font-family-iconfont();
			display: inline-block;
			margin-top: -8px;
			position: absolute;
			top: 50%;
		}
	}
	
	a.page-link,
	button.log-as-btn {
		.navbadge {
			float: right;
			margin-top: 3px;
			padding: 4px 6px;
		}
	}
	
	//a.page-link,
	a.log-as-link {
		padding-left: 50px;
		
		&:before {
			left: 24px;
			opacity: 0.4;
		}
	}
	
	//a.page-link {
	//	&:before {
	//		content: @icf-caret-right;
	//	}
	//}
	
	a.log-as-link {
		line-height: 22px;
		
		&:before {
			content: @icf-user;
		}
	}
	
	.title {
		font-weight: bold;
		opacity: 0.5;
		text-align: center;
	}
	
	button,
	a.btn,
	.language-switcher a,
	.main-cat-switcher a,
	#theme-switcher-list span {
		&:focus, &:active, &:focus:active {
			outline: 0;
		}
		
		&:hover,
		&.btn-highlight {
			background: @theme-color;
			color: #fff;
			text-decoration: none;
			
			strong {
				color: #fff;
			}
		}
		
		&.btn-highlighting {
			strong {
				color: #fff
			}
		}
		
		&.btn-highlight-t {
			transition: background 0.28s;
			
			strong {
				transition: color 0.28s;
			}
		}
		
		&.isLoading {
			&:hover {
				background: none;
			}
			
			cursor: wait;
			opacity: 0.5;
		}
		
		.emoji {
			display: inline-block;
			margin: 0 5px 0 0;
			width: 18px;
		}
		
		.icon-f {
			display: inline-block;
			height: 16px;
			line-height: 16px;
			margin: 0 5px 0 2px;
			position: relative;
			top: 1px;
			width: 16px;
			
			&.icf-theme-switch:before,
			&.icf-search-plus:before {
				font-size: 20px;
				left: -1px;
				top: 1px;
				position: relative;
			}
			
			&.icf-money:before {
				font-size: 14px;
			}
		}
		
		.flag-small {
			margin: 2px 5px 0 2px;
		}
		
		.listing-nb-thumbs {
			margin: 0 3px 0 0;
			
			&.mobile {
				margin: 0 5px 0 2px;
			}
		}
		
		.text-highlight {
			display: none;
		}
		
		.toggle {
			display: inline-block;
			float: right;
			margin: 3px 0 0 6px;
			vertical-align: top;
		}
		
		&,
		&:hover,
		&.btn-highlight {
			.theme-label {
				background: @body-bg;
				border-radius: 4px;
				color: @text-color;
				float: right;
				margin: -2px 0 -2px 6px;
				padding: 2px 6px;
			}
		}
	}
	
	
	.currency-switcher {
		ul {
			column-width: 86px;
			max-width: 95vw;
			width: 900px;
		}
		
		.btn {
			font-size: 20px;
			font-weight: normal;
			padding: 6px 0;
			text-align: center;
			
			&:active {
				box-shadow: none;
			}
			
			&:focus {
				color: #fff;
			}
		}
	}
	
	.language-switcher,
	.main-cat-switcher,
	.log-as-menu,
	.theme-switcher,
	.currency-switcher {
		ul {
			margin: 0;
			padding: 0;
		}
	}
	
	.main-cat-switcher .current  a,
	#theme-switcher-list .current,
	.currency-switcher .btn.is-current {
		background: rgba(255, 255, 255, 0.1);
		font-weight: bold;
	}
	
	.language-switcher {
		a {
			font-weight: bold;
		}
	}
	
	.sep {
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		height: 1px;
		margin: 2px 20px 3px;
	}
}

.currency-error-pop {
	&.x-popup {
		&.below .x-popup-arrow {
			border-bottom-color: #eee;
		}
		
		.x-popup-content {
			background-color: #eee;
			color: @red-color;
			font-size: 15px;
			padding: 5px 15px;
		}
	}
}

.multi-accounts-popup {
	&.below .x-popup-arrow {
		border-bottom-color: @gray;
	}
	
	.x-popup-content {
		padding: 8px 0;
		background: @gray;
	}
	
	ul {
		margin: 0;
		padding: 0;
	}
	
	li {
		display: block;
	}
	
	a {
		white-space: nowrap;
		text-decoration: none;
		display: block;
		padding: 3px 20px;
		font-weight: bold;
		
		&:hover {
			background: @theme-color;
			color: #fff;
			text-decoration: none;
		}
	}
}

#main .secondary-menu {
	clear: both;
	overflow: hidden;
	zoom: 1;
	padding: 6px 0;
	
	p {
		float: right;
		margin: 0;
		overflow: hidden;
		zoom: 1;
	}
	
	.login-icon-link {
		margin-left: 12px;
		text-decoration: none;
		
		strong {
			text-decoration: underline;
		}
	}
	
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow: hidden;
		
		li {
			display: inline;
			padding: 0;
			margin-left: -4px;
			
			&:first-child {
				margin-left: 0;
			}
			
			&.mobile-show {
				display: none;
			}
			
			a {
				display: inline-block;
			}
			
			a,
			.content-title {
				padding: 0 3px;
			}
			
			.content-title {
				font-weight: bold;
				
				.no-bold {
					font-weight: normal;
				}
			}
			
			&.extra-padding {
				padding-left: 20px;
			}
		}
		
		.subscribe-all-upvidsilike {
			cursor: pointer;
			vertical-align: text-top;
		}
	}
}


.animated-hamburger {
	cursor: pointer;
	display: block;
	height: 1em;
	position: relative;
	width: 1em;
	
	span {
		background: black;
		border-radius: 0.05em;
		display: block;
		height: 0.1em;
		left: 0.175em;
		position: absolute;
		top: 0.24em;
		transform-origin: center bottom;
		width: 0.65em;
	}
	
	.an-h-2,
	.an-h-3 {
		top: 0.44em;
		transform-origin: center;
	}
	
	.an-h-4 {
		transform-origin: center top;
		top: 0.64em;
	}
	
	&.opened {
		.an-h-1,
		.an-h-4 {
			height: 0.3125em;
			width: 0.1em;
			left: 0.44em;
			transform: scale(1, 0);
		}
		
		.an-h-4 {
			top: 0.44em;
		}
		
		.an-h-2 {
			transform: rotate(45deg);
		}
		
		.an-h-3 {
			transform: rotate(-45deg);
		}
		
	}
}

.language-switcher-popup {
	.x-popup-content {
		padding: 8px 0;
		margin-left: 30px;
		
		ul {
			margin: 0;
			padding: 0;
		}
		
		li {
			display: block;
		}
		
		a {
			display: block;
			font-size: 14px;
			font-weight: bold;
			padding: 6px 20px;
			text-decoration: none;
			white-space: nowrap;
			
			&:hover {
				background: @theme-color;
				color: #fff;
				text-decoration: none;
			}
		}
		
		.flag-small {
			margin-right: 5px;
		}
	}
}
