@import '../../pay_icons_sprite.less';

.premium-form-layout-v3 {
	#premium-popup-form,
	#premium-popup-signin {
		display: none;
	}
}




.x-overlay.x-overlay-box {
	&.premium-popup-form {
		&.premium-form-layout-v3 {
			.x-body {
				max-width: 650px;
				
				.x-close {
					color: #aaa;
					display: inline-block;
					font-size: 12px;
					right: 5px;
					top: 5px;
					text-shadow: none;
					
					.icon-f {
						display: block;
						padding: 5px;
					}
					
					&:hover {
						color: #fff;
					}
					
					&:active {
						transform: translateY(2px);
					}
				}
				
				
				#premium-popup-form {
					#premium-form {
						.form-buttons {
							clear: both;
						}
						
						.premium-plans {
							margin-bottom: 1rem;
						}
					}
					
					.premium-plans {
						display: flex;
						flex-wrap: wrap;
						margin: 15px -5px 20px;
						
						.col-xs-12,
						.col-xs-6,
						.col-xs-4,
						.col-xs-3 {
							margin-top: 12px;
							padding-left: 5px;
							padding-right: 5px;
						}
						
						&.offers-bytype {
							.col-bytype-6 {
								width: 50%;
							}
							.col-bytype-12 {
								width: 100%;
							}
							@media (max-width: @screen-xxs-max) {
								.col-bytype-6,
								.col-bytype-12 {
									width: 100%;
								}
							}
						}
						
						
						
						.plan {
							align-items: center;
							background: #000;
							border: 2px solid #585858;
							color: #a8a8a8;
							cursor: pointer;
							display: flex;
							flex-direction: column;
							justify-content: center;
							height: 100%;
							line-height: 1.2;
							padding: 0.5em;
							position: relative;
							
							input {
								display: none;
							}
							
							.icf-check-circle-thin {
								background: #fff;
								border-radius: 50%;
								color: #000;
								display: none;
								font-size: 1.5em;
								left: -0.25em;
								position: absolute;
								top: -0.25em;
							}
							
							&.premium-plan-hiddable {
								display: none;
							}
							
							&.selected {
								&.is-clicked {
									border-color: #fff;
									color: #fff;
								}
								
								.icf-check-circle-thin {
									display: inline-block;
								}
							}
							
							.label {
								display: block;
								font-size: 1.4rem;
								@media (max-width: 479px) {
									font-size: 1.2rem;
								}
								font-weight: 600;
								white-space: normal;
								
								sup {
									top: -.25em;
								}
								
								&.strong {
									font-size: 2.2rem;
									@media (max-width: 479px) {
										font-size: 1.8rem;
									}
									margin-top: .4rem;
								}
							}
							
							.details {
								font-size: 1em;
								@media (max-width: 479px) {
									font-size: .9rem;
								}
								
								&.strong {
									font-weight: bold;
									text-transform: uppercase;
								}
								
								sup {
									top: -.25em;
								}
							}
							
							.saving {
								background: @saving-tag-bg;
								border-radius: 0.25em;
								color: @saving-tag-color;
								font-size: 0.85em;
								font-weight: bold;
								line-height: 0.25em;
								padding: 0.5em .25em;
								position: absolute;
								right: -0.5em;
								top: -0.75em;
							}
							
							.pay-type {
								font-weight: bold;
								font-size: 102%;
								color: @premium-highlight-color;
							}
							
							.crypto-icons {
								position: absolute;
								top: 0;
								left: 0;
								
								font-size: 200%;
								
								width: 100%;
								height: 100%;
								display: flex;
								flex-wrap: wrap;
								justify-content: space-evenly;
								align-items: center;
								
								.icon-f {
									color: rgba(255, 255, 255, 0.192);
									background: none;
									display: inline-block;
									position: relative;
									width: 50px;
									height: 50px;
									transform: translate(38%, 13%);
								}
								
								.icf-crypto-eth {
									position: relative;
									
									.icon-f-multicolor {
										position: absolute;
										transform: translateX(-50%);
										
										&::before {
											color: rgba(255, 255, 255, 0.192);
											margin-left: 0;
										}
									}
								}
							}
						}
						
						&.premium-plans-one-offer {
							display: none;
							
							& ~ .payment-types,
							& ~ .see-other-payment-types {
								margin-top: 15px;
							}
						}
					}
					
					.crypto-plans {
						display: none;
						
						&.enabled {
							display: block;
						}
						
						margin-bottom: 10px;
						
						hr {
							margin-bottom: 9px;
						}
						
						.select-crypto-text {
							margin-bottom: 4px;
						}
						
						.select-crypto {
							display: flex;
							flex-wrap: wrap;
							
							.option-crypto {
								position: relative;
								
								display: flex;
								align-items: center;
								justify-content: center;
								// width dynamically computed
								
								margin: .5%;
								
								background: #000;
								border: 2px solid #585858;
								color: #a8a8a8;
								
								.crypto-name {
									font-size: 120%;
									font-weight: bold;
									color: white;
									padding-top: 4px;
									display: flex;
									align-items: center;
									
									.icon-f {
										font-size: 125%;
										position: relative;
										transform: translate(5px, -10%);
										
										.icon-f-multicolor {
											position: absolute;
											transform: translateY(-58%);
											
											&::before {
												color: white;
												margin-left: 0;
											}
										}
									}
								}
								
								&:hover {
									cursor: pointer;
									background: lighten(#000, 10%);
									border-color: lighten(#585858, 10%);
								}
								
								.selected-icon {
									background: #fff;
									border-radius: 50%;
									color: #000;
									display: none;
									font-size: 1.5em;
									left: -0.25em;
									position: absolute;
									top: -0.25em;
								}
								
								&.selected {
									background: #000;
									border-color: white;
									
									.selected-icon {
										display: inline-block;
									}
								}
							}
						}
					}
					
					
					.premium-popup-form-sub-header,
					.premium-popup-form-ppv-header,
					.premium-popup-form-membership-header {
						background: #222;
						border-radius: 4px;
						margin: 0 0 20px;
						padding: 10px 10px 25px;
						//overflow: hidden;
						position: relative;
						
						&:after {
							background: linear-gradient(to bottom, transparent, #000);
							bottom: 0;
							content: "";
							display: block;
							height: 25px;
							left: 0;
							position: absolute;
							width: 100%;
						}
						
						.v-img {
							.v-img-p {
								height: 0;
								padding: 0 0 56.25%;
								position: relative;
								width: 100%;
								
								.video-hd-mark {
									font-size: 12px;
									position: absolute;
									right: 2px;
									top: 2px;
									z-index: 2;
								}
							}
							
							img {
								left: 0;
								position: absolute;
								top: 0;
								width: 100%;
							}
							
							.cover-thumb {
								background: rgba(0, 0, 0, 0.1);
								height: 100%;
								left: 0;
								padding: 3%;
								position: absolute;
								top: 0;
								width: 100%;
								
								/*.video-gold {
									background: transparent url('/v3/img/skins/xnxx/gold/xnxx.gold.logo_b.png') 10% center no-repeat;
									background-size: auto 90%;
									height: 100%;
									left: 0;
									position: absolute;
									top: 0;
									width: 100%;
								}*/
							}
						}
						
						& + .form-group,
						& + form {
							margin: 0 auto 0.5em;
							
							&:not(.is-only-displayed) {
								margin-top: -45px;
							}
						}
					}
					
					.premium-popup-form-ppv-header {
						.v-img {
							margin: 0 auto;
							max-width: 200px;
							width: 80% !important;
						}
						
						/*.thumb-block {
							float: none;
							margin: 0 auto;
							max-width: 300px;
							padding: 0;
							width: 80%!important;
							
							p,
								//p.metadata,
							p.title a,
							p:first-child a,
							p:first-of-type a {
								color: #fff;
							}
							
							.thumb-under {
								min-height: auto;
								
							}
							
							.metadata {
								display: none;
							}
						}*/
					}
					
					.premium-popup-form-sub-header,
					.premium-popup-form-membership-header {
						text-align: left;
						
						img {
							width: 100%;
						}
						
						.banner {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							z-index: 1;
						}
						
						.main_pic {
							box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.5);
							left: 10px;
							max-width: 100px;
							position: absolute;
							width: 20%;
							z-index: 3;
						}
						
						.premium_main_pic {
							background: @premium-purchase-form-highlight-color;
							border-radius: 4px;
							box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.5);
							height: 0;
							left: 10px;
							max-width: 100px;
							padding-bottom: ~"min(20%, 100px)";
							position: absolute;
							width: 20%;
							z-index: 3;
							
							.premium-logo {
								left: 50%;
								position: absolute;
								top: 50%;
								transform: translate(-50%, -50%);
								width: auto;
							}
						}
						
						.included {
							box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
							max-width: 130px;
							overflow: hidden;
							position: absolute;
							right: 10px;
							width: 40%;
							z-index: 2;
							
							span {
								background: @btn-primary-bg;
								border-radius: 3px;
								box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
								bottom: 3px;
								color: @btn-primary-color;
								font-weight: bold;
								margin: 0;
								padding: 0 4px;
								position: absolute;
								right: 3px;
							}
						}
						
						.premium-info {
							font-size: 14px;
							padding-top: 6px;
							& + .premium-info {
								padding-top: 2px;
							}
						}
						
						.title {
							color: #fff;
							font-size: 24px;
							line-height: 28px;
							padding: 8px 0 4px;
							margin: 0;
						}
						
						&.with-included {
							min-height: 120px;
							padding-right: calc(~"min(130px, 40%) + 20px");
						}
						
						&.with-main-pic {
							min-height: 130px; // this has priority over  .with-included
							padding-bottom: 30px;
							padding-left: calc(~"min(100px, 20%) + 20px");
						}
					}
					
					.premium-plans + .count {
						font-size: 17px;
						margin: 10px 0;
						
						span {
							font-style: italic;
						}
					}
					
					.sep {
						margin: 15px 0;
						position: relative;
						
						&:before {
							background: #666;
							content: "";
							height: 1px;
							left: 0;
							top: 50%;
							position: absolute;
							width: 100%;
							z-index: 1;
						}
						
						span {
							background: #000;
							display: inline-block;
							padding: 0 6px;
							position: relative;
							text-transform: uppercase;
							z-index: 2;
						}
					}
					
					
					.included {
						margin: 20px 0 40px;
					}
					
					
					.detail-info {
						.detail-info-btn {
							background: #000;
							border: 0;
							border-radius: 50%;
							color: #fff;
							font-size: 22px;
							padding: 4px;
							position: absolute;
							right: -10px;
							top: -10px;
							z-index: 5;
							
							&:focus, &:active, &:active:focus {
								outline: none;
							}
						}
						
						.info {
							display: none;
						}
					}
					
					.premium-plans {
						.detail-info {
							.detail-info-btn {
								right: 5px;
								top: -15px;
							}
						}
					}
					
					.premium-plans-block {
						position: relative;
						
						.premium-plans {
							.detail-info {
								.detail-info-btn {
									right: -10px;
									top: -20px;
								}
							}
						}
					}
					
					.offer-title {
						font-size: 23px;
						font-weight: bold;
						margin: 0 0 10px;
						text-transform: uppercase;
						line-height: 75%;
						
						& + .premium-plans {
							margin-top: 5px;
						}
					}
				}
				
				
				#premium-popup-signin {
					.currency-switch-block {
						left: 0;
						line-height: 26px;
						position: absolute;
						top: 0;
						
						.x-options-container * {
							display: inline-block;
							line-height: 26px;
							vertical-align: middle;
						}
						
						.currency-switch-btn {
							background: #000;
							border: 1px solid #666;
							border-left: 0;
							border-top: 0;
							color: #ccc;
							
							&.popup-opened-btn {
								&, .icf-caret-down {
									color: #fff;
								}
							}
						}
						
						.icf-money {
							margin-right: 6px;
						}
						
						.icf-caret-down {
							color: #666;
							font-size: 0.7em;
						}
					}
					
					h3.premium-expired {
						align-items: center;
						display: flex;
						font-size: 1em;
						line-height: 1;
						font-weight: normal;
						margin: 0 auto 20px;
						max-width: 80%;
						text-align: center;
						
						.offer-title-framed {
							span {
								background: transparent;
								border-color: @premium-purchase-form-highlight-color;
								//color: @multiform-option-premium-color;
								margin-right: 16px;
							}
						}
					}
				}
				
				#arony-pay-form {
					button {
						margin: .5em 0;
					}
				}
				
				.see-other-payment-types ,
				.choose-payment-method ,
				.see-one-payment-type-by-offer {
					display: none;
				}
				
				.see-other-payment-types {
					& > span {
						display: block;
						font-size: 0.9em;
						
						.pay-type {
							color: @premium-highlight-color;
							font-weight: bold;
							
							.icon-f {
								display: inline-block;
								vertical-align: text-bottom;
							}
						}
					}
					button {
						background: none;
						border: 0;
						margin: 5px 0;
						text-decoration: underline;
					}
				}
				
				.need-other-payment-method {
					.button-variant(#fff, #999, #666);
				}
			}
			
			
			.currency-switch-pop {
				ul, li {
					list-style: none;
					margin: 0;
					padding: 0;
				}
				
				ul {
					column-width: 86px;
					overflow: auto;
					max-width: 86vw;
					width: 900px;
				}
				
				.btn {
					border: 0;
					background: transparent;
					color: #fff;
					display: block;
					font-size: 20px;
					font-weight: normal;
					padding: 6px 0;
					text-align: center;
					width: 100%;
					
					&:active {
						box-shadow: none;
					}
					
					&:focus {
						color: #fff;
					}
					
					&.is-current {
						background: rgba(255, 255, 255, 0.1);
						font-weight: bold;
					}
				}
			}
		}
	}
}



/* ==== max 479px = XVIDEOS ==== */
@media (max-width: @screen-xxs-max) {
	.x-overlay.x-overlay-box {
		&.premium-popup-form {
			&.premium-form-layout-v3 {
				.x-body {
					#premium-popup-form {
						.premium-plans {
							display: block;
							
							.plan {
								&.with-saving {
									.saving {
										border-radius: 0;
										border-bottom-left-radius: .25em;
										line-height: 2px;
										padding: 8px 2px 6px 4px;
										right: -2px;
										top: -2px;
									}
								}
							}
						}
						
						.premium-popup-form-sub-header,
						.premium-popup-form-membership-header {
							&.with-included {
								&.with-main-pic {
									padding-left: 10px;
									padding-right: 10px;
									
									.main_pic {
										width: 28%;
									}
									.premium_main_pic {
										padding-bottom: ~"min(28%, 100px)";
										width: 28%;
									}
								}
							}
						}
						.premium-popup-form-membership-header {
							&.with-included {
								&.with-main-pic {
									.title {
										padding-top: ~"calc(24% + 24px)";
									}
								}
							}
						}
						.premium-popup-form-sub-header {
							&.with-included {
								&.with-main-pic {
									.premium-info {
										padding-top: ~"calc(24% + 24px)";
										& + .premium-info {
											padding-top: 2px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}