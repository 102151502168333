#page.global-feed {
	.mobile-col {
		display: flex;
		flex-direction: row;
		@media (max-width: 768px) {
			flex-direction: column;
		}
		gap: 5px;
		max-width: 100%;
		align-items: stretch;
    	justify-content: space-around;
	}
	.no-subs {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		font-size: 14px;

		.tutorial-thumb {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.legend {
				font-size: 13px;
				text-align: center;
				color: #de2600;
				font-weight: bold;
			}

			img {
				margin-left: auto;
				margin-right: auto;
				border: 2px solid #de2600bb;
				cursor: pointer;
			}
		}

		.size-4 .tutorial-thumb {
			width: 25%;
		}
		.size-5 .tutorial-thumb {
			width: 20%;
		}
		@media (max-width: 768px) {
			.size-4 .tutorial-thumb,
			.size-5 .tutorial-thumb {
				width: 100%;
			}
		}
	}

	.sub-more-text {
		text-align: center;
		font-size: 20px;
		color: #de2600;
		font-weight: bold;
	}

	#bottom-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5px;

		.buttons {
			display: flex;
			justify-content: center;
			gap: 15px;
		}
	}

	.tutorial-section {
		font-size: 110%;
		background: @global-feed-tutorial-section-bg;
		padding: 20px;
		border-radius: 6px;
		margin: 10px;
		font-weight: 700;
	}

	#toggle-tracking {
		white-space: normal;
	}
}