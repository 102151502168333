
.ad-alert-badge {
	background: @theme-color;
	border-radius: 4px;
	color: #fff;
	font-size: 10px;
	font-weight: bold;
	left: 4px;
	padding: 1px 4px;
	position: absolute;
	text-transform: uppercase;
	top: 4px;
}

.remove-ads {
	a {
		line-height: 18px;
		text-decoration: none;
		white-space: nowrap;
		.remove-ads-link {
			text-decoration: underline;
		}
		.icon-f {
			color: @gray-dark;
			font-size: 14px;
			vertical-align: text-bottom;
			&:not(:first-child) {
				margin-left: 4px;
			}
		}
	}
}

#ad-header-mobile,
#ad-footer,
#ad-footer2,
#video-right,
#related-videos .thumb-block.video-ad {
	video,
	img {
		display: block;
	}
}

// Video page
#video-right {
	a {
		display: block;
		position: relative;
	}
	> a:first-child {
		margin-bottom: 16px;
	}
	
	.exo-ad-ins-div {
		position: relative;
	}
	.exo-ad-square {
		height: 250px;
		
		&:first-child {
			margin-bottom: 16px;
		}
	}
	.exo-ad-playersiderectangle {
		height: 500px;
		overflow: hidden;
	}
	
	.remove-ads {
		text-align: right;
		a {
			height: auto;
		}
	}
	
	& > *:not(:first-child):not(.remove-ads) {
		margin-bottom: 16px;
	}
	
	video,
	img/*,
	.exo-ad-ins-div video,
	.exo-ad-ins-div img*/ {
		bottom: 0;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
	video,
	img {
		max-height: 100%;
		
		&.resize_300250 {
			//transform: scaleY(0.88);
			transform: scaleY(0.8342);
			transform-origin: top;
		}
	}
	video {
		vertical-align: middle;
	}
	.remove-ads {
		position: absolute;
		bottom: -20px;
		right: 0;
		margin: 0;
	}
	&.ad-support-mobile {
		@media @media-ratio-vertical {
			display: none;
		}
	}
}

// Footer
#ad-footer,
#ad-footer2,
#related-videos .thumb-block.video-ad-support-tablet .thumb-inside,
#related-videos .thumb-block.video-ad-support-mobile .thumb-inside {
	clear: both;
	margin-top: 14px;
	
	.exo-ad-ins-div,
	& > a {
		display: inline-block;
		box-shadow: 0 0 7px @text-color;
		position: relative;
	}
	img,
	video {
		box-shadow: 0 0 0 1px @text-color;
		height: auto;
		max-width: 100%;
	}
	.exo-ad-ins-div {
		img,
		video {
			box-shadow: none;
		}
	}
	video {
		vertical-align: middle;
	}
}

#ad-footer,
#ad-footer2 {
	&.is-filled {
		height: 0;
		margin-left: auto;
		margin-right: auto;
		max-width: 480px;
		
		.is-desktop & {
			max-width: 900px;
			padding-bottom: 27.78%;
		
			& > * {
				display: block;
				max-width: 900px;
				width: 900px;
			}
		}
		
		&.ad-support-mobile,
		&.ad-support-tablet {
			padding-bottom: 33.33%;
			
			& > * {
				display: block;
				max-width: 300px;
				width: 300px;
			}
			
			& + .remove-ads {
				p {
					max-width: 476px;
				}
			}
		}
	}
}
@media (min-width: 900px + (2 * @body-out-padding)) {
	#ad-footer,
	#ad-footer2 {
		.is-desktop &.is-filled {
			padding-bottom: 250px;
		}
	}
}
@media (min-width: 300px + (2 * @body-out-padding)) {
	#ad-footer,
	#ad-footer2 {
		&.is-filled {
			&.ad-support-mobile,
			&.ad-support-tablet {
				padding-bottom: ~"min(33.33%, 160px)";
			}
		}
	}
}

#ad-footer {
	text-align: center;
	
	& + .remove-ads {
		margin-top: 4px;
		
		p {
			margin: 0 auto;
			max-width: 898px;
			overflow: hidden;
		}
	}
	
	&.ad-support-mobile + .remove-ads {
		p {
			max-width: 298px;
		}
	}
	
	&.ad-support-tablet + .remove-ads {
		p {
			max-width: 418px;
		}
	}
	
	&.with-related-ad {
		&,
		& + .remove-ads {
			&, * {
				display: none;
			}
		}
	}
}

#ad-footer2 {
	clear: both;
	margin: 6px 0;
	display: none;
	a {
		display: inline-block;
	}
	img,
	video {
		width: 100%;
	}
	video {
		vertical-align: middle;
	}
	.remove-ads {
		clear: both;
		margin: 4px 0 0;
		text-align: right;
		a {
			display: inline;
		}
	}
}

#full-video-link-band + #ad-footer2 {
	margin-top: 6px;
}


.video-ad-support-mobile-auto-height() {
	&.video-ad-support-mobile,
	&.video-ad-support-tablet {
		padding: 8px 10px 16px;
		.thumb-inside {
			height: auto;
			padding: 0;
			margin-bottom: 0;
		}
		img,
		video {
			max-width: none;
			width: 100%;
		}
	}
}
#related-videos {
	.mozaique {
		.thumb-block {
			&.video-ad-support-desktop {
				padding-bottom: @video-thumb-padding-bottom + 58px;
				
				.thumb-inside {
					.exo-ad-ins-div,
					& > a {
						left: 10px;
						position: absolute;
						right: 10px;
						top: 0;
					}
					
					& > a {
						video,
						img {
							height: auto;
							max-height: 100%;
							max-width: 100%;
						}
					}
				}
			}
			
			&.video-ad {
				.thumb-inside {
					.exo-ad-ins-div,
					& > a {
						box-shadow: none;
						max-width: 300px;
						width: 300px;
					}
					.exo-ad-playersiderectangle {
						max-height: 500px;
						overflow: hidden;
					}
				}
			}
			
			&.video-ad-support-mobile,
			&.video-ad-support-tablet {
				margin-top: 0;
				text-align: left;
				width: 100%;
				
				.thumb-inside {
					margin: 8px 0 0;
					padding: 0 15px 33.33%;
					
					a {
						display: inline-block;
						margin: 0;
						vertical-align: middle;
						
						img {
							display: block;
						}
					}
					
					.remove-ads {
						left: 0;
						position: absolute;
						right: 0;
						text-align: center;
						top: 100%;
						
						.pull-right {
							display: none;
						}
						
						a {
							box-shadow: none;
						}
					}
				}
			}
		}
	}
}

// Header mobile

#ad-header-mobile-contener {
	text-align: center;
}
#ad-header-mobile {
	display: block;
	height: 0;
	margin: 20px auto;
	max-width: 480px;
	overflow: hidden;
	padding: 0 0 33.33%;
	padding-bottom: ~"min(33.33%, 160px)";
	position: relative;
	text-align: left;
	
	&.is-filled {
		box-shadow: 0 0 5px fadeOut(@text-color, 60%);
	}
	
	.exo-ad-ins-div {
		max-width: 300px;
		width: 300px;
	}
	& > a {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
	}
}


#ad-footer.ad-footermobile + .remove-ads {
	max-width: 285px;
	margin: 2px auto 0;
	text-align: left;
}




/*.exo-ad-ins-div {
	position: relative;
	
	div {
		max-height: 100%!important;
		max-width: 100%!important;
		
		video,
		img {
			max-height: 100%;
			max-width: 100%;
		}
	}
	
	html:not(.is-desktop) #ad-footer &,
	#video-right &,
	#related-videos &,
	#ad-header-mobile & {
		& > div {
			display: block!important;
			margin: 0!important;
		}
	}
	
}*/

.thumb-block {
	&.thumb-nat-exo-ad {
		overflow: hidden;
		
		.mark {
			background: @red-color;
			border-radius: 3px;
			color: #fff;
			font-size: 10px;
			font-weight: bold;
			padding: 2px 4px;
			position: absolute;
			right: 5px;
			top: 5px;
			z-index: 5;
		}
		
		.thumb-inside {
			overflow: visible;
			z-index: 2;
			
			.thumb {
				overflow: visible;
				
				a {
					border: 0;
					
					iframe {
						border: 1px solid @thumb-block-thumb-border;
					}
				}
				.exo-native-widget-item-content {
					background-color: @body-bg !important;
					
					.exo-native-widget-item-title {
						&, &:hover {
							color: @text-color !important;
						}
					}

					.exo-native-widget-item-text,
					.exo-native-widget-item-brand {
						&, &:hover {
							color: @gray !important;
						}
					}
				}
			}
		}
		@media (max-width: @screen-all-mobile) {
			&,
			html:not(.nb-thumbs-cols-xs-2):not(.nb-thumbs-cols-xs-3) .mozaique.cust-nb-cols & {
				.thumb-under {
					min-height: 56px;
				}
			}
		}
	}
}

.exo-native-widget-item-container {
	.exo-native-widget-item-content {
		background-color: #fff !important;
		line-height: normal!important;
		
		.exo-native-widget-item-title {
			&, &:hover {
				color: #000 !important;
			}
		}
		
		.exo-native-widget-item-title {
			.line-clamp(2);
			// Override manyal <style> shipped with exo
			display: -webkit-box!important;
		}
		.exo-native-widget-item-text {
			.line-clamp(1);
			// Override manyal <style> shipped with exo
			display: -webkit-box!important;
		}
		
		.exo-native-widget-item-text,
		.exo-native-widget-item-brand {
			&, &:hover {
				color: @gray !important;
			}
		}
	}
}