#ad-profiles{
	margin: 0;
	.ad-profiles{
		display: block;
		margin: 20px 0 10px;
	}
}
#profile-search-form {
	background: @body-bg;
	overflow: hidden;
	margin: 6px 0;
	padding: 0;
	&.display-compact {
		.col-left {
			width: 100%;
		}
		.form-group {
			display: inline-block;
			clear: none;
			width: 33.33333%;
			margin-left: 0;
			margin-right: 0;
			&:first-child {
				width: 100%;
			}
		}
	}
	&.display-advanced {
		.col-create-account,
		.col-create-profile {
			display: none;
		}
	}
	&.display-compact,
	&.display-create-account,
	&.display-create-profile {
		.col-center,
		.col-right {
			display: none;
		}
	}
	#profile-search-filters-summary {
		overflow: hidden;
		clear: both;
		.btn-md {
			display: block;
			font-size: 14px;
		}
		#profile-search-result-filter {
			height: 30px;
			line-height: 28px;
			margin: 0 10px 0 0;
			padding: 0 12px;
			float: left;
		}
		ul {
			margin: 0;
			padding: 3px 0;
			font-size: 13px;
			li {
				padding: 0 0 3px;
				vertical-align: text-top;
			}
			.label {
				padding-right: 5px;
				color: @profsearch-block-label-color;
				font-weight: bold;
			}
			.value {
				color: @red-color;
				font-weight: bold;
				cursor: pointer;
			}
			.sep {
				color: @gray-light;
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
	&.expanded {
		background: @search-block-bg;
		padding: 8px;
		#profile-search-filters-summary {
			.btn-md,
			.btn-xs {
				display: block;
				margin-bottom: 4px;
			}
			ul {
				display: none;
			}
		}
		.label-contracted {
			display: none;
		}
		
		.row{
			max-height: 2000px;
			opacity: 1;
			overflow: hidden;
			padding-top: 10px;
		}
	}
	&.contracted {
		.row{
			max-height: 0;
			opacity: 0;
			overflow: hidden;
		}
		.label-expanded{
			display: none;
		}
	}
	.info-bordered {
		border: 1px solid @text-color;
		background: @text-invert-color;
		font-size: 15px;
		line-height: 22px;
		padding: 10px;
		ul {
			margin: 10px 0 20px;
		}
	}
	.control-label {
		font-size: 15px;
		font-weight: normal;
		text-align: left;
	}
	.form-group {
		margin-bottom: 3px;
		vertical-align: text-top;
	}
	.form-text {
		input {
			border: 2px solid @text-color;
		}
	}
	.form-select,
	.form-range-select {
		.control-label {
			padding-top: 2px;
		}
		select {
			height: 26px;
			padding: 3px 6px;
		}
	}
	.form-range-select select {
		display: inline;
		width: 45%;
	}
	.form-control {
		font-size: 14px;
	}
	.help-block {
		margin: 3px 0;
	}
	.form-actions {
		font-size: 15px;
		margin-top: 10px;
		.btn {
			font-size: 15px;
			a {
				text-decoration: underline;
			}
		}
		strong,
		.radio-inline {
			display: inline-block;
			margin: 0 2px 4px 0;
			padding: 7px 8px 7px 0;
			vertical-align: bottom;
		}
		.radio-inline {
			padding-left: 20px;
			input {
				margin-top: 2px;
			}
		}
		#profile-search-advanced{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}
}

#profile-search-result-count{
	display: block;
	float: left;
	font-size: 15px;
	height: 30px;
	line-height: 30px;
	padding: 0 40px 0 0;
}