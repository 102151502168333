@icomoon-font-family: "iconfont";
@icomoon-font-path: "fonts";

@import './iconfont_chars.less';

@font-v-YY: '24'; //YY - year
@font-v-MM: '12'; //MM - month
@font-v-DD: '10'; //DD - day
@font-v-HH: '14'; //HH - time : hours
@font-v-mm: '59'; //mm - time : minutes

@font-version: "@{font-v-YY}@{font-v-MM}@{font-v-DD}@{font-v-HH}@{font-v-mm}";
@icomoon-font-family: "iconfont";
@icomoon-font-path: '../../../../v-0@{font-version}/v3/fonts/skins/common/@{icomoon-font-family}/';
.font-family-iconfont() {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '@{icomoon-font-family}' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	
	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;
	
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: '@{icomoon-font-family}';
	src:  url('@{icomoon-font-path}/@{icomoon-font-family}.woff2')						format('woff2'),
	url('@{icomoon-font-path}/@{icomoon-font-family}.ttf')								format('truetype'),
	url('@{icomoon-font-path}/@{icomoon-font-family}.woff')								format('woff'),
	url('@{icomoon-font-path}/@{icomoon-font-family}.svg#@{icomoon-font-family}')		format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.icon-f {
	.font-family-iconfont();
}

/* === ICONS START === */
.icf-angle-double-down {
	&:before {
		content: @icf-angle-double-down;
	}
}
.icf-angle-double-left {
	&:before {
		content: @icf-angle-double-left;
	}
}
.icf-angle-double-right {
	&:before {
		content: @icf-angle-double-right;
	}
}
.icf-angle-double-up {
	&:before {
		content: @icf-angle-double-up;
	}
}
.icf-angle-down {
	&:before {
		content: @icf-angle-down;
	}
}
.icf-angle-down-circle {
	&:before {
		content: @icf-angle-down-circle;
	}
}
.icf-angle-down-circle-o {
	&:before {
		content: @icf-angle-down-circle-o;
	}
}
.icf-angle-left {
	&:before {
		content: @icf-angle-left;
	}
}
.icf-angle-left-circle {
	&:before {
		content: @icf-angle-left-circle;
	}
}
.icf-angle-left-circle-o {
	&:before {
		content: @icf-angle-left-circle-o;
	}
}
.icf-angle-right {
	&:before {
		content: @icf-angle-right;
	}
}
.icf-angle-right-circle {
	&:before {
		content: @icf-angle-right-circle;
	}
}
.icf-angle-right-circle-o {
	&:before {
		content: @icf-angle-right-circle-o;
	}
}
.icf-angle-up {
	&:before {
		content: @icf-angle-up;
	}
}
.icf-angle-up-circle {
	&:before {
		content: @icf-angle-up-circle;
	}
}
.icf-angle-up-circle-o {
	&:before {
		content: @icf-angle-up-circle-o;
	}
}
.icf-arrow-down {
	&:before {
		content: @icf-arrow-down;
	}
}
.icf-arrow-left {
	&:before {
		content: @icf-arrow-left;
	}
}
.icf-arrow-right {
	&:before {
		content: @icf-arrow-right;
	}
}
.icf-arrows {
	&:before {
		content: @icf-arrows;
	}
}
.icf-at {
	&:before {
		content: @icf-at;
	}
}
.icf-ban {
	&:before {
		content: @icf-ban;
	}
}
.icf-bar-chart {
	&:before {
		content: @icf-bar-chart;
	}
}
.icf-basket {
	&:before {
		content: @icf-basket;
	}
}
.icf-bell {
	&:before {
		content: @icf-bell;
	}
}
.icf-bell-slash {
	&:before {
		content: @icf-bell-slash;
	}
}
.icf-bolt {
	&:before {
		content: @icf-bolt;
	}
}
.icf-book {
	&:before {
		content: @icf-book;
	}
}
.icf-brand-apple {
	&:before {
		content: @icf-brand-apple;
	}
}
.icf-bricks {
	&:before {
		content: @icf-bricks;
	}
}
.icf-bricks-o {
	&:before {
		content: @icf-bricks-o;
	}
}
.icf-calendar {
	&:before {
		content: @icf-calendar;
	}
}
.icf-camera-o {
	&:before {
		content: @icf-camera-o;
	}
}
.icf-caret-down {
	&:before {
		content: @icf-caret-down;
	}
}
.icf-caret-right {
	&:before {
		content: @icf-caret-right;
	}
}
.icf-caret-up {
	&:before {
		content: @icf-caret-up;
	}
}
.icf-cart {
	&:before {
		content: @icf-cart;
	}
}
.icf-cart-o {
	&:before {
		content: @icf-cart-o;
	}
}
.icf-cash {
	&:before {
		content: @icf-cash;
	}
}
.icf-check {
	&:before {
		content: @icf-check;
	}
}
.icf-check-circle {
	&:before {
		content: @icf-check-circle;
	}
}
.icf-check-circle-o {
	&:before {
		content: @icf-check-circle-o;
	}
}
.icf-check-circle-o-thin {
	&:before {
		content: @icf-check-circle-o-thin;
	}
}
.icf-check-circle-thin {
	&:before {
		content: @icf-check-circle-thin;
	}
}
.icf-check-shield {
	&:before {
		content: @icf-check-shield;
	}
}
.icf-check-square {
	&:before {
		content: @icf-check-square;
	}
}
.icf-chevron-circle-down {
	&:before {
		content: @icf-chevron-circle-down;
	}
}
.icf-chevron-circle-left {
	&:before {
		content: @icf-chevron-circle-left;
	}
}
.icf-chevron-circle-right {
	&:before {
		content: @icf-chevron-circle-right;
	}
}
.icf-chevron-circle-up {
	&:before {
		content: @icf-chevron-circle-up;
	}
}
.icf-chevron-first {
	&:before {
		content: @icf-chevron-first;
	}
}
.icf-chevron-last {
	&:before {
		content: @icf-chevron-last;
	}
}
.icf-chevron-left {
	&:before {
		content: @icf-chevron-left;
	}
}
.icf-chevron-right {
	&:before {
		content: @icf-chevron-right;
	}
}
.icf-clock {
	&:before {
		content: @icf-clock;
	}
}
.icf-close {
	&:before {
		content: @icf-close;
	}
}
.icf-close-circle {
	&:before {
		content: @icf-close-circle;
	}
}
.icf-close-circle-o {
	&:before {
		content: @icf-close-circle-o;
	}
}
.icf-close-thin {
	&:before {
		content: @icf-close-thin;
	}
}
.icf-cog {
	&:before {
		content: @icf-cog;
	}
}
.icf-cog-o {
	&:before {
		content: @icf-cog-o;
	}
}
.icf-columns {
	&:before {
		content: @icf-columns;
	}
}
.icf-comment-o {
	&:before {
		content: @icf-comment-o;
	}
}
.icf-comment-plus-o {
	&:before {
		content: @icf-comment-plus-o;
	}
}
.icf-commenting-o {
	&:before {
		content: @icf-commenting-o;
	}
}
.icf-comments-o {
	&:before {
		content: @icf-comments-o;
	}
}
.icf-contract-o {
	&:before {
		content: @icf-contract-o;
	}
}
.icf-copy {
	&:before {
		content: @icf-copy;
	}
}
.icf-crown {
	&:before {
		content: @icf-crown;
	}
}
.icf-crypto-btc {
	&:before {
		content: @icf-crypto-btc;
	}
}
.icf-crypto-eth {
	&:before {
		content: @icf-crypto-eth;
	}
}
.icf-crypto-trx {
	&:before {
		content: @icf-crypto-trx;
	}
}
.icf-crypto-usdc {
	&:before {
		content: @icf-crypto-usdc;
	}
}
.icf-crypto-usdt {
	&:before {
		content: @icf-crypto-usdt;
	}
}
.icf-cut {
	&:before {
		content: @icf-cut;
	}
}
.icf-dashboard {
	&:before {
		content: @icf-dashboard;
	}
}
.icf-device-desktop {
	&:before {
		content: @icf-device-desktop;
	}
}
.icf-device-mobile {
	&:before {
		content: @icf-device-mobile;
	}
}
.icf-device-tablet {
	&:before {
		content: @icf-device-tablet;
	}
}
.icf-device-tv-v2 {
	&:before {
		content: @icf-device-tv-v2;
	}
}
.icf-diamond {
	&:before {
		content: @icf-diamond;
	}
}
.icf-download {
	&:before {
		content: @icf-download;
	}
}
.icf-drawer {
	&:before {
		content: @icf-drawer;
	}
}
.icf-earth-simple {
	&:before {
		content: @icf-earth-simple;
	}
}
.icf-ellipsis-v {
	&:before {
		content: @icf-ellipsis-v;
	}
}
.icf-embed {
	&:before {
		content: @icf-embed;
	}
}
.icf-envelop {
	&:before {
		content: @icf-envelop;
	}
}
.icf-external-link {
	&:before {
		content: @icf-external-link;
	}
}
.icf-eye {
	&:before {
		content: @icf-eye;
	}
}
.icf-eye-blocked {
	&:before {
		content: @icf-eye-blocked;
	}
}
.icf-favorites {
	&:before {
		content: @icf-favorites;
	}
}
.icf-favorites-add {
	&:before {
		content: @icf-favorites-add;
	}
}
.icf-feed {
	&:before {
		content: @icf-feed;
	}
}
.icf-feedback {
	&:before {
		content: @icf-feedback;
	}
}
.icf-file-broken {
	&:before {
		content: @icf-file-broken;
	}
}
.icf-file-image-o {
	&:before {
		content: @icf-file-image-o;
	}
}
.icf-file-movie-o {
	&:before {
		content: @icf-file-movie-o;
	}
}
.icf-file-slash-o {
	&:before {
		content: @icf-file-slash-o;
	}
}
.icf-filter {
	&:before {
		content: @icf-filter;
	}
}
.icf-flag {
	&:before {
		content: @icf-flag;
	}
}
.icf-fullscreen {
	&:before {
		content: @icf-fullscreen;
	}
}
.icf-fullscreen-back {
	&:before {
		content: @icf-fullscreen-back;
	}
}
.icf-gamepad {
	&:before {
		content: @icf-gamepad;
	}
}
.icf-gamepad-o {
	&:before {
		content: @icf-gamepad-o;
	}
}
.icf-gift {
	&:before {
		content: @icf-gift;
	}
}
.icf-google {
	&:before {
		content: @icf-google;
	}
}
.icf-grid-4 {
	&:before {
		content: @icf-grid-4;
	}
}
.icf-hand-pointer {
	&:before {
		content: @icf-hand-pointer;
	}
}
.icf-hand-pointer-o {
	&:before {
		content: @icf-hand-pointer-o;
	}
}
.icf-hd-4k-circle-o {
	&:before {
		content: @icf-hd-4k-circle-o;
	}
}
.icf-hd-4k-square {
	&:before {
		content: @icf-hd-4k-square;
	}
}
.icf-hd-circle-o {
	&:before {
		content: @icf-hd-circle-o;
	}
}
.icf-heart {
	&:before {
		content: @icf-heart;
	}
}
.icf-heart-circle-o {
	&:before {
		content: @icf-heart-circle-o;
	}
}
.icf-heart-o {
	&:before {
		content: @icf-heart-o;
	}
}
.icf-heartbeat-rectangle-o {
	&:before {
		content: @icf-heartbeat-rectangle-o;
	}
}
.icf-hearts-o {
	&:before {
		content: @icf-hearts-o;
	}
}
.icf-history {
	&:before {
		content: @icf-history;
	}
}
.icf-home {
	&:before {
		content: @icf-home;
	}
}
.icf-hour-glass {
	&:before {
		content: @icf-hour-glass;
	}
}
.icf-id-card-o {
	&:before {
		content: @icf-id-card-o;
	}
}
.icf-image {
	&:before {
		content: @icf-image;
	}
}
.icf-images {
	&:before {
		content: @icf-images;
	}
}
.icf-info-circle {
	&:before {
		content: @icf-info-circle;
	}
}
.icf-info-slash-circle-o {
	&:before {
		content: @icf-info-slash-circle-o;
	}
}
.icf-joystick {
	&:before {
		content: @icf-joystick;
	}
}
.icf-key {
	&:before {
		content: @icf-key;
	}
}
.icf-language {
	&:before {
		content: @icf-language;
	}
}
.icf-life-saver {
	&:before {
		content: @icf-life-saver;
	}
}
.icf-line-chart {
	&:before {
		content: @icf-line-chart;
	}
}
.icf-link {
	&:before {
		content: @icf-link;
	}
}
.icf-list-ul {
	&:before {
		content: @icf-list-ul;
	}
}
.icf-lock {
	&:before {
		content: @icf-lock;
	}
}
.icf-mail-forward {
	&:before {
		content: @icf-mail-forward;
	}
}
.icf-mail-forward-all {
	&:before {
		content: @icf-mail-forward-all;
	}
}
.icf-mail-reply {
	&:before {
		content: @icf-mail-reply;
	}
}
.icf-mail-reply-all {
	&:before {
		content: @icf-mail-reply-all;
	}
}
.icf-map-marker {
	&:before {
		content: @icf-map-marker;
	}
}
.icf-medal {
	&:before {
		content: @icf-medal;
	}
}
.icf-messages {
	&:before {
		content: @icf-messages;
	}
}
.icf-minus-circle {
	&:before {
		content: @icf-minus-circle;
	}
}
.icf-minus-square {
	&:before {
		content: @icf-minus-square;
	}
}
.icf-money {
	&:before {
		content: @icf-money;
	}
}
.icf-network {
	&:before {
		content: @icf-network;
	}
}
.icf-network-xv-o {
	&:before {
		content: @icf-network-xv-o;
	}
}
.icf-no-ads-circle-fat-o {
	&:before {
		content: @icf-no-ads-circle-fat-o;
	}
}
.icf-no-ads-circle-o {
	&:before {
		content: @icf-no-ads-circle-o;
	}
}
.icf-paint-brush {
	&:before {
		content: @icf-paint-brush;
	}
}
.icf-paste {
	&:before {
		content: @icf-paste;
	}
}
.icf-pay-card {
	&:before {
		content: @icf-pay-card;
	}
}
.icf-pay-crypto {
	&:before {
		content: @icf-pay-crypto;
	}
}
.icf-pay-mastercard {
	&:before {
		content: @icf-pay-mastercard;
	}
}
.icf-pay-paybybank {
	&:before {
		content: @icf-pay-paybybank;
	}
}
.icf-pay-pix {
	&:before {
		content: @icf-pay-pix;
	}
}
.icf-pay-sepa {
	&:before {
		content: @icf-pay-sepa;
	}
}
.icf-pay-visa {
	&:before {
		content: @icf-pay-visa;
	}
}
.icf-pencil {
	&:before {
		content: @icf-pencil;
	}
}
.icf-pencil-square-o {
	&:before {
		content: @icf-pencil-square-o;
	}
}
.icf-photo {
	&:before {
		content: @icf-photo;
	}
}
.icf-pin {
	&:before {
		content: @icf-pin;
	}
}
.icf-play {
	&:before {
		content: @icf-play;
	}
}
.icf-play-bolt {
	&:before {
		content: @icf-play-bolt;
	}
}
.icf-play-clap-o {
	&:before {
		content: @icf-play-clap-o;
	}
}
.icf-play-rectangle {
	&:before {
		content: @icf-play-rectangle;
	}
}
.icf-play-rectangle-o {
	&:before {
		content: @icf-play-rectangle-o;
	}
}
.icf-play-square-film {
	&:before {
		content: @icf-play-square-film;
	}
}
.icf-play-square-o {
	&:before {
		content: @icf-play-square-o;
	}
}
.icf-player-pause-circle-o {
	&:before {
		content: @icf-player-pause-circle-o;
	}
}
.icf-player-play-circle-o {
	&:before {
		content: @icf-player-play-circle-o;
	}
}
.icf-player-stop-circle-o {
	&:before {
		content: @icf-player-stop-circle-o;
	}
}
.icf-playlist {
	&:before {
		content: @icf-playlist;
	}
}
.icf-plus {
	&:before {
		content: @icf-plus;
	}
}
.icf-plus-circle {
	&:before {
		content: @icf-plus-circle;
	}
}
.icf-plus-square {
	&:before {
		content: @icf-plus-square;
	}
}
.icf-question-circle-o {
	&:before {
		content: @icf-question-circle-o;
	}
}
.icf-quotes-left {
	&:before {
		content: @icf-quotes-left;
	}
}
.icf-quotes-right {
	&:before {
		content: @icf-quotes-right;
	}
}
.icf-random {
	&:before {
		content: @icf-random;
	}
}
.icf-reddit {
	&:before {
		content: @icf-reddit;
	}
}
.icf-refresh {
	&:before {
		content: @icf-refresh;
	}
}
.icf-refresh-circle {
	&:before {
		content: @icf-refresh-circle;
	}
}
.icf-resolution {
	&:before {
		content: @icf-resolution;
	}
}
.icf-rocket {
	&:before {
		content: @icf-rocket;
	}
}
.icf-rotate-left {
	&:before {
		content: @icf-rotate-left;
	}
}
.icf-rotate-right {
	&:before {
		content: @icf-rotate-right;
	}
}
.icf-save {
	&:before {
		content: @icf-save;
	}
}
.icf-screen-both-orientation {
	&:before {
		content: @icf-screen-both-orientation;
	}
}
.icf-screen-rotation {
	&:before {
		content: @icf-screen-rotation;
	}
}
.icf-search {
	&:before {
		content: @icf-search;
	}
}
.icf-search-minus {
	&:before {
		content: @icf-search-minus;
	}
}
.icf-search-plus {
	&:before {
		content: @icf-search-plus;
	}
}
.icf-search-v2 {
	&:before {
		content: @icf-search-v2;
	}
}
.icf-send-o {
	&:before {
		content: @icf-send-o;
	}
}
.icf-sexe-man {
	&:before {
		content: @icf-sexe-man;
	}
}
.icf-sexe-man-v2 {
	&:before {
		content: @icf-sexe-man-v2;
	}
}
.icf-sexe-straight {
	&:before {
		content: @icf-sexe-straight;
	}
}
.icf-sexe-trans {
	&:before {
		content: @icf-sexe-trans;
	}
}
.icf-sexe-trans-v2 {
	&:before {
		content: @icf-sexe-trans-v2;
	}
}
.icf-sexe-woman {
	&:before {
		content: @icf-sexe-woman;
	}
}
.icf-sexe-woman-v2 {
	&:before {
		content: @icf-sexe-woman-v2;
	}
}
.icf-share {
	&:before {
		content: @icf-share;
	}
}
.icf-sheer-circle {
	&:before {
		content: @icf-sheer-circle;
	}
}
.icf-shrink {
	&:before {
		content: @icf-shrink;
	}
}
.icf-sign-in {
	&:before {
		content: @icf-sign-in;
	}
}
.icf-sign-out {
	&:before {
		content: @icf-sign-out;
	}
}
.icf-size {
	&:before {
		content: @icf-size;
	}
}
.icf-sliders {
	&:before {
		content: @icf-sliders;
	}
}
.icf-sort-alpha-asc {
	&:before {
		content: @icf-sort-alpha-asc;
	}
}
.icf-sort-alpha-desc {
	&:before {
		content: @icf-sort-alpha-desc;
	}
}
.icf-sort-amount-asc {
	&:before {
		content: @icf-sort-amount-asc;
	}
}
.icf-sort-amount-desc {
	&:before {
		content: @icf-sort-amount-desc;
	}
}
.icf-sound {
	&:before {
		content: @icf-sound;
	}
}
.icf-sound-off {
	&:before {
		content: @icf-sound-off;
	}
}
.icf-spinner {
	&:before {
		content: @icf-spinner;
	}
}
.icf-square {
	&:before {
		content: @icf-square;
	}
}
.icf-star-circle-o {
	&:before {
		content: @icf-star-circle-o;
	}
}
.icf-star-full {
	&:before {
		content: @icf-star-full;
	}
}
.icf-star-o {
	&:before {
		content: @icf-star-o;
	}
}
.icf-stopwatch {
	&:before {
		content: @icf-stopwatch;
	}
}
.icf-stretch {
	&:before {
		content: @icf-stretch;
	}
}
.icf-suggest-square {
	&:before {
		content: @icf-suggest-square;
	}
}
.icf-swap {
	&:before {
		content: @icf-swap;
	}
}
.icf-tags {
	&:before {
		content: @icf-tags;
	}
}
.icf-theme-switch {
	&:before {
		content: @icf-theme-switch;
	}
}
.icf-theme-switch-v2 {
	&:before {
		content: @icf-theme-switch-v2;
	}
}
.icf-thumb-down {
	&:before {
		content: @icf-thumb-down;
	}
}
.icf-thumb-down-o {
	&:before {
		content: @icf-thumb-down-o;
	}
}
.icf-thumb-up {
	&:before {
		content: @icf-thumb-up;
	}
}
.icf-thumb-up-o {
	&:before {
		content: @icf-thumb-up-o;
	}
}
.icf-ticket {
	&:before {
		content: @icf-ticket;
	}
}
.icf-ticket-red {
	&:before {
		content: @icf-ticket-red;
	}
}
.icf-ticket-wide {
	&:before {
		content: @icf-ticket-wide;
	}
}
.icf-ticket-wide-n {
	&:before {
		content: @icf-ticket-wide-n;
	}
}
.icf-ticket-wide-o {
	&:before {
		content: @icf-ticket-wide-o;
	}
}
.icf-toggle-off {
	&:before {
		content: @icf-toggle-off;
	}
}
.icf-trash {
	&:before {
		content: @icf-trash;
	}
}
.icf-twitter {
	&:before {
		content: @icf-twitter;
	}
}
.icf-unlock {
	&:before {
		content: @icf-unlock;
	}
}
.icf-upload {
	&:before {
		content: @icf-upload;
	}
}
.icf-user {
	&:before {
		content: @icf-user;
	}
}
.icf-user-ban {
	&:before {
		content: @icf-user-ban;
	}
}
.icf-user-check {
	&:before {
		content: @icf-user-check;
	}
}
.icf-user-hourglass {
	&:before {
		content: @icf-user-hourglass;
	}
}
.icf-user-lock {
	&:before {
		content: @icf-user-lock;
	}
}
.icf-user-o {
	&:before {
		content: @icf-user-o;
	}
}
.icf-user-plus {
	&:before {
		content: @icf-user-plus;
	}
}
.icf-user-times {
	&:before {
		content: @icf-user-times;
	}
}
.icf-user-trash {
	&:before {
		content: @icf-user-trash;
	}
}
.icf-users {
	&:before {
		content: @icf-users;
	}
}
.icf-users-o {
	&:before {
		content: @icf-users-o;
	}
}
.icf-video-camera {
	&:before {
		content: @icf-video-camera;
	}
}
.icf-video-camera-check {
	&:before {
		content: @icf-video-camera-check;
	}
}
.icf-video-camera-o {
	&:before {
		content: @icf-video-camera-o;
	}
}
.icf-warning {
	&:before {
		content: @icf-warning;
	}
}
.icf-warning-circle {
	&:before {
		content: @icf-warning-circle;
	}
}
.icf-warning-stop {
	&:before {
		content: @icf-warning-stop;
	}
}
.icf-woman {
	&:before {
		content: @icf-woman;
	}
}
.icf-wrench {
	&:before {
		content: @icf-wrench;
	}
}
.icf-x {
	&:before {
		content: @icf-x;
	}
}
.icf-x-twitter {
	&:before {
		content: @icf-x-twitter;
	}
}
.icf-xcams {
	&:before {
		content: @icf-xcams;
	}
}

/* === ICONS END === */

// Custom icon mix
// -------------------------
.icf-device-mobile-share {
	display: inline-block;
	height: 1em;
	position: relative;
	text-align: center;
	width: 1em;
	
	&:before {
		content: @icf-device-mobile;
	}
	
	&:after {
		content: @icf-share;
		font-size: 0.4em;
		left: 0;
		line-height: 2.5em;
		top: -0.16em;
		width: 2.5em;
		position: absolute;
	}
}


// Icon Sizes
// -------------------------
/* makes the font 33% larger relative to the icon container */
.icf-lg {
	font-size: (4em / 3);
	line-height: (3em / 4);
	vertical-align: -15%;
}
.icf-2x { font-size: 2em; }
.icf-3x { font-size: 3em; }
.icf-4x { font-size: 4em; }
.icf-5x { font-size: 5em; }

// Fixed Width Icons
// -------------------------
.icf-fw {
	display: inline-block;
	text-align: center;
	width: (18em / 14);
}

// Animated Icons
// --------------------------
.icf-anim-spin {
	&:before {
		-webkit-animation: icf_spin_anim 2s infinite linear;
		animation: icf_spin_anim 2s infinite linear;
		display: inline-block;
	}
}

.icf-anim-pulse {
	&:before {
		-webkit-animation: icf_spin_anim 1s infinite steps(8);
		animation: icf_spin_anim 1s infinite steps(8);
		display: inline-block;
	}
}

@-webkit-keyframes icf_spin_anim {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes icf_spin_anim {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

// White Background
// -------------------------
.icf-white-fill {
	position: relative;
	
	&:before {
		position: relative;
		z-index: 2;
	}
	
	&:after {
		background: white;
		bottom: 12%;
		content: " ";
		left: 12%;
		position: absolute;
		right: 12%;
		top: 12%;
		z-index: 1;
	}
	
	&.icf-angle-down-circle,
	&.icf-angle-down-circle-o,
	&.icf-angle-left-circle,
	&.icf-angle-left-circle-o,
	&.icf-angle-right-circle,
	&.icf-angle-right-circle-o,
	&.icf-angle-up-circle,
	&.icf-angle-up-circle-o,
	&.icf-minus-circle,
	&.icf-plus-circle,
	&.icf-info-circle,
	&.icf-warning-circle,
	&.icf-warning-stop,
	&.icf-close-circle,
	&.icf-check-circle,
	&.icf-chevron-circle-left,
	&.icf-chevron-circle-right,
	&.icf-chevron-circle-up,
	&.icf-reddit {
		&:after {
			border-radius: 50%;
		}
	}
	
	&.icf-warning-stop {
		&:after {
			bottom: 20%;
			left: 20%;
			right: 20%;
			top: 20%;
		}
	}
	
	&.icf-ticket-red {
		&:after {
			bottom: 27%;
			left: 14%;
			right: 14%;
			top: 24%;
		}
	}
	
	&.icf-envelop {
		&:after {
			left: 7%;
			right: 7%;
		}
	}
}

.icf-white-fill-ticket-red {
	&:after {
		bottom: 12%;
		left: 12%;
		right: 12%;
		top: 12%;
	}
}

// SEPA icon fix
// -------------------------
.icf-pay-sepa-crop-empty {
	&:before {
		font-size: 3.3em;
		vertical-align: middle;
	}
	display: contents;
    line-height: 1em;
    height: 1em;
}

// Red slash through it
// -------------------------
.icf-red-crossed {
	position: relative;
	
	&:before {
		position: relative;
		z-index: 2;
	}
	
	&:after {
		background: #c52200;
		border-radius: .1em;
		content: " ";
		height: .1em;
		left: 50%;
		margin: -.05em -.8em;
		position: absolute;
		top: 50%;
		transform: rotate(35deg);
		width: 1.6em;
		z-index: 3;
	}
}

body .alert-with-icf-bg {
	display: block;
	overflow: hidden;
	min-height: 60px;
	padding: 15px 20px 15px 70px;
	position: relative;
	
	& > *:first-child {
		margin-top: 0;
	}
	
	.alert-icf-bg {
		font-size: 65px;
		left: -10px;
		opacity: 0.2;
		position: absolute;
		top: -10px;
	}
	
	@media (max-width: @screen-sm-max) {
		padding: 10px 20px 10px 64px;
	}
}

.icon-full-screen {
	vertical-align: middle;
	box-sizing: border-box;
	display: inline-block;
	width: 1em;
	height: 1em;
	position: relative;
	
	@fs_icn_border: 0.1em;
	@fs_icn_side_pad: 0.05em;
	@fs_icn_hover_move: 0.05em;
	@fs_icn_hover_move_dur: 0.25s;
	@fs_icn_corner_size: 0.3em;
	
	@keyframes anim_fs_icn_top_left {
		0% {top: @fs_icn_side_pad;left: @fs_icn_side_pad;}
		40% {top: @fs_icn_side_pad - @fs_icn_hover_move;left: @fs_icn_side_pad - @fs_icn_hover_move;}
		60% {top: @fs_icn_side_pad - @fs_icn_hover_move;left: @fs_icn_side_pad - @fs_icn_hover_move;}
		100% {top: @fs_icn_side_pad;left: @fs_icn_side_pad;}
	}
	@keyframes anim_fs_icn_top_right {
		0% {top: @fs_icn_side_pad;right: @fs_icn_side_pad;}
		40% {top: @fs_icn_side_pad - @fs_icn_hover_move;right: @fs_icn_side_pad - @fs_icn_hover_move;}
		60% {top: @fs_icn_side_pad - @fs_icn_hover_move;right: @fs_icn_side_pad - @fs_icn_hover_move;}
		100% {top: @fs_icn_side_pad;right: @fs_icn_side_pad;}
	}
	@keyframes anim_fs_icn_bottom_left {
		0% {bottom: @fs_icn_side_pad;left: @fs_icn_side_pad;}
		40% {bottom: @fs_icn_side_pad - @fs_icn_hover_move;left: @fs_icn_side_pad - @fs_icn_hover_move;}
		60% {bottom: @fs_icn_side_pad - @fs_icn_hover_move;left: @fs_icn_side_pad - @fs_icn_hover_move;}
		100% {bottom: @fs_icn_side_pad;left: @fs_icn_side_pad;}
	}
	@keyframes anim_fs_icn_bottom_right {
		0% {bottom: @fs_icn_side_pad;right: @fs_icn_side_pad;}
		40% {bottom: @fs_icn_side_pad - @fs_icn_hover_move;right: @fs_icn_side_pad - @fs_icn_hover_move;}
		60% {bottom: @fs_icn_side_pad - @fs_icn_hover_move;right: @fs_icn_side_pad - @fs_icn_hover_move;}
		100% {bottom: @fs_icn_side_pad;right: @fs_icn_side_pad;}
	}
	
	@keyframes anim_fs_icn_exit_top_left {
		0% {top: @fs_icn_side_pad;left: @fs_icn_side_pad;}
		40% {top: @fs_icn_side_pad + @fs_icn_hover_move;left: @fs_icn_side_pad + @fs_icn_hover_move;}
		60% {top: @fs_icn_side_pad + @fs_icn_hover_move;left: @fs_icn_side_pad + @fs_icn_hover_move;}
		100% {top: @fs_icn_side_pad;left: @fs_icn_side_pad;}
	}
	@keyframes anim_fs_icn_exit_top_right {
		0% {top: @fs_icn_side_pad;right: @fs_icn_side_pad;}
		40% {top: @fs_icn_side_pad + @fs_icn_hover_move;right: @fs_icn_side_pad + @fs_icn_hover_move;}
		60% {top: @fs_icn_side_pad + @fs_icn_hover_move;right: @fs_icn_side_pad + @fs_icn_hover_move;}
		100% {top: @fs_icn_side_pad;right: @fs_icn_side_pad;}
	}
	@keyframes anim_fs_icn_exit_bottom_left {
		0% {bottom: @fs_icn_side_pad;left: @fs_icn_side_pad;}
		40% {bottom: @fs_icn_side_pad + @fs_icn_hover_move;left: @fs_icn_side_pad + @fs_icn_hover_move;}
		60% {bottom: @fs_icn_side_pad + @fs_icn_hover_move;left: @fs_icn_side_pad + @fs_icn_hover_move;}
		100% {bottom: @fs_icn_side_pad;left: @fs_icn_side_pad;}
	}
	@keyframes anim_fs_icn_exit_bottom_right {
		0% {bottom: @fs_icn_side_pad;right: @fs_icn_side_pad;}
		40% {bottom: @fs_icn_side_pad + @fs_icn_hover_move;right: @fs_icn_side_pad + @fs_icn_hover_move;}
		60% {bottom: @fs_icn_side_pad + @fs_icn_hover_move;right: @fs_icn_side_pad + @fs_icn_hover_move;}
		100% {bottom: @fs_icn_side_pad;right: @fs_icn_side_pad;}
	}
	
	span {
		height: 0.5em;
		left: 0;
		position: absolute;
		right: 0;
		width: 1em;
		
		&:before,
		&:after {
			border-color: white;
			border-color: currentColor;
			border-style: solid;
			border-width: 0;
			content: "";
			display: inline-block;
			height: @fs_icn_corner_size;
			position: absolute;
			width: @fs_icn_corner_size;
		}
		
		//left
		&:before {
			left: @fs_icn_side_pad;
			border-left-width: @fs_icn_border;
		}
		//right
		&:after {
			right: @fs_icn_side_pad;
			border-right-width: @fs_icn_border;
		}
		
		//top
		&:first-child {
			top: 0;
			
			&:before,
			&:after {
				border-top-width: @fs_icn_border;
				top: @fs_icn_side_pad;
			}
		}
		
		//bottom
		&:last-child {
			bottom: 0;
			
			&:before,
			&:after {
				border-bottom-width: @fs_icn_border;
				bottom: @fs_icn_side_pad;
			}
		}
	}
	
	.notouch &:hover span {
		&:first-child {
			//top left
			&:before {
				animation: anim_fs_icn_top_left @fs_icn_hover_move_dur ease-in-out;
			}
			
			//top right
			&:after {
				animation: anim_fs_icn_top_right @fs_icn_hover_move_dur ease-in-out;
			}
		}
		
		&:last-child {
			//bottom left
			&:before {
				animation: anim_fs_icn_bottom_left @fs_icn_hover_move_dur ease-in-out;
			}
			
			//bottom right
			&:after {
				animation: anim_fs_icn_bottom_right @fs_icn_hover_move_dur ease-in-out;
			}
		}
	}
	
	.notouch:fullscreen &:hover span,
	.notouch *:fullscreen &:hover span {
		&:first-child {
			//top left
			&:before {
				animation: anim_fs_icn_exit_top_left @fs_icn_hover_move_dur ease-in-out;
			}
			
			//top right
			&:after {
				animation: anim_fs_icn_exit_top_right @fs_icn_hover_move_dur ease-in-out;
			}
		}
		
		&:last-child {
			//bottom left
			&:before {
				animation: anim_fs_icn_exit_bottom_left @fs_icn_hover_move_dur ease-in-out;
			}
			
			//bottom right
			&:after {
				animation: anim_fs_icn_exit_bottom_right @fs_icn_hover_move_dur ease-in-out;
			}
		}
		
	}
	
}
