
.xv-label-mixin(@video-hd-mark-height) {
	font-size: round(@video-hd-mark-height * 6 / 9);
	line-height: @video-hd-mark-height;
	height: @video-hd-mark-height;
	padding: 0 round(@video-hd-mark-height / 4);
}

html {
	font-size: 1rem;
}

@import "elements/jquery-ui";
@import "elements/type";
@import "elements/lists";
@import "elements/tabs";
@import "elements/pagination";
@import "elements/popup";
@import "../../common/x-messages";
@import "elements/messages-bottom";
@import "elements/overlay";
//@import "elements/infobox";
@import "elements/forms";
@import "elements/viewer";
@import "elements/thumb-ad-red.less";
@import "elements/dragdrop";
@import "elements/share.less";
@import "elements/quickies";
@import "elements/search_filters.less";

@import "../../common/download_app";
@import "../../common/scrollbar";
@import "../../common/scroll_buttons";
@import "elements/download_app";
@import "elements/big_ui";
@import "elements/search_box.less";

.rotate90 {
	transform: rotate(-90deg);
}

.navbadge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 4px;
	font-size: 10px;
	font-weight: 700;
	color: #fff;
	line-height: 1;
	vertical-align: middle;
	white-space: nowrap;
	text-align: center;
	background: @theme-color;
	border-radius: 8px;
	&.default {
		color: @gray-light;
		background: @gray-lighter;
	}
	&.white {
		color: @text-color;
		background: #fff;
	}
}

.labels-list {
	padding: 4px 0;
	position: relative;
	line-height: 2;
	a,
	.label {
		background: @gray-bg;
		padding: 3px 7px;
		text-decoration: none;
		white-space: nowrap;
	}
	.label {
		background: @gray;
		color: #fff;
		font-weight: bold;
	}
	a {
		&:hover {
			background: @gray-light2;
			color: #fff;
		}
		&.highlighted {
			background: @theme-color;
			color: #fff;
			font-weight: bold;
			&:hover {
				background: darken(@theme-color, 5%);
			}
		}
		&.view-more {
			cursor: pointer;
			font-weight: bold;
		}
	}
	&.one-line {
		white-space: nowrap;
		overflow: hidden;
	}
	&.two-lines {
		height: 52px;
		overflow: hidden;
	}
}

#x-red-home-messages,
#x-ajax-tab-messages {
	.x-message {
		background: @text-invert-color;
		border: 1px solid @gray-light2;
		color: @text-color;
		.del .icon-f {
			color: @text-color;
		}
		&.x-message-warning{
			background: @theme-color;
			border-color: @theme-color;
			color: #fff;
			.content a,
			.del .icon-f {
				color: #fff;
			}
		}
	}
}
#x-fda-messages,
#x-home-messages {
	.x-message {
		background: @theme-color;
		border: 1px solid @theme-color;
		border-radius: 5px;
	}
}
#x-home-messages {
	float: left;
	width: 100%;
}

.highlight {
	color: @red-color;
}
