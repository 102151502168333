.model-list-container {
    flex: 1 1 100%;
    z-index: 3;
    position: relative;

    #models_list {
        background: @body-bg;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 3;
        display: none;

        .super-container {
            a, a * {
                cursor: pointer;
            }
            
            .container-model-info-form,
            .container-list-models-suggestion {
                margin-top: 0;
                background: @body-bg;
            }
            
        }
    }
    .container-model-info-form {
        display: none;
        overflow: hidden;
        position: relative;

        .overlay {
            align-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.7);
            bottom: 0;
            opacity: 0;
            display: none;
            flex-wrap: wrap;
            justify-content: center;
            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            z-index: 5;

            .icon-f {
                font-size: 80px;
                flex: 1 1 100%;

                &.icf-hour-glass {
                    font-size: 50px;
                    margin-bottom: 10px;
                }
            }
        }

        textarea[name=who_are_they] {
            height: 85px;
            resize: none;
            overflow: hidden;
        }

        input[type=number] {
            max-width: 70px;
        }

        .error-name.alert.alert-danger,
        .error-age.alert.alert-danger,
        .error-who_are_they.alert.alert-danger,
        .new-model-error-global.alert.alert-danger,
        .add-model-error-global.alert.alert-danger {
            background: inherit;
            border: none;
        }

        label {
            margin: 15px 0;
        }
    }

    .container-list-models-suggestion {
        .mozaique {
            max-height: 350px;
            overflow-y: auto;

            .card {
                cursor: pointer;
            }
        }
    }
}
#suggestions_models_list,
#models_list .mozaique {
    .line-through > .icf-ban {
        display: block;
    }

    .card {
        margin: 0;
        padding: 5px;

        .card-img {
            background: @body-bg;
            .overlay-container {
                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }

                background-position: center !important;
                background-repeat: no-repeat !important;
                background-size: contain !important;
                //height: 4vw;
				height: 60px;
                position: relative;

                .overlay2 {
                    position: relative;
                    justify-content: center;
                    text-align: center;
                    font-size: 3rem;
                    width: 100%;
                    height: 100%;

                    span.icon-f.icf-ban {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .overlay {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: flex;
                    justify-content: flex-end;

                    .icf-ban {
                        color: @text-color;
                        font-size: 6rem;
                        left: 0;
                        position: absolute;
                        right: 0;
                        text-shadow: 1px 1px @text-color;
                        top: 20%;
                    }

                    .metadata-remove {
                        background-color: @btn-primary-bg;
                        border-radius: 0;
                        color: @text-color;
                        cursor: pointer;
                        font-size: 15px;
                        font-weight: bolder;
                        height: 25px;
                        padding-top: 5px;
                        text-align: center;
                        width: 25px;
                        z-index: 1;
                        @media screen and (min-width: 1024px) {
                            &:hover {
                                background-color: @text-color;
                                color: @btn-primary-bg;
                            }
                        }
                    }
                }
            }

            //@media screen and (max-width: 1024px) {
            //    .overlay-container {
            //        height: 22vw;
            //    }
            //}
        }

        .card-footer {
            .name {
                font-weight: bold;
                height: 21px;
                letter-spacing: 0;
                overflow: hidden;
                text-align: center;
                text-overflow: ellipsis;
            }
        }
    }
}


.metadata-suggest-popup {
	.model-list-container {
		#models_list {
			background: @background-black;
			
			.super-container {
				.container-model-info-form,
				.container-list-models-suggestion {
					background: @black-background;
					border: 1px solid #ccc;
					border-top: 0;
				}
			}
			
			.card {
				.card-img {
					background: @background-black;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
    .overlay-container {
        height: 22vw;
    }
}
