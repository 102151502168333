.main-cat-change-alert-popup.x-overlay,
.main-cat-switcher-popup.x-popup,
.site-settings .main-cat-switcher {
	.alert-toggle {
		.btn {
			background: transparent;
			margin: 0;
			text-align: left;
			width: auto;
			white-space: normal;
			
			&, &:hover {
				color: #fff;
			}
			
			&:focus, &:active, &:focus:active {
				outline: none;
			}
			
			.checked {
				display: none;
			}
		}
		
		&.disabled-feat {
			.btn {
				.checked {
					display: inline;
				}
				
				.unchecked {
					display: none;
				}
			}
		}
	}
}

.main-cat-change-alert-popup.x-overlay {
	.x-body {
		max-width: 600px;
	}
	
	h2 {
		line-height: 1.4;
		margin-bottom: 14px;
	}
	
	h3 {
		line-height: 1.2;
		margin: 24px 0 10px;
	}
	
	h2, h3 {
		a {
			text-decoration: none;
		}
		
		strong {
			color: @brand-danger;
		}
		
		span {
			display: inline-block;
		}
	}
	
	.btn {
		display: block;
		font-weight: bold;
		margin: 0 auto 8px;
		max-width: 100%;
		width: 320px;
		white-space: normal;
		
		span {
			display: inline-block;
			margin-right: 4px;
			vertical-align: middle;
			
			[dir="rtl"] & {
				margin-left: 4px;
				margin-right: 0;
			}
			
			&.icf-woman {
				font-size: 1.4em;
				line-height: 0.714em;
			}
			
			&.icf-sexe-trans {
				font-size: 1.2em;
				line-height: 0.833em;
			}
		}
		
		&.stay {
			margin-bottom: 30px;
		}
		
		&.reload {
			margin-top: 30px;
		}
	}
	.alert-toggle {
		.btn {
			span {
				display: inline;
			}
		}
	}
	.change-pos {
		margin-top: 26px;
	}
}

.main-cat-switcher-popup.x-popup {
	.x-popup-content {
		background: @tooltip-bg;
		padding: 0;
	}
	
	ul {
		padding: 5px;
		margin: 0;
		
		li {
			background: lighten(@tooltip-bg, 2%);
			border-radius: 4px;
			padding: 5px 10px 5px 5px;
			margin: 2px 0;
			
			&.current,
			&:hover {
				background: lighten(@tooltip-bg, 10%);
			}
			
			.btn {
				border: 0;
				color: #fff;
				display: block;
				font-size: 14px;
				line-height: 22px;
				margin: 0;
				padding: 0;
				text-align: left;
				text-decoration: none;
				
				&:hover {
					color: #fff;
				}
				
				.icon-f {
					vertical-align: middle;
				}
			}
		}
	}
}

.main-cat-switcher-popup.x-popup,
.site-settings .main-cat-switcher {
	ul {
		li {
			&.alert-toggle {
				background: @tooltip-bg;
				margin-top: 20px;
				
				.btn {
					color: #ccc;
					font-size: 1em;
					font-weight: normal;
					line-height: 16px;
					max-width: 170px;
					
					.icon-f {
						line-height: 16px;
						margin-left: 0;
						margin-right: 4px;
						width: 16px;
					}
					
					.text {
						display: inline-block;
						float: none;
						text-align: left;
						width: calc(~'100% - 20px');
					}
					
				}
			}
			
			.btn {
				line-height: 24px;
				
				& > span {
					float: left;
					text-align: center;
					width: 30px;
				}
				
				.icon-f {
					line-height: 24px;
					margin: 0;
					top: 0;
				}
				
				.icf-sexe-woman-v2,
				.icf-sexe-trans-v2 {
					font-size: 18px;
				}
				
				.icf-woman {
					font-size: 22px;
				}
				
				.icf-sexe-trans {
					//font-size: 16px;
				}
				
				.icon-flag-gay {
					display: block;
					margin: 6.5px auto;
				}
			}
		}
	}
}

.site-settings .main-cat-switcher {
	ul {
		li {
			&.alert-toggle {
				.btn {
					padding-right: 10px;
					max-width: 210px;
				}
			}
		}
	}
}
#main-cat-switcher {
	span {
		display: inline-block;
	}
}