@import "../../../common/premium_feed";
@import "../../../common/sub_suggestions/suggestList";


#page.premium_feed {
	#main {
		#feed-videos-filters {
			p {
				margin: 0 0 12px 0;
			}
			.feed-videos-number.with-new-contents {
				margin: 4px 0px 0px;
			}
		}
		&.with-active-suggests {
			.suggest-list-header {
				margin: 15px 0 7px 0;
			}
			#feed-videos {
				& ~ .suggest-list-block {
					.suggest-list-header {
						margin-top: 0;
					}
				}
			}
			#feed-videos-filters {
				padding-top: 0;
				p {
					margin: 0 0 17px 4px;
				}
			}
		}
	}
	
	.suggest-list {
		.thumb-block {
			&.thumb-block-premium-feed-account {
				.thumb-under {
					.any-col-config({
						padding-top: 0;
					})
				}
				&.thumb-block-premium-feed-other-suggest {
					.thumb-inside {
						padding-bottom: calc(~"56.25% + 64px");
					}
				}
			}
		}
	}
	
	
	.simple-dropdown {
		.membership-button.niv1.with-niv2 {
			.membership-dropdown.show-niv2 {
				padding: 0;
				
				.icf-ticket-left-side, .icf-ticket-right-side {
					overflow: hidden;
					position: relative;
					width: 8px;
					color: @premium-feed-membership-filter-btn-bg;
					font-size: 32px;
				}
				
				.icf-ticket-left-side {
					&,
					.icf-ticket-wide {
						float: left;
					}
				}
				
				.icf-ticket-right-side {
					&,
					.icf-ticket-wide {
						float: right;
					}
				}
				
				.membership-dropdown-content {
					display: flex;
					align-items: center;
					border: 0;
					background-color: @premium-feed-membership-filter-btn-bg;
					color: @premium-feed-membership-filter-btn-color;
					cursor: pointer;
					float: left;
					flex-grow: 1;
					line-height: 11px;
					max-width: calc(~"100% - 16px");
					padding: 0;
					font-weight: bold;
					text-decoration: none;
					text-align: center;
					white-space: nowrap;
					
					.membership-profile-picture {
						img {
							width: auto;
							height: 24px;
							object-fit: cover;
							border: 1px solid @premium-feed-membership-filter-btn-bg;
						}
						
						.icon-f.icf-crown {
							font-size: 22px;
							margin-right: 10px;
							margin-left: 2px;
						}
					}
					
					.membership-title {
						margin: 5px 0 5px 6px;
						display: flex;
						flex-direction: column;
						line-height: 11px;
						overflow: hidden;
						width: stretch;
						
						.membership-name {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
					
					small {
						font-size: 70%;
						font-weight: initial;
						line-height: 8px;
					}
					
					.icon-f.icf-caret-down {
						margin: 0 3px;
					}
				}
				
				&.active {
					.membership-dropdown-content {
						color: @premium-feed-membership-filter-btn-active-color;
						background-color: @premium-feed-membership-filter-btn-active-bg;
						
						img {
							border: 1px solid @premium-feed-membership-filter-btn-active-bg;
						}
					}
					
					.icf-ticket-left-side, .icf-ticket-right-side {
						color: @premium-feed-membership-filter-btn-active-bg;
					}
					
					&:hover {
						.membership-dropdown-content {
							background-color: @premium-feed-membership-filter-btn-active-hover-bg;
							
							img {
								border: 1px solid @premium-feed-membership-filter-btn-active-hover-bg;
							}
						}
						
						.icf-ticket-left-side, .icf-ticket-right-side {
							color: @premium-feed-membership-filter-btn-active-hover-bg;
						}
					}
				}
			}
		}
		
		.library {
			.library-btn {
				&.active {
					color: @premium-feed-library-filter-btn-active-color;
					background-color: @premium-feed-library-filter-btn-active-bg;
					
					&:hover {
						background-color: @premium-feed-library-filter-btn-active-hover-bg;
					}
				}
			}
		}
	}
}

#page.premium_feed .suggest-list .thumb-block-premium-feed-account .thumb-under,
.suggest-account-preview-pop .account-preview-header {
	.account-actions {
		.btn {
			&.btn-ticket {
				background-color: @red-color;
				border: 0;
				border-radius: 0;
				line-height: 30px;
				margin-left: 8px;
				margin-right: 8px;
				overflow: visible;
				padding: 0;
				width: calc(~"100% - 16px");
				
				.label {
					color: #fff;
					position: relative;
					z-index: 3;
				}
				
				.text {
					display: inline;
				}
				
				.icf-ticket-left-side,
				.icf-ticket-right-side {
					float: left;
					height: 30px;
					overflow: hidden;
					position: relative;
					width: 8px;
					
					.icf-ticket-wide {
						color: @red-color;
						font-size: 32px;
						line-height: 32px;
						margin-top: -1px;
						opacity: 1;
						padding-right: 0;
					}
				}
				
				&:hover {
					background-color: darken(@red-color, 5%);
					
					.icf-ticket-left-side,
					.icf-ticket-right-side {
						.icf-ticket-wide {
							color: darken(@red-color, 5%);
						}
					}
				}
				
				.icf-ticket-left-side {
					margin-left: -8px;
					
					&, .icf-ticket-wide {
						float: left;
					}
				}
				
				.icf-ticket-right-side {
					margin-right: -8px;
					
					&, .icf-ticket-wide {
						float: right;
					}
				}
				
				&.loading {
					.icf-ticket-left-side {
						.icf-ticket-wide {
							color: @premium-feed-account-sub-load-bg;
						}
					}
				}
			}
			
			&.reject {
				position: relative;
				
				.ticket-rip {
					//background-color: darkred;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					
					&:after,
					&:before {
						background-color: @premium-feed-account-reject-load-bg;
						bottom: 0;
						clip-path: polygon(0 0, 95% 0, 100% 12.5%, 95% 25%, 100% 37.5%, 95% 50%, 100% 62.5%, 95% 75%, 100% 87.5%, 95% 100%, 0 100%);
						content: "";
						left: 0;
						opacity: 0;
						position: absolute;
						top: 0;
						right: 48.75%;
					}
					
					&:before {
						clip-path: polygon(100% 0, 0 0, 5% 12.5%, 0 25%, 5% 37.5%, 0 50%, 5% 62.5%, 0 75%, 5% 87.5%, 0 100%, 100% 100%);
						left: 48.75%;
						right: 0;
					}
				}
				
				&.loading {
					background-color: rgba(0, 0, 0, 0);
					border-color: rgba(0, 0, 0, 0);
					transition: all 0s 2s;
					
					&:after {
						opacity: 0;
						transition: width 3s ease, opacity 0.2s 2s
					}
					
					.ticket-rip {
						&:after {
							animation: ticket-rip-left .9s 2s ease-in-out forwards;
							transform-origin: 100% 50%;
						}
						
						&:before {
							animation: ticket-rip-right 1s 2s ease-in-out forwards;
							transform-origin: 0 50%;
						}
					}
				}
				
				@keyframes ticket-rip-left {
					0% {
						opacity: 0;
					}
					5% {
						opacity: 1;
						transform: rotate(0) translateX(0);
					}
					10% {
						transform: rotate(3deg) translateX(-2%);
					}
					15% {
						transform: rotate(-3deg) translateX(2%);
					}
					20% {
						transform: rotate(3deg) translateX(-2%);
					}
					25% {
						transform: rotate(-3deg) translateX(2%);
					}
					30%, 40% {
						opacity: 1;
						transform: rotate(0) translateX(0);
					}
					100% {
						opacity: 0;
						transform: rotate(0) translateX(-30%);
					}
				}
				
				@keyframes ticket-rip-right {
					0% {
						opacity: 0;
					}
					5% {
						opacity: 1;
						transform: rotate(0) translateX(0);
					}
					10% {
						transform: rotate(3deg) translateX(-2%);
					}
					15% {
						transform: rotate(-3deg) translateX(2%);
					}
					20% {
						transform: rotate(3deg) translateX(-2%);
					}
					25% {
						transform: rotate(-3deg) translateX(2%);
					}
					30%, 40% {
						opacity: 1;
						transform: translateX(0);
					}
					100% {
						transform: translateX(30%);
						opacity: 0;
					}
				}
			}
			
			&.sub {
				.icf-crown {
					color: #fff;
				}
			}
		}
	}
}

.suggest-account-preview-pop {
	.account-preview-header {
		.account-actions {
			.btn {
				&.btn-ticket {
					width: calc(~"49% - 16px");
				}
			}
		}
	}
}

.premium-channels-index-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-top: -20px;
	padding: 30px 60px 20px;
	position: relative;
	
	
	&::before{
		background: @premium_channels_index_header_bg;
		bottom: 0;
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		z-index: 1;
	}
	
	& > * {
		position: relative;
		z-index: 2;
	}
	
	.tour-pushs {
		display: grid;
    	grid-template-columns: repeat(5, minmax(0, 1fr));
		justify-content: space-between;
		width: 980px;
		flex-shrink: 0;

		.inter {
			background: @body-bg;
			border-radius: 12px;
			padding: 10px 10px 20px;
			margin: 0 0 10px;
			text-align: center;
			width: 180px;
			
			.icon-f {
				color: @red-color;
				display: block;
				font-size: 40px;
				line-height: 80px;
				opacity: 0.9;
			}
			
			h3 {
				font-size: 16px;
				height: 44px;
				line-height: 20px;
				margin: 0;
			}
			
			p {
				color: @gray-light;
				font-size: 14px;
				line-height: 16px;
				margin: 0;
			}
		}
		
	}
	
	.cta-cont {
		flex-shrink: 99;
		padding: 0 0 0 40px;
		text-align: right;
		
		.logo {
			display: inline-block;
			margin: 0 0 10px;
			width: 280px;
		}
		
		h2 {
			font-weight: normal;
			margin: 0 0 20px;
		}
		
		.btn-primary {
			border-radius: 5px;
			text-transform: uppercase;
			white-space: normal;
		}
	}
	
	.top-title {
		display: none;
	}
	
	@media (max-width: 1600px) {
		.tour-pushs {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			width: 472px;
			
			.inter {
				align-items: center;
				display: flex;
				justify-content: space-between;
				text-align: left;
				padding: 10px;
				
				.icon-f {
					font-size: 34px;
					line-height: 34px;
					flex-grow: 0;
					padding: 0 15px 0 5px;
				}
				
				h3 {
					font-size: 14px;
					height: auto;
					flex-grow: 2;
				}
				
				p {
					display: none;
				}
			}
			.inter:nth-child(5) {
				display: none;
			}
		}
	}
	
	@media (max-width: 1200px) {
		padding: 20px 20px 10px;
	}
	
	@media (max-width: 960px) {
		flex-direction: column;
		max-width: 480px;
		margin-left: auto;
		margin-right: auto;
		
		.tour-pushs {
			justify-content: space-between;
			width: 100%;
			
			.inter {
				max-width: 100%;
			}
		}
		
		.top-title {
			display: block;
			font-weight: normal;
			margin: 0 0 20px;
			text-align: center;
			width: 100%;
			
			.site-name {
				color: @red-color;
				opacity: 0.9;
			}
		}
		.cta-cont {
			padding: 10px 0 0;
			width: 100%;
			
			.logo,
			.lat-title {
				display: none;
			}
			
			.btn-primary {
				display: block;
				width: 100%;
			}
		}
	}
	
	@media (max-width: @screen-all-mobile) {
		margin-top: 0;
	}

	@media (max-width: @screen-xs-max) {
		#main {
			.suggest-list-header {
				margin: 20px 0 15px 0;
			}
			#feed-videos-filters {
				padding-top: 0;
				p {
					margin: 0 0 17px 4px;
				}
			}
		}
	}
	
	@media (max-width: 480px) {
		padding: 20px 10px 10px;
	}
	
	@media (max-width: 360px) {
		.tour-pushs {
			
			.inter {
				.icon-f {
					font-size: 28px;
					padding: 0 10px 0 0;
				}
			}
		}
	}
}


.profile-video-list-block {
	border: 1px solid @gray-lighter;
	.header {
		h4 {
			a {
				color: @red-color;
			}
			
			.icon-f {
				color: @red-color;
				font-size: inherit;
				line-height: 1;
				padding-right: 4px;
				vertical-align: baseline;
			}
		}
	}
}