.user-subscribe,
.sub-strip {
	.button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
	cursor: pointer;
	display: none;
	border: 0;
	border-radius: 2px;
	font-size: 11px;
	font-weight: normal;
	padding: 0 3px;
	
	* {
		float: left;
		
		[dir="rtl"] & {
			float: right;
		}
	}
	
	&, * {
		height: 14px;
		line-height: 14px;
	}
	
	&:not(.user-reject) {
		.sub-state-text:first-child {
			padding-right: 3px;
		}
		
		.count + .sub-state-text,
		.sub-state-text:last-child,
		.sub-state-text:nth-child(3),
		.sub-state-text:nth-child(4) {
			padding-left: 3px;
		}
	}
	
	
	.thumb-block .profile-counts &,
	.x-popup .uploader-info-profile & {
		&.active .sub-state-text {
			background: @red-color;
			border-radius: 2px;
			color: #fff;
			display: inline-block;
			font-size: 11px;
			line-height: 14px;
			margin-left: 3px;
			margin-right: -3px;
			padding: 0 3px;
			
			&:hover {
				background: darken(@red-color, 5%);
			}
		}
	}
	
	.loader {
		display: none;
	}
	
	.icon-f {
		font-size: 10px;
		padding-left: 4px;
		vertical-align: middle;
	}
	
	&.wait {
		& > *:not(.loader) {
			display: none!important;
		}
		.loader {
			display: inline;
		}
	}
	&:hover {
		//background: @gray-light2;
	}
}

// .gf-user-subscribe {
	
// 	-webkit-text-size-adjust: 100%;
//     -webkit-tap-highlight-color: rgba(0,0,0,0);
//     --vh: 10.68px;
//     font-family: Arial,sans-serif;
//     box-sizing: border-box;
//     cursor: pointer;
//     border-radius: 2px;
//     font-weight: 400;
//     height: 22px;
//     padding: 0 4px;
//     font-size: 14px;
//     line-height: 20px;
//     margin-bottom: 0;
//     width: fit-content;

// 	.sub-state-text, .sub-profile {
// 		display: inline-flex;
// 		align-items: center;
// 		float: left;
// 		padding-left: 3px;
// 		height: 20px;
// 	}

// 	.count {
// 		display: inline-flex;
// 		align-items: center;
// 		height: 20px;
// 	}

// 	.icon-f {
// 		display: inline-flex;
// 		align-items: center;
// 		font-style: normal;
// 		font-variant: normal;
// 		text-transform: none;
// 		letter-spacing: 0;
// 		font-feature-settings: "liga";
// 		font-variant-ligatures: discretionary-ligatures;
// 		-webkit-font-smoothing: antialiased;
// 		float: left;
// 		font-size: 14px;
// 		padding-left: 2px;
// 		vertical-align: middle;
// 		padding-right: 2px;
// 		line-height: 20px;
// 		height: 20px;
// 	}

//     .icf-null {
//         display: none;
//     }
// }