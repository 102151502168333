
body.video-page {
	#main {
		overflow: visible;
	}
}

#page.video-page {
	~ #mobile-login-menu {
		position: absolute;
	}
	.search-autocomplete {
		position: absolute;
	}
	#main .page-title {
		&.reduced-size {
			margin-bottom: 6px;
			font-size: 14px;
			line-height: 18px;
			.duration,
			.video-hd-mark {
				margin-top: 0;
			}
		}
		.duration{
			font-weight: normal;
		}
		.duration,
		.video-hd-mark {
			.xv-label-mixin(18px);
			margin-top: 3px;
		}
	}
	.video-metadata {
		margin-left: 0;
		margin-right: 0;
		
		.uploader {
			display: block;
			padding-right: 0;
		}
		
		#show-all-tags {
			background: @gray;
			color: #fff;
			display: block;
			float: right;
			font-size: 28px;
			font-weight: bold;
			line-height: 24px;
			margin-left: 2px;
			margin-top: -4px;
			padding: 0 4px;
			text-decoration: none;
			
			&:hover {
				background: @theme-color;
			}
		}
		
		.video-tags {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		
		&.expanded {
			#show-all-tags {
				display: none;
			}
			
			.video-tags {
				white-space: normal;
			}
		}
	}
	
	#video-sponsor-links {
		display: none;
	}
	
	#content {
		clear: both;
		margin: 3px -@body-out-padding 0;
	}
	
	#video-player-bg {
		#html5video.embed-responsive,
		#player {
			padding-bottom: 40%;
			@media (max-aspect-ratio: ~"640/340") {
				padding-bottom: 45%;
			}
			@media (max-aspect-ratio: ~"640/380") {
				padding-bottom: 50%;
			}
			@media (max-aspect-ratio: ~"640/420") {
				padding-bottom: 56.25%;
			}
			@media (max-aspect-ratio: ~"640/480") {
				padding-bottom: 62.5%;
			}
			@media (max-aspect-ratio: ~"640/640") {
				padding-bottom: 81.5%;
			}
		}
	}
}

&.old-ie #page.video-page #video-player-bg #player {
	height: 320px;
	padding-bottom: 0;
	embed,
	.embed-responsive-item {
		height: 320px;
	}
}

#video-tabs {
	.btn:not(.copy-btn) {
		margin-bottom: 6px;
		margin-top: 6px;
	}
	
	.tabs .tab {
		margin-bottom: 6px;
	}
}

@video-actions-m-outer-height: 42px;
@video-actions-m-inner-height: 40px;
@video-actions-m-icf-height: 24px;
@video-actions-m-bottom-bar-height: 2px;
@video-actions-m-margin: 6px;
@video-actions-m-margin: 6px;


#v-actions-container {
	height: @video-actions-m-outer-height + (@video-actions-m-margin * 2);
	margin: 0 -@body-out-padding;
	width: calc(~"100% + @{body-out-padding} * 2");
}

#v-actions-overflow-menu {
	padding: 0 3px 0 5px;
}

#v-actions-scroll-left,
#v-actions-scroll-right {
	height: @video-actions-m-outer-height + (@video-actions-m-margin * 2);
	
	.icon-f {
		height: @video-actions-m-outer-height + (@video-actions-m-margin * 2);
		line-height: @video-actions-m-inner-height + (@video-actions-m-margin * 2);
	}
}

#v-actions {
	height: @video-actions-m-outer-height + (@video-actions-m-margin * 2) + @scroll-overflow + @scroll-bar-compatibility-margin;
	padding: @video-actions-m-margin (@video-actions-m-margin / 2) (@video-actions-m-margin + @scroll-overflow);
	
	#v-views {
		font-size: 16px;
		line-height: @video-actions-m-inner-height - @video-actions-m-icf-height;
		margin: 0 @video-actions-m-margin;
		
		.icon-f {
			display: block;
			line-height: @video-actions-m-icf-height;
			margin-right: 0;
			text-align: center;
		}
	}
	
	#v-actions-left {
		white-space: nowrap;
	}
	
	.vote-actions {
		&:not(:first-child) {
			margin-left: @video-actions-m-margin;
		}

		.vote-action-good,
		.vote-action-bad {
			height: @video-actions-m-inner-height;

			.icon-f {
				font-size: 16px;
				line-height: @video-actions-m-icf-height;

				&.big-if-zero-0 {
					font-size: 22px;
				}
			}
		}
		
		.vote-action-good .rating-good-perc:not(.hide-if-zero-0),
		.vote-action-bad .rating-bad-perc:not(.hide-if-zero-0) {
			line-height: @video-actions-m-inner-height - @video-actions-m-icf-height;
			display: block;
		}
	}
	
	.tabs {
		border-bottom: @video-actions-m-bottom-bar-height solid @gray-bg;
		display: flex;
		flex-grow: 0.5;
		height: @video-actions-m-outer-height;
		margin-left: @video-actions-m-margin;
		padding: 0;
		white-space: nowrap;
		
		button {
			border-bottom: @video-actions-m-bottom-bar-height solid @gray-bg;
			flex-grow: 2;
			font-size: 11px;
			height: @video-actions-m-outer-height;
			line-height: @video-actions-m-inner-height - @video-actions-m-icf-height;
			margin-left: @video-actions-m-margin;
			padding-left: @video-actions-m-margin;
			padding-right: @video-actions-m-margin;
			
			.icon-f {
				display: block;
				line-height: @video-actions-m-icf-height;
				margin-right: 0;
			}
			
			&:first-child {
				margin-left: 0;
			}
			
			&.comments {
				border-radius: 0;
				color: @text-color;
				padding-left: @video-actions-m-margin;
				
				.notouch &:hover,
				&.active {
					&, & > span:first-child {
						border-bottom: @video-actions-m-bottom-bar-height solid @text-color;
					}
				}
				
				& > span:first-child {
					background: none;
					color: @text-color;
					position: static;
					width: auto;
					
					.badge {
						left: calc(~"50% + 5px");
						right: auto;
					}
				}
			}
		}
	}
}

#tabReport {
	button[type="submit"] {
		float: none;
		width: 100%;
	}
}

#favs-container {
	.row .col-sm-6 {
		&:first-child ul {
			margin-bottom: -1px;
			li:last-child {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:last-child ul {
			li:first-child {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}
}

#thumbs-select-form {
	.thumb-select-actions .btn {
		font-size: 12px;
		padding: 1px 5px;
		margin-left: 2px;
	}
}

#video-premium-alert {
	max-width: 400px;
	
	.video-premium-alert-row {
		display: block;
		padding-bottom: 0;
	}
	
	.video {
		float: none;
		margin: 0 auto;
		padding: 10px;
		width: 100%;
	}
}

#video-playlist {
	.thumb-block {
		width: calc(50vw - 8px);
	}

	#playlist-header {
		.checkbox.toggle {
			display: none;
		}
	}

	#playlist-content {
		.checkbox.toggle {
			display: block;
		}
	}
}

.related-content {
	.related-content__btns {
		margin-top: 0;
		padding-top: 5px;
	}
}
