.share-toolbox {
	font-size: 0;
	margin: 0;
	padding-left: 0;
	padding-right: 0;
	z-index: @zindex-share-toolbox;
	
	li {
		display: inline-block;
		margin-left: 10px;
		padding: 5px 2px 0 2px;
		vertical-align: top;
		
		a {
			display: block;
			line-height: 1;
			padding: 0;
			text-decoration: none;
			transition: transform 0.1s ease;
			width: 32px;
			
			.notouch &:hover {
				background: none;
				transform: scale(1.2);
			}
			
			.icon-f {
				display: block;
				font-size: 32px;
				height: 32px;
				line-height: 32px;
				text-align: center;
				vertical-align: top;
				width: 32px;
			}
			
			&.twitter {
				&, .icon-f {
					color: @share-picto-twitter;
				}
			}
			
			&.reddit {
				&, .icon-f {
					color: @share-picto-reddit;
				}
			}
			
			&.envelop{
				&, .icon-f {
					color: @share-picto-mail;
				}
			}
			
			&.native-share {
				&, .icon-f {
					color: @share-picto-native;
				}
				.icon-f {
					&:after, &:before {
						color: @share-picto-native-share;
					}
				}
			}
			
			&.copy-btn {
				&, .icon-f {
					color: @share-picto-other;
				}
				.icon-f {
					font-size: 26px;
				}
			}
		}
		
	}
	
	.copy-link {
		.copy-btn {
			border: 0;
			float: none;
			width: 32px;
		}
		
		input,
		input[type=text] {
			border: 0!important;
			font-size: 1px!important;
			height: 1px!important;
			line-height: 1px!important;
			margin: 0 0 0 -10px!important;
			opacity: 0!important;
			padding: 0!important;
			width: 1px!important;
		}
	}
	
	.x-popup-content & {
		li {
			&:first-child {
				margin-left: 0;
			}
			
			a {
				&.copy-btn {
					&, .icon-f {
						color: @share-picto-popup-other;
					}
				}
			}
		}
	}
	
}