.thumb-block {
	.thumb,
	.thumb-purchase {
		a {
			border-width: 0;
		}
	}
}

@media (max-width: @screen-xs-max) {
	#related-videos,
	#related-playlists,
	.related-content {
		background: @video-related-mobile-bg;
		margin-left: -@body-out-padding;
		margin-right: -@body-out-padding;
		padding: 0 @body-out-padding @body-out-padding;
		
		.thumb-block{
			border-bottom: 0;
			
			&.thumb-nat-exo-ad .thumb-inside .thumb .exo-native-widget-item-content {
				background: @video-related-mobile-bg!important;
			}
		}
	}
}