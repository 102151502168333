
body {
	font-family: @font-family-sans-serif;
	overflow-y: scroll;
	padding: @body-out-padding/2 @body-out-padding @body-out-padding;
	
	&.resize-stop-anim * {
		animation: none !important;
		transition: none !important;
	}
	
	#browser-too-old {
		margin-top: -@body-out-padding/2;
	}
	
	&.no-scroll {
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
	&.no-scroll-sticky {
		overflow: hidden;
		position: sticky;
		width: 100%;
	}
}

::selection {
	background: @theme-color;
	color: white;
}

#page {
	width: auto;
	margin: 0 auto;
	max-width: @screen-max;
	position: relative;
	.desktop-main-min-height-minus-header-and-footer();
}

.stripe {
	overflow: hidden;
	&.red-stripe {
		background: @theme-color;
		color: #fff;
	}
	&.black-stripe {
		background: @black-stripe-bg;
		color: #fff;
	}
	&.red-stripe,
	&.black-stripe {
		a, a:visited, a:hover {
			color: #fff;
		}
		a b,
		a strong {
			text-decoration: underline;
		}
	}
	&.bordered {
		margin: 3px 0;
		padding: 5px 10px;
		border: 1px solid black;
	}
	table {
		th, td {
			padding: 1px 10px;
		}
	}
}

@import "modules/header-v2022.less";
@import "modules/header.less";

#main {
	clear: both;
	margin: @body-out-padding 0 0;
	overflow: hidden;
	.red-stripe {
		padding: 4px 8px;
		margin: 4px 0;
		clear: both;
	}
	.black-stripe {
		padding: 4px 8px;
		margin: 2px 0;
	}
	.header-high-vmargins {
		margin: 10px 0;
	}
	.head-message {
		font-size: 14px;
		img {
			float: left;
			margin-right: 10px;
			max-height: 60px;
			max-width: 60px;
		}
	}
	.big-margin {
		margin-bottom: 50px;
		margin-top: 50px;
	}
	#content {
		overflow: hidden;
	}
	.page-title {
		font-size: 25px;
		line-height: 32px;
		margin: 0 2px 6px;
		word-break: break-word;
		.sub {
			color: @gray-light;
			font-size: 0.8em;
			display: inline-block;
		}
		.ordered-label-list-btn{
			font-size: 20px;
			font-weight: bold;
			line-height: 32px;
			padding: 0 10px;
		}
	}
}

#warning-survey {
	font-size: 16px;
	background: #98ceee;
	color: #333;
	padding: 5px 20px;
	font-weight: bold;
	text-align: left;
	a {
		color: #333;
	}
	.question {
		font-weight: normal;
	}
}

.main-categories,
.video-tags-list {
	clear: both;
}

.head__menu-line,
#page {
	.ordered-label-list {
		padding: 0;
		margin: 4px 0 0;
		ul {
			margin: 0;
			padding: 0;
			font-size: 0;
			li {
				display: inline-block;
				margin: 0 5px 5px 0;
				padding: 0;
				&.sub-list {
					display: inline;
					margin-right: 0;
					ul {
						display: inline;
					}
				}
				&.mobile-show {
					display: none;
				}
				&.click-order-toggle {
					.btn{
						background: @nav-dSktp-btn-vwmore-bg;
						color: @nav-dSktp-btn-vwmore-color;
						padding: 0 4px 0 8px;
						&:hover {
							background: @nav-dSktp-btn-vwmore-hover-bg;
							color: @nav-dSktp-btn-vwmore-color;
						}
						&.active{
							.icon-f{
								transform: rotate(-90deg);
							}
						}
					}
					.icon-f{
						display: inline-block;
						font-size: 16px;
						line-height: 26px;
						vertical-align: bottom;
					}
				}
				&#listing-settings {
					.cog-big {
						display: none;
					}
					.cog {
						display: inline-block;
					}
				}
			}
		}
	}
	.ordered-label-list .btn,
	.ordered-label-list-btn {
		background: @nav-dSktp-btn-bg;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		color: @nav-dSktp-btn-color;
		display: inline-block;
		font-size: 14px;
		font-weight: normal;
		line-height: 26px;
		margin: 0;
		padding: 0 6px;
		text-shadow: none;
		vertical-align: middle;
		white-space: normal;
		
		-webkit-user-select: text;
		-khtml-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
		
		.flag-small {
			vertical-align: baseline;
		}
		&.btn-link {
			background: transparent;
			border: 1px solid @text-color;
			color: @text-color;
			line-height: 24px;
			.navbadge {
				//font-size: 50%;
				line-height: 1;
				font-weight: bold;
				vertical-align: text-top;
				text-transform: uppercase;
			}
		}
		&:hover {
			background: @nav-dSktp-btn-hover-bg;
			color: @nav-dSktp-btn-hover-color;
			text-decoration: none;
		}
		&.no-hover{
			&:hover {
				background: @nav-dSktp-btn-bg;
				color: @nav-dSktp-btn-color;
			}
		}
		&.label {
			background: @nav-dSktp-btn-label-bg;
			color: @nav-dSktp-btn-label-color;
			cursor: pointer;
			font-weight: bold;
		}
		&.main {
			background: @nav-dSktp-btn-main-bg;
			color: @nav-dSktp-btn-main-color;
			cursor: default;
			font-weight: bold;
			&.hover-name {
				cursor: pointer;
				.name {
					background: @nav-dSktp-btn-main-bg;
				}
			}
			.user-subscribe {
				border-color: @nav-dSktp-btn-main-bg;
				&.sub-checked {
					.button-variant(black; white; @nav-dSktp-btn-main-bg);
				}
			}
			.fan-video {
				background: #fff;
				color: #000;
			}
		}
		&.btn-text {
			background: transparent;
			color: @text-color;
			&:hover {
				color: @text-color;
			}
		}
		&.hover-name {
			padding: 0;
			-webkit-user-select: auto;-moz-user-select: auto;-ms-user-select: auto;user-select: auto;
			.model-star-sub {
				float: left;
				height: 26px;
				line-height: 25px;
				padding: 0 5px;
				display: block;
				.button-variant(@nav-dSktp-btn-label-color; @nav-dSktp-btn-label-bg; @nav-dSktp-btn-label-bg);
				& + .name { padding-left : 0 }
			}
			.name {
				float: left;
				height: 26px;
				line-height: 27px;
				padding: 0 6px;
				display: block;
				&:hover {
					text-decoration: underline;
				}
			}
			.verified {
				float: left;
				height: 26px;
				line-height: 26px;
				margin-right: 6px;
				top: 0;
				&.icf-white-fill {
					&:after{
						bottom: 27%;
						top: 27%;
					}
				}
			}
			.user-subscribe {
				margin-left: -6px;
				margin-right: 0;
			}
		}
		.user-subscribe{
			border: solid 6px @nav-dSktp-btn-label-bg;
			background: white;
			color: black;
			display: inline-block;
			float: right;
			font-size: 12px;
			font-weight: normal;
			height: 27px;
			line-height: 14px;
			margin-right: -6px;
			min-width: 26px;
			position: relative;
			text-transform: none;
			vertical-align: middle;
			white-space: nowrap;
			
			&.sub-checked {
				.button-variant(black; white; @nav-dSktp-btn-label-bg);
			}
			
			.count{
				box-shadow: 0 0 0 rgba(0,0,0,0.5) inset,  0 0 0 rgba(0,0,0,0.5);
				display: block;
				float: left;
				height: 14px;
				line-height: 16px;
				padding: 0 2px;
			}
			.icf-feed,
			.icf-check{
				color: @red-color;
				float: left;
				font-size: 12px;
				height: 14px;
				line-height: 14px;
				padding: 0 2px 0 0;
			}
			.loader{
				display: none;
				img {
					padding: 0 4px;
				}
			}
			&.wait{
				cursor: wait;
				&,
				&:hover,
				&:active{
					box-shadow: none;
					transform: initial;
				}
				.count,
				.icf-check{
					display: none;
				}
				.loader{
					display: inline;
				}
			}
		}
		&.current {
			background: @nav-dSktp-btn-main-bg;
			color: @nav-dSktp-btn-main-color;
			cursor: default;
			font-weight: bold;
		}
		&.country-switch {
			background: @nav-dSktp-btn-ctysw-bg;
			color: @nav-dSktp-btn-ctysw-color;
			&:hover {
				background: @nav-dSktp-btn-ctysw-hover-bg;
				color: @nav-dSktp-btn-ctysw-color;
			}
			&.main {
				background: @nav-dSktp-btn-main-ctysw-bg;
				color: @nav-dSktp-btn-main-color;
				font-weight: bold;
				&:hover {
					background: @nav-dSktp-btn-main-ctysw-hover-bg;
				}
			}
		}
		&.view-more {
			background: @nav-dSktp-btn-vwmore-bg;
			color: @nav-dSktp-btn-vwmore-color;
			font-size: 20px;
			font-weight: bold;
			&:hover {
				background: @nav-dSktp-btn-vwmore-hover-bg;
				color: @nav-dSktp-btn-vwmore-color;
			}
		}
		&.play-all-rand {
			.icon-f {
				float: left;
				line-height: 26px;
			}
		}
		
		&.uploader-tag{
			cursor: pointer;
			text-transform: none;
		}
		.verified {
			font-size: 16px;
			position: relative;
			top: 2px;
		}
		&.profile{
			.verified{
				color: black;
			}
		}
	}
	.ordered-label-list {
		.btn {
			&.red-label {
				background: @theme-color;
				color: #fff;
			}
			&.red-home {
				.icon-f {
					font-size: 20px;
					line-height: 26px;
					float: left;
				}
			}
			&.red-free {
				img {
					float: left;
					height: 18px;
					margin: 4px 5px 4px 0;
				}
			}
			&.red-ticket {
				background: none;
				padding: 0;
				position: relative;
				.icf-ticket-wide-cont {
					display: inline-block;
					padding: 0 10px;
					position: relative;
					
					& > span {
						background: @red-color;
						font-weight: bold;
						position: relative;
						z-index: 2;
					}
					
					.icf-ticket-wide {
						&:before {
							left: 0;
							top: 0;
							position: absolute;
						}
					}
					
					.icf-ticket-wide-left,
					.icf-ticket-wide-right {
						background: none;
						color: @red-color;
						display: block;
						bottom: 0;
						font-size: 26px;
						left: 0;
						overflow: hidden;
						position: absolute;
						top: 0;
						width: 55%;
						z-index: 1;
					}
					
					.icf-ticket-wide-right {
						left: 30px;
						right: 0;
						
						&:before {
							left: auto;
							right: 0;
						}
					}
					
					.navbadge {
						margin-right: -2px;
					}
				}
				&.inactive {
					.icf-ticket-wide-cont {
						& > span {
							background: @nav-dSktp-btn-bg;
							color: @nav-dSktp-btn-color;
						}
						
						.icf-ticket-wide-left,
						.icf-ticket-wide-right {
							background: none;
							color: @nav-dSktp-btn-bg;
						}
					}
					&:hover {
						.icf-ticket-wide-cont {
							& > span {
								background: @red-color;
								color: #fff;
							}
							
							.icf-ticket-wide-left,
							.icf-ticket-wide-right {
								background: none;
								color: @red-color;
							}
						}
					}
				}

				&.btn-profiles-date-links {
					.btn-profiles-date-links-arrow {
						position: absolute;
						right: 7px;
						z-index: 2;
					}
				}

			}
			.navbadge {
				background: @nav-dSktp-btn-label-badge-bg;
				color: @nav-dSktp-btn-label-badge-color;
				vertical-align: text-bottom;
			}
		}
	}
}

.main-message {
	overflow: hidden;
	position: relative;
	padding: 5px 8px;
	margin: 4px 0;
	background: @theme-color;
	color: #fff;
	font-size: 13px;
	&:after {
		position: absolute;
		content: "";
		height: 1px;
		background-color: #000;
		bottom: -2px;
		left: 0;
		right: 0;
		width: 100%;
	}
	a {
		color: #fff;
	}
	&.closable {
		padding-right: 18px;
		.close {
			position: absolute;
			cursor: pointer;
			position: absolute;
			right: 6px;
			top: 0;
			color: #fff;
			font-weight: bold;
			font-size: 13px;
			line-height: 15px;
			font-family: 'sans-serif';
		}
	}
}

.ticket-red-height( @height ) {
	.ticket-red {
		.ticket-in {
			height: @height;
			line-height: @height;
		}
		.ticket-back {
			.ticket-back-left,
			.ticket-back-right,
			.icon-f {
				font-size: @height + @height/26;
			}
		}
	}
}

.ticket-red {
	display: inline-block;
	margin: 0;
	position: relative;
	.ticket-in {
		color: white;
		display: block;
		font-size: 14px;
		font-weight: bold;
		height: 26px;
		line-height: 26px;
		padding: 0 8px;
		position: relative;
		z-index: 2;
	}
	.ticket-back {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		.ticket-back-left,
		.ticket-back-right {
			width: 55%;
			overflow: hidden;
		}
		.ticket-back-left,
		.ticket-back-right,
		.icon-f {
			bottom: 0;
			font-size: 27px;
			position: absolute;
			top: 0;
			z-index: 2;
		}
		.icon-f {
			color: @theme-color;
		}
		.ticket-back-left {
			&,
			.icon-f {
				left: 0;
			}
		}
		.ticket-back-right {
			&,
			.icon-f {
				right: 0;
			}
		}
	}
}

#footer {
	clear: both;
	font-size: 13px;
	margin: 20px 0 10px;
	overflow: hidden;
	.terms {
		border-top: 1px solid @gray-lighter;
		padding-top: 15px;
		p {
			margin: 0 0 16px;
		}
	}
	.botLinks {
		border-top: 1px solid @gray-lighter;
		padding: 10px 0 0;
		&, a {
			color: @gray-light;
		}
	}
	.slogan {
		margin: 0;
	}
}

#page-go-up {
	background: #000;
	border-radius: 5px;
	bottom: 40px;
	color: #000;
	cursor: pointer;
	font-size: 50px;
	display: none;
	opacity: 0.75;
	position: fixed;
	right: 10px;
	z-index: @zindex-page-go-up;
	
	.icon-f {
		display: block;
	}
}

.mac-models {
	h2 {
		color: @red-color;
		
		span {
			color: #fff;
		}
	}
	
	.models {
		.model {
			.live {
				background: @theme-color;
			}
		}
	}
}

.video-nb-icon {
	display: flex;
	gap: 3px;
	line-height: 1;

	.icon-f {
		line-height: 1.1;
	}
}