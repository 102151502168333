@import (less) "../libs/emojione_sprites/emojione.sprites.css";
[class*=emojione-] {
  background-image: url("../../less/libs/emojione_sprites/emojione.sprites.png")!important;
  width:20px;
  height:20px;
  &.emoji-only {
    width:40px;
    height:40px;
  }
}
@import (less) "../lib/emojionearea/emojionearea.min.css";