
.xv-tabs {
	position: relative;
	.tab-buttons {
		float: left;
		margin: 0;
		padding: 6px 0 6px;
		overflow: hidden;
		white-space: nowrap;
		li {
			display: inline-block;
			margin: 0 5px 0 0;
			padding: 0;
			vertical-align: top;
			
			&.mobile-show {
				display: none;
			}
		}
		.hide-if-not-premium{
			display: none;
		}
		.premium-page &{
			.hide-if-not-premium{
				display: inline-block;
			}
			.hide-if-premium{
				display: none;
			}
		}
		.btn {
			border-radius: 0;
			margin: 0;
			font-size: 13px;
			line-height: 1.6;
			.icon,
			.icon-f {
				vertical-align: text-top;
			}
			&:hover,
			&.active,
			&.desktop-active {
				color: #fff;
				background: @theme-color;
				.navbadge {
					background: #000;
				}
			}
		}
	}
	.side-scrollable-tab-buttons {
		overflow-x: hidden;
		overflow-y: visible;
		
		&.is_mobile {
			overflow-x: auto;
		}
		
		.left-scroll,
		.right-scroll {
			.text-border-2px( #111 );
			color: #fff;
			cursor: pointer;
			display: none;
			font-size: 12px;
			height: 34px;
			line-height: 34px;
			left: 0;
			position: absolute;
			text-align: center;
			top: 0;
			width: 20px;
			z-index: 5;
		}
		
		.right-scroll {
			left: auto;
			right: 0;
		}
	}
	.tabs {
		overflow: hidden;
		zoom: 1;
		clear: both;
		.tab {
			display: none;
			overflow: hidden;
			zoom: 1;
			&.active,
			&.desktop-active {
				display: block;
			}
		}
	}
}

.xv-slim-tabs {
	display: flex;
	font-size: 0;
	justify-content: space-evenly;
	margin: 0 auto;
	padding: 0;
	position: relative;
	width: 100%;
	
	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 1px;
		background: @thin-tab-border-bottom;
		z-index: 0;
	}
	
	li {
		position: relative;
		display: inline-block;
		margin: 0;
		padding: 0;
		z-index: 1;
		
		& + li {
			margin-left: 10px;
		}
	}
	
	.xv-slim-tab-btn {
		border-bottom: 1px solid @thin-tab-border-bottom;
		color: @gray;
		cursor: pointer;
		display: block;
		font-size: 14px;
		font-weight: 400;
		line-height: 1;
		margin: 0;
		padding: 5px;
		text-decoration: none;
		
		&:hover {
			color: @text-color;
		}
		
		&.active {
			color: @text-color;
			border-color: @thin-tab-border-bottom-active;
		}
		
		//.text {
		//	white-space: nowrap;
		//	overflow: hidden;
		//	text-overflow: ellipsis;
		//}
		
		.count {
			color: @gray-light;
			display: inline-block;
			font-size: 0.9em;
			line-height: 1;
			margin-left: 3px;
		}
	}
}