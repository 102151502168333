
#filter-tags-input {
	padding: 1px 4px;
	margin: 8px 0;
	border-color: #aaa;
	border-radius: 0;
	height: auto;
	box-shadow: none;
}

#tags,
.tags-list {
	margin: 10px 5px;
	padding: 0;
	li {
		display: inline-block;
		width: 25%;
		vertical-align: text-top;
	}
	a {
		display: inline-block;
		padding: 0 5px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

#page {
	&.search-page,
	&.tag-page {
		.bg-title {
			&.black {
				margin: 0 0 2px;
			}
			&.grey {
				margin: 6px 0;
			}
		}
	}
	&.search-page {
		.thumb-block {
			&.thumb-profile {
				background: darken(@gray-lighter, 20%);
				p a {
					font-weight: bold;
				}
			}
			&.thumb-block-profile {
				display: flex;
				flex-direction: column-reverse;
			}
		}
		
		.page-title {
			overflow: hidden;
			
			.report-search {
				float: right;
				margin: 0;
				padding: 0 10px;
				white-space: nowrap;
				
				* {
					display: inline-block;
					line-height: 32px;
					vertical-align: top;
				}
				
				.label {
					padding-left: 2px;
				}
				
				
				&:focus,
				&:hover {
					text-decoration: none;
				}
				
				.notouch &:hover {
					.label {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

#search-channel-banner {
	margin: 2px 0 4px;
	position: relative;
	img {
		border:1px solid #000;
	}
	a {
		position: absolute;
		width: 50%;
		top: 0;
		left: 0;
		bottom: 0;
		span {
			display: block;
			position: absolute;
			top: 2px;
			left: 2px;
			padding: 1px 5px;
			background: #000;
			background: fadeOut(#000, 40%);
			color: #fff;
			white-space: nowrap;
			overflow: hidden;
			font-size: 15px;
			&.channelname {
				font-size: 16px;
			}
			&.nbvids {
				top: 29px;
			}
		}
		&.paysite {
			left: auto;
			right: 0;
			span {
				top: auto;
				left: auto;
				right: 2px;
				bottom: 2px;
			}
		}
	}
}

#search-associates {
	margin: 6px 0;
	padding: 4px 8px;
	overflow: hidden;
	//background: @search-block-bg;
	color: @gray-dark;
	line-height: 1.2;
	
	span {
		a {
			display: inline-block;
			padding: 1px 3px;
		}
	}
	
	strong {
		font-size: 16px;
		padding: 1px 3px;
	}
	
	strong,
	span {
		display: inline-block;
		vertical-align: middle;
	}
	
	.size-0 { font-size: 12px; }
	.size-1 { font-size: 13px; }
	.size-2 { font-size: 16px; }
	.size-3 { font-size: 18px; }
	.size-4 {
		font-size: 20px;
		font-weight: bold;
	}
	
	.show-more {
		display: none;
	}
}

.banner-game {
	position: relative;
	margin: 20px auto 25px auto;
	max-width: 728px;
	height: auto;
	&:before {
		content: '';
		display: block;
		padding-top: 8.241758242%;
	}
	a {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
	}
	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}


.mozaique {
	.premium-results-line-title {
		margin: 5px 10px 0;
		text-align: center;
		
		strong {
			white-space: nowrap;
		}
		
		a.see-more {
			color: #fff;
			display: inline-block;
			font-size: 18px;
			line-height: 28px;
			margin: 0 @video-thumb-padding-left;
			text-decoration: none;
			
			.query {
				font-size: 22px;
			}
			
			.icf-ticket-red {
				color: #000;
				display: inline-block;
				font-size: 1.8em;
				line-height: 28px;
				vertical-align: top;
				
				&:before {
					display: inline-block;
					vertical-align: top;
				}
			}
			
			&:active,
			&:focus,
			&:hover {
				text-decoration: none;
			}
		}
	}
	.premium-results-line-see-more {
		clear: both;
		margin: 0 4px 4px;
		text-align: right;
		
		a.see-more {
			color: #fff;
			font-size: 16px;
			text-decoration: none;
			
			.underline {
				text-decoration: underline;
			}
			
			.icf-ticket-red {
				font-size: 25px;
				line-height: 16px;
				vertical-align: text-top;
			}
		}
	}
	
	.premium-results-line {
		background-color: @post-comment-s1-bg-left;
		background-image: linear-gradient(135deg, @post-comment-s1-bg-left, @post-comment-s1-bg-right);
		border: 1px solid #000;
		float: left;
		padding: 0 (2 * @video-thumb-padding-left);
		margin: 15px @video-thumb-padding-left;
		overflow: hidden;
		width: calc(~"100% - (@{video-thumb-padding-left} * 2)");
	}
}


.search-premium-tabs {
	position: relative;
	padding: 0;
	margin: 5px 0;
	font-size: 0;
	
	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 1px;
		background: @thin-tab-border-bottom;
		z-index: 0;
	}
	
	li {
		position: relative;
		display: inline-block;
		margin: 0;
		padding: 0;
		z-index: 1;
		
		& + li {
			margin-left: 10px;
		}
	}
	a {
		margin: 0;
		text-decoration: none;
		font-size: 12px;
		line-height: 18px;
		box-shadow: none;
		border-radius: 2px;
		
		&.link {
			display: block;
			padding: 5px;
			font-weight: 400;
			font-size: 14px;
			line-height: 1;
			color: @gray;
			border-radius: 0;
			border-bottom: 1px solid @thin-tab-border-bottom;
			
			&:hover {
				color: @text-color;
			}
		}
		
		&.active {
			color: @text-color;
			border-color: @thin-tab-border-bottom-active;
		}
		
		.btn__text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			
			.icf-ticket-red {
				color: @body-bg;
				display: inline-block;
				font-size: 38px;
				line-height: 16px;
				height: 16px;
				vertical-align: bottom;
				
				&:after {
					background: @gray;
					bottom: 0;
					top: 0;
				}
			}
		}
		
		&.active {
			.btn__text {
				.icf-ticket-red {
					color: @red-color;
					
					&:after {
						background: #fff;
					}
				}
			}
		}
			
		.btn__nb {
			color: @gray-light;
			font-size: 0.9em;
		}
	}
}