.dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] {
  position: absolute;
  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] li.textcomplete-item {
    font-size: 14px;
    padding: 1px 3px;
    border: 0; }
    .dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] li.textcomplete-item a {
      text-decoration: none;
      display: block;
      height: 100%;
      line-height: 1.8em;
      padding: 0 1.54em 0 .615em;
      color: #4f4f4f; }
    .dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] li.textcomplete-item:hover, .dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] li.textcomplete-item.active {
      background-color: #e4e4e4; }
      .dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] li.textcomplete-item:hover a, .dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] li.textcomplete-item.active a {
        color: #333; }
    .dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] li.textcomplete-item .emojioneemoji {
      font-size: inherit;
      height: 2ex;
      width: 2.1ex;
      min-height: 20px;
      min-width: 20px;
      display: inline-block;
      margin: 0 5px .2ex 0;
      line-height: normal;
      vertical-align: middle;
      max-width: 100%;
      top: 0; }

.emojionearea-text [class*=emojione-], .emojionearea-text .emojioneemoji {
  font-size: inherit;
  height: 2ex;
  width: 2.1ex;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  margin: -.2ex .15em .2ex;
  line-height: normal;
  vertical-align: middle;
  max-width: 100%;
  top: 0; }

.emojionearea, .emojionearea * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
.emojionearea.emojionearea-disable {
  position: relative;
  background-color: #eee;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none; }
  .emojionearea.emojionearea-disable:before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.3;
    position: absolute;
    background-color: #eee; }
.emojionearea, .emojionearea.form-control {
  display: block;
  position: relative !important;
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 14px;
  border: 0;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-transition: border-color 0.15s ease-in-out,    -moz-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out,         box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,         box-shadow 0.15s ease-in-out; }
.emojionearea.focused {
  border-color: #66AFE9;
  outline: 0;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
.emojionearea .emojionearea-editor {
  display: block;
  height: auto;
  min-height: 8em;
  max-height: 15em;
  overflow: auto;
  padding: 6px 24px 6px 12px;
  line-height: 1.42857143;
  font-size: inherit;
  color: #555555;
  background-color: transparent;
  border: 0;
  cursor: text;
  margin-right: 1px;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .emojionearea .emojionearea-editor:empty:before {
    content: attr(placeholder);
    display: block;
    color: #BBBBBB; }
  .emojionearea .emojionearea-editor:focus {
    border: 0;
    outline: 0;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .emojionearea .emojionearea-editor [class*=emojione-], .emojionearea .emojionearea-editor .emojioneemoji {
    font-size: inherit;
    height: 2ex;
    width: 2.1ex;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -.2ex .15em .2ex;
    line-height: normal;
    vertical-align: middle;
    max-width: 100%;
    top: 0; }
.emojionearea.emojionearea-inline {
  height: 34px; }
  .emojionearea.emojionearea-inline > .emojionearea-editor {
    height: 32px;
    min-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 12px;
    right: 24px;
    padding: 6px 0; }
  .emojionearea.emojionearea-inline > .emojionearea-button {
    top: 4px; }
.emojionearea .emojionearea-button {
  z-index: 5;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 24px;
  height: 24px;
  opacity: 0.6;
  cursor: pointer;
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out; }
  .emojionearea .emojionearea-button:hover {
    opacity: 1; }
  .emojionearea .emojionearea-button > div {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out; }
    .emojionearea .emojionearea-button > div.emojionearea-button-open {
      background-position: 0 -24px;
      filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
      opacity: 1; }
    .emojionearea .emojionearea-button > div.emojionearea-button-close {
      background-position: 0 0;
      -webkit-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0; }
  .emojionearea .emojionearea-button.active > div.emojionearea-button-open {
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  .emojionearea .emojionearea-button.active > div.emojionearea-button-close {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1; }
.emojionearea .emojionearea-picker {
  background: #FFFFFF;
  position: absolute;
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: 276px;
  width: 316px;
  top: -15px;
  right: -15px;
  z-index: 90;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none; }
  .emojionearea .emojionearea-picker.hidden {
    display: none; }
  .emojionearea .emojionearea-picker .emojionearea-wrapper {
    position: relative;
    height: 276px;
    width: 316px; }
    .emojionearea .emojionearea-picker .emojionearea-wrapper:after {
      content: "";
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      z-index: 91; }
  .emojionearea .emojionearea-picker .emojionearea-filters {
    width: 100%;
    position: absolute;
    z-index: 95; }
  .emojionearea .emojionearea-picker .emojionearea-filters {
    background: #F5F7F9;
    padding: 0 0 0 7px;
    height: 40px; }
    .emojionearea .emojionearea-picker .emojionearea-filters .emojionearea-filter {
      display: block;
      float: left;
      height: 40px;
      width: 32px;
      filter: inherit;
      padding: 7px 1px 0;
      cursor: pointer;
      -webkit-filter: grayscale(1);
      filter: grayscale(1); }
      .emojionearea .emojionearea-picker .emojionearea-filters .emojionearea-filter.active {
        background: #fff; }
      .emojionearea .emojionearea-picker .emojionearea-filters .emojionearea-filter.active, .emojionearea .emojionearea-picker .emojionearea-filters .emojionearea-filter:hover {
        -webkit-filter: grayscale(0);
        filter: grayscale(0); }
      .emojionearea .emojionearea-picker .emojionearea-filters .emojionearea-filter > i {
        width: 24px;
        height: 24px;
        top: 0; }
      .emojionearea .emojionearea-picker .emojionearea-filters .emojionearea-filter > img {
        width: 24px;
        height: 24px;
        margin: 0 3px; }
  .emojionearea .emojionearea-picker .emojionearea-search-panel {
    height: 30px;
    position: absolute;
    z-index: 95;
    top: 40px;
    left: 0;
    right: 0;
    padding: 5px 0 5px 8px; }
    .emojionearea .emojionearea-picker .emojionearea-search-panel .emojionearea-tones {
      float: right;
      margin-right: 10px;
      margin-top: -1px; }
  .emojionearea .emojionearea-picker .emojionearea-tones-panel .emojionearea-tones {
    position: absolute;
    top: 4px;
    left: 171px; }
  .emojionearea .emojionearea-picker .emojionearea-search {
    float: left;
    padding: 0;
    height: 20px;
    width: 160px; }
    .emojionearea .emojionearea-picker .emojionearea-search > input {
      outline: none;
      width: 160px;
      min-width: 160px;
      height: 20px; }
  .emojionearea .emojionearea-picker .emojionearea-tones {
    padding: 0;
    width: 120px;
    height: 20px; }
    .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone {
      display: inline-block;
      padding: 0;
      border: 0;
      vertical-align: middle;
      outline: none;
      background: transparent;
      cursor: pointer;
      position: relative; }
      .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-0, .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-0:after {
        background-color: #ffcf3e; }
      .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-1, .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-1:after {
        background-color: #fae3c5; }
      .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-2, .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-2:after {
        background-color: #e2cfa5; }
      .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-3, .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-3:after {
        background-color: #daa478; }
      .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-4, .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-4:after {
        background-color: #a78058; }
      .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-5, .emojionearea .emojionearea-picker .emojionearea-tones > .btn-tone.btn-tone-5:after {
        background-color: #5e4d43; }
    .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-bullet > .btn-tone, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-square > .btn-tone {
      width: 20px;
      height: 20px;
      margin: 0;
      background-color: transparent; }
      .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-bullet > .btn-tone:after, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-square > .btn-tone:after {
        content: "";
        position: absolute;
        display: block;
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px; }
      .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-bullet > .btn-tone.active:after, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-square > .btn-tone.active:after {
        top: 0;
        left: 0;
        width: 20px;
        height: 20px; }
    .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-radio > .btn-tone, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-checkbox > .btn-tone {
      width: 16px;
      height: 16px;
      margin: 0px 2px; }
      .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-radio > .btn-tone.active:after, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-checkbox > .btn-tone.active:after {
        content: "";
        position: absolute;
        display: block;
        background-color: transparent;
        border: 2px solid #fff;
        width: 8px;
        height: 8px;
        top: 2px;
        left: 2px;
        box-sizing: initial; }
    .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-bullet > .btn-tone, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-bullet > .btn-tone:after, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-radio > .btn-tone, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-radio > .btn-tone:after {
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      border-radius: 100%; }
    .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-square > .btn-tone, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-square > .btn-tone:after, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-checkbox > .btn-tone, .emojionearea .emojionearea-picker .emojionearea-tones.emojionearea-tones-checkbox > .btn-tone:after {
      -moz-border-radius: 1px;
      -webkit-border-radius: 1px;
      border-radius: 1px; }
  .emojionearea .emojionearea-picker .emojionearea-scroll-area {
    height: 236px; }
  .emojionearea .emojionearea-picker .emojionearea-search-panel + .emojionearea-scroll-area {
    height: 206px; }
  .emojionearea .emojionearea-picker .emojionearea-scroll-area {
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    position: absolute;
    padding: 0 0 5px; }
    .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-emojis-list {
      z-index: 1; }
    .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-title {
      display: block;
      font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
      font-size: 13px;
      font-weight: normal;
      color: #b2b2b2;
      background: #FFFFFF;
      line-height: 20px;
      margin: 0;
      padding: 7px 0 5px 6px; }
      .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-title:after, .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-title:before {
        content: " ";
        display: block;
        clear: both; }
    .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-block {
      padding: 0 0 0 7px; }
      .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-block > .emojionearea-category {
        padding: 0 !important; }
        .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-block > .emojionearea-category:after, .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-block > .emojionearea-category:before {
          content: " ";
          display: block;
          clear: both; }
      .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-block:after, .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-block:before {
        content: " ";
        display: block;
        clear: both; }
    .emojionearea .emojionearea-picker .emojionearea-scroll-area [class*=emojione-] {
      -moz-box-sizing: content-box;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      margin: 0;
      width: 24px;
      height: 24px;
      top: 0; }
    .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojibtn {
      -moz-box-sizing: content-box;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      width: 24px;
      height: 24px;
      float: left;
      display: block;
      margin: 1px;
      padding: 3px; }
      .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojibtn:hover {
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background-color: #e4e4e4;
        cursor: pointer; }
      .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojibtn i, .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojibtn img {
        float: left;
        display: block;
        width: 24px;
        height: 24px; }
      .emojionearea .emojionearea-picker .emojionearea-scroll-area .emojibtn img.lazy-emoji {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-top .emojionearea-filters {
    top: 0;
    -moz-border-radius-topleft: 5px;
    -webkit-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -moz-border-radius-topright: 5px;
    -webkit-border-top-right-radius: 5px;
    border-top-right-radius: 5px; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-top.emojionearea-search-position-top .emojionearea-scroll-area {
    bottom: 0; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-top.emojionearea-search-position-bottom .emojionearea-scroll-area {
    top: 40px; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-top.emojionearea-search-position-bottom .emojionearea-search-panel {
    top: initial;
    bottom: 0; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-bottom .emojionearea-filters {
    bottom: 0;
    -moz-border-radius-bottomleft: 5px;
    -webkit-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-bottom.emojionearea-search-position-bottom .emojionearea-scroll-area {
    top: 0; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-bottom.emojionearea-search-position-bottom .emojionearea-search-panel {
    top: initial;
    bottom: 40px; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-bottom.emojionearea-search-position-top .emojionearea-scroll-area {
    top: initial;
    bottom: 40px; }
  .emojionearea .emojionearea-picker.emojionearea-filters-position-bottom.emojionearea-search-position-top .emojionearea-search-panel {
    top: 0; }
  .emojionearea .emojionearea-picker.emojionearea-picker-position-top {
    margin-top: -286px;
    right: -14px; }
    .emojionearea .emojionearea-picker.emojionearea-picker-position-top .emojionearea-wrapper:after {
      width: 19px;
      height: 10px;
      background-position: -2px -49px;
      bottom: -10px;
      right: 20px; }
    .emojionearea .emojionearea-picker.emojionearea-picker-position-top.emojionearea-filters-position-bottom .emojionearea-wrapper:after {
      background-position: -2px -80px; }
  .emojionearea .emojionearea-picker.emojionearea-picker-position-left, .emojionearea .emojionearea-picker.emojionearea-picker-position-right {
    margin-right: -326px;
    top: -8px; }
    .emojionearea .emojionearea-picker.emojionearea-picker-position-left .emojionearea-wrapper:after, .emojionearea .emojionearea-picker.emojionearea-picker-position-right .emojionearea-wrapper:after {
      width: 10px;
      height: 19px;
      background-position: 0px -60px;
      top: 13px;
      left: -10px; }
    .emojionearea .emojionearea-picker.emojionearea-picker-position-left.emojionearea-filters-position-bottom .emojionearea-wrapper:after, .emojionearea .emojionearea-picker.emojionearea-picker-position-right.emojionearea-filters-position-bottom .emojionearea-wrapper:after {
      background-position: right -60px; }
  .emojionearea .emojionearea-picker.emojionearea-picker-position-bottom {
    margin-top: 10px;
    right: -14px;
    top: 47px; }
    .emojionearea .emojionearea-picker.emojionearea-picker-position-bottom .emojionearea-wrapper:after {
      width: 19px;
      height: 10px;
      background-position: -2px -100px;
      top: -10px;
      right: 20px; }
    .emojionearea .emojionearea-picker.emojionearea-picker-position-bottom.emojionearea-filters-position-bottom .emojionearea-wrapper:after {
      background-position: -2px -90px; }
.emojionearea .emojionearea-button.active + .emojionearea-picker {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1; }
.emojionearea .emojionearea-button.active + .emojionearea-picker-position-top {
  margin-top: -269px; }
.emojionearea .emojionearea-button.active + .emojionearea-picker-position-left,
.emojionearea .emojionearea-button.active + .emojionearea-picker-position-right {
  margin-right: -309px; }
.emojionearea .emojionearea-button.active + .emojionearea-picker-position-bottom {
  margin-top: -7px; }
.emojionearea.emojionearea-standalone {
  display: inline-block;
  width: auto;
  box-shadow: none; }
  .emojionearea.emojionearea-standalone .emojionearea-editor {
    min-height: 33px;
    position: relative;
    padding: 6px 42px 6px 6px; }
    .emojionearea.emojionearea-standalone .emojionearea-editor::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 50%;
      bottom: 4px;
      border-left: 1px solid #e6e6e6; }
    .emojionearea.emojionearea-standalone .emojionearea-editor.has-placeholder {
      background-repeat: no-repeat;
      background-position: 20px 4px; }
      .emojionearea.emojionearea-standalone .emojionearea-editor.has-placeholder .emojioneemoji {
        opacity: 0.4; }
  .emojionearea.emojionearea-standalone .emojionearea-button {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto; }
    .emojionearea.emojionearea-standalone .emojionearea-button > div {
      right: 6px;
      top: 5px; }
  .emojionearea.emojionearea-standalone .emojionearea-picker.emojionearea-picker-position-bottom .emojionearea-wrapper:after, .emojionearea.emojionearea-standalone .emojionearea-picker.emojionearea-picker-position-top .emojionearea-wrapper:after {
    right: 23px; }
  .emojionearea.emojionearea-standalone .emojionearea-picker.emojionearea-picker-position-left .emojionearea-wrapper:after, .emojionearea.emojionearea-standalone .emojionearea-picker.emojionearea-picker-position-right .emojionearea-wrapper:after {
    top: 15px; }

.emojionearea .emojionearea-button > div, .emojionearea .emojionearea-picker .emojionearea-wrapper:after {
  background-image: url('../../less/lib/emojionearea/button.png') !important; }

.emojionearea.emojionearea-standalone .emojionearea-editor.has-placeholder {
  background-image: url('../../less/lib/emojionearea/placeholder.png') !important; }

/*# sourceMappingURL=emojionearea.css.map */
