#suggestions_models_list, #models_list .mozaique {
    .card {
        margin: 0;
        overflow: hidden;

        .card-img {
            background: @body-bg;

            .overlay-container {
                .overlay {
                    .icf-ban {
                        color: @text-invert-color;
                        font-size: 50px;
                        left: 35%;
                        text-shadow: 1px 1px @text-color;
                        top: 25%;
                    }

                    .metadata-remove {
                        background-color: @theme-color;
                        color: @text-invert-color;
                        margin: 5px;

                        &:hover {
                            background: @x-messages-icon-bg-success;
                        }
                    }
                }
            }
        }
    }
}
.metadata-suggest-popup {
    #suggestions_tags_list, #suggestions_removed_tags_list {
        li {
            overflow: hidden;
            margin: 2px;
            display: inline-block;

            input[type=text] {
                font-size: 12px;
                width: 75px;

                &:focus {
                    outline: none;
                }
            }

            a, .metadata-remove {
                outline: none;
                position: relative;
                border-radius: 0;
                font-weight: 400;

                .btn {
                    border: none;
                    color: @text-color;
                }

                &.btn-default {
                    background: @form-tag-adder-tag-bg;
                    color: @text-color !important;
                    font-size: 14px;
                    padding: 0 2px 0 6px;
                    border: none;

                    &:hover {
                        background: @form-tag-adder-tag-hover-bg;
                    }
                }
            }

            .metadata-remove {
                border-radius: 0;
                cursor: pointer;
                font-size: 15px;
                font-weight: bolder;
                background: @form-tag-adder-tag-bg;
                color: @text-color;
                float: right;
                display: block;
                text-decoration: none;

                &:hover {
                    background: @form-tag-adder-tag-hover-bg;
                }

                .icf-close, .icf-plus {
                    font-size: 14px;
                    margin: 4px 0 3px;
                    border: 0;
                    float: right;
                }
            }
        }
    }

    .panel-models-suggestions {
        .metadata-suggest-box {
            flex-grow: 4;
        }

        .validate-suggest-tag, .validate-suggest-model {
            margin-left: -2px;
            border: 1px solid @red-color;
            @media screen and (max-width: 1024px) {
                padding: 5px 0;
            }
        }
    }
}

#suggestions_models_list, #models_list .mozaique {
	display: flex;
	flex-wrap: wrap;
	margin-top: 0px;
	.thumb-block-premium-feed-account {
		@media (max-width: 460px) {
			width: 100%;
		}
		@media (min-width: 460px) {
			width: 100% / 2;
		}
		@media (min-width: 580px) {
			width: 100% / 3;
		}
		@media (min-width: 850px) {
			width: 100% / 4;
		}
		@media (min-width: 1100px) {
			width: 100% / 5;
		}
		@media (min-width: 1550px) {
			width: 100% / 6;
		}
		@media (min-width: 1850px) {
			width: 100% / 7;
		}
	}
}

.metadata-suggest-popup #models_list .mozaique {
	display: flex;
	flex-wrap: wrap;
	margin-top: 0px;
	.thumb-block-premium-feed-account {
		@media (max-width: 480px) {
			width: 100%;
		}
		@media (min-width: 480px) {
			width: 100% / 2;
		}
		@media (min-width: 950px) {
			width: 100% / 3;
		}
		// Layout shift, container width is divided by 2
		@media (min-width: 1200px) {
			width: 100% / 2;
		}
		@media (min-width: 1550px) {
			width: 100% / 3;
		}
		@media (min-width: 1850px) {
			width: 100% / 4;
		}
	}
}