@premium-feed-zindex-profile-hover	: 		10;
@premium-feed-zindex-membership-focus	: 	12;
@premium-feed-zindex-membership-lock	: 	13;
@premium-feed-zindex-profile-name	: 		15;
@premium-feed-zindex-cover-loader	:		20;


//@TODO fix thumb-block display in the popup on premium feed
.x-overlay.overlay-subs {
	.thumb-block-premium-feed-account {
		.profile-name {
			position: relative;
			left: auto;
			bottom: auto;
			padding-top: 0;
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
		}
		.profile-counts {
			top: auto;
			font-weight: 400;
			border-radius: 0;
		}
	}
}

.premium-welcome-popup-opened {
	max-width: 600px;
	img {
		height: 18px;
	}
	.gold-plate {
		font-size: 14px;
		margin-left: 2px;
	}
}

#page.premium_feed {
	#main {
		padding-top: 5px;
	}

	.expand-memberships {
		display: inline-flex;
		align-items: center;
		float: left;
		background: 0 0;
		border: 0;
		padding: 0;
		margin: 0 6px 5px 4px;
		height: 32px;
		color: @premium-feed-filter-color;
		font-size: 12px;
		font-weight: 700;
		line-height: 30px;
		cursor: pointer;
		visibility: hidden;

		.icon-f {
			display: inline-block;
			line-height: 30px;
			vertical-align: top;
		}
	}
	
	.premium-switch-page-dropdown {
		display: inline-block;
		height: 100%;
		overflow: hidden;
		z-index: 10;
		width: 100%;

		.expand-memberships {
			visibility: visible;
		}
		
		.niv1 .show-niv2 {
			flex-direction: row;
			margin: 0 5px 0 0;
			max-width: 100%;
			width: stretch;
		}

		&.without-memberships {
			.expand-memberships {
				display: none;
			}

			//.hide-suggests {
			//	float: inline-start;
			//}
		}

		//&.is-expand {
		//	.hide-suggests {
		//		float: inline-start;
		//	}
		//}
	}

	#page-videos-content {
		position: relative;
		
		& > .suggest-list:first-child,
		& > .xscroll-container:first-child {
			margin-bottom: 30px;
		}
	}
	
	#feed-videos-filters {
		clear: both;
		display: none;
		font-size: 18px;
		
		& > p {
			display: inline-block;
			line-height: 26px;
			vertical-align: top;
		}

		.with-new-contents ~ .suggest-list-visibility-btn {
			float: right;
			margin: 10px 10px 14px 0px;
		}

		.suggest-list-visibility-btn {
			float: right;
			margin: 4px 0px 0px;
		}
		
		.simple-dropdown {
			display: inline-block;
			padding-bottom: 5px;
		}
		
		.niv1 {
			padding: 0 3px 3px 0;
		}
		
		.show-niv2 {
			background: @premium-feed-filter-bg;
			border-radius: 3px;
			color: @premium-feed-filter-color;
			cursor: pointer;
			font-size: 14px;
			line-height: 22px;
			padding-left: 10px;
			padding-right: 5px;
			flex-direction: row-reverse;
			
			&.is-reset {
				background: transparent;
				font-size: 12px;
				padding-left: 10px;
				padding-right: 10px;
				text-align: center;
				flex-direction: row;
				
				.label {
					display: inline-block;
				}
				
				.icf-close-thin {
					font-size: 10px;
				}
			}
			
			.has-duration-limit,
			.icf-eye,
			.icf-eye-blocked,
			.icf-plus-square,
			.icf-minus-square,
			.has-search {
				color: @premium-feed-filter-highlight-color;
			}
			
			
			.label {
				span {
					float: left;
					line-height: 22px;
				}
				
				.slash {
					padding: 0 5px;
				}
				
				.icon-f {
					padding-right: 5px;
					
					& + .slash {
						padding-left: 0;
					}
				}
			}
			
			.icf-caret-down {
				float: right;
				line-height: 22px;
				margin: 0 0 0 3px;
				padding: 0;
			}
			
			.icf-plus-square,
			.icf-minus-square {
				&.count-0 {
					color: @premium-feed-filter-color;
					opacity: 0.5;
				}
			}
		}
	}
	
	.welcome-channel-sub-message {
		color: @premium-feed-empty-color;
		font-size: 20px;
		margin: 0;
		padding: 20px 7px 10px;
		text-align: center;
	}
	
	#feed-videos {
		margin-bottom: 20px;
		position: relative;
		
		&.no-videos {
			margin-bottom: 0;
		}
		
		&.loading {
			min-height: 120px;
		}
		
		.empty,
		& ~ .empty {
			color: @premium-feed-empty-color;
			font-size: 20px;
			margin: 0;
			padding: 20px 0 10px;
			text-align: center;
			
			span {
				font-style: italic;
			}
		}
		
		.empty {
			padding: 0 0 10px;
			font-size: 16px;
		}
	}
	
	.mozaique {
		.loader {
			clear: both;
			font-size: 20px;
			margin: 0;
			padding: 10px 0;
			text-align: center;
		}
	}
	
	
	.simple-dropdown {
		
		.membership-button.niv1.with-niv2,
		.library,
		.switch-page-button,
		.suggest-list-visibility-btn {
			display: inline-flex;
			margin-bottom: 5px;
			padding: 0;
			float: left;
			height: 32px;
			position: relative;
		}
		.suggest-list-visibility-btn {
			float: right;
			margin: 0 15px 0 auto;
			padding: 7px 5px;
			height: 32px;
			line-height: 18px;
			position: absolute;
			right: 0;
		}
		
		.library {
			.library-btn {
				cursor: pointer;
				display: flex;
				align-items: center;
				border-radius: 3px;
				border: 0;
				background-color: @premium-feed-library-filter-btn-bg;
				color: @premium-feed-library-filter-btn-color;
				float: left;
				line-height: 11px;
				margin: 0 4px 0 0;
				font-weight: bold;
				text-decoration: none;
				text-align: center;
				white-space: nowrap;
				padding: 5px 10px 5px 7px;
				
				.icon-f {
					font-size: 22px;
					margin-right: 6px;
				}
				
				small {
					font-size: 70%;
					font-weight: initial;
					line-height: 8px;
				}
				
			}
		}
	}
}

.premium_feed,
.suggest-account-preview-pop {
	.cover-loader {
		background: @premium-feed-cover-loader-bg;
		bottom: 0;
		color: @premium-feed-cover-loader-color;
		font-size: 80px;
		left: 0;
		overflow: hidden;
		padding: 20px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		z-index: @premium-feed-zindex-cover-loader;
		
		.icon-f {
			display: block;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.mozaique {
		.loader {
			float: left;
			margin: 10px 0 0;
			text-align: center;
			width: 100%;
			&.with-space {
				padding: 10px 0 30px;
			}
		}
		.error-message {
			float: left;
			margin: 10px 0;
			width: 100%;
			letter-spacing: 0;
			
			button {
				display: block;
				margin: 5px auto 0;
			}
		}
	}
}

#page.premium_feed .suggest-list .thumb-block-premium-feed-account .thumb-under,
.suggest-account-preview-pop .account-preview-header {
	.account-actions {
		height: auto;
		max-height: none;
		
		.btn {
			display: block;
			height: 30px;
			line-height: 28px;
			margin: 0 0 2px;
			overflow: hidden;
			padding: 0 2px;
			position: relative;
			transition: none;
			width: 100%;
			
			&:after {
				bottom: 0;
				content: " ";
				display: block;
				left: 0;
				top: 0;
				width: 0;
			}
			
			&.sub {
				&:after {
					background: @premium-feed-account-sub-load-bg;
					color: @premium-feed-account-sub-color;
				}
			}
			
			&.reject {
				&:after {
					background: @premium-feed-account-reject-load-bg;
					color: @premium-feed-account-reject-color;
				}
			}
			
			&:focus,
			&:active,
			&:focus:active {
				outline: none;
			}
			
			&.loading {
				&:after {
					position: absolute;
					transition: all 3s ease;
					z-index: 2;
					width: 100%;
				}
			}
			
			.label {
				display: inline-block;
				
				sup {
					font-size: 0.9em;
				}
			}
			
			.icon-f {
				float: left;
				line-height: 28px;
				opacity: 0.3;
				padding-right: 4px;
				
				&.icf-crown {
					font-size: 1.4em;
					opacity: 0.6;
				}
			}
		}
	}
}

.suggest-account-preview-pop,
.premium-feed-cat-review-pop,
.premium-feed-categories-filter-pop{
	&.x-overlay.x-overlay-box {
		padding: 0 10px;
		height: fit-content;
		
		.x-body {
			padding: 25px 15px 10px;
			width: 600px;
		}
	}
}

.x-overlay.x-overlay-box.auto-width-popup.suggest-account-preview-pop {
	position: fixed;
	height: 100vh;
	.x-body {
		margin-top: 10px;
	}
}

.suggest-account-preview-pop {
	&.x-overlay.x-overlay-box {
		.x-body {
			background: @suggest-account-preview-pop-bg;
		}
	}
	
	.account-preview-header {
		margin-bottom: 10px;
		height: 70px;
		overflow: hidden;
		
		.page-link {
			.button-variant(@btn-default-color, @btn-default-bg, @btn-default-border);
			float: right;
			line-height: 28px;
			margin: 0 0 0 4px;
			padding: 0 12px;
			
			
			.icon-f {
				float: left;
				line-height: 26px;
				opacity: 0.3;
				padding-right: 6px;
				
				&.icf-crown {
					font-size: 1.3em;
					opacity: 0.6;
				}
			}
		}
		
		.prof-pic-n-page-link {
			&.with-pic {
				.profile-pic {
					float: left;
					height: 70px;
					overflow: hidden;
					position: relative;
					width: 70px;
					
					img {
						display: block;
						height: 70px;
						object-fit: contain;
						object-position: center;
						width: 70px;
					}
				}
				
				& + h2,
				& ~ .model-info,
				& ~ .membership-info ,
				& ~ .account-actions {
					margin-left: 80px;
				}
				& ~ .model-info,
				& ~ .membership-info {
					width: calc(100% - 80px);
				}
			}
		}
		
		&.with-membership-info {
			height: 136px;
			
			.prof-pic-n-page-link {
				&.with-pic {
					& ~ .account-actions {
						margin-left: 0;
					}
				}
			}
			
			& + .mozaique {
				.max-height-100vh(201px);
			}
		}
		
		h2 {
			& ~ .account-actions {
				margin-top: 10px;
			}
		}
		
		.membership-info {
			align-items: center;
			display: flex;
			width: 100%;
			
			& > * {
				display: inline-block;
				margin: 0 10px 0 0;
				vertical-align: top;
			}
			.icon-f {
				color: #777;
			}
			
			.icf-bar-chart {
				color: #666;
				font-size: 40px;
			}
			
			.membership-info-videos {
				text-align: center;
			}
			
			.membership-info-videos,
			.membership-info-other {
				flex-shrink: 2;
				overflow: hidden;
				
				p {
					overflow: hidden;
					margin: 0;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			
			.membership-info-detail {
				margin-right: 0;
				
				.icf-question-circle-o {
					cursor: pointer;
					font-size: 26px;
					
					.notouch &:hover {
						color: #fff;
					}
				}
				
				.membership-info-detail-hover {
					background: #212121;
					border: 10px solid @suggest-account-preview-pop-bg;
					box-shadow: 0 0 0 1px inset #444;
					display: none;
					left: 50%;
					max-width: 100%;
					padding: 10px 15px;
					position: absolute;
					top: 0;
					transform: translateX(-50%);
					z-index: 50;
					width: 450px;
					
					p {
						line-height: 20px;
						margin: 0;
						
						.icon-f {
							display: inline-block;
							text-align: center;
							width: 20px;
						}
					}
					
					.close {
						cursor: pointer;
						float: right;
						height: 30px;
						line-height: 30px;
						margin: -10px -15px 5px 5px;
						text-align: center;
						width: 30px;
					}
				}
				
				.membership-info-detail-hover.is-opened {
					display: block;
				}
			}
		}
		
		.account-actions {
			overflow: hidden;
			
			.btn {
				float: left;
				margin-bottom: 0;
				width: 49%;
				
				&.assign,
				&.edit {
					align-items: center;
					display: flex;
					justify-content: center;
					width: 100%;
					
					.icon-f {
						opacity: 1;
					}
				}
				
				&.reject {
					margin-left: 2%;
				}
			}
		}
	}
	
	&.is-model {
		.account-preview-header {
			&.with-model-info {
				height: 100px;
				
				.model-info {
					display: flex;
					column-gap: 4px;
					flex-direction: column;
					flex-wrap: wrap;
					height: 30px;
					justify-content: center;
					padding: 5px 0;
					
					p {
						overflow: hidden;
						margin: 0;
						line-height: 20px;
						padding-left: 5px;
						text-overflow: ellipsis;
						white-space: nowrap;
						
						&:last-child {
							margin-bottom: auto;
						}
						
						span {
							color: #a9a9a9;
						}
					}
				}
				
				&.with-pic {
					height: 120px;
					
					.model-info {
						height: 50px;
					}
				}
			}
			
			&.with-pic {
				height: 120px;
			}
			
			.prof-pic-n-page-link {
				&.with-pic {
					.profile-pic {
						border-radius: 4px;
						height: 120px;
						width: 120px;
						
						img {
							height: 120px;
							width: 120px;
							
							&:after {
								content: "";
								position: absolute;
								top: -30%;
								right: -200%;
								width: 50%;
								height: 200%;
								opacity: 0;
								transform: rotate(30deg);
								background: rgba(255, 255, 255, 0.13);
								background: linear-gradient( to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
								animation: modelProfPicShine 2s 1s;
							}
							
							@keyframes modelProfPicShine {
								to {
									opacity: 1;
									right: 210%;
								}
							}
						}
					}
					
					& + h2,
					& ~ .model-info,
					& ~ .membership-info,
					& ~ .account-actions {
						margin-left: 130px;
					}
					
					& ~ .model-info,
					& ~ .membership-info {
						width: calc(100% - 130px);
					}
				}
			}
		}
	}
	
	.mozaique-container {
		overflow: auto;
		max-height: 300px;
		.max-height-100vh(135px);
		
		.xscroll-container,
		.xscroll-content {
			max-height: 300px;
			.max-height-100vh(135px);
		}

		&.with-pic {
			&,
			.xscroll-container,
			.xscroll-content {
				.max-height-100vh(185px);
			}
		}

		&.with-membership-info {
			&,
			.xscroll-container,
			.xscroll-content {
				.max-height-100vh(210px);
			}
		}
	}
	
	.thumbs-2-cols {
		padding-top: 0;
		padding-right: 0;
		
		.membership-detail {
			margin: 5px auto 15px;
			padding: 10px;
			font-size: 20px;
			max-width: 300px;
			position: relative;
			text-align: center;
			
			.membership-detail-left,
			.membership-detail-right {
				display: inline-block;
				padding: 10px 5px;
				
				& > * {
					display: block;
				}
			}
			.membership-detail-left {
				text-align: right;
			}
			.membership-detail-right {
				text-align: left;
			}
			
			&:after,
			&:before {
				bottom: 2%;
				content: '';
				left: 2%;
				position: absolute;
				top: 2%;
				transition: 0.2s all;
				right: 2%;
			}
			&:after {
				border-left: 2px solid transparent;
				border-right: 2px solid transparent;
				border-image: linear-gradient(180deg, #fff 0%, #fff 30%, transparent 30.01%, transparent 69.99%,  #fff 70%, #fff 100%) 10;
			}
			&:before {
				border-bottom: 2px solid transparent;
				border-top: 2px solid transparent;
				border-image: linear-gradient(90deg, #fff 0%, #fff 30%, transparent 30.01%, transparent 69.99%,  #fff 70%, #fff 100%) 10;
			}
			
			p {
				
				strong {
					float: left;
					padding-right: 10px;
					text-align: right;
					width: 46%;
				}
			}
		}
		& > img {
			border: 1px solid black;
			display: block;
			float: left;
			margin: 1px;
			vertical-align: middle;
			width: calc(50% - 2px);
			
			&:last-child:nth-child(2n + 1) {
				margin-left: auto;
				margin-right: auto;
				float: none;
			}
		}
		.thumb-block {
			padding-bottom: 0;
			padding-top: 0;
			width: 50%;
			
			&:first-child:last-child {
				width: 100%;
			}
			
			.video-title {
				background-image: linear-gradient(0deg, @premium-feed-account-profile-name-bg 0%, transparent 100%);
				bottom: 0;
				color: @premium-feed-account-profile-name-color;
				display: block;
				font-size: 14px;
				font-weight: bold;
				left: 0;
				line-height: 20px;
				overflow: hidden;
				padding: 20px 4px 0;
				position: absolute;
				right: 0;
				text-align: center;
				text-overflow: ellipsis;
				.text-border-1px(fadeout(@premium-feed-account-profile-name-bg, 50%));
				white-space: nowrap;
				z-index: 2;
			}
		}
	}
}

.suggest-account-preview-pop .account-preview-header h2,
.premium-feed-cat-review-pop h2 {
	line-height: 30px;
	margin: 0;
	
	&:last-child {
		margin-bottom: 10px;
	}
	
	.label {
		color: @suggest-account-preview-pop-title-color;
		display: block;
		line-height: inherit;
		overflow: hidden;
		padding: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		
		span {
			color: @suggest-account-preview-pop-title-sub-color;
			font-size: 0.8em;
			font-weight: normal;
			
			.icon-f {
				font-size: 1em;
				margin-left: 6px;
			}
		}
	}
}

.premium-feed-cat-review-pop {
	.category-actions {
		margin: 18px 0;
		
		p {
			margin: 0 0 14px;
			
			& > span {
				display: block;
				font-size: 13px;
				line-height: 14px;
				opacity: 0.9;
				padding: 0 4px;
			}
			
			.icon-f:first-child {
				float: left;
				line-height: 14px;
				padding-right: 4px;
			}
		}
		
		.btn {
			display: block;
			font-size: 15px;
			line-height: 22px;
			margin: 0 0 4px;
			text-align: left;
			white-space: normal;
			
			.icon-f:first-child {
				float: left;
				line-height: 22px;
				padding-right: 6px;
			}
		}
	}
	
	.cancel {
		float: right;
	}
	
	.cover-loader {
		padding: 0;
		text-align: center;
		
		.icon-f {
			display: inline-block;
			font-size: 80px;
			margin: 20px;
		}
	}
	
	h2 {
		.btn {
			.button-variant(@btn-default-color, @btn-default-bg, @btn-default-border);
			float: right;
			line-height: 28px;
			margin: 0 0 0 4px;
			padding: 0 12px;
		}
	}
}

.premium-feed-cats-review-pop {
	.x-body {
		width: 1280px;
	}
	
	h2 {
		margin: 0 0 20px;
	}
	
	h3 {
		height: 48px;
		.line-clamp(2);
		line-height: 24px;
		margin:	10px 0;
		overflow: hidden;
	}
	
	.cats-row {
		background: #000;
		overflow: hidden;
		position: relative;
		
		.cats-col {
			float: left;
			padding: 0 10px;
			width: 20%;
		}
		
		&:before,
		&:after {
			background-image: linear-gradient(180deg, #000 0%, transparent 100%);
			content: " ";
			left: 0;
			height: 12px;
			position: absolute;
			top: 68px;
			width: 100%;
			z-index: 2;
		}
		&:after {
			background-image: linear-gradient(0deg, #000 0%, transparent 100%);
			bottom: 0;
			top: auto;
		}
	}
	
	.cats {
		max-height: 300px;
		.max-height-100vh(230px);
		overflow: auto;
		padding: 12px 0;
		
		p {
			background: #333;
			border-radius: 3px;
			color: #fff;
			cursor: pointer;
			line-height: 32px;
			overflow: hidden;
			padding: 0 6px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	
	.ok {
		margin: 10px 0 0;
		width: 100%;
	}
}

.premium-feed-min-length-filter-pop,
.premium-feed-categories-filter-pop {
	&.x-overlay.x-overlay-box {
		.x-body {
			background: @premium-feed-cat-filter-pop-bg;
			color: @premium-feed-cat-filter-pop-color;
		}
	}
	
	h3 {
		border-bottom: 1px solid @premium-feed-cat-filter-list-border;
		color: @premium-feed-cat-filter-pop-title-color;
		line-height: 32px;
		margin: 0;
		overflow: hidden;
		padding: 0 0 10px;
		text-overflow: ellipsis;
	}
}

.premium-feed-min-length-filter-pop {
	.form-range {
		border-bottom: 1px solid @premium-feed-cat-filter-list-border;
		margin: 16px 0;
		padding-bottom: 16px;
	}
}

.premium-feed-categories-filter-pop {
	h3 {
		white-space: nowrap;
		
		& > .icf-white-fill {
			color: #000;
			.text-border-1px(#fff);
		}
		
		.reset {
			float: right;
			margin: 0 0 0 4px;
		}
	}
	
	.cat-list {
		max-height: 300px;
		.max-height-100vh(150px);
		overflow: auto;
		padding: 12px 0;
	}
	
	.categories-filter-resume {
		.row {
			.add,
			.exclude {
				height: 32px;
				line-height: 30px;
				margin: 0 0 6px;
				padding: 0;
				width: 100%;
				
				.icf-white-fill {
					color: #000;
					font-size: 20px;
					line-height: 30px;
					.text-border-1px(#fff);
				}
			}
			
			p {
				background: @premium-feed-cat-filter-resume-bg;
				line-height: 32px;
				overflow: hidden;
				padding: 0 0 0 4px;
				text-overflow: ellipsis;
				white-space: nowrap;
				
				.remove {
					height: 32px;
					margin: 0;
					float: right;
				}
			}
		}
		.cat-list {
			.max-height-100vh(176px);
			
			.empty {
				color: @premium-feed-empty-color;
				background: transparent;
				font-size: 16px;
				margin: 0;
				padding: 10px;
				text-align: center;
				white-space: normal;
			}
		}
	}
	
	.categories-filter-update-list {
		&.add h3 .icf-minus-square,
		&.exclude h3 .icf-plus-square {
			display: none;
		}
		
		.btn {
			overflow: hidden;
			text-align: left;
			text-overflow: ellipsis;
			width: 100%;
		}
		
		p {
			.icon-f {
				display: none;
			}
			
			&.add .icf-plus-square,
			&.exclude .icf-minus-square,
			&.neutral .icf-square {
				display: inline;
			}
			
			.is-desktop & .btn:hover {
				&.add .icf-square,
				&.exclude .icf-square {
					display: none;
				}
			}
		}
		
		.is-desktop & {
			&.add p.neutral .btn:hover .icf-plus-square,
			&.add p.exclude .btn:hover .icf-plus-square,
			&.add p.add .btn:hover .icf-square,
			&.exclude p.neutral .btn:hover .icf-minus-square,
			&.exclude p.add .btn:hover .icf-minus-square,
			&.exclude p.exclude .btn:hover .icf-square {
				display: inline;
			}
			
			&.add p.neutral .btn:active .icf-plus-square,
			&.add p.exclude .btn:active .icf-plus-square,
			&.add p.add .btn:active .icf-square,
			&.exclude p.neutral .btn:active .icf-minus-square,
			&.exclude p.add .btn:active .icf-minus-square,
			&.exclude p.exclude .btn:active .icf-square,
			&.add p.neutral .btn:focus .icf-plus-square,
			&.add p.exclude .btn:focus .icf-plus-square,
			&.add p.add .btn:focus .icf-square,
			&.exclude p.neutral .btn:focus .icf-minus-square,
			&.exclude p.add .btn:focus .icf-minus-square,
			&.exclude p.exclude .btn:focus .icf-square,
			&.add p.neutral .btn:hover .icf-square,
			&.add p.exclude .btn:hover .icf-minus-square,
			&.add p.add .btn:hover .icf-plus-square,
			&.exclude p.neutral .btn:hover .icf-square,
			&.exclude p.add .btn:hover .icf-plus-square,
			&.exclude p.exclude .btn:hover .icf-minus-square {
				display: none;
			}
			
			&.add p.neutral .btn:active .icf-square,
			&.add p.exclude .btn:active .icf-minus-square,
			&.add p.add .btn:active .icf-plus-square,
			&.exclude p.neutral .btn:active .icf-square,
			&.exclude p.add .btn:active .icf-plus-square,
			&.exclude p.exclude .btn:active .icf-minus-square,
			&.add p.neutral .btn:focus .icf-square,
			&.add p.exclude .btn:focus .icf-minus-square,
			&.add p.add .btn:focus .icf-plus-square,
			&.exclude p.neutral .btn:focus .icf-square,
			&.exclude p.add .btn:focus .icf-plus-square,
			&.exclude p.exclude .btn:focus .icf-minus-square {
				display: inline;
			}
		}
		
		&.add p.add,
		&.exclude p.exclude {
			.btn {
				background: @premium-feed-cat-filter-selected-bg;
				color: @premium-feed-cat-filter-selected-color;
			}
		}
		&.add p.exclude,
		&.exclude p.add {
			.btn {
				background: transparent;
				color: @premium-feed-cat-filter-unselected-color;
				opacity: 0.8;
			}
		}
	}
	
	.close {
		border-top: 1px solid @premium-feed-cat-filter-list-border;
		padding: 10px 0 0;
		margin: 0;
		
		.btn {
			height: 32px;
			margin: 0;
			text-align: center;
			width: 100%;
		}
	}
}
.premium-feed-free-search-filter-pop {
	.input-group {
		border-bottom: 1px solid @premium-feed-cat-filter-list-border;
		border-top: 1px solid @premium-feed-cat-filter-list-border;
		margin: 12px 0 16px;
		max-width: 400px;
		padding: 16px 0;
		
		.btn {
			margin: 0;
		}
	}
}

.premium-feed-source-filter-pop {
	.strong {
		border-radius: 3px;
		border-style: solid;
		border-width: 1px;
		padding: 2px 4px;
		text-transform: capitalize;
		
		.button-variant(@btn-default-color, @btn-default-bg, @btn-default-border);
		
		.icon-f {
			display: inline-block;
			font-size: 0.8em;
			line-height: 1.11em;
		}
	}
}

.simple-dropdown-pop {
	.premium-feed-filters {
		.uls {
			a {
				line-height: 18px;
				
				&.current {
					background: @premium-feed-filter-drop-current-bg;
					font-weight: normal;
				}
				
				&:hover {
					background: @premium-feed-filter-drop-hover-bg;
				}
				
				.sub {
					opacity: 0.6;
				}
				
				.icon-f {
					float: left;
					font-size: 1.2em;
					line-height: 18px;
					margin-right: 6px;
				}
				
				&[data-filter="show_free"], 
				&[data-filter="sources"] {
					line-height: 20px;
					
					.toggle {
						display: inline-block;
						margin: 0 6px 0 0;
						vertical-align: top;
					}
				}
			}
		}
	}
}

.profile-video-list-block {
	margin: 18px 0 24px;
	
	.mozaique {
		margin-left: 14px;
		margin-right: 14px;
		overflow: visible;
		white-space: nowrap;
		
		.thumb-block {
			display: inline-block;
			float: none;
			vertical-align: top;
			white-space: normal;
		}
	}
}

// 991px max
@media screen and (max-width: @screen-sm-max) {
	#page.premium_feed #feed-videos-filters {
		p {
			margin: 0 0 10px 4px;
		}
	}
}

// 767px max
@media (max-width: @screen-xs-max) {
	#page.premium_feed {
		.premium-switch-page-dropdown {
			.primary-premium-buttons {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				width: fit-content;

				//.hide-suggests {
				//	margin: 0 5px 5px 0;
				//	float: inline-start;
				//}

				.simple-dropdown .niv1 {
					padding: 0;
				}
			}
			#memberships-container {
				display: inline-block;
				float: inline-start;
				overflow-y: hidden;
				width: 100%;
				max-height: 37px;
			}
			.expand-memberships {
				float: inline-start;
			}

			&.is-expand {
				#memberships-container {
					max-height: 111px;
					overflow-y: scroll;
					display: grid;
					grid-template-columns: 25% 25% 25% 25%;
				}
			}
		}
		
		#page-videos-content {
			& > .suggest-list:first-child,
			& > .xscroll-container:first-child {
				margin-bottom: 6px;
			}
		}
		
		#feed-videos {
			margin-bottom: 16px;
		}
		
		//.hide-suggests.show-less {
		//	max-width: fit-content;
		//}
	}

	.premium-feed-filters {
		.uls {
			a {
				white-space: normal;
			}
		}
	}
	
	.premium-feed-cats-review-pop {
		.x-body {
			max-width: 400px;
			width: 100%;
		}
		
		h3 {
			height: auto;
		}
		
		.cats-row {
			.max-height-100vh(150px);
			overflow: auto;
			padding-bottom: 20px;
			
			&:before,
			&:after {
				display: none;
			}
			
			.cats-col {
				float: none;
				width: 100%;
			}
		}
		.cats {
			margin: 0 0 18px;
			max-height: none;
			min-height: 12px;
			padding: 0;
			
			&.empty {
				border-bottom: 1px solid #333;
				border-top: 1px solid #333;
			}
		}
	}
	
	
	.suggest-account-preview-pop {
		&.x-overlay.x-overlay-box {
			.x-body {
				padding-top: 15px;
			}
		}
		
		.mozaique-container {
			&,
			.xscroll-container,
			.xscroll-content {
				.max-height-100vh(125px);
			}
			
			&.with-pic {
				&,
				.xscroll-container,
				.xscroll-content {
					.max-height-100vh(175px);
				}
			}
		}
	}
}

// 479px max
@media (max-width: @screen-xxs-max) {
	#page.premium_feed {
		#feed-videos-filters {
			.premium-feed-filters {
				.niv1 {
					width: 50%;
				}
				.show-niv2 {
					overflow: hidden;
					white-space: nowrap;
					width: 100%;
					.label {
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}
			}
		}

		.premium-switch-page-dropdown {
			&.is-expand {
				#memberships-container {
					max-height: 111px;
					overflow-y: scroll;
					display: grid;
					grid-template-columns: 33.33% 33.33% 33.33%;
				}
			}
		}
	}

	.suggest-account-preview-pop {
		&.x-overlay.x-overlay-box {
			.x-body {
				padding-top: 15px;
			}
		}
		
		.mozaique-container {
			&,
			.xscroll-container,
			.xscroll-content {
				.max-height-100vh(125px);
			}
			
			&.with-pic {
				&,
				.xscroll-container,
				.xscroll-content {
					.max-height-100vh(175px);
				}
			}
		}
		
		.account-preview-header {
			height: auto;
			
			.page-link {
				line-height: 22px;
				
				.icon-f {
					display: inline-block;
					float: none;
					line-height: 20px;
					vertical-align: top;
				}
			}
			
			.prof-pic-n-page-link {
				&.with-pic {
					float: left;
					
					.profile-pic,
					.btn {
						display: block;
						float: none;
						width: 64px;
					}
					
					.profile-pic {
						height: 64px;
						
						img {
							height: 64px;
							width: 64px;
						}
					}
					
					.btn {
						display: none;
						//overflow: hidden;
						//margin: 4px 0;
						//padding: 4px 0;
						//white-space: normal;
						//line-height: 12px;
						//font-size: 11px;
						//
						//.icon-f {
						//	padding-right: 3px;
						//}
					}
					
					& + h2 {
						.label {
							overflow: hidden;
							max-height: 48px;
							word-break: break-all;
							white-space: normal;
							.line-clamp(2);
						}
					}
					
					& + h2,
					& ~ .model-info ,
					& ~ .membership-info,
					& ~ .account-actions {
						margin-left: 70px;
					}
					& ~ .model-info,
					& ~ .membership-info {
						width: calc(100% - 70px);
					}
				}
			}
			
			.model-info,
			.membership-info {
				& > * {
					flex-grow: 2;
					margin: 0 5px 0 0;
				}
			}
			
			.membership-info {
				.icf-bar-chart {
					display: none;
				}
				
				.membership-info-detail {
					.icf-question-circle-o {
						font-size: 20px;
					}
				}
			}
			
			&.with-membership-info {
				height: 130px;
				
				.account-actions {
					clear: both;
				}
				
				.prof-pic-n-page-link {
					&.with-pic {
						& ~ .account-actions {
							margin-left: 0;
						}
						
						& + h2 {
							align-items: center;
							display: flex;
							height: 48px;
							justify-content: center;
							padding-right: 15px;
						}
					}
				}
				
				& + .mozaique {
					.max-height-100vh(187px);
				}
			}
			
			h2 {
				line-height: 24px;
				
				& ~ .account-actions {
					margin-top: 8px;
				}
			}
			
			
			@media @media-ratio-vertical {
				&.with-membership-info {
					height: auto;
					min-height: 130px;
					max-height: 150px;
					
					& + .mozaique {
						.max-height-100vh(207px);
					}
				}
				/*h2 + .membership-info {
					.line-clamp(2);
					overflow: hidden;
					white-space: normal;
					word-break: break-all;
				}*/
			}
		}
		&.is-model {
			.account-preview-header {
				&.with-model-info {
					&.with-pic {
						height: auto;
						
						.model-info {
							height: 30px;
						}
					}
				}
				
				&.with-pic {
					height: auto;
				}
				
				.prof-pic-n-page-link {
					&.with-pic {
						.profile-pic {
							height: 100px;
							width: 100px;
							
							img {
								height: 100px;
								width: 100px;
							}
						}
						.btn {
							width: 100px;
						}
						
						& + h2,
						& ~ .model-info,
						& ~ .membership-info,
						& ~ .account-actions {
							margin-left: 110px;
						}
						
						& ~ .model-info,
						& ~ .membership-info {
							width: calc(100% - 110px);
						}
					}
				}
			}
		}
		
		.thumbs-2-cols {
			padding-right: 0;
			
			.thumb-block {
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
		}
	}
	
	.premium-feed-cat-review-pop {
		.category-actions {
			.btn {
				text-align: center;
				width: 100%;
			}
		}
	}
	
	.premium-feed-min-length-filter-pop,
	.premium-feed-categories-filter-pop {
		h3 {
			white-space: normal;
			.line-clamp(2);
		}
	}
	
	.premium-feed-categories-filter-pop {
		.cat-list {
			.max-height-100vh(182px);
		}
	}
	
	.suggest-account-preview-pop,
	.premium-feed-min-length-filter-pop,
	.premium-feed-categories-filter-pop {
		&.x-overlay.x-overlay-box {
			.x-body {
				padding-left: 8px;
				padding-right: 8px;
			}
		}
	}
}