.popup-pornin-indexes {
	ul {
		margin: 0;
	}
	li {
		display: block;
		float: left;
		width: 20%;
	}
}

#page .resps-tabs,
#home-tabs {
	margin: 2px 0 0;
	clear: both;
	height: 34px;
	overflow: hidden;
	.tab-buttons {
		border-bottom: 1px solid @home-tab-border;
		float: none;
		overflow: visible;
		padding: 0;
		li {
			&:not(.report) {
				.btn {
					background: @home-tab-bg;
					border: 1px solid @home-tab-border;
					border-bottom: 0;
					border-radius: 2px 2px 0 0;
					box-shadow: none;
					color: @home-tab-color;
					font-size: 16px;
					font-weight: normal;
					line-height: 21px;
					margin: 0;
					overflow: hidden;
					padding: 5px 12px 6px;
					text-shadow: none;
					transition: all 0.1s;
					
					.icon,
					.icon-f {
						vertical-align: middle;
					}
					
					.icf-cog {
						color: @text-color;
						vertical-align: text-top;
					}
					
					.navbadge {
						background: @theme-color;
						border-radius: 14px;
						color: #fff;
						margin-left: 4px;
						padding: 4px 6px;
						vertical-align: bottom;
					}
					
					&:hover {
						background: @gray-lighterer;
						color: @home-tab-active-color;
						text-shadow: none;
					}
					
					&.active,
					&.active:hover {
						background: @home-tab-active-bg;
						color: @home-tab-active-color;
						margin-bottom: -1px;
						padding-bottom: 7px;
					}
					
					&.premium {
						position: relative;
						background: none;
						border: 0;
						padding: 0 8px;
						line-height: 21px;
						
						.ticket-in {
							display: block;
							font-weight: bold;
							padding: 7px 8px 5px;
							position: relative;
							z-index: 2;
							
							.navbadge {
								margin-bottom: 1px;
							}
						}
						
						.ticket-back {
							bottom: 0;
							left: 0;
							position: absolute;
							right: 0;
							top: 0;
							z-index: 1;
							
							.ticket-back-left,
							.ticket-back-right {
								width: 55%;
								overflow: hidden;
							}
							
							.ticket-back-left,
							.ticket-back-right,
							.icon-f {
								bottom: 0;
								font-size: 34px;
								position: absolute;
								top: 0;
								z-index: 2;
							}
							
							.ticket-back-left {
								&,
								.icon-f {
									left: 0;
								}
							}
							
							.ticket-back-right {
								&,
								.icon-f {
									right: 0;
								}
							}
							
							.icf-ticket-wide {
								z-index: 1;
							}
						}
						
						&.active,
						&.active:hover {
							padding-bottom: 0;
							
							.ticket-in {
								padding-bottom: 6px;
							}
						}
					}
					
					&.home-premium {
						.icon-f {
							display: inline-block;
							font-size: 21px;
							height: 19px;
							line-height: 19px;
						}
					}
					
					&.home-regular {
						img {
							float: left;
							height: 21px;
						}
					}
					
					&.page-title {
						background: none;
						border: 0;
						color: @text-color;
						cursor: default;
						font-size: 25px;
						font-weight: bold;
						line-height: 26px;
						margin-bottom: 0;
						overflow: visible;
						padding-bottom: 0;
						padding-top: 0;
					}
				}
				
				&:last-child {
					margin-right: 0;
				}
				
				&#listing-settings {
					&.pull-right {
						margin-right: 0;
						position: relative;
						z-index: 2;
					}
				}
			}
			&.report {
				background: @body-bg;
				float: right;
				margin: 0;
				position: relative;
				
				.report-search {
					background: @body-bg!important;
					padding: 0 10px;
					white-space: nowrap;
					
					* {
						display: inline-block;
						line-height: 30px;
						vertical-align: top;
					}
					
					&:hover {
						* {
							color: @text-color;
						}
					}
					
					.label {
						padding-left: 4px;
					}
				}
			}
		}
	}
	&.not-logged {
		.tab-buttons {
			border-bottom-color: @home-tab-notlogged-border;
			li .btn{
				&.active,
				&:hover {
					.navbadge {
						background: #fff;
						color: #000;
					}
				}
				&.home {
					background: @home-tab-notlogged-inact-home-bg;
					border-color: @home-tab-notlogged-inact-home-bg;
					&:hover {
						background: @home-tab-bg;
						color: @home-tab-color;
					}
					&.active,
					&.active:hover {
						background: @home-tab-active-bg;
						border-color: @home-tab-notlogged-border;
						color: @home-tab-active-color;
					}
				}
				&.mysubs,
				&.videosilike{
					background: @home-tab-notlogged-bg;
					border-color: @home-tab-notlogged-bg;
					&,
					&:hover {
						&,
						.icon.black-hover.feed,
						.icon.black-hover.thumb-up {
							color: @home-tab-notlogged-color;
						}
					}
					&.active {
						background: @home-tab-notlogged-active-bg;
						border-color: @home-tab-notlogged-border;
						&,
						.icon.black-hover.feed,
						.icon.black-hover.thumb-up {
							color: @home-tab-notlogged-active-color;
						}
					}
				}
				.navbadge {
					background: #999;
				}
			}
		}
	}
	.tab-buttons {
		&.premium-active{
			border-bottom-color: @home-tab-premium-active-border;
		}
		li .btn.premium {
			color: @home-tab-color;
			.ticket-back {
				.icon-f {
					color: @home-tab-bg;
				}
			}
			&:hover,
			&.active,
			&.active:hover {
				color: white;
				.icf-ticket-wide {
					color: @theme-color;
				}
				.navbadge {
					background: #fff;
					color: #000;
				}
			}
		}
	}
	&.video-suggest {
		.tab-buttons {
			border-image: linear-gradient(
				to left,
				rgba(0, 0, 0, 0) 0,
				rgba(0, 0, 0, 0) 12px,
				@home-tab-border 120px,
				@home-tab-border 100%,
			);
			border-image: linear-gradient(
				to left,
				rgba(0, 0, 0, 0) 0,
				rgba(0, 0, 0, 0) 12px,
				@home-tab-border 120px,
				@home-tab-border 100%,
			) 1;
			&.premium-active {
				border-image: linear-gradient(
					to left,
					rgba(0, 0, 0, 0) 0,
					rgba(0, 0, 0, 0) 12px,
					@home-tab-premium-active-border 120px,
					@home-tab-premium-active-border 100%
				);
				border-image: linear-gradient(
					to left,
					rgba(0, 0, 0, 0) 0,
					rgba(0, 0, 0, 0) 12px,
					@home-tab-premium-active-border 120px,
					@home-tab-premium-active-border 100%
				) 1;
			}
		}
		&.not-logged {
			.tab-buttons {
				border-image: linear-gradient(
					to left,
					rgba(0, 0, 0, 0) 0,
					rgba(0, 0, 0, 0) 12px,
					@home-tab-notlogged-border 120px,
					@home-tab-notlogged-border 100%
				);
				border-image: linear-gradient(
					to left,
					rgba(0, 0, 0, 0) 0,
					rgba(0, 0, 0, 0) 12px,
					@home-tab-notlogged-border 120px,
					@home-tab-notlogged-border 100%
				) 1;
			}
		}
	}
	
	&.not-logged,
	&.not-premium {
		.tab-buttons li .btn.premium {
			background: none;
			color: @home-tab-notlogged-color;
			.icon-f {
				color: @home-tab-notlogged-bg;
			}
			&.active,
			&:hover {
				color: @home-tab-notlogged-color;
			}
			&:hover {
				.icf-ticket-wide-n {
					color: @theme-color;
				}
			}
		}
	}
	&,
	&.not-logged,
	&.not-premium {
		.tab-buttons li .btn.premium {
			&:hover,
			&.active,
			&.active:hover {
				color: white;
				.icf-ticket-wide{
					color: @theme-color;
				}
				.navbadge {
					background: #fff;
					color: #000;
				}
			}
			&.active,
			&.active:hover {
				.icf-ticket-wide-n {
					color: @home-tab-premium-active-border;
				}
			}
		}
	}
	#listing-settings {
		.btn {
			background: @home-tab-active-bg;
			border: @home-tab-active-bg;
			cursor: inherit;
			padding-left: 8px;
			padding-right: 8px;
		}
	}
}

#page {
	.resps-tabs {
		margin-bottom: 16px;
	}
	
	.premium-page &.search-page {
		.resps-tabs {
			.tab-buttons {
				li {
					.btn {
						&:not(.premium):not(.page-title):not(.active) {
							background: @home-tab-red-search-inact-bg;
						}
						&.premium:not(.active) {
							.ticket-back {
								.icon-f {
									color: @home-tab-premium-red-search-inact-bg;
								}
							}
						}
					}
				}
			}
		}
	}
}

.users-videos {
	#home-tabs {
		clear: none;
		margin-bottom: 10px;
	}
	.tab-form {
		overflow: hidden;
		border: 1px solid #000;
	}
	.tab-description {
		overflow: hidden;
		p {
			font-size: 14px;
			margin: 0 0 10px;
			padding: 0 2px;
		}
		.legends {
			text-align: center;
			font-weight: bold;
			margin: 0 0 3px;
			color: @red-color;
			font-size: 13px;
			line-height: 1.25;
		}
		.thumbs {
			margin: 0 0 10px;
			img {
				margin-left: auto;
				margin-right: auto;
				border: 4px solid #f00;
				cursor: pointer;
			}
		}
	}
	.x-overlay {
		p {
			text-align: center;
			margin: 0 0 10px;
			font-weight: normal;
		}
		.img-responsive {
			margin: 0 auto;
			border: 2px solid #fff;
		}
		.prev, .next {
			font-weight: bold;
			margin: 0 4px 0 0;
			cursor: pointer;
		}
		.prev {
			float: left;
		}
		.next {
			float: right;
		}
	}
	#content > p {
		font-size: 14px;
	}
	#bottom-buttons {
		margin: 50px 0 0;
		text-align: center;
		.btn, a {
			white-space: normal;
			margin: 4px;
		}
	}
	.eventslist {
		.activity-event {
			margin-top: 15px;
		}
		h4 {
			font-weight: normal;
			font-size: 14px;
			a {
				font-weight: bold;
				color: @theme-color;
			}
			.timestamp {
				display: none;
			}
			a.video-subscribe {
				color: #fff;
			}
		}
		
		.show-less-vids,
		.show-more-less-vids {
			text-align: right;
			.no-more-to-load {
				display: none;
			}
		}
		
		.show-less {
			.thumb-block {
				&.extra-thumb-block {
					display: none;
				}
			}
			.show-less-vids,
			.show-more-less-vids {
				.less {
					display: none;
				}
			}
			.show-more-less-vids {
				.no-more-to-load {
					display: inline;
				}
			}
			&.has-less-21 {
				.show-more-less-vids {
					.no-more-to-load {
						display: none;
					}
				}
			}
		}
		
	}
}

#reload-page-cont,
#subs-home-toggle-cont {
	margin: 20px 0 10px;
	text-align: center;
	.premium-page &.hide-if-premium{
		display: none;
	}
}
#reload-page,
#subs-home-toggle {
	.button-variant(@gray-dark,@gray-lighter,@gray-light2);
	font-size: 18px;
	font-weight: normal;
	.init-ok-btn();
	line-height: 28px;
	margin: 0 4px 4px;
	white-space: normal;
	.icon-f{
		float: left;
		font-size: 24px;
		line-height: 28px;
		margin-right: 8px;
	}
}
