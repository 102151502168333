body {
	
/* ELEMENTS */
	
	.btn-clear {
		background: none;
		border: 0;
		color: @text-color;
		padding: 0;
		text-align: center;
		text-decoration: none;
		
		&:active,
		&:focus,
		&:active:focus {
			outline: 0;
		}
	}
	
/* STRUCTURE */
	
	.width-full-body {
		margin: 0 -@body-out-padding;
		padding: 0 @body-out-padding;
		max-width: calc(~"100vw + 2 * @{body-out-padding}");
	}
	
	.width-main-container {
		margin: 0 auto;
		max-width: @screen-max;
		width: 100%;
	}
	
/* min 1440px */
	@media (min-width: @screen-xlg-min) {
		.width-full-body {
			margin: 0 -@screen-xlg-side-padding;
			width: calc(~"100% + 2 * @{screen-xlg-side-padding}");
		}
		
		.width-main-container {
			padding-left: @screen-xlg-side-padding;
			padding-right: @screen-xlg-side-padding;
			max-width: calc(~"@{screen-xlg-max} + 2 * @{screen-xlg-side-padding}");
		}
	}
	
	
/* HEAD */
	
	.head__top__container {
		align-items: center;
		display: flex;
		justify-content: space-between;
		
		.animated-hamburger {
			display: none;
		}
	}
	
	#header-mobile-right {
		display: flex;
	}
	
	.head__logo {
		margin-right: 4px;
		text-decoration: none;
		white-space: nowrap;
		
		img {
			display: inline-block;
			vertical-align: middle;
		}
		
		#site-logo-red {
			color: @red-color;
			display: inline-block;
			font-size: 48px;
			line-height: 36px;
			vertical-align: middle;
		}
	}
	
	.head__search {
		flex-grow: 2;
		
		#xv-search-form {
			float: none;
			margin: 0 auto;
			max-width: 460px;
			min-width: 100px;
			width: 90%;
		}
	}
	
	.head__btn {
		border-radius: 5px;
		line-height: 12px;
		padding: 8px 5px;
	}
	
	.head__login-btn-group {
		display: flex;
		
		.head__btn {
			display: flex;
			align-items: center;
		}
	}
	
	.head__btn--icf {
		padding-bottom: 5px;
		padding-top: 5px;
		
		& > * {
			font-size: 18px;
			line-height: 18px;
			vertical-align: top;
		}
	}
	
	.head__btn--id {
		display: none;
	}
	
	
	@media (max-width: @screen-max) {
		.head__login-btn-group {
			display: none;
			
			background: @body-bg;
			flex-direction: column;
			max-width: 80%;
			position: fixed;
			right: 3px;
			top: 40px;
			z-index: @zindex-login-menu;
			
			&.is-opened {
				display: flex;
				
				&::before {
					border-color: transparent;
					border-bottom-color: @text-color;
					border-style: solid;
					border-width: 0 5px 5px;
					content: "";
					height: 0;
					position: absolute;
					right: 70px;
					width: 0;
					top: -5px;
				}
			}
			
			.head__btn {
				border-radius: 0;
				display: block;
				margin: 0;
				line-height: 18px;
				text-align: center;
				
				&.head__btn--login {
					background: #666;
					color: #fff;
					font-weight: bold;
				}
			}
		}
		
		.head__btn--id {
			display: inline-block;
		}
	}
	
	.head__btn--join,
	.head__btn--join-free,
	.head__btn--login {
		margin-right: 10px;
		padding: 8px 20px;
	}
	
	.head__btn--join {
		background: @red-color;
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
	}
	
	.head__btn--join-free {
		background: @join-free-btn-bg;
		color: @join-free-btn-color;
		font-weight: bold;
	}
	
	.head__btn--login {
		color: @gray-light;
		padding-left: 10px;
		padding-right: 10px;
		
		.notouch &:hover {
			text-decoration: underline;
		}
	}
	
	.head__btn--settings {
		position: relative;
		
		.flag-small {
			position: absolute;
			right: -3px;
			top: -3px;
			transform: scale(0.75);
		}
	}
	
	.icf-mixed-user-cog {
		display: block;
		position: relative;
		
		.icf-user-o {
			font-size: 20px;
			line-height: 20px;
		}
		
		.icf-cog-o {
			background: @body-bg;
			border-radius: 50%;
			bottom: -2px;
			font-size: 15px;
			height: 15px;
			line-height: 15px;
			position: absolute;
			right: -2px;
			text-align: center;
			width: 15px;
		}
	}
	
	
	.head__menu-line {
		.sub-list {
			display: none;
		}
		
		.head__menu-line__main-menu__lvl1,
		.sub-list a {
			cursor: pointer;
			display: block;
			line-height: 20px;
			text-decoration: none;
			
			.icon-f,
			.nav-btn-picto {
				display: inline-block;
				line-height: 20px;
				margin-right: 7px;
				vertical-align: top;
			}
			
			.icon-f {
				font-size: 1.3em;
				opacity: 0.6;
				
				&:before {
					vertical-align: middle;
					display: block;
				}
				
				&.icf-ticket-red {
					opacity: 1;
					color: @theme-color;
				}
			}
		}


		.head__menu-line__main-menu__lvl1 {
			.flag-small {
				font-size: 1.3em;
				margin-right: 7px;
				margin-top: 4px;
				margin-bottom: 5px;
			}
		}
	}
	
	@mobile_nav_header_height: 40px;
	
	
	#nav * {
		user-select: none;
	}
	
	.mobile_nav_2022(@top_header_height) {
		
		.head__top__container {
			.animated-hamburger {
				display: block;
				font-size: @mobile_nav_header_height;
				float: left;
				
				&.init-ok span {
					background: @text-color;
				}
				
				span {
					background: @gray-bg;
				}
			}
		}
		
		#nav {
			background-color: @mobile-menu-v22-back-bg;
			bottom: 0;
			box-shadow: 0 10px 10px fadeOut(#000, 70%);
			display: none;
			.height-100vh(@top_header_height);
			left: 0;
			.min-height-100vh(@top_header_height + 10px);
			overflow: auto;
			position: fixed;
			top: @top_header_height;
			width: 100%;
			z-index: @zindex-mobile-menu;
			
			&.opened {
				display: block;
			}
			
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				
				&.head__menu-line__main-menu {
					padding-bottom: 20px;
				}
			}
			
			li {
				margin: 0;
				padding: 0;
				flex: 1;
				
				&:first-child .head__menu-line__main-menu__lvl1 {
					border-top: 1px solid @mobile-menu-v22-border;
				}
				
				&.with-sub-list {
					.head__menu-line__main-menu__lvl1 {
						padding-right: 40px;
						position: relative;
						
						&:after {
							.font-family-iconfont();
							content: @icf-caret-down;
							display: inline-block;
							font-size: 0.9em;
							padding-left: 3px;
							transition: transform 0.1s;
							
							position: absolute;
							right: 20px;
							top: 50%;
							transform: translateY(-50%);
						}
						
						&.opened {
							border-bottom-color: @mobile-menu-v22-sub-menu-border;
							position: sticky;
							top: 0;
							z-index: 1;
							
							.icon-f {
								color: @red-color;
								opacity: 1;
							}
							
							&:after {
								transform: translateY(calc(~"-50% + 4px"));
							}
						}
					}
					
					.sub-list {
						&.opened {
							display: block;
						}
						
						li {
							a {
								background-color: @mobile-menu-v22-sub-menu-bg;
								border-color: @mobile-menu-v22-sub-menu-border;
								padding-left: 40px;
							}
						}
					}
				}
			}
			
			.head__menu-line__main-menu__lvl1,
			.sub-list a {
				background-color: @mobile-menu-v22-bg;
				border-bottom: 1px solid @mobile-menu-v22-border;
				color: @mobile-menu-v22-color;
				font-size: 18px;
				padding: 10px 20px;
				
				html.big-ui & {
					padding-bottom: 15px;
					padding-top: 15px;
				}
				
				&.is-current {
					font-weight: bold;
					
					.icon-f,
					.nav-btn-picto {
						opacity: 1;
					}
				}
				
				.icon-f,
				.nav-btn-picto {
					text-align: center;
					width: 26px;
				}

				&.j-m .flag-small {
					margin-bottom: -1px;
					zoom: 1.333;
					margin-left: 1px;
					margin-top: 0;
					margin-right: 7px;
				}
			}
			
			.head__menu-line__main-menu__lvl1--section {
				background: @mobile-menu-v22-section-bg;
				font-size: 16px;
				font-weight: bold;
				line-height: 24px;
				padding: 40px 10px 0;
				
				.icon-f {
					line-height: 24px;
					opacity: 0.6;
					text-align: right;
					vertical-align: text-bottom;
				}
			}
		}
	}
	
	.make-static-header-body-css() {
		&, &.mobile-menu-opened {
			padding-top: 0;
			overflow: visible;
			position: static;
		}
		
		.head__top {
			position: relative;
			width: calc(~"100% + 2 * @{body-out-padding}");
		}
		
		#nav {
			bottom: auto;
			height: auto;
			overflow: visible;
			position: absolute;
		}
	}
	
	&.body--account {
		.mobile_nav_2022(@mobile_nav_header_height);
		
		&.no-scroll {
			overflow: hidden;
			position: fixed;
			width: 100%;
		}
	}
	
	/* min 768px - view desktop */
	@media (min-width: @screen-sm-min) {
		
		.head__top {
			position: relative;
			z-index: @zindex-header-top-top;
		}
		
		.head__choice {
			border-radius: 3px;
			line-height: 18px;
			margin: 0 5px;
			padding: 5px 15px 5px 5px;
			position: relative;
			white-space: nowrap;
			transition: 0.1s box-shadow;
			
			& > * {
				display: inline-block;
				line-height: 18px;
				vertical-align: top;
			}
			
			.flag-small {
				margin: 3px 2px 0;
			}
			
			.notouch &:hover {
				box-shadow: 0 0 5px fadeOut(@text-color, 90%);
				
				&:active {
					box-shadow: 0 0 3px fadeOut(@text-color, 70%);
				}
			}
			
			&:active {
				box-shadow: 0 0 5px fadeOut(@text-color, 85%);
			}
			
			&:after {
				.font-family-iconfont();
				display: inline-block;
				content: @icf-caret-down;
				font-size: 12px;
				position: absolute;
				right: 2px;
				top: calc(~"50% - 6px");
			}
		}
		
		.head__choice--localisation {
			.icon-f {
				font-size: 14px;
			}
			
			@media (min-width: 1650px) {
				.icon-f {
					color: @gray-light2;
					margin-right: 4px;
				}
			}
			@media (max-width: 1649px) {
				.label {
					display: none;
				}
			}
			@media (max-width: @screen-max) {
				.txt {
					display: none;
				}
			}
		}
		
		.head__choice--main-cat {
			.icon-f {
				font-size: 14px;
			}

			.icon-flag-gay {
				margin-top: 2.5px;
			}
		}
		
		.head__btn--settings--account {
			background: @red-color;
			color: #fff;
			font-weight: bold;
			padding: 8px 14px;
			text-transform: uppercase;
			
			.icon-f {
				display: none;
			}
			
			.label {
				font-size: 12px;
				line-height: 12px;
			}
		}
		
		.head__btn--theme-switch { /* .head__choice--language && .head__choice--localisation && .head__choice--main-cat*/
			color: @body-invert-bg;
		}
		
		&:not(.body--account) {
			.head__menu-line {
				box-shadow: 0 0 20px -10px fadeOut(@text-color, 80%);
				min-height: 30px;
				margin-bottom: 20px;
				margin-top: 14px;
				position: relative;
				z-index: @zindex-dropdown-top-menu;
				
				.head__menu-line__main-menu {
					display: flex;
					min-height: 30px;
					justify-content: space-around;
					margin: 0;
					padding: 0;
					width: 100%;
					
					& > li {
						flex-grow: 2;
						min-width: 65px;
					}
				}

				.head__menu-line__main-menu__lvl1 {
					align-items: center;
					display: flex;
					justify-content: center;
				}
				
				.head__menu-line__main-menu__lvl1,
				.sub-list a {
					font-weight: bold;
					font-size: 13px;
					padding: 5px 10px;
					text-align: center;
				}
				
				a {
					.notouch &:hover {
						background: @body-bg;
						box-shadow: 0 0 0 1px @gray-lighter;
						
						.icon-f {
							color: @red-color;
							opacity: 1;
						}
					}
				}
				
				.with-sub-list {
					.head__menu-line__main-menu__lvl1 {
						.main-cats-title {
							&:after {
								.font-family-iconfont();
								content: @icf-caret-down;
								display: inline-block;
								font-size: 0.9em;
								padding-left: 3px;
								transition: transform 0.1s;
							}
						}
					}
					
					&:hover {
						@keyframes main_menu_display_delay {
							0% {
								height: 0;
								opacity: 0;
								overflow: hidden;
							}
							99.99% {
								height: 0;
								opacity: 0;
								overflow: hidden;
							}
							100% {
								height: auto;
								opacity: 1;
								overflow: initial;
							}
						}
						
						.sub-list {
							animation: main_menu_display_delay .325s linear;
							background: @body-bg;
							box-shadow: 0 0 20px -10px fadeOut(@text-color, 80%), 10px 0 20px -10px fadeOut(@text-color, 80%);
							display: block;
							column-width: 230px;
							left: 50%;
							margin: 0;
							max-width: @screen-max;
							padding: 15px 0 20px;
							position: absolute;
							top: 100%;
							transform: translateX(-50%);
							width: 100%;
							z-index: 1;
							
							
							/* min 1440px */
							@media (min-width: @screen-xlg-min) {
								max-width: @screen-xlg-max;
								padding-left: @screen-xlg-side-padding;
								padding-right: @screen-xlg-side-padding;
								//max-width: calc(~"@{screen-xlg-max} + 2 * @{screen-xlg-side-padding}");
							}
							
							a {
								text-align: left;
								padding: 5px 20px;
								
								.notouch &:hover {
									background: @gray-lighter;
									text-decoration: none;
								}
							}
						}
						
						.head__menu-line__main-menu__lvl1 {
							background: @body-bg;
							box-shadow: -1px 0 0 @gray-lighter, 1px 0 0 @gray-lighter, 0 -1px 0 @gray-lighter;
							position: relative;
							z-index: 2;
							
							.main-cats-title {
								&:after {
									transform: translateY(2px);
								}
							}
							
							.icon-f {
								color: @red-color;
								opacity: 1;
							}
						}
					}
				}
			}
		}
		
		&.body--account {
			.make-static-header-body-css();
			
			#nav {
				max-width: 500px;
			}
			
			#site-language {
				margin-right: auto;
			}
		}
	}
	
	/* max 767px - view mobile */
	@media (max-width: @screen-all-mobile) {

		padding-top: @mobile_nav_header_height;
		
		&.mobile-menu-opened,
		&.header-site-settings-menu-opened {
			overflow: hidden;
			max-width: 100%;
		}
		
		&.opera-mini {
			.make-static-header-body-css();
		}
		
		&,
		&.mobile-menu-opened,
		&.header-site-settings-menu-opened {
			&.body--video {
				.make-static-header-body-css();
			}
			
			@media @media-ratio-horizontal {
				.make-static-header-body-css();
			}
		}
		
		
		.head__top {
			background-color: @body-bg;
			padding: 0 4px;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: @zindex-mobile-header;
		}
		
		.head__top__container {
			flex-wrap: wrap;
			height: @mobile_nav_header_height;
		}
		
		.head__logo {
			margin: 0 auto;
			
			&.short {
				overflow: hidden;
			}
		}
		
		.head__btn--theme-switch,
		.head__choice { /* .head__choice--language && .head__choice--localisation && .head__choice--main-cat*/
			display: none;
		}
		
		.head__btn--settings--account {
			.label {
				display: none;
			}
		}
		
		.head__btn--icf {
			& > * {
				line-height: 22px;
				font-size: 22px;
			}
		}
		
		#header-mobile-search-toggle {
			margin-left: 4px;
			width: 30px;
		}
		
		#header-mobile-right {
			justify-content: flex-end;
			
			.head__btn--icf:not(:last-child) {
				margin-right: 4px;
			}
		}
		
		.head__login-btn-group {
			&.is-opened {
				&::before {
					right: 48px;
				}
			}
		}
		
		#home-search,
		.head__search {
			background: @body-bg;
			flex-grow: 0;
			margin: 0 -@body-out-padding / 2;
			order: 90;
			padding: 0 @body-out-padding / 2;
			width: calc(~"100% + @{body-out-padding}");
			
			#xv-search-form {
				height: 51px;
				overflow: hidden;
				position: relative;
				
				width: 96%;
				
				&.mobile-hide {
					display: block;
					height: 0;
					
					div.cont {
						opacity: 0;
					}
				}
				
				div.cont {
					bottom: 0;
					left: 0;
					opacity: 1;
					padding: 0;
					position: absolute;
					transition: all 0.5s;
					width: 100%;
					
					div {
						margin: 0 auto;
						max-width: 350px;
						padding: 8px 8px 4px;
					}
				}
			}
		}
		
		&.body--search {
			#header-mobile-search-toggle {
				display: none;
			}
			
			.head__search {
				#xv-search-form {
					&.mobile-hide {
						height: 51px;
						
						div.cont {
							opacity: 1;
						}
					}
				}
			}
		}
		
		.mobile_nav_2022(@mobile_nav_header_height);
	}

	/* min 768px   //   max 1280px */
	@media (min-width: @screen-sm-min) and (max-width: @screen-max) {
		ul.head__menu-line__main-menu {
			.head__menu-line__main-menu__lvl1 {
				flex-direction: column;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				.icon-f {
					margin: 0;
				}

				.main-cats-title {
					display: inline-block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
				}
			}
		}
	}
}