// xscroll
@xscrollbar-back-bg:		 		fadeout(@gray-light2, 80%);
@xscrollbar-back-thickness: 		12px;
@xscrollbar-back-margin-content: 	6px; // margin on the content side
@xscrollbar-back-margin-side: 		2px; // margin on the other side
@xscrollbar-bar-bg:			 		fadeout(@gray-light, 50%);
@xscrollbar-bar-thickness: 			8px;

.xscroll-container {
	overflow: hidden;
	position: relative;
	z-index: 2;
	
	&.xscroll-autohide {
		.xscroll-v,
		.xscroll-h {
			opacity: 0;
			transition: opacity .2s ease;
		}
		
		&.xscroll-autohide-show {
			.xscroll-v,
			.xscroll-h {
				opacity: 1;
			}
		}
	}
}

.xscroll-content {
	overflow: auto;
	max-height: 100%;
	max-width: 100%;
	position: relative;
	
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	
	&::-webkit-scrollbar {
		display: none;
	}
}

.xscroll-v-added .xscroll-v,
.xscroll-h-added .xscroll-h {
	background: @xscrollbar-back-bg;
	border-radius: @xscrollbar-back-thickness / 2 - 1;
	cursor: grab;
	position: absolute;
	
	.xscroll-grab {
		background: @xscrollbar-bar-bg;
		border-radius: @xscrollbar-bar-thickness / 2;
		cursor: grab;
		position: absolute;
	}
	
	&.is-grabbed {
		&,
		.xscroll-grab {
			cursor: grabbing;
		}
	}
}

.xscroll-v-added {
	padding-right: @xscrollbar-back-thickness + @xscrollbar-back-margin-content + @xscrollbar-back-margin-side;
	
	.xscroll-content {
		margin-right: -50px;
		max-width: calc(~"100% + 50px");
		padding-right: 50px;
	}
	
	.xscroll-v {
		bottom: 0;
		right: @xscrollbar-back-margin-side;
		top: 0;
		width: @xscrollbar-back-thickness;
		
		.xscroll-grab {
			right: (@xscrollbar-back-thickness - @xscrollbar-bar-thickness) / 2;
			width: @xscrollbar-bar-thickness;
		}
	}
}

.xscroll-h-added {
	padding-bottom: @xscrollbar-back-thickness + @xscrollbar-back-margin-content + @xscrollbar-back-margin-side;
	
	.xscroll-content {
		margin-bottom: -50px;
		max-height: calc(~"100% + 50px");
		padding-bottom: 50px;
	}
	
	.xscroll-h {
		bottom: @xscrollbar-back-margin-side;
		height: @xscrollbar-back-thickness;
		left: 0;
		right: 0;
		
		.xscroll-grab {
			bottom: (@xscrollbar-back-thickness - @xscrollbar-bar-thickness) / 2;
			height: @xscrollbar-bar-thickness;
		}
	}
}