.profile-page {
	#header {
		.white-stripe {
			.header-icons {
				#mobile-login-btn {
					background: none;
				}
			}
		}
	}
}

#main .page-title.channel{
	font-size: 18px;
	padding: 6px 4px;
	.icf-ticket-red {
		font-size: 32px;
		line-height: 25px;
	}
}
#profile-search-filters-summary{
	#profile-search-result-count{
		font-size: 14px;
		padding: 4px 0;
	}
	.btn-xs{
		font-size: 14px;
		padding: 3px 14px;
	}
}

#profile-title {
	margin-top: 0px;
	max-width: none;
	.profile-pic {
		.external-link.mobile-hide{
			display: none;
		}
	}
	#profile-action {
		margin-top: 18px;
		position: absolute;
		right: 0;
	}

	.right-container {
		align-items: flex-start;
		padding: 1px 0 0;
	}
	
	&,
	&.has-banner {
		h2 {
			background: none;
			bottom: auto;
			border-radius: 0;
			color: @text-color;
			float: none;
			height: auto;
			left: auto;
			line-height: 26px;
			padding: 4px 15px 0 0;
			position: static;
			white-space: normal;
			
			> * {
				line-height: 26px;
			}
			
			.mobile-user-actions {
				display: inline-flex;
				vertical-align: middle;
			}
			
			.flag {
				float: none;
				margin: -4px 0;
				&.flag-p1,
				&.flag-p2,
				&.flag-p3,
				&.flag-p4,
				&.flag-p5,
				&.flag-p6,
				&.flag-p7,
				&.flag-p8,
				&.flag-p9,
				&.flag-m1,
				&.flag-s1 {
					width: 30px;
					height: 20px;
					margin-top: 2px;
					border: none;
				}
			}
			
			small {
				color: @text-color;
				font-size: 65%;
			}
			
			br {
				display: inline;
				
				&.mobile-hide {
					display: none;
				}
			}
			
			.fan-video {
				background: #000;
				color: #fff;
			}
			
			a.more-aliases {
				color: @text-color;
				
				&:hover {
					color: @text-color;
				}
			}
			
			.flag-vspacer {
				margin-right: -6px;
			}
			
			.user-subscribe,
			.sub-strip,
			.friend-request-cont {
				display: inline-block;
				margin: 0 0 0 4px;
				position: static;
				top: auto;
				right: auto;
				vertical-align: middle;
			}
			
			.friend-request-cont {
				.btn-group,
				.btn {
					float: left;
					margin-bottom: 0;
				}
			}
			
			.profile-maincat-switch {
				background: @body-bg;
				
				&:hover {
					background: @gray-lighterer;
				}
			}
		}
	}
	
	.embed-responsive {
		&.banner-slider {
			.banner-slide:not(.banner-personal) {
				font-size: 20px;
			}
		}
		
		//.embed-responsive-item {
		//	&.access-red {
		//		&:after,
		//		&:before {
		//			width: 40px;
		//		}
		//		.btn {
		//			font-size: 22px;
		//			padding-bottom: 2px;
		//			padding-top: 2px;
		//		}
		//	}
		//}
	}
	&.has-banner {
		.embed-responsive {
			margin-bottom: 4px;
			.add-banner {
				text-align: center;
				left: 8px;
				top: 0;
				font-size: 16px;
				line-height: 16px;
			}
		}
		.profile-pic {
			position: relative;
			top: auto;
			bottom: auto;
			left: auto;
			float: left;
			margin-right: 10px;
			margin-bottom: 4px;
			[dir="rtl"] & {
				float: right;
				margin-left: 10px;
				margin-right: 0;
			}
			img {
				position: static;
				top: auto;
				bottom: auto;
				left: auto;
				height: 60px;
			}
		}
		.btn-link {
			background: none;
		}
	}
}

@media @media-ratio-vertical {
	#header-about-me {
		.line-clamp(2);
		max-height: 50px;
		
		&.expanded {
			max-height: none;
			.no-line-clamp-block();
		}
	}
}

#profile-tabs {
	overflow: hidden;
	
	.tab-buttons {
		li {
			.navbadge {
				display: none;
			}
			#tab-videos .navbadge,
			#tab-videos2 .navbadge,
			#tab-activity .navbadge,
			#tab-posts .navbadge,
			#tab-videos-red .navbadge {
				display: inline;
			}
			.btn {
				&.desktop-active {
					padding: 3px 12px;
					background: #000;
					color: #fff;
					margin-bottom: -2px;
				}
				&.desktop-active:hover,
				&.mobile-active {
					padding: 4px 12px;
					margin-bottom: -1px;
					background: #fff;
					color: #000;
					text-shadow: none;
					.navbadge {
						background: @theme-color;
					}
				}
			}
		}
	}
	
	.filters {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: -1px 0px 12px;
		
		.content-filters {
			display: flex;
			flex-grow: 2;
			justify-content: space-around;
			margin: 3px 5px;
			order: -1;
			
			& > span {
				flex-shrink: 2;
				margin-right: 0;
				padding: 0 4px;
				
				&.content-filter-k-uploadsonly {
					flex-shrink: 1;
				}
				
				&.content-filter-k-unlocked {
					flex-shrink: 3;
				}
			}
			
			.with-checkbox-toggle {
				align-items: center;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
			}
			
			.label {
				font-size: 11px;
				line-height: 16px;
				white-space: normal;
			}
			
			.toggle {
				&, &:first-child {
					margin: 0 4px;
				}
				& + .icon-f {
					margin-right: 4px;
				}
			}
		}

		.select-filter-list-mobile {
			width: 100%;
			margin-right: 10px;
			margin-top: 6px;
			.x-dropdown-selected-option {
				justify-content: flex-start;
			}
			.x-dropdown.default {
				margin: 0px 5px;
			}
		}
		
		.main-cat-filter {
			order: 2;
			
			& + .sort-filter {
				margin: 0px 5px;
        		min-width: calc(50% - 10px);
			}
			
			.profile-maincat-switch-cont {
				margin: 0 5px 0 auto;
				
				.profile-maincat-switch {
					padding-top: 0;
					
					.icon-f {
						font-size: 16px;
						vertical-align: middle;
					}
				}
				
				&.all-mobile-content-filter {
					line-height: 36px;
				}
			}
		}
	}
	
	.activity-event {
		.profile-maincat-switch-cont {
			margin: -8px 0 7px;
			
			& + * {
				clear: both;
			}
		}
		
		.mozaique.red-line {
			overflow: visible;
		}
	}
	
	.mozaique {
		.top-message {
			font-size: 14px;
		}
	}
}

@media @media-ratio-vertical {
	#profile-tabs {
		.sticky-tabs {
			&.is-sticky {
				top: 40px;
			}
		}
	}
}

//.dropdown-filters-pop {
//	&.all-mobile-content-filter.has-main-cat-switch {
//		width: calc(~"100% - (2 * @{body-out-padding}) - 55px");
//	}
//}

#home-tabs,
#profile-search-form {
	& + .mobile-show {
		margin-top: 16px;
	}
}

.profile-owner #profile-tabs .tabs {
	padding-top: 30px;
	.tab-actions {
		top: -26px;
	}
	.pfinfo-actions {
		margin-top: -23px;
	}
}

#tabAboutMe {
	.pfinfo-col {
		font-size: 13px;
		padding: 0 15px;
		
		[dir="rtl"] & {
			&#pfinfo-col-col2 {
				padding-right: 15px;
			}
		}
	}
	
	#pfinfo-col-pict {
		padding-right: 15px;
		z-index: 10;
		float: right;
		width: auto;
		white-space: nowrap;
		[dir="rtl"] & {
			padding-left: 15px;
			padding-right: 0;
			float: left;
		}
		.profile-pic {
			display: none;
		}
	}
	.rating-box {
		margin-top: 2px;
		.rating-bar-block {
			min-width: 70px;
		}
	}
	#pfinfo-col-col1,
	#pfinfo-col-col2 {
		float: none;
	}
	
	.bottom-actions {
		float: none;
		text-align: center;
		padding: 8px 0 0;
		border-color: @gray-light;
	}
	
	[dir="rtl"] & {
		.bottom-actions {
			float: none;
			text-align: center;
		}
	}
}

.prof-tab-metadata {
	p {
		float: none;
	}
	.rating-box {
		float: none;
		margin-left: 0;
	}
}

.activity-event {
	.act-achievement {
		font-size: 40px;
	}
	.header {
		h4 {
			a {
				&.user-subscribe {
					margin-top: -2px;
				}
			}
		}
	}
}


.voters-list {
	.voters-list-votes {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		text-align: center;
		width: 100%;
		
		& > span {
			padding: 0 10px;
		}
	}
}