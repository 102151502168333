.search-box {
	padding: 5px 20px 10px 20px;
	color: @text-color;
	border-top: 1px solid @gray-bg;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none;

	.x-popup-content & {
		margin-bottom: -10px;
	}

	.is-hidden + & {
		padding-top: 0;
		border-top : none;
	}
}
.search-box__title {
	padding: 0;
	margin-bottom: 5px;
	font-size: 14px;
}
.search-box__form {
	position: relative;
}
.search-box__input {
	position: relative;
	width: 100%;
	margin: 0;
	padding: 2px 25px 2px 5px;
	font-size: 14px;
	color: @search-header-inp-color;
	background: @search-header-inp-bg;
	border: 2px solid @search-header-inp-border;
	border-radius: 3px;
	z-index: 0;

	&:focus, &:active, &:focus:active {
		outline: 0 !important;
		box-shadow: none !important;
		border-color: @search-header-inp-border;
	}
}
.search-box__reset {
	position: absolute;
	top: 5px;
	right: 0;
	background: none;
	border: none;
	z-index: 1;

	&.is-hidden {
		display: none;
	}

	.icon-f {
		color: #000000;
	}
}
.search-box__list {
	margin: 0 -20px;
	padding: 0;
}
.search-box__item {

	&:hover {
		a {
			background: @gray-bg;
		}
	}

	&.is-active,
	&.is-active:hover {
		a {
			background: @gray-lighter;
		}
	}

	& > a,
	& > strong {
		display: block;
		font-size: 14px;
		padding: 5px 20px;
		text-decoration: none;
	}

	.x-popup & > a,
	.x-popup & > strong {
		color: @text-color;
	}
}
.search-box__item--not-found {
	.x-popup & > strong {
		color: @red-color;
	}
}

@media (max-width: @screen-all-mobile) {
	.search-box__input {
		font-size: 16px;
	}
	.search-box__reset {
		width: 32px;
		height: 32px;
		top: 0;
		line-height: 32px;
	}
}