body:not(.body--with-old-header),
body.body--with-old-header {
	#page {
		&.search-page {
			.bg-title {
				&.black {
					margin-top: 4px;
				}
			}
		}
	}
}

#page.search-page {
	#header-mobile-search-toggle {
		display: none;
	}
	#xv-search-form {
		display: block;
		height: 55px;
		.cont{
			opacity: 1;
		}
	}
	.page-title {
		.report-search {
			* {
				line-height: 24px;
			}
		}
	}
}

#search-channel-banner {
	margin: 4px 0;
	a span {
		&,
		&.channelname {
			font-size: 14px;
		}
		&.nbvids {
			top: 27px;
		}
	}
}

#search-page-filters {
	.filters-column {
		width: 50%;
		border: 0;
		h4 {
			margin: 0 0 3px;
		}
		ul {
			margin: 0;
		}
		li {
			padding: 3px 0;
		}
		&:nth-child(2) {
			clear: left;
			margin-top: 5px;
		}
		&:last-child {
			float: none;
			width: auto;
		}
	}
}

#search-associates {
	margin-top: 0;
	padding: 2px 0;
	
	strong {
		font-size: 13px;
	}
	
	strong,
	span a {
		padding: 4px 6px;
	}
	
	.size-0,
	.size-1,
	.size-2 {
		display: none;
	}
	
	.size-3,
	.size-4 {
		font-size: 13px;
	}
	
	.show-more {
		display: inline;
	}
	
	.report-search {
		.mobile-hide {
			display: none;
		}
	}
	
	&.mobile-expanded {
		strong {
			font-size: 13px;
		}
		.size-0,
		.size-1,
		.size-2 {
			display: inline-block;
		}
		.size-0 { font-size: 12px; }
		.size-1 { font-size: 13px; }
		.size-2 { font-size: 14px; }
		.size-3 { font-size: 15px; }
		.size-4 { font-size: 16px; }
		.show-more {
			display: none;
		}
	}
}

.banner-game {
	margin: 0 auto;
}


.report-search-words {
	max-width: 400px;
	
	ul {
		li {
			float: none;
			width: 100%;
		}
	}
}