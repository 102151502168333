
.pagination {
    margin: 6px 0;
    overflow: hidden;
	clear: left;
	white-space: nowrap;
    ul {
		margin: 0;
        padding: 0 0 0 1px;
        vertical-align: middle;
		text-align: center;
        li {
            display: inline-block;
            padding: 0;
			margin: 0 5px;
            a {
                border: 1px solid @pagination-border;
                display: inline-block;
				font-size: 14px;
				margin-bottom: 2px;
                padding: 4px @pagination-side-padding;
                text-decoration: none;
				transition: all 0.15s;
				&,
				&.no-page:hover {
					background-color: @pagination-bg;
					color: @pagination-color;
				}
				&:hover{
					background-color: @pagination-active-bg;
					color: @pagination-active-color;
				}
				&.active {
					background-color: @pagination-active-bg;
					border-color: @pagination-active-border;
					color: @pagination-active-color;
					cursor: default;
					font-weight: bold;
					padding-left: @pagination-active-side-padding;
					padding-right: @pagination-active-side-padding;
				}
				&.no-page {
					background: none;
					border: 0;
					font-weight: bold;
					padding: 5px ceil(@pagination-side-padding / 2);
					* {
						text-decoration: underline;
					}
					&:hover{
						color: @pagination-no-page-hover-color;
						* {
							text-decoration: none;
						}
					}
					.icon-f {
						vertical-align: middle;
						text-decoration: none;
					}
				}
			}
			&.no-page {
				margin: 0;
				a {
					display: inline-block;
					font-size: 14px;
					margin-bottom: 2px;
					padding: 5px 0;
					text-decoration: none;
					border: 0;
					background: none;
					color: @pagination-color;
					cursor: default;
				}
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
	
			.nbr-per-page {
				background-color: @pagination-bg;
				border: 1px solid @pagination-border;
				color: @pagination-color;
				display: inline-block;
				font-size: 14px;
				height: 31px;
				margin-bottom: 2px;
				padding: 4px @pagination-side-padding;
				text-decoration: none;
				
				&:hover {
					background-color: @pagination-bg;
					color: @pagination-color;
				}
		
				&:focus {
					font-size: 14px;
				}
			}
        }
    }
	&.align-left {
		ul {
			text-align: left;
			li:first-child {
				margin-left: 0;
			}
		}
	}
	&.big {
		ul {
			li a {
				font-size: 18px;
				padding: 0 8px;
			}
		}
	}
	&.ordered-label-list{
		ul{
			li{
				margin: 0 6px 5px 0;
				a{
					background: @nav-dSktp-btn-bg;
					border: 0;
					color: @nav-dSktp-btn-color;
					font-size: 14px;
					font-weight: normal;
					line-height: 26px;
					margin: 0;
					text-decoration: none;
					&, &.no-page {
						padding: 0 6px;
					}
					&:hover {
						background: @nav-dSktp-btn-hover-bg;
						color: @nav-dSktp-btn-hover-color;
						text-decoration: none;
					}
					&.active{
						background: @nav-dSktp-btn-main-bg;
						color: @nav-dSktp-btn-main-color;
						cursor: default;
						font-weight: bold;
					}
					&.no-page {
						background: none;
						border: 0;
						font-weight: bold;
						text-decoration: underline;
						&:hover{
							color: @pagination-no-page-hover-color;
							text-decoration: none;
						}
					}
				}
				
				&.no-page {
					a {
						padding: 0;
						background: none;
						cursor: default;
						&:hover {
							color: @nav-dSktp-btn-color;
						}
					}
				}
			}
		}
	}
}

.x-overlay-box {
	&:not(.x-overlay-body-bg) {
		.x-content .pagination {
			ul {
				li {
					a {
						&,
						&.no-page:hover {
							background-color: #000;
							color: #fff;
						}
						
						&:hover {
							background-color: @nav-dSktp-btn-hover-bg;
						}
						
						&.active {
							background-color: #555;
							border-color: #555;
							padding-left: @pagination-side-padding;
							padding-right: @pagination-side-padding;
						}
					}
				}
			}
		}
	}
	&.x-overlay-body-bg {
		.x-content .pagination {
			ul {
				li {
					a {
						&,
						&.no-page:hover {
							background-color: @pagination-bg;
							color: @pagination-color;
						}
						
						&:hover {
							background-color: @pagination-active-bg;
							color: @pagination-active-color;
						}
						
						&.active {
							background-color: @pagination-active-bg;
							border-color: @pagination-active-border;
							color: @pagination-active-color;
						}
					}
				}
			}
		}
	}
}
