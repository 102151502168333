@import './iconfont_chars.less';

.video-tags,
#suggestions_tags_list,
#suggestions_removed_tags_list,
#suggestions_models_list,
.video-tags-list {
    a, li {
        position: relative;
        cursor: pointer;
    }

    .line-through, .line-through a, .line-through button, .line-through .name {
        text-decoration: line-through !important;
    }

    .added {
        .button-add-suggestion, .name {
            font-style: italic;
        }
    }
}

.model-list-container {
    flex: 1 1 100%;

    #models_list {
        position: absolute;
        top: 32px;
        width: 100%;
        z-index: 3;
        display: block;

        .super-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 0;

            .small-padding {
                padding: 8px 0 5px 0;
            }
			
			.btn {
				white-space: normal;
			}

            .container-list-models-suggestion {
                padding: 10px;
                margin-top: 8px;
            }

            .container-list-models-suggestion {
                .mozaique {
                    max-height: 350px;
                    overflow-y: auto;
					
					.card {
						cursor: pointer;
					}
					.loader {
						display: block;
						font-size: 50px;
						opacity: 0.5;
						text-align: center;
						width: 100%;
					}
					.is-error {
						color: @gray-dark;
						font-size: 0.9em;
						margin: 0 20px 10px;
					}
                }
				
				&.with-to-scroll {
					.to-scroll {
						max-height: 350px;
						overflow-y: auto;
						overflow-x: hidden;
					}
					.xscroll-content .to-scroll {
						overflow-y: visible;
						overflow-x: visible;
					}
					
					.mozaique {
						max-height: none;
						overflow-y: visible;
					}
				}
            }
        }
    }
}


#suggestions_models_list, #models_list .mozaique {
	.line-through > .icf-ban {
		display: block;
	}
	
	.card {
		//height: 170px;
		padding: 5px;
	
		.card-img {
			background: @background-black;
	
			.overlay-container {
				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
	
				background-position: center !important;
				background-repeat: no-repeat !important;
				background-size: contain !important;
				//height: 4vw;
				height: 60px;
				position: relative;
	
				.overlay2 {
					position: relative;
					justify-content: center;
					text-align: center;
					font-size: 3rem;
					width: 100%;
					height: 100%;
	
					span.icon-f.icf-ban {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
	
				.overlay {
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					display: flex;
					justify-content: flex-end;
	
					.icf-ban {
						position: absolute;
					}
	
					.metadata-remove {
						border-radius: 0;
						cursor: pointer;
						font-size: 15px;
						font-weight: bolder;
						height: 25px;
						padding: 5px 0 0;
						text-align: center;
						width: 25px;
						z-index: 1;
					}
				}
			}
	
			//@media screen and (max-width: 1024px) {
			//	.overlay-container {
			//		height: 22vw;
			//	}
			//}
		}
	
		.card-footer {
			.name {
				font-weight: bold;
				font-size: 14px;
				height: 21px;
				letter-spacing: 0;
				overflow: hidden;
				text-align: center;
				text-overflow: ellipsis;
			}
			a {
				color: @text-color;
			}
			.highlighted {
				background: fadeOut(@theme-color, 70%);
			}
		}
	}
}

.metadata-suggest-popup {
	@media screen and (min-width: 1024px) {
		&.half-sized {
			.x-body {
				width: 840px;
			}
		}
	}
    .card-footer a {
        color: #fff !important;
    }
    #suggestions_tags_list, #suggestions_removed_tags_list {
        list-style-position: inside;
        list-style-type: none;
        margin: 10px 0;
        padding: 0;
        @media screen and (max-width: 1024px) {
            li[data-type=tag] a {
                padding: 8px !important;
            }
        }
    }

    .alert {
        background: inherit;
        border: none;
    }

    .panel-models-suggestions {
		align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;

        .metadata-suggest-box {
            display: inline-flex;
        }

        .validate-suggest-tag, .validate-suggest-model {
			align-items: center;
            flex-grow: 1;
            display: inline-flex;
			margin-bottom: 0;
            justify-content: center;
			
            @media screen and (max-width: 1024px) {
                padding: 5px 0;
            }
        }
    }

    .relative {
        position: relative;
    }

    @media screen and (max-width: 1024px) {
        #models_suggestions {
            clear: both;
        }
    }

    .panel-search-suggestions {
        input[type=button] {
            &:focus {
                outline: none;
            }
        }
    }
}


#add_new_form_access,
.cancel-model-info-form {
	.icon-f {
		vertical-align: text-bottom;
	}
}

.models-suggestion-model-create-pop {
	&.x-overlay {
		overflow: auto;
		
		.x-body {
			//background: @body-bg;
			//box-shadow: 0 0 15px fadeOut(@text-color, 50%);
			//
			//* {
			//	color: @text-color;
			//}
			//
			.x-content {
				.btn-primary {
					.button-variant(@btn-primary-color, @btn-primary-bg, @btn-primary-border);
				}
			}
		}
	}
}

.container-model-info-form {
	max-width: 1000px;
	
	.overlay {
		align-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.7);
		bottom: 0;
		opacity: 0;
		display: none;
		flex-wrap: wrap;
		justify-content: center;
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		z-index: 5;
		
		.icon-f {
			font-size: 80px;
			flex: 1 1 100%;
			
			&.icf-hour-glass {
				font-size: 50px;
				margin-bottom: 10px;
			}
		}
	}
	
	h2 {
		margin: 20px 0;
	}
	
	h3 {
		margin: 20px 0 10px;
	}
	
	
	.input-group-btn {
		display: flex;
		justify-content: center;
		position: relative;
		flex-direction: row-reverse;
		width: 100%;

		&.with-thumb-select {
			flex-direction: column;
			align-items: center;
		}
		&.thumb-expanded {
			min-height: 300px;
		}
		
		&.is-error {
			input,
			select,
			textarea {
				border-color: @btn-danger-border;
			}
			
			&:after {
				.font-family-iconfont();
				bottom: 0;
				color: @state-danger-text;
				content: @icf-close;
				font-size: 14px;
				position: absolute;
				right: 0;
				z-index: 10;
			}
			
			&.with-exemption-toggle {
				&:after {
					right: 44px;
				}
			}
		}

		&.missing-required {
			input,
			select,
			textarea {
				border-color: @btn-danger-border;
			}

			&:after {
				.font-family-iconfont();
				bottom: 0;
				color: @state-danger-text;
				content: @icf-close;
				font-size: 14px;
				position: absolute;
				right: 0;
				z-index: 10;
			}

			&.with-exemption-toggle {
				&:after {
					right: 44px;
				}
			}
		}

		.pic-choose-thumb {
			display: flex;
			align-items: stretch;
			justify-content: space-around;
			font-size: initial;

			label.btn {
				white-space: break-spaces;
				width: 45%;

				display: flex;
    			align-items: center;
			}
		}
	}

	label {
		margin: 5px 0;
	}
	.legend {
		opacity: 0.6;
	}
	
	input:not([type=file]),
	select,
	textarea {
		font-size: 17px;
	}
	
	input,
	select {
		height: 38px;
	}
	
	[disabled=disabled] {
		-webkit-appearance: none;
		border: 0;
	}
	
	.model-info-input-block,
	.model-info-file-block {
		margin-bottom: 10px;
	}
	
	.model-info-input-block {
		.reject-reasons {
			margin: 0;
			padding: 0;
			color: red;
			list-style: none;

			&.updated {
				color: @text-color;
			}
		}
	}
	
	.model-info-exemption-toggle,
	.model-info-hidden-unchanged {
		align-items: center;
		background: transparent;
		border: 0;
		color: @text-color;
		display: flex;
		font-size: 20px;
		position: relative;
	}
	.model-info-hidden-unchanged {
		padding: 0 6px 0 10px;
	}
	.model-info-exemption-toggle {
		padding: 0 10px 0 6px;
		&:after {
			color: @text-color;
			content: @icf-caret-down;
			.font-family-iconfont();
			font-size: 10px;
			opacity: 0.7;
			position: absolute;
			right: 0;
			top: calc(~'50% - 5px');
		}
	}

	.model-info-hidden-unchanged .x-popup-content {
		font-size: 80%;
	}
	
	.model-info-file-block {
		background: @gray-lighterer;
		border: 2px solid @gray-lighterer;
		margin: 10px 0;
		min-height: 120px;
		overflow: hidden;
		padding: 10px 15px;
		
		h4 {
			margin: 0 0 10px;
			
			&.with-exemption-toggle {
				padding-right: 30px;
				position: relative;
				
				.model-info-exemption-toggle {
					position: absolute;
					right: -10px;
					top: 4px;
					
					.icon-f {
						float: none;
						margin-right: 0;
					}
				}
			}
		}
		
		.model-info-file-block-with-bg {
			margin-bottom: 14px;
			padding-left: 60px;
			position: relative;
			
			.model-info-file-block-bg {
				color: @gray-bg;
				font-size: 50px;
				left: -10px;
				position: absolute;
				text-align: center;
				top: calc(~"50% - 25px");
				width: 60px;
				z-index: 1;
			}
			
			/* ==== max 479px = MAINSTREAM OF MOBILE PHONES ==== */
			@media (max-width: @screen-xxs-max) {
				padding-left: 60px;
				
				.model-info-file-block-bg {
					font-size: 40px;
					top: 10px;
					width: 58px;
				}
			}
		}
		
		
		.model-info-file {
			span,
			a {
				&,
				&:hover .txt {
					text-decoration: none;
				}
				.txt {
					text-decoration: underline;
				}
				&.alert-success {
					color: darken(@alert-success-text, 25%)!important;
				}
				&.alert-info {
					color: @alert-info-text!important;
				}
				&.alert-warning {
					color: @alert-warning-text!important;
				}
				&.alert-danger {
					color: @alert-danger-text!important;
				}
			}
			.icon-f {
				display: inline-block;
				
				&:first-child {
					cursor: help;
				}
			}
			& > * {
				display: inline-block;
				padding: 0 3px;
				text-align: center;
			}
			* {
				*:not(:last-child) {
					padding-right: 3px;
				}
				line-height: 20px;
				vertical-align: top;
			}
		}

		.reject-reasons {
			color: @red-color;
			margin: 0.75em 0;
			padding-left: 1.5em;
		}

		.crop-widget-container {
			width: 85%;

			.x-popup.below {
				display: none;
			}

			#document-display-error {
				display: none;
			}

			.crop-image-block {

				display: flex;
				flex-direction: column;
    			align-items: center;

				input[type=file] {
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 0;
					z-index: 0;
				}

				.croppie-container {
					width: auto;
					height: auto;

					.cr-viewport {
						box-shadow: none;
						border-color: fade(@text-color, 30%);
					}

					.cr-slider-wrap {
						max-width: 300px;

						.cr-slider {
							width: 100%;
							background-color: transparent;
							border-radius: unset;
							height: unset;
							margin: unset;
							outline: unset;
							transition: none;

							&::-webkit-slider-thumb {
								-webkit-appearance: none;
								border: none;
								height: 16px;
								width: 16px;
								border-radius: 50%;
								background: #ddd;
								margin-top: -6px;
							}

							&::-moz-range-thumb {
								border: none;
								height: 16px;
								width: 16px;
								border-radius: 50%;
								background: #ddd;
								margin-top: -6px;
							}
						}
					}
				}

				#document-actions {
					width: 100%;
					text-align: center;
					font-size: 0;
					line-height: 30px;
					background: rgba(0,0,0,.5);
					z-index: 2;

					.btn {
						width: 50%;
						margin: 0;
						padding: 0;
						font-size: 22px;
						line-height: 1;
						color: #fff;
						background: 0 0;
						border: 0;
						transition: transform .3s;
					}
				}
			}
		}
	}

	/* ==== min 992px = Just "desktop" ==== */
	@media (min-width: @screen-md-min) {
		.model-info-files {
			display: flex;
			flex-wrap: wrap;
		}
		
		.has-1-blocks {
			.model-info-file-block {
				width: 100%;
			}
		}
		.has-2-blocks {
			.model-info-file-block {
				width: calc(~"50% - 10px");
			}
		}
		.has-3-blocks {
			.model-info-file-block {
				width: calc(~"33.3333% - 13.33333px");
			}
		}
		.has-4-blocks {
			.model-info-file-block {
				width: calc(~"25% - 15px");
			}
		}
		.model-info-file-block {
			flex-shrink: 0;
			margin-right: 20px;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.model-info-file-upload {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.show-model-info-file-upload + .model-info-file-upload {
		display: none;
	}
	
	.model-info-file-block {
		background: @gray-lighter;
		border: 2px dashed @gray-bg;
		min-height: 120px;
		position: relative;
		
		.file-block-overlay {
			align-items: center;
			background: fadeOut(@body-bg, 10%);
			bottom: 0;
			display: flex;
			font-size: 40px;
			justify-content: space-around;
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			transition: opacity 0.2s;
			z-index: 2;
			
			span {
				color: @gray-light;
			}
		}
		
		&:hover {
			.file-block-overlay {
				display: none;
			}
		}
		
		&.is-drag-over {
			border-color: @theme-color;
			
			&:hover {
				.file-drop-overlay {
					display: flex;
				}
			}
			.file-drop-overlay {
				opacity: 1;
			}
		}
		
		&.is-uploading {
			&:hover {
				.uploading-overlay {
					display: flex;
				}
			}
			.uploading-overlay {
				opacity: 1;
			}
		}
		
		label {
			margin-top: 0;
		}
		
		
		.file-drop-here {
			display: block;
			font-size: 26px;
			opacity: 0.3;
			padding: 10px 10px 0 0;
		}
		.model-info-file-upload.hide-drop {
			.file-drop-here {
				display: none;
			}
		}
		
		.file-block-empty {
			display: none;
		}
		
		&.has-file {
			border-color: @btn-success-border;
			
			.file-drop-here {
				display: none;
			}
			
			.file-block-empty {
				display: block;
				margin-top: 10px;
			}
			
			input {
				border-color: @btn-success-border;
				color: @btn-success-border;
			}
		}
		&.has-file-error {
			border-color: @btn-danger-border;
		}
	}
	
	.model-info-form-error {
		color: @state-danger-text;
		
		ul {
			margin: 0.5em 0.5em 1em 0;
			padding-left: 2em;
		}
	}
}

.model-info-form-confirm-title {
	font-size: 24px;
	
	.icon-f {
		color: @state-success-text;
		margin-right: 10px;
	}
}

.model-info-exemption-toggle__popup {
	h3 {
		margin: 0 0 10px;
		font-size: 20px;
	}
	ul, li {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	
	button {
		background: fadeOut(white, 90%);
		border: none;
		display: block;
		font-size: 14px;
		margin: 0 0 5px;
		padding: 5px 10px;
		position: relative;
		
		/*&.is-selected {
			background: transparent;
			cursor: default;
			!*padding-left: 20px;
			
			&:after {
				color: white;
				content: @icf-angle-right;
				.font-family-iconfont();
				font-size: 16px;
				opacity: 0.7;
				position: absolute;
				left: 0;
				top: calc(~'50% - 8px');
			}*!
		}*/
	}
}

.model-exemption-choice {
	width: 100%;

	button {
		width: 100%;
		text-align: left;

		&:hover {
			background: rgba(255,255,255,.15);
			cursor: pointer;
		}
	}
}

.alternative-verification {
	.alternative-header {
		font-weight: bold;
	}
	.alternative-link {
		background: rgba(255,255,255,.1);
		border: none;
		display: block;
		font-size: 14px;
		margin: 0 0 5px;
		padding: 5px 10px;
		position: relative;
		width: 100%;
		text-align: left;
		text-decoration: none;

		&:hover {
			background: rgba(255,255,255,.15);
			cursor: pointer;
		}
	}
}
.model-info-thumb-select_profile_pic-overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 299;

	width: 100%;
	height: 100%;

	background: rgba(102, 102, 102, 0.5);

	.container {
		position: absolute;
		width: 70%;
		// max-width: 650px;
		height: 75%;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		background: @body-bg;
		color: @text-color;
		border: 1px solid #575757;
		padding: 5px 5px 0px;

		display: flex;
		flex-direction: column;
		.close {
			font-size: 185%;
			width: 4%;
			height: 4%;
			top: -5%;
			right: -5%;
			position: absolute;

			&:hover {
				font-size: 200%;
				cursor: pointer;
			}
		}

		.pictures {
			width: fit-content;
			padding: 5px;

			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
			overflow-x: hidden;

			display: flex;

			.thumb-selection {
				width: ~"max(150px, min(300px,calc(50% - 5px)))";
				margin: 2.5px;

				img {
					max-width: 100%;
					outline: 1px solid @text-color;
				}

				&:hover {
					opacity: 0.98;
					cursor: pointer;
				}
			}
		}
	}
}


