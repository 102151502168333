
@import "modules/ads";
@import "modules/home";
@import "modules/mozaique";
@import "modules/search-tags";
@import "modules/video";
@import "modules/favorites";
@import "modules/profile";
@import "modules/signin";
@import "modules/history";
@import "modules/premium";
@import "modules/premium_feed";
@import "modules/global_feed";
@import "modules/facial-reco";
@import "modules/arony_payment";
@import "modules/user_metadata_suggest";
.http-error-page {
	h1, h2, h3, h4 {
		margin-left: 0;
		text-align: center;
	}
    h1 {
        color: @red-color;
    }
}

.tab-form {
	text-align: left;
	background: @tab-form-bg;
    margin-bottom: 15px;
    padding: 10px;
	font-size: 13px;
	.title {
		font-weight: bold;
		color: @red-color;
		font-size: 15px;
		margin-bottom: 10px;
	}
	.form-group {
		margin-bottom: 5px;
	}
	.login {
		border-right: 2px solid @gray-lighter;
	}
	.create-account {
		font-size: 14px;
	}
	label.control-label {
		text-align: left;
	}
	.btn {
		margin: 0;
		font-size: 15px;
	}
	.forgot-password {
		float: left;
		margin-top: 15px;
	}
	ul {
		padding-left: 10px;
	}
	&:last-child {
		margin-top: 15px;
		margin-bottom: 0;
	}
	.x-overlay .x-body & {
		&:first-child {
			margin-top: 0;
		}
		&, a {
			color: @text-color;
		}
		.text-danger {
			color: @brand-danger;
		}
	}
	&.tab-login-form.contracted {
		.form-group {
			display: none;
			&.actions {
				display: block;
				a {
					display: none;
				}
			}
		}
		.create-account {
			display: none;
		}
	}
}

#list-page {
	margin: 10px 5px;
	padding: 0;
	li {
		display: inline-block;
		width: 33.333%;
	}
	a {
		display: inline-block;
		padding: 5px;
		text-decoration: none;
		font-size: 15px;
		&:hover {
			text-decoration: underline;
		}
	}
}

#load-more-subs {
	margin-top: 5px;
	font-weight: bold;
	display: block;
	width: 100%;
	clear: both;
}

#switch-redirect-alert {
	max-width: 610px;
	overflow: hidden;
	text-align: center;
	
	p {
		text-transform: uppercase;
		font-size: 26px;
		font-weight: bold;
		margin: 0 auto;
		padding: 10px 0;
	}
	
	.logo {
		inline-display: block;
		max-width: 320px;
		margin: 0 auto;
	}
	
	.icon-f {
		font-size: 55px;
		vertical-align: text-bottom;
		color: @red-color;
	}
	
	.btn-primary {
		text-transform: uppercase;
		font-size: 26px;
		font-weight: 700;
		white-space: normal;
	}
}
