.email-verification {

	&.x-overlay {
		.x-body,
		.x-content {
			width: fit-content;
		}

		.x-body {
			border-radius: @border-radius-base;

			.x-close {
				top: 4px;
			}
		}

		.x-content {
			display: flex;
			flex-direction: column;
			padding: 10px 30px;
			text-align: center;
		}
	}

	&__title,
	&__message,
	&__divider {
		margin-bottom: 0.8em;
	}
	&__title {
		font-size: @font-size-h1;
		text-align: center;
	}

	&__message {
		font-size: @font-size-h2;
		text-align: center;
	}

	&__divider {
		--text-divider-gap: 1rem;
		align-items: center;
		color: @gray;
		display: flex;
		font-size: @font-size-h4;
		margin: 10px 0 15px 0;
	}

	&__divider::before, &__divider::after {
		content: '';
		height: 2px;
		background-color: @gray;
		flex-grow: 1;
	}

	&__divider::before {
		margin-right: var(--text-divider-gap);
	}

	&__divider::after {
		margin-left: var(--text-divider-gap);
	}

	&__cta {
		display: flex;
		justify-content: space-between;

		&__item {
			background-color: @black-background;
			border: 2px solid #fff;
			border-radius: 3px;
			font-size: 16px;
			padding: 4px 8px;
			text-decoration: none;

			&:hover {
				text-decoration: none;
				border-color: @gray;

			}
		}
	}
}
