
.x-popup {
	position: absolute;
	z-index: @zindex-popup;
	padding: @tooltip-arrow-width 0;
	
	.x-popup-content {
		background: @tooltip-bg;
		color: @tooltip-color;
		padding: 15px 20px;
		overflow-x: hidden;
		overflow-y: auto;
		zoom: 1;
		
		a,
		.text-danger,
		.text-success {
			color: @tooltip-color;
		}
		
		.x-overlay.x-overlay-body-bg .x-body .x-content & {
			a,
			.text-danger,
			.text-success {
				color: @tooltip-color;
			}
		}
	}
	.x-popup-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.x-popup-close {
		cursor: pointer;
		position: absolute;
		color: @tooltip-color;
		font-size: 13px;
		right: 5px;
		top: 8px;
		span span {
			display: none;
		}
	}
	&.above {
		margin-bottom: -@tooltip-arrow-width;
		.x-popup-arrow {
			bottom: 0;
			left: 10px;
			margin-left: -@tooltip-arrow-width;
			border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
			border-top-color: @tooltip-bg;
		}
		&.right .x-popup-arrow {
			left: auto;
			right: 3px;
		}
	}
	&.below {
		margin-top: -@tooltip-arrow-width;
		.x-popup-arrow {
			top: 0;
			left: 10px;
			margin-left: -@tooltip-arrow-width;
			border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
			border-bottom-color: @tooltip-bg;
		}
		&.right .x-popup-arrow {
			left: auto;
			right: 3px;
		}
	}
	&.no-arrow {
		.x-popup-arrow {
			display: none;
		}
	}
	&.x-popup-white {
		.x-popup-content {
			border: 1px solid @tooltip-bg;
			background: @tooltip-white-bg;
			color: @tooltip-white-color;
			a {
				color: @tooltip-white-color;
			}
			.text-danger {
				color: @state-danger-text;
			}
			.text-success {
				color: @state-success-text;
			}
		}
		.x-popup-close {
			color: @tooltip-white-color;
		}
	}
	&.x-popup-white-border {
		&.x-popup-white {
			.x-popup-content {
				border-color: @tooltip-white-bg;
			}
		}
		&.above {
			.x-popup-arrow {
				border-top-color: @tooltip-white-bg;
			}
		}
		&.below {
			.x-popup-arrow {
				border-bottom-color: @tooltip-white-bg;
			}
		}
	}
}