html.big-ui {
	.pagination ul li a,
	.pagination ul li a.active {
		border-radius: 3px;
		font-size: 20px;
		padding: 10px 16px;
	}
	#page {
		&.video-page {
			#main {
				.video-tags-list {
					&.cropped {
						max-height: 72px;
						
						&.opened {
							max-height: none;
						}
					}
				}
				
				.ordered-label-list .btn,
				.ordered-label-list-btn {
					font-size: 17px;
					line-height: 32px; //was 26
					padding: 0 10px;
					
					&.uploader-tag,
					&.hover-name {
						padding: 0;
						
						.verified,
						.name {
							height: 32px;
							line-height: 32px;
						}
						.verified {
							&.icf-white-fill {
								&:after {
									bottom: 33%;
									top: 33%;
								}
							}
						}
						
						.user-subscribe {
							height: 32px;
							line-height: 20px;
							
							.count {
								font-size: 14px;
								height: 20px;
								line-height: 20px;
							}
							
							.icf-feed {
								font-size: 16px;
								height: 20px;
								line-height: 20px;
							}
						}
					}
				}
				
				#video-tabs{
					.nb-views,
					.btn:not(.copy-btn) {
						font-size: 1.2em;
						height: 32px;
						line-height: 32px;
					}
				}
			}
		}
		&.premium_feed {
			.hide-suggests {
				margin-top: 0;
				padding: 5px 10px;
			}
			#feed-videos-filters {
				.show-niv2 {
					font-size: 15px;
					padding: 8px 8px 8px 12px;
				}
			}
		}
	}
	
	.search-filters.simple-dropdown {
		.niv1.with-niv2 {
			.show-niv2 {
				font-size: 15px;
				padding: 8px 8px 8px 12px;
			}
		}
	}
	
	.simple-dropdown-pop {
		.premium-feed-filters .uls a,
		.search-filters ul li a {
			font-size: 1.2em;
			padding: 8px 18px;
		}
	}
	
	.quickies-lat__filters__filter {
		font-size: 14px;
		padding: 8px 18px;
	}
	
	#search-associates {
		strong,
		span a {
			padding: 6px 8px;
		}
	}
	
	#html5video #hlsplayer .buttons-bar .progress-text {
		font-size: 16px;
	}
	
	@media (max-width: @screen-all-mobile) {
		#search-associates {
			strong,
			span a {
				padding: 8px 10px;
			}
		}
		.search-autocomplete {
			li {
				a {
					font-size: 18px;
					line-height: 36px;
					
					.nbres {
						line-height: 20px;
						margin-top: 7px;
					}
				}
			}
		}
	}
}