.stripe.message {
	font-size: 13px;
}

#page {
	width: 100%;
}

@media @media-ratio-horizontal {
	#mobile-login-menu {
		position: absolute;
	}
	
	.search-autocomplete:not(.isForcedFixedPosition) {
		position: absolute;
	}
}

#main {
	margin: 10px 0 0 0;
	position: relative;
	z-index: @zindex-main-content;
	
	.page-title{
		font-size: 20px;
		line-height: 24px;
		margin: 3px 0;
		padding: 2px 4px;
	}
}

#page .resps-tabs,
#home-tabs {
	height: 45px;
	.tab-buttons {
		width: 100%;
		&.tab-2-buttons li {
			width: 49%;
			width: calc(~"50% - 2px");
			&:last-child {
				width: 50%;
			}
		}
		&.tab-3-buttons li {
			width: 33%;
			width: calc(~"33.333% - 2px");
			&:last-child {
				width: 33.33%;
			}
		}
		&.tab-4-buttons li {
			width: 24%;
			width: calc(~"25% - 2px");
			&:last-child {
				width: 25%;
			}
		}
		&.tab-5-buttons li {
			width: 19%;
			width: calc(~"20% - 2px");
			&:last-child {
				width: 20%;
			}
		}
		&.tab-6-buttons li {
			width: 16%;
			width: calc(~"16.666% - 2px");
			&:last-child {
				width: 16.66%;
			}
		}
		li {
			margin: 0 2px 0 0;
			&:last-child {
				margin-right: 0;
			}
			.btn {
				height: 44px;
				padding: 8px 6px;
				position: relative;
				width: 100%;
				.icon,
				.icon-f {
					vertical-align: text-top;
				}
				.icf-cog {
					font-size: 23px;
				}
				.icf-feed {
					font-size: 22px;
					line-height: 27px;
				}
				&.active {
					height: 45px;
					padding-bottom: 9px;
				}
				.navbadge {
					box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
					padding-bottom: 1px;
					padding-top: 2px;
					position: absolute;
					right: 12px;
					top: 8px;
				}
				&.premium {
					padding: 0 8px !important;
					line-height: 26px;
					.ticket-back {
						.icon-f {
							font-size: 46px;
						}
					}
					.ticket-in {
						display: block;
						padding: 11px 0 7px;
						.navbadge {
							right: 4px;
							top: 9px;
						}
					}
					&.active {
						.ticket-in {
							padding-bottom: 8px;
						}
					}
				}
				&.home-premium {
					.icon-f {
						height: 26px;
						font-size: 25px;
						line-height: 26px;
					}
				}
				&.home-regular {
					img {
						float: none;
						height: 30px;
					}
				}
			}
		}
	}
}
#page .resps-tabs {
	&.resps-tabs-mobile-sm {
		height: 35px;
		.tab-buttons {
			li {
				.btn {
					height: 34px;
					padding-bottom: 3px;
					padding-top: 3px;
					&.active{
						height: 35px;
						padding-bottom: 4px;
					}
					&.premium {
						.ticket-back {
							.icon-f {
								font-size: 37px;
							}
						}
						.ticket-in {
							padding: 6px 0 2px;
							.navbadge{
								right: -2px;
							}
						}
						&.active{
							.ticket-in {
								padding-bottom: 3px;
							}
						}
					}
					&.home-regular{
						line-height: 26px;
						img{
							height: 22px;
						}
					}
				}
			}
		}
	}
	& + .page-title {
		margin-top: 10px;
	}
}


.ordered-label-list{
	ul {
		li {
			&.mobile-hide{
				display: none;
			}
			&.mobile-show{
				display: inline-block;
			}
		}
	}
}

#page-go-up {
	&.displayed {
		display: inline-block;
	}
}