@thread-font-size: 14px;
@thread-title-font-size: 18px;
@thread-color-gray: @gray;
@thread-color-gray-light: @gray-light;
@thread-color-gray-dark: @gray-bg;
@thread-color-active: @theme-color;
@thread-color-success: @theme-color;
@thread-node-padding-horizontal: 5px;
@thread-node-children-count-color: @text-invert-color;
@thread-node-children-count-bg: @text-color;
@thread-node-picture-box-shadow-color: fadeout(@text-color, 70%);

.x-thread {
	.thread-node-btn {
		&,
		a {
			background-color: @pagination-bg;
			border: 1px solid @pagination-border;
			font-size: @thread-font-size;
			margin-bottom: 2px;
			padding: 4px @pagination-side-padding;
			text-decoration: none;
			transition: all 0.15s;
			
			&:hover {
				background-color: @pagination-active-bg;
				color: @pagination-active-color;
			}
		}
	}
	
	.thread-header {
		font-size: @thread-title-font-size;
		margin: 0 0 10px;
		
		.thread-title {
			font-weight: bold;
		}
		
		.thread-node-children-count {
			display: inline-block;
			min-width: 10px;
			font-weight: 700;
			white-space: nowrap;
			text-align: center;
			padding: 0 5px;
			margin-left: 5px;
			border-radius: 2px;
			color: @thread-node-children-count-color;
			background: @thread-node-children-count-bg;
			line-height: @thread-title-font-size;
			font-size: @thread-title-font-size * 0.78;
			vertical-align: text-top;
		}
		
		.thread-node-btn-sort {
			margin-left: 20px;
			font-size: @thread-font-size;
			vertical-align: middle;
		}
		
		.thread-sort {
			margin-left: 20px;
			font-size: @thread-font-size;
			
			&,
			a {
				color: @thread-color-gray;
			}
			
			.active {
				cursor: default;
				text-decoration: none;
				font-weight: bold;
			}
		}
		
		.thread-node-btn-post {
			display: inline-block;
			font-size: @thread-font-size;
			margin: 0 0 0 20px;
			padding-top: 2px;
			padding-bottom: 2px;
			vertical-align: top;
			white-space: nowrap;
		}
	}
	
	.thread-root-sort-popup {
		.x-popup-content {
			padding: 10px 0;
			
			ul {
				margin: 0;
				padding: 0;
				
				li {
					margin: 0;
					padding: 0;
					
					a {
						display: block;
						padding: 3px 15px;
						text-decoration: none;
						font-size: @thread-font-size;
						
						&:hover {
							background: @gray-dark;
						}
						
						&.active {
							cursor: default;
							font-weight: bold;
							background: none;
						}
					}
				}
			}
		}
	}
	
	.thread-node {
		font-size: @thread-font-size;
		margin: 5px @thread-node-padding-horizontal 15px;
		clear: both;
		min-height: 54px;
		
		.thread-node-nb-before {
			clear: both;
			margin: -5px 0 15px;
		}
		
		.thread-node-nb-after {
			clear: both;
			margin: 10px 0 15px;
		}
		
		.thread-node-picture {
			box-shadow: 0 0 1px @thread-node-picture-box-shadow-color;
			float: left;
			margin: 5px 0 0 0;
			min-height: 24px;
			position: relative;
			width: 50px;
			
			a {
				display: block;
			}
			
			img {
				width: 50px;
				min-height: 24px;
			}
			
			.flag-small {
				position: absolute;
				right: 3px;
				bottom: 3px;
			}
			
			&.has-bottom .flag-small {
				bottom: 32px;
			}
			
			.thread-node-pic-bottom {
				text-align: center;
				width: 50px;
				
				.icon-f {
					font-size: 16px;
					line-height: 26px;
				}
				
				.btn {
					display: none;
					height: 26px;
					margin: 2px 0 0;
					padding: 0;
					width: 100%;
					
					&.init-ok {
						display: block;
					}
					
					.icon-f {
						line-height: 24px;
					}
				}
				
				img {
					display: block;
					margin: 0 auto;
					max-height: 26px;
					max-width: 100%;
				}
				
				.icf-user-plus {
					color: @theme-color;
				}
			}
		}
		
		.thread-node-content {
			padding-left: 68px;
			
			.thread-node-poster {
				.thread-node-poster-name {
					font-weight: bold;
					
					&.poster-owner {
						&,
						a {
							color: @theme-color;
							font-weight: bold;
						}
					}
					
					.verified {
						vertical-align: text-bottom;
					}
					
					.poster-stats {
						padding-left: 10px;
						padding-right: 10px;
						color: @thread-color-gray;
						font-weight: normal;
					}
				}
				
				.thread-node-date {
					color: @thread-color-gray;
				}
			}
			
			.thread-node-message {
				margin: 5px 0;
				
				& + .thread-node-form {
					margin-top: 0;
					clear: none;
				}
				
				.icon {
					color: @text-color;
				}
				
				.verified {
					vertical-align: text-bottom;
				}
			}
			
			.thread-node-metadata {
				color: @thread-color-gray-light;
				font-size: 85%;
				
				.icf-thumb-down,
				.thread-node-btn-post,
				.thread-node-btn-edit,
				.thread-node-btn-delete,
				.thread-node-nb-replies,
				.thread-node-btn-report {
					padding-left: 10px;
				}
				
				.icon-f {
					cursor: pointer;
					vertical-align: middle;
					
					&.active {
						color: @thread-color-active;
					}
				}
				
				.thread-node-nb-votes {
					font-weight: bold;
					vertical-align: middle;
				}
				
				.thread-node-btn-post,
				.thread-node-btn-edit,
				.thread-node-btn-delete,
				.thread-node-btn-restore,
				.thread-node-btn-report {
					text-decoration: none;
					vertical-align: middle;
					color: @thread-color-gray-light;
					
					&:hover {
						text-decoration: underline;
					}
					
					&.has-error {
						color: @theme-color;
					}
				}
				
				.thread-node-nb-replies {
					text-transform: uppercase;
				}
				
				.thread-node-btn-report.success {
					color: @thread-color-success;
					font-style: italic;
					cursor: default;
					
					&:hover {
						text-decoration: none;
					}
				}
				
				&.votes-disabled {
					.icon-f {
						cursor: wait;
					}
					
					.icon-f,
					.thread-node-nb-votes {
						color: @thread-color-gray-dark;
					}
				}
			}
			
			.emojionearea-editor {
				min-height: 60px;
				font-size: 17px;
			}
			
			.emojionearea.focused {
				border-color: inherit;
				-moz-box-shadow: none !important;
				-webkit-box-shadow: none !important;
				box-shadow: none !important;
			}
		}
		
		&.pending-delete {
			.thread-node-poster,
			.thread-node-message {
				text-decoration: line-through;
				font-style: italic;
			}
		}
	}
	
	.thread-node-children {
		font-size: @thread-font-size;
		
		.thread-node {
			margin-left: 0;
			margin-right: 0;
		}
		
		.thread-node-children-show,
		.thread-node-children-hide {
			margin: 5px 0;
			
			span {
				cursor: pointer;
				font-size: 92.86%;
			}
		}
	}
	
	.thread-node-form {
		margin-top: 15px;
		margin-bottom: 0;
		
		.form-error-list-contener {
			font-size: @thread-font-size;
		}
		
		.thread-node-picture {
			margin-top: 0;
			
			img {
				border-color: @input-border;
			}
			
			.thread-node-poster {
				font-weight: bold;
				margin-top: 2px;
				font-size: @thread-font-size * 0.714;
				text-align: center;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				word-break: break-all;
			}
		}
		
		.form-group {
			margin-bottom: 5px;
			
			textarea,
			input {
				border-radius: 0;
			}
		}
		
		.form-buttons {
			text-align: right;
			margin-bottom: 0;
			
			.btn {
				margin: 0;
				border-radius: 0;
			}
			
			script + div {
				display: none;
			}
		}
	}
	
	.thread-node-loader,
	.thread-node-error,
	.thread-node-children-loader,
	.thread-node-children-error {
		font-size: @thread-font-size * 0.9;
	}
	
	.thread-node-error,
	.thread-node-children-error {
		&,
		a {
			color: @theme-color;
		}
	}
	
	.thread-node-loader,
	.thread-node-error {
		margin-top: 10px;
	}
	
	.thread-node-children-load-next {
		overflow: hidden;
		
		.thread-node-btn {
			display: inline-block;
		}
	}
}

.thread-post-report-form-popup {
	.form-field-report-post_reason {
		.radio {
			display: inline-block;
			margin-right: 20px;
		}
	}
	.form-buttons {
		script + div {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.x-thread {
		.thread-node {
			.thread-node-content {
				.thread-node-metadata {
					font-size: @thread-font-size;
					
					.icf-thumb-up,
					.icf-thumb-down {
						padding: 10px;
						font-size: 18px;
					}
					
					.thread-node-nb-votes {
						margin-left: -10px;
					}
					
					.icf-thumb-down {
						margin-left: 10px;
					}
					
					.thread-node-btn-post,
					.thread-node-btn-edit,
					.thread-node-btn-delete,
					.thread-node-nb-replies,
					.thread-node-btn-report {
						padding-left: 15px;
					}
				}
			}
		}
		
		.thread-node-children {
			.thread-node-children-show,
			.thread-node-children-hide {
				margin: 10px 0;
			}
		}
		
		.thread-node-form {
			.form-error-list-contener {
				font-size: @thread-font-size * 0.86;
			}
		}
	}
}

@media (max-width: 480px) {
	.x-thread {
		.thread-header {
			.thread-node-btn-sort {
				margin-left: 5px;
			}
			
			.thread-node-btn-post {
				margin-left: 0;
			}
		}
	}
}
