
/* ==== Purchase new style ==== */


@multiform-option-bg: #141414;
@multiform-option-push-bg: #212121;

@multiform-option-premium-color: @premium-purchase-form-highlight-color;

@multiform-option-border-radius: 10px;

@multiform-option-includes-top-padding: 10px;
@multiform-option-includes-bottom-padding: 30px;
@multiform-option-includes-side-padding: 10px;
@multiform-option-include-padding: 10px;
@multiform-option-include-border-color: #2c2c2c;

@multiform-option-desktop-padding: 16px;
@multiform-option-desktop-padding-top: 26px;

@multiform-option-desktop-full-popup-padding: 60px;

@multiform-option-mobile-padding: 10px;

@multiform-option-offer-info-padding: 10px;

@multiform-dropdown-and-btn-min-height: 28px;
@multiform-dropdown-and-btn-line-height: 20px;
@multiform-dropdown-and-btn-padding: 6px;

@multiform-dropdown-corner-position: 6px;

@multiform-option-included-green: #8bab71;

@multiform-option-dropdown-bg: #313131;


//options order

@multiform-option-desktop-order-ppv: 1;
@multiform-option-desktop-order-premium: 2;
@multiform-option-desktop-order-membership: 3;

@multiform-option-condense-order-premium: 1;
@multiform-option-condense-order-membership: 2;
@multiform-option-condense-order-ppv: 3;




.premium-popup-form-v1.purchase-form.x-overlay.x-overlay-box {
	text-align: center;
	
	.x-body {
		display: inline-block;
		max-width: 100%;
		text-align: left;
		width: auto;
	}
}

#premium-popup-signin {
	.x-dropdown {
		line-height: @multiform-dropdown-and-btn-line-height;
		padding-bottom: @multiform-dropdown-and-btn-padding;
		padding-top: @multiform-dropdown-and-btn-padding;
		
		.x-dropdown-selected-option {
			min-height: @multiform-dropdown-and-btn-min-height;
			
			&.icons-only {
				padding-right: 18px;
			}
		}
		
		.x-dropdown-selected {
			padding: 0;
		}
	}
	
	.currency-switch-block {
		.x-dropdown {
			background-color: fadeOut(@premium-form-body-bgcolor, 30%);
			color: #f7f7f7;
			
			.icon-f {
				color: #898989;
			}
		}
	}
	
	.multiform-vertical-display() {
		.multiform {
			flex-direction: row;
			
			.multiform-option {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				
				& > * {
					order: 2;
					position: relative;
					z-index: 3;
				}
				
				& > form {
					z-index: 2;
				}
				
				//&::before {
				//	border-top-left-radius: @multiform-option-border-radius;
				//	border-top-right-radius: @multiform-option-border-radius;
				//	border-top: 5px solid #fff;
				//	height: @multiform-option-border-radius;
				//	width: 100%;
				//}
				
				&.premium-form {
					padding-top: 100px;
					background-image: url('../../img/skins/common/premium/purchase-form/bg-premium-option.jpg');
					background-size: 125% auto;
					background-position: top center;
					background-repeat: no-repeat;
					
					&::before {
						border-top-color: @premium-purchase-form-highlight-color;
					}
				}
				
				
				& > .premium-popup-form-header {
					aspect-ratio: 16/8;
					border-top-left-radius: @multiform-option-border-radius;
					border-top-right-radius: @multiform-option-border-radius;
					overflow: hidden;
					margin: -@multiform-option-desktop-padding-top -@multiform-option-desktop-padding -30px;
					order: 1;
					z-index: 1;
					
					&::after {
						background-image: linear-gradient(to top, @multiform-option-bg, transparent);
						bottom: 0;
						content: "";
						left: 0;
						position: absolute;
						top: 40%;
						width: 100%;
						z-index: 2;
					}
				}
				
				.offer-header {
					display: flex;
					flex-direction: column;
					
					& > * {
						order: 2;
					}
					
					& > .offer-no-ads {
						display: block;
						order: 1;
						text-align: center;
					}
					
					.offer-title-framed {
						display: block;
						font-size: 14px;
						line-height: 16px;
						text-align: center;
					}
					
					.offer-sub-slogan {
						display: block;
						text-align: center;
					}
				}
				
				.offer-includes {
					padding: @multiform-option-includes-top-padding @multiform-option-includes-side-padding @multiform-option-includes-bottom-padding;
				}
				
				.multiform-option-bottom {
					margin-top: auto;
				}
			}
			
			
			&.push-premium .multiform-option.premium-form,
			&.push-membership .multiform-option.membership-form,
			&.push-ppv .multiform-option.ppv-form {
				background-color: @multiform-option-push-bg;
				
				.offer-title-framed {
					span {
						background: fadeOut(@multiform-option-push-bg, 30%);
					}
				}
				
				& > .premium-popup-form-header {
					&::after {
						background-image: linear-gradient(to top, @multiform-option-push-bg, transparent);
					}
				}
			}
		}
	}
	
	.multiform-vertical-full-popup-display() {
		.multiform-vertical-display();
		
		.premium-expired {
			padding-top: 40px;
		}
		.multiform {
			.multiform-option {
				background-color: transparent;
				border-top-left-radius: @premium-form-body-radius;
				border-top-right-radius: @premium-form-body-radius;
				box-shadow: none;
				margin: -@premium-form-content-padding -@premium-form-content-padding 0;
				width: calc(~"100% + @{premium-form-content-padding} * 2");
				
				&::before {
					content: none;
				}
				
				.offer-header {
					.offer-title-framed {
						font-size: 20px;
						line-height: 1;
						margin-bottom: 20px;
						
						span {
							background: fadeOut(@premium-form-body-bgcolor, 75%);
						}
					}
				}
				
				& > .premium-popup-form-header {
					border-top-left-radius: @premium-form-body-radius;
					border-top-right-radius: @premium-form-body-radius;
					margin-top: 0;
					margin-bottom: -50px;
					
					&::after {
						background-image: linear-gradient(to top, @premium-form-body-bgcolor, transparent);
					}
				}
				
				.form-buttons {
					button[type=submit]{
						font-size: 20px;
						line-height: 30px;
					}
				}
				
				&.premium-form {
					background-image: url('../../img/skins/common/premium/purchase-form/bg-premium-full-pop.jpg');
				}
			}
			
			&.push-premium .multiform-option.premium-form,
			&.push-membership .multiform-option.membership-form,
			&.push-ppv .multiform-option.ppv-form {
				background-color: transparent;
				
				& > .premium-popup-form-header {
					&::after {
						background-image: linear-gradient(to top, @premium-form-body-bgcolor, transparent);
					}
				}
			}
		}
	}
	
	.multiform-condense-display() {
		.multiform-option-toggle {
			display: flex;
			justify-content: center;
			
			.offer{
				background: fadeOut(@premium-form-body-bgcolor, 30%);
				border: 2px solid #585858;
				border-radius: 4px;
				color: #fff;
				cursor: pointer;
				font-size: 14px;
				font-style: italic;
				font-weight: bold;
				display: inline-block;
				margin-bottom: 12px;
				padding: 4px 7px;
				vertical-align: top;
				text-align: center;
				white-space: nowrap;
				
				&.is-current {
					border-color: @premium-purchase-form-highlight-color;
				}
				
				&:not(.is-current) {
					overflow: hidden;
					text-overflow: ellipsis;
				}
				
				&:not(:first-child) {
					margin-left: 2%;
					
					[dir="rtl"] & {
						margin-left: 0;
						margin-right: 2%;
					}
				}
				
				@media (min-width: @screen-xs-min) {
					font-size: 16px;
					padding: 4px 10px;
					
					&:not(:first-child) {
						margin-left: 20px;
						
						[dir="rtl"] & {
							margin-left: 0;
							margin-right: 20px;
						}
					}
				}
				
				@media (max-width: 359px) {
					font-size: 12px;
					padding: 4px 4px;
					
					&:not(:first-child) {
						margin-left: 1%;
						
						[dir="rtl"] & {
							margin-left: 0;
							margin-right: 1%;
						}
					}
				}
			}
			
			&.with-price {
				.offer {
					
					&:not(.is-current) {
						.offer-price {
							opacity: 0.75;
						}
					}
					
					.offer-price {
						display: block;
						font-size: 0;
						
						.product {
							
							//& + .product {
							//	display: none;
							//}
							
							.product-price {
								display: block;
								font-size: 11px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								
								* {
									vertical-align: baseline;
								}
								
								.amount {
									color: #8d8d8d;
									font-weight: bold;
									line-height: 0.9em;
									white-space: nowrap;
									
									.currency {
										font-size: 0.95em;
										line-height: 1;
										
										&:first-child {
											margin-right: 0.25em;
										}
									}
								}
								
								.repeat {
									color: #5f5f5f;
									font-size: 0.95em;
									line-height: 1;
									margin-left: 0.25em;
									text-transform: lowercase;
									text-align: right;
								}
							}
						}
					}
				}
			}
			
		}
		
		.multiform {
			flex-direction: column;
			
			.multiform-option {
				padding: @multiform-option-mobile-padding @multiform-option-mobile-padding @multiform-option-mobile-padding @multiform-option-mobile-padding + 5px;
				
				&.premium-form {
					order: @multiform-option-condense-order-premium;
				}
				&.membership-form {
					order: @multiform-option-condense-order-membership;
				}
				&.ppv-form {
					order: @multiform-option-condense-order-ppv;
				}
				
				&::before {
					content: "";
					bottom: 0;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					border-bottom: 5px solid @premium-purchase-form-highlight-color-reduced-bottom;
					height: 100%;
					z-index: 1;
				}
				
				& > * {
					position: relative;
					z-index: 3;
				}
				
				&:not(.is-condense-open) {
					&.is-condense-with-toggle {
						display: none;
					}
					
					//@todo remove all the rest here
					cursor: pointer;
					padding-bottom: 20px;
					z-index: 1;
					
					&::before {
						border-top-color: @premium-purchase-form-highlight-color-reduced-top;
					}
					
					.offer-includes,
					.offer-info,
					.multiform-option-bottom {
						transition: all 0.25s;
						max-height: 0;
						opacity: 0;
						padding-bottom: 0;
						padding-top: 0;
						overflow: hidden;
					}
					
					.offer-condensed-header-line {
						align-items: center;
						display: flex;
						justify-content: space-between;
						padding-right: 14%;
						position: relative;
						width: 100%;
						
						&::after {
							content: @icf-angle-down;
							.font-family-iconfont();
							
							background: @multiform-option-dropdown-bg;
							border-radius: @premium-purchase-form-condensed-btn-border-radius;
							font-size: 23px;
							padding: 5px;
							position: absolute;
							right: calc(~"100% * 0.12 / 2");
							top: 50%;
							transform: translate(50%, -50%);
							z-index: 2;
						}
					}
					
					
					.offer-price {
						display: block;
						margin-left: auto;
						
						.product {
							display: block;
							text-align: center;
							
							//& + .product {
							//	display: none;
							//}
							
							.product-price {
								font-size: 15px;
								
								* {
									vertical-align: baseline;
								}
								
								.amount {
									display: block;
									font-weight: bold;
									line-height: 0.9em;
									white-space: nowrap;
									
									.currency {
										font-size: 0.55em;
										line-height: 1;
										&:first-child {
											margin-right: 0.25em;
										}
									}
								}
								
								.repeat {
									display: block;
									color: #6e6d6d;
									font-size: 0.7em;
									line-height: 1;
									margin-left: 0.25em;
									text-transform: lowercase;
									text-align: right;
								}
							}
						}
					}
					
					.offer-condensed-desc {
						&.offer-condensed-desc-open-hidden {
							display: block;
						}
					}
				}
				
				&.is-condense-open {
					&.is-condense-with-toggle {
						display: block;
						
						.offer-title-framed {
							span {
								border-color: @premium-purchase-form-highlight-color;
							}
						}
					}
					
					background-color: @multiform-option-push-bg;
					z-index: 2;
					
					&::before {
						border-top-color: @premium-purchase-form-highlight-color;
					}
					
					.offer-includes,
					.offer-info,
					.multiform-option-bottom {
						opacity: 1;
						overflow: visible;
						max-height: 600px;
						transition: all 0.5s 0.1s;
					}
					
					.offer-includes {
						li {
							.premium-included {
								background: lighten(@multiform-option-push-bg, 5%);
							}
						}
					}
				}
				
				.premium-popup-form-header {
					display: none;
				}
				
				.offer-header {
					align-items: center;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					width: 100%;
					
					.offer-sub-slogan {
						display: none;
					}
				}
				
				.offer-title-framed {
					display: inline-block;
					text-align: left;
					margin: 4px 0;
					
					span {
						font-size: 14px;
						line-height: 20px;
						padding-bottom: 2px;
						padding-top: 2px;
					}
				}
				
				.offer-no-ads {
					display: inline-block;
					margin: 0 4px;
				}
				
				.offer-condensed-desc {
					color: #999;
					font-size: 11.5px;
					padding: 2px;
					display: block;
					
					strong {
						color: #fff;
					}
					
					&.offer-condensed-desc-open-hidden {
						display: none;
					}
				}
				
				.offer-includes {
					padding: 8px 0 8px 5px;
					
					& + .offer-info {
						margin-top: -8px;
					}
				}
				
				.multiform-option-bottom {
					width: 100%;
					.offer-selection {
						margin-bottom: 5px;
					}
				}
				
			}
		}
	}

	&.multiform-nb-3,
	&.multiform-nb-2 {
		.card-payments-soon-desktop {
			display: flex;
		}

		.card-payments-soon {
			display: none;
		}
	}


	.card-payments-soon {
		max-width: 700px;
	}

	.card-payments-soon-desktop {
		display: none;
	}

	.card-payments-soon,
	.card-payments-soon-desktop {
		text-align: center;
		font-weight: bold;
		font-size: 12px;
		margin: 5px 6px;
		padding: 6px;
		background-color: #611708;
		border-radius: 6px;
		align-items: center;
    	justify-content: center;

		.icon-f.icf-info-circle {
			margin-right: 6px;
		}
	}
	
	.purchase-form-contents {
		max-width: 700px;
		
		.multiform-header {
			margin: 0 0 24px;
			
			.choose-offer {
				font-size: 24px;
				margin: 0;
				padding: 0 6px;
				position: relative;
				text-align: center;
				text-transform: uppercase;
				
				& > span {
					display: inline-block;
					font-weight: bold;
					line-height: 1.55em;
					vertical-align: top;
				}
				
				.logo-xvred {
					height: 1.4em;
					white-space: nowrap;
					
					img {
						display: inline-block;
						height: 1.4em;
						vertical-align: top;
						width: auto;
					}
					
					.icf-ticket-red {
						font-size: 2.15em;
					}
				}
			}
		}

		.offer-title-framed {
			span {
				background: fadeOut(@premium-form-body-bgcolor, 30%);
				border: 2px solid #585858;
				color: #fff;
				border-radius: 4px;
				font-style: italic;
				font-weight: bold;
				display: inline-block;
				padding: 4px 10px;
				vertical-align: top;
			}
		}
		
		h3.premium-expired {
			align-items: center;
			//color: @multiform-option-premium-color;
			display: flex;
			font-size: 1.1em;
			font-weight: normal;
			margin: 0 auto 20px;
			max-width: 80%;
			text-align: center;
			
			.offer-title-framed {
				span {
					background: transparent;
					border-color: @premium-purchase-form-highlight-color;
					//color: @multiform-option-premium-color;
					margin-right: 16px;
				}
			}
		}
		
		.multiform-option-toggle {
			display: none;
		}
		
		.multiform {
			display: flex;
			
			.multiform-option {
				background: @multiform-option-bg;
				border-radius: @multiform-option-border-radius;
				box-shadow: 0 0 10px #000;
				margin-bottom: 20px;
				padding: @multiform-option-desktop-padding-top @multiform-option-desktop-padding @multiform-option-desktop-padding;
				position: relative;
				
				&::before {
					content: "";
					left: 0;
					opacity: 0.7;
					position: absolute;
					top: 0;
					z-index: 2;
					
					// border-top everywere
					border-top-left-radius: @multiform-option-border-radius;
					border-top-right-radius: @multiform-option-border-radius;
					border-top: 5px solid #fff;
					height: @multiform-option-border-radius;
					width: 100%;
				}
				
				.premium-popup-form-header {
					z-index: 0;
					
					 .v-img-p{
						.vid-img-wrapper,
						img {
							display: block;
							width: 100%;
						}
					}
				}
				
				.offer-price {
					display: none;
				}
				
				.offer-title-framed {
					margin: 0 0 16px;
					
					span {
						background: fadeOut(@multiform-option-push-bg, 30%);
					}
				}
				
				&.premium-form {
					.offer-no-ads {
						margin: 0 0 8px;
						line-height: 14px;
						
						span {
							background: @premium-purchase-form-highlight-color;
							color: #fff;
							border-radius: 4px;
							display: inline-block;
							font-size: 12px;
							font-weight: bold;
							line-height: 14px;
							padding: 2px 10px;
							vertical-align: top;
						}
					}
					
					.offer-title-framed {
						margin: 0;
						
						span {
							border-color: @premium-purchase-form-highlight-color;
						}
					}
					
					.offer-sub-slogan {
						margin: 0 0 16px;
						
						span {
							color: #9b9999;
							font-size: 12px;
						}
						strong {
							color: #fff;
						}
					}
				}
				
				.offer-includes {
					margin: 0;
					
					li {
						align-items: center;
						color: #999;
						display: flex;
						font-size: 14px;
						justify-content: space-between;
						line-height: 20px;
						padding: @multiform-option-include-padding 0 @multiform-option-include-padding @multiform-option-include-padding+20px;
						position: relative;
						
						strong {
							color: #fff;
						}
						
						&::before {
							.font-family-iconfont();
							color: @multiform-option-included-green;
							content: @icf-check-circle-thin;
							font-size: 20px;
							left: 0;
							position: absolute;
							top: calc(~"50% - 10px");
						}
					
						& + li {
							border-top: 1px solid @multiform-option-include-border-color;
						}
						
						& > *:last-child&:not(:first-child) {
							flex-grow: 0;
							flex-shrink: 0;
							margin-left: 10px;
						}
						
						&.video {
							img {
								border-radius: 5px;
								height: 38px;
								width: auto;
							}
						}
						
						&.membership {
							img {
								border-radius: 50%;
								height: 56px;
								width: 56px;
							}
						}
						
						&.premium {
							&.premium-videos {
								&::before {
									content: @icf-play-square-film;
								}
							}
							&.new-premium-videos {
								&::before {
									content: @icf-play-rectangle-o;
								}
							}
							&.no-ads {
								&::before {
									content: @icf-no-ads-circle-fat-o;
								}
							}
							&.custom-homepage {
								&::before {
									content: @icf-home;
								}
							}
							&.cancel {
								&::before {
									content: @icf-check-shield;
								}
							}
						}
						.premium-included {
							background: lighten(@multiform-option-bg, 5%);
							border-radius: 4px;
							font-size: 0.9em;
							font-style: italic;
							font-weight: bold;
							padding: 0 7px;
						}
					}
					
					& + .offer-info {
						margin-top: -@multiform-option-includes-bottom-padding;
					}
				}
				
				.offer-info {
					border-top: 1px solid @multiform-option-include-border-color;
					color: #777;
					font-size: 12.5px;
					padding: @multiform-option-includes-top-padding 0 @multiform-option-includes-bottom-padding;
					
					text-align: center;
				}
				
				.x-dropdown {
					background-color: @multiform-option-dropdown-bg;
				}
				
				.form-buttons {
					margin-bottom: 0;
					
					button[type=submit] {
						font-size: 15px;
						line-height: @multiform-dropdown-and-btn-line-height;
						min-height: @multiform-dropdown-and-btn-min-height + (@multiform-dropdown-and-btn-padding * 2);
						margin: 5px;
						padding-bottom: @multiform-dropdown-and-btn-padding;
						padding-top: @multiform-dropdown-and-btn-padding;
						width: calc(~"100% - 10px");
					}
				}
			}
			
			.premium-form {
				.form-field-premium-form_product {
					display: none;
				}
			}
			
			.selectable-element-parent {
				padding: 5px;
				
				display: flex;
				gap: 10px;
				flex-wrap: wrap;
				width: 100%;
				
				& > div {
					flex-grow: 1;
					width: auto;
				}
			}
			
			.payment-method-show-all-options {
				display: none;
				
				&.selected {
					display: flex;
					width: 100%;
					
					& + .payment-method-show-all {
						display: none;
					}
				}
			}
			
			.product-desc {
				.product-desc-main,
				.product-desc-sep.price,
				.product-desc-price {
					color: white;
					font-weight: bold;
				}
				
				.trial,
				.product-desc-sep,
				.product-desc-description {
					color: darken(white, 33%);
					font-weight: normal;
				}
				
				.trial,
				.product-desc-description {
					font-size: 86%;
				}
				
				.trial {
					.offer,
					.price {
						font-size: 116.28%;
						color: white;
						font-weight: bold;
					}
				}
			}
			
			.offer {
				.x-dropdown-option {
					border-top: 1px solid darken(white, 66%);
				}
			}
			
			.vid-img-wrapper {
				display: inline-block;
				position: relative;
				
				img {
					display: block;
					width: 100%;
					position: relative;
					z-index: 1;
				}
				
				.cover-thumb {
					display: block;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 96%;
					z-index: 2;
				}
			}
		}
		
		.offer-condensed-desc {
			display: none;
		}
		
		.included {
			margin: 10px 20px 30px;
		}
		
		.x-dropdown-selected-option {
			img.pay-card {
				vertical-align: text-top;
				height: 20px;
			}
			
			.x-dropdown-value {
				.icf-pay-sepa {
					font-size: 100%;
				}
			}
		}
		.x-dropdown-option {
			img.pay-card {
				height:	18px;
			}
		}
	}
	
	&.multiform-nb-1 {
		@max-nb-1-width: 600px - @premium-form-content-padding * 2;
		
		& > * {
			position: relative;
			z-index: 1;
		}
		
		& > .currency-switch-block {
			position: absolute;
			z-index: 2;
		}
		
		.card-payments-soon {
			max-width: @max-nb-1-width;
		}
		
		.purchase-form-contents {
			max-width: @max-nb-1-width;
			
			.multiform-vertical-full-popup-display();
		}
		
		.legal {
			max-width: @max-nb-1-width;
		}
		
		&::before {
			border-top-left-radius: @premium-form-body-radius;
			border-top-right-radius: @premium-form-body-radius;
			border-top: 5px solid #fff;
			content: "";
			height: @premium-form-body-radius;
			left: 0;
			opacity: 0.7;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}
		
		&.multiform-nb-1-premium::before {
			border-top-color: @premium-purchase-form-highlight-color;
		}
	}
	
	/*** DESKTOP ***/
	@media (min-width: @screen-sm-min) {
		.purchase-form-contents {
			.multiform-header {
				margin-left: 120px;
				margin-right: 120px;
				margin-top: 20px;
				
				.premium-popup-form-header {
					display: none;
				}
			}
			
			&:first-child {
				.multiform-header {
					margin-top: -25px;
				}
			}
		}
		
		&.multiform-nb-3,
		&.multiform-nb-2 {
			.purchase-form-contents {
				.multiform-vertical-display();
				
				.multiform {
					.multiform-option {
						&.premium-form {
							order: @multiform-option-desktop-order-premium;
						}
						&.membership-form {
							order: @multiform-option-desktop-order-membership;
						}
						&.ppv-form {
							order: @multiform-option-desktop-order-ppv;
						}
					}
					
					
					&.push-premium,
					&.push-membership,
					&.push-ppv {
						.multiform-option {
							.form-buttons {
								button[type=submit] {
									background-color: @red-color;
									
									&:hover,
									&:active,
									&:focus {
										background-color: darken(@red-color, 10%);
									}
								}
							}
						}
					}
					
					&.push-premium .multiform-option.premium-form,
					&.push-membership .multiform-option.membership-form,
					&.push-ppv .multiform-option.ppv-form {
						background-color: @multiform-option-push-bg;
						
						.offer-includes {
							li {
								.premium-included {
									background: lighten(@multiform-option-push-bg, 5%);
								}
							}
						}
					}
				}
			}
		}
		
		&.multiform-nb-3 {
			.card-payments-soon {
				max-width: 1100px;
			}
			
			.purchase-form-contents {
				max-width: 1100px;
				
				.multiform {
					justify-content: space-between;
					margin: 0 10px;
					
					.multiform-option {
						width: 31%;
					}
					
					&.push-premium,
					&.push-membership,
					&.push-ppv {
						.multiform-option {
							margin-top: 20px;
							width: 29%;
						}
					}
					&.push-premium .multiform-option.premium-form,
					&.push-membership .multiform-option.membership-form,
					&.push-ppv .multiform-option.ppv-form {
						margin-top: 0;
						width: 35%;
					}
				}
			}
		}
		
		&.multiform-nb-2 {
			.card-payments-soon {
				max-width: 850px;
			}
			
			.purchase-form-contents {
				max-width: 850px;
				
				.multiform {
					justify-content: space-evenly;
					margin: 0 10px;
					
					.multiform-option {
						width: 45%;
					}
					
					&.push-premium,
					&.push-membership,
					&.push-ppv {
						.multiform-option {
							margin-top: 20px;
							width: 40%;
						}
					}
					&.push-premium .multiform-option.premium-form,
					&.push-membership .multiform-option.membership-form,
					&.push-ppv .multiform-option.ppv-form {
						margin-top: 0;
						width: 52%;
					}
				}
			}
		}
		
		&.multiform-nb-1 {
			.purchase-form-contents {
				
				//overrides multiform-vertical-full-popup-display :
				.multiform {
					justify-content: space-evenly;
					
					&:first-child {
						.multiform-option {
							.offer-header {
								margin-top: -25px;
							}
						}
					}
					
					.multiform-option {
						padding: 0 @multiform-option-desktop-full-popup-padding;
						margin-bottom: 40px;
						
						& > .premium-popup-form-header {
							aspect-ratio: 16/7;
							margin-left: -@multiform-option-desktop-full-popup-padding;
							margin-right: -@multiform-option-desktop-full-popup-padding;
						}
						
						.offer-includes {
							padding-left: 0;
							padding-right: 0;
						}
					}
				}
			}
		}
	}
	
	/*** MOBILE ***/
	@media (max-width: @screen-xs-max) {
		//& > * {
		//	position: relative;
		//	z-index: 2;
		//}
		
		//& > .currency-switch-block {
		//	left: 0;
		//	position: absolute;
		//	top: 0;
		//	z-index: 3;
		//
		//	.x-dropdown {
		//		border-top-left-radius: @premium-form-body-radius;
		//	}
		//}

		&.multiform-nb-3,
			&.multiform-nb-2 {
				.card-payments-soon-desktop {
					display: none;
				}
	
				.card-payments-soon {
					display: flex;
				}
			}
		
		.purchase-form-contents {
			
			.multiform-header {
				& > * {
					position: relative;
					z-index: 2;
				}
				
				& > .premium-popup-form-header {
					//display: block;
					margin: -@premium-form-content-padding  -@premium-form-content-padding 0;
					//z-index: 1;
					
					&::after {
						background-image: linear-gradient(to top, @premium-form-body-bgcolor, transparent);
						bottom: -1px;
						content: "";
						left: 0;
						position: absolute;
						top: 30%;
						width: 100%;
						z-index: 2;
					}
					
					.v-img {
						aspect-ratio: 16/6;
						overflow: hidden;
					}
					
					img {
						border-top-left-radius: @multiform-option-border-radius;
						border-top-right-radius: @multiform-option-border-radius;
						display: block;
						width: 100%;
					}
					
					& + .choose-offer {
						margin-top: -30px
					}
				}
				
				.choose-offer {
					font-size: 16px;
					margin-top: 40px
				}
			}
			
			h3.premium-expired {
				max-width: 96%;
				
				.offer-title-framed {
					font-size: 12px;
					
					span {
						font-size: 12px;
						line-height: 20px;
					}
				}
			}
		}
		
		&.multiform-nb-1 {
			//.currency-switch-block {
			//	top: 5px;
			//}
			
			.purchase-form-contents {
				
				//overrides multiform-vertical-full-popup-display :
				.multiform {
					.multiform-option {
						padding-top: 0;
						
						&.premium-form {
							padding-top: 80px;
						}
						
						& > .premium-popup-form-header {
							aspect-ratio: 16/6;
						}
						
						.offer-header {
							.offer-title-framed {
								font-size: calc(~"2.5vw + 4px");
								line-height: 1.3;
								margin-bottom: 2vw;
							}
							.offer-sub-slogan {
								margin-bottom: 2vw;
							}
						}
						
						.form-buttons {
							button[type=submit]{
								font-size: calc(~"3.2vw + 4px");
								line-height: 1.3;
							}
						}
					}
				}
			}
		}
		
		&.multiform-nb-3,
		&.multiform-nb-2 {
			
			& > * {
				position: relative;
				z-index: 1;
			}
			
			& > .currency-switch-block {
				left: @multiform-dropdown-corner-position;
				position: absolute;
				top: @multiform-dropdown-corner-position;
				z-index: 2;
			}
			
			.purchase-form-contents {
				.multiform-condense-display();
			}
		}
	}
	
	/*** "BIG" MOBILE ***/
	/*@media (max-width: @screen-xs-max) and (min-width: 368px) {
		&.multiform-nb-3,
		&.multiform-nb-2 {
			.purchase-form-contents {
				.multiform {
					.multiform-option {
						&.ppv-form,
						&.membership-form {
							.multiform-option-bottom {
								display: flex;
								justify-content: space-between;
								
								.offer-selection {
									margin-right: 5px;
									margin-bottom: 0;
									flex-shrink: 1;
									flex-grow: 5;
								}
								form {
									flex-grow: 2;
									flex-shrink: 2;
								}
							}
						}
					}
				}
			}
		}
	}*/
}
