
.xscroll-dim-background(@background-color) {

	.xscroll-buttons-dim {
		&.xscroll-buttons-dim-left {
			background: linear-gradient(270deg,
				fadeOut(@background-color, 100%) 0%,
				fadeOut(@background-color, 15%) 100%
			);
		}
		&.xscroll-buttons-dim-right {
			background: linear-gradient(90deg,
				fadeOut(@background-color, 100%) 0%,
				fadeOut(@background-color, 15%) 100%
			);
		}
	}
}

.xscroll-buttons-dim {
	// width: set in js
	// height: set in js
	position: absolute;
	z-index: 200;
	top: 0;

	pointer-events: none;

	&.xscroll-buttons-dim-left {
		left: 0;
	}
	&.xscroll-buttons-dim-right {
		right: 0;
	}
}
.xscroll-dim-background(@scroll-buttons-dim-background);

.xscroll-buttons {
	// width: set in js
	// height: set in js
	position: absolute;
	z-index: 200;
	top: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 350%;

	&.xscroll-buttons-hide {
		display: none;
	}

	&.xscroll-buttons-left {
		left: 0;
	}
	&.xscroll-buttons-right {
		right: 0;
	}

	color: white;
	.text-border-1px(fadeOut(black, 50%));

	&:hover {
		background-color: fadeOut(black, 66%);
		cursor: pointer;
	}
}