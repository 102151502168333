
@form-available-bgcolor: #212121;
@form-available-hover-bgcolor: #242424;
@form-available-optionhover-bgcolor: lighten(@form-available-hover-bgcolor, 10%);
@form-available-opacity: 1;
@form-active-bgcolor: #4d4b4b;
@form-element-radius: 9px;
@form-dimmed-bgcolor: @premium-form-available-bgcolor;
@form-dimmed-opacity: .2;

.x-dropdown {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: @form-available-bgcolor;
	min-height: 40px;
	padding: 7px;
	cursor: pointer;

	border-radius: @form-element-radius;
	&.open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.x-dropdown-caret {
		position: absolute;
    	right: 7px;
		color: #fff;
	}
	.x-dropdown-caret::before { content: @icf-angle-down; }
	&.open .x-dropdown-caret::before { content: @icf-angle-up; }

	.x-dropdown-select { display: none;	}
	&.open .x-dropdown-select {	display: flex; }

	.x-dropdown-select,
	.x-dropdown-selected {
		width: 100%;
	}

	.x-dropdown-value {
		color: #fff;
	}
	
	.x-dropdown-selected-one-line {
		.x-dropdown-value {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	@option-height: 40px;

	.x-dropdown-select {
		.x-options-container {
			width: 100%;
			flex-direction: column;
			overflow: hidden;
			border-bottom-left-radius: @form-element-radius;
			border-bottom-right-radius: @form-element-radius;
		}
		position: absolute;
		top: 100%;
		z-index: 10;
		width: 100%;
		background-color: @form-available-bgcolor;

		each(range(3,10,1), {
			&.max-items-@{value} {
				//&, .xscroll-content {
					max-height: @value * @option-height;
				//}
			}
		});

		&.allow-overflow {
			width: auto;
			left: 0;
			border-top-right-radius: @form-element-radius;

			&.overflow-right {
				left: auto;
				right: 0;
				border-top-left-radius: @form-element-radius;
				border-top-right-radius: 0;
			}
		}

		border-bottom-left-radius: @form-element-radius;
		border-bottom-right-radius: @form-element-radius;
		.xscroll-container {
			width: 100%;
			border-bottom-left-radius: @form-element-radius;
			border-bottom-right-radius: @form-element-radius;
		}
	}
	&:hover {
		.x-dropdown-select {
			background-color: @form-available-hover-bgcolor;
		}
	}
	&.with-scrollbar {
		.x-dropdown-select {
			.x-options-container {
				border-bottom-right-radius: 0;
			}
		}
	}

	.x-dropdown-sep,
	.x-dropdown-option,
	.x-dropdown-selected-option {
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
		padding-right: 24px;
	}
	&.one-option-button {
		.x-dropdown-selected-option {
			padding-right: 0;
		}
		.x-dropdown-caret {
			display: none;
		}
	}
	.x-dropdown-selected-option {
		min-height: 24px;
		.icon-f {
			font-size: 125%;
		}
		.x-dropdown-value {
			.icon-f {
				vertical-align: middle;
			}
		}
	}

	@media (min-width: @screen-sm-min) {
		&.style-left .x-dropdown-selected-option {
			margin-left: 10px;
			gap: 13px;
			justify-content: flex-start;
		}
	}

	.x-dropdown-sep,
	.x-dropdown-option {
		min-height: @option-height;
		background-color: @form-available-bgcolor;
		justify-content: flex-start;
	}

	.x-dropdown-option {
		width: 100%;
		padding-bottom: 5px;
		padding-left: 10px;
		padding-top: 5px;
		
		&.selected {
			background-color: @form-active-bgcolor;
		}
		&.hidden {
			display: none;
		}

		&:not(.disabled):hover,
		&.selected:not(.disabled):hover,
		&.with-action:hover {
			cursor: pointer;
			background-color: @form-available-optionhover-bgcolor;
		}

		&.disabled:not(.with-action:hover) {
			opacity: @form-dimmed-opacity;
		}
	}
	&:hover {
		.x-dropdown-sep,
		.x-dropdown-option {
			background-color: @form-available-hover-bgcolor;
		}
		.x-dropdown-option {
			
			&.selected {
				background-color: @form-active-bgcolor;
			}
		}
	}
	.x-dropdown-option {
		&:not(.disabled),
		&.selected:not(.disabled),
		&.with-action {
			&:hover {
				background-color: @form-available-optionhover-bgcolor;
			}
		}
	}

	.x-dropdown-sep {
		color: fadeOut(white, 25%);
		font-size: 90%;
		min-height: @option-height * (2/3);
		padding-left: 10px;
		padding-top: 10px;
		margin-left: 8px;
		margin-right: 8px;
		border-bottom: 1px solid lighten(@form-available-bgcolor, 15%);

		&:hover {
			cursor: default;
		}

		&.no-text {
			padding-top: 3px;
			min-height: 0px;
		}
	}
	&:hover {
		.x-dropdown-sep {
			border-color: lighten(@form-available-hover-bgcolor, 15%);
		}
	}
}

@import './dropdown/dropdown_default.less';