html.qucks-opened {
	height: 100vh;//tweak for safari 15 iOS
	
	body {
		overflow: hidden;
		width: 100%;
		//position: fixed;
	}
}

@quickies-zindex-manager-players-level-2:		6;
@quickies-zindex-manager-close-swipe-l-2:		5;
@quickies-zindex-manager-players:				4;
@quickies-zindex-manager-desktop-profile:		3;
@quickies-zindex-manager-sub-players:			2;
@quickies-zindex-manager-close-swipe:			1;

@quickies-zindex-playerbox-player:			1;
@quickies-zindex-playerbox-text:			5;
@quickies-zindex-playerbox-progress:		10;
@quickies-zindex-playerbox-actions:			15;
@quickies-zindex-playerbox-actions-back:	14;
@quickies-zindex-playerbox-close:			20;
@quickies-zindex-playerbox-profile:			25;
@quickies-zindex-playerbox-menus:			30;
@quickies-zindex-playerbox-comments:		35;

@quickies-zindex-playerbox-player-video:	4;
@quickies-zindex-playerbox-player-loader:	3;
@quickies-zindex-playerbox-player-bg:		2;
@quickies-zindex-playerbox-player-bg-blur:	1;

@quickies-zindex-playerbox-actions-act:			3;
@quickies-zindex-playerbox-actions-act-back:	2;
@quickies-zindex-playerbox-actions-inact:		1;



@quickies-media-ratio-horizontal-169-with-prevnext-or-more:		~"min-aspect-ratio: 20/9";
@quickies-media-ratio-horizontal-169-with-prevnext-or-thinner:	~"max-aspect-ratio: 20/9";

@quickies-media-ratio-vertical-169-with-prevnext-or-more:		~"min-aspect-ratio: 15/16";
@quickies-media-ratio-vertical-169-with-prevnext-or-thinner:	~"max-aspect-ratio: 15/16";


// w : width // h : height // lr : position left/right // t : position top // b : position bottom // m : margin


@quickies-player-min-w:					500px;
@quickies-player-min2-w:				1000px;
@quickies-desktop-profile-w:			500px;

@quickies-medium-media-min-w:			@quickies-player-min-w + @quickies-desktop-profile-w;
@quickies-small-media-max-w:			@quickies-medium-media-min-w - 1px;

@quickies-medium-plus-media-min-w:		@quickies-player-min2-w + @quickies-desktop-profile-w;
@quickies-small-plus-media-max-w:		@quickies-medium-plus-media-min-w - 1px;

@quickies-medium-media-min-h:			800px;
@quickies-small-media-max-h:			@quickies-medium-media-min-h - 1px;

@quickies-xsmall-media-max-h:			499px;

@quickies-nextprev-max-w:				160px;
@quickies-nextprev-min-w:				60px;
@quickies-nextprev-small-min-w:			40px;

@quickies-actions-b:					20px;

@quickies-action-h:						50px;
@quickies-action-m:						5px;
@quickies-action-small-h:				40px;

@quickies-close-h:						60px;
@quickies-close-lh:						50px;
@quickies-desktop-profile-close-h:		36px;


@quickies-vertical-max-h:				1000px;
@quickies-vertical-max-h-perc:			90;
@quickies-vertical-small-max-h-perc:	100;

@quickies-horizontal-max-w:				800px;




// ------------------------------------
// PLAYER  ----------------------------
// ------------------------------------

@quickies-vertical-h-vh:					~"(@{quickies-vertical-max-h-perc} * var(--vh, 1vh))";
@quickies-vertical-h-vw:					~"calc((100vw - (@{quickies-nextprev-min-w} * 2)) * 16 / 9)";
@quickies-vertical-h-px:					@quickies-vertical-max-h;
@quickies-vertical-w-vh:					~"calc(@{quickies-vertical-max-h-perc} * var(--vh, 1vh) / 16 * 9)";
@quickies-vertical-w-vw:					~"calc(100vw - (@{quickies-nextprev-min-w} * 2))";
@quickies-vertical-w-px:					~"calc(@{quickies-vertical-h-px} / 16 * 9)";

@quickies-horizontal-w-vh:					~"calc(100 * var(--vh, 1vh) * 16 / 9)";
@quickies-horizontal-w-vw:					~"calc(100vw - (@{quickies-nextprev-min-w} * 2))";
@quickies-horizontal-w-px:					@quickies-horizontal-max-w;
@quickies-horizontal-h-vh:					~"calc(100 * var(--vh, 1vh))";
@quickies-horizontal-h-vw:					~"calc((100vw - (@{quickies-nextprev-min-w} * 2)) / 16 * 9)";
@quickies-horizontal-h-px:					~"calc(@{quickies-horizontal-w-px} / 16 * 9)";

//small
@quickies-vertical-small-h-vh:				~"(@{quickies-vertical-small-max-h-perc} * var(--vh, 1vh))";
@quickies-vertical-small-h-vw:				~"calc((100vw - @{quickies-nextprev-small-min-w}) * 16 / 9)";
@quickies-vertical-small-h-px:				@quickies-vertical-h-px;
@quickies-vertical-small-w-vh:				~"calc(@{quickies-vertical-small-max-h-perc} * var(--vh, 1vh) / 16 * 9)";
@quickies-vertical-small-w-vw:				~"calc(100vw - @{quickies-nextprev-small-min-w})";
@quickies-vertical-small-w-px:				@quickies-vertical-w-px;

@quickies-horizontal-small-w-vh:			@quickies-horizontal-w-vh;
@quickies-horizontal-small-w-vw:			~"calc(100vw - (@{quickies-nextprev-small-min-w} * 2))";
@quickies-horizontal-small-w-px:			@quickies-horizontal-w-px;
@quickies-horizontal-small-h-vh:			@quickies-horizontal-h-vh;
@quickies-horizontal-small-h-vw:			~"calc((100vw - (@{quickies-nextprev-small-min-w} * 2)) / 16 * 9)";
@quickies-horizontal-small-h-px:			@quickies-horizontal-h-px;


// ------------------------------------
// PLAYER FINAL -----------------------
// ------------------------------------

@quickies-vertical-h:						~"min(@{quickies-vertical-h-vh}, min(@{quickies-vertical-h-vw}, @{quickies-vertical-h-px}))";
@quickies-vertical-w:						~"min(@{quickies-vertical-w-vh}, min(@{quickies-vertical-w-vw}, @{quickies-vertical-w-px}))";

@quickies-horizontal-w:						~"min(@{quickies-horizontal-w-vh}, min(@{quickies-horizontal-w-vw}, @{quickies-horizontal-w-px}))";
@quickies-horizontal-h:						~"min(@{quickies-horizontal-h-vh}, min(@{quickies-horizontal-h-vw}, @{quickies-horizontal-h-px}))";

//small
@quickies-vertical-small-h:					~"calc(100 * var(--vh, 1vh))";
@quickies-vertical-small-w:					~"100vw";

@quickies-horizontal-small-h:				~"calc(100 * var(--vh, 1vh))";
@quickies-horizontal-small-w:				~"100vw";




// ------------------------------------
// NEXT & PREV BTNS -------------------
// ------------------------------------

@quickies-vertical-nextprev-h:				~"calc(@{quickies-vertical-h} / 2)";
@quickies-vertical-nextprev-w:				~"calc((100vw - (@{quickies-vertical-w})) / 2)";
@quickies-vertical-nextprev-lr:				~"calc(50vw + (@{quickies-vertical-w}) / 2)";
@quickies-vertical-prev-t:					~"calc(( 	(100 * var(--vh, 1vh)) - @{quickies-vertical-h} ) / 2)";
@quickies-vertical-next-t:					~"calc((( 	(100 * var(--vh, 1vh)) - @{quickies-vertical-h} ) / 2) + @{quickies-vertical-nextprev-h})";

@quickies-horizontal-nextprev-h:			~"calc(@{quickies-horizontal-h} / 2)";
@quickies-horizontal-nextprev-w:			~"calc((100vw - (@{quickies-horizontal-w})) / 2)";
@quickies-horizontal-nextprev-lr:			~"calc(50vw + (@{quickies-horizontal-w}) / 2)";
@quickies-horizontal-prev-t:				~"calc((	(100 * var(--vh, 1vh)) - @{quickies-horizontal-h}) / 2)";
@quickies-horizontal-next-t:				~"calc(((	(100 * var(--vh, 1vh)) - @{quickies-horizontal-h}) / 2) + @{quickies-horizontal-nextprev-h})";




// ------------------------------------
// CLOSE SWIPE -------------------
// ------------------------------------

@quickies-vertical-close-swipe-w:			@quickies-vertical-w;
@quickies-vertical-close-swipe-t:			@quickies-vertical-prev-t;
@quickies-vertical-close-swipe-l:			~"calc((100vw - (@{quickies-vertical-close-swipe-w})) / 2)";
@quickies-horizontal-close-swipe-w:			@quickies-horizontal-w;
@quickies-horizontal-close-swipe-t:			@quickies-horizontal-prev-t;
@quickies-horizontal-close-swipe-l:			~"calc((100vw - (@{quickies-horizontal-close-swipe-w})) / 2)";

//small
@quickies-vertical-small-close-swipe-w:		@quickies-vertical-small-w;
@quickies-vertical-small-close-swipe-t:		~"calc(( 	(100 * var(--vh, 1vh)) - @{quickies-vertical-small-h} ) / 2)";
@quickies-vertical-small-close-swipe-l:		~"calc((100vw - (@{quickies-vertical-small-close-swipe-w})) / 2)";
@quickies-horizontal-small-close-swipe-w:	@quickies-horizontal-small-w;
@quickies-horizontal-small-close-swipe-t:	~"calc(( 	(100 * var(--vh, 1vh)) - @{quickies-horizontal-small-h} ) / 2)";
@quickies-horizontal-small-close-swipe-l:	~"calc((100vw - (@{quickies-horizontal-small-close-swipe-w})) / 2)";




// ------------------------------------
// ACTIONS ----------------------------
// ------------------------------------

@quickies-vertical-actions-h:				~"calc((@{quickies-vertical-h} / 2) - @{quickies-actions-b})";

//small
@quickies-vertical-small-actions-h:			~"calc(@{quickies-vertical-small-h} - @{quickies-actions-b} - @{quickies-close-h})";
@quickies-horizontal-small-actions-h:		~"calc(@{quickies-horizontal-small-h} - @{quickies-actions-b} - @{quickies-close-h})";






.addCloseInBefore() {
	&::before {
		bottom: calc(~"100% + 30px");
		content: @icf-close-thin;
		color: #fff;
		cursor: pointer;
		display: inline-block;
		.font-family-iconfont;
		font-size: 20px;
		right: 20px;
		position: absolute;
		text-shadow: 2px 2px 5px #000;
		z-index: 2;
	}
}



.quickies__main-container {
	background: fadeout(#000, 10%);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: @zindex-quickies-manager-overall;
	
	html.qucks-full-screen-display & {
		background: #000;
		height: calc(~"100 * var(--vh, 1vh)");
		
		&.quickies--horizontal {
			height: calc(~"100 * var(--vh, 1vh)");
		}
	}
}


// ------------------------------------
// LIST ELEMENTS ----------------------
// ------------------------------------

@listElemPX: 180;
@listElemMargin: 10px;





// ALL quickies container
.quickies__players,
.quickies__sub-players { //playerS
	bottom: 0;
	height: calc(~"100 * var(--vh, 1vh)");
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: calc(~"100% - 500px");
	z-index: @quickies-zindex-manager-players;
	
	html.qucks-full-screen-display & {
		width: 100vw;
		
		.quickies__sub-players {
			display: none;
		}
	}
}

.quickies__desktop-profile-info {
	bottom: 0;
	height: calc(~"100 * var(--vh, 1vh)");
	position: absolute;
	right: 0;
	top: 0;
	width: 500px;
	z-index: @quickies-zindex-manager-desktop-profile;
	
	html.qucks-full-screen-display & {
		display: none;
	}
}

.quickies__players {
	&.quickies__players--profile-level-container {
		display: none;
		z-index: @quickies-zindex-manager-players-level-2;
	}
	
	&.quickies__players--profile-level {
		background: #000;
		left: 100%;
		width: 100%;
	}
}

.quickies__sub-players {
	padding: 30px;
	z-index: @quickies-zindex-manager-sub-players;
}

// ONE quickie container
.quickies__container {
	background: #000;
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0s top ease-out;
	
	html.qucks-side-panel-display & {
		padding: 0 @quickies-action-h;
	}
}

// ONE quickie PLAYER container
.quickies__player { //player_
	background: #000;
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: @quickies-zindex-playerbox-player;
	
	video {
		background: transparent;
		color: transparent;
		opacity: 0;
	}
	
	video,
	.quickies__player-bg {
		height: auto;
		left: 50%;
		max-height: 100vh;
		max-height: calc(~"100 * var(--vh, 1vh)");
		max-width: calc(~"100% - @{quickies-action-h} * 2");
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		z-index: @quickies-zindex-playerbox-player-video;
		
		html.qucks-full-screen-display & {
			max-height: 100%;
			max-width: 100%;
		}
		
		&::-internal-media-controls-overlay-cast-button {
			display: none;
		}
	}
	
	.quickies__player-bg--blur {
		background-size: cover;
		background-position: center center;
		bottom: 0;
		filter: blur(30px) brightness(0.4);
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: @quickies-zindex-playerbox-player-bg-blur;
	}
	
	.quickies__player-bg {
		background-size: cover;
		background-position: center center;
		height: 100vh;
		height: calc(~"100 * var(--vh, 1vh)");
		width: 100%;
		z-index: @quickies-zindex-playerbox-player-bg;
		
		
		html.qucks-side-panel-display & {
			width: calc(~"100% - @{quickies-action-h} * 2");
		}
		@media (max-aspect-ratio: round(unit(@quickies-small-media-max-h + (@quickies-action-h * 2) + @quickies-desktop-profile-w)) ~"/" round(unit(@quickies-small-media-max-h))) {
			//player max 1/1
			html.qucks-side-panel-display .quickies__container--horizontal & {
				height: 0;
				padding-bottom: calc(~"(100% - @{quickies-action-h} * 2) * 0.5625");
				padding-right: 0;
			}
			
			html.qucks-side-panel-display .quickies__container--vertical & {
				height: 0;
				padding-bottom: calc(~"(100% - @{quickies-action-h} * 2) * 1.7778");
				padding-right: 0;
			}
		}
		@media (min-aspect-ratio: round(unit(@quickies-small-media-max-h + (@quickies-action-h * 2) + @quickies-desktop-profile-w)) ~"/" round(unit(@quickies-small-media-max-h))) {
			//player min 1/1
			html.qucks-side-panel-display .quickies__container--horizontal & {
				width: 0;
				padding-bottom: 0;
				.padding-right-vh(177.78);
			}
			
			html.qucks-side-panel-display .quickies__container--vertical & {
				width: 0;
				padding-bottom: 0;
				.padding-right-vh(56.25);
			}
		}
		
		@media (min-aspect-ratio: round(unit((@quickies-small-media-max-h / 16 * 9) + (@quickies-action-h * 2) + @quickies-desktop-profile-w)) ~"/" round(unit(@quickies-small-media-max-h))) {
			//player min 9/16
			html.qucks-side-panel-display .quickies__container--vertical & {
				height: 100vh;
				height: calc(~"100 * var(--vh, 1vh)");
				padding-bottom: 0;
				.padding-right-vh(56.25);
				width: 0;
			}
		}
		
		@media (max-aspect-ratio: round(unit((@quickies-small-media-max-h / 9 * 16) + (@quickies-action-h * 2) + @quickies-desktop-profile-w)) ~"/" round(unit(@quickies-small-media-max-h))) {
			//player max 16/9
			html.qucks-side-panel-display .quickies__container--horizontal & {
				height: 0;
				padding-bottom: 56.25%;
				padding-right: 0;
				width: 100%;
			}
		}
		
		@media @media-ratio-vertical and (max-width: @quickies-small-media-max-w), @media-ratio-vertical and (max-height: @quickies-small-media-max-h) {
			.quickies__container--horizontal & {
				height: 0;
				padding-bottom: 56.25%;
				padding-right: 0;
			}
			
			.quickies__container--vertical & {
				height: 0;
				padding-bottom: 177.78%;
				padding-right: 0;
			}
		}
		@media @media-ratio-horizontal and (max-width: @quickies-small-media-max-w), @media-ratio-horizontal and (max-height: @quickies-small-media-max-h) {
			.quickies__container--horizontal & {
				width: 0;
				padding-bottom: 0;
				.padding-right-vh(177.78);
			}
			
			.quickies__container--vertical & {
				width: 0;
				padding-bottom: 0;
				.padding-right-vh(56.25);
			}
		}
		
		@media (min-aspect-ratio: ~"9/16") and (max-width: @quickies-small-media-max-w), (min-aspect-ratio: ~"9/16") and (max-height: @quickies-small-media-max-h) {
			.quickies__container--vertical & {
				height: 100vh;
				height: calc(~"100 * var(--vh, 1vh)");
				padding-bottom: 0;
				.padding-right-vh(56.25);
				width: 0;
			}
		}
		
		@media (max-aspect-ratio: ~"16/9") and (max-width: @quickies-small-media-max-w), (max-aspect-ratio: ~"16/9") and (max-height: @quickies-small-media-max-h) {
			.quickies__container--horizontal & {
				height: 0;
				padding-bottom: 56.25%;
				padding-right: 0;
				width: 100%;
			}
		}
		
	}
	
	.loader {
		color: #fff;
		font-size: 100px;
		left: calc(~"50% - 50px");
		opacity: 0.3;
		position: absolute;
		text-shadow: 0 0 10px #000;
		top: calc(~"50% - 50px");
		z-index: @quickies-zindex-playerbox-player-loader;
	}
}

.quickies--anim-c-fadeout {
	display: inline-block;
	left: 50%;
	opacity: 0.6;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%) scale(1);
	z-index: 15;
	
	&.quickies--anim-go {
		opacity: 0;
		transform: translate(-50%, -50%) scale(1.5);
		transition: all .5s ease;
	}
	
	&.icon-f {
		color: #fff;
		font-size: 100px;
	}
}

.quickies__force-play {
	display: inline-block;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 15;
	
	&.icon-f {
		color: #fff;
		font-size: 100px;
	}
}

.quickies__progress {
	cursor: pointer;
	height: 50px;
	left: 50%;
	position: absolute;
	top: 100%;
	transform: translate(-50%, calc(~"-100% + 25px"));
	width: 100%;
	
	z-index: @quickies-zindex-playerbox-progress;
	
	.quickies__progress__bar,
	.quickies__progress__bar-back {
		bottom: 25px;
		background: #fff;
		box-shadow: 0 0 5px #000;
		height: 4px;
		left: 0;
		position: absolute;
		transition: transform 0.2s;
		transform: translateY(0);
		width: 0;
		z-index: 2;
	}
	.quickies__progress__bar-back {
		background: fadeOut(#fff, 60%);
		box-shadow: 0 0 5px fadeOut(#000, 50%);
		width: 100%;
		z-index: 1;
	}
	.quickies__progress__holder {
		border-radius: 50%;
		bottom: 29px;
		background: #fff;
		box-shadow: 0 0 5px #000;
		height: 20px;
		left: 0;
		margin: -10px;
		position: absolute;
		transition: transform 0.2s;
		transform: scale(0) translateY(0);
		width: 20px;
		z-index: 3;
	}
	
	&.is-touched {
		.quickies__progress__bar,
		.quickies__progress__bar-back {
			transform: translateY(-10px);
		}
		.quickies__progress__holder {
			transform: scale(1) translateY(-8px);
		}
	}
	
	.notouch .quickies__container:hover & {
		.quickies__progress__bar,
		.quickies__progress__bar-back {
			transform: translateY(-10px);
		}
		.quickies__progress__holder {
			transform: scale(0.4) translateY(-20px);
		}
	}
}

.quickies__text,
.quickies__uploader-back {
	color: #fff;
	left: 0;
	position: absolute;
	text-shadow: 1px 1px 2px #000;
	z-index: @quickies-zindex-playerbox-text;
	
	a {
		&, &:hover, &:focus, &:visited {
			color: #fff;
		}
	}
	
	.quickies__profile-pic {
		border-radius: 4px;
		box-shadow: 0 0 3px fadeOut(#000, 70%);
		float: left;
		height: 24px;
		margin: 0 10px 2px 0;
		vertical-align: top;
		width: 24px;
	}
	
	.quickies__uploader {
		font-size: 14px;
		font-weight: bold;
		padding: 8px 6px 0 6px;
		position: relative;
		
		.name {
			cursor: pointer;
			.line-clamp(1);
		}
	}
}

.quickies__text {
	bottom: 5px;
	display: block;
	font-size: 24px;
	line-height: 26px;
	padding: 12px 0 20px 6px;
	width: calc(~"100% - @{quickies-action-h}");
	
	.quickies--actions-3cols & {
		width: calc(~"100% - (@{quickies-action-h} * 3)");
	}
	
	.quickies__title {
		padding: 8px 6px 0 0;
		position: relative;
		
		.name {
			cursor: pointer;
			padding-left: 6px;
			.line-clamp(3);
		}
	}
	
	.quickies__full-video-link {
		a {
			color: @red-color;
			font-size: 16px;
			font-weight: bold;
			padding-left: 6px;
			text-decoration: none;
			
			.icf-ticket-red {
				color: @red-color;
				display: inline-block;
				font-size: 26px;
				margin-left: 3px;
				text-shadow: none;
				vertical-align: middle;
			}
		}
	}
	
	html.qucks-side-panel-display & {
		display: none;
	}
}

.quickies__uploader-back {
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	top: 0;
	width: 100%;
	
	.quickies__profile-pic {
		margin-top: 7px;
	}
	
	.icon-f {
		display: inline-block;
		float: left;
		font-size: 20px;
		line-height: 40px;
		vertical-align: top;
		padding: 0 10px 0 10px;
		
		&:before {
			vertical-align: middle;
		}
	}
}

.quickies__close {
	color: #fff;
	cursor: pointer;
	height: @quickies-close-h;
	line-height: @quickies-close-lh;
	padding: 0 0 ~"calc(@{quickies-close-h} - @{quickies-close-lh}) calc(@{quickies-close-h} - @{quickies-close-lh})";
	position: absolute;
	right: 0;
	text-shadow: 0 0 5px #000;
	text-align: center;
	top: 0;
	width: @quickies-close-h;
	z-index: @quickies-zindex-playerbox-close;
	
	.icon-f {
		display: block;
		font-size: 20px;
		line-height: @quickies-close-lh;
		vertical-align: top;
		
		// [--M--]
		@media (max-width: @screen-all-mobile) {
			font-size: 12px;
		}
	}
	
	.quickies__desktop-profile-info & {
		background: @body-bg;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		color: @text-color;
		height: @quickies-desktop-profile-close-h;
		line-height: @quickies-desktop-profile-close-h;
		padding: 0;
		right: 16px;
		text-shadow: none;
		width: @quickies-desktop-profile-close-h;
		
		.icon-f {
			font-size: 20px;
			line-height: @quickies-desktop-profile-close-h;
			
			
			.quickies--profile-level-is-opened &.icf-close-circle-o ,
			.quickies__main-container:not(.quickies--profile-level-is-opened) &.icf-close-circle {
				display: none;
			}
		}
	}
	
	.quickies__container & {
		html.qucks-side-panel-display & {
			display: none;
		}
	}
}

.quickies__actions {
	bottom: 0;
	max-height: @quickies-vertical-actions-h;
	margin-bottom: @quickies-actions-b;
	position: absolute;
	right: 0;
	width: @quickies-action-h;
	z-index: @quickies-zindex-playerbox-actions;
	
	.quickies--actions-3cols & {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap-reverse;
		width: @quickies-action-h;
	}
	
	html.qucks-full-screen-display & {
		max-height: @quickies-vertical-small-actions-h;
		
		.quickies--horizontal & {
			max-height: @quickies-horizontal-small-actions-h;
		}
	}
	
	.quickies__actions__btn {
		text-shadow: 1px 1px 1px fadeOut(#000, 50%);
		color: #fff;
		cursor: pointer;
		display: block;
		font-size: 28px;
		height: @quickies-action-h;
		line-height: @quickies-action-h;
		margin: @quickies-action-m 0;
		position: relative;
		width: @quickies-action-h;
		text-align: center;
		z-index: @quickies-zindex-playerbox-actions-inact;
		
		&.is-opened {
			z-index: @quickies-zindex-playerbox-actions-act;
		}
		
		& > .icon-f {
			display: block;
			line-height: @quickies-action-h;
			vertical-align: top;
			position: relative;
			z-index: 2;
		}
		
		&.quickies__actions__btn--comments {
			.count {
				display: block;
				font-size: 12px;
				font-weight: bold;
				height: @quickies-action-h * 0.92; //to be centered in comment icon
				left: 0;
				line-height: @quickies-action-h * 0.92;
				position: absolute;
				top: 0;
				white-space: nowrap;
				width: @quickies-action-h;
				z-index: 3;
				
				& + .icon-f {
					font-size: 32px;
					opacity: 0.5;
				}
			}
		}
		
		&.quickies__actions__btn--like,
		&.quickies__actions__btn--dislike {
			.count {
				bottom: 0;
				display: block;
				font-size: 10px;
				left: 0;
				line-height: 8px;
				position: absolute;
				white-space: nowrap;
				width: @quickies-action-h;
				z-index: 3;
			}
		}
		
		&.quickies__actions__btn--like.is-liked,
		&.quickies__actions__btn--dislike.is-disliked {
			.icon-f {
				color: #de2600;
			}
			
			&.is-animated {
				.icon-f {
					animation: is_liked_pulse .4s linear;
				}
			}
		}
		
		&.quickies__actions__btn--share,
		&.quickies__actions__btn--fullscreen {
			font-size: 22px;
			text-decoration: none;
		}
		
		&.quickies__actions__btn--fullscreen {
			html:not(.qucks-can-force-full-screen-display) & {
				display: none;
			}
		}
		
		html.qucks-side-panel-display & {
			&.quickies__actions__btn--prev,
			&.quickies__actions__btn--next {
				display: none;
			}
		}
		
		html.qucks-side-panel-display .quickies--with-nav-prev.quickies--cant-swipe &.quickies__actions__btn--prev,
		html.qucks-side-panel-display .quickies--with-nav-prev.quickies--mob-nav-btn &.quickies__actions__btn--prev,
		html.qucks-side-panel-display .quickies--with-nav-prev.quickies--profile-info-cont-visible &.quickies__actions__btn--prev,
		html.qucks-side-panel-display .quickies--with-nav-next.quickies--cant-swipe &.quickies__actions__btn--next,
		html.qucks-side-panel-display .quickies--with-nav-next.quickies--mob-nav-btn &.quickies__actions__btn--next,
		html.qucks-side-panel-display .quickies--with-nav-next.quickies--profile-info-cont-visible &.quickies__actions__btn--next {
			bottom: calc(~"50 * var(--vh, 1vh) - @{quickies-actions-b}");
			display: block;
			font-size: 40px;
			position: absolute;
		}
		
		html.qucks-side-panel-display .quickies--with-nav-next.quickies--cant-swipe &.quickies__actions__btn--next,
		html.qucks-side-panel-display .quickies--with-nav-next.quickies--mob-nav-btn &.quickies__actions__btn--next,
		html.qucks-side-panel-display .quickies--with-nav-next.quickies--profile-info-cont-visible &.quickies__actions__btn--next {
			bottom: calc(~"50 * var(--vh, 1vh) - @{quickies-actions-b} - @{quickies-action-h}");
		}
		
		html.qucks-full-screen-display & {
			&.quickies__actions__btn--prev,
			&.quickies__actions__btn--next {
				height: 0;
				line-height: 0;
				opacity: 0;
				overflow: hidden;
				margin-bottom: 0;
				margin-top: 0;
				
				.profile-overlay-is-opened & {
					transition: all 0s 1s;
				}
			}
		}
		
		html.qucks-full-screen-display .quickies--with-nav-prev.quickies--cant-swipe &.quickies__actions__btn--prev,
		html.qucks-full-screen-display .quickies--with-nav-prev.quickies--mob-nav-btn &.quickies__actions__btn--prev,
		html.qucks-full-screen-display .quickies--with-nav-prev.quickies--profile-info-cont-visible &.quickies__actions__btn--prev,
		html.qucks-full-screen-display .quickies--with-nav-next.quickies--cant-swipe &.quickies__actions__btn--next,
		html.qucks-full-screen-display .quickies--with-nav-next.quickies--mob-nav-btn &.quickies__actions__btn--next,
		html.qucks-full-screen-display .quickies--with-nav-next.quickies--profile-info-cont-visible &.quickies__actions__btn--next {
				height: @quickies-action-h;
				line-height: @quickies-action-h;
				opacity: 1;
				margin-bottom: @quickies-action-m;
				margin-top: @quickies-action-m;
			}
		
		html.qucks-small-height &,
		html.qucks-small-height & > .icon-f,
		html.qucks-small-height .quickies--with-nav-prev.quickies--cant-swipe &.quickies__actions__btn--prev,
		html.qucks-small-height .quickies--with-nav-prev.quickies--mob-nav-btn &.quickies__actions__btn--prev,
		html.qucks-small-height .quickies--with-nav-prev.quickies--profile-info-cont-visible &.quickies__actions__btn--prev,
		html.qucks-small-height .quickies--with-nav-next.quickies--cant-swipe &.quickies__actions__btn--next,
		html.qucks-small-height .quickies--with-nav-next.quickies--mob-nav-btn &.quickies__actions__btn--next,
		html.qucks-small-height .quickies--with-nav-next.quickies--profile-info-cont-visible &.quickies__actions__btn--next {
			height: @quickies-action-small-h;
			line-height: @quickies-action-small-h;
		}
		html.qucks-small-height & {
			&.quickies__actions__btn--comments {
				.count {
					height: @quickies-action-small-h * 0.92; //to be centered in comment icon
					line-height: @quickies-action-small-h * 0.92;
				}
			}
			
			&.quickies__actions__btn--like,
			&.quickies__actions__btn--dislike {
				.count {
					bottom: -4px;
				}
			}
		}
		
		
		@keyframes is_liked_pulse {
			0%, 10% {
				color: lighten(#de2600, 50%);
				opacity: 0.5;
				transform: scale(1);
			}
			45% {
				opacity: 0.75;
				transform: scale(.2) rotate(25deg);
			}
			50% {
				color: #de2600;
				opacity: 1;
				transform: scale(1.8) rotate(-30deg);
			}
			90%, 95% {
				transform: scale(1) rotate(10deg);
			}
			100% {
				transform: rotate(-2.5deg);
			}
		}
	}
}




.quickies__uploader-profile {
	background: @body-bg;
	color: @text-color;
	display: flex;
	flex-direction: column;
	height: 100%;
	left: 100%;
	overflow: auto;
	padding: 40px 5px 0;
	position: absolute;
	top: 0;
	transition: left .5s ease;
	width: 100%;
	z-index: @quickies-zindex-playerbox-profile;
	
	.quickies__desktop-profile-info & {
		left: 0;
	}
	
	.quickies__players & {
		html.qucks-side-panel-display & {
			display: none;
		}
	}
	
	.xscroll-container .scroll {
		flex-grow: 2;
		padding: 0 16px 16px;
	}
	.xscroll-v-added .scroll {
		padding-right: 0;
	}
	& > .scroll {
		flex-grow: 2;
		overflow: auto;
		padding: 0 16px 16px;
	}
	
	.back {
		background: @quickies-profile-overlay-back-bg;
		color: @quickies-profile-overlay-back-color;
		cursor: pointer;
		height: 40px;
		left: 0;
		line-height: 40px;
		position: absolute;
		top: 0;
		width: 100%;
		
		.icon-f {
			display: inline-block;
			font-size: 20px;
			line-height: 40px;
			vertical-align: top;
			padding: 0 5px 0 10px;
			
			&.icf-close-thin {
				float: right;
				padding: 0 10px 0 5px;
			}
		}
	}
	
	h2 {
		align-items: center;
		display: flex;
		font-size: 0;
		justify-content: space-between;
		margin: 0 15px 10px;
		padding: 16px 0 0;
		position: relative;
		z-index: 2;
		
		.quickies__profile-pic-link,
		.quickies__profile-pic {
			display: inline-block;
			height: 75px;
			margin: 0 8px 0 0;
			vertical-align: top;
			width: 75px;
			
			
			html.qucks-small-height & {
				height: 50px;
				width: 50px;
			}
		}
		
		.quickies__profile-pic-link {
			.quickies__profile-pic {
				display: block;
				margin-right: 0;
			}
		}
		
		.profile-name {
			display: inline-block;
			flex-grow: 2;
			font-size: 16px;
			word-break: break-all;
			
			a {
				text-decoration: none;
			}
		}
		
		.name {
			color: @red-color;
			font-size: 20px;
			line-height: 22px;
			
			.icon-f {
				color: @text-color;
				font-size: 20px;
				line-height: 22px;
				margin-left: 4px;
				vertical-align: text-bottom;
			}
		}
		
		.title {
			margin-left: 4px;
			vertical-align: middle;
		}
		
		.flag {
			margin-right: 4px;
			vertical-align: middle;
		}
		
	}
	
	.ban {
		background: transparent top center no-repeat;
		background-size: cover;
		display: none;
		height: 0;
		margin: 0 -5px -40px;
		padding-bottom: calc(~"(100% + 10px) * 0.15625");
		position: relative;
		width: calc(~"100% + 10px");
		z-index: 1;
		
		a {
			background: transparent top center no-repeat;
			background-size: cover;
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
		
		html:not(.qucks-small-height) &.has-banner {
			display: block;
			
			& + h2 {
				align-items: flex-start;
				margin-top: 45px;
				padding-top: 0;
				
				& > .quickies__profile-pic-link,
				& > .quickies__profile-pic {
					margin-top: -40px;
				}
			}
		}
		
		html.qucks-full-screen-display & {
			@media (min-width: 1280px) {
				padding-bottom: 200px;
				a {
					backdrop-filter: blur(20px) brightness(0.4);
					background: transparent top center no-repeat;
					background-size: contain;
				}
			}
		}
	}
	
	.user-subscribe {
		.button-variant(white, @red-color, @red-color) ;
		font-size: 18px;
		height: 32px;
		line-height: 32px;
		padding: 0 10px;
		margin-left: 4px;
		white-space: nowrap;
		
		* {
			height: 32px;
			line-height: 32px;
		}
	}
	
	.quickies__uploader-profile__video-info {
		margin: 0 15px 20px;
		
		.quickies__uploader-profile__video-title {
			display: inline;
		}
		.quickies__uploader-profile__video-stats {
			display: inline;
		}
		.quickies__uploader-profile__video-views {
			color: @gray;
			padding-left: 4px;
			
			.icon-f {
				color: @gray-light;
				font-size: 16px;
				vertical-align: text-bottom;
			}
		}
		.quickies__uploader-profile__video-full-video-link {
			
			&.has-link {
				margin-top: 10px;
				text-align: center;
			}
			
			a {
				color: @red-color;
				font-size: 13px;
				font-weight: bold;
				
				.icf-ticket-red {
					color: @red-color;
					display: inline-block;
					font-size: 26px;
					margin-left: 3px;
					vertical-align: middle;
				}
			}
		}
	}
	
	
	
	.quickies__uploader-profile__content__tabs {
		display: flex;
		font-size: 0;
		justify-content: space-evenly;
		margin: 10px auto 20px;
		padding: 0;
		position: relative;
		width: 90%;
		
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			display: block;
			width: 100%;
			height: 1px;
			background: @thin-tab-border-bottom;
			z-index: 0;
		}
		
		li {
			position: relative;
			display: inline-block;
			margin: 0;
			padding: 0;
			z-index: 1;
			
			& + li {
				margin-left: 10px;
			}
		}
		.quickies__uploader-profile__content__btn {
			border-bottom: 1px solid @thin-tab-border-bottom;
			color: @gray;
			display: block;
			font-size: 14px;
			font-weight: 400;
			line-height: 1;
			margin: 0;
			padding: 5px;
			text-decoration: none;
			
			&:hover {
				color: @text-color;
			}
			
			&.is-active {
				color: @text-color;
				border-color: @thin-tab-border-bottom-active;
			}
			
			.quickies__uploader-profile__content__btn__text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			
			.quickies__uploader-profile__content__btn__count {
				color: @gray-light;
				font-size: 0.9em;
				margin-left: 3px;
			}
			
			.icf-bolt {
				color: @red-color;
			}
		}
	}
	
	.quickies__uploader-profile__content {
		.mozaique {
			margin: 0;
			
			& > .loader {
				display: block;
				font-size: 40px;
				opacity: .15;
				padding: 10px 0 20px;
				text-align: center;
			}
			
			.quickies__list-elem-ratio-vertical(@nbCols) { // portrait thumb
				padding-bottom: calc(~"((100% / " @nbCols ~") - 10px) * 16 / 9");
				width: calc(~"(100% / " @nbCols ~") - 10px");
			}
			.quickies__list-elem-ratio-horizontal(@nbCols) { // lanscape thumb
				padding-bottom: calc(~"((100% / " @nbCols ~") - 10px) / 16 * 9");
				width: calc(~"(100% / " @nbCols ~") - 10px");
			}
			color: green;
			.quickies__list-elem-ratio-all(@nbCols) { // square thumb
				padding-bottom: calc(~"(100% / " @nbCols ~") - 10px");
				width: calc(~"(100% / " @nbCols ~") - 10px");
			}
			
			.quickies__list-elem {
				height: 0;
				margin: 0 5px 5px;
				max-height: none;
				max-width: none;
				
				html:not(.qucks-small-height) & {
					color: purple;
					.quickies__list-elem-ratio-vertical(3);
				}
				html.qucks-small-height & {
					color: brown;
					.quickies__list-elem-ratio-vertical(5);
				}
				
				html:not(.qucks-small-height) &.quickies__list-elem--horizontal,
				html:not(.qucks-small-height) &.is-error,
				html.qucks-full-screen-display:not(.qucks-small-height) & {
					color: pink;
					.quickies__list-elem-ratio-horizontal(2);
				}
				html.qucks-small-height &,
				html.qucks-small-height &.is-error,
				html.qucks-full-screen-display.qucks-small-height & {
					color: orange;
					.quickies__list-elem-ratio-horizontal(4);
				}
			}
			
			&.quickies__uploader-profile__content__tab--mozaique-all {
				.quickies__list-elem {
					&.quickies__list-elem--vertical,
					&.quickies__list-elem--horizontal {
						html:not(.qucks-small-height) & {
							color: red;
							.quickies__list-elem-ratio-all(3);
						}
						
						html.qucks-full-screen-display:not(.qucks-small-height) & {
							color: yellow;
							.quickies__list-elem-ratio-all(2);
						}
						html.qucks-full-screen-display.qucks-small-height & {
							color: blue;
							.quickies__list-elem-ratio-all(4);
						}
						
						.quickies__list-elem__thumb {
							background-size: contain;
						}
					}
				}
			}
			
			&.quickies__uploader-profile__content__tab--mozaique-vertical {
				.quickies__list-elem {
					&.is-error,
					&.quickies__list-elem--vertical,
					&.quickies__list-elem--horizontal {
						html:not(.qucks-small-height) & {
							color: cyan;
							.quickies__list-elem-ratio-vertical(3);
						}
						html.qucks-small-height & {
							color: aquamarine;
							.quickies__list-elem-ratio-vertical(5);
						}
					}
				}
			}
			
			&.quickies__uploader-profile__content__tab--mozaique-horizontal {
				.quickies__list-elem {
					&.is-error {
						&.quickies__list-elem--vertical,
						&.quickies__list-elem--horizontal {
							html:not(.qucks-small-height) & ,
							html.qucks-full-screen-display:not(.qucks-small-height) & {
								color: lawngreen;
								.quickies__list-elem-ratio-horizontal(2);
							}
							html.qucks-small-height & ,
							html.qucks-full-screen-display.qucks-small-height & {
								color: peru;
								.quickies__list-elem-ratio-horizontal(4);
							}
						}
					}
				}
			}
			
			
			@media (min-width: @screen-lg-min) {
				.quickies__list-elem {
					html.qucks-full-screen-display:not(.qucks-small-height) & {
						&,
						&.quickies__list-elem--horizontal,
						&.is-error {
							color: mediumpurple;
							.quickies__list-elem-ratio-horizontal(4);
						}
					}
					html.qucks-full-screen-display.qucks-small-height & {
						&,
						&.quickies__list-elem--horizontal,
						&.quickies__list-elem--vertical,
						&.is-error {
							color: silver;
							.quickies__list-elem-ratio-horizontal(6);
						}
					}
				}
				
				&.quickies__uploader-profile__content__tab--mozaique-all {
					.quickies__list-elem {
						&.quickies__list-elem--vertical,
						&.quickies__list-elem--horizontal {
							html.qucks-full-screen-display:not(.qucks-small-height) & {
								color: rebeccapurple;
								.quickies__list-elem-ratio-all(4);
							}
							html.qucks-full-screen-display.qucks-small-height & {
								color: olive;
								.quickies__list-elem-ratio-all(6);
							}
						}
					}
				}
				
				&.quickies__uploader-profile__content__tab--mozaique-horizontal,
				&.quickies__uploader-profile__content__tab--mozaique-vertical {
					.quickies__list-elem {
						&.is-error {
							&.quickies__list-elem--vertical,
							&.quickies__list-elem--horizontal {
								html.qucks-full-screen-display:not(.qucks-small-height) & {
									color: teal;
									.quickies__list-elem-ratio-horizontal(4);
								}
								html.qucks-full-screen-display.qucks-small-height & {
									color: tomato;
									.quickies__list-elem-ratio-horizontal(6);
								}
							}
						}
					}
				}
			}
		}
		
		& > .loader {
			display: inline-block;
			font-size: 100px;
			left: 50%;
			opacity: 0.15;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	
	
	.x-thread {
		.thread-header {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 0 0 20px;
			
			.thread-title,
			.thread-node-children-count {
				display: none;
			}
			
			.thread-node-btn-post {
				margin: 0;
				order: 1;
			}
			
			.thread-sort,
			.thread-node-btn-sort {
				order: 2;
				margin-left: 10px;
			}
		}
		
		.thread-node {
			.thread-node-content {
				.thread-node-poster {
					.thread-node-poster-name {
						a {
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}




// bottom menu

.quickies__menus {
	background: linear-gradient(0deg, fadeOut(#000, 20%) 80px, transparent 500px);
	bottom: 0;
	cursor: pointer;
	display: none;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: @quickies-zindex-playerbox-menus;
}

.quickies__menu {
	.addCloseInBefore;
	color: #fff;
	bottom: 0;
	cursor: default;
	display: none;
	left: 0;
	padding: 0;
	position: absolute;
	right: 0;
}

.quickies__menu-elem {
	background: #121212;
	border-top: 1px solid #444;
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 16px;
	line-height: 20px;
	margin: 0;
	padding: 15px ~"max(calc(50% - 250px + 20px), 20px)";
	text-align: left;
	text-decoration: none;
	
	&:hover,
	&:active,
	&:focus {
		color: #fff;
		text-decoration: none;
	}
	
	.notouch &:hover {
		background: #1c1c1c;
	}
	
	& > .icon-f {
		&:first-child {
			display: inline-block;
			margin-right: 8px;
		}
	}
}




// comments

.quickies__comments {
	background: fadeOut(#000, 35%);
	bottom: 0;
	display: none;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: @quickies-zindex-playerbox-comments;
	
	&.is-open {
		display: block;
	}
	
	.quickies__comments__close-container {
		.addCloseInBefore;
		background: @body-bg;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		bottom: 0;
		box-shadow: 0 -60px 120px 40px fadeOut(#000, 60%);
		left: 50%;
		max-width: 96%;
		padding: 20px 10px 0 20px;
		position: absolute;
		transform: translateX(-50%);
		width: 800px;
	}
	
	.quickies__comments__container-with-scrollable-content {
		max-height: calc(~"100 * var(--vh, 1vh) - 100px");
		overflow: hidden;
		
		.xscroll-container {
			&:not(.xscroll-v-added) {
				padding-right: 10px;
			}
		}
		
		.xscroll-content {
			max-height: calc(~"100 * var(--vh, 1vh) - 100px");
		}
	}
	
	.quickies__comments__container {
		min-height: 100px;
		
		.x-thread {
			padding: 0 0 20px;
		}
	}
}




// volume control

.quickies__volume-slider {
	z-index: 3;
	position: absolute;
	right: 0;
	top: 0;
	max-width: calc(~"100vw - @{quickies-action-h}");
	width: 300px;
	
	&::before {
		right: 0;
	}
	
	* {
		vertical-align: top;
	}
	
	.slider {
		background: #121212;
		box-shadow: 0 0 0 2px inset #b3b3b3;
		border-radius: (@quickies-action-h / 2);
		display: block;
		cursor: pointer;
		font-size: 0;
		height: @quickies-action-h;
		position: relative;
		text-align: center;
		white-space: nowrap;
		width: 100%;
		z-index: 2;
		
		.icon-f {
			display: inline-block;
			font-size: 28px;
			line-height: @quickies-action-h;
			vertical-align: top;
			width: @quickies-action-h;
		}
		
		.close {
			font-size: 16px;
			position: absolute;
			right: 100%;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	
	.back {
		display: inline-block;
		height: @quickies-action-h;
		margin: 0 (@quickies-action-m * 2) 0 (@quickies-action-m * 3);
		position: relative;
		width: calc(~"100% - (@{quickies-action-m} * 5 ) - @{quickies-action-h}");
		
		.back-bar,
		.bar {
			border-radius: 2.5px;
			height: 5px;
			left: 0;
			top: calc(~"50% - 2.5px");
			position: absolute;
		}
		
		.back-bar {
			background: #888;
			width: 100%;
		}
		
		.bar {
			background: #ccc;
		}
		
		.handle {
			background: #eee;
			border-radius: 50%;
			box-shadow: 0 0 5px fadeOut(#000, 50%);
			cursor: pointer;
			cursor: grab;
			height: 15px;
			margin-left: -7.5px;
			position: absolute;
			top: calc(~"50% - 7.5px");
			width: 15px;
			
			.notouch & {
				transition: transform 0.2s, background 0.2s;
				transform-origin: center;
				
				&:hover {
					background: #fff;
					transform: scale(1.1);
				}
			}
		}
	}
	
	&.is-muted {
		.back {
			opacity: 0.3;
		}
		
		.mute-toggle {
			&:before {
				content: @icf-sound-off;
			}
		}
	}
}

.quickies__volume-slider__back-close {
	background: fadeOut(#000, 50%);
	bottom: 0;
	display: none;
	height: calc(~"100 * var(--vh, 1vh)");
	position: absolute;
	right: 0;
	width: 100vw;
	z-index: @quickies-zindex-playerbox-actions-act-back;
}



// settings

.quickies__actions__btn--settings {
	
	& > .icon-f {
		&.icf-close-thin {
			font-size: 15px;
		}
	}
}

.quickies__setting {
	padding: 15px ~"max(calc(50% - 250px + 60px), 60px)" 15px ~"max(calc(50% - 250px + 20px), 20px)";
	position: relative;
	
	strong {
		white-space: nowrap;
	}
	
	.checkbox,
	.icon-f.no-toggle {
		margin: 0;
		position: absolute;
		right: ~"max(calc(50% - 250px + 20px), 20px)";
		top: 50%;
		transform: translateY(-50%);
	}
	.icon-f.no-toggle {
		text-align: center;
		width: 28px;
	}
}

// CLOSE-SWIPE

.quickies__close-swipe {
	color: #fff;
	font-size: 20px;
	left: 0;
	opacity: 0;
	position: absolute;
	text-align: center;
	top: 0;
	transform-origin: top center;
	width: calc(~"100vw - 500px");
	z-index: @quickies-zindex-manager-close-swipe;
	
	.quickies--profile-level-is-opened &.is-visible {
		z-index: @quickies-zindex-manager-close-swipe-l-2;
		
		&:before {
			background: fadeOut(#000, 30%);
			content: "";
			height: 9998px;
			left: 50%;
			margin: -4999px;
			position: absolute;
			top: 50%;
			width: 9998px;
			z-index: 1;
		}
	}
	
	.icon-f {
		background: #444;
		background-image: radial-gradient(~"#777 max( 0.01% , min( 100% , calc( var(--quickies-close-load, 0.01%) - 5% ) ) ), #444 max( 0.01% , min( 100% , calc( var(--quickies-close-load, 0.01%) + 5% ) ) )");
		border-radius: 50%;
		display: inline-block;
		padding: 10px;
		position: relative;
		transition: background .2s ease;
		z-index: 2;
	}
	
	&.quickies__close-swipe--next {
		bottom: 0;
		top: auto;
		transform-origin: bottom center;
	}
	
	html.qucks-full-screen-display & {
		width: 100vw;
	}
}



//listing in page

.quickies-lat {
	clear: both;
	padding: 0 0 20px;
	margin: 6px 2px;
	position: relative;
	width: 100%;
	
	.quickies-lat__title {
		background: none;
		color: @text-color;
		display: block;
		font-size: 20px;
		font-weight: bold;
		line-height: 30px;
		margin: 6px 0 10px;
		padding: 0 8px;
		position: relative;
		width: 100%;
		white-space: nowrap;
		z-index: 4;
		
		& > .icon-f {
			color: @red-color;
			font-size: 20px;
			vertical-align: middle;
		}
		
		.quickies-lat__rotate-btn {
			color: fadeOut(@text-color, 40%);
			cursor: pointer;
			font-size: 24px;
			margin: -6px 0;
			padding: 6px 4px;
			
			.icon-f {
				transition: all 0.1s;
				vertical-align: middle;
			}
		}
		
		.quickies-lat__filter-btn,
		.quickies-lat__expand-btn,
		.quickies-lat__shrink-btn{
			padding: 0;
		}
		
		.quickies-lat__title-count {
			color: @gray-light;
			font-size: 0.75em;
			font-weight: normal;
			padding: 0 2px;
		}
	}
	
	.mozaique & {
		background: @quickies-lat-listing-bg;
		background-image: linear-gradient(to right, @quickies-lat-listing-fade 0%, @quickies-lat-listing-bg 20%, @quickies-lat-listing-bg 80%, @quickies-lat-listing-fade 100%);
		
		float: left;
		margin-bottom: 20px;
		width: calc(~"100% - 4px");
		
		
		
		&::before,
		&::after {
			bottom: 0;
			content: "";
			position: absolute;
			top: 0;
			width: 5px;
			z-index: 3;
		}
		&::before {
			background-image: linear-gradient(to right, @quickies-lat-listing-fade 0%, transparent 100%);
			left: 0;
		}
		&::after {
			background-image: linear-gradient(to left, @quickies-lat-listing-fade 0%, transparent 100%);
			right: 0;
		}
		
		.quickies-lat__title {
			margin: 0 auto;
			padding: 6px 8px 10px;
		}
	}
	
	&.quickies-lat--horizontal,
	&.quickies-lat--vertical {
		.quickies-lat__title .quickies-lat__rotate-btn {
			.icf-screen-rotation {
				display: inline-block;
			}
			.icf-screen-both-orientation {
				display: none;
			}
		}
	}
	
	&.quickies-lat--vertical {
		.quickies-lat__title .quickies-lat__rotate-btn {
			.icon-f {
				transform: rotate(-135deg);
			}
			.notouch &:hover .icon-f {
				transform: scale(1.1) rotate(-155deg);
			}
		}
	}
	
	&.quickies-lat--horizontal {
		.quickies-lat__title .quickies-lat__rotate-btn {
			.icon-f {
				transform: rotate(-45deg);
			}
			.notouch &:hover .icon-f {
				transform: scale(1.1) rotate(-65deg);
			}
		}
	}
	
	&.quickies-lat--all {
		.quickies-lat__title .quickies-lat__rotate-btn {
			.notouch &:hover .icon-f {
				transform: scale(1.1) rotate(-45deg);
			}
			.icf-screen-rotation {
				display: none;
			}
			.icf-screen-both-orientation {
				display: inline-block;
			}
		}
	}
	
	// [--M--]
	@media (max-width: @screen-all-mobile) {
		.quickies-lat__title {
			font-size: 16px;
			line-height: 24px;
			
			& > .icon-f {
				font-size: 21px;
			}
			
			.quickies-lat__rotate-btn {
				font-size: 21px;
			}
			
			.quickies-lat__title-count {
				font-size: 0.875em;
			}
		}
	}
	
	.xscroll-container {
		&.xscroll-h-added {
			margin-bottom: -20px;
		}
		
		z-index: 2;
	}
	
	.quickies-lat__list {
		font-size: 0;
		height: unit(@listElemPX, px);
		padding: 0 5px;
		position: relative;
		white-space: nowrap;
		width: 100%;
		z-index: 2;
	}
	
	&.quickies-lat--horizontal {
		.quickies-lat__list {
			&,
			.quickies__list-elem {
				height: unit((@listElemPX / 16 * 9), px);
			}
		}
	}
	
	.quickies-lat__loader {
		background: fadeOut(@body-bg, 50%);
		bottom: 0;
		display: none;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 999;
		
		.icon-f {
			color: @text-color;
			font-size: 50px;
			left: calc(~"50% - 25px");
			opacity: 0;
			position: absolute;
			bottom: 100px;
			transition: all .25s;
		}
		
		&.quickies-lat__loader--is-visible {
			.icon-f {
				opacity: 1;
			}
		}
	}
	
	&.quickies-lat--expanded-view {
		padding-bottom: 0;
		
		.quickies-lat__list {
			height: auto;
			white-space: normal;
			
			.thumb-block {
				border-bottom: 0;
			}
		}
		.quickies-lat__loader {
			.icon-f {
				bottom: 120px;
			}
		}
	}
	
	.quickies-lat__filter-btn,
	.quickies-lat__expand-btn,
	.quickies-lat__shrink-btn {
		background: none;
		border: 0;
		color: @text-color;
		float: right;
		font-size: 12px;
		line-height: 30px;
		
		.icon-f {
			display: inline-block;
			line-height: 30px;
			vertical-align: top;
		}
	}
	
	.quickies-lat__shrink-btn {
		.icf-bolt  {
			color: @red-color;
		}
	}
	
	.quickies-lat__load-more-btn-container {
		text-align: center;
		
		// [--M--]
		@media (max-width: @screen-all-mobile) {
			.quickies-lat__shrink-btn {
				display: block;
				float: none;
				width: 100%;
			}
		}
	}
	
	.quickies-lat__load-more-btn {
		display: none;
		font-size: 16px;
		margin: 0 0 10px;
		
		.icon-f {
			display: inline-block;
			font-size: 0.8em;
			vertical-align: middle;
		}
	}
	
}

.quickies-lat__filter__popup {
	.x-popup-content {
		padding: 8px 0;
	}
}
.quickies-lat__filters__filter {
	background: none;
	border: 0;
	color: @tooltip-color;
	display: block;
	font-size: 12px;
	line-height: 20px;
	margin: 0 4px 3px;
	padding: 3px 14px;
	text-align: left;
	width: calc(~"100% - 8px");
	
	&.quickies-lat__filters__filter-current {
		background: @premium-feed-filter-drop-current-bg;
		font-weight: normal;
	}
	
	.notouch &:hover {
		background: @premium-feed-filter-drop-hover-bg;
	}
}



// listing elem

.quickies__list-elem {
	background-color: #000;
	cursor: pointer;
	display: inline-block;
	font-size: 12px;
	margin: 0 @listElemMargin 0 0;
	height: unit(@listElemPX, px);
	overflow: hidden;
	position: relative;
	text-align: left;
	width: unit(@listElemPX / 16 * 9, px);
	white-space: normal;
	
	
	
	.quickies-lat--expanded-view & {
		aspect-ratio: 16/9!important;
		height: auto!important;
		width: 100%!important;
		
		.quickies__list-elem__infos {
			background: linear-gradient(to bottom, transparent, fadeout(#000, 25%));
			padding-top: 70px;
		}
	}
	
	.quickies-lat--expanded-view.quickies-lat--vertical &,
	.quickies-lat--expanded-view.quickies-lat--all & {
		&.quickies__list-elem--vertical {
			.quickies__list-elem__thumb {
				background-size: contain;
			}
		}
	}
	
	.quickies__list-elem__thumb,
	.quickies-lat--all & .quickies__list-elem__back,
	.quickies-lat--expanded-view.quickies-lat--vertical & .quickies__list-elem__back,
	.quickies__uploader-profile__content__tab--mozaique-all & .quickies__list-elem__back {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		bottom: 0;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: 2;
	}
	
	.quickies-lat--all & .quickies__list-elem__back,
	.quickies-lat--expanded-view.quickies-lat--vertical & .quickies__list-elem__back,
	.quickies__uploader-profile__content__tab--mozaique-all & .quickies__list-elem__back {
		filter: blur(10px) brightness(0.7);
		z-index: 1;
	}
	
	.quickies__list-elem__thumb {
		.unavailable,
		.fan-video {
			background: rgba(0,0,0,.6);
			border-radius: 10px;
			color: #fff;
			font-size: 14px;
			height: 20px;
			line-height: 21px;
			position: absolute;
			padding-left: 4px;
			top: 5px;
			right: 5px;
			width: 20px;
			
			&:hover {
				text-decoration: none;
				color: #fff;
			}
			
			&:hover {
				text-decoration: none;
				color: #fff;
			}
		}
		.unavailable {
			border-radius: 2px;
		}
	}
	
	&:not(.is-unavailable):not(.is-error) {
		.unavailable {
			display: none;
		}
	}
	
	&.quickies__list-elem--horizontal {
		height: unit((@listElemPX / 16 * 9), px);
		width: unit(@listElemPX, px);
	}
	
	.quickies-lat--all & {
		&.quickies__list-elem--horizontal {
			height: unit(@listElemPX, px);
			width: unit(@listElemPX, px);
			
			.quickies__list-elem__thumb {
				background-size: contain;
			}
		}
	}
	
	
	.quickies__list-elem__infos {
		background: linear-gradient(to bottom, transparent, fadeout(#000, 50%) 70%);
		bottom: 0;
		color: #fff;
		font-size: 14px;
		padding: 10px 8px 6px;
		position: absolute;
		width: 100%;
		z-index: 3;
		
		.quickies__list-elem__title {
			font-size: 16px;
			.line-clamp(3);
			line-height: 16px;
		}
	}
	
	.viewed-overlay {
		display: none;
	}
	
	&.is-viewed {
		.viewed-overlay {
			background: linear-gradient(to bottom, fadeout(#000, 60%), transparent);
			bottom: 0;
			color: #fff;
			display: block;
			font-size: 34px;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 2;
			
			.icon-f {
				display: block;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				
				.quickies-lat__list & {
					font-size: 24px;
					left: auto;
					right: 4px;
					top: 0;
					transform: none;
				}
			}
		}
	}
	
	&.is-current {
		.quickies__list-elem__thumb, .viewed-overlay {
			box-shadow: 0 0 0 3px inset @red-color;
		}
	}
}



// share

.quickies-popup-share--copied {
	
	&.x-popup.above.right {
		.x-popup-arrow {
			right: (@quickies-action-h / 2) - 5px;
		}
	}
	
	p {
		font-size: 18px;
		line-height: 20px;
		
		&:before {
			content: @icf-check;
			display: inline-block;
			.font-family-iconfont;
			font-size: 18px;
			line-height: 20px;
			vertical-align: top;
			margin-right: 8px;
		}
	}
}

.quickies-popup-share__input {
	background: #fff;
	color: #000;
}



// debug
/*


.quickies__player .quickies__player-bg--blur,
.quickies__uploader-profile {
	.quickies__debug & {
		&:after {
			color: #79ff88;
			content: "NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL - NO_LVL";
			font-size: calc(~"3vh + 3vw");
			font-weight: bold;
			left: -50%;
			opacity: 0.2;
			position: absolute;
			top: 50%;
			text-align: center;
			transform: translateY(-50%) rotate(-20deg);
			width: 200%;
			word-break: break-all;
			z-index: -1;
		}
	}
	
	.quickies__debug .quickies__players--main-level & {
		&:after {
			content: "MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL - MAIN_LVL";
		}
	}
	.quickies__debug .quickies__players--profile-level & {
		&:after {
			content: "PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL - PROF_LVL";
		}
	}
}

.quickies__player .quickies__player-bg--blur {
	.quickies__debug & {
		filter: brightness(0.4);
		&:after {
			font-size: calc(~"6vh + 6vw");
			opacity: 1;
		}
	}
}
.quickies__desktop-profile-info .quickies__uploader-profile {
	.quickies__debug & {
		&:after {
			content: "DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY - DESKTOP_ONLY";
		}
	}
}*/
