
.bottom-msg {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	font-size: 13px;
	line-height: 20px;
	z-index: 190;
	.body {
		max-width: @screen-max;
		margin: 0 auto;
		background: #000;
		background: fadeout(#000, 10%);
		color: #fff;
		padding: 8px 20px;
	}
	.close {
		float: right;
		background: #fff;
		font-size: 16px;
		font-weight: bold;
		background: #fff;
		color: #000;
		border-radius: 50%;
		height: 20px;
		width: 20px;
		line-height: 20px;
		text-align: center;
		cursor: pointer;
	}
	a {
		color: #fff;
	}
	@media(max-width: @screen-xs) {
		.body {
			padding: 5px 10px;
			font-size: 12px;
		}
	}
}