
#arony-pay-form {
	&.x-overlay.x-overlay-box {
		background: #252525;
		
		.x-body {
			margin-top: 10px;
			max-width: 800px;
			
			form {
				.form-group {
					label {
						display: inline-block;
					}
					
					input[type="text"],
					input[type="email"],
					input[type="tel"],
					input[type="password"],
					textarea ,
					select {
						border-radius: 4px;
					}
				}
			}
		}
		
		header {
			padding: 10px;
			text-align: center;
		}
		
		main {
			padding: 10px 20px;
		}
		
		footer {
			color: #fff;
			margin: 25px auto 10px;
			max-width: 800px;
			padding: 0 10px;
			
			a {
				color: #fff;
			}
		}
	}
}