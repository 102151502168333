/* Update elitmovie skin if you update this file */

@import "variables";
@import "bootstrap";
@import "../../libs/datetimepicker";
.split-css-rule{display:none;color:#123456}
@import "../../common/xvxn_common";
@import "../../common/dragdrop_select_and_reorder";
@import "../../common/emoji";
@import "../../common/threads";
.split-css-rule{display:none;color:#123456}
@import "elements";
@import "page";
.split-css-rule{display:none;color:#123456}
@import "modules";
@import "modules/chat";
@import "responsive";
@import "light-theme";
