.profile-page {
	#header {
		.white-stripe {
			.header-icons {
				#mobile-login-btn {
					background: @header-logged_btn_bg;
				}
			}
		}
	}
}

#main a.fan-video {
	text-decoration: none;
}

.fan-video {
	color: #fff;
	&:hover {
		text-decoration: none;
		color: #fff;
	}
	.thumb-inside & {
		font-size: 12px;
		line-height: 21px;
		background: rgba(0,0,0,.6);
		position: absolute;
		bottom: 5px;
		right: 5px;
		width: 20px;
		border-radius: 10px;
		height: 20px;
	}
}

.x-overlay,
#page {
	.mozaique {
		.thumb-block {
			.thumb-under{
				position: relative;
				.prof-thumb-label,
				.prof-thumb-title {
					display: inline-block;
					background: @theme-color;
					color: #fff;
					font-weight: bold;
					text-align: center;
					border-radius: 1px;
					.xv-label-mixin(28px);
					font-size: 14px;
					vertical-align: baseline;
					position: absolute;
				}
				.prof-thumb-label {
					bottom: 3px;
					left: 3px;
				}
				.prof-thumb-title {
					top: 3px;
					right: 3px;
				}
				.profile-pic {
					float: left;
					height: 36px;
					border: 1px solid @thumb-block-thumb-border;
					margin-right: 2px;
				}
				.profile-name {
					font-weight: bold;
				}
				.profile-counts {
					font-size: 13px;
					height: 20px;
					line-height: 20px;
					.with-sub {
						display: inline-block;
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.sub-strip.active {
						.sub-state-text {
							margin-right: -6px;
						}
					}
					.user-subscribe, .sub-state-text, .count {
						font-size: 13px;
						height: 20px;
						line-height: 20px;
					}
					.sub-strip {
						margin-left: 3px;
						vertical-align: top;
					}
					.sub-strip, .user-subscribe {
						padding: 0 6px;
					}
					.icon-f {
						padding-top: 3px;
					}
				}
				.profile-aboutme {
					bottom: 0;
					left: 0;
					margin: 0;
					overflow: hidden;
					position: absolute;
					width: 100%;
					
					.profile-aboutme-content {
						background: @gray-lighter;
						height: 42px;
						position: relative;
						
						p {
							font-style: italic;
							font-size: 12px;
							height: auto;
							line-height: 1.5;
							max-height: inherit;
							.no-line-clamp-block();
							padding: 3px 10px;
							text-align: center;
						}
					}
					.show-more {
						position: absolute;
						right: 5px;
						bottom: 5px;
						display: none;
						width: 18px;
						height: 18px;
						padding: 0;
						margin: 0;
						text-align: center;
						font-weight: bold;
						font-size: 20px;
						line-height: 18px;
						color: #fff;
						background: lighten(@gray-light, 15%);
						border: 0;
						border-radius: 3px;
						cursor: pointer;
						&:hover {
							background: lighten(@gray-light, 5%);
						}
						&:active,
						&:focus {
							outline: none;
							border: none;
						}
					}
					&.is-oneline {
						.profile-aboutme-content {
							p {
								line-height: 36px;
							}
						}
					}
					&.is-close,
					&.is-open {
						.profile-aboutme-content {
							p {
								padding: 3px 25px 3px 10px;
							}
						}
						.show-more {
							display: block;
						}
					}
					&.is-close {
						.profile-aboutme-content {
							p {
								.line-clamp(2);
							}
						}
					}
					&.is-open {
						.profile-aboutme-content {
							height : auto;
							p {
								.no-line-clamp-block();
								text-overflow: clip;
								-o-text-overflow: clip;
								overflow-y: auto;
							}
						}
					}
				}
			}
		}
		&.profileslist.with-about-me {
			.thumb-block {
				.thumb-under {
					padding-bottom: 55px;
				}
			}
		}
	}
}

.x-profiles-date-links-popup {
	&.x-popup.below .x-popup-arrow {
		border-bottom-color: @text-invert-color;
	}
	.x-popup-content {
		background: @text-invert-color;
		padding: 10px 0;
		box-shadow: 1px 1px 5px fade(@text-color, 30%);
	}
	.profiles-date-links {
		margin: 0;
		padding: 0;

		li {
			.icon-f {
				display: none;
			}
			a {
				display: block;
				padding: 5px 20px;
				color: @text-color;
				font-size: 14px;
				text-decoration: none;
				&:hover {
					background: @gray-bg;
				}
			}
			&.active a {
				background: @gray-bg;
				font-weight: bold;
			}
			.icon-arrow-down {
				display: none;
			}
		}

		&.is-hidden {
			display: none;
		}
	}
}

.rating-box {
	display: inline-block;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	.vote-actions {
		vertical-align: bottom;
		margin-right: 5px;
		.btn {
			margin: 0;
			padding: 4px 6px 0;
			position: relative;
			
			.loader {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			
			.icon-f {
				color: #FFFFFF;
				display: inline-block;
				font-size: 15px;
				height: 16px;
				line-height: 16px;
				width: 15px;
			}
			
			&.vote-action-good {
				.icon-f {
					color: #000;
				}
			}
			
			&.vote-action-bad {
				.icon-f {
					color: @red-color;
				}
			}
			
			&.is-voted {
				background: @theme-color;
				border-color: @red-color-2;
				
				.icon-f {
					color: #FFF;
				}
			}
			
			&.disabled {
				.icon-f {
					color: #ccc;
				}
			}
			
			&:first-child {
				border-radius: @btn-border-radius-base 0 0 @btn-border-radius-base;
				border-right-width: 0;
			}
			
			&:last-child {
				border-radius: 0 @btn-border-radius-base @btn-border-radius-base 0;
				border-right-width: 1px;
			}
			
			[dir="rtl"] & {
				&:first-child {
					border-radius: 0 @btn-border-radius-base @btn-border-radius-base 0;
					border-right-width: 1px;
				}
				
				&:last-child {
					border-radius: @btn-border-radius-base 0 0 @btn-border-radius-base;
					border-right-width: 0;
				}
			}
		}
	}
	.rating-bar-block {
		display: inline-block;
		vertical-align: bottom;
		span {
			vertical-align: text-top;
		}
		.thumb-up {
			margin-left: 15px;
		}
		.thumb-down {
			margin-left: 5px;
		}
	}
	.rating-bar {
		background: @red-color;
		height: 3px;
		margin-top: 1px;
		div {
			background: @gray;
			height: 3px;
		}
	}
	.rating {
		font-size: 14px;
		line-height: 24px;
		background: #555;
		color: #fff;
		padding: 4px 5px;
		margin-left: 5px;
	}
	.total {
		font-size: 11px;
		padding-left: 10px;
	}
	.x-overlay .x-body {
		padding: 1px 5px;
		font-size: 10px;
		line-height: 12px;
		text-align: center;
	}
}

.rating-box-light {
	display: inline-block;
	font-size: 11px;
	line-height: 16px;
	background: lighten(@gray-bg, 5%);
	border-radius: 2px;
	padding: 2px 5px 3px;
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	.rating-bar {
		display: none;
		/*position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 1px;
		background: lighten(@red-color, 15%);
		div {
			background: lighten(@gray, 15%);
			height: 1px;
		}*/
	}
	.rating {
		font-weight: bold;
	}
	.x-overlay .x-body {
		padding: 1px 5px;
		font-size: 10px;
		line-height: 12px;
		text-align: center;
	}
}

#main {
	.page-title.channel {
		font-size: 22px;
		margin: 12px 2px 8px;
		&.lighter {
			font-weight: normal;
		}
		a {
			text-decoration: none;
		}
		.icf-ticket-red {
			font-size: 36px;
			vertical-align: bottom;
			line-height: 32px;
		}
	}
	h3.profile {
		margin: 9px 0;
	}
	.bg-title {
		.rating-box {
			float: right;
			[dir="rtl"] & {
				float: left;
			}
		}
	}
	.rating-box {
		.show-more{
			float: right;
			margin: 3px 0 0 8px;
		}
		.rating-box-content {
			display: none;
			float: left;
		}
		[dir="rtl"] & {
			.show-more{
				float: left;
				margin: 3px 8px 0 0;
			}
			.rating-box-content {
				float: right;
			}
		}
	}
}

.voters-list {
	.voters-list-title {
		margin: 0 0 10px 10px;
		font-weight: bold;
		font-size: 20px;
	}
	.voters-list-votes {
		opacity: 0.5;
		font-size: 0.8em;
		
		& > span {
			padding-left: 10px;
			white-space: nowrap;
		}
		
		.icf-heart {
			color: @red-color;
		}
	}
	
	.voters {
		max-height: 75vh;
		padding: 0;
		margin: 0 auto 20px auto;
		text-align: left;
		font-size: 0;
		overflow-y: auto;
		
		li {
			display: inline-block;
			vertical-align: top;
			width: 120px;
			padding: 5px 10px;
			text-align: center;
			overflow: hidden;
			
			&.action {
				.btn {
					position: relative;
					display: block;
					width: 100%;
					margin: 0;
					padding: 0;
					white-space: normal;
					
					&:before {
						content: '';
						display: block;
						padding-top: 100%;
					}
					
					span {
						position: absolute;
						top: 50%;
						left: 0;
						display: block;
						width: 100%;
						padding: 0 3px;
						font-size: 12px;
						white-space: normal;
						transform: translateY(-50%);
					}
				}
			}
		}
		
		a {
			display: block;
			width: 100%;
			text-decoration: none;
		}
		
		figure {
			position: relative;
			width: 100%;
			background: white;
			
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
			}
		}
		
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			transform: translate3D(-50%, -50%, 0);
		}
		
		span {
			display: block;
			font-size: 14px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}


@import "profile/search.less";
@import "profile/page.less";
@import "profile/posts.less";
