@import "../../../common/premium.less";

.bg_img_slide(@num, @main_cat, @ethnic) {
	.bg_img_folder("default/red/tour-bg/@{main_cat}/@{ethnic}/slide-@{num}.jpg");
}

body#red-page {
	each(@tour_bg_sections, .(@section) {
		.main-slider__item--img-@{section} {
			.slider-bg {
				.bg_img_slide(@section, @tour_bg_main_cat_default, @tour_bg_ethnic_default);
			}
		}
	});
	each(@tour_bg_main_cats, .(@main_cat) {
		&.bg-@{main_cat} {
			each(@tour_bg_ethnics, .(@ethnic) {
				&.bg-@{ethnic} {
					each(@tour_bg_sections, .(@section) {
						.main-slider__item--img-@{section} {
							.slider-bg {
								.bg_img_slide(@section, @main_cat, @ethnic);
							}
						}
					});
				}
			})
		}
	});
}

.floating-purchase-btn {
	bottom: 5px;
	box-shadow: 0 2px 5px fadeout(#000, 50%);
	font-size: 18px;
	left: 50%;
	max-width: 300px;
	position: fixed;
	transform: translateX(-50%);
	white-space: normal;
	width: 90%;
	z-index: @zindex-profile-sub-btn;
}

.penthouse-issues {
	#content {
		h2 {
			margin-bottom: 24px;
		}
		> .alert {
			font-size: 14px;
			.btn {
				margin-bottom: 0;
			}
		}
	}
	#mailing-data {
		border: 1px solid @gray-lighter;
		background: @gray-lighterer;
		padding: 6px 16px;
		
		> fieldset > div > .alert {
			font-size: 14px;
			margin-bottom: 8px;
		}
		
		@media (min-width: @screen-sm-min) {
			.form-field-mailing-data_data_first_name,
			.form-field-mailing-data_data_last_name,
			.form-field-mailing-data_data_city,
			.form-field-mailing-data_data_province_state,
			.form-field-mailing-data_data_zip_code,
			.form-field-mailing-data_data_country,
			.form-field-mailing-data_data_phone,
			.form-field-mailing-data_data_email,
			.form-field-mailing-data_data_birth_date {
				width: calc(50% + 30px);
				float: left;
			}
		
			.form-field-mailing-data_data_address_line_1,
			.form-field-mailing-data_data_address_line_2,
			.form-buttons {
				.col-sm-2 {
					width: 8.33333333%;
				}
				
				.col-sm-10 {
					width: 91.66666667%;
					&.col-sm-offset-2 {
						margin-left: 8.33333333%;
					}
				}
			}
		}
	}
	#issues-list {
		margin-top: 24px;
	}
	h2 + form.hidden + #issues-list {
		margin-top: 0;
	}
}
