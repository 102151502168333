#signin-popup-form {
	position: relative;
	z-index: 1;
	
	.text-danger {
		color: @theme-color;
	}
	
	h2 {
		display: inline-block;
		font-size: 30px;
		line-height: 36px;
		margin: 0 0 16px;
		padding: 0 5px;
		text-transform: uppercase;
		background: rgba(0, 0, 0, 0.7);
		
		img {
			display: inline-block;
			vertical-align: top;
		}
	}
	
	legend {
		color: #fff;
		font-weight: bold;
	}
	
	.fieldset-header {
		font-weight: bold;
		font-size: 15px;
		margin: 0 0 10px;
	}
	
	.form-control {
		border-radius: 0;
	}
	
	.help-block {
		color: #ddd;
	}
	
	.error-block {
		color: @theme-color;
	}
	
	.btn-default,
	.btn-danger {
		border: 0;
		box-shadow: none;
		border-radius: 0;
		white-space: normal;
	}
	
	.btn-default {
		background: #fff;
		color: #000;
		
		&:hover,
		&:active,
		&.active {
			background: @theme-color;
			color: #fff;
			
			.text-danger {
				color: #fff;
			}
		}
		
		&.has-verror,
		&.disabled {
			background: @gray-light;
			color: @gray-dark;
			font-style: italic;
		}
	}
	
	.btn-danger {
		min-width: 200px;
		
		&.has-verror,
		&.disabled {
			&,
			&:active {
				background: darken(@theme-color, 10%);
			}
		}
	}
	
	.form-buttons {
		margin-bottom: 0;
		
		> div {
			margin-left: 0;
			width: 100%;
			
			> div {
				display: none;
			}
		}
	}
	
	.login-mobile-show {
		display: none;
	}
	
	#signin-form-block,
	#login-dynamic-form-block {
		.btn-danger {
			display: block;
			width: 100%;
		}
	}
	
	#signin-form-block {
		button {
			&.is-signinonly-init {
				.signin-only {
					display: none;
				}
				
				&.is-signin-only {
					font-size: 1.5rem;
					
					.not-signin-only {
						display: none;
					}
					
					.signin-only {
						display: inline;
					}
				}
			}
		}
	}
	
	#signup-form {
		.form-field-signup-form_details_tos_pp .content,
		.form-field-signup-form_step2_optional_marketing_internal .content,
		.form-field-signup-form_details_marketing_internal .content,
		.form-field-signup-form_step2_optional_marketing_partners .content {
			margin-left: 0;
			width: 100%;
		}
	}
	
	#signup-form_step2 {
		.form-field-signup-form_step2_optional_country .help-block {
			margin-bottom: 0;
		}
		
		.form-buttons {
			padding-left: 15px;
			padding-right: 15px;
			overflow: hidden;
			clear: both;
		}
	}
	
	#signup-form_step2_profilepic {
		legend {
			max-width: 70%;
		}
		
		.form-group {
			overflow: auto;
			/*background: rgba(0,0,0,0.7);*/
			
			input[type=file] {
				border: 1px solid @brand-success;
			}
			
			.help-block {
				margin-top: -2px;
			}
		}
		
		.content {
			width: 100%;
			margin-left: 0;
		}
	}
	
	#signup-form_step2_marketing {
		float: left;
		width: 45%;
		margin: 20px 0 0 0;
		padding-left: 15px;
		padding-right: 15px;
		
		h3 {
			margin: 0;
			line-height: 1;
			
			.marketing-content {
				display: inline-block;
				vertical-align: top;
				text-transform: uppercase;
				font-weight: normal;
				font-size: 22px;
				line-height: 1;
				
				span {
					display: block;
					font-weight: bold;
					font-size: 106px;
					line-height: 0.8;
				}
			}
			
			.logo {
				display: inline-block;
				margin: 3px 0 0 10px;
				text-align: center;
				
				svg {
					display: block;
					margin-bottom: 2px;
				}
				
				.icf-ticket-red {
					font-size: 110px;
					line-height: 0.60;
					color: @red-color;
				}
			}
		}
		
		.marketing-mentions {
			display: block;
			margin-bottom: 30px;
			font-size: 12px;
			
			.asterisque {
				color: @red-color;
			}
		}
		
		.form-group {
			margin-left: 0;
			margin-right: 0;
			
			.checkbox.toggle {
				input {
					&:checked {
						& + label {
							&:before {
								background: @red-color;
								border-color: @toggle-border-focus;
							}
						}
					}
				}
				
				label {
					&:before {
						background: #333333;
						border: 1px solid #333333;
					}
				}
			}
		}
	}
	
	#signup-form-iframe {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 1px;
		height: 1px;
		opacity: 0;
		border: 0;
	}
	
	#btn-content-owner {
		float: left;
		margin-bottom: 0;
	}
	
	#btn-login2lostpassword {
		&:before {
			content: ' ';
			display: block;
			clear: right;
		}
	}
	
	#lostpassword-form-block {
		fieldset {
			.text-danger {
				padding: 0 5px;
				background: rgba(0, 0, 0, 0.7);
			}
		}
	}
}

#create-account-frame {
	.info-pannel {
		border-left: 1px solid #fff;
		padding-left: 30px;
		
		h3 {
			font-size: 22px;
			line-height: 1.4;
			margin-bottom: 30px;
		}
		
		.advantage {
			margin: 15px 0;
			
			p {
				font-weight: bold;
				font-size: 15px;
				margin: 0;
				overflow: hidden;
			}
			
			.icon-f {
				display: block;
				float: left;
				margin-right: 5px;
				font-size: 22px;
				line-height: 24px;
				
				&.icf-users {
					font-size: 20px;
				}
			}
		}
		
		.btn-default {
			width: 100%;
			display: block;
			margin-top: 30px;
		}
	}
}

.signin-popup-form {
	&.straight {
		.x-body {
			background-image: url('../../img/skins/default/form-signup-straight.png');
		}
	}
	
	&.gay {
		.x-body {
			background-image: url('../../img/skins/default/form-signup-gay.png');
		}
	}
	
	&.trans,
	&.shemale {
		.x-body {
			background-image: url('../../img/skins/default/form-signup-trans.png');
		}
	}
	
	.x-body {
		max-width: 1066px;
		background-color: #000000;
		/*background-color: rgba(0,0,0,0.9);*/
		background-repeat: no-repeat;
		background-position: right top;
		background-size: auto 100%;
		background-size: contain;
		overflow: hidden;
		
		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 100px;
			height: 230px;
			background: @red-color;
			transform: rotate(30deg);
			z-index: 0;
		}
		
		&:before {
			top: -42px;
			left: -34px;
		}
		
		&:after {
			right: -34px;
			bottom: -42px;
		}
	}
}

.account-page.anonymous #page.exec-manage-login_create {
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: auto 100%;
	overflow: hidden;
	
	&:before,
	&:after {
		content: '';
		position: absolute;
		display: block;
		width: 100px;
		height: 230px;
		background: @red-color;
		transform: rotate(30deg);
		z-index: 0;
	}
	
	&:before {
		top: -42px;
		left: -34px;
	}
	
	&:after {
		right: -34px;
		bottom: -42px;
	}
	
	&.straight {
		background-image: url('../../img/skins/default/form-signup-straight.png');
	}
	
	&.gay {
		background-image: url('../../img/skins/default/form-signup-gay.png');
	}
	
	&.trans,
	&.shemale {
		background-image: url('../../img/skins/default/form-signup-trans.png');
	}
	
	.language-switcher-row {
		width: 90%;
		z-index: 1;
	}
}

.x-overlay.with-premium-info-frame {
	&.straight {
		.x-body {
			background-image: url('../../img/skins/default/form-signup-straight-2.jpg');
		}
	}
	
	&.gay {
		.x-body {
			background-image: url('../../img/skins/default/form-signup-gay-2.jpg');
		}
	}
	
	&.trans,
	&.shemale {
		.x-body {
			background-image: url('../../img/skins/default/form-signup-trans-2.jpg');
		}
	}
	
	.x-body {
		background-position: right top;
		background-size: 65% auto;
		
		&:before,
		&:after {
			content: none;
		}
	}
}

.account-page.anonymous #page.exec-manage-login_create.with-premium-info-frame {
	
	background-position: right top;
	background-size: 65% auto;
	overflow: visible;
	//margin-left: max(80px, auto);
	//margin-right: max(80px, auto);
	padding-left: 40px;
	padding-right: 40px;
	
	&.straight {
		background-image: url('../../img/skins/default/form-signup-straight-2.jpg');
	}
	
	&.gay {
		background-image: url('../../img/skins/default/form-signup-gay-2.jpg');
	}
	
	&.trans,
	&.shemale {
		background-image: url('../../img/skins/default/form-signup-trans-2.jpg');
	}
	
	&:before,
	&:after {
		content: none;
	}
}

.account-page.anonymous #page.exec-manage-login_create.with-premium-info-frame,
.x-overlay.with-premium-info-frame {
	
	.login-create-section-title {
		font-size: 36px;
		margin: 4px 0 0;
	}
	
	#premium-info-frame {
		text-align: right;
		
		.btn-danger {
			margin: 0;
		}
	}
	
	#signin-popup-form {
		.btn-danger {
			border-radius: 21px;
			line-height: 20px;
		}
		
		.btn-default {
			background: none;
			border: 2px solid @red-color;
			border-radius: 21px;
			color: #fff;
			line-height: 16px;
			text-shadow: 1px 2px 0 #000;
		}
	}
	
	#signup-form-block,
	#signin-form-block {
		.form-buttons {
			text-align: left;
			
			.btn {
				margin: 0 0 10px;
				white-space: normal;
			}
			.btn-link {
				font-size: 14px;
				margin: 0 0 5px;
				text-align: left;
			}
		}
	}
	
	#signup-form_step2 {
		.form-buttons {
			padding-left: 15px;
			padding-right: 15px;
			width: calc(~"100% + 30px");
			
			.btn:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
	
	.signup2login-container {
		text-align: right;
	}
	
	#btn-login2create {
		display: block;
		width: 100%;
	}
	/*
	#btn-signup2next,
	#btn-login2create,
	#btn-signup2login {
		display: block;
	}*/
	
	#signin-form button[type="submit"] {
		&, & + .text-center {
			margin: 0 0 6px;
		}
	}
	
	#premium-info-frame .login-create-section-sub-title,
	#signin-popup-form fieldset legend {
		border-bottom: 0;
		font-size: 22px;
		font-weight: normal;
		line-height: 24px;
		margin: 0 0 20px;
	}
	
	#premium-info-frame .login-create-section-sub-title {
		.icf-ticket-red {
			color: @red-color;
			display: inline-block;
			font-size: 34px;
			line-height: 24px;
			vertical-align: top;
		}
	}
	
	.advantages {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 0 10px auto;
		max-width: 400px;
		
		.advantage {
			border: 2px solid #fff;
			box-shadow: 1px 1px 0 1px #000;
			border-radius: 10px;
			font-weight: bold;
			margin-bottom: 18px;
			padding: 12px 4px 4px;
			text-align: center;
			text-shadow: 1px 2px 0 #000;
			width: 48%;
			
			.icon-f {
				display: block;
				font-size: 40px;
				margin-bottom: 4px;
				width: 100%;
			}
		}
	}
}

.signin-popup-form,
.account-page.anonymous #page.exec-manage-login_create {
	&.es {
		#signin-popup-form {
			#signup-form_step2_marketing {
				h3 {
					.marketing-content {
						margin-top: 5px;
						font-size: 25px;
						
						span {
							margin-bottom: 10px;
							font-size: 76px;
						}
					}
				}
			}
		}
	}
	
	&.pt {
		#signin-popup-form {
			#signup-form_step2_marketing {
				h3 {
					.marketing-content {
						margin-top: 7px;
						font-size: 26px;
						
						span {
							margin-bottom: 10px;
							font-size: 75px;
						}
					}
				}
			}
		}
	}
}

#login-create-verified-msg {
	background: @brand-success;
	padding: 10px 20px;
	
	h1 {
		text-align: center;
		margin: 0 0 30px;
		
		.icon-f:first-child {
			float: none;
			margin-right: 0;
			vertical-align: bottom;
		}
	}
	
	h2 {
		text-align: center;
		margin: 0 0 20px;
	}
	
	.btn {
		width: 100%;
		text-transform: uppercase;
		white-space: normal;
		
		&.btn-default {
			color: @text-color;
		}
	}
	
	& + #signin-popup-form {
		display: none;
		margin-top: 40px;
	}
}
