
.mobile-only-show {
	display: block;
}

.mobile-only-show-inline {
	display: inline;
}

.mobile-only-show-inline-block {
	display: inline-block;
}

.mobile-only-show-list-item {
	display: list-item;
}

.mobile-only-hide {
	display: none;
}

.x-overlay {
	&.x-overlay-box {
		padding: 0 10px;
		
		&.favlist-overlay {
			padding: 10px;
		}
		
		.x-body {
			padding: 20px 15px 10px;
		}
		
		&.contextual-popup {
			.x-body {
				padding: 30px 15px 10px;
				
				.x-close {
					font-size: 20px;
				}
			}
		}
	}
}

.favlists {
	.favlists-actions {
		.favlists-actions-line-1 {
			justify-content: space-between;
		}
		
		label {
			.btn,
			.icon-f {
				font-size: 14px;
				width: 24px;
			}
		}
		
		.select-label {
			.form-control {
				max-width: calc(~"100% - 24px");
			}
		}
		
		.favlists-show-more-filters {
			padding: 0 10px 0 5px;
			
			.icf-sliders {
				font-size: 18px;
			}
		}
		
		
		.favlists-display-mode {
			.icf-columns {
				display: none;
			}
		}
	}
}

.favlist-elem-line {
	padding: 0 5px;
	
	& > * {
		font-size: 16px;
		//line-height: 38px;
	}
	
	.favlist-e-action {
		margin-left: 5px;
		
		.btn {
			font-size: 14px;
			//line-height: 26px;
			
			.icon-f {
				font-size: 16px;
			}
		}
		
		.action-menu {
			font-size: 16px;
			//height: 28px;
			//line-height: 28px;
			margin-left: 5px;
			//width: 28px;
		}
	}
	
	.favlist-e-visibility {
		font-size: 14px;
		width: 20px;
	}
	
	.favlist-e-title {
		font-size: 16px;
		max-width: calc(~"100% - 20px");
		//line-height: 24px;
		//padding: 2px 5px 2px 5px;
		
		.is-watch-later {
			font-size: 14px;
			//line-height: 24px;
		}
	}
	
	.favlist-e-tags {
		//margin: 2px 0 2px 6px;
		//line-height: 24px;
		
		& > *{
			font-size: 11px;
		}
	}
}

.favlist-create,
.favlist-edit {
	.btn-back {
		float: right;
		margin: 0 0 0 10px;
	}
	.favlist-picto {
		&, &.icf-clock {
			font-size: 48px;
			left: 10px;
			right: auto;
		}
	}
}

.signin-popup-form {
	&.pt,
	&.es {
		#signin-popup-form {
			#signup-form_step2_marketing {
				h3 {
					.marketing-content {
						margin-top: 0;
						font-size: 20px;
						span {
							margin-bottom: 0;
							font-size: 58px;
						}
					}
				}
			}
		}
	}
	&.es {
		#signin-popup-form {
			#signup-form_step2_marketing {
				h3 {
					.marketing-content {
						span {
							font-size: 62px;
						}
					}
				}
			}
		}
	}
}

#signin-popup-form {
	#signup-form_step2_marketing {
		background: url('../../img/skins/default/form-signup-mob.png') no-repeat right center / auto 100%;
		margin: 0 0 20px 0;
		h3 {
			.marketing-content {
				display: block;
				font-size: 18px;
				span {
					font-size: 90px;
				}
			}
			.logo {
				display: block;
				margin: 0;
				svg {
					display: inline-block;
					vertical-align: top;
					margin-top: 10px;
					line-height: 1;
				}
				.icf-ticket-red {
					display: inline-block;
					font-size: 50px;
					line-height: 1
				}
			}
		}
		.marketing-mentions {
			max-width: 260px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.nav-elem {
	.ordered-label-list ul li,
	#nav-language-switcher,
	#language-switcher-list li {
		.btn,
		&.btn {
			padding-left: 6px;
			
			.right {
				padding-right: 0;
			}
		}
		
		&.section-title {
			padding-top: 8px;
			
			&,
			.icon-f {
				font-size: 11px;
				height: auto;
			}
		}
	}
}

#list-page li {
	width: 100%;
}

#tags li,
.tags-list li {
	width: 50%;
}

#page .resps-tabs,
#home-tabs {
	.stripe {
		font-size: 13px;
		padding-top: 4px;
		height: 27px;
	}
}

.home #home-tabs {
	.tab-buttons {
		float: left;
	}
}

#main .simple-dropdown.align-left {
	float: none;
	margin: 5px 0 0 0;
	
	& ~ .page-title.channel {
		margin-top: 5px;
	}
}

#warning-survey {
	font-size: 15px;
	padding: 5px;
	line-height: 1.3;
}

.mozaique {
	&.cust-nb-cols {
		.thumb-block {
			&,
			.nb-thumbs-cols-xs-1 & {
				.thumb-block-size-mixin(1);
				padding-left: @body-out-padding*2;
				padding-right: @body-out-padding*2;
				
				&:not(.premium-search-on-free) {
					.thumb-under-n-lines();
					
					.thumb-under {
						padding-top: 2px;
						
						p {
							font-size: 15px;
							
							&.title,
							&:first-child {
								a {
									line-height: 22px;
								}
							}
						}
					}
				}
				
				&.with-thumb-over + .with-thumb-over-space,
				&.with-thumb-over-space + .with-thumb-over-space {
					padding-top: 5px;
				}
				
				&.premium-search-on-free {
					padding-left: @body-out-padding;
					padding-right: @body-out-padding;
					
					.thumb-under {
						left: @body-out-padding;
						width: calc(~"100% - (@{body-out-padding} * 2)");
					}
				}
				
				.thumb-over {
					height: auto;
				}
			}
			
			.nb-thumbs-cols-xs-2 &,
			.nb-thumbs-cols-xs-3 & {
				padding-left: @video-thumb-padding-left;
				padding-right: @video-thumb-padding-right;
				.thumb-under-2-lines();
				
				&.with-thumb-over-space {
					padding-top: 10px;
				}
				
				.thumb-over {
					height: 28px;
				}
				
				.thumb-under {
					padding-top: 0;
				}
				
				.sprfluous {
					display: none;
					
					& + .icf-ticket-red {
						margin-left: 4px;
					}
				}
				
				&.premium-search-on-free {
					padding-left: @video-thumb-padding-left;
					padding-right: @video-thumb-padding-right;
					
					.thumb-under {
						left: @video-thumb-padding-left;
						width: calc(~"100% - @{video-thumb-padding-left} - @{video-thumb-padding-right}");
					}
				}
			}
			
			.nb-thumbs-cols-xs-2 & {
				.thumb-block-size-mixin(2);
				
				.thumb-under {
					p {
						font-size: 14px;
						
						&.title,
						&:first-child {
							a {
								line-height: 18px;
							}
						}
					}
				}
			}
			
			.nb-thumbs-cols-xs-3 & {
				.thumb-block-size-mixin(3);
				
				.thumb-under {
					p {
						font-size: 13px;
						
						&.title,
						&:first-child {
							a {
								line-height: 18px;
							}
						}
					}
				}
			}
			
			.max-line-1 & {
				& {
					&:nth-child(n+4) {
						display: none;
					}
				}
				
				.nb-thumbs-cols-xs-2 & {
					&:nth-child(4) {
						display: block;
					}
				}
			}
			
			.max-line-2 & {
				&:nth-child(n+7) {
					display: none;
				}
			}
			
			html:not(.nb-thumbs-cols-xs-2):not(.nb-thumbs-cols-xs-3) & {
				&.thumb-block-big-button {
					padding: 0;
					
					.thumb-inside {
						padding-bottom: 0;
						height: auto;
					}
					
					.big-button-container {
						position: relative;
					}
					
					a {
						margin: 5px 0;
						padding: 5px;
						text-align: center;
					}
				}
			}
			
			.nb-thumbs-cols-xs-100 & {
				.nb-thumbs-cols-100(1/2.2, 14, 16);
			}
		}
		
		#tabAboutMe &.channels-list {
			.thumb-block {
				&,
				.nb-thumbs-cols-xs-1 & {
					.thumb-under {
						height: auto;
						max-height: none;
						min-height: 0;
						padding: 2px 0 10px;
						
						p {
							height: auto;
							
							&.title,
							&:first-child {
								display: block;
							}
							
							&,
							a {
								line-height: 18px;
							}
						}
					}
				}
				
				.nb-thumbs-cols-xs-2 &,
				.nb-thumbs-cols-xs-3 & {
					.thumb-under {
						height: 36px;
						max-height: 36px;
						min-height: 36px;
						padding: 0 0 4px;
						
						p {
							height: 32px;
							
							&.title,
							&:first-child {
								.line-clamp(2);
							}
							
							&,
							a {
								line-height: 16px;
							}
						}
					}
				}
			}
		}
	}
}

#related-videos,
#profile-tabs .activity-event.premium-preview,
#related-playlists {
	padding: 0;

	.mozaique {
		.thumb-block {
			padding-left: @video-thumb-padding-left;
			padding-right: @video-thumb-padding-right;
			.thumb-block-size-mixin(2);
			.thumb-under-2-lines();
			
			.thumb-under {
				padding-top: 0;
				
				p {
					font-size: 14px;
					
					&.title,
					&:first-child {
						a {
							line-height: 18px;
						}
					}
				}
			}
			
			.sprfluous {
				display: none;
				
				& + .icf-ticket-red {
					margin-left: 4px;
				}
			}
		}
	}
	.thumb-block {
		&.after-12,
		&.after-15 {
			display: none;
		}
	}
	&.with-ad {
		&.with-footer-ad {
			.thumb-block:not(.thumb-nat-exo-ad) {
				.thumb-under {
					min-height: 0;
				}
			}
			.thumb-block {
				&.with-add-nth-child-2-plus-1{
					clear: left;
				}
				&.video-ad {
					width: 100%;
					
					.thumb-inside {
						padding-left: 0;
						padding-right: 0;
					}
				}
				
				&.video-ad-support-mobile,
				&.video-ad-support-tablet {
					.thumb-inside {
						.remove-ads {
							display: none;
						}
						& > a {
							display: block;
						}
					}
				}
				
				//.video-ad-support-mobile-auto-height();
			}
		}
	}
}

#related-playlists {
	.mozaique {
		.thumb-block {
			.thumb-under-1-line();

			.thumb-under {
				min-height: 19px;
			}
		}
	}
}

.users-videos .eventslist {
	.mozaique {
		&.cust-nb-cols {
			html:not(.nb-thumbs-cols-xs-2):not(.nb-thumbs-cols-xs-3) & {
				.show-more-vids,
				.show-less-vids,
				.show-more-less-vids {
					a {
						border: 1px solid @btn-default-border;
						display: block;
						padding: 5px;
						margin: 5px 0;
						text-align: center;
					}
					
					.less {
						display: block;
					}
					
					.no-more-to-load {
						display: none;
					}
				}
				
				.show-less {
					.less {
						display: none;
					}
					
					.no-more-to-load {
						display: block;
					}
				}
			}
		}
	}
	@media @media-ratio-vertical {
		.show-less {
			.thumb-block {
				&.thumb-block-10-more {
					display: none;
				}
			}
			&.has-less-21 {
				.show-more-vids,
				.show-more-less-vids {
					.more {
						display: block;
					}
				}
			}
		}
	}
}

.mozaique {
	&.thumbs-small{
		.thumb-block {
			.any-col-config({
				.thumb-block-size-mixin(3);
			});
		}
	}
	&.thumbs-small .thumb-block {
		width: 33.3333%;
		clear: none;
		padding-left: 2px;
		padding-right: 2px;
	}
}


#ad-footer2 {
	display: block;
}

.tab-form {
	.create-account,
	.btn {
		font-size: 13px;
	}
}

#page {
	&.search-page {
		#search-channel-banner {
			margin-top: 37px;
		}
	}
}

#search-channel-banner {
	a {
		span {
			padding: 0 2px;
			&,
			&.channelname {
				font-size: 10px;
			}
			&.nbvids {
				top: 19px;
			}
		}
	}
}

#profile-search-form {
	&.display-compact {
		.form-group {
			display: block;
			clear: both;
			width: auto;
			margin-left: -15px;
			margin-right: -15px;
		}
	}
}

#profile-title {
	h2 {
		.sex-age-profs {
			vertical-align: text-bottom;
		}
		.website {
			position: static;
			vertical-align: text-bottom;
		}
	}
	
	&.has-banner {
		.embed-responsive {
			&.with-website-link {
				//padding-bottom: calc(~"15.625% + 22px");
				margin-bottom: 26px; //22px link + 4px bottom margin
				overflow: visible;
				
				.website {
					background: #000;
					border-radius: 0;
					bottom: auto;
					left: 0;
					right: 0;
					text-align: right;
					top: 100%;
					
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
	
	.embed-responsive {
		&.banner-slider {
			.banner-slide:not(.banner-personal) {
				font-size: 16px;
				height: calc(14.59vw);
				min-height: 62px;
				
				&.ticket-red {
					.ticket-in {
						font-size: 16px;
					}
				}
			}
		}
	}
}

#profile-tabs {
	.tab-buttons {
		height: 36px;
		padding: 2px 0 0 0;
		
		li {
			margin-right: 3px;
			.btn {
				&,
				&.desktop-active,
				&.mobile-active {
					padding-left: 8px;
					padding-right: 8px;
				}
			}
			#tab-posts .navbadge {
				margin-left: 0;
			}
		}
	}
	
	.xv-slim-tabs {
		.xv-slim-tab-btn {
			.icf-feed {
				font-size: 20px;
				line-height: 16px;
			}
		}
	}
	
	.filters {
		.content-filters {
			& > span {
				&.content-filter-k-lock-unlock,
				&.content-filter-k-locked,
				&.content-filter-k-unlocked {;
					.icon-f {
						display: block;
					}
				}
			}
			.icon-f {
				display: none;
			}
		}
	}
}

#tabAboutMe {
	.rating-box {
		.rating-bar-block {
			min-width: 40px;
		}
	}
}

.activity-event {
	.act-achievement {
		font-size: 38px;
		line-height: 1.1;
		margin-top: 10px;
	}
}

.favorite-page {
	#profile-title {
		h2 {
			float: none;
			margin-top: 1px;
		}
		.fav-votes {
			text-align: left;
			margin: 6px 0;
			.rating-box-light {
				margin-left: 0;
			}
		}
		&.no-tags {
			h2 {
				margin-top: 0;
			}
			.fav-votes {
				height: 27px;
			}
		}
	}
}

.copy-link:not(.force-one-line) {
	& > *:first-child {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	
	& > *:last-child {
		border-left-width: 1px;
		border-top-width: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
	
	.copy-btn,
	input {
		width: 100%;
	}
}



.voters-list {
	.voters {
		li {
			width: 33.333%;
		}
	}
}


#video-premium-alert {
	p {
		.btn-primary {
			font-size: 20px;
		}
	}
}

body:not(.nb-thumbs-cols-xs-2):not(.nb-thumbs-cols-xs-3) {
	#page .post-blocks.mozaique {
		.thumb-block {
			.p-date {
				height: auto;
				line-height: 30px;
				margin-top: 30px;
				
				.icon-f {
					line-height: 30px;
				}
				
				&.empty {
					margin-top: 6px;
				}
			}
		}
	}
}

@post-goto-nextprev-mob-w: 30px;
.tabFanClub-post-overlay {
	&.x-overlay.x-overlay-box {
		padding-left: @post-goto-nextprev-mob-w;
		padding-right: @post-goto-nextprev-mob-w;
		
		.x-body {
			border-radius: 0;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	
	.goto-prev,
	.goto-next {
		width: @post-goto-nextprev-mob-w;
		
		.icon-f {
			font-size: @post-goto-nextprev-mob-w - 4;
		}
	}
}

.search-filters.simple-dropdown {
	.niv1.with-niv2 {
		width: calc(~"50% - 4px");
		
		.show-niv2 {
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
		}
	}
	&.search-filters-1,
	&.search-filters-3,
	&.search-filters-5,
	&.search-filters-7 {
		.niv1.with-niv2 {
			&.filter-sort {
				width: calc(~"100% - 4px");
			}
		}
	}
}