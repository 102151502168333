
#page {
	.suggest-list.mozaique {
		clear: left;
		min-height: 80px;
		margin-bottom: 0;
		overflow: visible;
		position: relative;
		white-space: nowrap;
		
		&.suggest-list-2-rows {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			max-height: 500px;
		}
		
		
		.thumb-block-premium-feed-account {
			display: inline-block;
			float: none;
			white-space: normal;
			
			.thumb {
				a {
					border: 0;
					height: 100%;
					
					.profile-hover {
						display: none;
					}
				}
				
				.is-desktop & a {
					.profile-hover {
						align-items: center;
						background: rgba(0, 0, 0, 0.5);
						bottom: 0;
						color: rgba(255, 255, 255, .9);
						display: block;
						display: flex;
						font-size: 20px;
						justify-content: center;
						left: 0;
						line-height: 22px;
						opacity: 0;
						position: absolute;
						right: 0;
						text-align: center;
						text-shadow: 0 0 5px rgba(0, 0, 0, .5);
						top: 0;
						transition: all .4s;
						z-index: @premium-feed-zindex-profile-hover;
						
						.label {
							display: inline-block;
							padding: 0 10px 0 70px;
							position: relative;
							top: 10px;
							transition: all .4s;
							max-width: 210px;
						}
						
						.icon-f {
							font-size: 50px;
							left: 10px;
							margin-top: -25px;
							position: absolute;
							top: 50%;
						}
					}
					
					&:hover {
						.profile-hover {
							opacity: 1;
							
							.label {
								top: 0;
							}
						}
					}
				}
			}
			
			.profile-name {
				background-image: linear-gradient(0deg, @premium-feed-account-profile-name-bg 0%, transparent 100%);
				bottom: 0;
				color: @premium-feed-account-profile-name-color;
				font-size: 16px;
				font-weight: bold;
				left: 0;
				line-height: 20px;
				overflow: hidden;
				padding-top: 20px;
				position: absolute;
				text-align: center;
				text-overflow: ellipsis;
				.text-border-1px(fadeout(@premium-feed-account-profile-name-bg, 50%));
				white-space: nowrap;
				width: 100%;
				z-index: @premium-feed-zindex-profile-name;
			}
			
			.profile-counts {
				background: @premium-feed-account-profile-count-bg;
				border-radius: 3px;
				color: @premium-feed-account-profile-count-color;
				font-size: 12px;
				font-weight: bold;
				left: 2px;
				line-height: 12px;
				padding: 2px 4px;
				position: absolute;
				top: 2px;
				
				.icon-f {
					float: left;
					line-height: 12px;
					padding-right: 4px;
				}
			}
			
			.thumb-under {
				.profile-pic {
					height: 62px;
					width: 62px;
					overflow: hidden;
					position: relative;
					
					img {
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
					}
				}
			}
			
			&.thumb-block-premium-feed-other-suggest {
				.big-button-container {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					height: 100%;
					width: 100%;
				}
				
				a {
					align-items: center;
					background: @premium-feed-account-cat-btn-bg;
					border: 1px solid @premium-feed-account-cat-btn-border;
					border-radius: 3px;
					color: @premium-feed-account-cat-btn-color;
					display: flex;
					font-size: 18px;
					font-weight: bold;
					height: 100%;
					line-height: 26px;
					justify-content: center;
					padding: 0 10px;
					text-align: center;
					text-decoration: none;
					
					.is-desktop &:hover {
						background: darken(@premium-feed-account-cat-btn-bg, 10%);
						text-decoration: none;
					}
				}
				
				.cat {
					display: block;
					font-size: 0.8em;
					margin-top: -10px;
					opacity: 0.7;
				}
				
				.thumbs {
					display: inline-block;
					font-size: 15px;
					font-weight: normal;
				}
				
				.cover-loader {
					padding: 0;
					
					.icon-f {
						font-size: 80px;
						left: 50%;
						margin: -40px;
						position: absolute;
						top: 50%;
					}
				}
			}
			
			&.nav-hide {
				display: none;
			}
		}
	}
	.xscroll-h-added {
		.suggest-list.mozaique {
			.thumb-block-premium-feed-account {
				padding-bottom: 0;
			}
		}
	}
	
	.suggest-list-header {
		.btn-clear {
			display: inline-block;
			height: 32px;
			line-height: 32px;
			margin: 0 5px;
			padding: 0 5px;
			
			span {
				padding: 0 2px;
				vertical-align: middle;
			}
		}
		
		.suggest-list-reload-left,
		.suggest-list-reload-right {
			
			&:hover {
				box-shadow: 0 0 1px 1px inset fadeOut(@text-color, 80%);
			}
			
			&.nav-hide {
				visibility: hidden;
			}
		}
		
		.suggest-list-visibility-btn {
			&.show-less {
				margin-bottom: 0;
				margin-left: 5px;
				height: auto;
			}
			
			&:hover {
				span {
					text-decoration: underline;
				}
			}
		}
	}
}

.suggest-list-block {
	
	@media screen and (min-width: @screen-sm-min) {
		.flex-desktop-row {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}
		
		.flex-desktop-column {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
		}
	}
	@media screen and (max-width: @screen-xs-max) {
		.flex-mobile-row {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
		}
		
		.flex-mobile-column {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
		}
	}
	
	.suggest-list-header {
		clear: both;
		font-size: 18px;
		font-weight: normal;
		justify-content: flex-end;
		line-height: 20px;
		margin: 0 0 7px;
		overflow: hidden;
		padding: 0 10px;
		
		.suggest-list-header-title { //flex-desktop-row
			/*align-items: center;
			display: flex;
			justify-content: space-between;
			width: 100%;*/
			flex-grow: 999;
		}
		
		.added-removed {
			align-items: center;
			display: flex;
			font-size: 14px;
			font-weight: normal;
			margin-left: auto;
			padding-left: 8px;
			
			a {
				display: inline-block;
				text-decoration: none;
				
				&:hover {
					text-decoration: underline;
				}
			}
		}
		
		.sep {
			padding: 0 6px;
		}
		
		.suggest-list-toggle {
			cursor: pointer;
			font-size: 12px;
			line-height: 18px;
			padding: 1px 5px;
		}
		
		/*.added-removed-cats {
			clear: right;
			cursor: pointer;
			
			&:hover {
				text-decoration: underline;
			}
		}*/
	}
	
	&.suggest-visibility-hidden {
		.suggest-list-header {
			margin: 0;
		}
	}
	&.suggest-visibility-full-hidden {
		display: none;
	}
	
	@media screen and (max-width: @screen-xs-max) {
		.suggest-list-header {
			.suggest-list-header-title {
				.title {
					margin-bottom: 5px;
					
					.suggest-list-toggle {
						margin-bottom: 8px;
					}
					
					.sep {
						display: block;
					}
				}
				
				.added-removed {
					margin-left: 0;
					padding-left: 0;
				}
			}
		}
	}
	
	
	@media screen and (max-width: @screen-xs-max) {
		.premium_feed #content & {
			.suggest-list-header {
				margin: 20px 0 15px;
			}
		}
		
		#user-videos-header:has(> .xscroll-h-added) ~ #user-videos-page-videos #page-videos-content & {
			.suggest-list-header {
				margin: 0 0 15px 0;
			}
		}
	}
	
	#user-videos-header:has(> .xscroll-h-added) ~ #user-videos-page-videos #page-videos-content & {
		.suggest-list-header {
			margin: 0 0 15px 0;
		}
	}
	
	.premium_feed #content & {
		.suggest-list-header {
			margin: 8px 0 6px;
		}
	}
}



.suggest-list-toggle-pop.x-popup {
	.x-popup-content {
		padding: 10px 20px;
		ul,
		ul li {
			margin: 0;
			padding: 0;
		}
	}
	
	//strong,
	a {
		display: block;
		font-size: 14px;
		line-height: 22px;
		padding: 5px 15px;
		text-decoration: none;
		
		&:hover {
			.name {
				text-decoration: underline;
			}
		}
	}
	
	.icon-f {
		display: inline-block;
		padding-right: 4px;
		width: 22px;
		text-align: center;
		transform: scale(1.5);
	}
}


.suggest-list-error {
	color: @gray-light;
	padding: 10px 0;
	text-align: center;
}

.thumb-block-premium-feed-account {
	border-bottom: 0;
	font-size: 12px;
	padding: 10px;
	
	/*.thumb-inside,
	.thumb a {
		overflow: visible;
	}*//* commented to hide an incorrect ratio image from showing out of the box */
	
	.videopv {
		max-height: 100%;
		overflow: hidden;
	}
	
	&.is-membership .thumb a {
		&:after,
		&:before {
			bottom: 16%;
			content: '';
			left: 14%;
			position: absolute;
			top: 16%;
			transition: 0.2s all;
			right: 14%;
			z-index: @premium-feed-zindex-membership-focus;
		}
		.is-desktop &:hover {
			&:after,
			&:before {
				bottom: 8%;
				left: 6%;
				top: 8%;
				right: 6%;
			}
		}
		&:after {
			border-left: 2px solid transparent;
			border-right: 2px solid transparent;
			border-image: linear-gradient(180deg, #fff 0%, #fff 30%, transparent 30.01%, transparent 69.99%,  #fff 70%, #fff 100%) 10;
		}
		&:before {
			border-bottom: 2px solid transparent;
			border-top: 2px solid transparent;
			border-image: linear-gradient(90deg, #fff 0%, #fff 30%, transparent 30.01%, transparent 69.99%,  #fff 70%, #fff 100%) 10;
		}
	}

	&.is-model-video {
		.flag-small {
			position: relative;
			left: 0;
			top: 1px;
		}

		.account-actions button {
			width: 100%;

			&.btn-primary {
				.icon-f {
					color: white;
				}
			}

			.label {
				font-size: 90%;
				padding-left: 2px;
				top: initial;
			}

			&.add-model {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: nowrap;
				gap: 2px;
				height: 36px;
				margin: 0;
				padding-bottom: 0;
				padding-top: 0;
				width: 100%;

				.label {
					line-height: 14px;
					padding: 0.2em 0 0.2em 2px;
					white-space: normal;
				}
			}
		}

		.thumb-inside {
			@profilePicBigVisibilityZIndexUnderTb: 1;
			@profilePicBigVisibilityZIndexTb: 2;
			@profilePicBigVisibilityZIndexOverTb: 3;

			.thumb {
				z-index: @profilePicBigVisibilityZIndexTb;
				overflow: hidden;

				a {
					height: 100%;

					img {
						padding: 0;
					}
				}
			}

			.is-desktop & {
				.profile-hover {
					width: 100%;
					opacity: 0;
					transition: opacity .2s ease;

					.label {
						white-space: break-spaces;
						width: 90%;
					}
				}

				&:hover {
					.profile-hover {
						opacity: 100%;
					}
				}
			}

			.profile-name {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				//bottom: -1px;

				.name-part {
					.line-clamp(1);

					.highlighted {
						padding: 0 1px;
						background-color: rgba(207, 35, 0, 1);
					}

					margin-right: 5px;
				}

				.rank-part {
					margin-left: 5px;
					margin-right: 5px;
					font-size: 85%;
					opacity: 0.9;
					font-style: italic;
				}
			}

			.profile-hover {
				display: flex;
				align-items: center;
				justify-content: center;

				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				.label {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 3px;

					text-shadow: 0 0 4px black;
					font-size: 100%;
				}
			}

			.mid-tags,
			.top-tags {
				display: flex;
				justify-content: space-between;
				gap: 2px;

				position: absolute;
				top: 0;
				padding: 3px;
				width: 100%;
				font-size: 90%;

				.left,
				.right {
					display: flex;
					gap: 3px;

					background-color: rgba(0, 0, 0, .5);
					color: white;
					font-weight: bold;

					padding: 2px;
					border-radius: 4px;
				}

				.left {
					padding: 3px;

					.tag-part {
						display: flex;
						align-items: flex-end;
					}

				}

				.right {
					overflow: hidden;
					height: 100%;

					.tag-part {
						white-space: nowrap;

						&:last-child {
							.line-clamp(1);
						}
					}
				}

				.status-tags {
					display: flex;
					gap: 3px;
					color: white;
					font-weight: bold;
					.line-clamp(1);

					padding: 2px;
					border-radius: 4px;

					.default {
						background-color: rgba(0, 0, 0, .5);
						border-radius: 4px;
						color: white;
					}

					.info {
						background-color: @brand-info;
						border-radius: 4px;
						color: black;
					}

					.success {
						background-color: @brand-success;
						border-radius: 4px;
					}

					.danger {
						background-color: @theme-color;
						border-radius: 4px;
					}

					.tag-big {
						font-size: 15px;
						padding: 8px;
					}
				}
			}

			.mid-tags {
				top: 45px;
			}
			
			
			@keyframes profilePicBigHide {
				0% {
					opacity: 1;
					z-index: @profilePicBigVisibilityZIndexOverTb;
				}
				99% {
					opacity: 0;
					z-index: @profilePicBigVisibilityZIndexOverTb;
				}
				100% {
					opacity: 0;
					z-index: @profilePicBigVisibilityZIndexUnderTb;
				}
			}
			.profile-pic-big {
				position: absolute;
				z-index: @profilePicBigVisibilityZIndexUnderTb;

				left: -3%;
				bottom: 3px;
				height: 95%;

				border: 1px solid black;

				opacity: 0;
				transition: opacity 200ms ease;
				
				&.is-visible {
					opacity: 1;
					z-index: @profilePicBigVisibilityZIndexOverTb;
				}
				&.is-hidden {
					animation: profilePicBigHide 200ms ease;
				}
			}
		}

		.thumb-under {
			min-height: 0;
			
			.profile-pic{
				float: left;
				height: 36px;
				border: 1px solid @thumb-block-thumb-border;
				margin-right: 2px;
			}

			.btn {
				font-size: 15px
			}

			.btn-default {
				.label {
					color: black;
				}
			}
		}

		.thumb-under.disabled {
			display: none;
		}
	}
	
	.profile-name {
		background-image: linear-gradient(0deg, @premium-feed-account-profile-name-bg 0%, transparent 100%);
		bottom: 0;
		color: @premium-feed-account-profile-name-color;
		font-size: 16px;
		font-weight: bold;
		left: 0;
		line-height: 20px;
		overflow: hidden;
		padding-top: 20px;
		position: absolute;
		text-align: center;
		text-overflow: ellipsis;
		.text-border-1px(fadeout(@premium-feed-account-profile-name-bg, 50%));
		white-space: nowrap;
		width: 100%;
	}

	.ticket-red {
		position: absolute;
		left: -4px;
		top: -4px;
		transform: rotate(-2deg);
	}

	.profile-counts {
			background: @premium-feed-account-profile-count-bg;
			border-radius: 3px;
			color: @premium-feed-account-profile-count-color;
			font-size: 12px;
			font-weight: bold;
			left: 2px;
			line-height: 12px;
			padding: 2px 4px;
			position: absolute;
			top: 2px;

			.icon-f {
				float: left;
				line-height: 12px;
				padding-right: 4px;
			}
		}
}
