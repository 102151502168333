#popup-opening-message {
	position: fixed;
	right: 10px;
	bottom: 10px;
	width: 300px;
	max-width: 96%;
	text-align: center;
	font-size: 15px;
	line-height: 22px;
	box-shadow: 0 0 20px black;
	z-index: 8000;
}

#popup-opening-close {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
}

#popup-opening-content {
	position: relative;
	padding : 20px 30px;
	text-align: center;
	line-height: 1.5;
	overflow: hidden;
	z-index: 0;
	
	#popup-opening-desc {
		position: relative;
		z-index: 1;
	}
	
	p {
		margin: 0;
	}
	
	strong {
		font-weight: bold;
	}
	
	.popup-opening-title {
		font-size: 18px;
	}
	
	.popup-opening-link {
		margin: 20px 0;
		a {
			display: inline-block;
			padding: 12px 12px 10px 12px;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 16px;
			font-weight: bold;
			line-height: 1;
		}
	}
	
	.popup-opening-infos {
		font-size: 12px;
	}
	
	.icf-device-mobile {
		position: absolute;
		bottom: -5px;
		right: -80px;
		font-size: 170px;
		z-index: 0;
	}
	
}