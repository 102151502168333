@import '../modules/btn_subscribe.less';
@import './subscriptions.less';
@import './channel_preview.less';
@import './no_subs.less';

@global-feed-tablet-min: 600px;
// @global-feed-desktop-min: 1200px;
@global-feed-desktop-min: @global-feed-tablet-min; // Tablet mode disabled

@global-feed-mobile-max: @global-feed-tablet-min - 1px;
@global-feed-tablet-max: @global-feed-desktop-min - 1px;

@cols-2-min-width: 1600px;

#global-feed-video-filters {
	&.disabled {
		pointer-events: none;
		cursor: not-allowed;
		opacity: 0.7;
	}
}

.global-feed-filters {
	.uls {
		a {
			font-size: 100%;
			display: flex!important;
			align-items: center;

			span {
				margin-right: 9px;
				margin-top: 7px;
				margin-bottom: 7px;
			}
		}
	}
}

body.with-global-feed {
	// Overlay box global fix to avoid losing scroll progress
	.x-overlay {
		position: fixed;
	}

	// Don't impact the chat window
	#chat-window .overlay-container .x-overlay {
		position: absolute;
	}

	// Increase size of lightgallery nav buttons
	.lg-actions {
		[type="button"] {
			&.lg-prev,
			&.lg-next {
				font-size: 30px;
				padding: 30px;
			}
		}
	}
}

body.with-global-feed {

#main {
	padding-top: 5px;
}

.global-switch-page-dropdown {
	z-index: 10;
	width: 100%;

	.niv1 .show-niv2 {
		margin-bottom: 0;
	}
}

#page-videos-content {
	position: relative;
}

.simple-dropdown {
	clear: both;
	font-size: 14px;
	padding-top: 2px;

	display: inline-block;

	.niv1 {
		padding: 0 3px 3px 0;
	}

	.show-niv2 {
		background: @premium-feed-filter-bg;
		color: @premium-feed-filter-color;
		border-radius: 3px;
		cursor: pointer;
		font-size: 14px;
		line-height: 22px;
		padding-left: 10px;
		padding-right: 5px;

		&.is-reset {
			background: transparent;
			font-size: 12px;
			padding-left: 10px;
			padding-right: 10px;
			text-align: center;

			.label {
				display: inline-block;
			}

			.icf-close-thin {
				font-size: 10px;
			}
		}

		.label {
			span {
				float: left;
				line-height: 22px;
			}

			.slash {
				padding: 0 5px;
			}

			.icon-f {
				padding-right: 5px;

				& + .slash {
					padding-left: 0;
				}
			}
		}

		.icf-caret-down {
			float: right;
			line-height: 22px;
			margin: 0 0 0 3px;
			padding: 0;
		}

		.icf-plus-square,
		.icf-minus-square {
			&.count-0 {
				opacity: 0.5;
			}
		}
	}
}

#global-feed-content {
	margin-bottom: 15px;
	position: relative;
	min-height: 100px;

	display: flex;
    flex-wrap: wrap;

	justify-content: space-between;

	.post-block {
		width: 100%;
	}

	@media (min-width: @cols-2-min-width) {
		&.cols-2 {
			.post-block {
				width: 49.5%;
			}
		}
	}

	&.no-videos {
		margin-bottom: 0;
	}

	&.loading {
		min-height: 100px;
	}

	&.loading-feed {
		padding-bottom: 80px;
	}

	.empty,
	& ~ .empty {
		font-size: 20px;
		margin: 0;
		padding: 20px 0 10px;
		text-align: center;

		span {
			font-style: italic;
		}
	}

	.empty {
		padding: 0 0 10px;
		font-size: 16px;
	}
}

.no-more-posts {
	position: absolute;
    bottom: 0;
	width: 100%;
	margin-top: 25px;
	font-size: 130%;
	font-weight: bold;
	text-align: center;
}

/* ==== FILTERS ==== */

.feed-main-message {
	width: fit-content;
	margin: auto;
	.feed-main-message-text {
		font-weight: bold;
		font-size: 150%;
	}
	.feed-main-message-btn {
		width: fit-content;
		margin: auto;
	}
}

.cover-interal-error {
	margin-top: 25px;
	display: flex;
	align-items: center;
	flex-direction: column;

	.error-retry-text,
	.error-retry-btn {
		height: fit-content;
	}
}

.no-access,
span[data-filter=display_showfree] {
	.free,
	.icf-ticket-red {
		&:not(.active) {
			opacity: .5;
		}
	}

	.sep {
		padding: 0px 3px 0px;
	}

	.icf-ticket-red {
		font-size: 215%;
		color: @red-color;
	}

	.icf-white-fill::after {
		right: 26%;
	}
}

.p-banner-new-uploads,
.p-banner-new-associated,
.p-banner-recently-watched-assoc,
.p-banner-recently-watched {
	.icf-ticket-red {
		font-size: 215%;
		color: @red-color;
		vertical-align: middle;
		text-shadow: none;
	}
	.thumb-under {
		.date-diff,
		.sprfluous {
			display: none;
		}
	}
}

.no-access {

	font-size: 150%;
	text-align: center;

}

.no-access,
.date, .p-frame-head, .uploader {

	.icf-ticket-red {
		vertical-align: middle;
		color: @red-color!important;
		font-size: 250%;
	}
	.icf-white-fill::after {
		background: #fff!important;
		right: 11%;
	}
}

.date, .p-frame-head, .uploader {
	font-size: 115%;
	white-space: nowrap;
	text-wrap: balance;

	display: flex;
    flex-wrap: wrap;
    gap: 3px;
    align-items: center;

	.icf-ticket-red {
		line-height: 0.7;
		margin-bottom: 1px;
		display: inline-block;
	}

	.icf-image,
	.icf-video-camera {
		vertical-align: text-top;
		font-size: 112%;
	}
	.icf-video-camera {
		padding-left: 4px;
	}
}

span[data-filter=display_content] {
	&.active {
		color: red;
	}
}

/* ==== POST ==== */

@post-frame-bg-color: lighten(@gray-lighter, 2%);
.p-red {
	.p-glass-effect {
		.p-text {
			// background: linear-gradient(104deg,
			// 	@red-color 0%,
			// 	fadeout(@red-color, 50%) 20%,
			// 	fadeout(@red-color,100%) 100%
			// )
			background: linear-gradient(135deg,
				@post-comment-s1-bg-left,
				@post-comment-s1-bg-right
			);
			color: white!important;
			.p-posttitle {
				color: white;
				a {
					color: white;
				}
			}
		}
	}
}

// Video titles on thumb
#global-feed-content > .post-block {
	&.p-playlist,
	&.p-banner-new-uploads,
	&.p-banner-new-associated,
	&.p-banner-recently-watched,
	&.p-banner-recently-watched-assoc {
		.p-gallery .p-pics .thumb-under {
			width: 95%;
			display: flex;
			position: absolute;
			bottom: 6px;
			left: 50%;
			transform: translateX(-50%);
			flex-direction: column;
			min-height: 0;

			.title a {
				color: white;
				font-weight: bold;
				text-shadow: 0 0 3px black;

				.line-clamp(1);

				// @media (max-width: @global-feed-tablet-max) {
				@media (max-width: @global-feed-mobile-max) {
					font-size: 95%;
				}
			}

			.metadata {
				display: none;

				.uploader-link,
				.sprfluous,
				.date-diff {
					text-shadow: 0 0 2px black;
					color: #fbfbfb;
				}

				.duration {
					display: none;
				}
			}
		}
	}
}

// @media (min-width: @global-feed-tablet-min) {
@media (min-width: @global-feed-desktop-min) {
	.p-red {
		.p-user {
			color: white!important;
		}
	}
}
.p-head,
.p-frame-head {
	.uploader .icf-ticket-red {
		display: none;
	}
}

.p-centerframe {
	order: 99;
	&.first {
		order: 0;
	}
}

.red-cache::after {
	content: "";
    width: 90%;
    aspect-ratio: 1.7;
    margin-left: 5%;
    position: absolute;
    background-image: url(https://static-ss.red-cdn.com/v3/img/skins/default/ticket-red.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
	pointer-events: none;
}

.p-locked::after {
	content: "";
    position: absolute;
	font-family: 'iconfont';
	content: @icf-lock;
	font-size: 300%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	pointer-events: none;
	text-shadow: 0 0 5px #000;
}

@keyframes icf_spin_anim {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.loading-posts {
	.post-block {
		transition: 0.1s all;
		opacity: 0.5;
	}
}

.loading-feed::after {
	content: '@{icf-spinner}';
	display: block;
	animation: icf_spin_anim 1s infinite steps(8);

	.font-family-iconfont();

	width: fit-content;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;

	position: absolute;
    bottom: 0;
    left: 50%;

	font-size: 48px;
	left: calc(~"50% - 24px");
}

.tb-title {
	z-index: 1;
    position: absolute;
	color: white;
    text-shadow: 1px 1px 2px #000;
    font-weight: 700;
    bottom: 3%;
    width: 80%;
	left: 50%;
	transform: translate(-50%, 0);
	.line-clamp(1);
}

.top-right-tags {
	display: none;
}

.video-hd-mark {
	display: none;
}
.fan-video,
.fan-video:after,
.fan-video:before {
	display: none;
}

.p-metadata {
	font-size: 105%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	padding: 2px 0px 0px 5px;

	.date {
		margin: 0px;
	}
}

.p-metadata-onthumb {
	position: absolute;
	z-index: 10;
	color: white;
	text-shadow: 1px 1px 4px #000;
	padding: 2px 0px 0px 5px;

	.date {
		margin: 0px;

		a {
			color: white;
		}
	}
}

.p-gallery-data {
	display: none;
}

.p-user {
	font-weight: bold;
	word-break: break-word;
}

.p-posttitle {
	height: fit-content;
	text-align: center;
	width: 100%;
	margin-bottom: 5px;
	padding: 2px 6px;
}

.post-pictures.post-novideos {
	.p-posttitle {
		margin-bottom: 10px;
		margin-top: 5px;
	}
}

.p-postcomment {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	vertical-align: middle;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
	text-align: center;
	text-shadow: 1px 1px @global-feed-text-shadow;

	.p-formatted-text {
		width: 100%;
		max-height: 100%;
		padding: 2px 12px;
		margin: auto 0 auto;
		word-break: break-word;

		&.p-red-link {
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.p-textonly .p-postcomment {
	.p-formatted-text {
		padding: 12px 16px;
	}
}
.has-title {
	.p-formatted-text.p-clampable {
		margin: 0;
	}
}
.has-title .p-postcomment.p-dim-down,
.post-viewing-text .has-title .p-postcomment {
	justify-content: flex-start;
}

// .p-textonly .p-postcomment {
// 	min-height: calc(100% - 40px);
// 	flex-grow: unset;
// }

.p-comment-full {
	display: none;
}

.p-display-min {
	display: none;
}

.p-display-more {
	display: none;
}

.p-postcomment.p-display-more {
	span {
		padding-bottom: 18px;
	}
}

.p-profile-picture {
	display: none;
}

.p-glass-effect {
	background-color: @global-feed-post-glass-effect;
}

.p-icon-bg {
	background-image: url(@global-feed-post-text-bg-image);
	background-repeat: repeat;
	background-size: auto;
}

.p-gallery {
	.post-text:not(.post-pictures):not(.post-videos) {
		.p-profile-picture {
			display: block;
		}
	}
}
.p-faketext.p-pictures .post-novideos {
	&.post-nb-pic-1 {
		.p-frame:not(.p-gallery-data) {
			// @media (min-width: @global-feed-tablet-min) {
			@media (min-width: @global-feed-desktop-min) {
				display: flex;
			}
			flex-direction: row;
			.p-profile-picture {
				height: 100%;
				display: block;
			}
			.p-text {
				height: fit-content;
			}
			.p-postcomment {
				display: none;
			}
		}
	}
}

.p-gallery-more {
	display: none;
}

// Post text dimming
.p-pics:not(.post-viewing-text) {
	.p-dim-down {
		position: relative;
	}

	// Base style for dimming down text posts
	.p-dim-down:after {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		content: "";
		background: linear-gradient(to bottom,
			fade(@global-feed-dim-color, 0%), 50%,
			fade(@global-feed-dim-color, 85%) 97%
		);
		pointer-events: none; /* so the text is still selectable */
	}
	// Dim for text-only posts
	&.post-text:not(.post-videos):not(.post-pictures) {
		.p-dim-down:after {
			background: linear-gradient(to bottom,
				fade(@global-feed-dim-color, 0%), 97%,
				fade(@global-feed-dim-color, 85%) 100%
			);
			// background: none;
		}
	}
	
	// Dim for actual frame text
	.p-dim-down:after {
		background: linear-gradient(to bottom,
			fade(@global-feed-dim-color, 0%), 50%,
			fade(@global-feed-dim-color, 5%) 97%,
			fade(@global-feed-dim-color, 85%) 100%
		);
	}
}
.p-red {
	.p-pics:not(.post-viewing-text) {
		.p-dim-down:after {
			background: linear-gradient(to bottom,
				fade(@post-comment-s1-bg-right, 0%), 50%,
				fade(@post-comment-s1-bg-right, 5%) 97%,
				fade(@post-comment-s1-bg-right, 85%) 100%
			);
		}
	}
}

.p-show-more {
	text-align: right;
	height: fit-content;
	z-index: 1;
	position: absolute;
	bottom: 0;
	right: 0;

	.p-show-more-label {
		text-decoration: underline;
	}
	.p-show-more-text {
		padding: 0 7px 0;
		color: @global-feed-show-more-color;
	}
	.p-show-more-text:hover {
		cursor: pointer;
	}
}

.p-gallery {
	// Text only posts
	.p-pics.post-text:not(.post-videos):not(.post-pictures) {
		.p-frame {
			display: flex;
		}
		.p-frame-1 {
			flex-direction: column;
		}
		.p-show-more {
			margin-bottom: 7px;
			background-image: linear-gradient(to right, fade(@global-feed-dim-color, 0%) 0%, @global-feed-dim-color 49%);
			min-width: 170px;
		}
	}
}
.p-head {
	.p-show-more {
		background-image: linear-gradient(to right, fade(@global-feed-dim-color, 0%) 0%, @global-feed-dim-color 49%);
		min-width: 170px;
	}
}

.p-gallery > .p-head {
	position: absolute;
	z-index: 10;
}
.p-red {
	.p-gallery > .p-head {
		color: white;
	}
}

#global-feed-content > .post-block .p-pics {
	.thumb-block {
		padding: 0px;
		border-bottom: none;
	}

	.video-sd-mark {
		display: none;
	}

	.thumb-under {
		display: none;
	}

	.thumb-inside {
		margin: 0px!important;

		.thumb {
			a {
				border: none;
			}
		}
	}
}
.p-allgallery-pics {
	.thumb-block {
		aspect-ratio: unset;
		--xvaspectratio: none;
		border: none;
		.thumb-under {
			.title {
				a {
					color: white;
				}
			}
		}
	}
}
.post-block {
	border: none;
}

// desktop picture in picture
// @media(min-width: @global-feed-tablet-min) {
@media(min-width: @global-feed-desktop-min) {
	#global-feed {
		// picture in picture
		.videopv {
			left: auto;
			top: auto;
			bottom: 15px;
			right: 15px;
			width: 42%;
			max-height: 204px;
			padding: 0;
			display: flex;
			pointer-events: none;
			a {
				padding: 0;
			}
		}

		// blur
		.thumb-block.rotator-started {
			img {
				filter: blur(7px);
			}
			.videopv {
				box-shadow: 0 0 10px 5px rgba(0,0,0,0.5);
			}
		}

		// centered pip
		.thumb-block {
			.videopv {
				left: 50%;
				top: 50%;
				bottom: auto;
				right: auto;
				transform: translate(-50%, -50%);
			}
		}

		// pip bigger (full vid res)
		.thumb-block {
			.videopv {
				width: 363px;
				height: 204px;
			}
		}
	}
}

.p-frame-opacitybg {
	background-color: fadeout(#000, 90%);
	background-repeat: "no-repeat";
	background-size: "cover";
	background-position: "center";
	background-image: none!important;

	.p-pics {
		backdrop-filter: "blur(13px) brightness(0.5)";
	}
}
.p-frame-xvbg {
	background-repeat: "repeat";
	background-size: "auto";
	background-position: "unset";
	//background-image: url("@xv-repeat-asset");
	background-color: @post-frame-bg-color;

	.p-postcomment {
		color: @text-color;
		text-shadow: none;
	}

	.p-pics {
		backdrop-filter: "unset";
	}
}

.update-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	font-size: 80%;
	padding: 4px;
	gap: 4px;

	.content-info {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		opacity: 0.5;

		span {
			text-align: end;
		}
	}
}
.p-metadata {
	.update-container {
		.content-info {
			height: 100%;
		}
	}
}
.post-text.post-novideos.post-nopictures {
	.update-container {
		flex-direction: row;
		flex-wrap: wrap;

		& > div {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 3px;

			&:not(.uploader) * {
				margin-right: 5px;
			}

			margin-right: 5px;

			&:empty {
				margin-right: 0px;
			}
		}
	}
}
.update-container > .uploader > .icf-ticket-red {
	margin-right: 5px;
}

.post-block .quickies-icon {
	display: flex;
	font-size: 150%;
	// @media (max-width: @global-feed-tablet-max) {
	@media (max-width: @global-feed-mobile-max) {
		font-size: 200%;
	}
	margin-right: 5px;
	color: @red-color;
	text-shadow: 0 0 3px black;
}

.p-frame {
	position: relative;
	color: @global-feed-post-frame-text-color;
	height: fit-content;
	.aspect-ratio(16/9);
	overflow: hidden;

	.thumb {
		height: 100%;
		width: 100%;
		a {
			height: 100%;
			width: 100%;
			display: block;
		}
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}
}

.p-textonly .p-frame {
	.aspect-ratio(unset);
}

.p-profile-picture {

	// margin: 10px;

	& > img {
		width: 64px;
		height: 64px;
		max-width: unset;
		border: 1px solid black;

		&:hover {
			cursor: pointer;
		}
	}
}
.p-textonly {
	.p-profile-picture > img {
		width: 100px;
		height: 100px;
	}
}
.post-text:not(.post-pictures):not(.post-videos) .p-frame > .p-postcomment {
	color: @text-color;
	text-shadow: none;
	margin: auto;
}

.p-frame-1 {
	order: 1;
}
.p-frame-2 {
	order: 2;
}
.p-frame-3 {
	order: 3;
}
.p-frame-4 {
	order: 4;
}
.p-frame-5 {
	order: 5;
}
.p-frame-6 {
	order: 6;
}
.p-frame-7 {
	order: 7;
}
.p-frame-8 {
	order: 8;
}
.p-frame-9 {
	order: 9;
}
.p-frame-10 {
	order: 10;
}
.p-frame-11 {
	order: 11;
}
.p-frame-12 {
	order: 12;
}
.p-frame-13 {
	order: 13;
}
.p-frame-10,
.p-frame-11,
.p-frame-12,
.p-frame-13,
.p-frame-14,
.p-frame-15,
.p-frame-16 {
	display: none;
}
.p-frame-added {
	order: 100;
	display: none;
}

.p-allgallery, .p-allgallery-back {
	display: none;
}

.fit-image {
	width: 100%;
	height: 100%;
}

.thumb-top-right {
	display: flex;
	align-items: center;

	position: absolute;
	top: 4px;
	right: 8px;
}

.contents-indicator {
	display: flex;
	align-items: center;
	&.with-bg {
		background-color: @global-feed-video-indicator-bg-color;
		border-radius: 3px;
		color: #e2e2e2;
		line-height: 18px;
		padding: 2px 6px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		font-weight: bold;
	}
}

.post-view-full-gallery {
	.p-allgallery, .p-allgallery-back {
		display: flex;
	}
}

.p-allgallery, .p-allgallery-back {
	position: fixed;
	z-index: 250;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.p-allgallery-exit {
	position: absolute;
	top: 0;
	right: 0;
	height: 3vh;
	width: 3vh;
	font-size: 2.5vh;
	margin: 2vh;

	color: white;
	text-shadow: 0 0 4px black;
}

.p-allgallery-exit:hover {
	cursor: pointer;
}

.p-allgallery-back {
	background-color: rgba(0,0,0,.5);
}

.p-allgallery {
	margin: 5.5vh 5.5vw;
	padding: 1vh 1vw;

	background-color: rgba(0,0,0, 0.90);
	border-radius: 3px;
	box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.62);

	.xscroll-container {
		width: 100%;
	}

	.p-allgallery-pics {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		overflow: hidden;

		&> .p-gallery-data {
			display: flex;
			flex-basis: 33.333%;
			padding: 0.5vh 0.25vw;

			.fit-image {
				height: 100%;
				width: 100%;

				img {
					object-fit: contain;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}

.post-block {

	.p-pics {
		display: flex;
    	flex-wrap: wrap;
		flex-direction: column;
		align-content: flex-start;
		justify-content: center;

		&:not(.post-videos) {
			&:not(.post-pictures) {

				.p-frame-1 {
					width: 100%;
					height: 100%;
				}
			}
		}

		&.post-viewing-text {
			.p-frame-1 {
				.aspect-ratio(unset);
				height: 100%;
				width: 50%;

				.p-postcomment {
					// scrollbar if needed
					overflow-y: auto;
					padding: 7px;
				}

				.p-show-more {
					min-width: 15%;
					height: 20px;
					margin-right: 15px;
					padding-right: 4px;
				}

				.p-show-more {
					background-image: linear-gradient(to right,
						fade(@global-feed-dim-color, 0%) 0%,
						@global-feed-dim-color 49%);

						.p-show-more-text {
							padding: 0;
						}
				}

				.p-show-more:hover {
					background-image: linear-gradient(to right,
						fade(@global-feed-dim-color, 0%) 0%,
						lighten(@global-feed-dim-color,10%) 49%);
				}
			}

			.p-frame-3,
			.p-frame-4,
			.p-frame-5,
			.p-frame-6,
			.p-frame-7,
			.p-frame-8,
			.p-frame-9 {
				display: none !important;
			}
		}
	}
}

// 2 cols mode
@media (min-width: @cols-2-min-width) {
	.cols-2 {
		.post-block {
			.p-pics:not(.post-viewing-text) {
				align-content: center;
			}
		}
	}
}

.post-block.p-red {
	.p-show-more-text {
		color: white;
	}
	.p-pics.post-viewing-text {
		.p-frame-1 {
			.p-postcomment {
				.p-show-more {
					background-image: linear-gradient(to right,
						fade(@post-comment-s1-bg-right, 0%) 0%,
						@post-comment-s1-bg-right 49%);
				}
				.p-show-more:hover {
					background-image: linear-gradient(to right,
						fade(@post-comment-s1-bg-right, 0%) 0%,
						lighten(@post-comment-s1-bg-right,10%) 49%);
				}
			}
		}
	}
}

.post-block.p-textonly {
	&>.p-head {
		display: none;
	}
}

.p-content {
	width: 100%;
	height: 100%;
}

.p-text {
	width: 100%;
	height: 100%;
	flex-grow: 100;
	position: relative;
	display: flex;
	flex-direction: column;
}

.p-frame-head {
	width: 100%;
	padding: 3px 3px 0px 3px;
}


#global-feed-content:not(.cols-2)  {
	.small-text-center {
		.p-frame-head {
			position: absolute;
		}
		.p-postcomment {
			justify-content: center;
		}
	}
}
.big-text-left {
	text-align: left;
}

.p-frame-head-full {
	margin-left: 5px;
}

.p-content {
	display: flex;
}

.p-frame-foot {
	width: 100%;
	display: none;
	position: absolute;
	padding-left : 3px;
	bottom: 0;
	left: 0;

	justify-content: space-between;

	.p-extend-container {
		padding: 10px 5px 5px 10px;
		position: absolute;
		bottom: 0;
		right: 0;
		.p-extend {

			margin: 0px 5px 5px 0px;
			height: 15px;
			width: 15px;

			border-bottom: 1px solid @global-feed-post-frame-text-color;
			border-right: 1px solid @global-feed-post-frame-text-color;
		}
	}
	.p-extend-container:hover {
		cursor: pointer;

		.p-extend {
			margin: 0px 3px 3px 0px;
			padding: 1px;
			height: 17px;
			width: 17px;

			font-size: 110%;
		}
	}
}

.post-viewing-head,
.post-viewing-text {
	.p-frame-foot .p-extend-container .p-extend {
		border-bottom: none;
		border-right: none;
		border-left: 1px solid @global-feed-post-frame-text-color;
		border-top: 1px solid @global-feed-post-frame-text-color;
	}

	.p-frame-head > .update-container {
		flex-direction: row;
		flex-wrap: wrap;

		& > div {
			display: flex;
			flex-direction: row;
			align-items: center;

			&:not(.uploader) * {
				margin-right: 5px;
			}

			margin-right: 5px;

			&:empty {
				margin-right: 0px;
			}
		}
	}
}

.p-extend-container {
	background: linear-gradient(
		-45deg,
		fade(@global-feed-dim-color, 100%) 0%,
		fade(@global-feed-dim-color, 0%) 90%
	);
}
.p-textonly .p-extend-container {
	background: linear-gradient(
		-45deg,
		fade(@global-feed-dim-color, 100%) 0%,
		fade(@global-feed-dim-color, 0%) 90%
	);
}
.p-gallery-seemore {
	display: none; // flex
	color: rgb(207, 207, 207);

	position: absolute;
	z-index: 5; // eventually needs to be above the red ticket
	width: 100%;
	height: 100%;

	background-color: rgba(0, 0, 0, 40%);
	transition: background-color 0.2s ease;
    top: 0;

    justify-content: center;
    align-items: center;

	font-size: 350%;

	word-break: break-all;
    text-align: center;
}
.p-playlist-seemore {
	display: none; // flex
	color: rgb(207, 207, 207);

	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;

	background-color: rgba(0, 0, 0, 40%);
	transition: background-color 0.2s ease;
    top: 0;

    justify-content: center;
    align-items: center;

	font-size: 350%;

	word-break: break-all;
    text-align: center;
	cursor: pointer;
}

.p-pic-top-right {
	position: absolute; // above red ticket
	z-index: 10;
	top: 4px;
	right: 8px;

	background-color: #222;
    border-radius: 3px;
    color: #e2e2e2;
    line-height: 18px;
    padding: 2px 6px;
    font-weight: 700;
}

.p-views-achieved {
	.p-postcomment {
		.p-formatted-text {
			text-align: center;

			padding-top: 20px;
			padding-bottom: 20px;
		}
		.p-nb-video-views {
			font-weight: bold;
		}
	}
}

@media (min-width: @cols-2-min-width) {
	.cols-2 {
		.p-gallery-seemore {
			font-size: 250%;
		}
		.p-playlist-seemore {
			font-size: 250%;
		}
	}
}

.fit-image:hover > .p-gallery-seemore {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 25%);
	// font-size: 375%;
}
.thumb-inside:hover > .p-playlist-seemore {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 25%);
	// font-size: 375%;
}

.p-red {
	.p-frame-foot {
		.p-extend-container {
			background: linear-gradient(
				-45deg,
				rgba(222,38,0,1) 0%,
				rgba(222,38,0,0) 90%
			);

			.p-extend {
				border-bottom: 1px solid white;
				border-right: 1px solid white;
			}
		}
	}


	.post-viewing-text, .post-viewing-head {
		.p-frame-foot .p-extend-container .p-extend {
			border-bottom: none;
			border-right: none;
			border-left: 1px solid white;
			border-top: 1px solid white;
		}
	}
}

.fill-icon {
	position: relative;
}

.apply-preview-clap() {
	position: absolute;
	top: 13px;
	right: 13px;

	content: @icf-play-clap-o;
	.font-family-iconfont();

	color: #fff;
    font-size: 150%;
    text-shadow: 0 0 3px BLACK;
	pointer-events: none;
	cursor: pointer;
}

// @media (min-width: @global-feed-tablet-min) {
@media (min-width: @global-feed-desktop-min) {
	.fill-icon:hover::after {
		.apply-preview-clap();
	}
}
@media (max-width: @global-feed-mobile-max) {
	.fill-icon::after {
		.apply-preview-clap();
	}
}

.p-textonly {
	.p-head {
		margin-left: 80px;
	}
}


.post-block div.p-pics.post-videos.post-text {
	.p-frame-1 {
		height: 100%;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	// @media (max-width: @global-feed-tablet-max) and (min-width: @global-feed-tablet-min) {
	// 	.p-frame-1 {
	// 		height: 50%;
	// 	}
	// 	&.post-viewing-text {
	// 		.p-frame-1 {
	// 			height: 100%;
	// 		}
	// 	}
	// }
}

.post-videos,
.p-allgallery {
	.thumb-inside > .thumb > a::after {
		font-family: iconfont !important;
		content: @icf-play;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		text-shadow: 0 0 5px black;
		font-size: 300%;
	}
}
.post-videos,
.p-allgallery{
	.p-quickies .thumb-inside > .thumb > a::after {
		content: @icf-play-bolt;
	}
}
.thumb-block.p-content-locked {
	.thumb-inside > .thumb > a::after {
		content: @icf-lock;
	}
}

.post-videos .rotator-started .thumb-inside > .thumb > a::after {
	display: none;
}
.post-videos .p-content-locked.rotator-started .thumb-inside > .thumb > a::after {
	display: block;
}

.aspect-ratio(@ar) {
	aspect-ratio: @ar;
	--xvaspectratio: @ar;
}

@video-thumbs-padding: 2 / 100;

// Sets the virtual widths for a post configuration
.post_getVirtWidths (@nb_col, @nb_row) {
	@virtual_width_min: (1920 / @nb_row) * @nb_col;
	@virtual_width_max: 1920;
}

// Gets, in %, the width of big frames according a post configuration
.post_getBigWidth (@nb_col, @nb_row) {
	.post_getVirtWidths(@nb_col, @nb_row);
	@post_getBigWidth: 100% * (@virtual_width_max / (@virtual_width_max + @virtual_width_min));
}

// Gets, in %, the width of a min frame according to number of thumbs vertically and columns of min
.post_getMinWidth (@nb_col, @nb_row) {
	.post_getBigWidth(@nb_col, @nb_row);

	@post_getMinWidthTotal: 100% - @post_getBigWidth;
	@post_getMinWidth: @post_getMinWidthTotal / @nb_col;
}

.post_getAspectRatio(@nb_col, @nb_row) {
	.post_getMinWidth(@nb_col, @nb_row);

	@post_getAspectRatio: floor((@virtual_width_min + @virtual_width_max) / 1080 * 1000) / 1000;
}

.post_setThumbOrder(@main-thumb-nb, @thumb-nb, @nb_col_left, @nb_row, @nb_skip) {
	order: if((@thumb-nb = @main-thumb-nb),
		@nb_col_left * @nb_row - @nb_skip + 1,
		if((@thumb-nb - 1  <= @nb_col_left * @nb_row - 1),
			@thumb-nb - 1,
			@thumb-nb
		)
	);
}

/* Set the properties of the thumbs */
/* @main-thumb-nb: the number of the main thumb */
/* @nb_col: the total number of columns */
/* @nb_col_left: the number of columns before the main thumb */
/* @nb_row: the number of rows for small thumbs */
/* @post_getBigWidth: the width of the big thumb */
/* @post_getMinWidth: the width of the small thumbs */
/* @nb_skip: the number of thumbs to skip */
.post_setThumbsProperties(@main-thumb-nb, @nb_col, @nb_col_left, @nb_row, @post_getBigWidth, @post_getMinWidth, @nb_skip) {
	@minv-frame-mw: 2px;
	@minv-frame-mh: @minv-frame-mw / 2;

	each(range(9), {
		.p-frame-@{value} {
			width: if((@value = @main-thumb-nb), @post_getBigWidth, calc(@post_getMinWidth - (@minv-frame-mw * 2)));
			.post_setThumbOrder(@main-thumb-nb, @value, @nb_col_left, @nb_row, @nb_skip);

			margin: if((@value = @main-thumb-nb), 0px, @minv-frame-mh @minv-frame-mw @minv-frame-mh);

			display: if(@value <= (@nb_row * @nb_col + 1) - @nb_skip, 'flex', 'none');
		}
	});
}

/* Set the properties of the post */
/* @nb_row: the number of rows */
/* @nb_col_left: the number of columns before the main thumb */
/* @nb_col_right: the number of columns after the main thumb */
/* @nb_skip: the number of thumbs to skip */
.post_setPostProperties(@nb_row, @nb_col_left, @nb_col_right, @nb_skip) {
	@nb_col: @nb_col_left + @nb_col_right;

	&.p-videos .thumb-block .thumb-inside {
		height: 100%;
		width: 100%;
	}

	.post_getAspectRatio(@nb_col, @nb_row);

	&:not(.p-textonly) {
		.aspect-ratio(@post_getAspectRatio);
	}

	@post_openWidth: 100% - @post_getBigWidth;

	.p-gallery {
		height: 100%;
	}

	.p-pics {
		height: 100%;

		&.post-viewing-text:not(.post-nopictures) {
			.p-frame-1 {
				width: @post_openWidth !important;
			}
			.p-frame-2 {
				width: @post_getBigWidth !important;
			}
		}

		&.post-videos {

			&.post-viewing-text {
				.p-frame-1 {
					width: @post_openWidth !important;
				}
				.p-frame-2 {
					width: @post_getBigWidth !important;
				}
			}

			each(range(9), {
				.p-frame-@{value} {
					width: @post_getMinWidth;
				}
			});

			.p-frame-1 {
				.aspect-ratio(16/((@nb_skip+1) * 9));
			}

			&:not(.post-text) {
				.post_setThumbsProperties(1, @nb_col, @nb_col_left, @nb_row, @post_getBigWidth, @post_getMinWidth, @nb_skip);
			}

			&.post-text {
				.post_setThumbsProperties(2, @nb_col, @nb_col_left, @nb_row, @post_getBigWidth, @post_getMinWidth, @nb_skip);
			}
		}
	}
}

/// Desktop
@media (min-width: @global-feed-desktop-min) {

	& {
		.post-block {
			.post_setPostProperties(2, 1, 3, 1);

			&.p-pictures:not(.p-videos) {
				.aspect-ratio(6);
			}

			.p-pics {
				&.post-gallery-more-video-desktop {
					.p-frame-8 {
						.p-gallery-more-video-desktop {
							display: flex;
						}
					}
				}

				&.post-gallery-more-desktop {
					.p-frame-12 {
						.p-gallery-more-desktop {
							display: flex;
						}
					}
				}

				&.post-playlist-more-desktop {
					.p-frame-8 {
						.p-playlist-more-desktop {
							display: flex;
						}
						.thumb-inside .thumb a::after {
							display: none;
						}
					}
				}
			}
		}

		.cols-2 {
			.post-block {
				.p-pics {
					&.post-playlist-more-desktop-col-2 {
						.p-frame-5 {
							.p-playlist-more-desktop-col-2 {
								display: flex;
							}
							.thumb-inside .thumb a::after {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	// 2 cols mode
	@media (min-width: @cols-2-min-width) {
		.cols-2 {
			& {
				.post-block {
					.post_setPostProperties(3, 1, 1, 2);

					&.p-pictures:not(.p-videos),
					&.p-textonly {
						.aspect-ratio(3);
					}

					.p-pics {
						&.post-gallery-more-video-desktop-col-2 {
							.p-frame-5 {
								.p-gallery-more-video-desktop-col-2 {
									display: flex;
								}
							}
						}
						.with-desktop-2cols {
							.p-gallery-more-video-desktop {
								display: none!important;
							}
							.p-gallery-more-desktop {
								display: none!important;
							}
							.p-playlist-more-desktop {
								display: none!important;
							}
						}
					}
				}
			}
		}
	}
}

/// Tablet + Desktop
// @media (min-width: @global-feed-tablet-min) {
@media (min-width: @global-feed-desktop-min) {
	.post-block {
		&>.p-head {
			display: none;
		}
	}

	.post-block:not(.p-textonly) {
		.p-frame-1.p-glass-effect {
			background: linear-gradient(
				45deg,
				@global-feed-post-text-bg-color-grad,
				@global-feed-post-text-bg-color
			);
		}
	}
}

/// Tablet
// @media (max-width: @global-feed-tablet-max) and (min-width: @global-feed-tablet-min) {
// 	& {
// 		.post-block {
// 			.post_setPostProperties(4, 1, 1, 1);

// 			&.p-pictures:not(.p-videos) {
// 				.aspect-ratio(3);
// 			}

// 			.p-pics {

// 				&.post-gallery-more-video-tablet {
// 					.p-frame-8 {
// 						.p-gallery-more-video-tablet {
// 							display: flex;
// 						}
// 					}
// 				}

// 				&.post-gallery-more-tablet {
// 					.p-frame-3 {
// 						.p-gallery-more-tablet {
// 							display: flex;
// 						}
// 					}
// 				}

// 				&.post-playlist-more-tablet {
// 					.p-frame-8 {
// 						.p-playlist-more-tablet {
// 							display: flex;
// 						}
// 						.thumb-inside .thumb a::after {
// 							display: none;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

//// GALLERY

.post_setGalleryProperties(@nb_row, @nb_col, @more_type) {
	.p-gallery {
		.post-novideos.post-pictures {
			.p-frame {
				.aspect-ratio(1);
				width: 100% / @nb_col;
			}
			each(range(12), {
				.p-frame-@{value} {
					display: if((@value <= @nb_row * @nb_col), 'flex', 'none');
				}
			});
			@last_frame: @nb_row * @nb_col;
			.p-frame-@{last_frame} {
				.p-gallery-seemore {
					display: none;
				}
				.p-gallery-more-@{more_type} {
					display: flex;
				}
			}
		}
	}
}

.post_setGalleryPropertiesForNbPics(@nb_row, @nb_col, @more_type, @nb_pics, @divisor) {
	.post_setGalleryProperties(@nb_row, @nb_col, @more_type);
	.p-gallery {
		.post-novideos.post-pictures.post-nb-pic-@{nb_pics} {
			.p-frame {
				width: 100% / (@divisor + 1);
			}
		}
	}
}

/// Desktop gallery 6x2
@media (min-width: @global-feed-desktop-min) {

	// 1 col mode
	.post_setGalleryProperties(1, 6, desktop);

	#global-feed-content:not(.cols-2) {
		.post-novideos.post-pictures {
			&.p-realcenter:not(.post-viewing-text) {
				.p-frame-1 {
					position: absolute;
					left: 0;
				}
			}
		}
	}

	// 2 cols mode
	@media (min-width: @cols-2-min-width) {
		.cols-2 {
			& {
				.post_setGalleryPropertiesForNbPics(2, 6, desktop-col-2, 1, 2);
				.post_setGalleryPropertiesForNbPics(2, 6, desktop-col-2, 2, 2);
				.post_setGalleryPropertiesForNbPics(2, 6, desktop-col-2, 3, 3);
				.post_setGalleryPropertiesForNbPics(2, 6, desktop-col-2, 4, 4);
			}
		}
	}
	#global-feed-content.cols-2 {
		.post-novideos.post-pictures {
			&.p-realcenter.post-nb-pic-1 {
				justify-content: flex-start;
			}
		}
	}
}

/// Tablet gallery 3x2
// @media (max-width: @global-feed-tablet-max) and (min-width: @global-feed-tablet-min) {
// 	.post_setGalleryProperties(2, 3, tablet);
// }


/// Tablet + Desktop
// @media (min-width: @global-feed-tablet-min) {
@media (min-width: @global-feed-desktop-min) {
	.p-textonly {
		.p-frame-head {
			display: none;
		}
	}
	.p-pictures:not(.p-videos) {
		.p-frame-head-full {
			display: none;
		}
	}
	.post-block {
		.p-frame-foot {
			display: flex;

			background: linear-gradient(to bottom,
				fade(@global-feed-dim-color, 0%) 0%,
				fade(@global-feed-dim-color, 100%) 80%
			);
		}
	}
	.p-textonly {
		.p-update {
			display: none;
		}
	}
	.post-block:not(.p-textonly) {
		.p-frame-head-full {
			display: none;
		}
		.p-postcomment {
			align-items: start;

			&.overflowing {
				justify-content: flex-start;
			}
		}
	}
	.p-faketext {
		.p-extend-container {
			display: none;
		}
	}
	.p-videos:not(.p-textonly).p-red {
		.p-frame-foot {
			background: linear-gradient(to bottom,
				fade(@global-feed-dim-color, 0%) 0%,
				fade(@post-comment-s1-bg-right, 100%) 90%
			);
		}
	}
	.post-videos {

		// remove '.pic >' to impact video thumb
		.pic > .thumb {
			overflow: hidden;
		}

		.thumb-block:not(.p-content-locked) {
			.thumb-inside:hover > .thumb {
				box-shadow: inset 0px 0px 30px 0px rgba(0,0,0,0.85);
				&> a::after {
					font-size: 400%;
					cursor: pointer;
				}
			}
		}

		.fit-image {
			cursor: pointer;
		}
	}

	.post-novideos.post-pictures {
		position: relative;
		overflow: hidden;
		flex-direction: row;
		justify-content: center;

		.p-frame:not(.p-frame-1) {
			padding: 0.25%;
			position: relative;

			.fit-image {
				position: absolute;
				margin: auto;
				width: fit-content;
				height: fit-content;
				inset: 0;
				// safari for inset
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: hidden;
				cursor: pointer;

				img {
					width: unset;
					height: auto;
					object-fit: unset;
					object-position: unset;
					max-width: 100%;
					max-height: unset;
				}
			}
		}

		&.post-nb-pic-1 {
			.p-frame {
				margin: 0;
				.aspect-ratio(unset);
				height: 100%;
				--fit-img: 1;
			}
		}
	}


	.post-block.p-videos {
		.p-metadata {
			padding: 0;
			.date, .p-frame-head {
				text-shadow: 0 0 5px @text-invert-color;
				background: fade(@text-invert-color, 50%);
				padding: 2px 5px 0px 7px;
				color: @text-color;
				.p-user {
					color: @text-color;
				}

				.icf-ticket-red {
					text-shadow: none;
				}
			}
		}
	}

	.p-red.post-block.p-videos {
		.p-metadata {
			.date {
				text-shadow: 0 0 5px black;
				background: fade(black, 50%);
				color: white;
				.p-user {
					color: white;
				}
			}
		}
	}
}

////

/// Mobile
@media (max-width: @global-feed-mobile-max) {
.p-display-min {
	display: block;
}
.p-gallery-nb {
	display: none;
}

.p-frame-head {
	display: none;
}
.p-frame-foot {
	display: flex;
}
.p-update {
	display: none;
}

.p-pictures:not(.p-text) {
	.p-frame-head-full {
		display: none;
	}
}

.date {
	.p-user {
		color: @text-color!important;
	}
	text-shadow: none!important;
}

.p-head {

	.p-mobile-comment {
		overflow: hidden;
		position: relative;
		color: @global-feed-post-frame-text-color;
		background: #0000000f;

		&.p-dim-down::after {
			content: "";
			display: block;
			position: absolute;
			height: 100%;
			width: 100%;
			bottom: 0;
			pointer-events: none;
			background: linear-gradient(to bottom,
				fade(@global-feed-dim-color, 0%) 60%,
				fade(@global-feed-dim-color, 100%) 97%
			);
		}

		.p-mobile-comment-title {
			text-align: center;
			font-size: 125%;
			padding: 8px 4px 4px;
		}

		.p-mobile-comment-comment {
			font-size: 100%;
			padding: 0 13px 4px;
		}

		&:not(.open) {
			.p-mobile-comment-comment {
				display: none;
				text-align: center;
			}
			.p-mobile-comment-title {
				.line-clamp(2);
			}
			&.title-one-line {
				.p-mobile-comment-title {
					.line-clamp(1);
				}
				.p-mobile-comment-comment {
					display: block;
					.line-clamp(1);
				}
			}
		}

		&.open {
			height: fit-content;

			.p-mobile-comment-comment {
				padding-bottom: 20px;
			}
		}

		&.show-extend {
			.p-mobile-extend-container {
				display: flex;
			}
		}

		.p-mobile-extend-container {
			display: none;

			padding: 10px 5px 5px 10px;
			position: absolute;
			bottom: 0;
			right: 0;

			&:hover {
				cursor: pointer;

				.p-extend {
					margin: 0px 3px 3px 0px;
					padding: 1px;
					height: 17px;
					width: 17px;

					font-size: 110%;
				}
			}
		}

		.p-mobile-extend-container .p-extend {
			margin: 0px 5px 5px 0px;
			height: 15px;
			width: 15px;

			border-bottom: 1px solid @global-feed-post-frame-text-color;
			border-right: 1px solid @global-feed-post-frame-text-color;
		}
		&.open .p-mobile-extend-container .p-extend {
			border-bottom: none;
			border-right: none;
			border-left: 1px solid @global-feed-post-frame-text-color;
			border-top: 1px solid @global-feed-post-frame-text-color;
		}
	}
}
.post-block:not(:has(.post-text)) {
	.p-mobile-comment {
		display: none;
	}
}
.p-red .p-head .p-mobile-comment {
	background: linear-gradient(135deg,
		@post-comment-s1-bg-left,
		@post-comment-s1-bg-right
	);

	.p-mobile-comment-title {
		color: white;
	}
	.p-mobile-comment-comment {
		color: white;
	}

	.p-mobile-extend-container {
		.p-extend {
			border-color: white;
		}
	}

	&.p-dim-down::after {
		background: linear-gradient(
			to bottom,
			rgba(222,38,0,0) 68%,
			rgba(222,38,0,1) 105%
		);
	}
}

.update-container {
	display: flex;
	flex-direction: column-reverse;
	flex-wrap: wrap;

	& > div {
		display: flex;
		flex-direction: row;
		align-items: center;

		// width: 100%;
		gap: 3px;

		&:not(.uploader) * {
			margin-right: 5px;
		}

		& > span {
			line-height: 0;
		}

		margin-right: 5px;

		&:empty {
			margin-right: 0px;
		}
	}
}

.p-show-more-button {
	background-color: @post-frame-bg-color;
	display: flex;
	align-items: center;
	justify-content: center;

	.icon-f {
		font-size: 40px;
	}
}

@big-frame-w: 100%;
@mid-frame-w: 50%;

.post-block:not(.p-textonly) {
	.p-gallery > .p-head {
		display: none;
	}
}

#global-feed-content .post-block {
	&.p-noextend {
		.p-show-more-button {
			display: none;
		}
	}

	&.p-textonly {
		.p-gallery .p-pics {
			.aspect-ratio(unset);
		}
	}

	.p-gallery .p-pics.side-by-side {
		flex-direction: row;

		.pic.side-by-side {
			display: block;
			aspect-ratio: unset;
			height: 100%;
			width: fit-content;
			.thumb {
				width: fit-content;
			}
		}
	}

	.p-gallery .p-pics {
		flex-direction: row;
		.aspect-ratio(floor(16/9*1000)/1000);

		.p-frame-gallery {
			display: none;
		}

		.p-frame-2,
		.p-frame-3,
		.p-frame-4,
		.p-frame-5,
		.p-frame-6,
		.p-frame-7,
		.p-frame-8,
		.p-frame-9,
		.p-frame-10,
		.p-frame-11,
		.p-frame-12 {
			width: @mid-frame-w;

			.thumb {
				width: 100%;
			}

			.thumb-inside {
				position: unset;
			}
		}

		&.post-text,
		&.always-text {
			.p-frame-1 {
				display: none;
			}
		}

		&.post-videos {
			&.post-text,
			&.always-text {
				&.post-videos {
					.p-frame-2 {
						width: @big-frame-w;
						order: 0;
					}
				}
			}

			&:not(.post-text):not(.always-text) {
				&.post-videos {
					.p-frame-1 {
						width: @big-frame-w;
						order: 0;
					}
				}
			}
		}

		&:not(.post-videos):not(.side-by-side) {
			&.post-text,
			&.always-text {
				.p-frame-2 {
					width: @big-frame-w;
				}
			}
			&:not(.post-text):not(.always-text) {
				.p-frame-1 {
					width: @big-frame-w;
				}
			}
			&:not(.post-pictures) {
				.p-frame-1 {
					display: flex;
				}
			}
		}

		.p-frame-preview {
			display: block;
			order: 1;
			width: 100% / 2;
		}
		.p-frame-preview-extend {
			width: @big-frame-w;
		}

		&.post-extend-odd:not(.side-by-side) {
			.p-frame-1,
			.p-frame-2,
			.p-frame-3 {
				order: 0;
			}

			&.post-text,
			&.always-text {
				.p-frame-3 {
					width: @big-frame-w;
				}
			}
			&:not(.post-text):not(.always-text) {
				.p-frame-2 {
					width: @big-frame-w;
				}
			}
		}

		&.post-extend-odd.side-by-side {
			.p-frame-1,
			.p-frame-2,
			.p-frame-3,
			.p-frame-4 {
				order: 0;
			}

			&.post-text,
			&.always-text {
				.p-frame-4 {
					width: @big-frame-w;
				}
			}
			&:not(.post-text):not(.always-text) {
				.p-frame-3 {
					width: @big-frame-w;
				}
			}
		}
	}

	.p-content {

		.p-big.p-display-min {
			// width: 95%;
			// margin: 0px auto 5px;

			width: 100%;
			display: flex;
			flex-flow: row wrap;
			margin: 0px auto 0px;

			.p-frame {
				width: 50%;
				// background-color: @post-frame-bg-color;
				height: fit-content;
				.aspect-ratio(floor(16/9*1000)/1000);

				.thumb {
					a {
						img {
							width: 100%;
							max-width: 100%;
						}
					}
				}
			}

			.p-frame-big {
				width: 100%;
			}
		}

	}

	&.p-reduced-mobile {
		.p-gallery {
			.p-pics {
				.p-frame-preview:not(.p-main-frame) {
					width: 25%;

					.fill-icon::after {
						display: none;
					}

					.thumb-top-right {
						top: 2px;
						right: 4px;
						font-size: 80%;

						.contents-indicator.with-bg {
							line-height: 11px;
							padding: 2px 3px;
						}
					}

					.thumb > a::after {
						font-size: 150%;
					}

					.thumb-under {
						bottom: 0px;
						.title a {
							font-size: 70%;
						}
					}
				}
			}
		}
	}
}
}

.see-more-posts {
	position: relative;
	display: flex;
    flex-direction: column;
    align-items: center;

	background-color: @global-feed-preview-glass-effect;
	transition: opacity 0.1s;


	&.view {
		background-color: unset;

		.see-more-posts-text-container {
			display: none;
		}
	}

	&:not(.view) .see-more-posts-text-container {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		pointer-events: none;

		width: 100%;
		height: 100%;

		display: flex;

		.see-more-posts-text {
			height: 100%;
			width: fit-content;
			padding: 0px 10% 0px 10px;
			display: flex;
			align-items: center;

			background: rgb(0,0,0);
			background: linear-gradient(90deg,
				fadeOut(@global-feed-preview-text-gradient, 10%) 70%,
				fadeOut(@global-feed-preview-text-gradient, 100%) 100%
			);

			.see-more-nb {
				font-weight: bold;
			}

			.icon-f {
				font-size: 150%;
			}
		}
	}

	.more-posts-preview {
		width: 100%;
		height: 40px;

		display: block;
		direction: rtl;
		overflow: hidden;
		white-space: nowrap;

		.post-preview {
			display: inline-block;
			.aspect-ratio(16/9);
			height: 100%;
			overflow: hidden;

			.post-preview-text {
				@preview-text-lines: 2;
				@preview-text-lheight: 18px;

				.line-clamp(@preview-text-lines);
				line-height: @preview-text-lheight;
				max-height: @preview-text-lines * @preview-text-lheight;
				text-align: center;
			}

			img {
				object-fit: contain;
				max-width: 100%;
				max-height: 100%;
				padding: 2px 1px 0px 0px;
			}

			&.p-icon-bg {
				background-size: contain;
			}

			transition: opacity 0.1s;
			opacity: 0.9;
			&:hover {
				opacity: 1;
			}
		}
	}

	.more-posts-container {
		display: none;
		width: 100%;
	}

	&.view {
		& > .more-posts-preview {
			display: none;
		}

		& > .more-posts-container {
			display: block;
		}
	}

	opacity: 0.9;
	&:not(.view) {
		&:hover {
			opacity: 1;
			cursor: pointer;
		}
	}
}

.hide-filter {
	display: none;
}

// page end
}

#global-feed-content, //body.with-global-feed,
#tabActivity {
	.loader {
		.text-border-2px(rgba(0,0,0,0.4));
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);

		.icf-spinner {
			font-size: 6em;
		}
	}
}

/* ==== UPLOADER POP-UP ==== */

.uploader-info-popup {
	.x-popup-content {
		padding: 5px 5px;
		box-shadow: 0px -1px 20px 5px @global-feed-uploader-info-shadow;
		background-color: @global-feed-uploader-info-bg-color;
		background: linear-gradient(120deg, @global-feed-uploader-info-bg-color 0%, lighten(@global-feed-uploader-info-bg-color, 10%) 100%);
		border-radius: 3px;
	}
}

.uploader-info {
	display: flex;
	flex-direction: row;

	font-size: 120%;

	.user-filter,
	.uploader-info-name-text {

		&:hover {
			color: fadeOut(white, 25%);
			cursor: pointer;
		}
	}

	.uploader-info-name-text {
		text-decoration: none;
	}
	.user-filter:hover {
		.view-click {
			text-decoration: underline fadeOut(white, 50%);
		}
	}

	.user-filter {
		font-size: 75%;
		margin: 5px;

		.view-click {
			font-size: 105%;
		}
	}
}

.uploader-info-profile {
	.user-reject,
	.user-subscribe {
		margin-left: 2px;
		margin-bottom: 2px;
	}
}

.uploader-info-pic > img {
	width: 64px;
	height: 64px;
	margin-right: 5px;

	// tmp
	border: 1px solid black;
	background-color: #333;
}
.uploader-info-full-name {
	display: flex;
}
.uploader-info-flag, .uploader-info-name {
	display: flex;
	align-items: center;
}
.uploader-info-flag {
	margin-bottom: 3px;
}

.uploader-info-profile {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}

.uploader-info-user {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	padding: 3px;
}

.uploader-info-ranking {
	height: 32px;
	padding: 3px;
}

.p-metadata:hover .uploader-info {
	// visibility: visible;
}

.uploader-info-name-text {
	font-size: 120%;
	padding: 3px;
	margin-right: 3px;
	.line-clamp(1);
}

.p-dropdown-error {
	color: red;
	width: fit-content;
	margin: auto;
	text-align: center;
}

.p-popup-error {
	border: 1px solid rgba(255,0,0,.5);
}

@scroll-to-top-height: 3.5vh;
@scroll-to-top-hide-height: -(@scroll-to-top-height * 1.5);

.scroll-to-top {
	position: fixed;
	z-index: @zindex-feed-back-to-top;
	top: @scroll-to-top-hide-height;
	left: 50%;
	transform: translateX(-50%);

	height: @scroll-to-top-height;
	min-width: 11vw;
    border-radius: 5px;
	padding: 0px 6px 0px;

	color: white;
	border: 1px solid fadeOut(@post-comment-s1-bg-left, 50%);
	background: linear-gradient(160deg,
		fadeOut(@post-comment-s1-bg-left, 50%) 0%,
		fadeOut(@post-comment-s1-bg-right, 50%) 75%
	);

	display: flex;
    align-items: center;
    justify-content: center;

	transition: top 0.5s ease;
}

.show-scroll-to-top {
	top: 6vh;

	&.profile-feed-scroll-to-top {
		top: 10vh;
	}
}
.is-desktop {
	// hidden on desktop
	.show-scroll-to-top {
		top: @scroll-to-top-hide-height;
	}
}
.post-view-noscroll {
	overflow: hidden;
}

.post-block .content-locked {
	padding-right: 3px;
	.content-locked-icon {
		padding: 3px 5px 1px;;
		background-color: #161616;
		color: white;
		box-shadow: 0px 0px 8px 0px #00000050;
		border-radius: 5px;

		.icon-f {
			display: flex;
		}

		&:hover {
			opacity: 0.9;
			cursor: pointer;
		}
	}

	.content-locked-tooltip {
		display: none;
	}
}
// mobile
.post-block .p-head .content-locked {
	.content-locked-icon {
		font-size: 95%;
		padding: 3px 3px 0px;
		box-shadow: none;
	}
}

// .debug_switcher {
// 	display: flex;
// 	flex-direction: column;

// 	position: fixed;
// 	bottom: 10px;
// 	right: 10px;
// 	z-index: 1000;

// 	padding: 10px;

// 	background: fade(#171A21, 95%);
// 	border: 1px #d8d5db;
// 	color: white;

// 	button {
// 		text-align: left;
// 		cursor: pointer;
// 		margin: 8px 4px;
// 		padding: 2px;
// 		color: black;
// 		border-radius: 0px;
// 		background: #eae8ff;
// 		border: 1px solid #d8d5db;

// 		&:hover {
// 			background: darken(#d8d5db, 10%);
// 		}

// 		&.active {
// 			background: #b0d7ff;
// 			&:hover {
// 				background: darken(#b0d7ff, 10%);
// 			}
// 		}
// 	}
// }

// Specific display of the feed filters for profile activity tab
.with-global-feed #tabActivity #global-feed-video-filters {
	position: absolute;
    right: 0;
    top: -13px;

	.simple-dropdown.global-feed-filters {
		width: 100%;
		display: flex;
		flex-direction: row-reverse;

		.with-niv2:nth-child(1),
		.with-niv2:nth-child(2) {
			display: none;
		}
		.with-niv2:nth-child(3) .show-niv2 {
			box-shadow: 0px 2px 6px 0px #00000038;
		}
	}
}