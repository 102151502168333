#page {
	&.is-draging {
		cursor: crosshair;
	}
	
	& .drag-drop-selection {
		position: absolute;
		background: rgba(255, 0, 0, 0.3);
		border: 1px dotted red;
		z-index: 9999;
	}
}