
.report-search-words {
	max-width: 840px;
	text-align: center;
	
	h2 {
		.icon-f {
			color: @red-color;
		}
	}
	
	.desc {
		text-align: left;
		font-size: 17px;
		margin: 0 0 10px;
	}
	
	ul {
		overflow: hidden;
		padding: 0 0 15px 10px;
		text-align: left;
		width: 100%;
		
		li {
			float: left;
			width: 50%;
			
			&:first-child:last-child {
				float: none;
				text-align: center;
				width: 100%;
			}
		}
	}
	
	label {
		display: block;
		font-weight: normal;
		padding: 5px 10px;
		margin: 0 0 4px;
		
		input {
			margin-right: 4px;
			vertical-align: middle;
		}
	}
	
	.label-i-understand {
		text-align: left;
	}
	.i-understand {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Chrome/Safari/Opera */
		-khtml-user-select: none; /* Konqueror */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently not supported by any browser */
		
		font-family: monospace;
	}
	
	input[name="i_understand"] {
		font-size: 17px;
	}
	
	button {
		font-size: 22px;
		text-transform: uppercase;
	}
}