.thumb-block-size-mixin(@nb_thumbs) {
	width: 100% / @nb_thumbs;
}

.listing-settings-float-mixin(@top, @height) {
	float: none;
	margin-top: 0;
	position: relative;
	.btn {
		position: absolute;
		right: -2px;
		top: @top;
		height: @height;
		padding: 5px 2px 5px 7px;
		border: 0;
		border-left: 5px solid @body-bg;
		&:hover {
			border-color: @text-invert-color;
		}
	}
}

.video-sd-mark,
.video-hd-mark,
.thumb-block .video-viewed {
	display: inline-block;
	background: @thumb-block-mark-bg;
	border-radius: 3px;
	color: #fff;
	font-weight: bold;
	text-align: center;
	vertical-align: text-top;
	.xv-label-mixin(22px);
}
.video-sd-mark {
	background: #000;
	color: #fff;
}

.mozaique {
	overflow: hidden;
	margin-left: -@video-thumb-padding-left;
	margin-right: -@video-thumb-padding-right;
	
	&.profileslist {
		.thumb-block {
			padding-bottom: 10px;
			
			.thumb-inside {
				padding-bottom: 100%;
				
				.thumb {
					a {
						height: 100%;
						border: 0;
						
						img {
							border: 1px solid @thumb-block-thumb-border;
							height: 100%;
							object-fit: contain;
							width: 100%;
						}
					}
				}
			}
			
			p {
				margin-bottom: 0;
			}
			
			.profile-name {
				a {
					color: @text-color;
					font-size: 13px;
					
					.x-content & {
						color: #fff;
					}
				}
			}
			
			
			.profile-info {
				font-size: 12px;
				
				.x-content & {
					color: #fff;
				}
			}
			
			.profile-counts {
				height: auto;
				font-size: 14px;
				line-height: 18px;
				
				.x-content & {
					background: rgba(222, 38, 0, 0.85);
				}
			}
			
			.profile-days-ago {
				font-size: 12px;
				color: @gray;
			}
			
			.action-mobile-btn {
				margin: 4px;
				white-space: normal;
			}
			
			.action-desktop-btn {
				border-color: @thumb-block-thumb-border;
				border-radius: 0;
				color: @text-color;
				margin: 0;
				position: absolute;
				top: 2px;
				right: 2px;
				transition-property: top;
				transition-duration: .25s;
				
				&.read {
					top: -50%;
				}
				
				&.wait {
					& > *:not(.loader) {
						display: none;
					}
				}
				
				.icf-user {
					font-size: 20px;
				}
				
				.small-icf {
					font-size: 13px;
					left: 52%;
					position: absolute;
					top: 52%;
					.text-border-1px(@btn-default-bg);
				}
			}
			
			.success-message {
				background: white;
				border-bottom: 1px solid @thumb-block-thumb-border;
				left: 3px;
				line-height: 14px;
				padding: 2px;
				position: absolute;
				top: 3px;
				right: 3px;
				transition-property: top;
				transition-duration: .25s;
			}
			
			&.success {
				.thumb,
				.profile-name,
				.profile-info {
					opacity: 0.2;
				}
				
				.profile-days-ago {
					visibility: hidden;
				}
			}
			
			&.success-read {
				.success-message {
					top: -50%;
				}
			}
			
			&:hover {
				.action-desktop-btn {
					top: 2px;
				}
				
				.success-message {
					top: 3px;
				}
			}
		}
	}
}


.thumb-under-2-lines() {
	.thumb-under {
		min-height: 56px;
		
		&.title-only {
			min-height: 38px;
			p {
				&.title,
				&:first-child {
					height: auto;
					max-height: 38px;
				}
			}
		}
		p {
			&.title,
			&:first-child,
			&:first-of-type {
				height: auto;
				max-height: 36px;
				white-space: normal;
				.line-clamp(2);
			}
		}
	}
}
.thumb-under-1-line() {
	.thumb-under {
		min-height: 38px;
		
		p {
			&.title,
			&:first-child,
			&:first-of-type {
				height: 18px;
				max-height: 18px;
				.line-clamp(1);
			}
		}
	}
}
.thumb-under-n-lines() {
	.thumb-under {
		&, &.title-only {
			min-height: 0;
		}
		p {
			&.title,
			&:first-child,
			&:first-of-type {
				height: auto;
				max-height: none;
				.no-line-clamp-block();
			}
		}
	}
}

.thumb-block-duration() {
	display: inline-block;
	background: @gray-light;
	border-radius: 3px;
	color: @text-invert-color;
	font-size: 0.83em;
	height: 18px;
	line-height: 18px;
	margin-left: 4px;
	padding: 0 3px;
	position: relative;
	vertical-align: top;
	z-index: 3;
}

.nb-thumbs-cols-100( @iImgWidthPercent, @iMiddleSpace, @iFontSize ) {
	//&:not(.favlist-elem) {
	&:not(.thumb-nat-exo-ad) {
		align-items: center;
		display: flex;
		float: none;
		font-size: 0;
		margin: 0 auto;
		max-width: 1000px;
		padding: 10px 8px 0;
		width: 100%;
		white-space: nowrap;
		
		.thumb-inside,
		.thumb-under {
			display: inline-block;
			font-size: initial;
			margin: 1% 0;
			white-space: normal;
		}
		
		.thumb-inside {
			padding: 0 0 unit((9/16*100*@iImgWidthPercent), %);
			vertical-align: top;
			width: unit((100*@iImgWidthPercent), %);
		}
	
		.thumb-under {
			margin-left: unit(@iMiddleSpace, px);
			padding: 0;
			text-align: left;
			vertical-align: top;
			width: unit((100-100*@iImgWidthPercent), %);
			
			p {
				font-size: unit((@iFontSize/1.3), px);
				line-height: unit((@iFontSize/1.125), px);
				
				&.title,
				&:first-child,
				&:first-of-type {
					max-height: none;
					margin: 0 0 unit((@iMiddleSpace/4), px);
					padding: 0;
					white-space: normal;
					
					a {
						font-size: unit(@iFontSize, px);
						.line-clamp(3);
						line-height: unit((@iFontSize * 1.2), px);
						padding: 0;
						
						.duration {
							display: inline-block;
							float: none;
							font-size: 0.75em;
							vertical-align: text-bottom;
						}
					}
				}
				
				&.metadata {
					.bg {
						padding: 2px 0;
						
						span {
							vertical-align: top;
						}
						
						.duration {
							display: none;
						}
						
						.icf-ticket-red {
							margin-left: 4px;
						}
						
						a {
							.icon-f {
								line-height: unit((@iFontSize/1.125), px);
							}
						}
						
						.video-hd-mark,
						.video-sd-mark {
							height: unit((@iFontSize/1.125), px);
							line-height: unit((@iFontSize/1.125), px);
							margin-left: 6px;
						}
					}
				}
				
				.sprfluous {
					display: none;
				}
			}
		}
	}
	&.thumb-nat-exo-ad {
		padding: 10px 8px 0;
		float: none;
		margin: 0 auto;
		max-width: 280px;
		width: 50%;
	}
}

.thumb-block {
	border-bottom: @video-thumb-border-bottom;
	float: left;
	padding: @video-thumb-padding-top @video-thumb-padding-right @video-thumb-padding-bottom @video-thumb-padding-left;
	position: relative;
	text-align: center;

	&[data-is-ppv]:not([data-is-membership]):not([data-is-premium]) {
		/* .thumb-inside .video-hd-mark,
		.thumb-inside .video-sd-mark {
			display: none;
		} */
		.thumb-inside .is-ppv-mark {
			display: block;
			
		}
	}
	
	.thumb-over {
		height: 28px;
		
		.icf-ticket-left-side {
			float: left;
			height: 24px;
			overflow: hidden;
			width: 5px;
			
			.icf-ticket-wide {
				color: @thumb-block-thumb-over-bg;
				font-size: 26px;
				float: left;
				margin-top: -1px;
			}
		}
		
		a {
			display: block;
			margin-bottom: 4px;
			overflow: hidden;
			text-decoration: none;
			text-overflow: ellipsis;
			white-space: nowrap;
			
			span {
				background: @thumb-block-thumb-over-bg;
				color: #fff;
				display: block;
				float: left;
				font-weight: bold;
				line-height: 24px;
				max-width: 80%;
				padding: 0 0 0 3px;
				position: relative;
				text-align: left;
			}
			
			&:after {
				background-image: linear-gradient(to right, @thumb-block-thumb-over-bg 0%, transparent 100%);
				content: " ";
				display: block;
				float: left;
				height: 24px;
				width: 20%;
			}
		}
		
		button {
			&.sub {
				background: @thumb-block-thumb-over-bg;
				border: 0;
				color: #fff;
				height: 24px;
				line-height: 24px;
				float: left;
				margin: 0;
				overflow: hidden;
				padding: 0;
				transition: 0.2s all;
				width: 0;
				
				&.sub-checked {
					width: 21px;
					padding-right: 5px;
					
					* {
						opacity: 1;
						transition: 0.2s 0.2s opacity;
					}
					
					& + a {
						max-width: calc(~'100% - 28px');
						
						span:hover {
							text-decoration: underline;
						}
					}
				}
				
				.icon-f {
					float: right;
				}
				
				* {
					opacity: 0;
					vertical-align: middle;
				}
			}
			
			&:active, &:focus, &active:focus {
				outline: none;
			}
		}
	}
	
	.thumb-inside {
		display: block;
		height: 0;
		margin-bottom: 2px;
		overflow: hidden;
		padding: 0 0 56.25%;
		position: relative;
		
		.cover-thumb {
			background: rgba(0, 0, 0, 0.1);
			height: 100%;
			left: 0;
			padding: 3%;
			position: absolute;
			top: 0;
			width: 100%;
		}
		
		.video-sd-mark,
		.video-hd-mark,
		.video-viewed {
			position: absolute;
			right: 5px;
			top: 5px;
			vertical-align: baseline;
			.xv-label-mixin(18px);
			z-index: 1;
		}
		
		.top-left-tags {
			padding: 0;
			position: absolute;
			left: 5px;
			top: 5px;
			z-index: 1;
		}
		
		.top-right-tags {
			padding: 0;
			position: absolute;
			right: 5px;
			top: 5px;
			z-index: 1;
		}
		
		.bottom-right-tags {
			padding: 0;
			position: absolute;
			right: 5px;
			bottom: 5px;
			z-index: 1;
		}
		
		.is-ppv-mark {
			.xv-label-mixin(16px);
			background: #fff;
			color: #000;
			display: none;
			vertical-align: top;
			
			.is-ppv-only & {
				display: block;
			}
		}
		
		.is-membership-mark {
			color: #fff;
			display: block;
			text-align: right;
			font-size: 20px;
			height: 22px;
			line-height: 22px;
			text-shadow: 0 0 5px fadeOut(#000, 50%);
			vertical-align: top;
		}
		
		.video-sd-mark,
		.video-hd-mark,
		.is-membership-mark {
			.is-ppv-only [data-is-ppv]& {
				display: none;
			}
		}
		
		.video-viewed {
			width: 22px;
			background: #000;
			background: rgba(0, 0, 0, 0.5);
			
			.icon-f {
				position: absolute;
				top: 50%;
				left: 5px;
				transform: translateY(-50%);
			}
		}
		
		.watch-later {
			display: none;
		}
		
		.icf-player-play-circle-o {
			color: rgba(255, 255, 255, 0.7);
			font-size: 60px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		.icf-ticket-red {
			color: @theme-color;
			display: inline-block;
			font-size: 28px;
			height: 29px;
			margin: -7px 0;
			vertical-align: text-top;
		}
	}
	
	&.tbv-init-ok {
		.thumb-inside {
			.video-sd-mark,
			.video-hd-mark {
				display: none;
			}
		}
	}
	
	.thumb-block-size-mixin(4);
	
	.thumb-under {
		p {
			font-size: 13px;
			
			&.title,
			&:first-child,
			&:first-of-type {
				a {
					font-size: 1.1em;
					
					.duration {
						display: none;
					}
				}
			}

			.icon-f {
				color: @text-color;
			}
			
			.icf-ticket-red {
				color: @theme-color;
				display: inline-block;
				font-size: 28px;
				height: 29px;
				margin: -7px 0;
				vertical-align: bottom;
			}
			
			.is-purchased-mark {
				color: @gray-light2;
				display: none;
				margin-right: 4px;
				vertical-align: middle;
				
				.is-lock-unlock-mixed & {
					display: inline;
				}
			}
		}
		
		&.title-only {
			.duration {
				margin-right: 3px;
				vertical-align: middle;
			}
		}
	}
	
	.thumb-under-2-lines();
	
	p {
		color: @text-color;
		font-size: 12px;
		margin: 0;
		height: 18px;
		line-height: 18px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		
		&.title,
		&:first-child,
		&:first-of-type {
			a {
				color: @text-color;
				text-decoration: none;
			}
		}
		
		&.metadata {
			height: 20px;
			color: @gray;
			
			.bg {
				display: inline-block;
				max-width: 100%;
				overflow: hidden;
				padding: 2px 0 0;
				text-overflow: ellipsis;
				
				.duration {
					& + span {
						overflow: hidden;
						text-overflow: ellipsis;
						vertical-align: top;
					}
				}
			}
			
			a {
				color: @gray;
				font-weight: normal;
				text-decoration: none;
				
				.name {
					text-decoration: underline;
				}

				.name-italic {
					font-style: italic;
				}
				
				.is-desktop &:hover {
					.name {
						text-decoration: none;
					}
				}
				
				.icon-f {
					margin-left: 4px;
					line-height: 18px;
					vertical-align: bottom;
				}
				
				&.from-uploader {
					color: @red-color;
					font-weight: bold;
					text-decoration: none;
				}
			}
		}
		
		&.name {
			.icon-f {
				font-size: 18px;
				vertical-align: middle;
			}
		}
		
		.video-hd-mark, .video-sd-mark {
			font-size: .82em;
			font-weight: normal;
			height: 18px;
			line-height: 18px;
			padding: 0 3px;
			position: relative;
			vertical-align: top;
			z-index: 3;
		}
	}
	
	.duration {
		.thumb-block-duration();
		
		&:first-child {
			float: right;
		}
	}
	
	.mozaique.thumbs-5-cols & {
		.thumb-block-size-mixin(4);
		
		vertical-align: top;
		.thumb-under {
			min-height: 56px;
			
			p {
				font-size: 13px;
				
				&:first-child {
					height: auto;
					max-height: 36px;
					white-space: normal;
				}
			}
		}
	}
	
	.thumbs-small & {
		.any-col-config({
			.thumb-block-size-mixin(8);
		});
		background: none;
		overflow: hidden;
		padding: 2px;
		
		.flag-small {
			top: auto;
			bottom: 4px;
		}
		
		.profile-counts {
			background: #000;
			background: fadeout(#000, 15%);
			bottom: -50%;
			color: #fff;
			left: 2px;
			position: absolute;
			right: 2px;
			padding: 3px;
			transition-property: bottom;
			transition-duration: .25s;
			z-index: 2;
			
			#page #main & {
				height: auto;
			}
		}
		
		.is-desktop &:hover {
			.profile-counts {
				bottom: 0;
			}
		}
	}
	
	&.rotator-started .thumb-inside,
	&.show-tb-menu .thumb-inside,
	.is-desktop &:not(.post-elem) .thumb-inside:hover {
		.video-sd-mark,
		.video-hd-mark,
		.video-viewed,
		.top-right-tags,
		.icf-player-play-circle-o {
			display: none;
		}
	}
	
	&.premium-search-on-free {
		&.rotator-started,
		&.show-tb-menu,
		.is-desktop .thumb-inside:hover{
			.thumb-under {
			
			}
		}
	}
	
	.microthumb {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding-bottom: 56.25%;
		background: @img-border-color;
		overflow: hidden;
		.microthumb-thumb {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background-size: 400%;
			background-repeat: no-repeat;
			filter: blur(3px);
		}
		.microthumb-border {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
		}
	}
	
	.thumb,
	.thumb-purchase {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		a {
			border: 1px solid @thumb-block-thumb-border;
			display: block;
			min-height: 99%;
			position: relative;
			overflow: hidden;
			zoom: 1;
		}
		&.verified-pic a {
			border-color: @theme-color;
		}
		img {
			vertical-align: bottom;
			width: 100%;
			&.profile-pic {
				background: #000;
				padding: 0 21.875%;
			}
			&.video-pic {
				background: #000;
				padding: 21.875% 0;
			}
			&.top-left,
			&.top-right,
			&.bottom-left,
			&.bottom-right {
				float: left;
				height: 50%;
				width: 50%;
			}
		}
	}
	
	.gif-tag {
		display: block;
		position: absolute;
		left: 3px;
		top: 3px;
		background: @theme-color;
		color: #fff;
		font-weight: bold;
		text-align: center;
		border-radius: 1px;
		.xv-label-mixin(14px);
		vertical-align: baseline;
	}
	
	.actions {
		opacity: 0.75;
		position: absolute;
		top: 3px;
		left: 3px;
		transition: opacity 0.5s;
		.notouch & {
			opacity: 0;
		}
		.btn-action {
			background: fadeout(#000, 50%);
			clear: both;
			color: #fff;
			cursor: pointer;
			display: block;
			font-size: 16px;
			font-weight: bold;
			height: 24px;
			line-height: 24px;
			margin: 0 0 4px;
			width: 24px;
			border-radius: 2px;
			&:hover {
				background: fadeout(@red-color, 20%);
			}
			&.selected {
				background: @red-color;
			}
			.icf-flag,
			.icf-trash {
				float: left;
				line-height: 24px;
				width: 24px;
			}
		}
	}
	.notouch &.selected .actions,
	.notouch &:hover .actions {
		opacity: 1;
	}
	.flag-small,
	.flag {
		position: absolute;
		top: 0;
		left: 3px;

		&.flag-question,
		&.flag-p1,
		&.flag-p2,
		&.flag-p4,
		&.flag-p5,
		&.flag-p6,
		&.flag-p7,
		&.flag-p8,
		&.flag-p9,
		&.flag-m1,
		&.flag-s1 {
			margin: 5px 0 0 1px;
		}

		&.flag-p3 {
			top: 6px;
		}

		&.secondary-flag {
			top: 25px;

			&.flag-question,
			&.flag-p1,
			&.flag-p2,
			&.flag-p4,
			&.flag-p5,
			&.flag-p6,
			&.flag-p7,
			&.flag-p8,
			&.flag-p9,
			&.flag-m1,
			&.flag-s1 {
				top: 26px;
			}

			&.flag-p3 {
				top: 30px;
			}
		}
	}
	.video-new-mark {
		.xv-label-mixin(14px);
		position: absolute;
		top: 3px;
		left: 3px;
		background: @theme-color;
		color: #fff;
		font-weight: bold;
		text-align: center;
		border-radius: 1px;
		vertical-align: baseline;
	}
	.video-debug {
		position: absolute;
		top: 0;
		left: 3px;
		background-color: #fff;
		border: 1px #000 solid;
		padding: 4px;
		color: #000;
	}
	
	&.thumb-ad {
		p.metadata .duration {
			background: @theme-color;
			color: #fff;
			font-weight: bold;
		}
	}
	
	body .mozaique &.thumb-block-profile {
		.thumb-under-1-line();
		
		.thumb-under {
			.profile-pic {
				& ~ .profile-name,
				& ~ .profile-counts {
					padding-right: 34px;
				}
			}
		}
		
		&.title-in-thumb {
			&:last-child {
				margin-bottom: 15px;
			}
			.profile-name {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding: 20px 42px 0 42px;
				text-align: center;
				text-overflow: ellipsis;
				.text-border-1px(fadeout(@premium-feed-account-profile-name-bg, 50%));
				font-size: 16px;
				font-weight: bold;
				line-height: 20px;
				white-space: nowrap;
				color: @premium-feed-account-profile-name-color;
				background-image: linear-gradient(0deg, @premium-feed-account-profile-name-bg 0%, transparent 100%);
				overflow: hidden;
			}
			
			.thumb-under {
				min-height: inherit;
				
				.profile-pic {
					margin-top: -22px;
					margin-left: 5px;
					z-index: 1;
					
					& ~ .profile-counts {
						padding-right: 39px;
					}
				}
			}
		}
	}
	
	&.thumb-block-big-button {
		.big-button-container {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
		}
		
		.thumb-inside {
			a {
				align-items: center;
				background: @red-color;
				border: 1px solid @thumb-block-thumb-border;
				color: #fff;
				display: flex;
				font-size: 18px;
				font-weight: bold;
				height: 100%;
				line-height: 30px;
				justify-content: center;
				text-align: center;
				text-decoration: none;
				
				.is-desktop &:hover {
					background: darken(@red-color, 10%);
					text-decoration: none;
				}
				
				.icon-f {
					float: left;
					line-height: 30px;
					padding-right: 5px;
				}
				
			}
		}
		
		&.thumb-block-big-button-arrow-right {
			.thumb-inside {
				a {
					border: 0;
					padding: 0 8px;
					margin-right: 20px;
					
					&:after {
						background: @red-color;
						bottom: 0;
						clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
						content: "";
						display: block;
						position: absolute;
						right: 0;
						top: 0;
						width: 21px;
					}
					&:hover {
						&:after {
							background: darken(@red-color, 10%);
						}
					}
				}
			}
		}
	}
	
	.videos_ilike & {
		&.voted-bad,
		&.voted-null {
			.thumb-under,
			.thumb-inside > * {
				opacity: 0.2;
			}
			.thumb-inside {
				.action-menu {
					opacity: 1;
				}
			}
		}
	}
	
	&.premium-search-on-free {
		border-bottom: 0;
		
		.thumb-inside {
			.duration {
				background: #4a4a4a;
				font-size: 0.95em;
				left: 5px;
				margin-left: 0;
				position: absolute;
				top: 5px;
			}
		}
		.thumb-under {
			background: rgba(0, 0, 0, 0.8);
			bottom: @video-thumb-padding-bottom;
			left: @video-thumb-padding-left;
			min-height: 0!important;
			padding: 2px 4px;
			position: absolute!important;
			width: calc(~"100% - @{video-thumb-padding-left} - @{video-thumb-padding-right}");
			
			p.title {
				&,
				a {
					color: #fff;
				}
			}
		}
	}
}

.favlist-elem .thumb-inside,
.thumb-block .thumb-inside {
	.action-menu {
		background: #000;
		background: rgba(0, 0, 0, 0.6);
		border: 0;
		border-radius: 3px;
		color: #eee;
		height: 30px;
		line-height: 30px;
		font-size: 16px;
		padding: 0;
		position: absolute;
		right: 5px;
		text-align: center;
		top: 5px;
		width: 30px;
		z-index: 2;
		
		.icon-f {
			line-height: 30px;
			vertical-align: middle;
			
			&, &.open {
				display: block;
			}
			
			&.close {
				display: none;
			}
		}
		
		&, &.disabled {
			display: none;
		}
	}
	
	
	
	&.show-tb-menu .thumb-inside .action-menu,
	.is-desktop & .thumb-inside .action-menu:hover,
	&.menu-opened .thumb-inside .action-menu {
		display: block;
		top: 5px;
	}
	&.menu-opened .thumb-inside .action-menu {
		.open {
			display: none;
		}
		.close {
			display: block;
		}
	}
}

.thumb-block .thumb,
.thumb-block .thumb-purchase,
#video-premium-alert .video {
	.videopv {
			&,
			& a {
				border: 0;
				left: 0;
				padding: 0 0 56.25%;
				position: absolute;
				top: 0;
				width: 100%;
			}
			
			video,
			canvas {
				border: 0;
				width: 100%;
				height: 100%;
			}
			
			.progress {
				background: #000;
				bottom: 0;
				height: 2px;
				left: 0;
				margin-bottom: 0;
				position: absolute;
				right: 0;
				width: 100%;
				
				.bar {
					background: @theme-color;
					height: 2px;
					width: 0;
				}
			}
}
	img {
		&.hovered {
			display: block;
			box-sizing: content-box;
			position: absolute;
			width: 300%;
			height: 300%;
			top: 0;
			left: 0;
			margin-left: 0;
			margin-top: 0;
		}
	}
}

.tb-menu {
	z-index: @zindex-popup - 1;
	
	&.x-popup.below.right {
		.x-popup-arrow {
			right: 10px;
		}
	}
	.x-popup-content {
		padding: 4px 6px;
		max-width: 300px;
	}
	
	ul {
		margin: 0;
		padding: 0;
	}
	
	li {
		margin: 0 0 4px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		ul {
			margin-left: 8px;
		}
	}
	.share-toolbox {
		text-align: center;
		
		li {
			margin: 0 5px;
		}
		
		&:last-child {
			margin-bottom: 10px;
		}
	}
	
	a {
		border-radius: 3px;
		text-decoration: none;
		
		&:hover {
			background: @tooltip-bg-lighten;
		}
	}
	
	a,
	p,
	.tb-menu-share-title {
		display: block;
		font-size: 14px;
		line-height: 22px;
		padding: 4px 8px;
		text-transform: lowercase;
		
		.icon-f {
			display: inline-block;
			line-height: 22px;
			margin: 0 4px 0 0;
			text-align: center;
			vertical-align: top;
			width: 24px;
		}
	}
	
	.tb-menu-share-title {
		border-top: 1px solid fadeOut(#fff, 90%);
		cursor: default;
		margin-top: 10px;
		padding-top: 14px;
		
		strong {
			cursor: pointer;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	
	.tb-menu-share-legend {
		display: block;
		font-size: 12px;
		opacity: 0.8;
		padding: 4px 8px 2px;
	}
	
	.vote-actions {
		margin-bottom: 8px;
		margin-top: 8px;
		overflow: hidden;
		
		&:last-child {
			margin-bottom: 4px;
		}
		
		a {
			background: lighten(@tooltip-bg-lighten, 5%);
			border: 0;
			float: left;
			margin: 0 5%;
			width: 40%;
			
			&.active {
				color: @red-color;
			}
		}
	}
	
	.video-viewed {
		opacity: 0.6;
	}
	
	.link-go-to {
		a {
			.icf-arrow-right {
				transition: transform 0.1s;
			}
			
			&:hover {
				.icf-arrow-right {
					transform: translateX(2px);
				}
			}
		}
	}
}

.watch-later-tb-menu {
	z-index: @zindex-popup - 1;
	
	.x-popup-arrow {
		display: none;
	}
}

#best-months,
#date-links-pagination {
	padding: 0;
	margin: 0 0 8px;
	position: relative;
	line-height: 2;
}

#play-all-rand {
	line-height: 16px;
	margin-bottom: 0;
	overflow: hidden;
	white-space: normal;
	
	span {
		float: left;
		line-height: 16px;
		margin: 0 4px 0 0;
		vertical-align: middle;
	}
	
	&:hover {
		span {
			text-decoration: underline;
		}
		
		&,
		.icon-f {
			text-decoration: none;
		}
	}
}

.profiles-shortcuts {
	margin: 0;
	overflow: hidden;
	padding: 10px 0 0;
	
	li {
		float: left;
		line-height: 14px;
		margin: 0 3px 1px 0;
		padding: 2px 4px;
		
		a {
			color: @text-color;
			text-decoration: none;
			span {
				color: @gray-light;
				font-size: 0.9em;
			}
			&:hover {
				text-decoration: underline;
			}
		}
		.icon-f {
			&, &:before {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}

.simple-dropdown.premium-switch-page-dropdown {
	display: flex;
	align-items: stretch;
	.niv1 .show-niv2 {
		flex-direction: row;
	}
}

#feed-videos-filters .simple-dropdown .show-niv2 {
	flex-direction: row-reverse;
	&.is-reset {
		flex-direction: row;
	}
}

#feed-videos-filters .simple-dropdown .show-niv2,
.search-page .simple-dropdown .show-niv2 {
	flex-direction: row-reverse;
	&.is-reset {
		flex-direction: row;
	}
}

.primary-premium-buttons {
	width: fit-content;
}

.simple-dropdown {
	font-size: 12px;
	overflow: hidden;
	position: relative;
	z-index: 10;
	&.align-left {
		float: left;
		margin-top: 12px;
		margin-right: 12px;
	}
	
	.niv1 {
		display: flex;
		float: left;
		padding: 0 3px;
		position: relative;
		
		&:first-child {
			padding: 0;
		}
		&.with-niv2 {
			.show-niv2 {
				cursor: pointer;
				&.premium-switch-page {
					padding-right: 6px;
				}
			}
		}
		a.show-niv2 {
			&:hover {
				text-decoration: underline;
			}
		}
		.show-niv2,
		a {
			cursor: pointer;
			float: left;
			line-height: 22px;
			margin: 0 4px 0 0;
			padding: 4px 2px;
			text-decoration: none;
			white-space: nowrap;
		}
		.show-niv2 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.icf-caret-down {
				opacity: 0.2;
				margin-right: -2px;
				padding-left: 2px;
			}
			&.init-ok {
				.icf-caret-down {
					opacity: 1;
					transition: 0.2s all;
				}
			}
			&.current {
				span:first-child{
					font-weight: bold;
					text-decoration: underline;
				}
			}
			&.premium-switch-page {
				background: @theme-color;
				border-radius: 3px;
				color: #fff;
				font-weight: bold;
				padding: 4px 10px 4px;
				&[data-page="free"] {
					cursor: default;
				}
			}
		}
		a {
			&.next,
			&.prev {
				border-radius: 3px;
				font-size: 18px;
				&:hover {
					background-color: @gray-lighter;
				}
			}
		}
		.niv2 {
			display: none;
		}
	}
	.sep {
		float: left;
		line-height: 32px;
	}
	.total-videos {
		float: right;
		line-height: 16px;
		padding: 7px 0;
	}
	.eventslist & {
		float: left;
		margin-top: 5px;
	}
	& + .profiles-shortcuts {
		padding-left: 5px;
	}
}

#premium-listing-date-pagin {
	font-size: 14px;
	margin: 10px 0;
	width: 100%;
}

.simple-dropdown-pop {
	box-shadow: 5px 5px 5px -5px rgba(0,0,0,0.5);
	margin-left: 2%;
	padding-bottom: 0;
	.x-popup-content {
		padding: 8px 0;
		max-width: 100%;
	}
	&.current {
		.niv2 {
			ul {
				a {
					&.current {
						background: @tooltip-bg-lighten;
					}
				}
			}
		}
	}
	.niv2 {
		.uls {
			overflow: auto;
			white-space: nowrap;
			max-width: 100%;
		}
		ul {
			margin: 0;
			padding: 0;
			vertical-align: top;
			&.hidden-pagin {
				display: none;
				
				&.main-pagin {
					display: inline-block;
				}
			}
			li {
				padding: 0 4px 3px;
			}
			a {
				display: block;
				padding: 6px 14px;
				text-decoration: none;
				&:hover {
					background: @tooltip-bg-lighten;
				}
				&.current {
					font-weight: bold;
				}
			}
		}
		&.see-all {
			ul {
				&.hidden-pagin {
					display: inline-block;
				}
			}
		}
		.see-all {
			display: block;
			margin: 8px auto 0;
			max-width: 300px;
			width: 90%;
		}
	}
}

.videos-by-profile {
	.activity-event {
		.header{
			padding: 4px 8px;
			h4 {
				height: 22px;
				line-height: 22px;
				margin: 0;
				
				.tag {
					color: @red-color;
					font-weight: bold;
					padding: 0 2px;
				}
			}
			.sub-strip{
				.button-variant(#fff; @theme-color; darken(@theme-color, 10%));
				float: right;
				height: 22px;
				padding: 0 4px;
				&, * {
					color: #fff;
					font-size: 14px;
					line-height: 20px;
				}
				* {
					height: 20px;
				}
			}
		}
	}
	&.video-suggest {
		.activity-event {
			.header {
				position: relative;
				* {
					position: relative;
					z-index: 2;
				}
				&:after {
					background-image: linear-gradient(to right, @gray-lighter 0%, @body-bg 90%);
					bottom: 0;
					content: " ";
					max-width: 20%;
					position: absolute;
					right: 0;
					top: 0;
					width: 120px;
					z-index: 1;
				}
			}
		}
	}
}

// 767px max
@media (max-width: @screen-xs-max) {
	.simple-dropdown {
		.membership-button.niv1.with-niv2 {
			padding: 0px;
		}

		.primary-premium-buttons {
			width: max-content;
		}

		.library {
			float: inline-start;
			padding: 0px;
		}

		&.is-expand {
			.membership-button.niv1.with-niv2 {
				
			}
		}
	}
}