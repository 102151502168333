#browser-too-old {
	background: #fef8ea;
	border-bottom: 2px solid #bfb471;
	color: #000;
	padding: 20px;
	margin: 0 0 10px;
	position: relative;
	z-index: 99999;
	text-align: center;
	width: 100%;
	* {
		font-size: 16px;
	}
	p + p {
		margin-top: 20px;
	}
}