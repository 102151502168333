@import "./../../../common/form/models-finder";
.video-subscribe,
.video-subscribe-square {
	display: inline-block;
	border: 1px solid @red-color;
	background: @red-color;
	font-weight: bold;
	color: #fff;
	text-decoration: none;
	font-size: 13px;
	line-height: 15px;
	transition: border-color 0.3s, background-color 0.3s;
}

.video-subscribe .sub-btn,
.video-subscribe-square .sub-btn,
.video-subscribe-square .x-loader-inline {
	display: inline-block;
	background: #fff;
	padding: 1px 3px 1px 4px;
	margin: 1px 2px 1px 1px;
	line-height: 13px;
	border-radius: 1px;
	transition: background-color 0.3s;
	}

.video-subscribe-square {
	height: auto;
	
	.sub-btn {
		margin: 1px;
		height: auto;
	}
	.x-loader-inline {
		width: 23px;
		height: 18px;
		padding-top: 2px;
	}
}

.video-subscribe {
	.label:last-child {
		padding-right: 3px;
	}
	.nb-subscribers {
		display: inline-block;
		background: #fff;
		color: #000;
		padding: 1px 2px;
		font-weight: normal;
		margin-left: 1px;
	}
	a {
		color: #fff;
	}
	&:hover {
		text-decoration: none;
		color: #fff;
		border-color: lighten(@red-color, 5%);
		background: lighten(@red-color, 5%);
		.sub-btn {
			color: lighten(@red-color, 5%);
		}
		.label {
			text-decoration: underline;
		}
	}
}
#metadata_suggestion_popup_opener {
	font-size: 14px;
	margin-top: 5px;
	vertical-align: middle;
	text-decoration: none;
	color: @darker-base-text;
}

#html5video {
	&,
	* {
		box-sizing: content-box;
	}
	
	.video-title {
		.icf-ticket-red {
			color: @red-color;
			display: inline-block;
			font-size: 2em;
			line-height: inherit;
			vertical-align: top;
		}
	}
	
	#hlsplayer {
		.video-ended-desktop {
			button {
				&.active {
					.icon-f {
						color: @red-color;
					}
				}
			}
		}
	}
	
	
	#centered-full-video-link {
		background-position: center;
		background-size: cover;
		box-sizing: border-box;
		
		&:before{
			background: rgba(0,0,0,0.5);
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
		}
		
		a {
			position: relative;
			z-index: 2;
		}
		
		a.is-xv {
			color: #fff;
			max-width: 100%;
			
			&:not(.more-at) {
				background: transparent;
				border: 0;
				padding: 0 40px;
			}
			
			&.more-at {
				.logo {
					display: block;
					margin: 0 auto;
					padding-top: 4px;
					width: 180px;
				}
			}
			
			.ticket {
				display: flex;
				font-size: 24px;
				line-height: 30px;
				max-width: 100%;
				min-height: 110px;
				padding: 10px 14px;
				position: relative;
				align-items: center;
				
				&::before,
				&::after {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					width: 24px;
					background-image: url('../../img/skins/default/ticket-red.svg');
					background-size: cover;
				}
				
				&::before {
					right: 100%;
					background-position: top left;
				}
				
				&::after {
					left: 100%;
					background-position: top right;
				}
				
				background: @red-color;
				
				&:hover {
					background: #B21E00;
					&::before,
					&::after {
						filter: brightness(0.8);
					}
				}
				
				.logo {
					display: block;
					overflow: hidden;
					
					img {
						margin: -8px auto;
						height: 64px;
					}
				}
			}
		}
	}
}


#page.video-page {
	h1.text-danger {
		margin: 30px 0;
		text-align: center;
	}
	#main {
		.page-title {
			.icf-refresh {
				color: @gray;
				cursor: pointer;
				display: inline-block;
				font-size: 60%;
				line-height: inherit;
				margin-right: 4px;
				vertical-align: middle;
			}
			.duration {
				display: inline-block;
				.xv-label-mixin(22px);
				background: @gray-light;
				border-radius: 3px;
				color: @text-invert-color;
				font-weight: bold;
				margin: 5px 0 0 4px;
				vertical-align: top;
			}
			.video-hd-mark {
				margin: 5px 0 0 4px;
				.xv-label-mixin(22px);
				vertical-align: top;
			}
		}
	}
	.video-tags-list {
		margin: 0 2px;
		&.cropped {
			max-height: 60px;
			&.opened {
				max-height: none;
			}
		}
		overflow: hidden;
		
		.view-more-li {
			display: none;
		}
	}

	.video-metadata {
		a.added {
			font-style: italic;
		}
		padding: 0;
		margin: 0 2px;
		font-size: 13px;
		.uploader {
			padding-right: 20px;
		}
		.fan-video {
			margin: 0 0 0 3px;
		}
	}
	#full-video-link-band,
	#video-sponsor-links {
		background: @video-sponsor-bg;
		color: @text-invert-color;
		font-size: 14px;
		font-weight: bold;
		line-height: 22px;
		overflow: hidden;
		max-height: 52px;
		position: relative;
		text-align: center;
		text-overflow: ellipsis;
		a {
			color: #fff;
			text-decoration: underline;
		}
		.channel-desc {
			font-size: 13px;
			font-weight: normal;
		}
		#show-channel-desc {
			background: @video-sponsor-bg;
			bottom: 0;
			color: @text-invert-color;
			display: none;
			font-weight: normal;
			line-height: 22px;
			padding: 0 4px 0 2px;
			position: absolute;
			right: 0;
			text-decoration: none;
			span {
				display: inline-block;
				background: lighten(@gray-light, 15%);
				border-radius: 3px;
				color: #fff;
				font-size: 20px;
				font-weight: bold;
				height: 18px;
				line-height: 19px;
				margin: 0 0 0 4px;
				text-align: center;
				transition: all 0.2s;
				width: 18px;
				&:hover {
					background: lighten(@gray-light, 5%);
				}
				&:active,
				&:focus {
					box-shadow: 0 0 0 3px lighten(@gray-light, 35%);
				}
			}
		}
		&.too-small {
			#show-channel-desc {
				box-shadow: -3px 3px 5px @video-sponsor-bg;
				display: block;
			}
		}
		&.expanded {
			max-height: none;
			#show-channel-desc {
				display: none;
			}
		}

		&.sheer-sponsor-link {
			.sponsor-text {
				padding: 2px;
				.sheer-bg {
					display: inline;
					// background: @video-sponsor-sheer-bg;
					padding: 6px;
				}
	
				strong {
					text-transform: uppercase;
				}
			}
		}
	}
	
	#full-video-link-band {
		margin-bottom: 6px;
		
		&.v1-btn {
			background: @red-color;
			color: #fff;
			font-size: 14px;
			text-decoration: none;
			
			.icf-ticket-red {
				color: #fff;
			}
		}
		
		&.v2-txt {
			background: transparent;
			color: @text-color;
		}
		
		&.v3-txt {
			background: transparent;
			color: @red-color;
			
			.icf-ticket-red {
				color: @red-color;
			}
		}
		
		strong {
			text-decoration: underline;
			vertical-align: unset;
		}
		
		.icf-ticket-red {
			font-size: 30px;
			line-height: 22px;
			margin-left: 3px;
			text-decoration: none;
			vertical-align: bottom;
		}
	}
	
	#content {
		margin-top: 10px;
		overflow: visible;
		position: relative;
		z-index: 1;
	}
	#video-right {
		float: right;
		margin-bottom: 10px;
		margin-left: 16px;
		min-height: 1px;
		position: relative;
		text-align: center;
		width: 300px;
	}
	.live_model_thumb {
		position: relative;

		img {
			display: block;
			width: 100%;
		}
		
		&:hover {
			img {
				box-shadow: 0 0 0 2px #fff;
			}
		}

		video {
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;	
			position: absolute;
			width: 100%;		
		}
	}
	#video-player-bg {
		overflow: hidden;
		background: #000;
		#html5video.embed-responsive,
		#player {
			overflow: hidden;
			text-align: center;
			color: #000;
			line-height: 0;
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 80.95%; // Ensure aspect ratio with .embed-responsive
			.embed-responsive-item {
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% auto;
			}
			.old-ie & {
				height: 476px;
				padding-bottom: 0;
				embed,
				.embed-responsive-item {
					height: 476px;
					position: static;
				}
			}
		}
	}
	.player-enlarged {
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				height: auto;
				padding-bottom: 40%;
				@media (max-width: @screen-max) {
					.embed-responsive-item {
						background-size: auto 100%;
					}
				}
				@media (min-aspect-ratio: ~"1280/840") {
					.embed-responsive-item {
						background-size: 100% auto;
					}
				}
				@media (max-aspect-ratio: ~"1280/680") {
					padding-bottom: 45%;
				}
				@media (max-aspect-ratio: ~"1280/760") {
					padding-bottom: 50%;
				}
				@media (max-aspect-ratio: ~"1280/840") {
					padding-bottom: 56.25%;
				}
				@media (max-aspect-ratio: ~"1280/960") {
					padding-bottom: 62.5%;
				}
				embed,
				.embed-responsive-item {
					height: 100%;
					position: absolute;
				}
			}
		}
		#video-right {
			display: none;
		}
	}
}

#video-playlist {
	clear: both;
	margin: 3px 0;
	//border: 1px solid @video-playlist-border;
	#playlist-header {
		background: @gray-bg;
		overflow: hidden;
		button {
			margin: 0;
			background: @gray-bg;
			border-color: @video-playlist-border;
			box-shadow: none;
			font-weight: bold;
			font-size: 15px;
			line-height: 24px;
			border-radius: 0;
			&.pull-left {
				border-width: 0 1px 0 0;
			}
			&.pull-right {
				border-width: 0;
			}
			&:hover {
				background: @theme-color;
				color: #fff;
			}
			&.disabled {
				background: @gray-bg;
				color: @gray-light
			}
			.icon-f {
				vertical-align: middle;
				line-height: 24px;
			}
		}
		h4 {
			font-weight: bold;
			font-size: 18px;
			line-height: 33px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 0 8px;
			margin: 0;
			.icon.playlist {
				vertical-align: middle;
			}
			small {
				color: @gray-dark;
				a {
					text-decoration: underline;
				}
			}
			a {
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.rating-box {
			float: right;
			margin: 3px 8px;
			.btn {
				box-shadow: none;
			}
		}
	}
	#playlist-content {
		padding: 0 2px;
		//border-top: 1px solid @video-playlist-border;
		background: @gray-bg;
		overflow: hidden;
		.mozaique {
			white-space: nowrap;
			overflow: hidden;
			.thumb-block {
				background: none;
				border: 0;
				clear: none;
				display: inline-block;
				float: none;
				padding-top: 0;
				.thumb-overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom:  0;
					font-size: 22px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					background: rgba(0, 0, 0, 0.6);
					p {
						text-align: center;
						color: #fff;
						font-weight: bold;
						white-space: normal;
						line-height: 1.3;
						padding: 6px 10px;
					}
				}
				&.selected {
					.thumb-overlay {
						border: 3px solid @theme-color;
					}
					p a {
						color: @theme-color;
						font-weight: bold;
					}
				}
			}
			> p {
				font-size: 13px;
				margin: 8px 0 10px;
			}
		}

		.mozaique-grid {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			white-space: nowrap;
		}
	}
}

#video-playlist {
	.autoplay-title {
		margin-bottom: 0;
	}

	#playlist-header {
		.checkbox.toggle {
			display: inline-block;
			margin-right:15px;
		}
	}

	#playlist-content {
		.checkbox.toggle {
			display: none;
    		text-align: center;
    		width: 100%;
		}
	}
}

@video-actions-height: 36px;
@video-actions-margin: 10px;
@video-actions-vote-rate-bar-height: 2px;

#v-actions {
	color: @video-actions-text-color;
	display: flex;
	flex-shrink: 0;
	font-weight: bold;
	justify-content: space-between;
	overflow: auto;
	margin-bottom: -@scroll-overflow;
	padding: @video-actions-margin (@video-actions-margin / 2) (@video-actions-margin + @scroll-overflow);
	white-space: nowrap;
	width: 100%;
	
	* {
		vertical-align: top;
	}
	
	.hide-if-zero-0 {
		display: none!important;
	}
	
	button {
		background: none;
		border: 0;
		outline: 0;
		padding: 0;
		
		&:active, &:focus {
			outline: 0;
			
			.icon-f {
				color: @video-actions-text-color;
			}
		}
	}
	
	.icon-f {
		color: @video-actions-icon-color;
		display: inline-block;
		font-size: 18px;
		vertical-align: top;
		
		&.icf-comment-o,
		&.icf-download {
			font-size: 20px;
		}
		
		&.icf-warning-stop {
			font-size: 22px;
		}
	}
	
	#v-views {
		display: inline-block;
		font-size: 19px;
		font-weight: bold;
		line-height: @video-actions-height;
		margin: 0 @video-actions-margin;
		
		.icon-f {
			font-size: 14px;
			line-height: @video-actions-height;
			margin-right: 6px;
		}
	}
	
	.vote-actions {
		display: inline-block;
		position: relative;
		
		&:not(:first-child) {
			margin-left: @video-actions-margin;
		}
		
		.vote-action-good,
		.vote-action-bad {
			display: inline-block;
			font-size: 11px;
			height: @video-actions-height - @video-actions-vote-rate-bar-height;
			text-align: center;
			width: @video-actions-height;
			
			.icon-f {
				display: block;
				font-size: 24px;
				line-height: @video-actions-height - @video-actions-vote-rate-bar-height;
			}
			
			.notouch &:hover {
				.icon-f {
					color: @video-actions-vote-active-color;
				}
			}
		}
		
		.vote-action-good {
			.rating-good-perc {
				display: none;
			}
		}
		
		.vote-action-bad {
			.rating-bad-perc {
				display: none;
			}
		}
		
		.active {
			.icon-f {
				color: @video-actions-vote-active-color;
			}
		}
		
		.rating-bar {
			@video-actions-vote-rate-tot-height: 16px;
			
			.pgs-bar {
				background: @video-actions-vote-bar-bg;
				height: @video-actions-vote-rate-bar-height;
				
				div {
					background: @video-actions-vote-bar-color;
					height: @video-actions-vote-rate-bar-height;
				}
			}
			
			&.is-good {
				.pgs-bar {
					div {
						background: @video-actions-vote-active-color;
					}
				}
			}
			
			&.is-bad {
				.pgs-bar {
					background: @video-actions-vote-active-color;
				}
			}
		}
		
		.rate-infos {
			@rate-infos-side-padding: 16px;
			background: @gray-lighter;
			border-radius: 2px;
			box-shadow: 0 0 0 1px @gray-bg;
			display: none;
			font-size: 0;
			left: 120%;
			line-height: @video-actions-height / 2;
			padding: (@video-actions-margin / 2) @rate-infos-side-padding;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			white-space: nowrap;
			z-index: 2;
			
			.rating-total-txt {
				display: inline-block;
				font-size: 16px;
				padding: (@video-actions-height / 4) @rate-infos-side-padding (@video-actions-height / 4) 0;
			}
			
			.nbr ,
			.perc {
				display: inline-block;
				font-size: 11px;
				text-align: right;
				
				& > span {
					display: block;
				}
				//& > *:first-child {
				//	margin-bottom: (@video-actions-height / 6);
				//}
			}
			
			.nbr {
				padding-right: @rate-infos-side-padding;
				
				.icon-f {
					font-size: @video-actions-height / 3;
					line-height: @video-actions-height / 2;
					padding-left: 4px;
					vertical-align: top;
				}
			}
			
			.perc {
				padding-right: 46px;
				
				.good,
				.bad {
					display: block;
					position: relative;
					width: 40px;
				}
				
				.bar {
					display: inline-block;
					height: @video-actions-height / 4;
					position: absolute;
					left: 46px;
					top: @video-actions-height / 8;
				}
			}
		}
		
		.notouch &:hover {
			.rate-infos {
				display: block;
				
				.nbr .icon-f{
					color: @video-actions-icon-color;
				}
				.perc .bar{
					background: @video-actions-icon-color;
				}
			}
			
			.vote-action-good:hover + .vote-action-bad + .rating-bar + .rate-infos {
				.nbr .rating-good-nbr + .icon-f{
					color: @video-actions-vote-active-color;
				}
				.perc .good .bar{
					background: @video-actions-vote-active-color;
				}
			}
			
			.vote-action-bad:hover + .rating-bar + .rate-infos {
				.nbr .rating-bad-nbr + .icon-f{
					color: @video-actions-vote-active-color;
				}
				.perc .bad .bar{
					background: @video-actions-vote-active-color;
				}
			}
		}
	}
	
	.tabs {
		display: flex;
		flex-grow: 10;
		overflow: hidden;
		
		.comments-disabled & {
			justify-content: flex-end;
		}
		
		button {
			//border-bottom: 2px solid @body-bg;
			height: @video-actions-height;
			line-height: @video-actions-height;
			padding: 0 @video-actions-margin;
			margin-left: @video-actions-margin;
			//position: relative;
			
			.notouch &:hover,
			&.active {
				border-bottom: 2px solid @text-color;
				
				.icon-f {
					color: @video-actions-text-color;
				}
			}
			
			.icon-f {
				line-height: @video-actions-height;
				margin-right: 8px;
			}
			
			&.comments {
				margin-right: auto;//self align left
				padding-left: 40px;
				position: relative;
				
				.notouch &:hover,
				&.active {
					&, & > span:first-child {
						border-bottom: 2px solid @red-color;
					}
				}
				
				& > span:first-child {
					height: @video-actions-height;
					left: 0;
					position: absolute;
					top: 0;
					text-align: center;
					width: 40px;
					
					.icon-f {
						margin-right: 0;
						font-size: 24px;
					}
					
					.badge {
						background: @red-color;
						border-radius: ~"50% / 60%";
						color: #fff;
						font-size: 10px;
						line-height: 9px;
						min-width: 16px;
						padding: 3px 3px 2px;
						position: absolute;
						right: calc(~"50% - 18px");
						top: 0;
					}
				}
			}
			
			.notouch &:hover {
				border-bottom-color: @text-color;
			}
			
			.nb {
				font-size: 1.1em;
				padding: 0 0 0 4px;
				//position: absolute;
				//left: @video-actions-margin;
			}
		}
	}
}

#full-video-link-btn {
	height: 30px;
	line-height: 22px;
	margin: ((@video-actions-height - 30px)/2) auto 0 @video-actions-margin;
	padding: 4px 8px;
	//position: relative;
	
	&.v1-btn {
		background: @red-color;
		color: #fff;
		font-size: 14px;
		text-decoration: none;
		
		.icf-ticket-red {
			color: #fff;
			line-height: 16px;
			vertical-align: text-bottom;
		}
		
		strong {
			text-decoration: underline;
		}
	}
	
	&.v2-txt {
		color: @text-color;
		text-decoration: underline;
	}
	
	&.v3-txt {
		color: @red-color;
		text-decoration: underline;
	}
	
	strong {
		vertical-align: unset;
	}
	
	.icf-ticket-red {
		color: @red-color;
		font-size: 26px;
		margin-left: 3px;
		vertical-align: middle;
	}
}

@video-actions-scroll-btn-w: 40px;

#v-actions-container {
	display: flex;
	height: @video-actions-height + (@video-actions-margin * 2);
	overflow: hidden;
	position: relative;
	width: auto;
	
	&.with-overflow-menu {
		padding-right: @video-actions-scroll-btn-w;
		
		#v-actions-overflow-menu {
			display: block;
		}
	}
}

#v-actions-overflow-menu {
	background: none;
	border: 0;
	cursor: pointer;
	display: none;
	height: @video-actions-height;
	padding: 0;
	position: absolute;
	right: 0;
	top: @video-actions-margin;
	width: @video-actions-scroll-btn-w;
	
	.icon-f {
		display: block;
		font-size: 20px;
		line-height: @video-actions-height;
		vertical-align: top;
	}
}
.v-actions-pop-menu {
	.x-popup-content {
		padding: 5px 0;
	}
	
	&.x-popup.above.right .x-popup-arrow {
		right: 15px;
	}
	
	button {
		background: transparent;
		border:	0;
		color: #fff;
		font-size: 16px;
		line-height: 40px;
		padding: 0 20px;
		text-align: left;
		
		.icon-f {
			display: inline-block;
			font-size: 18px;
			line-height: 40px;
			vertical-align: top;
			width: 30px;
			
			&.icf-comment-o,
			&.icf-download {
				font-size: 20px;
			}
			
			&.icf-warning-stop {
				font-size: 22px;
			}
		}
		
		.badge {
			background: @red-color;
			color: #fff;
			padding: 1px 3px;
			position: absolute;
			right: calc(~"100% - 50px");
		}
	}
}

#video-tabs {
	.btn:not(.copy-btn) {
		border: 0;
		border-radius: 0;
		min-height: 30px;
		line-height: 28px;
		margin: 12px 0;
		padding: 0 12px;
		vertical-align: top;
		
		.icon,
		.icon-f {
			transition: none;
		}
		
		.icon-f {
			vertical-align: middle;
		}
	}
	
	.tabs {
		overflow: initial;
		
		.tab {
			background: @video-tabs-bg;
			margin: 0 0 10px 0;
			padding: 15px 10px;
			
			.clear-infobar {
				margin-bottom: 10px;
				
				&:not(:first-child) {
					margin-top: 26px;
				}
				
				.icon-f {
					display: inline-block;
					font-size: 20px;
					margin-right: 4px;
					vertical-align: middle;
				}
				
				.icf-warning-stop {
					color: @red-color;
					font-size: 30px;
				}
			}
			
			.icf-download {
				display: inline-block;
				font-size: 26px;
				margin-right: 4px;
				vertical-align: middle;
			}
			
			#watch-later-cont {
				padding-bottom: 10px;
				text-align: center;
			}
		}
		
		#tabComments,
		#tabCommentsBottom {
			overflow: initial;
			background: none;
			border-top: 0;
			padding: 5px 10px 15px;
		}
	}
}


#tabComments_bottom_page {
	margin: 0 0 50px;
	padding: 0 10px;
	#tabComments_loader {
		padding: 15px 10px;
		text-align: center;
	}
	#tabComments,
	#tabCommentsBottom {
		margin: 30px 0 20px;
		padding: 5px 0 15px;
		h4 {
			font-size: 20px;
		}
	}
}

#tabShareAndEmbed .share-toolbox {
	display: flex;
	justify-content: space-around;
	max-width: 440px;
	text-align: center;
	
	li {
		margin: 0;
	}
}

#tabDownload {
	text-align: center;
	font-size: 15px;
	
	.video-hd-mark,
	.video-sd-mark {
		vertical-align: middle;
	}
}

#tabReport {
	.clear-infobar {
		text-align: center;
	}
	
	.tab-form label.control-label {
		padding-top: 7px;
	}
	
	.form-field-video-report-form_reason + .content {
		text-align: center;
	}
	
	.radio-inline,
	.radio-inline + .radio-inline {
		padding-top: 7px;
		margin: 0 10px 0 0;
		label {
			font-weight: bold;
		}
	}
}

#favs-container {
	color: @text-color;
	.pagination {
		margin: 6px 2px 15px;
	}
	.list-group {
		.list-group-item {
			padding: 6px 12px;
			strong {
				padding-right: 15px;
			}
			.badge {
				margin-top: 3px;
			}
			.btn-xs {
				margin: 0 0 0 5px;
				box-shadow: none;
			}
			&.video-in-list {
				strong,
				.remove {
					color: @red-color;
				}
			}
			&.watch-later-list {
				margin-bottom: 8px;
				.icon-f {
					color: @text-color;
					vertical-align: middle;
				}
			}
			a {
				color: @text-color;
			}
		}
	}
	form {
		strong {
			padding-right: 0;
		}
		.btn {
			margin: 0 0 0 10px;
			color: @text-color;
		}
	}
	.create-account .btn {
		color: @text-color;
	}
}

.related-content {
	.related-content__btns {
		position: relative;
		padding: 0;
		margin: 5px 0 0 0;
		font-size: 0;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			display: block;
			width: 100%;
			height: 1px;
			background: @thin-tab-border-bottom;
			z-index: 0;
		}

		li {
			position: relative;
			display: inline-block;
			margin: 0;
			padding: 0;
			z-index: 1;

			& + li {
				margin-left: 10px;
			}
		}
		a {
			margin: 0;
			text-decoration: none;
			font-size: 12px;
			line-height: 18px;
			box-shadow: none;
			border-radius: 2px;

			&.link {
				display: block;
				padding: 5px;
				font-weight: 400;
				font-size: 14px;
				line-height: 1;
				color: @gray;
				border-radius: 0;
				border-bottom: 1px solid @thin-tab-border-bottom;

				&:hover {
					color: @text-color;
				}
			}

			&.active {
				color: @text-color;
				border-color: @thin-tab-border-bottom-active;
			}

			.btn__text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.btn__nb-playlists {
				color: @gray-light;
			}
		}
	}
	.related-content__tabs {
		overflow: hidden;
		zoom: 1;
		clear: both;

		& > div {
			display: none;
			overflow: hidden;
			zoom: 1;
			&.active,
			&.desktop-active {
				display: block;
			}
		}
	}
}

#related-videos {
	overflow: hidden;
	.thumb-block {
		&.video-ad {
			.thumb-inside {
				overflow: visible;
			}
		}
	}
	.show-more {
		display: block;
		margin: 5px 0 0;
		box-shadow: none;
		overflow: hidden;
	}
	&.expanded {
		.show-more {
			display: none;
		}
	}
	&.is-loading-sponsor {
		.mozaique {
			margin-top: 31px;
		}
	}
	&.is-loading-related-content {
		.mozaique {
			padding-top: 30px;
		}
	}
	.plist {
		display: block;
		margin: 5px 0 0 2px;
		float: right;
		box-shadow: none;
	}
	
	.any-col-config({ &.expanded {
		&,
		&.enlarged-player.with-ad,
		&.with-footer-ad.with-ad {
			.thumb-block {
				&.after-10,
				&.after-12,
				&.after-13,
				&.after-14,
				&.after-15,
				&.after-16 {
					display: inline-block;
				}
			}
		}
	} });
}

#related-playlists {
	& > .text-center {
		margin-top : 10px;
	}
	.mozaique {
		.thumb-block {
			a {
				.thumb {
					border: 1px solid @thumb-block-thumb-border;
				}

				.nb-videos {
					background: #000000;
					border-radius: 3px;
					color: #ffffff;
					display: block;
					padding: 0 3px;
				}
			}
			.thumb-under {
				min-height: 20px;
				display: flex;
				flex-flow: row nowrap;
				justify-content: center;
				align-items: flex-start;
				flex-basis: initial;

				p.title {
					max-width: 100%;
					height: auto;
					max-height: 20px;
					-webkit-line-clamp: 1;

					span {
						font-size: 13px;

						&.creator {
							color: #555;
						}
					}
				}
			}
		}
	}
}

#thumbs-select-form {
	p {
		font-style: italic;
		margin-bottom: 15px;
	}
	.mozaique {
		margin-left: -3px;
		margin-right: -3px;
		.thumb-block {
			border-bottom: none;
			padding: 0 3px 4px;
			.thumb a {
				border-color: #999;
				.corner-select {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						border-top: 34px solid #999;
						border-right: 34px solid transparent;
						width: 0;
					}
				}
				.select-over,
				.rejected {
					display: none;
					flex-direction: column;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: rgba(0, 0, 0, 0.7);
					color: #fff;
					font-size: 24px;
					font-weight: bold;
					text-align: center;
				}
				input {
					display: none;
				}
				.icf-check-square {
					display: none;
					position: absolute;
					top: 2px;
					left: 2px;
					color: @theme-color;
				}
			}
			&.casting,
			&.selected {
				.thumb a {
					.select-over {
						display: flex;
					}
				}
			}
			&.selected {
				.thumb a {
					& {
						border: 4px solid #fff;
					}
					.corner-select:before {
						border-top-color: #fff;
					}
					.icf-check-square {
						display: inline-block;
					}
				}
			}
			&.disabled {
				.thumb a {
					& {
						border: 4px solid #999;
					}
					.rejected {
						display: flex;
						color: #aaa;
						font-style: italic;
						background: rgba(0, 0, 0, 0.8);
					}
				}
			}
		}
	}
	.thumb-select-actions {
		margin-top: 20px;
		border-top: 1px solid #fff;
		padding-top: 10px;
		text-align: right;
		.alert {
			text-align: left;
			margin-bottom: 10px;
			font-weight: bold;
		}
		.btn {
			margin: 0 0 2px 10px;
			font-weight: normal;
			font-size: 15px;
			border-radius: 0;
			&.validate {
				font-weight: bold;
			}
		}
	}
}

.premium-log-overlay {
	bottom: 0;
	box-sizing: border-box!important;
	cursor: pointer;
	left: 0;
	padding: 2%;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 2;
	
	.cover-thumb {
		bottom: 0;
		box-sizing: border-box!important;
		left: 0;
		padding: 2%;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		
		& + .purchase-content {
			background-color: fadeOut(black, 30%);
		}
	}
	
	span.cover-thumb {
		background-image: url('../../img/skins/default/ticket-red-side-space.svg');
		background-position: center;
		background-repeat: repeat-x;
		background-size: auto 90%;
	}
	
	.purchase-content {
		background: none;
		border: 0;
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		
		.btn {
			font-size: 1.2em;
			left: 50%;
			max-width: 96%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			white-space: normal;
		}
	}
	
	.video-loader {
		background: rgba(0, 0, 0, .5);
		color: #fff;
		font-size: 12px;
		font-weight: bold;
		height: 72px;
		left: 50%;
		line-height: 72px;
		margin: -36px;
		position: absolute;
		text-align: center;
		top: 50%;
		transition: 0.2s background;
		width: 72px;
		
		.buffer {
			bottom: 0;
			background: rgba(255,255,255,.3);
			height: 0%;
			left: 0;
			right: 0;
			position: absolute;
			z-index: 1;
		}
		
		.label {
			position: relative;
			z-index: 2;
		}
	}
}

#video-premium-alert {
	max-width: 610px;
	overflow: hidden;
	text-align: center;
	
	.video-premium-alert-row {
		align-items: center;
		display: flex;
		overflow: hidden;
		padding: 10px 0 20px;
		
		p {
			flex: 1 1 0;
		}
		
		.video {
			flex: 1.1 1 0;
			float: left;
			margin-right: 15px;
		}
	}
	
	.video {
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		width: 300px;
		
		img {
			width: 100%;
		}
		
		.premium-log-overlay {
			.icon-f {
				&,
				&:hover {
					background: rgba(0, 0, 0, .5);
					color: #fff;
					cursor: default;
					font-size: 34px;
					height: 52px;
					left: 50%;
					line-height: 52px;
					margin: -26px;
					position: absolute;
					text-align: center;
					top: 50%;
					transition: 0.2s background;
					width: 52px;
				}
			}
		}
		
		span.premium-log-overlay {
			cursor: default;
		}
		
		.cover-thumb {
			bottom: 0;
			left: 0;
			padding: 3%;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
	
	.icon-f {
		color: @red-color;
		font-size: 1.8em;
		line-height: 0.6;
		vertical-align: text-bottom;
	}
	
	p {
		font-size: 22px;
		margin: 0;
		padding: 10px 0;
		
		&:first-child {
			padding: 0 0 20px;
			text-transform: uppercase;
			
			strong {
				display: block;
				font-size: 26px;
				
				.icon-f {
					font-size: 1.4em;
					line-height: 0.9;
				}
			}
		}
		
		.link {
			cursor: pointer;
			text-decoration: underline;
			
			&:hover {
				text-decoration: none;
			}
		}
		
		.btn-primary {
			text-transform: uppercase;
			font-size: 26px;
			font-weight: bold;
			white-space: normal;
		}
	}
}
