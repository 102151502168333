
.mobile-show {
	display: block;
}
@media @media-ratio-vertical {
	.mobile-portrait-show {
		display: block;
	}
	.mobile-portrait-hide {
		display: none;
	}
}
@media @media-ratio-horizontal {
	.mobile-landscape-show {
		display: block;
	}
	.mobile-landscape-hide {
		display: none;
	}
}
.mobile-show-inline {
	display: inline;
}
.mobile-show-inline-block {
	display: inline-block;
}
.mobile-show-list-item {
	display: list-item;
}
.mobile-hide,
.mobile-hide-inline {
	display: none;
}

.x-overlay {
	&.x-overlay-box {
		padding: 0 10px;
		
		.x-body {
			padding: 20px 15px 15px;
		}
	}
}
.pagination {
	ul {
		li {
			a {
				font-size: 16px;
				padding: 5px (@pagination-side-padding+2px);
				
				&.active {
					padding-left: @pagination-active-side-padding+2px;
					padding-right: @pagination-active-side-padding+2px;
				}
				
				&.no-page {
					padding: 5px ceil(@pagination-side-padding / 2);
				}
			}
			&.mobile-hide {
				display: none;
			}
		}
	}
	&.center {
		ul {
			text-align: left;
		}
	}
}

.xv-tabs {
	.tab-buttons {
		li {
			&.mobile-show {
				display: inline-block;
			}
			&.mobile-hide {
				display: none;
			}
		}
		.btn {
			&.desktop-active {
				color: #000;
				text-shadow: 0 1px #333;
				background: @btn-default-bg;
				border-color: @btn-default-border;
				.navbadge {
					background: @theme-color;
				}
			}
			&.desktop-active:hover,
			&.mobile-active {
				color: #fff;
				text-shadow: 0 -1px #333;
				background: @theme-color;
				border-color: darken(@theme-color, 12%);
				.navbadge {
					background: #000;
				}
			}
		}
	}
	.tabs {
		.tab {
			&.desktop-active {
				display: none;
			}
			&.mobile-active {
				display: block;
			}
		}
	}
}

.grecaptcha-badge {
	display: none;
}

.copy-link {
	.copy-btn{
		width: 25%;
	}
	input{
		width: 75%;
	}
}
