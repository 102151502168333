@import "../../../common/form/search-report";
@import "../../../common/form/tag_adder";
@import "../../../common/form/models_selector";
@import "../../../common/form/toggle";
@import "../../../common/form/custom_checkbox";

@keyframes input_error_focus {
	0% {
		box-shadow: 0 0 0 0 fadeout(@red-color, 20%),  0 0 0 1000px fadeout(@red-color, 100%) inset;
	}
	5% {
		box-shadow: 0 0 0 0 fadeout(@red-color, 20%),  0 0 0 1000px fadeout(@red-color, 40%) inset;
	}
	40% {
		box-shadow: 0 0 10px 25px fadeout(@red-color, 100%), 0 0 0 1000px fadeout(@red-color, 20%) inset;
	}
	80% {
		box-shadow: 0 0 0 0 fadeout(@red-color, 100%), 0 0 0 1000px fadeout(@red-color, 20%) inset;
	}
	100% {
		box-shadow: 0 0 0 0 fadeout(@red-color, 100%), 0 0 0 1000px fadeout(@red-color, 100%) inset;
	}
}
@keyframes input_error_focus_inner {
	0% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 100%) inset;
	}
	5% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 40%) inset;
	}
	40% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 20%) inset;
	}
	80% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 20%) inset;
	}
	100% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 100%) inset;
	}
}

.form-control {
	color: @gray-darker;
	border-radius: 0;
	.box-shadow(none);
	&.visible-password {
		height: auto;
		padding: 0;
		position: relative;
		button {
			background: #fff;
			border: 0;
			border-radius: 0;
			bottom: 0;
			color: #777;
			float: right;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 40px;
			&:focus,
			&:active {
				outline: none;
			}
			.icon-f {
				font-size: 16px;
				vertical-align: middle;
			}
			.icf-eye-blocked {
				display: none;
			}
		}
		&.visible {
			button {
				.icf-eye-blocked {
					display: inline;
				}
				.icf-eye {
					display: none;
				}
			}
		}
		input {
			border: 0;
			height: 30px;
			margin: 0;
			padding-right: 40px;
			width: 100%;
		}
		.progress-pwd-check{
			height: 4px;
			margin: 0 -1px -4px;
		}
	}
	.highlight-errors & {
		&.validator-error {
			&.hl-focus {
				&, & + div .emojionearea-editor {
					animation: input_error_focus 1.5s ease-out;
				}
			}
		}
	}
}

input[type="checkbox"],
input[type="radio"] {
	margin-top: 0;
	.highlight-errors & {
		&.validator-error {
			&.hl-focus {
				animation: input_error_focus 1.5s ease-out;
			}
		}
	}
}

.tag-adder .tag-list {
	.highlight-errors & {
		&.validator-error {
			&.hl-focus {
				animation: input_error_focus_inner 1.5s ease-out;
			}
		}
	}
}

.checkbox .checkbox-error-box {
	position: absolute;
	border: 2px solid @theme-color;
}

.help-block {
	&.text-danger {
		color: @red-color;
	}
}

.form-horizontal {
	.input-group .fa {
		z-index: 3;
	}
	.input-pwd-check {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.progress-pwd-check {
		border-radius: 0;
		height: 4px;
		margin-bottom: 0;
	}
	.x-popup.form-popup-error {
		&.above {
			.x-popup-arrow {
				border-top-color: @theme-color;
			}
		}
		&.below {
			.x-popup-arrow {
				border-bottom-color: @theme-color;
			}
		}
		.x-popup-content {
			background: @theme-color;
			padding: 2px 8px;
			box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
		}
	}
}

select {
	option {
		background: #fff;
		color: #000;
		
		&:focus,
		&:active,
		&:hover {
			background: @red-color;
			color: #fff;
		}
	}
}

.select-label {
	background: @body-bg;
	display: block;
	position: relative;
	
	select {
		appearance: none;
		background: transparent;
		border: 1px solid @input-border;
		color: @text-color;
		font-size: 17px;
		padding: 0 16px 0 5px;
		position: relative;
		z-index: 2;
	}
	
	&::after {
		content: @icf-angle-down;
		display: block;
		.font-family-iconfont;
		font-size: 12px;
		height: 32px;
		line-height: 32px;
		position: absolute;
		right: 0;
		text-align: left;
		top: 0;
		width: 16px;
		z-index: 1;
	}
}

.copy-link.force-one-line {
	overflow: hidden;
	
	& > *:first-child {
		border-radius: 2px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
	
	& > *:last-child {
		border-radius: 2px;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-left-width: 0;
	}
	
	.copy-btn {
		.button-variant(#333, @input-border, @input-border);
		cursor: pointer;
		float: left;
		height: 40px;
		line-height: 38px;
		margin: 0;
		padding: 0;
		width: 15%;
		
		.icf-copy {
			display: inline-block;
			font-size: 24px;
			line-height: 38px;
			margin-right: 4px;
			vertical-align: top;
		}
	}
	
	input {
		border-color: @input-border;
		color: @gray;
		float: left;
		font-weight: bold;
		height: 40px;
		line-height: 40px;
		text-overflow: ellipsis;
		width: 85%;
		
		
		.notouch &[type=text].form-control {
			font-size: 12px;
		}
	}
}

.link-copied-popup {
	.x-popup-content {
		padding: 6px 10px 8px;
		p {
			margin: 0;
		}
	}
}

form {
	.validator-err-sc {
		border: 1px solid @warning-block-border;
		background: @warning-block-bg;
		color: @warning-block-color;
		margin-bottom: 8px;
		overflow: hidden;
		padding: 12px 30px 14px 18px;
		position: relative;
		text-align: left;
		& > * {
			position: relative;
			z-index: 2;
		}
		.icon-bg {
			color: darken(@warning-block-bg, 5%);
			font-size: 22px;
			left: 10px;
			top: 12px;;
			position: absolute;
			z-index: 1;
		}
		.close-btn {
			background: @warning-block-bg;
			display: block;
			height: 28px;
			line-height: 28px;
			margin: 0;
			position: absolute;
			right: 0;
			text-decoration: none;
			text-align: center;
			top: 0;
			width: 28px;
			&:hover {
				background: darken(@warning-block-bg, 5%);
			}
			.icon-f {
				color: @warning-block-color;
			}
		}
		h3 {
			color: @warning-block-color!important;
			font-size: 16px!important;
			margin: 0 0 10px 22px!important;
			line-height: 22px!important;
		}
		p.form-error-shortcut {
			line-height: 20px;
			&:last-child {
				margin-bottom: 0;
			}
			& > .icon-f {
				left: 0;
				line-height: 20px;
				position: absolute;
			}
			a {
				color: @warning-block-color!important;
				display: block;
				padding-left : 16px;
				&, span:hover {
					text-decoration: none;
				}
				span {
					text-decoration: underline;
				}
			}
		}
	}
}

.alert {
	padding-top: 5px;
	padding-bottom: 5px;
	border-width: 0 4px;
	margin-bottom: 1px;
	& + :not(.alert) {
		margin-top: 10px;
	}
	.icon-f {
		vertical-align: text-bottom;
	}
	.form-error-list {
		&:first-child {
			&:last-child {
				margin: 0;
			}
		}
	}
}

.form-error-list {
	padding: 0;
}

.form-error-list-contener {
	font-size: 16px;
	overflow: hidden;
	padding-left: 40px;
	position: relative;
	
	.form-error-list {
		position: relative;
		z-index: 2;
	}
	& > .icon-f {
		font-size: 40px;
		left: 0;
		opacity: 0.2;
		top: 5px;
		position: absolute;
	}
}

.form-range {
	width: 100%;
	
	.min,
	.max {
		line-height: 14px;
		margin: 0 0 15px;
		max-width: 50%;
		min-width: 30px;
		padding: 0;
		position: relative;
		text-align: center;
		z-index: 1;
	}
	
	.min:before,
	.max:before,
	input[type="range"]:before,
	input[type="range"]:after {
		background-color: @gray-bg;
		content: ' ';
		display: block;
		height: 6px;
		left: 14px;
		position: absolute;
		top: 18px;
		width: 2px;
		z-index: 1;
	}
	
	.min {
		float: left;
	}
	
	.max {
		float: right;
		
		&:before {
			left: auto;
			right: 14px;
		}
	}
	
	input[type="range"] {
		clear: both;
		position: relative;
		z-index: 2;
		
		&:before {
			left: calc(~"50% - 1px");
			top: -11px;
		}
		&:after {
			background-color: transparent;
			border-left: 2px solid @gray-bg;
			border-right: 2px solid @gray-bg;
			left: calc(~"(100% - 30px) / 4 + 15px - 1px");
			width: calc(~"50% - 13px");
			top: -11px;
		}
		
		&::-webkit-slider-thumb {
			position: relative;
			z-index: 2;
		}
		
		&::-moz-range-thumb {
			position: relative;
			z-index: 2;
		}
	}
}

input[type="range"] {
	-webkit-appearance: none;
	appearance: none;
	background: @gray-light;
	border-radius: 3px;
	height: 6px;
	margin: 15px 0;
	outline: none;
	transition: opacity .2s;
	width: 100%;
	
	&:hover {
		background: @gray-dark;
	}
	
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		background: @red-color;
		border-radius: 50%;
		box-sizing: content-box;
		cursor: pointer;
		height: 30px;
		width: 30px;
	}
	
	&::-moz-range-thumb {
		background: @red-color;
		border-radius: 50%;
		cursor: pointer;
		height: 30px;
		width: 30px;
	}
}





input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	&, &.form-control {
		font-size: 17px;
	}
}


.animation_pulse(@anim_pulse_color,@anim_pulse_bg_percent) {
	0% {
		box-shadow: 0 0 0 0 fadeout(@anim_pulse_color, 40%);
		background: fadeout(@anim_pulse_color, min(100%, @anim_pulse_bg_percent + 30%));
	}
	18% {
		background: fadeout(@anim_pulse_color, @anim_pulse_bg_percent);
	}
	35% {
		box-shadow: 0 0 5px 25px fadeout(@anim_pulse_color, 100%);
	}
	50% {
		box-shadow: 0 0 0 0 fadeout(@anim_pulse_color, 100%);
	}
	80% {
		background: fadeout(@anim_pulse_color, @anim_pulse_bg_percent);
	}
}
