.checkbox.toggle {
	position: relative;
	
	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		margin: 0;
		opacity: 0;
		
		&:focus {
			& + label,
			& + .checkbox-error-box + label {
				&:before {
					border-color: #777777;
				}
			}
		}
		
		&:checked {
			& + label,
			& + .checkbox-error-box + label {
				&:before {
					background: @toggle-bg-focus;
					border-color: @toggle-border-focus;
				}
				
				&:after {
					left: 18px;
					background: @toggle-bg-disc-focus;
				}
			}
		}
		
		&:disabled {
			& + label,
			& + .checkbox-error-box + label {
				cursor: not-allowed;
				opacity: 0.5;
			}
		}
		
		& + .checkbox-error-box {
			display: none;
		}
		
		&.validator-error {
			& + .checkbox-error-box + label {
				&:before {
					box-shadow: 0 0 1px 2px inset @toggle-border-in-error, 0 0 1px 1px @toggle-border-out-error;
				}
				&:after {
					background: @toggle-bg-disc-error;
				}
			}
		}
	}
	
	label {
		position: relative;
		padding: 0 0 0 45px;
		
		&:before {
			background: @toggle-bg;
			border: 1px solid @toggle-border;
			border-radius: 10px;
			content: '';
			display: block;
			height: 20px;
			left: 0;
			position: absolute;
			top: 0;
			width: 36px;
		}
		
		&:after {
			background: @toggle-bg-disc;
			border-radius: 50%;
			content: '';
			display: block;
			height: 16px;
			left: 2px;
			position: absolute;
			top: 2px;
			transition: left 0.2s;
			width: 16px;
		}
	}
	
	&.no-label {
		height: 20px;
		width: 36px;
		
		label {
			height: 20px;
			padding: 0 0 0 36px;
		}
	}
	
	&.w-28 {
		height: 18px;
		width: 28px;
		
		input {
			&:checked {
				& + label,
				& + .checkbox-error-box + label {
					&:after {
						left: 11px;
					}
				}
			}
		}
		
		label {
			height: 18px;
			padding: 0 0 0 34px;
			
			&:before {
				height: 18px;
				border-radius: 9px;
				width: 28px;
			}
			
			&:after {
				top: 2px;
				left: 2px;
				width: 14px;
				height: 14px;
			}
		}
		
		&.no-label {
			label {
				padding: 0 0 0 28px;
			}
		}
	}
}