.container-dragdrop {
	&.mozaique {
		margin: 0;
	}
	
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none;
	
	.is-dragdrop {
		& > * {
			position: relative;
			z-index: 2;
		}
		
		&:after {
			background: fadeOut(@red-color, 90%);
			bottom: 5px;
			box-shadow: 0 0 0 2px fadeOut(@red-color, 50%);
			content: "";
			display: none;
			left: 2px;
			position: absolute;
			top: 5px;
			right: 2px;
			transition: transform 0.3s;
			z-index: 1;
		}
		
		.thumb-inside,
		.thumb-under {
			transition: transform 0.3s;
		}
		
		.thumb-inside {
			.videopv {
				&:after {
					bottom: 0;
					content: " ";
					display: block;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 100;
				}
			}
		}
		
		&.img-block {
			&:after {
				content: none;
				display: none!important;
			}
			
			& > * {
				opacity: 1!important;
			}
			
			.embed-responsive-item {
				border: 1px solid #ccc;
				transition: transform 0.3s;
			}
		}
		
		&.is-dragdrop-active {
			.checkbox-dragdrop {
				label {
					border-color: @red-color;
					opacity: 1;
				}
			}
			
			&:after {
				display: block;
			}
			
			&.img-block {
				.embed-responsive-item {
					border: 2px solid @red-color;
				}
			}
		}
		
		.notouch &:hover,
		&.is-dragdrop-active {
			.checkbox-dragdrop {
				display: block;
			}
			.video-viewed {
				display: none;
			}
		}
		
		&.is-dragdrop-active {
			&.is-dragdrop-selected {
				&:after {
					box-shadow: 0 0 0 2px fadeOut(#fff, 50%);
					display: block;
				}
				
				&.img-block {
					.embed-responsive-item {
						border: 2px solid @gray-bg;
						
						&:after {
							background: fadeOut(#fff, 50%);
						}
					}
				}
				
				.checkbox-dragdrop {
					input:checked + label {
						background: @gray-bg;
					}
				}
				
				&.is-dragdrop-first-selected {
					&:after {
						background: fadeOut(@red-color, 80%);
						box-shadow: 0 0 0 4px fadeOut(@red-color, 20%);
						display: block;
					}
					
					&.img-block {
						.embed-responsive-item {
							border: 2px solid @red-color;
							
							&:after {
								background: fadeOut(@red-color, 80%);
							}
						}
					}
					
					.checkbox-dragdrop {
						input:checked + label {
							background: @red-color;
						}
					}
				}
			}
		}
		
		&.is-dragdrop-selected {
			&:hover {
				cursor: grab;
			}
			
			&:after {
				box-shadow: 0 0 0 2px @red-color;
				display: block;
				transform-origin: center center;
				transform: scale(0.9);
			}
			.thumb-inside,
			.thumb-under {
				opacity: 0.9;
				transform-origin: bottom center;
				transform: scale(0.88);
			}
			.thumb-under {
				transform-origin: top center;
			}
			
			&.img-block {
				.embed-responsive-item {
					margin: 0 auto;
					transform-origin: center center;
					transform: scale(0.9);
					border: 2px solid @red-color;
					
					&:after {
						background: fadeOut(@red-color, 80%);
					}
				}
			}
		}
		
		&.is-dragdrop-inactive {
			&:after {
				background: @gray-bg;
				box-shadow: none;
				display: block;
			}
			
			.thumb-inside > *,
			img {
				visibility: hidden;
			}
			
			&.img-block {
				.thumb-inside {
					.thumb {
						visibility: visible;
					}
					
					.embed-responsive-item {
						background: @gray-bg;
						border-color: @gray-bg;
						
						& > * {
							visibility: hidden;
						}
					}
				}
			}
			&.is-dragdrop-selected {
			}
			
			.thumb a {
				border: none;
			}
			
			.thumb-under {
				visibility: hidden;
			}
			
			.checkbox-dragdrop {
				display: none;
			}
			
			&.dragdrop-selection {
				&:after {
					background: @gray-lighterer;
				}
				
				&.img-block {
					.thumb-inside {
						.embed-responsive-item {
							background: @gray-lighterer;
							border-color: @gray-lighterer;
							
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
		
		&.is-dragdrop-moving {
			border-bottom: 0;
			cursor: grabbing !important;
			margin: 0;
			padding: 0;
			position: fixed;
			z-index: 300;
			
			&:after {
				background: @body-bg;
				bottom: 0;
				display: block;
				left: 0;
				right: 0;
				top: 0;
			}
			
			&.img-block {
				.embed-responsive-item {
					background: @body-bg;
				}
			}
			
			.notouch &:hover {
				.checkbox-dragdrop {
					display: none;
				}
			}
			
			.dragdrop-nb-pics-selected {
				background: @red-color;
				border-radius: 50%;
				color: #ffffff;
				display: block;
				font-size: 14px;
				font-weight: 700;
				height: 30px;
				line-height: 30px;
				position: absolute;
				right: 0;
				text-align: center;
				top: 0;
				width: 30px;
			}
		}
		
		&.img-block {
			.embed-responsive-item {
				z-index: 0;
				
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
				}
			}
			
			.checkbox-dragdrop {
				bottom: 5px;
				right: 5px;
				top: auto;
			}
		}
	}
	
	&.is-dragdrop-selecting {
		&, * {
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			user-select: none;
		}
		
		.is-dragdrop {
			cursor: default;
			
			.checkbox-dragdrop {
				display: block;
			}
			
			a.play,
			.video-viewed{
				display: none;
			}
			
			&.is-dragdrop-selected {
				cursor: grab;
			}
			
			&.is-dragdrop-inactive {
				.checkbox-dragdrop {
					display: none;
				}
			}
		}
	}
	
	&.is-dragdrop-reorder-touch {
		.is-dragdrop {
			&.is-dragdrop-selected {
				.checkbox-dragdrop {
					display: none;
				}
				
				&.img-block {
					.thumb-inside {
						.embed-responsive-item {
							background: @gray-bg;
						}
					}
				}
			}
			
			&.is-dragdrop-selected,
			&.is-dragdrop-moving {
				img {
					display: block;
				}
			}
		}
	}
	
	&.is-dragdrop-reorder-moveOneNoSelect {
		.is-dragdrop {
			&, &:hover {
				.checkbox-dragdrop {
					display: none;
				}
			}
		}
	}
	
	.checkbox-dragdrop {
		height: auto;
		display: none;
		position: absolute;
		right: 0;
		top: 3px;
		width: 28px;
		z-index: 4;
		
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
		
		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			opacity: 0;
		}
		
		label {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;
			height: 100%;
			font-size: 0;
			background: #ffffff;
			border: 3px solid #555555;
			border-radius: 50%;
			z-index: 1;
			cursor: pointer;
			
			&:after {
				content: '';
				display: none;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 16px;
				height: 8px;
				margin: -4px 0 0 -8px;
				border-left: 3px solid #ffffff;
				border-bottom: 3px solid #ffffff;
				transform: rotate(-45deg);
			}
			
			.notouch &:hover {
				background: #555;
				border: none;
				
				&:after {
					display: block;
				}
			}
		}
		
		input:checked + label {
			background: @red-color;
			border: none;
			opacity: 1;
			
			&:after {
				display: block;
			}
		}
	}
}

.touch-dragdrop-with-scroll {
	padding-right: 24px;
	position: relative;
	
	&::after,
	&::before {
		.font-family-iconfont;
		.text-border-2px(@gray-lighter);
		border-radius: 3px;
		bottom: 14px;
		color: @gray-light;
		content: " ";
		display: block;
		font-size: 14px;
		line-height: 20px;
		position: absolute;
		right: 2px;
		text-align: center;
		vertical-align: bottom;
		width: 18px;
		z-index: 1;
	}
	
	&::before {
		//background: linear-gradient(to right, fadeOut(@gray-bg, 50%), fadeOut(@gray-bg, 90%), fadeOut(@gray-bg, 50%));
		.dot-bg(@gray-lighter, @gray-bg, 1px, 5px);
		box-shadow: 0 0 4px -1px fadeOut(@text-color, 40%);
		content: @icf-caret-up;
		top: 14px;
	}
	
	&::after {
		content: @icf-caret-down;
		height: 20px;
		z-index: 2;
	}
}

.dragdrop-selection-zone {
	position: absolute;
	background: fadeOut(@red-color, 70%);
	border: 1px dotted red;
	z-index: 9999;
	
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none;
}

.is-dragdrop-draging {
	cursor: crosshair;
	
	&, * {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		user-select: none;
	}
}

.is-dragdrop-selection,
.is-dragdrop-reorder {
	&, * {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		user-select: none;
	}

	form {
		input,
		textarea {
			-webkit-user-select: text;
			user-select: text;
		}
	}
}