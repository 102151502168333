#popup-opening-message {
	color: @text-color;
	background: @body-bg;
}

#popup-opening-content {
	.popup-opening-title {
		color: @red-color;
	}
	
	.popup-opening-link {
		a {
			background: @theme-color;
			color: #ffffff;
		}
	}
	
	.icf-device-mobile {
		color: @text-color;
		opacity: 0.2;
	}
}
