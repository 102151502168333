/* ==== FLAGS ==== */
@flag-path: "../../../v3/img/flags/flat/flags-32.png";

.flag {
	display: inline;
	*zoom: 1;
	display: inline-block;
	width: 32px;
	height: 32px;
	line-height: 32px;
	background-image: url(@flag-path);
	background-position: 0 0;
	background-repeat: no-repeat;
	&.flag-ad { background-position: 0 0 }
	&.flag-ae { background-position: -32px 0 }
	&.flag-af { background-position: -64px 0 }
	&.flag-ag { background-position: -96px 0 }
	&.flag-ai { background-position: -128px 0 }
	&.flag-al { background-position: -160px 0 }
	&.flag-am { background-position: -192px 0 }
	&.flag-an { background-position: -224px 0 }
	&.flag-ao { background-position: -256px 0 }
	&.flag-aq { background-position: -288px 0 }
	&.flag-ar { background-position: -320px 0 }
	&.flag-as { background-position: -352px 0 }
	&.flag-at { background-position: -384px 0 }
	&.flag-au { background-position: -416px 0 }
	&.flag-aw { background-position: -448px 0 }
	&.flag-ax { background-position: -480px 0 }
	&.flag-az { background-position: 0 -32px }
	&.flag-ba { background-position: -32px -32px }
	&.flag-bb { background-position: -64px -32px }
	&.flag-bd { background-position: -96px -32px }
	&.flag-be { background-position: -128px -32px }
	&.flag-bf { background-position: -160px -32px }
	&.flag-bg { background-position: -192px -32px }
	&.flag-bh { background-position: -224px -32px }
	&.flag-bi { background-position: -256px -32px }
	&.flag-bj { background-position: -288px -32px }
	&.flag-bl { background-position: -320px -32px }
	&.flag-bm { background-position: -352px -32px }
	&.flag-bn { background-position: -384px -32px }
	&.flag-bo { background-position: -416px -32px }
	&.flag-br { background-position: -448px -32px }
	&.flag-bs { background-position: -480px -32px }
	&.flag-bt { background-position: 0 -64px }
	&.flag-bw { background-position: -32px -64px }
	&.flag-by { background-position: -64px -64px }
	&.flag-bz { background-position: -96px -64px }
	&.flag-ca { background-position: -128px -64px }
	&.flag-cc { background-position: -160px -64px }
	&.flag-cd { background-position: -192px -64px }
	&.flag-cf { background-position: -224px -64px }
	&.flag-cg { background-position: -256px -64px }
	&.flag-ch { background-position: -288px -64px }
	&.flag-ci { background-position: -320px -64px }
	&.flag-ck { background-position: -352px -64px }
	&.flag-cl { background-position: -384px -64px }
	&.flag-cm { background-position: -416px -64px }
	&.flag-cn { background-position: -448px -64px }
	&.flag-co { background-position: -480px -64px }
	&.flag-cr { background-position: 0 -96px }
	&.flag-cu { background-position: -32px -96px }
	&.flag-cv { background-position: -64px -96px }
	&.flag-cw { background-position: -96px -96px }
	&.flag-cx { background-position: -128px -96px }
	&.flag-cy { background-position: -160px -96px }
	&.flag-cz { background-position: -192px -96px }
	&.flag-de { background-position: -224px -96px }
	&.flag-dj { background-position: -256px -96px }
	&.flag-dk { background-position: -288px -96px }
	&.flag-dm { background-position: -320px -96px }
	&.flag-do { background-position: -352px -96px }
	&.flag-dz { background-position: -384px -96px }
	&.flag-ec { background-position: -416px -96px }
	&.flag-ee { background-position: -448px -96px }
	&.flag-eg { background-position: -480px -96px }
	&.flag-eh { background-position: 0 -128px }
	&.flag-er { background-position: -32px -128px }
	&.flag-es { background-position: -64px -128px }
	&.flag-et { background-position: -96px -128px }
	&.flag-eu { background-position: -128px -128px }
	&.flag-fi { background-position: -160px -128px }
	&.flag-fj { background-position: -192px -128px }
	&.flag-fk { background-position: -224px -128px }
	&.flag-fm { background-position: -256px -128px }
	&.flag-fo { background-position: -288px -128px }
	&.flag-fr { background-position: -320px -128px }
	&.flag-ga { background-position: -352px -128px }
	&.flag-gb { background-position: -384px -128px }
	&.flag-gd { background-position: -416px -128px }
	&.flag-ge { background-position: -448px -128px }
	&.flag-gg { background-position: -480px -128px }
	&.flag-gh { background-position: 0 -160px }
	&.flag-gi { background-position: -32px -160px }
	&.flag-gl { background-position: -64px -160px }
	&.flag-gm { background-position: -96px -160px }
	&.flag-gn { background-position: -128px -160px }
	&.flag-gq { background-position: -160px -160px }
	&.flag-gr { background-position: -192px -160px }
	&.flag-gs { background-position: -224px -160px }
	&.flag-gt { background-position: -256px -160px }
	&.flag-gu { background-position: -288px -160px }
	&.flag-gw { background-position: -320px -160px }
	&.flag-gy { background-position: -352px -160px }
	&.flag-hk { background-position: -384px -160px }
	&.flag-hn { background-position: -416px -160px }
	&.flag-hr { background-position: -448px -160px }
	&.flag-ht { background-position: -480px -160px }
	&.flag-hu { background-position: 0 -192px }
	&.flag-ic { background-position: -32px -192px }
	&.flag-id { background-position: -64px -192px }
	&.flag-ie { background-position: -96px -192px }
	&.flag-il { background-position: -128px -192px }
	&.flag-im { background-position: -160px -192px }
	&.flag-in { background-position: -192px -192px }
	&.flag-iq { background-position: -224px -192px }
	&.flag-ir { background-position: -256px -192px }
	&.flag-is { background-position: -288px -192px }
	&.flag-it { background-position: -320px -192px }
	&.flag-je { background-position: -352px -192px }
	&.flag-jm { background-position: -384px -192px }
	&.flag-jo { background-position: -416px -192px }
	&.flag-jp { background-position: -448px -192px }
	&.flag-ke { background-position: -480px -192px }
	&.flag-kg { background-position: 0 -224px }
	&.flag-kh { background-position: -32px -224px }
	&.flag-ki { background-position: -64px -224px }
	&.flag-km { background-position: -96px -224px }
	&.flag-kn { background-position: -128px -224px }
	&.flag-kp { background-position: -160px -224px }
	&.flag-kr { background-position: -192px -224px }
	&.flag-kw { background-position: -224px -224px }
	&.flag-ky { background-position: -256px -224px }
	&.flag-kz { background-position: -288px -224px }
	&.flag-la { background-position: -320px -224px }
	&.flag-lb { background-position: -352px -224px }
	&.flag-lc { background-position: -384px -224px }
	&.flag-li { background-position: -416px -224px }
	&.flag-lk { background-position: -448px -224px }
	&.flag-lr { background-position: -480px -224px }
	&.flag-ls { background-position: 0 -256px }
	&.flag-lt { background-position: -32px -256px }
	&.flag-lu { background-position: -64px -256px }
	&.flag-lv { background-position: -96px -256px }
	&.flag-ly { background-position: -128px -256px }
	&.flag-ma { background-position: -160px -256px }
	&.flag-mc { background-position: -192px -256px }
	&.flag-md { background-position: -224px -256px }
	&.flag-me { background-position: -256px -256px }
	&.flag-mf { background-position: -288px -256px }
	&.flag-mg { background-position: -320px -256px }
	&.flag-mh { background-position: -352px -256px }
	&.flag-mk { background-position: -384px -256px }
	&.flag-ml { background-position: -416px -256px }
	&.flag-mm { background-position: -448px -256px }
	&.flag-mn { background-position: -480px -256px }
	&.flag-mo { background-position: 0 -288px }
	&.flag-mp { background-position: -32px -288px }
	&.flag-mq { background-position: -320px -128px }
	&.flag-mr { background-position: -96px -288px }
	&.flag-ms { background-position: -128px -288px }
	&.flag-mt { background-position: -160px -288px }
	&.flag-mu { background-position: -192px -288px }
	&.flag-mv { background-position: -224px -288px }
	&.flag-mw { background-position: -256px -288px }
	&.flag-mx { background-position: -288px -288px }
	&.flag-my { background-position: -320px -288px }
	&.flag-mz { background-position: -352px -288px }
	&.flag-na { background-position: -384px -288px }
	&.flag-nc { background-position: -416px -288px }
	&.flag-ne { background-position: -448px -288px }
	&.flag-nf { background-position: -480px -288px }
	&.flag-ng { background-position: 0 -320px }
	&.flag-ni { background-position: -32px -320px }
	&.flag-nl { background-position: -64px -320px }
	&.flag-no { background-position: -96px -320px }
	&.flag-np { background-position: -128px -320px }
	&.flag-nr { background-position: -160px -320px }
	&.flag-nu { background-position: -192px -320px }
	&.flag-nz { background-position: -224px -320px }
	&.flag-om { background-position: -256px -320px }
	&.flag-pa { background-position: -288px -320px }
	&.flag-pe { background-position: -320px -320px }
	&.flag-pf { background-position: -352px -320px }
	&.flag-pg { background-position: -384px -320px }
	&.flag-ph { background-position: -416px -320px }
	&.flag-pk { background-position: -448px -320px }
	&.flag-pl { background-position: -480px -320px }
	&.flag-pn { background-position: 0 -352px }
	&.flag-pr { background-position: -32px -352px }
	&.flag-ps { background-position: -64px -352px }
	&.flag-pt { background-position: -96px -352px }
	&.flag-pw { background-position: -128px -352px }
	&.flag-py { background-position: -160px -352px }
	&.flag-qa { background-position: -192px -352px }
	&.flag-ro { background-position: -224px -352px }
	&.flag-rs { background-position: -256px -352px }
	&.flag-ru { background-position: -288px -352px }
	&.flag-rw { background-position: -320px -352px }
	&.flag-sa { background-position: -352px -352px }
	&.flag-sb { background-position: -384px -352px }
	&.flag-sc { background-position: -416px -352px }
	&.flag-sd { background-position: -448px -352px }
	&.flag-se { background-position: -480px -352px }
	&.flag-sg { background-position: 0 -384px }
	&.flag-sh { background-position: -32px -384px }
	&.flag-si { background-position: -64px -384px }
	&.flag-sk { background-position: -96px -384px }
	&.flag-sl { background-position: -128px -384px }
	&.flag-sm { background-position: -160px -384px }
	&.flag-sn { background-position: -192px -384px }
	&.flag-so { background-position: -224px -384px }
	&.flag-sr { background-position: -256px -384px }
	&.flag-ss { background-position: -288px -384px }
	&.flag-st { background-position: -320px -384px }
	&.flag-sv { background-position: -352px -384px }
	&.flag-sy { background-position: -384px -384px }
	&.flag-sz { background-position: -416px -384px }
	&.flag-tc { background-position: -448px -384px }
	&.flag-td { background-position: -480px -384px }
	&.flag-tf { background-position: 0 -416px }
	&.flag-tg { background-position: -32px -416px }
	&.flag-th { background-position: -64px -416px }
	&.flag-tj { background-position: -96px -416px }
	&.flag-tk { background-position: -128px -416px }
	&.flag-tl { background-position: -160px -416px }
	&.flag-tm { background-position: -192px -416px }
	&.flag-tn { background-position: -224px -416px }
	&.flag-to { background-position: -256px -416px }
	&.flag-tr { background-position: -288px -416px }
	&.flag-tt { background-position: -320px -416px }
	&.flag-tv { background-position: -352px -416px }
	&.flag-tw { background-position: -384px -416px }
	&.flag-tz { background-position: -416px -416px }
	&.flag-ua { background-position: -448px -416px }
	&.flag-ug { background-position: -480px -416px }
	&.flag-us { background-position: 0 -448px }
	&.flag-uy { background-position: -32px -448px }
	&.flag-uz { background-position: -64px -448px }
	&.flag-va { background-position: -96px -448px }
	&.flag-vc { background-position: -128px -448px }
	&.flag-ve { background-position: -160px -448px }
	&.flag-vg { background-position: -192px -448px }
	&.flag-vi { background-position: -224px -448px }
	&.flag-vn { background-position: -256px -448px }
	&.flag-vu { background-position: -288px -448px }
	&.flag-wf { background-position: -320px -448px }
	&.flag-ws { background-position: -352px -448px }
	&.flag-ye { background-position: -384px -448px }
	&.flag-yt { background-position: -416px -448px }
	&.flag-za { background-position: -448px -448px }
	&.flag-zm { background-position: -480px -448px }
	&.flag-zw { background-position: 0 -480px }
	&.flag-xk { background-position: -288px -480px }
	&.flag-none { background-position: -32px -480px }
	&.flag-vspacer { background: none; width: 0 }
	&.flag-question {
		background-color: #000;
		background-image: none;
		color: #fff;
		font-size: 16px;
		height: 20px;
		line-height: 20px;
		margin: 0 1px;
		position: relative;
		text-align: center;
		width: 30px;
		*zoom: ~"expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '?')";
		&:before {
			color: #fff;
			content: "?";
			font-size: 16px;
			font-weight: bold;
			left: 0;
			line-height: 20px;
			position: absolute;
			text-align: center;
			top: 0;
			width: 100%;
			z-index: 1;
		}
	}
	
	&.flag-c1 { background-position: -224px -480px }
	&.flag-c2 { background-position: -160px -480px }
	&.flag-c3 { background-position: -192px -480px }
	&.flag-c4 { background-position: -128px -480px }
	&.flag-c5 { background-position: -64px -480px }
	&.flag-c6 { background-position: -96px -480px }
	&.flag-c7 { background-position: -352px -480px }
	&.flag-p3 {
		background-position: -256px -486px;
		height: 20px;
	}
	
	&.flag-p1,
	&.flag-p2,
	&.flag-p4,
	&.flag-p5,
	&.flag-p6,
	&.flag-p7,
	&.flag-p8,
	&.flag-p9,
	&.flag-m1,
	&.flag-s1 {
		position: relative;
		width: 30px;
		height: 20px;
		background: #000000 url(@flag-path) no-repeat -320px -486px;
		
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			border-radius: 50%;
			background: #ffffff;
		}
	}
	
	&.flag-p1,
	&.flag-p9,
	&.flag-m1 {
		&:before {
			width: 12px;
			height: 12px;
			margin: -6px 0 0 -6px;
		}
	}
	
	&.flag-p2,
	&.flag-p4,
	&.flag-p6,
	&.flag-p7,
	&.flag-p8{
		&:before {
			width: 14px;
			height: 14px;
			margin: -7px 0 0 -7px;
		}
	}
	
	&.flag-p5 {
		&:before {
			width: 16px;
			height: 16px;
			margin: -8px 0 0 -8px;
		}
	}
	
	&.flag-s1 {
		&:before {
			width: 18px;
			height: 18px;
			margin: -9px 0 0 -9px;
		}
	}
	
	&.flag-p1 {
		&:before {
			background: #909090;
		}
	}
	&.flag-p2 {
		&:before {
			background: #ffe7c3;
		}
	}
	&.flag-p4 {
		&:before {
			background: #DB4C14;
		}
	}
	&.flag-p5 {
		&:before {
			background: #ff9900;
		}
	}
	&.flag-p6 {
		&:before {
			background: #ffc800;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 24px;
			height: 1px;
			margin-left: -12px;
			transform: rotate(-20deg);
			background: #ffffff;
		}
	}
	&.flag-p7 {
		&:before {
			background: #99aaff;
		}
	}
	&.flag-p8 {
		&:before {
			background: #4122ee;
		}
	}
	&.flag-p9 {
		&:before {
			background: #e7b067;
		}
	}
	&.flag-m1 {
		&:before {
			background: #dedede;
		}
	}
	&.flag-s1 {
		&:before {
			background: #ffdd00;
		}
	}
}
