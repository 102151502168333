.viewer {
	width: 100%;
    margin-bottom: 10px;
	min-width: 36px;
	.viewer-close-button {
		padding: 0;
		margin: 2px 0;
		text-align: right;
		a {
			color: @text-color;
			text-decoration: none;
			&:hover {
				color: @text-color;
				text-decoration: underline;
			}
		}
	}
	.viewer-image-wrapper {
		width: 100%;
		height: 300px;
		position: relative;
		overflow: hidden;
		.viewer-loader {
			border: 2px solid #000;
			left: 50%;
			margin: -18px 0 0 -18px;
			position: absolute;
			top: 50%;
			z-index: 10;
		}
		.viewer-image {
			position: absolute;
			top: 0;
			left: 0;
			border: 1px solid #000;
			overflow: hidden;
			cursor: pointer;
			z-index: 9;
			p {
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 7px;
				margin: 0;
				right: 0;
				text-align: left;
				width: 100%;
				z-index: 2;
				background: #fff;
				background: rgba(255, 255, 255, 0.6);
				color: #000;
				.position {
					float: right;
				}
			}
		}
	}
	.viewer-next,
	.viewer-prev {
		position: absolute;
		top: 50%;
		right: 0;
		width: 25px;
		height: 90%;
		cursor: pointer;
		display: block;
		z-index: 200;
		/* Or else IE will hide it */
		background: url('../../../v3/img/skins/default/xv-viewer-trans.gif');
		transform: translateY(-50%);
	}
	.viewer-prev {
		left: 0;
		right: auto;
	}
	.icon-f {
		position: absolute;
		top: 50%;
		left: -12px;
		display: block;
		font-size: 50px;
		transform: translateY(-50%);
	}
	.voteGroup {
		.ratingBarBlock {
			margin-left: 4px;
		}
		.ratingBar {
			width: 50px;
		}
		.rating {
			font-size: 14px;
			padding: 3px;
			margin-left: 4px;
		}
	}
}

.viewer-preloads {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}