.global-feed {

@subs-scroll-height: 8px;
@subs-height: 120px;

#global-feed-subscriptions > .selection-wrapper {
	position: relative;
	display: flex;
	max-width: 100%;

	.xscroll-container {

		margin-bottom: unset;
		max-height: unset;
		padding-bottom: unset;

		&.xscroll-h-added {
			padding-bottom: @subs-scroll-height;
		}

		&.xscroll-autohide-show,
		&:not(.xscroll-autohide) {
			.xscroll-h {
				opacity: 0.35;
			}
		}

		.xscroll-h {
			height: @subs-scroll-height;

			.xscroll-grab {
				height: 5px;

				// margin: 10px;
				// transform: translateY(10px);
			}
		}

		.xscroll-content {
			overflow-y: hidden;
		}
	}

	&.floating {
		position: fixed;
		box-shadow: 0px -1px 20px 5px @global-feed-floating-sub-shadow;
		left: 50%;
		top: 0;
		z-index: 1000;

		transition: transform 500ms ease-in-out 0s;
		transform: translateX(-50%) translateY(-101%);
		&.float-displayed {
			transform: translateX(-50%) translateY(0);
		}
	}

	&.floating .floating-indicator {
		display: block;
	}
	.floating-indicator {
		display: none;
		position: absolute;
		z-index: 1000;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 100%);
		font-size: 150%;
		line-height: 0;

		.icon-f {
			background-color: @global-feed-subs-background;
    		border-radius: 0px 0px 5px 5px;
		}
	}
}

.subscriptions-selection-container,
.subscriptions-selected-container,
.subscriptions-selection,
.subscriptions-selected {
	position: relative;
	height: @subs-height + @subs-scroll-height;
	max-width: 100%;
	background-color: @global-feed-subs-background;

	display: flex;
	flex-direction: row;
}

.selection-wrapper.has-selected .subscriptions-selection {
	.subscription {
		opacity: 0.7;
		&:hover {
			opacity: 1;
			cursor: pointer;
		}
	}
}

.subscriptions-selected-container {
	max-width: 50%;

	border-radius: 2px;

	.xscroll-buttons-dim {
		display: none;
	}

	.subscriptions-selected {
		.subscription {
			background-color: @global-feed-subs-selected-background;
		}
	}
}

.subscriptions-selection-container {
	flex-grow: 1;
	overflow: hidden;
}

.subscription {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100%;
	padding: 6px 2px 8px;

	margin: 0px 2px 0px;
	width: 90px;

	opacity: 0.9;
	&:hover {
		opacity: 1;
		cursor: pointer;
	}
}

.subscription,
.subscription-hovericon {
	transition: opacity ease 200ms;
}

.subscription-hovericon {
	position: absolute;
	z-index: 50;

    color: white;

	width: fit-content;
	height: fit-content;

	opacity: 0;
}

.subscription-size-center(@size) {
	top: calc(50% - (@size/2));
	left: calc(50% - (@size/2));
	font-size: @size;
}

.subscription-indicator {
	position: absolute;
    bottom: 0;
    right: 0;
    margin: 5px;

	font-size: 130%;
    color: white;
	text-shadow: 0 0 3px black;
}

.subscription-hovericon.unselect {
	.subscription-size-center(40px);
}

.subscription-hovericon.select {
	.subscription-size-center(50px);
}

.subscriptions-selected-container {
	.subscription:hover {
		.subscription-hovericon.unselect {
			opacity: 1;
		}
	}
}

.subscriptions-selection-container {
	.subscription {
		&.selected {

			.subscription-thumb {
				img {
					opacity: .5;
				}
			}

			.subscription-hovericon.select {
				opacity: 1;
			}
		}
	}
}

.subscription-preview-button {
	display: none;
}
.subscriptions-selected-container {
	.subscription-username-container {
		display: flex;
	}
	.subscription.selected {
		.subscription-username-container {
			display: none;
		}
		.subscription-preview-button {
			display: flex;
		}
	}
}

.subscription-thumb {
	position: relative;
	overflow: hidden;
	width: 75px;
	height: 75px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 2px;
	}

	&:hover {
		.subscription-infos-tooltip {
			bottom: 0;
		}
	}

	.subscription-hoverselect {
		display: none;
	}
}

.subscription-thumb-under {
	width: 100%;
	height: calc(100% - 75px);

	display: flex;
    align-items: center;
    justify-content: center;
}

.subscription-username-container {
	margin: 5px 0px 0px;

	line-height: 15px;
	height: 30px;
	display: flex;
	align-items: center;

	.subscription-username {
		.line-clamp(2);
		text-align: center;
	}
}

.subscription-preview-button {
	width: 75%;
	padding: 3px;

	color: white;
	background-color: @red-color;
	border-radius: 3px;

	align-items: center;

	.subscription-preview-text {
		.line-clamp(2);
		width: 100%;
		text-align: center;
	}

	&:hover {
		background-color: darken(@red-color, 6%);
		cursor: pointer;
	}
}

.subscription-nbsubs {
	font-size: 85%;
	color: fadeOut(@text-color, 50%);
}

.subscription-infos-tooltip {
	@subs-tooltip-height: 40px;
	line-height: 18px;
	padding: 2px;

	color: #ddd;
	width: 100%;
	position: absolute;

	background-color: rgba(0,0,0,.75);
	display: flex;
	flex-direction: column;
	align-items: center;

	height: @subs-tooltip-height;
	bottom: -@subs-tooltip-height;
	transition: bottom ease .35s;
}

}

.sub-suggests-title {
	clear: both;
	font-size: 18px;
	font-weight: normal;
	line-height: 20px;
	margin: 0;
	padding: 3px 10px;
	overflow: hidden;

	.added-removed,
	.added-removed-cats {
		float: right;
		font-size: 14px;
		font-weight: normal;
		padding-left: 8px;
	}

	.added-removed {
		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.added-removed-cats {
		clear: right;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&.wrap {
		position: absolute;
		right: 0;
	}
}

.create-profile-popup {
	padding: 20px;
	.x-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.create-profile-popup-text {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.create-profile-popup-link {
		font-size: 16px;
		font-weight: bold;
		padding: 10px 20px;
		background-color: @red-color;
		color: white;
		border-radius: 5px;
		text-decoration: none;

		&:hover {
			background-color: darken(@red-color, 6%);
			cursor: pointer;
		}
	}
}

#sub-suggests {
	margin-bottom: 30px;
	#sub-suggests-cut ~ .thumb-block-premium-feed-account {
		display: none;
	}
}

html.is-desktop {
	.global-feed {

	.subscription-thumb {
		position: relative;
		overflow: hidden;
		width: 75px;
		height: 75px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 2px;
		}

		.subscription-hoverselect {
			position: absolute;
			z-index: 60;

			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			flex-direction: column;

			// .select-overlay,
			.select-filter {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				background-color: fadeOut(black, 75%);

				&:hover {
					background-color: fadeOut(black, 60%);
					cursor: pointer;
				}

				.select-text {
					font-size: 120%;
					color: white;
				}
			}
		}

		&:hover {
			.subscription-hoverselect {
				display: flex;
			}
		}
	}

	.selection-wrapper.has-selected .subscriptions-selection {
		.subscription {
			opacity: 0.4;
		}
		&:hover {
			.subscription {
				opacity: 0.7;
				&:hover {
					opacity: 1;
					cursor: pointer;
				}
			}
		}
	}

	.subscriptions-selection .subscription.selected {
		.subscription-thumb {
			.subscription-hovericon.unselect,
			.subscription-hoverselect {
				pointer-events: none;
				display: none;
			}
		}
	}
	.subscriptions-selected {
		.subscription-thumb .subscription-hoverselect {
			pointer-events: none;
			display: none;
		}
	}
	}
}