@ratio-logo: 4.125;
@ratio-txt: 0.8;

.thumb-block.thumb-ad-red {
	.thumb-ad-content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: auto;
		margin-top: -5px;
		transform : translateY(-50%);
		.thumb-ad-logo {
			margin-bottom: 5px;
		}
		.site-logo-svg {
			display: inline-block;
			width: auto;
			background: black;
			vertical-align: middle;
		}
		.site-logo-red {
			position: relative;
			right: auto;
			top: auto;
			display: inline-block;
			vertical-align: middle;
		}
		p {
			height: auto;
			font-weight: bold;
			line-height: 1.3;
			text-overflow: initial;
			text-shadow: 1px 1px 4px rgba(0,0,0,0.7);
			white-space: normal;
			color: white;
			overflow: initial;
			&.nb-uploads {
				margin-top: 10px;
			}
		}
	}
	.ad {
		display: inline-block;
		position: relative;
		height: 18px;
		margin-left: 4px;
		padding: 0 3px;
		font-size: .83em;
		font-weight: bold;
		line-height: 18px;
		color: #fff;
		background: @theme-color;
		border-radius: 3px;
		z-index: 3;
	}
	
	&.bg-gradient-black {
		.thumb {
			a {
				background: #000000;
				background: linear-gradient(30deg, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0.7));
			}
		}
		.thumb-ad-content {
			.site-logo-svg {
				background : none;
			}
		}
	}
	
	&.bg-red {
		.thumb {
			a {
				background: @theme-color;
			}
		}
		.thumb-ad-content {
			.site-logo-red {
				text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
			}
		}
	}
}

@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
	.thumb-block.thumb-ad-red,
	.nb-thumbs-cols-sm-3 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 3.911342894vw;
				width: 3.911342894vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 6.258148631vw;
				margin-left: 0.651890482vw;
			}
			p {
				padding: 0 1vw;
				font-size: 1.8vw;
				&.nb-uploads {
					margin-top: 5px;
					font-size: 1.8vw * @ratio-txt;
				}
			}
		}
	}
	
	.nb-thumbs-cols-sm-2 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 5.475880052vw;
				width: 5.475880052vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 8.604954368vw;
				margin-left: 0.912646675vw;
			}
			p {
				font-size: 2.8vw;
				&.nb-uploads {
					margin-top: 10px;
					font-size: 2.8vw * @ratio-txt;
				}
			}
		}
	}
	
	.nb-thumbs-cols-sm-4 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.thumb-ad-logo {
				margin: 0;
			}
			.site-logo-svg {
				height: 2.604166667vw;
				width: 2.604166667vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 4.172099087vw;
				margin-left: 0.260756193vw;
			}
			p {
				font-size: 1.5vw;
				&.nb-uploads {
					margin-top: 5px;
					font-size: 1.5vw * @ratio-txt;
				}
			}
		}
	}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-lg-max) {
	.thumb-block.thumb-ad-red,
	.nb-thumbs-cols-lg-4 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 2.635431918vw;
				width: 2.635431918vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 4.245973646vw;
				margin-left: 0.390625vw;
			}
			p {
				padding: 0 1vw;
				font-size: 1.5vw;
				&.nb-uploads {
					font-size: 1.5vw * @ratio-txt;
				}
			}
		}
	}
	
	.nb-thumbs-cols-lg-3 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 3.660322108vw;
				width:  3.660322108vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 5.856515373vw;
			}
			p {
				font-size: 2vw;
				&.nb-uploads {
					font-size: 2vw * @ratio-txt;
				}
			}
		}
	}
	
	.nb-thumbs-cols-lg-5 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 2.196193265vw;
				width: 2.196193265vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 3.513909224vw;
			}
			p {
				font-size: 1.2vw;
				&.nb-uploads {
					font-size: 1.2vw * @ratio-txt;
				}
			}
		}
	}
}

@media (min-width: @screen-xlg-min) {
	.thumb-block.thumb-ad-red,
	.nb-thumbs-cols-xlg-5 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 2.083333333vw;
				width : 2.083333333vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 3.333333333vw;
				margin-left: 0.260416667vw;
			}
			p {
				padding: 0 1vw;
				font-size: 1.1vw;
				&.nb-uploads {
					font-size: 1.1vw * @ratio-txt;
				}
			}
		}
	}
	
	.nb-thumbs-cols-xlg-4 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 2.5vw;
				width: 2.5vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 3.854166667vw;
			}
			p {
				font-size: 1.35vw;
				&.nb-uploads {
					font-size: 1.35vw * @ratio-txt;
				}
			}
		}
	}
	
	.nb-thumbs-cols-xlg-6 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 1.666666667vw;
				width: 1.666666667vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 2.604166667vw;
			}
			p {
				font-size: 0.9vw;
				&.nb-uploads {
					font-size: 0.9vw * @ratio-txt;
				}
			}
		}
	}
}

@media (max-width: @screen-xxs-max) {
	.thumb-block.thumb-ad-red,
	.nb-thumbs-cols-xs-1 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.site-logo-svg {
				height: 10.144927536vw;
				width: 10.144927536vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 15.942028985vw;
				margin-left: 1.207729469vw;
			}
			p {
				padding: 0 3vw;
				font-size: 5.25vw;
				&.nb-uploads {
					font-size: 5.25vw * @ratio-txt;
				}
			}
		}
	}
	
	.nb-thumbs-cols-xs-2 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			.thumb-ad-logo {
				margin: 0;
			}
			.site-logo-svg {
				height: 5.314009662vw;
				width: 5.314009662vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 8.212560387vw;
				margin-left: 0.483091787vw;
			}
			p {
				padding: 0 2vw;
				font-size: 3vw;
				&.nb-uploads {
					margin-top: 3px;
					font-size: 3vw * @ratio-txt;
				}
			}
		}
	}
	
	.nb-thumbs-cols-xs-3 .mozaique.cust-nb-cols .thumb-block.thumb-ad-red {
		.thumb-ad-content {
			margin-top: -3px;
			.thumb-ad-logo {
				margin: 0;
			}
			.site-logo-svg {
				height: 3.381642512vw;
				width: 3.381642512vw * @ratio-logo;
			}
			.site-logo-red {
				font-size: 5.314009662vw;
			}
			p {
				padding: 0 1vw;
				font-size: 2vw;
				&.nb-uploads {
					margin-top: 2px;
					font-size: 2vw * @ratio-txt;
				}
			}
		}
	}
}
