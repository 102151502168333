.nutaku-mig-pop {
	&.x-overlay.x-overlay-box {
		background-color: @nutaku_migration_overlay_bg_color;

		.x-body {
			background-color: @nutaku_migration_bg_color;
			background-position: top right;
			background-repeat: no-repeat;
			border-radius: 16px;
			color: @nutaku_migration_color_main;
			max-width: 100%;
			position: relative;

			.x-close {
				right: 12px;
				top: 10px;
			}

			.nutaku-mig__start-playing {
				color: @nutaku_migration_color_title;
				font-size: 44px;
				line-height: 56px;
				margin: 0 0 24px;
				text-transform: uppercase;
			}

			.nutaku-mig__confirm-for {
				font-weight: bold;
				margin: 0 0 10px;
				text-transform: uppercase;
			}

			.nutaku-mig__confirm-for-list {
				margin: 0 0 24px;
			}

			.nutaku-mig__confirm-for-list li {
				font-weight: bold;
				list-style: disc;
			}

			.btn {
				border-radius: 10px;
				display: block;
				font-weight: bold;
				line-height: 1.2;
				margin: 0 0 18px;
				padding: 14px 16px;
				text-align: center;
				white-space: normal;
				width: 100%;
				word-break: break-word;

				.no-thx {
					text-decoration: underline;
				}
			}

			.btn-primary {
				color: @nutaku_migration_btn_ok_color;
				background: @nutaku_migration_btn_ok_bg_color;
				font-size: 22px;
				text-transform: uppercase;

				&:hover {
					background: @nutaku_migration_btn_ok_hover_bg_color;
				}
			}

			.btn-secondary {
				background: transparent;
				border: 1px solid @nutaku_migration_btn_nok_border_color;
				font-size: 16px;

				.main {
					display: block;
					color: @nutaku_migration_btn_nok_main_color;

					& + .second {
						padding-top: 4px;
					}
				}
				.second {
					display: block;
					font-weight: normal;
					color: @nutaku_migration_btn_nok_second_color;
				}

				&:hover {
					border-color: @nutaku_migration_btn_nok_hover_border_color;
					.main {
						color: @nutaku_migration_btn_nok_hover_main_color;
					}
					.second {
						color: @nutaku_migration_btn_nok_hover_second_color;
					}
				}
			}




			/* MOBILE */
			@media (max-width: @screen-sm-max) {
				background-image: url(@nutaku_migration_bg_mobile);
				padding: 270px 36px 30px;
				width: 390px;

				.nutaku-mig__hey {
					margin: 0 0 12px;
					font-size: 20px;
				}

				.nutaku-mig__start-playing {
					font-size: 36px;
					line-height: 40px;
					margin: 0 0 18px;
				}

				.nutaku-mig__confirm-for,
				.nutaku-mig__confirm-for-list li {
					font-size: 20px;
				}

				.nutaku-mig__logo {
					text-align: center;
				}
			}

			/* DESKTOP */
			@media (min-width: @screen-md-min) {
				background-image: url(@nutaku_migration_bg_desktop);
				min-height: 600px;
				padding: 70px 70px 30px;
				width: 900px;

				.nutaku-mig__cont {
					width: 440px;
				}

				.nutaku-mig__hey {
					margin: 0 0 16px;
					font-size: 24px;
				}

				.nutaku-mig__confirm-for,
				.nutaku-mig__confirm-for-list li {
					font-size: 22px;
				}

				.btn-primary {
					padding: 18px 16px;
				}

				.nutaku-mig__logo {
					bottom: 58px;
					position: absolute;
					right: 60px;
				}
			}
		}
	}
}