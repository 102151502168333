@help-wizard-zindex-max-index:		9999;
@help-wizard-zindex-skip:			@help-wizard-zindex-max-index;
@help-wizard-zindex-mask:			@help-wizard-zindex-skip - 1;
@help-wizard-zindex-loader:			@help-wizard-zindex-mask - 1;
@help-wizard-zindex-description:	@help-wizard-zindex-loader - 1;
@help-wizard-zindex-cursor:			@help-wizard-zindex-description - 1;
@help-wizard-zindex-focus:			@help-wizard-zindex-cursor - 1;
@help-wizard-zindex-layer:			@help-wizard-zindex-focus - 1;

.help-wizard-cover,
.help-wizard-mask {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: @help-wizard-zindex-mask;
}

.help-wizard-cover {
	&.help-wizard-hiding-mask,
	&.help-wizard-no-mask {
		&,
		.help-wizard-mask,
		.help-wizard-mask .help-wizard-layer {
			bottom: auto;
			height: auto;
			left: auto;
			position: static;
			right: auto;
			top: auto;
			width: auto;
		}
	}
	
	&.help-wizard-hiding-mask {
		.help-wizard-mask {
			.help-wizard-focus {
				box-shadow: 0 0 0 9999px fadeOut(@help-wizard-mask-bg, 100%), 0 0 7px 7px inset fadeOut(@help-wizard-mask-bg, 100%);
			}
		}
	}
	
	&.help-wizard-no-mask {
		.help-wizard-mask {
			.help-wizard-focus {
				box-shadow: none;
				height: 0!important;
				width: 0!important;
			}
		}
	}
}

.help-wizard-mask {
	opacity: 0;
	overflow: hidden;
	
	.help-wizard-layer {
		bottom: 0;
		height: 100%;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: @help-wizard-zindex-layer;
		
		&.loader-layer {
			background: @help-wizard-mask-bg;
			cursor: default;
		}
	}
	
	.help-wizard-focus {
		background: transparent;
		box-shadow: 0 0 0 999px @help-wizard-mask-bg;
		box-shadow: 0 0 0 9999px @help-wizard-mask-bg;
		box-shadow: 0 0 0 9999px @help-wizard-mask-bg,  0 0 7px 7px inset @help-wizard-mask-bg;
		position: absolute;
		transition: box-shadow 0.4s ease;
		z-index: @help-wizard-zindex-focus;
	}
	
	.help-wizard-cursor {
		display: none;
		font-size: 20px;
		position: absolute;
		z-index: @help-wizard-zindex-cursor;
		
		&.click-pulse {
			&:after {
				animation: click-pulse 0.6s forwards;
				animation-iteration-count: infinite;
				background: rgba(0, 0, 0, 0.2);
				content: ' ';
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -35px 0 0 -35px;
				width: 70px;
				height: 70px;
				border-radius: 50%;
				opacity: 0;
				pointer-events: none;
			}
		}
		@keyframes click-pulse {
			 0% {
				 transform: scale3d(0.3, 0.3, 1);
			 }
			 13%, 25% {
				 opacity: 1;
			 }
			 50% {
				 opacity: 0;
				 transform: scale3d(1.2, 1.2, 1);
			 }
		 }
		
		.icf-hand-pointer-o {
			color: #000;
			display: inline-block;
			position: relative;
			.text-border-1px(#fff);
			z-index: 2;
		}
		
		.icf-hand-pointer {
			color: #fff;
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
	}
	
	.help-wizard-description {
		background: @help-wizard-description-bg;
		border-radius: 3px;
		box-shadow: 0 0 10px @help-wizard-mask-bg;
		display: none;
		padding: 6px 14px;
		position: absolute;
		z-index: @help-wizard-zindex-description;
		
		&.no-text {
			background: none;
			box-shadow: none;
		}
		
		.help-wizard-description-content {
			color: @help-wizard-description-color;
			font-size: 16px;
			line-height: 1.4;
		}
		
		.help-wizard-next {
			//background-image: linear-gradient(to right, rgba(255,255,255,0.2) 0%, transparent 0.1%);
			//margin-top: 4px;
			margin: 0;
			text-align: right;
			
			.btn {
				background-image: linear-gradient(90deg, @help-wizard-auto-next-bg 0%, @help-wizard-auto-next-bg 49.9%, transparent 50.01%, transparent 100%);
				background-position: ~"100% 50%";
				background-size: 210% 200%;
				font-size: 13px;
				font-weight: bold;
				margin: 0;
				
				&.auto-next {
					//background-image: linear-gradient(to right, rgba(255,255,255,0.2) 99.9%, transparent 100%);
					background-position: ~"0 50%";
					transition: background 5s ease;
				}
			}
		}
	}
	
	.help-wizard-loading {
		color: @help-wizard-loader-color;
		font-size: 40px;
		display: inline-block;
		height: 40px;
		width: 40px;
		line-height: 40px;
		left: 50%;
		margin: -20px 0 0 -20px;
		position: fixed;
		text-align: center;
		top: 50%;
		width: 40px;
		z-index: @help-wizard-zindex-loader;
	}
}

.help-wizard-cover {
	.help-wizard-skip {
		background: @help-wizard-mask-skip-bg;
		border: 1px solid @help-wizard-mask-skip-border;
		bottom: 2px;
		cursor: pointer;
		font-size: 14px;
		font-weight: bold;
		right: 2px;
		padding: 10px;
		position: fixed;
		text-decoration: none;
		z-index: @help-wizard-zindex-skip;
		
		&:hover {
			border-color: lighten(@help-wizard-mask-skip-border, 50%);
		}
		
		&.left {
			left: 2px;
			right: auto;
		}
		
		&.right {
			left: auto;
			right: 2px;
		}
		
		&.bottom {
			bottom: 2px;
			top: auto;
		}
		
		&.top {
			bottom: auto;
			top: 2px;
		}
	}
}