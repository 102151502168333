.live_model_thumb {
	height: 131px;
	position: relative;

	img {
		display: block;
		width: 100%;
	}
	
	&:hover {
		img {
			box-shadow: 0 0 0 2px #fff;
		}
	}

	video {
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;	
		position: absolute;
		width: 100%;		
	}
}

.mac-models {
	width: 580px;
	
	h2 {
		float: left;
		font-size: 16px;
		line-height: 26px;
		margin: 0;
	}
	
	a.btn {
		float: right;
		font-size: 12px;
		margin: 0;
	}
	
	.models {
		clear: both;
		font-size: 0;
		
		.model {
			display: inline-block;
			font-size: 14px;
			margin: 20px 5% 0 0;
			position: relative;
			vertical-align: top;
			text-decoration: none;
			width: 30%;
			
			&:nth-child(3n) {
				margin-right: 0;
			}
			
			.name {
				display: block;
				font-weight: bold;
				padding-top: 4px;
			}
			
			.live {
				position: absolute;
				right: 2px;
				top: 4px;
			}
			
			&.not-live {
				.live {
					display: none;
				}
			}
		}
	}
}

.mac-models-loading {
	width: 300px;
	text-align: center;
}