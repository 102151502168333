@import "activity_fanclub.less";

#profile-title {
	margin: 0 4px;
	max-width: 100%;
	min-height: 48px;
	position: relative;

	.profile-infos {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		& > div {
			display: flex;
    		flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			max-width: 100%;
			overflow: auto;
		}

		.top-right {
			.user-subscribe {
				display: block;
				position: static;
			}
			
			.user-actions,
			.friend-request-cont {
				display: block;
				position: static;
			}
			
			.friend-request-cont {
				text-align: right;
				
				button {
					margin-top: 4px;
				}
			}
		
			.user-actions{
				overflow: hidden;
				
				.user-subscribe,
				.sub-strip {
					display: block;
					position: static;
					float: right;
					transition: .5s;
					margin-top: 2px;
					margin-bottom: 2px;
				}
			}
		}

		.right-wrapper {
			display: flex;
			align-items: center;
			flex-direction: row;
			height: fit-content;
			margin-left: 8px;
			overflow: visible;

			// All direct children with a previous brother
			& > * + * {
				margin-left: 8px;
			}

			#profile-action {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				position: relative;
				height: 30px;
				width: 30px;

				.menu-icon.alert {
					color: @theme-color;
				}

				&:hover {
					background: rgba(0,0,0,.6);
					border-radius: 3px;
					.cross-icon, .kebab-icon {
						color: white;
					}
				}

				&.active {
					background: @tooltip-bg;
					border-radius: 3px;

					.cross-icon {
						display: flex;
						align-items: center;
						margin: 0;
						.icon-f {
							font-size: 13px;
						}
					}

					.menu-icon {
						display: none;
					}

					.kebab-icon {
						visibility: hidden;
					}
				}
				.cross-icon, .kebab-icon, .ban-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0;

					.icon-f {
						font-size: 18px;
					}
				}
				.cross-icon {
					position: absolute;
					margin-top: 1px;
					color: white;
					font-size: 22px;
					user-select: none;
					display: none;
				}
				.menu-icon {
					position: absolute;
					top: 0;
					right: 0;
					font-size: 10px;
					user-select: none;
					display: flex;
					z-index: -1;
				}

				.menu-custom {
					.rating-wrapper {
						display: flex;
						flex-direction: row;
						align-items: center;

						.rating-part {
							width: 100%;

							.vote-info {
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
								margin: 10px 0px 5px 20px;
								width: 80%;
							}
	
							.custom-rating-bar {
								background: @theme-color;
								height: 4px;
								width: 80%;
								margin: 0 0px 10px 20px;
								border-radius: 5px;

								.custom-inner-rating-bar {
									background: @gray;
									width: 0%;
									height: 100%;
								}
							}
						}
					}

					.custom-vote-action-good.is-voted,
					.custom-vote-action-bad.is-voted {
						.icon-f {
							color: @theme-color;
						}

						&:hover {
							.icon-f {
								color: darkred;
							}
						}
					}

					.menu-vote-info {
						width: 10%;
						margin-right: 10px;
						font-size: 16px;
					}
				}
			}
		}
	}
	
	.profile-pic {
		float: left;
		margin-right: 10px;
		margin-bottom: 4px;
		position: relative;
		
		[dir="rtl"] & {
			float: right;
			margin-left: 10px;
			margin-right: 0;
		}
		
		.external-link {
			bottom: 0;
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			text-decoration: none;
			z-index: 1;
		}
		
		img {
			border: 1px solid @img-border-color;
			display: block;
			height: 60px;
			
			&.lg-pic-opener {
				cursor: pointer;
			}
		}
		
		.chat-toggle-button-outside {
			background: #000;
			bottom: 0;
			color: #fff;
			cursor: pointer;
			font-size: 20px;
			height: 28px;
			left: 0;
			left: calc(~"100% - 28px");
			line-height: 32px;
			position: absolute;
			text-align: center;
			width: 28px;
			z-index: 2;
		}
	}
	
	h2 {
		display: block;
		font-size: 15px;
		margin: 0;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		
		> * {
			font-size: 20px;
			line-height: 32px;
			vertical-align: top;
		}
		
		> .mobile-user-actions {
			font-size: 0;
		}
		
		.flag {
			&.flag-question,
			&.flag-p1,
			&.flag-p2,
			&.flag-p3,
			&.flag-p4,
			&.flag-p5,
			&.flag-p6,
			&.flag-p7,
			&.flag-p8,
			&.flag-p9,
			&.flag-m1,
			&.flag-s1 {
				margin-top: 6px;
			}
			&.flag-p3 {
				height: 20px;
			}
		}
		
		.video-subscribe-square {
			vertical-align: text-bottom;
		}
		
		.prof-verified-link {
			text-decoration: none;
			
			.icon-f {
				line-height: 32px;
			}
		}
		
		&.with-aka {
			float: left;
		}
		
		small {
			color: @text-color;
			font-size: 16.5px;
			
			&.after-aka {
				float: right;
				padding-left: 4px;
				line-height: 32px;
			}
			
			&.aka {
				a {
					text-decoration: none;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		
		& > .playlist-edit {
			cursor: pointer;
			margin: 0 0 4px;
			padding: 0 10px 0 8px;
			
			&:hover {
				.text-danger {
					color: @red-color;
				}
			}
		}
		
		.profile-maincat-switch {
			background: @body-bg;
			border: 0;
			font-size: 14px;
			font-weight: bold;
			padding: 0 6px;
			
			&:hover {
				background: @gray-lighterer;
			}
			
			.profile-maincat-switch-label {
				& > span {
					&:first-child {
						vertical-align: unset;
						
						& > span {
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
			}
			
			.icf-caret-down {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
	
	.btn-link {
		&, &:hover, &:focus, &:active {
			background: transparent;
			border-color: transparent;
			color: @text-color;
			cursor: default;
			outline: none;
			text-decoration: none;
			
			.text-danger {
				color: @red-color;
			}
		}
	}
	
	.chat-toggle-button-outside {
		display: none;
	}
	
	.friend-request-cont {
		line-height: 22px;
		padding-bottom: 0;
		padding-top: 0;
		position: absolute;
		right: 0;
		top: 24px;
		
		button {
			font-size: 14px;
			font-weight: normal;
			height: 22px;
			line-height: 20px;
			margin: 0;
			padding-bottom: 0;
			padding-top: 0;
			vertical-align: top;
		}
		
		.icon.add-friend {
			vertical-align: top;
		}
	}
	
	.user-subscribe,
	.sub-strip {
		.button-variant(@profile-btn-subscribe-text; @profile-btn-subscribe-bg; @profile-btn-subscribe-border);
		border-width: 1px;
		border-style: solid;
		height: 22px;
		padding: 0 4px;
		position: absolute;
		top: 0;
		right: 0;
		
		&, * {
			font-size: 14px;
			line-height: 20px;
		}
		
		* {
			height: 20px;
		}
	}
	
	.sub-strip {
		&.user-subscribe.user-reject-active,
		&.user-reject:not(.active) {
			.button-variant(@profile-btn-reject-text; @profile-btn-reject-bg; @profile-btn-reject-border);
		}
	}

	@media (min-width: @screen-sm-min) {
		.user-subscribe {
			margin-left: 4px;
		}
	}

	.friend-request-cont {
		.premium-page.not-premium-logged & {
			display: none;
		}
	}
	
	.actions {
		float: right;
		margin: 2px 0 2px 10px;
	}
	
	.btn-link:hover {
		text-decoration: none;
		cursor: default;
	}
	
	&.no-pic {
		h2 {
			margin: 2px 0;
		}
		
		.actions {
			margin-top: 8px;
			margin-bottom: 0;
		}
	}
	
	
	&.has-banner-slider .embed-responsive:not(.banner-slider) .embed-responsive-item:first-child:last-child,
	.embed-responsive.banner-slider .banner-slide,
	.embed-responsive.banner-slider .banner-slide .ticket-in {
		align-items: center;
		background: none;
		border: 0;
		display: flex;
		flex-grow: 99;
		justify-content: center;
		text-decoration: none;
		
		.btn {
			.btn-custom(#fff, #000, #222, #000, #222, #000, rgba(0, 0, 0, 0.5));
			border: 0;
			font-size: 24px;
			margin: 2px 5px;
			position: relative;
			text-transform: uppercase;
			vertical-align: middle;
			white-space: normal;
			z-index: 5;
			
			.icon-f {
				display: inline-block;
				font-size: 1.1em;
				vertical-align: text-bottom;
			}
			
			.icf-ticket-red {
				color: @red-color;
				font-size: 1.45em;
				vertical-align: top;
			}
		}
	}

	.embed-responsive {
		&.banner-slider {
			font-weight: bold;
			margin-bottom: 10px;
			
			.banner-personal {
				background-color: @logo-bg;
				
				.img-responsive {
					margin: 0 auto;
					max-height: stretch;
				}
			}
			
			& > .banner-slide {
				display: none;
				
				&:first-child:last-child,
				&.banner-first-forced {
					display: flex;
				}
			}
			
			.banner-slide:not(.banner-personal) {
				background: @red-color;
				color: #fff;
				font-size: 24px;
				padding: 0 45px;
				text-align: center;
				
				&:not(.ticket-red) {
					background-image: linear-gradient(135deg, @post-comment-s1-bg-left, @post-comment-s1-bg-right);
				}
				
				&.ticket-red {
					background: none;
					
					.ticket-in {
						background: @red-color;
						font-size: 24px;
						height: 142px;
						text-align: center;
					}
					.ticket-back .icon-f {
						font-size: 144px;
					}
				}
			}
			
			.banner-slide:first-child {
				left: 0;
				min-height: inherit;
				max-height: 200px;
				position: relative;
			}
			
			.banner-slider-container {
				height: calc(14.59vw);
   				min-height: 62px;
				z-index: 1;

				//.banner-slide {
				//	height: stretch;
				//}
				
				.banner-slide + .banner-slide + .banner-slide {
					display: none;
				}
				
				.banner-slide:nth-child(2) {
					left: 100%;
				}
			}
			
			&.to-next {
				.banner-slide:first-child {
					animation: .5s bannerSliderToSecondForFirst;
				}
				.banner-slide:nth-child(2n) {
					animation: .5s bannerSliderToSecondForSecond;
				}
			}
			
			&.to-prev {
				.banner-slide:first-child {
					animation: .5s bannerSliderToFirstForFirst;
				}
				.banner-slide:nth-child(2n) {
					animation: .5s bannerSliderToFirstForSecond;
				}
			}
			
			@keyframes bannerSliderToSecondForFirst {
				from { left: 0; }
				to   { left: -100%; }
			}
			@keyframes bannerSliderToSecondForSecond {
				from { left: 100%; }
				to   { left: 0; }
			}
			@keyframes bannerSliderToFirstForFirst {
				from { left: -100%; }
				to   { left: 0; }
			}
			@keyframes bannerSliderToFirstForSecond {
				from { left: 0; }
				to   { left: 100%; }
			}
			
			.banner-slider-left,
			.banner-slider-right {
				background: none;
				border: none;
				color: #fff;
				font-size: 10px;
				height: 30px;
				padding: 0;
				position: absolute;
				top: calc(~"50% - 15px");
				width: 50px;
				z-index: 2;
				
				&:focus, &:active, &:focus:active {
					outline: 0;
				}
				
				.icon-f {
					background: fadeOut(black, 90%);
					border-radius: 50%;
					display: block;
					height: 20px;
					line-height: 20px;
					position: absolute;
					text-align: center;
					top: calc(~"50% - 10px");
					width: 20px;
				}
				
				.notouch &:hover {
					.icon-f {
						background: fadeOut(black, 80%);
					}
				}
			}
			.banner-slider-left {
				left: 0;
				
				.icon-f {
					left: 20px;
					padding-right: 2px;
				}
			}
			.banner-slider-right {
				right: 0;
				
				.icon-f {
					right: 20px;
					padding-left: 2px;
				}
			}
		}
	}
	
	&.banner-sliders,
	&.has-banner-slider {
		.embed-responsive {
			min-height: 62px;
			height: fit-content;
			margin-bottom: 10px;
			
			&:not(.banner-slider) .embed-responsive-item:first-child:last-child {
				background-color: @logo-bg;
				padding: 0;
			}
		}

		.top-right {
			top: 149px;
		}
	}
	
	&.has-banner-slider,
	&.has-banner {
		.website {
			background: #000;
			background: fadeOut(#000, 40%);
			border-radius: 2px;
			bottom: 2px;
			color: #fff;
			font-size: 14px;
			font-style: italic;
			font-weight: normal;
			line-height: 22px;
			padding: 0 12px;
			position: absolute;
			right: 2px;
		}
	}
	
	.setup-banner {
		position: absolute;
		top: 2px;
		right: 2px;
		z-index: 5;
		
		.icon-f {
			display: block;
			font-size: 18px;
		}
	}
	
	&.has-banner {
		margin: 4px 0 6px;
		max-width: none;
		position: relative;
		
		.embed-responsive {
			padding-bottom: 15.625%;
			
			.embed-responsive-item {
				display: flex;
				//max-height: stretch;
				justify-content: center;
				
				/*& + .join-club {
					bottom: 2px;
					margin: 0;
					position: absolute;
					right: 2px;
					width: auto;
				}*/
			}
			
			/*a.embed-responsive-item {
				& + .join-club {
					bottom: 26px;
				}
			}*/
			
			.add-banner {
				color: #fff;
				position: absolute;
				top: 2px;
				right: 8px;
				font-weight: bold;
				text-decoration: none;
				font-size: 20px;
				font-variant: small-caps;
				
				&:hover {
					text-decoration: underline;
				}
			}
		}
		
		.profile-pic {
			bottom: 38px;
			float: none;
			left: 2px;
			margin: 0;
			position: absolute;
			direction: ltr;
			top: 2px;
			
			img {
				width: auto;
				height: 100%;
			}
		}
		
		h2 {
			background: fadeOut(#000, 20%);
			border-radius: 2px;
			bottom: 2px;
			color: #fff;
			height: 32px;
			left: 2px;
			line-height: 32px;
			margin: 0;
			max-width: 70%;
			overflow: hidden;
			padding: 0 10px;
			position: absolute;
			white-space: nowrap;
			
			.flag {
				float: left;
				margin-right: 6px;
				
				&.flag-p1,
				&.flag-p2,
				&.flag-p4,
				&.flag-p3,
				&.flag-p5,
				&.flag-p6,
				&.flag-p7,
				&.flag-p8,
				&.flag-p9,
				&.flag-m1,
				&.flag-s1 {
					box-sizing: content-box;
					margin-top: 5px;
					border: 1px solid #333333;
				}
				&.flag-p3 {
					width: 30px;
					height: 20px;
				}
			}
			
			small {
				color: #fff;
			}
			
			br {
				display: none;
			}
			
			.fan-video {
				background: #fff;
				color: #000;
			}
			
			a {
				&,
				&:hover {
					color: #fff;
				}
				
				&.text-danger {
					&,
					&:hover {
						color: @red-color;
					}
				}
			}
			
			.profile-maincat-switch {
				background: transparent;
				
				&:hover {
					background: #222;
				}
			}
		}
		
		.btn-link {
			background: @btn-default-bg;
		}
		
		.video-subscribe {
			position: absolute;
			top: 2px;
			right: 2px;
		}
		
		
		.sub-strip {
			right: 2px;
			top: 2px;
			left: auto;
			
			&.no-active {
				display: none !important;
			}
		}
		
		.user-actions {
			position: absolute;
			right: 2px;
			top: 2px;
			left: auto;
			
			.sub-strip {
				display: inline-block;
				position: relative;
				right: auto;
				top: auto;
				
				& ~ .sub-strip {
					margin-right: 2px;
				}
			}
		}
		
		.friend-request-cont {
			right: 2px;
			top: 26px;
		}
	}
	
	[dir="rtl"] & {
		.friend-request-cont,
		.user-subscribe,
		.sub-strip,
		&:not(.has-banner) .top-right {
			left: 0;
			right: auto;
		}
	}
}

.popup-darker {
	.x-popup-content {
		background: @tooltip-bg-lighten;
		box-shadow: 4px 4px 18px 1px rgba(0, 0, 0, .75);
		padding: 10px 20px;
	}
	&.x-popup.below {
		.x-popup-arrow {
			border-bottom-color: @tooltip-bg-lighten;
			margin-right: 13px;
		}
	}
	.x-popup-close {
		margin-right: 13px;
	}
}

.profile-page {
	.profile-action-popup {
		.x-popup-content {
			padding: 8px 0;

			.dropdown {
				list-style: none;
				margin: 0;
				padding: 0;

				ul {
					margin: 0;
					padding: 0;
				}
				
				li {
					display: block;
					width: 100%;
					padding: 0;
					
					a {
						width: 100%;
						display: inline-block;
						color: @btn-primary-color;
						
						&.btn {
							font-weight: 400;
							padding-top: 4px;
							padding-bottom: 4px;
							border: 0;
							background: 0 0;
							display: flex;
							align-items: center;
							font-size: 16px;
							line-height: 24px;
							text-align: left;
							text-decoration: none;
							padding: 6px 20px;
							width: 100%;
						}
						
						span.icon-f {
							margin-right: 10px;
						}
					}

					&.label {
						font-size: 14px;
						padding: 6px 20px 2px;
					}

					&.separator:hover,
					&.label:hover {
						background-color: initial;
					}

					.menu-separator {
						height: 1px;
						background-color: @gray;
						margin: 0 auto 4px;
						width: 90%;
					}

					.menu-action {
						margin: 0 0 4px;
						&:hover {
							background-color: @theme-color;
						}
					}

					.menu-double-action {
						display: flex;
						align-items: center;
						justify-content: center;
						.menu-action {
							min-width: fit-content;
							position: relative;

							.loader {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
					}

					.menu-custom {
						.rating-wrapper {
							display: flex;
    						flex-direction: row;
							align-items: center;

							.rating-part {
								width: 100%;

								.vote-info {
									display: flex;
									flex-direction: row;
									justify-content: space-between;
									align-items: center;
									margin: 10px 0px 5px 20px;
									width: 80%;
								}
		
								.custom-rating-bar {
									background: @theme-color;
									height: 4px;
									width: 80%;
									margin: 0 0px 10px 20px;
									border-radius: 5px;

									.custom-inner-rating-bar {
										background: @gray;
										width: 0%;
										height: 100%;
									}
								}
							}
						}

						.custom-vote-action-good.is-voted,
						.custom-vote-action-bad.is-voted {
							.icon-f {
								color: @theme-color;
							}

							&:hover {
								.icon-f {
									color: darkred;
								}
							}
						}

						.menu-vote-info {
							width: 10%;
							margin-right: 10px;
							font-size: 16px;
						}
					}

					span {
						&.non-clickable {
							cursor: initial;
						}
					} 
				}
				
				&:hover {
					ul {
						transform: scale(1);
					}
				}
			}

			.share-buttons-container {
				button {
					border: 0;
					background: 0 0;
					display: block;
					font-size: 16px;
					line-height: 24px;
					text-align: left;
					text-decoration: none;
					transition: padding .5s;
					padding: 6px 20px;
					width: 100%;

					&:hover {
						background: @theme-color;
						color: #fff;
						text-decoration: none;
					}
				}

				.share-toolbox {
					padding: 0px 15px 6px;
				}
			}
		}
	}
}

#header-about-me,
#main .rating-box,
#pinfo-workedfor {
	.show-more {
		background: lighten(@gray-light, 15%);
		border-radius: 3px;
		color: #fff;
		cursor: pointer;
		font-size: 20px;
		font-weight: bold;
		height: 18px;
		line-height: 19px;
		text-align: center;
		transition: all 0.2s;
		width: 18px;
		
		&:hover {
			background: lighten(@gray-light, 5%);
		}
		
		&:active,
		&:focus {
			box-shadow: 0 0 0 3px lighten(@gray-light, 35%);
		}
	}
}

#header-about-me {
	background: @gray-lighter;
	border-radius: 5px;
	clear: both;
	cursor: pointer;
	font-size: 14px;
	overflow: hidden;
	margin: 5px 0 16px;
	padding: 4px 26px 4px 8px;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	
	br {
		display: none;
	}
	
	.show-more {
		bottom: 5px;
		position: absolute;
		right: 5px;
	}
	
	&.expanded {
		cursor: auto;
		white-space: normal;
		
		br {
			display: inline;
		}
	}
}

.profile-action-link-popup .x-popup-content {
	max-width: 400px;
}

#profile-report-form {
	.radio label {
		font-weight: 700;
	}
	
	.x-overlay .x-body & .help-block.error-block {
		color: @theme-color;
	}
}

#profile-tabs {
	margin: 3px 0 5px;
	padding: 0;
	
	/* @todo to remove after cache regen */
	.tab-buttons {
		border-bottom: 1px solid @home-tab-border;
		float: none;
		height: 34px;
		overflow: visible;
		padding: 0;
		
		li {
			.btn {
				background: @home-tab-bg;
				border: 1px solid @home-tab-border;
				border-radius: 2px 2px 0 0;
				box-shadow: none;
				color: #fff;
				font-size: 14px;
				font-weight: normal;
				height: 34px;
				line-height: 26px;
				margin: 0 0 -1px;
				padding: 3px 12px;
				text-shadow: none;
				transition: none;
				
				.navbadge {
					background: @theme-color;
					border-radius: 14px;
					color: #fff;
					margin-left: 4px;
					padding: 4px 6px;
					vertical-align: middle;
				}
				
				&:hover {
					background: @gray-lighterer;
					color: @home-tab-active-color;
					text-shadow: none;
				}
				
				&.desktop-active,
				&.active,
				&.active:hover {
					border-bottom: 0;
					background: @home-tab-active-bg;
					color: @home-tab-active-color;
					padding-bottom: 4px;
				}
				
				.icon,
				.icon-f {
					display: inline-block;
					font-size: 22px;
					line-height: 26px;
					vertical-align: top;
				}
				
				.icf-line-chart {
					font-size: 19px;
				}
				
				.icf-comment-o {
					font-size: 24px;
				}
				
				.icf-comments-o {
					font-size: 26px;
				}
				
				.icf-share {
					font-size: 18px;
				}
				
				.navbadge {
					margin-left: 4px;
				}
				
				&.premium {
					background: none;
					border-left: none;
					border-right: none;
					border-top: none;
					line-height: 21px;
					overflow: hidden;
					padding: 0 8px;
					position: relative;
					
					.ticket-in {
						display: block;
						font-weight: bold;
						padding: 6px 8px;
						position: relative;
						z-index: 2;
						
						.navbadge {
							margin-bottom: 1px;
						}
					}
					
					.ticket-back {
						bottom: 0;
						left: 0;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 1;
						
						.ticket-back-left,
						.ticket-back-right {
							width: 55%;
							overflow: hidden;
						}
						
						.ticket-back-left,
						.ticket-back-right,
						.icon-f {
							bottom: 0;
							font-size: 34px;
							line-height: 34px;
							position: absolute;
							top: 0;
							z-index: 2;
						}
						
						.ticket-back-left {
							&,
							.icon-f {
								left: 0;
							}
						}
						
						.ticket-back-right {
							&,
							.icon-f {
								right: 0;
							}
						}
						
						.icf-ticket-wide {
							z-index: 1;
						}
					}
					
					&.active,
					&.active:hover {
						padding-bottom: 0;
						
						.ticket-in {
							padding-bottom: 7px;
						}
					}
					
					color: @home-tab-color;
					
					.ticket-back {
						.icon-f {
							color: @home-tab-bg;
						}
					}
					
					&:hover,
					&.active,
					&.active:hover {
						color: white;
						
						.icf-ticket-wide {
							color: @theme-color;
						}
						
						.navbadge {
							background: #fff;
							color: #000;
						}
					}
				}
				
				.icf-pin {
					display: none;
				}
			}
			
			&:last-child {
				margin-right: 0;
			}
			
			&.un-tabbed {
				ul {
					padding: 0;
				}
				
				.btn:not(.active) {
					background: @body-bg;
					border: 1px solid @body-bg;
					border-bottom-width: 0;
					color: @text-color;
					height: 33px;
				}
			}
		}
	}
	
	.sticky-tabs {
		position: relative; //for the bottom line of .xv-slim-tabs position which can't be in relative because of left and right scroll arrows
	}
	.side-scrollable-tab-buttons {
		.xv-slim-tabs {
			position: static; //for the left and right scroll arrows position, must update container position to relative for bottom line position
		}
	}
	
	.xv-slim-tabs {
		flex-grow: 999;
		flex-shrink: 0;
		justify-content: left;
		min-width: 100%;
		padding: 0 10px 6px;
		width: auto;
		
		&::before {
			bottom: 6px;
		}
		
		li {
			flex-shrink: 0;
		}
		
		.un-tabbed {
			margin-left: auto;
			
			ul {
				padding: 0 0 0 20px;
			}
		}
		
		.xv-slim-tab-btn {
			font-size: 16px;
			font-weight: bold;
			line-height: 20px;
			
			.count {
				font-weight: normal;
				line-height: 20px;
				margin-left: 4px;
			}
			
			.icon-f {
				display: inline-block;
				font-size: 22px;
				height: 20px;
				line-height: 20px;
				vertical-align: top;
			}
			
			.icf-feed {
				font-size: 16px;
				line-height: 18px;
			}
			
			.icf-line-chart {
				font-size: 19px;
			}
			
			.icf-comment-o,
			.icf-messages {
				font-size: 24px;
			}
			.icf-messages {
				&:before {
					vertical-align: text-bottom;
				}
			}
			
			.icf-comments-o {
				font-size: 26px;
			}
			
			.icf-share {
				font-size: 18px;
			}
			
			&.premium {
				.count {
					margin-left: 0;
					position: relative;
					vertical-align: text-top;
				}
				
				.ticket-in {
					color: #fff;
					display: block;
					font-family: sans-serif;
					font-size: 12px;
					line-height: 16px;
					padding: 0 6px;
					position: relative;
					z-index: 2;
				}
				
				.ticket-back {
					bottom: 0;
					color: @red-color;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 1;
					
					.ticket-back-left,
					.ticket-back-right {
						width: 55%;
						overflow: hidden;
					}
					
					.ticket-back-left,
					.ticket-back-right,
					.icon-f {
						bottom: 0;
						font-size: 16px;
						line-height: 16px;
						position: absolute;
						top: 0;
						z-index: 2;
					}
					
					.ticket-back-left {
						&,
						.icon-f {
							left: 0;
						}
					}
					
					.ticket-back-right {
						&,
						.icon-f {
							right: 0;
						}
					}
					
					.icf-ticket-wide {
						z-index: 1;
					}
				}
			}
		}
		
	}
	
	.tabs {
		padding: 15px 0 4px;
		
		.tab {
			position: relative;
			overflow: visible;
			min-height: 200px;
			
			& > h4:first-child,
			& > .pagination:first-child,
			& > *:first-child > h4:first-child,
			& > div > h4:first-child,
			& > div > .pagination:first-child,
			& > div > *:first-child > h4:first-child {
				margin-top: 0;
			}
		}
		
		.tab-actions {
			position: absolute;
			top: -13px;
			right: 0;
		}
		
		.pfinfo-actions {
			margin: -8px 0 10px;
			padding: 0 0 3px;
			border-bottom: 1px solid @gray-light;
			text-align: center;
		}
		
		.tab-actions,
		.pfinfo-actions {
			.icon-f {
				display: inline-block;
				font-size: 16px;
				vertical-align: top;
			}
		}
	}
	
	h4 {
		font-size: 14px;
		line-height: 22px;
		
		a.active {
			text-decoration: none;
			cursor: default;
		}
		
		&.no-border {
			&, a {
				color: @gray-dark;
			}
		}
	}
	
	.filters {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		color: @gray-dark;
		font-size: 14px;
		line-height: 22px;
		overflow: hidden;
		margin: 2px 8px 12px;
		overflow: visible;
		
		button {
			border: 0;
			background: none;
			color: @text-color;
			padding: 0;
			
			&:active,
			&:focus,
			&:active:focus {
				outline: none;
			}
			
			&.is-highlighted {
				animation: red-tab-hl-color-pulse 0.3s 3;
				
				@keyframes red-tab-hl-color-pulse {
					0% {
						color: @text-color;
					}
					
					70% {
						color: @red-color;
					}
					
					100% {
						color: @text-color;
					}
				}
				
				.checkbox.toggle label:before {
					animation: red-tab-hl-bg-pulse 0.3s 3;
					
					@keyframes red-tab-hl-bg-pulse {
						0% {
							background: @toggle-bg;
						}
						
						70% {
							background: @red-color;
						}
						
						100% {
							background: @toggle-bg;
						}
					}
				}
			}
		}
		
		a {
			color: @gray-dark;
		}
		
		.content-filter-is {
			background: none;
			border: 0;
			height: 22px;
			padding: 0 0 0 4px;
		}
		
		.content-title {
			&.has-title {
				padding-right: 12px;
			}
		}
		
		.content-filters {
			& > span {
				margin-right: 10px;
				
				/*&.content-filter-k-unlocked {
					float: right;
					margin-left: 10px;
					margin-right: 0;
					padding: 0 4px;
				}*/
				
				&.content-filter-k-lock-unlock {
					.is-disabled {
						.icon-f {
							opacity: 0.5;
						}
					}
				}
				
				&.content-filter-k-uploadsonly {
					.is-disabled {
						opacity: 0.5;
					}
				}
			}
		}

		.select-filter-list-desktop {
			min-width: 25%;
			margin-right: auto;
		}
		.select-filter-list-mobile {
			min-width: 50%;
		}

		.select-filter-list-desktop {
			@media(max-width: @screen-xs-max) {
				display: none;
			}
		}
	
		.select-filter-list-mobile {
			@media(min-width: @screen-sm-min) {
				display: none;
			}
		}
		
		.sort-filter {
			float: right;
			order: 10;
			margin-left: auto;
			
			button {
				border: 0;
				background: none;
				padding: 0;
				text-decoration: underline;
				
				&.is-selected {
					cursor: default;
					text-decoration: none;
				}
			}
		}
	}
	
	.mozaique {
		clear: both;
		position: relative;
		
		.top-message {
			background: @red-color linear-gradient(135deg, @post-comment-s1-bg-left, @post-comment-s1-bg-right);
			color: #fff;
			display: block;
			font-size: 16px;
			margin: 0 0 10px;
			padding: 5px 10px;
			text-align: center;
		}
		
		.no-content {
			font-size: 18px;
			font-weight: bold;
			margin: 40px 20px;
			text-align: center;
			
			& + .load {
				padding: 40px 20px 40px;
			}
			
			.btn {
				display: block;
				font-size: 16px;
				margin: 10px auto 0;
				white-space: normal;
			}
		}
		
		.load {
			clear: both;
			display: block;
			padding: 80px 20px 40px;
			text-align: center;
			width: 100%;
			
			.icf-spinner {
				color: @gray-light;
				display: inline-block;
				font-size: 60px;
			}
			
			&:not(:first-child) {
				background: fadeOut(@body-bg, 40%);
				bottom: 0;
				position: absolute;
				top: 0;
				z-index: 3;
			}
		}
	}
	
	.thumb-block {
		background: none;
	}
	
	.sticky-tabs {
		&.is-sticky {
			background: @body-bg;
			left: 0;
			max-width: @screen-max;
			padding: 5px @body-out-padding 0;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 10;
		}
	}
	
	.side-scrollable-tab-buttons {
		display: flex;
		
		.tab-buttons {
			flex-grow: 999;
		}
	}
	
	.main-cat-filter {
		float: right;
		
		[dir="rtl"] & {
			float: left;
		}
		
		.profile-maincat-switch-cont {
			margin: 0 0 0 8px;
			position: relative;
			z-index: 2;
		}
	}
	
	.activity-event {
		.profile-maincat-switch-cont {
			margin: 3px 0 0;
		}
		
		.mozaique.red-line {
			font-size: 0;
			margin-right: 0;
			margin-left: 0;
			white-space: nowrap;
			
			.thumb-block {
				float: none;
				display: inline-block;
				vertical-align: top;
			}
		}
		
		.xscroll-h-added + .more {
			margin-top: -2px;
		}
	}
	
	.profile-maincat-switch {
		margin: 0;
		padding: 3px 3px 3px 8px;
		
		&.btn-clear {
			background: none;
		}
		
		&:hover, &:focus, &:active {
			outline: none;
		}
	}
}

.content-filters {
	.content-filter {
		text-decoration: none;
		white-space: nowrap;
		
		//&.with-checkbox-toggle {}
		
		&.is-disabled {
			.icon-f,
			label,
			.label {
				cursor: not-allowed;
			}
		}
		
		.toggle {
			display: inline-block;
			margin: 0;
			vertical-align: middle;
		}
		
		.count {
			color: @gray-light2;
		}
		
		&.popup-opened-btn {
			.icf-caret-down {
				transform: rotate(180deg);
			}
		}
		
		.icon-f {
			display: inline-block;
			font-size: 19px;
			height: 22px;
			line-height: 22px;
			vertical-align: top;
			
			&.icf-ticket-red {
				font-size: 28px;
			}
			
			&.icf-crown {
				line-height: 20px;
			}
			
			&.icf-ticket-red {
				font-size: 28px;
			}
		}
		
		&.is-default-all {
			.icon-f {
				opacity: 0.3;
			}
			
			.checkbox.toggle {
				input:checked + label {
					&:before {
						background: @toggle-bg;
						border-color: @toggle-border;
						opacity: 0.7;
					}
				}
			}
		}
		
		//& + .content-filter-choices-dropdown {
		//	display: none;
		//}
	}
}

//.dropdown-filter-choices-pop,
.dropdown-filters-pop {
	
	.x-popup-content {
		background: @gray-lighterer;
		//margin-top: -1px;
		padding: 0;
	
		button,
		.main-cat-switch-link {
			background: none;
			border: 1px solid @gray-bg;
			color: @text-color;
			display: block;
			padding: 8px 10px;
			text-align: left;
			text-decoration: none;
			width: 100%;
			
			&:not(:first-child) {
				border-top: 0;
			}
			
			&.is-selected {
				display: none;
			}
			
			.notouch &:hover,
			//&.is-choice-selected,
			&.current {
				background: @gray-bg;
			}
			
			.icf-sexe-trans {
				font-size: 22px;
			}
		}
		
		//button {
		//	&[data-type="choice"] {
		//		padding-left: 26px;
		//		position: relative;
		//
		//		.icon-f {
		//			font-size: 14px;
		//			left: 7px;
		//			top: 50%;
		//			margin-top: -7px;
		//			position: absolute;
		//		}
		//	}
		//}
	}
}
.dropdown-filters-pop {
	.x-popup-content {
		.main-cat-switch-link {
			& > span {
				float: left;
				text-align: center;
				width: 30px;
				
				[dir="rtl"] & {
					float: right;
				}
			}
		}
	}
}

.dropdown-filters-pop {
	padding: 0;
	width: calc(~"100% - (2 * @{body-out-padding})");
	
	/*button + .content-filter-choices {
		margin-top: -1px;
	}
	
	.content-filter-choices {
		border: 2px solid @gray-bg;
		border-left-width: 5px;
		//border-right-width: 1px;
		//border-right: 0;
		
		button {
			&[data-type="choice"] {
				padding-left: 36px;
				
				.icon-f {
					left: 16px;
				}
			}
			
			&.is-choice-selected {
				background: none;
				
				&:before {
					content: @icf-check;
					.font-family-iconfont();
					position: absolute;
					left: 3px;
					font-size: 10px;
					top: 50%;
					margin-top: -5px;
				}
			}
		}
	}*/
}

//.dropdown-filter-choices-pop {
//	button {
//		font-size: 14px;
//	}
//}

.profile-maincat-switch-pop {
	.x-popup-content {
		padding: 10px 0;
		
		a,
		.uploader-filter {
			display: block;
			font-size: 16px;
			font-weight: bold;
			padding: 6px 20px 6px 10px;
			text-decoration: none;
			cursor: pointer;
			
			&:hover {
				background: @red-color;
			}
			
			&.current {
				background: rgba(255, 255, 255, .1);
			}
			
			.icf-sexe-trans {
				font-size: 22px;
			}
			
			& > span {
				float: left;
				text-align: center;
				width: 30px;
				
				[dir="rtl"] & {
					float: right;
				}
			}
			
			small {
				font-size: 0.9em;
				font-weight: normal;
			}
		}
	}
}

.profile-privacy-popup {
	.x-popup-content {
		padding: 7px 10px;
		max-width: 500px;
	}
	
	.form-group {
		margin-bottom: 2px;
	}
	
	.title {
		p {
			font-weight: bold;
			margin-bottom: 7px;
			border-bottom: 1px solid @gray;
		}
	}
	
	.actions {
		margin-bottom: 0;
		
		> div > div {
			margin-top: 7px;
			padding-top: 7px;
			border-top: 1px solid @gray;
		}
	}
}

#tabAboutMe {
	.profile-pic {
		position: relative;
		
		.external-link,
		img {
			display: block;
			width: 100%;
		}
		
		img {
			border: 1px solid @img-border-color;
			height: auto;
		}
		
		.btn {
			position: absolute;
			top: 2px;
			right: 2px;
			
			.icon-f {
				display: inline-block;
				font-size: 16px;
				vertical-align: top;
			}
		}
	}
	
	.rating-box {
		margin: 10px auto 0;
		
		.rating-bar-block {
			min-width: 60px;
		}
	}
	
	#pfinfo-col-pict {
		padding-right: 5px;
	}
	
	#vote-info {
		display: inline-block;
		font-size: 18px;
		vertical-align: text-bottom;
	}
	
	.pfinfo-col {
		font-size: 13px;
		line-height: 1.4;
		padding: 0 5px;
		
		&#pfinfo-col-col2 {
			padding-right: 15px
		}
		
		p {
			overflow: hidden;
			margin: 0;
			
			&.light {
				margin-top: -4px;
				
				strong {
					font-weight: normal;
				}
			}
		}
		
		.psinfo-ranks,
		#pinfo-video-tags {
			margin: 5px 0;
		}

		#profile-aboutme-tab-chat-link {
			text-decoration: underline;
		}

		#pinfo-workedfor {
			.show-more {
				display: inline-block;
			}
			
			a {
				font-weight: bold;
			}
		}
		
		#pinfo-aboutme {
			background: @gray-lighter;
			border: 1px solid darken(@gray-lighter, 5%);
			padding: 3px 10px;
			max-height: 150px;
			position: relative;
			
			.tip {
				display: block;
				color: @gray-dark;
				font-size: 12px;
				font-style: italic;
				padding-bottom: 10px;
			}
			
			.aboutme-edit {
				display: none;
			}
			
			&.missing {
				border-color: @theme-color;
				
				.tip {
					display: inline;
					color: @theme-color;
					font-size: 12px;
					font-style: normal;
					padding-top: 0;
				}
				
				.value {
					display: none;
				}
			}
			
			&.editing {
				max-height: none;
				
				.tip, .value {
					display: none;
				}
				
				.aboutme-edit {
					display: inline;
				}
			}
			
			button {
				margin-top: 3px;
				float: right;
			}
			
			.show-more {
				display: none;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				background: #fff;
				background: fadeout(#fff, 5%);
				background: linear-gradient(180deg, fadeout(@gray-lighter, 90%), fadeout(@gray-lighter, 15%) 20%, @gray-lighter);
				text-align: center;
				padding: 15px 5px 2px;
				cursor: pointer;
			}
		}
		
		.pinfo-hidden {
			display: none;
		}
	}
	
	.bottom-actions {
		float: right;
		margin-top: 8px;
		padding: 8px 0 8px 50px;
		border-top: 1px solid darken(@gray-lighter, 10%);
	}
	
	[dir="rtl"] & {
		#pfinfo-col-pict {
			padding-left: 5px;
			padding-right: 15px;
		}
		
		#pfinfo-col-pict,
		.pfinfo-col {
			float: right;
		}
		
		#pfinfo-col-col2.pfinfo-col {
			padding-left: 15px;
			padding-right: 5px;
		}
		
		.bottom-actions {
			float: left;
		}
	}
	
	h4.bg-title {
		clear: both;
	}
	
	#profile-add-to-friends {
		padding-bottom: 6px;
	}
	
	.mozaique {
		&.channels-list {
			padding-bottom: 10px;
			padding-top: 10px;
			
			.thumb-block {
				padding-bottom: 0;
				padding-top: 0;
				
				.thumb-under {
					height: 36px;
					max-height: 36px;
					min-height: 36px;
					overflow: hidden;
					padding: 0 0 4px;
					
					p {
						line-height: 16px;
						height: 32px;
						max-height: none;
						overflow: hidden;
						
						&.title,
						&:first-child {
							.line-clamp(2);
							
							a {
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}
}

#tabPhotos {
	.thumb-mozaique {
		//text-align: center;
		clear: both;
	}
	
	.mozaique.profileslist,
	& {
		.img-block {
			.thumb-inside {
				padding-bottom: 75%;
			}
			
			.embed-responsive-item {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 0;
			}
			
			img {
				max-width: 100%;
				max-height: 100%;
				width: auto;
				height: auto;
				border: 1px solid #000;
			}
			
			&.selected {
				background: fadeOut(@red-color, 50%);
				border-radius: 4px;
				box-shadow: 0 0 2px 1px @red-color;
				transform: scale(0.95);
				transition: all 0.2s ease;
			}
		}
	}
}

#gallery-report-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	background: @gray-lighterer;
	border: 1px solid darken(@gray-lighterer, 5%);
	box-shadow: 0 0 20px rgba(@text-color, 0.5);
	z-index: 210;
	
	> div {
		max-width: @screen-xlg-max;
		margin: 0 auto;
	}
	
	.btn {
		margin: 0;
		line-height: 1;
		
		&:focus {
			outline: unset;
		}
		/*
		&.btn-move-first {
			margin-right: 10px;
			border-color: #cccccc;
			
			&:hover {
				background-color: #cccccc;
				border-color: #adadad;
			}
			
			.icon-f {
				margin-right: 5px;
			}
		}
		
		&.btn-massive-cancel {
			position: absolute;
			top: 50%;
			right: 30px;
			padding: 0;
			font-size: 16px;
			background: none;
			transform: translateY(-50%);
		}*/
	}
	
	.text-danger {
		p {
			font-size: 18px;
			margin: 0 0 4px;
		}
	}
}

.tabPhotos-overlay,
.tabFanClub-pics-overlay {
	&.x-overlay.x-overlay-box {
		position: fixed;
		padding: 0 10px;
		text-align: center;
		
		.x-body {
			display: inline-block;
			margin-top: 5px;
			padding: 10px 25px;
			
			.viewer {
				margin-bottom: 0;
				
				.viewer-image-wrapper {
					display: block;
					width: auto;
					
					.viewer-image {
						display: inline-block;
						position: static;
						
						p {
							position: static;
							height: 35px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
}

.prof-tab-metadata {
	margin: 0 4px 5px;
	min-height: 27px;
	font-size: 14px;
	
	.rating-box {
		float: right;
		margin-left: 20px;
		
		& + #gal-list-views {
			margin-right: -10px;
		}
	}
	
	#gal-list-views {
		float: right;
		font-weight: bold;
		line-height: 25px;
		margin-left: 20px;
	}
	
	p {
		float: left;
	}
	
	#report-gallery {
		margin-left: 20px;
		float: right;
	}
}

.favorite-page {
	.desktop-main-min-height-minus-header-and-footer();
	
	.watch-later-title {
		& + #profile-title {
			.profile-pic {
				margin-right: 30px;
			}
			
			h2 {
				font-size: 30px;
			}
		}
	}
	
	#profile-title {
		.fav-votes {
			float: right;
			height: 27px;
			text-align: right;
			
			.rating-box-light {
				margin: 3px 0 3px 10px;

				.vote-actions {
					display: inline-block;
					position: relative;
					
					&:not(:first-child) {
						margin-left: @video-actions-margin;
					}
					
					.vote-action-good,
					.vote-action-bad {
						display: inline-block;
						font-size: 11px;
						height: @video-actions-height - @video-actions-vote-rate-bar-height;
						text-align: center;
						width: @video-actions-height;
						
						.icon-f {
							display: block;
							font-size: 24px;
							line-height: @video-actions-height - @video-actions-vote-rate-bar-height;
						}
						
						.notouch &:hover {
							.icon-f {
								color: @video-actions-vote-active-color;
							}
						}
					}
					
					.vote-action-good {
						.rating-good-perc {
							display: none;
						}
					}
					
					.vote-action-bad {
						.rating-bad-perc {
							display: none;
						}
					}
					
					.active {
						.icon-f {
							color: @video-actions-vote-active-color;
						}
					}
					
					.rating-bar {
						@video-actions-vote-rate-tot-height: 16px;
						
						.pgs-bar {
							background: @video-actions-vote-bar-bg;
							height: @video-actions-vote-rate-bar-height;
							
							div {
								background: @video-actions-vote-bar-color;
								height: @video-actions-vote-rate-bar-height;
							}
						}
						
						&.is-good {
							.pgs-bar {
								div {
									background: @video-actions-vote-active-color;
								}
							}
						}
						
						&.is-bad {
							.pgs-bar {
								background: @video-actions-vote-active-color;
							}
						}
					}
					
					.notouch &:hover {
						.rate-infos {
							display: block;
							
							.nbr .icon-f{
								color: @video-actions-icon-color;
							}
							.perc .bar{
								background: @video-actions-icon-color;
							}
						}
						
						.vote-action-good:hover + .vote-action-bad + .rating-bar + .rate-infos {
							.nbr .rating-good-nbr + .icon-f{
								color: @video-actions-vote-active-color;
							}
							.perc .good .bar{
								background: @video-actions-vote-active-color;
							}
						}
						
						.vote-action-bad:hover + .rating-bar + .rate-infos {
							.nbr .rating-bad-nbr + .icon-f{
								color: @video-actions-vote-active-color;
							}
							.perc .bad .bar{
								background: @video-actions-vote-active-color;
							}
						}
					}
				}
			}
		}
		
		h2 {
			span,
			small {
				vertical-align: baseline;
			}
			
			.video-subscribe .nb-subscribers {
				vertical-align: text-bottom;
				margin-right: 1px;
			}
			
			.ordered-label-list-btn {
				margin-bottom: 1px;
			}
		}
		
		.fav-tags {
			font-size: 11px;
			margin: 0;
		}
		
		.fav-playlist {
			a {
				font-size: 14px;
				font-weight: normal;
				text-decoration: none;
				
				.label {
					text-decoration: underline;
				}
			}
		}
		
		&.no-tags {
			h2 {
				margin-top: 6px;
			}
			
			.fav-votes {
				height: 37px;
			}
		}
	}
}

#tabFavorites {
	.list-inline {
		li {
			padding-right: 0;
		}
	}
	
	.more {
		color: @red-color;
		font-size: 13px;
		font-weight: bold;
		margin: 5px 0 0;
		text-align: right;
		
		a {
			color: @red-color;
		}
	}
	
	.favorites {
		clear: both;
	}
}


#tabComments {
	.tab-form {
		border: 1px solid @gray;
		margin: 0 0 10px;
		display: block;
	}
	
	#profile-comments-tab-chat-btn {
		display: block;
		font-size: 13px;
		line-height: 20px;
		margin: 6px auto 22px;
		
		.icon-f {
			float: left;
			font-size: 20px;
			height: 20px;
			margin-right: 6px;
		}
	}
	
	.x-thread {
		.thread-header {
			.thread-title {
				font-weight: normal;
			}
			
			.thread-sort,
			.thread-node-btn-sort,
			.thread-node-btn-sort.hidden + .thread-sort.hidden + .thread-node-btn-post {
				margin-left: 0;
			}
		}
	}
}

#tabActivity {
	clear: both;
}

.activity-event {
	overflow: hidden;
	margin-bottom: 10px;
	
	.header {
		overflow: hidden;
		padding: 3px 8px;
		margin: 0 0 5px;
		background: @gray-lighter;
		
		h4 {
			margin: 2px 0;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			
			.icon-f {
				display: inline-block;  
				font-size: 20px;
				line-height: 22px;
				vertical-align: top;
				
				&.icf-ticket-red {
					color: @red-color;
					font-size: 27px;
				}
				
				&.icf-images,
				&.icf-favorites {
					font-size: 14px;
				}
			}
			
			a {
				font-weight: bold;
				
				&.user-subscribe {
					.button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
					float: right;
					height: 20px;
					line-height: 18px;
					
					* {
						height: 20px;
						line-height: 20px;
					}
					
					.sub-btn {
						height: 18px;
						line-height: 18px;
						padding: 0 3px;
						
						.icon-f {
							font-size: 16px;
							line-height: 18px;
							padding-left: 0;
						}
					}
				}
			}
			
			.timestamp {
				float: right;
				font-style: italic;
				white-space: nowrap;
				color: @gray-dark;
				font-size: 12px;
				padding: 3px 0 0 3px;
			}
		}
	}
	
	.content {
		padding: 3px 1px;
	}
	
	.thumb-block {
		background: none;
		border-bottom: 0;
		
		.timestamp {
			position: absolute;
			top: @video-thumb-padding-top + 1;
			left: @video-thumb-padding-left + 1;
			padding: 0 5px;
			background: rgba(255, 255, 255, 0.5);
			color: @gray-dark;
			font-size: 11px;
			font-style: italic;
		}
	}
	
	.img-block {
		padding: 5px 2px 3px;
		
		.embed-responsive-item {
			display: flex;
			align-items: center;
			justify-content: center;
			//background-color: #000;
		}
		
		img {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			border: 1px solid #000;
		}
		
		.timestamp {
			position: absolute;
			top: @video-thumb-padding-top;
			left: @video-thumb-padding-left;
		}
	}
	
	.more {
		font-weight: bold;
		font-size: 13px;
		margin: 5px 0 0;
		color: @red-color;
		text-align: right;
		text-decoration: none;
		
		a {
			color: @red-color;
			
			span:first-child {
				text-decoration: underline;
			}
		}
		
		.count {
			font-weight: normal;
		}
	}
	
	&.has-less-21 {
		.more {
			display: none;
		}
	}
	
	.act-achievement {
		text-align: center;
		font-size: 48px;
	}
}

#load-more-activities {
	margin-top: 5px;
	font-weight: bold;
	display: block;
	width: 100%;
	clear: both;
}

.prof-link-captcha .x-popup-content p {
	max-width: 304px;
}

.video-subscribe-popup,
.profile-vote-popup {
	max-width: 100%;
	
	.x-popup-content {
		padding: 15px 30px;
		
		.subscribed {
			font-size: 13px;
			margin: -5px;
			text-align: center;
			
			& > p:first-child {
				text-align: left;
				font-weight: bold;
				
				span {
					font-weight: normal;
				}
			}
			
			.profileslist {
				margin-top: 8px;
				
				.profile {
					float: left;
					padding: 2px 5px;
					margin-bottom: 6px;
					width: 140px;
					
					.profile-img {
						display: block;
						height: 130px;
						overflow: hidden;
						position: relative;
						width: 130px;
						
						img {
							display: inline-block;
							height: 130px;
							object-fit: cover;
							transition: opacity 0.2s ease;
							width: 130px;
							
							&:hover {
								opacity: 0.7;
							}
						}
						
						.flag-small {
							position: absolute;
							top: 2px;
							right: 1px;
						}
					}
					
					.profile-name {
						font-size: 14px;
						margin: 2px 5px;
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: center;
						white-space: nowrap;
						
						&, a {
							text-decoration: none;
						}
						
						a:hover {
							text-decoration: underline;
						}
					}
					
					.profile-desc {
						font-size: 11px;
						line-height: 16px;
						text-align: left;
						word-break: break-word;
					}
					
					.sub-strip {
						padding: 0 6px;
						border: 1px solid @btn-default-border;
						height: 24px;
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						
						&, * {
							font-size: 14px;
							line-height: 22px;
						}
						
						* {
							display: inline-block;
							float: none;
							height: 22px;
							padding-left: 0;
						}
					}
				}
				
				&.nb-profile-per-line-1 .profile:nth-child(n+1),
				&.nb-profile-per-line-2 .profile:nth-child(2n+1),
				&.nb-profile-per-line-3 .profile:nth-child(3n+1),
				&.nb-profile-per-line-4 .profile:nth-child(4n+1),
				&.nb-profile-per-line-5 .profile:nth-child(5n+1),
				&.nb-profile-per-line-6 .profile:nth-child(6n+1),
				&.nb-profile-per-line-7 .profile:nth-child(7n+1),
				&.nb-profile-per-line-8 .profile:nth-child(8n+1) {
					clear: left;
				}
			}
		}
	}
}
