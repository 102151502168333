.mobile_nav(@mobile_nav_header_height) {
	.nav-elem {
		background-color: @mobile-menu-bg;
		bottom: 0;
		display: block;
		height: 90%;
		height: calc(~"100% - @{mobile_nav_header_height} - @{nav-mobile-ctry-height}");
		left: -@screen-xs-max/2;
		overflow: visible;
		margin: @nav-mobile-ctry-height 0 0;
		max-width: @screen-xs-max/2;
		opacity: 0;
		position: fixed;
		top: @mobile_nav_header_height;
		transition: none;
		visibility: visible;
		width: 0;
		z-index: @zindex-mobile-menu;
		&,
		&.opened {
			max-height: none;
			padding: 0;
		}
		
		&.no-ctry-version {
			height: calc(~"100% - @{mobile_nav_header_height}");
			margin: 0;
		}
		nav {
			max-height: none;
		}
		
		.ordered-label-list {
			margin-top: 0;
			transition: none;
			width: 100%;
		}
		
		.ordered-label-list ul li,
		#nav-main-cat-switcher,
		#nav-main-cat-switcher-list li,
		#nav-language-switcher,
		#language-switcher-list li,
		.country-switch-list li {
			border: 0;
			border-top: 1px solid @gray-light;
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 3;
			&,
			&.mobile-show {
				display: block;
			}
			&.mobile-hide {
				display: none;
			}
			
			.btn,
			&.btn,
			&.section-title {
				background: @mobile-menu-bg;
				box-shadow: -8px 0 12px -10px rgba(0, 0, 0, 0.9) inset;
				font-weight: bold;
				text-align: left;
				[dir="rtl"] & {
					text-align: right;
				}
			}
			.btn,
			&.btn {
				color: white;
				display: block;
				font-size: 14px;
				line-height: 26px;
				padding: 7px 6px 7px 20px;
				transition: background 0.2s;
				white-space: normal;
				&:hover,
				&.opened {
					background: @mobile-menu-bg-hover;
				}
				&.main {
					cursor: pointer;
					padding-right: 20px;
					position: relative;
					&:after {
						border-left: 4px solid;
						border-bottom: 4px solid transparent;
						border-top: 4px solid transparent;
						content: " ";
						display: block;
						height: 0;
						margin-left: 4px;
						right: 7px;
						position: absolute;
						top: 16px;
						vertical-align: 4px;
						width: 0;
					}
				}
				.flag-small {
					margin: 7px 2px 0 0;
				}
				.swap-big {
					float: left;
					margin: 0 6px 0 0;
				}
				.right {
					float: right;
					padding: 0 10px;
				}
			}
			&.section-title {
				color: @gray-light;
				font-size: 12px;
				height: 51px;
				padding: 26px 6px 0;
				position: relative;
				.icon-f {
					font-size: 20px;
				}
			}
		}
		
		.ordered-label-list ul li {
			&.click-order-toggle {
				.label{
					padding-left: 4px;
				}
			}
			&#last-main-cats-visited {
				border-top: 0;
				.btn {
					background: lighten(@mobile-menu-bg, 10%);
				}
			}
			.red-home {
				display: block;
				height: auto;
				float: none;
				overflow: hidden;
				.icf-home {
					margin-right: 5px;
				}
			}
			.badge {
				background: @theme-color;
				color: #fff;
				margin-left: 2px;
				padding-left: 5px;
				padding-right: 5px;
			}
		}
		
		.main-categories {
			overflow: auto;
			max-height: none;
			//max-height: calc( ~"100vh - 81px" );
			.max-height-100vh(81px);
		}
		
		#nav-main-cat-switcher,
		#nav-language-switcher {
			opacity: 0.5;
			transition: opacity 0.2s;
			width: 100%;
			&,
			&.btn.main {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			&.init-ok {
				opacity: 1;
			}
		}
		
		.opened_nav_sublist_title_arrow(@height) {
			&:after {
				border-top: 4px solid;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				content: " ";
				display: block;
				height: 0;
				right: 15px;
				position: absolute;
				top: @height / 2 - 2px;
				vertical-align: 4px;
				width: 0;
			}
		}
		.ordered-label-list ul li.sub-list,
		#nav-main-cat-switcher-list,
		#language-switcher-list,
		.country-switch-list {
			background-color: @mobile-menu-sub-bg;
			box-shadow: none;
			box-sizing: content-box;
			border-top: 0;
			border-left: 0 solid @mobile-menu-bg;
			display: block;
			height: 100%;
			right: 0;
			overflow: hidden;
			margin: 0;
			max-height: none;
			padding: 0;
			position: absolute;
			top: 0;
			visibility: visible;
			width: 0;
			z-index: 1;
			.btn,
			li .btn {
				background-color: @mobile-menu-sub-bg;
				border: 0;
				box-shadow: none;
				height: auto;
				margin: 0;
				&:hover,
				&.active,
				&.title {
					background: @mobile-menu-sub-bg-hover;
				}
				&.title {
					cursor: default;
					position: relative;
					.opened_nav_sublist_title_arrow(@nav-mobile-ctry-height);
				}
			}
			.disabled {
				.btn,
				&.btn {
					background-color: @mobile-menu-sub-bg-disabled;
					color: #888;
				}
			}
		}
		.country-switch-list {
			.row {
				margin: 0;
				padding: 0;
			}
		}
		#nav-main-cat-switcher-list:before,
		#language-switcher-list:before {
			content: none;
		}
		
		#version-country-switch-li,
		#main-cat-switch-li {
			background: @mobile-menu-version-bg;
			border-bottom: 1px solid @gray-light;
			height: @nav-mobile-ctry-height + 1px;
			position: absolute;
			top: -@nav-mobile-ctry-height;
			width: 100%;
			a {
				background: @mobile-menu-version-bg;
				border-radius: 4px;
				box-shadow: none;
				height: @nav-mobile-ctry-height - 8px - 1px; //for margin & border
				margin: 4px;
				opacity: 0.5;
				overflow: hidden;
				padding: 3px 5px;
				position: relative;
				text-align: center;
				text-overflow: ellipsis;
				transition: all 0.2s;
				white-space: nowrap;
				&.init-ok {
					opacity: 1;
				}
				&.opened {
					background: @mobile-menu-version-bg-hover;
				}
				.flag-small {
					float: none;
					margin: 7px 2px 0 0;
				}
			}
		}
		#main-cat-switch-li {
			left: 100%;
		}
		&.opened {
			left: 0;
			opacity: 1;
			width: 50%;
			.ordered-label-list ul li.sub-list.opened,
			#nav-main-cat-switcher-list.opened,
			#language-switcher-list.opened,
			.country-switch-list.opened {
				border-left: @screen-xs-max/2 solid @mobile-menu-bg;
				overflow-x: hidden;
				overflow-y: auto;
				right: -100%;
				width: 100%;
				z-index: 2;
			}
		}
		
		#nav-main-cat-switcher{
			span {
				margin-left: 2px;
				vertical-align: middle;
				&.icf-woman {
					font-size: 1.4em;
				}
				&.icf-sexe-trans {
					font-size: 1.2em;
				}
			}
		}
		#nav-main-cat-switcher-list {
			li {
				a {
					& > span:first-child {
						float: left;
						text-align: center;
						width: 30px;
					}
					span {
						span {
							margin-right: 4px;
							vertical-align: middle;
							&.icf-woman {
								font-size: 1.4em;
								line-height: 0.714em;
							}
							&.icf-sexe-trans {
								font-size: 1.2em;
							}
						}
					}
				}
			}
			.alert-toggle {
				height: calc(~'100% - 123px');
				position: relative;
				.btn {
					bottom: 0;
					left: 0;
					right: 0;
					position: absolute;
				}
			}
		}
	}
}

#page{
	.mobile_nav(@header-height);
	.nav-elem {
		#version-country-switch-list {
			.filter {
				background: #fff;
				border-radius: 2px;
				overflow: hidden;
				margin: 4px auto 2px;
				width: 94%;
				label, input, button {
					background: #fff;
					border: 0;
					float: left;
					font-size: 16px;
					height: 40px;
					line-height: 40px;
				}
				input, button {
					&:active, &:focus {
						outline: none;
					}
				}
				label {
					display: block;
					margin: 0;
					width: calc(~"100% - 40px");
					.icon-f {
						color: #ddd;
						float: left;
						font-size: 13px;
						height: 40px;
						line-height: 40px;
						padding-right: 5px;
						text-align: right;
						width: 26px;
					}
					input {
						color: #333;
						font-weight: normal;
						line-height: 28px;
						padding: 6px 0;
						width: calc(~"100% - 26px");
					}
				}
				button {
					color: #aaa;
					font-size: 13px;
					width: 40px;
					&:hover {
						color: #666;
					}
				}
			}
			li{
				&:first-child {
					border-top: 0;
				}
				&.no-result {
					color: #fff;
					font-size: 14px;
					font-style: italic;
					padding: 10px 0 0;
					text-align: center;
				}
			}
		}
	}
}
