.search-filters.simple-dropdown {
	.niv1.with-niv2 {
		margin: 0 2px 0;
		padding: 0 0 4px 0;
		
		.show-niv2 {
			background: @gray-lighter;
			border-radius: 3px;
			color: @text-color;
			cursor: pointer;
			font-size: 14px;
			line-height: 22px;
			margin: 0;
			padding: 4px 5px 4px 8px;
			
			.label {
				.icon-f {
					float: left;
					line-height: 22px;
					padding-right: 7px;
				}
				
				.by {
					font-style: italic;
				}
			}
			
			.icf-caret-down {
				float: right;
				line-height: 22px;
				margin: 0 0 0 3px;
				padding: 0;
			}
		}
		
		&.is-selected {
			.show-niv2 {
				.label {
					.icon-f {
						color: @red-color;
					}
				}
			}
		}
	}
}

.simple-dropdown-pop {
	.search-filters {
		ul {
			li {
				padding: 0 4px 3px;
				
				a {
					padding: 3px 14px;
					
					&.current {
						background: @premium-feed-filter-drop-current-bg;
						font-weight: normal;
					}
					
					.notouch &:hover {
						background: @premium-feed-filter-drop-hover-bg;
					}
				}
			}
		}
	}
}