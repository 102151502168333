
@import (less) "../jquery-ui/base/core.css";
@import (less) "../jquery-ui/base/datepicker.css";
@import (less) "../jquery-ui/base/menu.css";
@import (less) "../jquery-ui/base/autocomplete.css";
@import (less) "../jquery-ui/base/theme.css";

.ui-widget-content {
	background-image: url(/v3/img/jquery-ui/ui-bg_flat_75_ffffff_40x100.png)
}
.ui-widget-header {
	background-image: url(/v3/img/jquery-ui/ui-bg_highlight-soft_75_cccccc_1x100.png);
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	background-image: url(/v3/img/jquery-ui/ui-bg_glass_75_e6e6e6_1x400.png);
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
	background-image: url(/v3/img/jquery-ui/ui-bg_glass_75_dadada_1x400.png);
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
	background-image: url(/v3/img/jquery-ui/ui-bg_glass_65_ffffff_1x400.png);
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	background-image: url(/v3/img/jquery-ui/ui-bg_glass_55_fbf9ee_1x400.png);
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
	background-image: url(/v3/img/jquery-ui/ui-bg_glass_95_fef1ec_1x400.png);
}
.ui-icon,
.ui-widget-content .ui-icon {
	background-image: url(/v3/img/jquery-ui/ui-icons_222222_256x240.png);
}
.ui-widget-header .ui-icon {
	background-image: url(/v3/img/jquery-ui/ui-icons_222222_256x240.png);
}
.ui-state-default .ui-icon {
	background-image: url(/v3/img/jquery-ui/ui-icons_888888_256x240.png);
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
	background-image: url(/v3/img/jquery-ui/ui-icons_454545_256x240.png);
}
.ui-state-active .ui-icon {
	background-image: url(/v3/img/jquery-ui/ui-icons_454545_256x240.png);
}
.ui-state-highlight .ui-icon {
	background-image: url(/v3/img/jquery-ui/ui-icons_2e83ff_256x240.png);
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
	background-image: url(/v3/img/jquery-ui/ui-icons_cd0a0a_256x240.png);
}
.ui-widget-overlay {
	background-image: url(/v3/img/jquery-ui/ui-bg_flat_0_aaaaaa_40x100.png);
}
.ui-widget-shadow {
	background-image: url(/v3/img/jquery-ui/ui-bg_flat_0_aaaaaa_40x100.png);
}