.favlists {
	& > h2 {
		margin: 10px 0 14px;
		position: relative;
		z-index: 1;
	}
	
	&.is-loading-lists,
	&.is-loading-videos {
		&:before {
			background: @body-bg;
			bottom: 0;
			content: '';
			display: block;
			opacity: 0.8;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 10;
		}
		
		& > h2 {
			z-index: 11;
		}
	}
	
	button.favlists-create {
		float: right;
		line-height: 28px;
		padding: 0 10px;
		position: relative;
		margin: 12px 0 0 8px;
		z-index: 2;
		
		.icon-f {
			display: inline-block;
			font-size: 20px;
			line-height: 28px;
			vertical-align: top;
		}
	}
	
	.favlists-actions {
		align-items: flex-start;
		clear: both;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 10px 0 20px;
		
		.favlists-actions-line-1,
		.favlists-actions-more {
			display: flex;
		}
		
		& > * {
			vertical-align: top;
		}
		
		.favlists-show-more-filters {
			background: none;
			border: 0;
			display: none;
			height: 32px;
			line-height: 32px;
			margin: 0;
			padding-bottom: 0;
			padding-top: 0;
			position: relative;
			
			.icf-sliders {
				font-size: 20px;
				line-height: 32px;
				vertical-align: top;
			}
			
			.icf-minus-circle {
				display: none;
			}
			
			&.is-opened {
				.icf-minus-circle {
					display: block;
				}
				.icf-plus-circle {
					display: none;
				}
			}
			
			.icf-minus-circle,
			.icf-plus-circle {
				bottom: 0;
				color: @gray;
				font-size: 16px;
				position: absolute;
				right: 0;
			}
		}
		
		label {
			font-size: 0;
			line-height: 32px;
			vertical-align: top;
			white-space: nowrap;
			
			* {
				display: inline-block;
				font-size: 12px;
				vertical-align: top;
			}
			
			.btn,
			.icon-f {
				display: inline-block;
				font-size: 16px;
				height: 32px;
				line-height: 32px;
				margin: 0;
				padding: 0;
				text-align: center;
				width: 32px;
			}
			
			&.favlists-search {
				font-size: 0;
				
				.form-control {
					margin: 0;
					border-right: 0;
				}
				
				.btn {
					border-bottom-left-radius: 0;
					border-color: @input-border;
					border-left: 0;
					border-top-left-radius: 0;
					margin-right: 20px;
				}
				.icon-f {
					line-height: 30px;
					height: 30px;
				}
			}
		}
		
		.form-control {
			display: inline-block;
			font-size: 17px;
			margin: 0 20px 0 5px;
			max-width: calc(~"100% - 57px");
		}
		
		.select-label {
			margin: 0 20px 5px 0;
			
			.form-control {
				margin: 0;
				max-width: calc(~"100% - 32px");
			}
		}
		
		.favlists-nb-per-page-cont {
			background: none;
			margin: 0 0 5px;
			
			&:after {
				content: none;
				display: none;
			}
			
			.select-label {
				margin: 0 4px 0 0;
				
				.form-control {
					&.favlists-nb-per-page {
						max-width: none;
					}
				}
			}
		}
		
		input[type=text] {
			font-weight: normal;
		}
		
		.favlists-display-mode {
			font-size: 0;
			line-height: 30px;
			padding: 0;
			margin: 0 20px 5px 0;
			
			.icon-f {
				border-radius: 3px;
				display: inline-block;
				font-size: 18px;
				line-height: 20px;
				margin: 2px;
				padding: 3px 0;
				text-align: center;
				vertical-align: top;
				width: 26px;
			}
			
			&.display-list .icf-list-ul,
			&.display-mozaique .icf-grid-4,
			&.display-cols .icf-columns {
				background: fadeOut(@btn-default-color, 30%);
				box-shadow: 0 0 2px 2px inset rgba(0, 0, 0, 0.3);
				color: @btn-default-bg;
			}
		}
	}
	
	.mozaique {
		.favlist-elem  {
			.thumb-inside {
				.pl-add-remove {
					.remove {
						display: none;
					}
				}
			}
			
			.in-fav-indic {
				display: none;
			}
			
			&.in-favlist {
				.thumb-inside {
					.pl-add-remove {
						.remove {
							display: block;
						}
						
						.add {
							display: none;
						}
					}
					.in-fav-indic {
						display: block;
					}
				}
				
				.in-fav-indic {
					display: inline-block;
				}
			}
			
			&.max-videos-reached {
				.thumb-inside {
					.pl-add-remove {
						cursor: default;
					}
				}
			}
		}
		.thumb-block {
			.thumb-inside {
				.in-fav-indic,
				.pl-add-remove,
				.pl-edit,
				.pl-move-to {
					background: fadeOut(@body-bg, 30%);
					cursor: pointer;
					display: none;
					
					&, .add, .remove {
						bottom: 0;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
					}
					
					.icon-f {
						color: fade(@text-color, 85%);
						.text-border-1px(fade(@text-invert-color, 30%));
						display: block;
						font-size: 60px;
						left: 0;
						margin-top: -30px;
						position: absolute;
						text-align: center;
						top: 50%;
						width: 100%;
					}
					
					.info-txt {
						font-weight: bold;
						left: 0;
						margin-top: 20px;
						position: absolute;
						text-align: center;
						text-transform: uppercase;
						top: 50%;
						width: 100%;
						
						& +	.icon-f {
							margin-top: -40px;
						}
					}
				}
				.pl-edit,
				.pl-move-to,
				.pl-add-remove {
					background: fadeOut(@body-bg, 15%);
					z-index: 2;
				}
				
				&:hover,
				&:active {
					.pl-edit,
					.pl-move-to,
					.pl-add-remove {
						display: block;
					}
					img {
						transition: transform .5s ease;
						transform: scale(1.05);
						
						&.top-left {
							transform-origin: bottom right;
						}
						&.top-right {
							transform-origin: bottom left;
						}
						&.bottom-left {
							transform-origin: top right;
						}
						&.bottom-right {
							transform-origin: top left;
						}
					}
					
					.is-fav-indic{
						display: none;
					}
				}
				.is-fav-indic {
					left: 50%;
					position: absolute;
					top: 50%;
					z-index: 1;
					transform: translate(-50%, -50%);
					
					/*&:after,
					&:before {
						display: block;
						content: " ";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 40px 40px 0 0;
						border-color: rgba(0,0,0,0.5) transparent transparent transparent;
						left: 0;
						position: absolute;
						top: 0;
						z-index: 2;
					}
					
					&:after {
						border-width: 42px 42px 0 0;
						border-color: rgba(0, 0, 0, 0.4) transparent transparent transparent;
						filter: blur(2px);
						z-index: 1;
					}*/
					
					.icon-f {
						.text-border-2px(fadeOut(black, 50%));
						color: #fff;
						display: block;
						font-size: 30px;
						margin: 7px 0 0 7px;
						position: relative;
						
						&.icf-clock {
							background: black;
							border-radius: 50%;
							font-size: 32px;
							margin: 6px 0 0 6px;
						}
						
						&.icf-favorites {
							&:before {
								position: relative;
								z-index: 2;
							}
							&:after {
								background: black;
								display: block;
								content: " ";
								width: 60%;
								height: 60%;
								left: 35%;
								position: absolute;
								top: 66%;
								z-index: 1;
								transform: translate(-50%, -50%);
							}
						}
					}
				}
			}
			
			.thumb-under {
				.metadata {
					white-space: normal;
				}
				
				.nb-videos,
				.max-videos-reached,
				.tag {
					.thumb-block-duration();
					margin-bottom: 4px;
				}
				.nb-videos {
					.icon-f {
						float: left;
						font-size: 15px;
						line-height: 18px;
						margin-right: 4px;
					}
				}
				.max-videos-reached {
					background: @red-color;
					color: #fff;
				}
				.tag {
					background: @gray-bg;
					color: @text-color;
					font-size: .8em;
					margin-left: 0;
				}
			}
			
			&.in-favlist {
				.thumb-inside {
					.is-fav-indic {
						display: none;
					}
					&:hover,
					&:active {
						.in-fav-indic {
							display: none;
						}
					}
				}
			}
		}
		
		&.display-cols {
			column-count: 3;
			
			@media (max-width: 1000px) {
				column-count: 2;
			}
			
			.favlist-elem {
				break-inside: avoid;
			}
		}
	}
}

#fav-list-manage-cont {
	overflow: auto;
}

.favlists,
.favlist-create,
.favlist-edit {
	overflow: hidden;
	
	.loader {
		display: block;
		font-size: 80px;
		margin: 20px auto;
		width: 80px;
		
		&:first-child {
			float: none;
		}
	}
}

.favlist-create,
.favlist-edit {
	position: relative;
	
	.btn-back {
		text-transform: uppercase;
		
		.icon-f {
			color: @gray;
			font-size: 10px;
			margin-right: 2px;
		}
	}
	
	.favlist-list-title,
	.favlist-create-form,
	.favlist-edit-form {
		clear: both;
		max-width: 1000px;
	}
	
	.favlist-list-title {
		p {
			font-size: 20px;
			font-weight: bold;
			margin: 0;
			padding-left: calc(~"25% + 15px");
		}
	}
	
	.favlist-picto {
		color: fadeOut(@text-color, 95%);
		font-size: 140px;
		left: 10px;
		top: 70px;
		position: absolute;
		
		&.icf-clock {
			font-size: 160px;
			left: 0;
			top: 50px;
		}
	}
	
	.favlist-status-label {
		select {
			padding: 0 16px 0 36px;
		}
	}
	
	.favlist-status-picto {
		display: inline-block;
		font-size: 14px;
		height: 32px;
		line-height: 32px;
		opacity: 0.5;
		position: absolute;
		text-align: right;
		top: 0;
		width: 30px;
		z-index: 1;
	}
}

.favlist-edit {
	.form-buttons {
		.delete {
			margin: 4px 0 0;
			float: right;
		}
	}
	
	.nb-videos,
	.max-videos-reached {
		.thumb-block-duration();
		font-size: 14px;
	}
	
	.pagination {
		margin-top: 20px;
		
		& + .mozaique {
			margin-top: 0;
		}
	}
	
	.mozaique {
		margin-top: 10px;
		
		& > p {
			margin: 20px;
			text-align: center;
		}
		
		& + .pagination {
			margin-bottom: 10px;
			margin-top: 0;
		}
		
		.thumb-block {
			a {
				cursor: default;
			}
			* {
				text-decoration: none!important;
			}
			
			.thumb-inside {
				&:hover,
				&:active {
					.in-fav-indic {
						display: none;
					}
				}
				
				.in-fav-indic {
					background: fadeOut(@body-bg, 50%);
					bottom: 0;
					cursor: pointer;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					
					.icon-f {
						color: fade(@text-color, 85%);
						.text-border-1px(fade(@text-invert-color, 30%));
						display: block;
						font-size: 60px;
						left: 0;
						margin-top: -30px;
						position: absolute;
						text-align: center;
						top: 50%;
						width: 100%;
					}
				}
			}
			
			.thumb-under {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				
				.title {
					flex: 1 0 1%;
					order: 1;
				}
				
				.play {
					order: 2;
				}
				
				.metadata {
					flex: 1 1 100%;
					order: 3;
					place-self: flex-end;
				}
			}
			
			.play {
				align-items: center;
				cursor: pointer;
				display: flex;
				line-height: 1;
				padding-left: 4px;
				
				.notouch &:hover .icon-f {
					background-color: @red-color;
					color: #fff;
				}
				
				.icon-f {
					align-items: center;
					background-color: @gray-light;
					border-radius: 3px;
					color: @text-invert-color;
					display: flex;
					font-size: 14px;
					height: 22px;
					justify-content: center;
					padding: 2px;
					width: 20px;
				}
			}
			
			.videopv {
				a {
					display: none;
				}
			}
		}
	}
	
	.voters-list {
		&, .account-page & {
			margin-top: 10px;
			max-width: 100%;
		}
	}
	
	.favlist-edit-form {
		
		.form-control,
		.tag-list {
			&.success {
				animation: pl_update_ok_pulse 1.5s ease-out;
			}
		}
		
		@keyframes pl_update_ok_pulse {
			0% {
				box-shadow: 0 0 0 0 fadeout(@state-success-text, 40%);
				background: fadeout(@state-success-text, 100%);
			}
			18% {
				background: fadeout(@state-success-text, 70%);
			}
			35% {
				box-shadow: 0 0 5px 25px fadeout(@state-success-text, 100%);
			}
			50% {
				box-shadow: 0 0 0 0 fadeout(@state-success-text, 100%);
			}
			80% {
				background: fadeout(@state-success-text, 70%);
			}
		}
	}
}

.favlist-edit-list-menu {
	background: @gray-lighter;
	display: none;
	padding: 8px 10px 0 20px;
	
	&.init-ok {
		display: block;
	}
	
	&.is-closed,
	&.is-opened {
		overflow: hidden;
		position: relative;
		
		&:before {
			background-image: linear-gradient(to top, darken(@gray-lighter, 20%), fadeOut(darken(@gray-lighter, 20%), 100%));
			bottom: 0;
			content: '';
			display: block;
			height: 14px;
			position: absolute;
			left: 0;
			width: 100%;
			z-index: 11;
		}
	}
	
	&.is-closed {
		max-height: 65px;
		transition: max-height 0.6s ease-out;
		
		&:before {
			transition: bottom 0.2s 0.6s ease-out;
		}
	}
	
	&.is-opened {
		max-height: 400px;
		
		&:before {
			bottom: -14px;
		}
	}
	
	&.is-pined {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 200;
	}
	
	.btn {
		display: inline-block;
		font-size: 16px;
		line-height: 18px;
		margin: 0 10px 8px 0;
		padding: 10px 16px;
		vertical-align: top;
		
		.icon-f {
			display: inline-block;
			line-height: 18px;
			margin: 0 2px 0 6px;
			vertical-align: text-bottom;
			
			&:first-child {
				margin-left: 0;
			}
			
			&.icf-square {
				margin-left: 2px;
			}
		}
		
		.icf-heart {
			color: @red-color;
			opacity: 0.7;
		}
		
		&.btn-link {
			.button-variant(@text-color, @gray-lighterer, @text-invert-color);
			
			&:hover {
				text-decoration: none;
			}
		}
		
		&.btn-show-more {
			border: 0;
			display: none;
			float: right;
			line-height: 20px;
			font-size: 20px;
			margin: 0 -6px 0 0;
			padding: 6px;
			text-align: center;
			width: 32px;
			
			.icon-f {
				line-height: 20px;
			}
		}
		
		.icf-arrow-right {
			transition: transform 0.1s;
		}
		
		&:hover {
			.icf-arrow-right {
				transform: translateX(2px);
			}
		}
	}
	
	.legend {
		display: inline-block;
		font-size: 16px;
		font-weight: bold;
		line-height: 20px;
		margin: 0 20px 6px 0;
		padding: 10px 0;
		vertical-align: top;
	}
	
	.max-videos-reached {
		background: @red-color;
		color: #fff;
		margin: 10px 20px 10px -16px;
	}
	/*
	.show-on-reorder-many,
	.show-on-reorder {
		display: none;
	}
	
	
	&.reorder-enabled {
		.show-on-reorder {
			//border: 0;
			display: inline-block;
			//float: right;
			//margin: 0 0 0 10px;
		}
		
		.hide-on-reorder {
			display: none;
		}
	}
	
	&.reorder-many {
		.show-on-reorder-many {
			display: inline-block;
		}
	}*/
}

.x-overlay {
	&.favlist-overlay {
		.height-100vh();
		left: 0;
		position: fixed;
		top: 0;
		padding: 10px;
		width: 100%;
		
		.x-body {
			padding: 25px;
			position: relative;
			
			.x-content {
				overflow-x: hidden;
				overflow-y: auto;
				padding-right: 10px;
				margin-right: -10px;
			}
			
			.favlist-edit-list-menu {
				&.is-pined {
					left: 25px;
					top: 25px;
					width: calc(~"100% - 50px");
				}
			}
		}
	}
}

.favlist-empty-checkbox {
	label {
		display: block;
		text-align: right;
		margin-top: 10px;
		
		span {
			display: block;
		}
	}
	
	&.is-mandatory {
		input {
			box-shadow: 0 0 0 2px @red-color;
		}
		
		label {
			span {
				color: @red-color;
			}
		}
	}
}

.favlist-alert-login {
	text-align: center;
	
	p {
		&:first-child {
			font-weight: bold;
			font-size: 14px;
			margin-bottom: 20px;
		}
	}
	
	.btn {
		white-space: normal;
		font-size: 20px;
		margin: 0;
		
		.icon-f {
			vertical-align: text-top;
		}
	}
	
	.separator {
		display: block;
		margin: 10px 0;
	}
}

.favlists-actions-cancel {
	margin-top: 10px;
	text-align: right;
	
	.btn {
		margin: 0;
	}
}

.favlist-elem-line,
.favlist-elem-small-line {
	display: flex;
	font-size: 0;
	margin: 0 0 5px;
	overflow: hidden;
	position: relative;
	padding: 5px;
	
	&:nth-child(2n) {
		background: @gray-lighterer;
	}
	
	.favlist-e-left {
		font-size: 0;
		width: 100%;
		
		& > * {
			display: inline-block;
			font-size: 16px;
			line-height: 38px;
			vertical-align: top;
		}
	}
	
	.favlist-e-action {
		float: right;
		font-size: 0;
		margin: 0 0 0 10px;
		max-width: 100%;
		white-space: nowrap;
		
		& > * {
			vertical-align: top;
		}
		
		.btn {
			font-size: 14px;
			line-height: 36px;
			margin: 0;
			padding-bottom: 0;
			padding-top: 0;
			
			.icon-f {
				font-size: 16px;
			}
		}
		
		.action-menu {
			font-size: 20px;
			height: 38px;
			line-height: 38px;
			margin-left: 5px;
			position: relative;
			right: 0;
			top: 0;
			width: 38px;
		}
	}
	
	.favlist-e-visibility {
		font-size: 18px;
		opacity: 0.5;
		text-align: center;
		width: 30px;
	}
	
	.favlist-e-title {
		font-size: 20px;
		max-width: calc(~"100% - 30px");
		line-height: 28px;
		padding: 5px;
		
		.is-watch-later {
			display: inline-block;
			font-size: 16px;
			line-height: 24px;
			padding: 0 2px 0 0;
			vertical-align: top;
		}
	}
	
	.favlist-e-tags {
		margin: 0 0 0 5px;
		vertical-align: middle;
		
		& > * {
			.thumb-block-duration();
			background: @gray-light2;
			color: #fff;
			font-size: 13px;
		}
		
		.max-videos-reached {
			background: @red-color;
		}
		
		.tag {
			background: @gray-bg;
			color: @text-color;
			margin-left: 0;
		}
	}
}

.favlist-elem-small-line {
	background: @gray-lighterer;
	display: inline-block;
	display: flex;
	vertical-align: top;
	width: 100%;
	
	.favlist-e-left {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		
		& > * {
			font-size: 16px;
			line-height: 28px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	
	.favlist-e-action {
		background: @gray-lighterer;
		margin-left: 5px;
		position: relative;
		z-index: 4;
		
		.btn {
			font-size: 14px;
			line-height: 26px;
			
			.icon-f {
				display: block;
				font-size: 16px;
				line-height: 26px;
			}
			
			.mobile-hide {
				display: none;
			}
		}
		
		.action-menu {
			font-size: 16px;
			height: 28px;
			line-height: 28px;
			margin-left: 5px;
			width: 28px;
		}
	}
	
	.favlist-e-visibility {
		font-size: 14px;
		width: 20px;
	}
	
	.favlist-e-title {
		font-size: 16px;
		max-width: calc(~"100% - 20px");
		line-height: 24px;
		padding: 2px 5px 2px 5px;
		
		.is-watch-later {
			font-size: 14px;
			line-height: 24px;
		}
	}
	
	.favlist-e-tags {
		margin: 2px 0;
		line-height: 22px;
		
		.favlist-e-nb-videos {
			font-size: 11px;
		}
	}
}

.users-videos.watch_later {
	.desktop-main-min-height-minus-header-and-footer();
	
	#profile-title {
		h2 {
			margin: 2px 0;
		}
	}
	
	#page-videos-content {
		position: relative;
	}
}

.watch-later-title {
	color: @gray-lighter;
	float: left;
	font-size: 106px;
	margin: 0 -140px -30px 20px;
}