
// Remove "/9" IE6-8 Hack

input[type="radio"],
input[type="checkbox"] {
	margin-top: 1px;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	margin-top: 4px;
}