#page.videos_history {
	#main {
		margin-top: @body-out-padding;
	}
	
	#page-videos-content {
		.big-message {
			color: @gray-light2;
			font-size: 20px;
			margin: 10px;
			text-align: center;
		}
	}
	
	#history-header {
		.premium-switch-page-dropdown {
			display: inline-block;
			vertical-align: middle;
			& + h2 {
				display: none;
			}
		}
		.icons {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 22px;
			margin-left: 5px;
			cursor: pointer;
			.icf-history,
			.icf-cog {
				position: absolute;
				display: block;
			}
			.icf-history {
				top: 0;
				left: 0;
				font-size: 20px;
				color: @gray-light;
			}
			.icf-cog {
				top: 50%;
				left: 50%;
				width: 13px;
				height: 13px;
				font-size: 14px;
				.text-border-2px(@body-bg);
			}
		}
	}
}

#page-videos-content {
	.loading-page {
		font-size: 14px;
		padding: 20px 20px 40px;
		text-align: center;
		
		strong {
			padding-right: 5px;
		}
		
		.icon-f {
			vertical-align: text-bottom;
		}
	}
}

.contextual-popup {
	.disable-history-confirm {
		form {
			display: block;
			margin: 10px 0 4px;
			
			label {
				font-weight: normal;
			}
		}
	}
}

.x-popup.history-config-popup {
	.x-popup-content {
		padding: 8px 0;
		
		.btn {
			display: block;
			width: 100%;
			padding: 6px 20px;
			margin: 0;
			text-align: left;
			font-weight: normal;
			font-size: 16px;
			color: #fff;
			background: none;
			border: none;
			border-radius: 0;
			transition: none;
			
			&:focus {
				outline: none;
			}
			
			&:hover {
				background: @theme-color;
			}
		}
	}
}
