.x-overlay.x-overlay-box {
	&.premium-popup-form {
		&.premium-form-layout-v1 {
			.x-body {
				max-width: 750px;
				#premium-popup-form {
					.included {
						margin: 20px 0 20px;
					}
				}
			}
		}
	}
}






#arony-pay-form {
	button {
		margin: .5em 20px;
	}
}



/* ==== max 767px = XVIDEOS ==== */
/* ==== max 991px = XNXX ==== */
@media (max-width: @screen-all-mobile) {
	body#red-page {
		.bloc-main-infos-row {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			
			.bloc-main-infos-column:nth-child(5) {
				display: none;
			}
		}
	}
}





/* Temp old style that can be removed later */

.premium-popup-form-ppv-header,
.premium-popup-form-membership-header {
	background: #222;
	border-radius: 4px;
	padding: 10px 10px 33px;
	position: relative;
	
	&:after {
		background: linear-gradient(to bottom, transparent, @premium-form-body-bgcolor);
		bottom: 0;
		content: "";
		display: block;
		height: 25px;
		left: 0;
		position: absolute;
		width: 100%;
	}
	
	.v-img {
		.v-img-p {
			height: 0;
			padding: 0 0 56.25%;
			position: relative;
			width: 100%;
			
			.video-hd-mark {
				font-size: 12px;
				position: absolute;
				right: 2px;
				top: 2px;
				z-index: 2;
			}
		}
		
		img {
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
		
		.cover-thumb {
			background: rgba(0, 0, 0, 0.1);
			height: 100%;
			left: 0;
			padding: 3%;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
	
	& + .form-group,
	& + form {
		margin: 0 auto 0.5em;
		
		&:not(.is-only-displayed) {
			margin-top: -45px;
		}
	}
	
	.detail-info {
		.detail-info-btn {
			border: 0;
			border-radius: 50%;
			color: #fff;
			font-size: 22px;
			padding: 4px;
			z-index: 5;
			background: 0 0;
			position: unset;
			
			&:focus, &:active, &:active:focus {
				outline: none;
			}
		}
		
		.info {
			display: none;
		}
	}
}

.premium-popup-form-ppv-header {
	.v-img {
		display: flex;
		align-items: flex-start;
		margin: 0 auto;
		max-width: 200px;
		width: 80% !important;
	}
}

.premium-popup-form-membership-header {
	text-align: left;
	
	img {
		width: 100%;
	}
	
	.banner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
	}
	
	.main_pic {
		box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.5);
		left: 10px;
		max-width: 100px;
		position: absolute;
		width: 20%;
		z-index: 3;
	}
	
	.included {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
		max-width: 130px;
		overflow: hidden;
		position: absolute;
		right: 10px;
		width: 40%;
		z-index: 2;
		
		span {
			background: @btn-primary-bg;
			border-radius: 3px;
			box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
			bottom: 3px;
			color: @btn-primary-color;
			font-weight: bold;
			margin: 0;
			padding: 0 4px;
			position: absolute;
			right: 3px;
		}
	}
	
	.title {
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 24px;
		line-height: 28px;
		padding: 8px 0 4px;
		margin: 0;
		
		@media (max-width: @screen-xs-max) {
			margin-top: 75px;
		}
	}
	
	@media (min-width: @screen-xs-min) {
		&.with-included {
			min-height: 120px;
			padding-right: calc(~"min(130px, 40%) + 20px");
		}
		
		&.with-main-pic {
			min-height: 130px;// this has priority over  .with-included
			padding-bottom: 50px;
			padding-left: calc(~"min(100px, 20%) + 20px");
		}
	}
}
.form-field-premium-form_product {
	.count {
		text-align: center;
	}
}


/* ==== Generic new style popup ==== */
.x-overlay.x-overlay-box.premium-popup-form-v1 {
	background: rgba(0,0,0,.8);
	
	.x-body {
		background: @premium-form-body-bgcolor;
		border: 2px solid @premium-form-body-bordercolor;
		border-radius: @premium-form-body-radius;
		
		padding: 0;
		font-size: 1rem;
		max-width: 750px;
		
		.x-content {
			padding: @premium-form-content-padding;
		}
		
		.x-close {
			background: @premium-form-body-bgcolor;
			border: 2px solid @premium-form-body-bordercolor;
			border-radius: 20px;
			color: #eee;
			display: inline-block;
			font-size: 65%;
			padding: 2px;
			right: -5px;
			text-shadow: none;
			top: -5px;
			z-index: 10;
			
			.icon-f {
				display: block;
				padding: 5px;
			}
			
			&:hover {
				color: #fff;
				background: lighten(@premium-form-body-bgcolor, 15%);
				cursor: pointer;
			}
			
			&:active {
				transform: translateY(2px);
			}
		}
	}
	
	.red-color,
	h1.red-color,
	h2.red-color,
	h3.red-color {
		color: @red-color;
	}
	
	.icf-ticket-red {
		color: @red-color;
		display: inline-block;
		font-size: 28px;
		margin: -0.16em 0;
		vertical-align: top;
	}
}

.x-overlay.x-overlay-box.chose-payment-type .x-body {
	max-width: 900px;
}
.x-overlay.x-overlay-box.purchase-form .x-body {
	
	.detail-info {
		font-size: 92%;
		opacity: .35;
		margin-left: 5px;
		display: flex;
		.info {
			display: none;
		}
	}
	
	.details-crypto {
		display: none !important;
	}
	
	.offer-title {
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 100%;
		text-align: center;
		font-size: 22px;
		font-weight: bold;
		margin: 10px;
		line-height: 75%;
		
		& + .premium-plans {
			margin-top: 5px;
		}
	}
	
	.single-form .offer-title {
		// margin: -26px 17px 17px; // incompatible with offer-ribbon-christmas-23
		
		@media (max-width: @screen-xs-max) {
			margin-top: 0;
		}
	}
	
	.category-sep {
		display: flex;
		gap: 5px;
		margin-top: 10px;
		margin-left: 8px;
		color: #a19f9f;
		text-align: left;
		.method {
			color: white;
			display: flex;
			gap: 5px;
			align-items: center;
			
			.icf-pay-sepa {
				font-size: 80%;
			}
		}
	}
	
	.premium-plans {
		display: flex;
		flex-wrap: wrap;
		@media (max-width: @screen-xs-max) {
			flex-direction: column;
		}
		justify-content: space-around;
		
		width: 100%;
		margin: 0;
	}
	
	// Handle width depending on amount of available offers
	// To keep available / unavailable each on the same line
	.premium-plans {
		@media (min-width: @screen-xs-max) {
			each(range(1,5,1), {
				&[data-x-items-unavailable="@{value}"] {
					.premium-plan {
						width: 100%/@value;
						font-size: 70% + (30%/@value);
					}
				}
			});
			each(range(1,3,1), {
				&[data-x-items-available="@{value}"] {
					.premium-plan.available {
						width: 100%/@value;
						font-size: 70% + (30%/@value);
					}
				}
			});
		}
		@media (max-width: (@screen-xs-max + 1)) {
			.premium-plan,
			.premium-plan.available {
				width: 100%;
			}
		}
	}
	
	// Encapsulates .plan
	.premium-plan {
		flex: auto;
		padding: @plan-gap;
		@media (max-width: @screen-xs-max) {
			padding: (@plan-gap/2) @plan-gap;
			margin-top: 0;
		}
		
		order: 1;
		
		.details-other {
			display: block;
			text-align: center;
		}
		.details {
			display: none;
			text-align: center;
		}
		
		&.available {
			order: -1;
			
			.details-other {
				display: none;
			}
			.details {
				display: block;
			}
		}
	}
	
	.option-crypto {
		background: @premium-form-available-bgcolor;
		&:hover {
			background: @premium-form-available-hover-bgcolor;
		}
		border: none;
		border-radius: @premium-form-element-radius;
		
		&.selected {
			background: @premium-form-active-bgcolor;
			outline: 3px solid @premium-highlight-color;
		}
	}
	
	.purchase-form-contents > .included {
		display: flex;
		@media (max-width: @screen-xs-max) {
			flex-direction: column;
		}
		justify-content: center;
		align-items: center;
		margin: 20px 0 20px;
		
		.included-title {
			font-weight: bold;
			font-size: 28px;
			padding-right: 10px;
			text-align: right;
			text-transform: uppercase;
		}
		
		.included-list {
			display: inline-block;
			margin: 0;
			padding: 0;
			
			@media (max-width: @screen-xs-max) {
				li {
					width: fit-content;
				}
			}
			
			li {
				display: block;
				line-height: 20px;
				margin: 0;
				padding: 0 0 0 30px;
				position: relative;
				text-align: left;
				font-size: 15px;
				
				&::before {
					.font-family-iconfont();
					color: @premium-form-valid-color;
					content: @icf-check!important;
					font-size: 14px;
					left: 5px;
					position: absolute;
					top: 3px;
				}
			}
		}
	}
	
	.currency-switch-block {
		position: relative;
		width: 115px;
		z-index: 2;
		
		.x-dropdown .x-dropdown-selected {
			padding: 5px 0;
		}
	}
	
	.tabbed-form-container {
		position: relative;
		z-index: 1;
	}
	
	.details .price {
		color: white;
	}
	
	.plan {
		align-items: center;
		color: #a8a8a8;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		line-height: 1.2;
		padding: 0.5em;
		position: relative;
		
		input {
			display: none;
		}
		
		&.selected {
			&.is-clicked {
				border-color: #fff;
				color: #fff;
			}
			
			.icon-f {
				display: inline-block;
			}
		}
		
		.label {
			display: block;
			color: white;
			font-size: 140%;
			@media(max-width: 479px) {
				font-size: 1.2rem;
			}
			font-weight: 600;
			white-space: normal;
			
			sup {
				top: -.25em;
			}
			
			&.strong {
				font-size: 170%;
				@media(max-width: 479px) {
					font-size: 140%;
				}
				margin-top: .4rem;
				
				&.monthly-price {
					margin-top: auto;
				}
			}
		}
		
		.monthly-prices {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			
			.monthly-price {
				padding-right: 0;
				padding-left: 0;
				
				&.monthly-price-striked {
					text-decoration: line-through;
					opacity: 50%;
					@media(min-width: 768px) {
						display: none;
					}
				}
				
			}
		}
		
		.details {
			font-size: 1em;
			@media(max-width: 479px) {
				font-size: .9rem;
			}
			
			&.strong {
				font-weight: bold;
				text-transform: uppercase;
			}
			
			sup {
				top: -.25em;
			}
		}
		
		.detail-info {
			.detail-info-btn {
				right: 5px;
				top: -15px;
			}
		}
		
		.saving {
			background: @saving-tag-bg;
			border-radius: 1em;
			color: @saving-tag-color;
			font-size: 0.85em;
			font-weight: bold;
			line-height: 0.25em;
			padding: 0.4em;
			position: absolute;
			right: -0.5em;
			top: -1.2em;
			.icon-f.icf-star-full {
				padding: .1em 0em .1em .2em;
				line-height: 1em;
				font-size: 1em;
			}
		}
	}
	
	.premium-plans-block {
		position: relative;
		
		.premium-plans {
			.detail-info {
				.detail-info-btn {
					right: -10px;
					top: -20px;
				}
			}
		}
	}
	
	.premium-plan:not(.available) {
		.plan:hover {
			background: @premium-form-available-hover-bgcolor;
			cursor: pointer;
			opacity: @premium-form-available-opacity;
		}
	}
	
	.control-label {
		display: none;
	}
	
	button[type="submit"],
	.btn-ppv-video-buy-submit,
	.btn-channel-membership-buy-submit,
	button.g-recaptcha,
	a.btn {
		border: none medium;
		border-radius: .3em;
		font-size: 1.4rem;
		font-weight: 700;
		line-height: initial;
		overflow: hidden;
		margin: .5em 10px;
		max-width: 100%;
		padding: .4em .7em;
		white-space: normal;
		width: calc(100% - 20px);
	}
	
	.login {
		// Override default link color even on hover
		color: #717171!important;
		display: block;
		font-size: 0.85em;
		line-height: 1;
		margin: 0.5em auto;
		padding: 0.3em 0.7em 0.3em;
		text-decoration: none;
		text-align: center;
		
		span {
			color: #d9d9d9!important;
		}
		&:hover {
			span {
				color: @premium-highlight-color!important;
			}
		}
	}
	
	.anonymous-secure,
	.legal {
		color: #717171;
		margin: 0 0 0.5em;
		text-align: center;
		
		a {
			color: #717171;
		}
		.icf-lock {
			color: #d9d9d9;
		}
		.secure {
			color: #d9d9d9;
		}
	}
	.legal {
		font-size: .85rem;
		margin: 1em 0 0;
	}
}

.payment-icon {
	background-color: white;
	
	&.pix-icon {
		margin-left: 3px;
	}
	
	&.sepa-icon {
		margin-bottom: 2px;
	}
}

.selectable-element-parent {
	padding: 10px;
	@media (max-width: @screen-xs-max) {
		padding: 5px 10px;
	}
	flex: 1 1 0;
	
	.payment-type {
		display: flex;
		justify-content: center;
		padding: 8px;
		gap: 8px;
		@media (max-width: @screen-xs-max) {
			gap: 10px;
		}
		align-items: center;
		height: 100%;
		min-height: 32px;
		
		.payment-type-text {
			white-space: nowrap;
			display: flex;
			justify-content: flex-start;
			width: 100%;
			gap: 3px;
			
			.icon-f {
				font-size: 140%;
				
				&.icf-pay-sepa {
					font-size: 100%;
				}
			}

			.payment-type-text-label {
				margin: auto;
			}
		}
		
		.payment-icon-vertical {
			display: flex;
			flex-direction: column;
		}
		
		.sepa_pix_sep {
			margin-left: 9px;
			margin-right: 9px;
		}
		&[data-x-payment-type="PIX_SEPA"] {
			justify-content: center;
		}
	}
}

.selectable-element {
	border: none;
	
	&:not(.x-dropdown) {
		border-radius: @premium-form-element-radius;
	}
	
	background: @premium-form-dimmed-bgcolor;
	opacity: @premium-form-dimmed-opacity;
	cursor: default;
	
	&:hover {
		outline: @premium-form-available-outline-hover;
	}
	
	&.selected {
		background: @premium-form-active-bgcolor;
		outline: 3px solid @premium-highlight-color;
	}
}

.selectable-element.available,
.selectable-element-parent.available .selectable-element {
	&:not(.selected) {
		background: @premium-form-available-bgcolor;
		&:hover {
			background: @premium-form-available-hover-bgcolor;
		}
	}
}

.selectable-element.available,
.selectable-element-parent.available .selectable-element {
	opacity: @premium-form-available-opacity;
	cursor: pointer;
}

.chosemethod-modal {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: @screen-xs-max) {
		text-align: center;
	}
	
	.chose-title {
		display: flex;
		@media (max-width: @screen-xs-max) {
			flex-direction: column;
		}
		width: 100%;
		justify-content: center;
		gap: 5px;
		
		font-weight: 700;
		color: darken(white, 20%);
		.title-method {
			color: white;
		}
		
		// SEPA icon center fix
		&.SEPA {
			.chose-current {
				display: flex;
				.title-method {
					font-size: 90%;
					padding-left: 5px;
				}
			}
		}
	}
	
	.methods {
		display: flex;
		width: 100%;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px;
		
		.selectable-element-parent {
			@media (max-width: @screen-sm-max) {
				width: calc(50% - 10px);
			}
			@media (max-width: 600px) {
				width: calc(100% - 10px);
			}
			@media (min-width: @screen-sm-min) {
				min-height: 100%;
			}
		}
		.selectable-element.payment-type {
			padding-left: 20px;
			padding-right: 20px;
			
			.x-dropdown-selected-option {
				margin-left: 0;
			}
		}
	}
}

.selectable-element-parent {
	&.same-width {
		padding: 3px;
		flex: unset;
	}
	&.size-1 {
		width: calc(100% - 10px);
	}
	&.size-2 {
		width: calc(50% - 10px);
	}
	&.size-3 {
		width: calc(33% - 10px);
	}
}

@import '../purchase_multiform.less';//@todo #104153 // comment to go back to previous multiform config
