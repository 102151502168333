// Xvideos skin for x-dropdown

.x-dropdown.default {
	border: 1px solid @gray-bg;
	background: 0 0;
	flex-grow: 2;
	font-size: 12px;
	line-height: 18px;
	min-width: 25%;
	position: relative;
	text-align: left;
	border-radius: 0;
	max-height: fit-content;
	min-height: 36px;
    padding: 5px;

	&.open {
		background-color: @dropdown-opened-bg;
		
		.x-dropdown-select,
		.x-dropdown-option {
			background-color: @dropdown-opened-bg;
		}
	}
	.x-dropdown-caret::before { content: @icf-caret-down; }
	&.open .x-dropdown-caret::before { content: @icf-caret-up; }

	.x-dropdown-value {
		color: @text-color;
	}

	.x-dropdown-caret {
		color: @gray-light2;
		font-size: 14px;
		margin-top: -7px;
		position: absolute;
		right: 4px;
		top: 50%;
	}
	.x-dropdown-caret::before { content: @icf-caret-down; }
	&.open .x-dropdown-caret::before { content: @icf-caret-up; }

	.x-dropdown-select {
		border: 1px solid @gray-bg;
	}

	.x-dropdown-selected .x-dropdown-selected-option {
		.icon-f {
			display: none;
		}
		.x-dropdown-caret.icon-f {
			display: flex;
		}
	}

	.x-dropdown-select,
	.x-dropdown-option,
	.x-dropdown-sep {
		background:  0 0;
	}
	
	.x-dropdown-option {
		border-top: 1px solid @gray-bg;
		
		.icon-f {
			color: @dropdown-icon;
			&.icf-swap {
				transform: rotate(90deg);
			}
		}
		
		&:not(.disabled):hover {
			background-color: @dropdown-opt-bg-hover;
		}
		
		&.selected {
			font-weight: bold;
			
			&,
			&:not(.disabled) {
				&:hover {
					background-color: @dropdown-opt-selected-bg-hover;
				}
			}
		}
	}

	.x-dropdown-select,
	.x-dropdown-select .xscroll-container,
	.x-dropdown-select .x-options-container {
		border-radius: 0;
	}


	.x-dropdown-sep,
	.x-dropdown-empty-search {
		display: flex;
		justify-content: flex-start;
    	background-color: @dropdown-sep-bg;
		margin-left: 0;
		margin-right: 0;
		border-bottom: 0;
		padding: 6px 0;
		border-top: 1px solid @gray-bg;
		.x-dropdown-sep-text,
		.x-dropdown-empty-search-text {
			font-size: 13px;
    		line-height: 18px;
			margin-left: 18px;
		}
	}

	.x-dropdown-sep {
		padding-left: 10px;
		background-color: @dropdown-sep-bg;
		.icon-f {
			color: @dropdown-sep-icon;
		}
		.x-dropdown-sep-text {
			color: @dropdown-sep-text;
			margin-left: 0;
		}
	}
 
	.x-dropdown-empty-search {
    	background-color: @dropdown-search-bg;
		.x-dropdown-empty-search-text {
			color: @dropdown-sep-text;
		}
	}

	.x-dropdown-search-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: auto;

		.x-dropdown-search-icon {
			font-size: 18px;
			margin-left: 4px;
			margin-right: 8px;
			color: @gray-bg;
			cursor: default;
		}

		.x-dropdown-search-input {
			background-color: @dropdown-search-bg;
			color: @dropdown-search-color;
			width: stretch;
			height: 24px;
			outline: none;
  			border: none;
			
			&::placeholder {
				color: @dropdown-search-placeholder;
			}
		}

		.x-dropdown-search-reset {
			font-size: 14px;
			margin-left: 8px;
			margin-right: 4px;
		}
	}
}