.models-selector {
	//i.icf-close.text-danger {
	//	bottom: 5px;
	//	right: 5px;
	//}
	.models-list {
		border: 1px solid fade(@form-tag-adder-border, 50%);
		background: fade(@form-tag-adder-bg, 50%);
		overflow: hidden;
		display: block;
		min-height: @form-tag-adder-tag-height + @body-out-padding + 2px;
		padding: @body-out-padding/2 @body-out-padding*2 0 @body-out-padding;
		position: relative;
		transition: all 0.2s;
		html[dir='rtl'] & {
			padding: @body-out-padding/2 @body-out-padding 0 @body-out-padding*2;
		}
		input,
		button.add,
		.model {
			float: left;
			font-size: 16px;
			margin: 0 @body-out-padding @body-out-padding/2 0;
			position: relative;
			z-index: 2;
			html[dir='rtl'] & {
				float: right;
				margin: 0 0 @body-out-padding/2 @body-out-padding;
			}
		}
		button.add,
		.model {
			background: @form-tag-adder-tag-bg;
			border: 0;
			color: @text-color;
			cursor: default;
			line-height: @form-tag-adder-tag-height - @body-out-padding;
			min-height: @form-tag-adder-tag-height;
			overflow: hidden;
			padding: @body-out-padding/2 10px;
			
			.txt {
				transition: 0.15s all;
				word-break: break-all;
				
				.icf-warning {
					vertical-align: text-bottom;
				}
			}
			
			.validated,
			.edit,
			.del {
				cursor: pointer;
				display: none;
				height: @form-tag-adder-tag-height;
				position: absolute;
				text-align: center;
				top: 0;
				width: 0;
			}
			.del {
				right: -@form-tag-adder-tag-height;
				transition: all 0s 0.2s;
			}
		}
		label,
		button.add,
		input {
			display: none;
		}
	}
	+ .help-block,
	.models-selector-legend {
		background: @form-tag-adder-tag-legend-bg;
		color: @form-tag-adder-tag-legend-color;
		float: left;
		font-size: 12px;
		opacity: 0.5;
		padding: 2px 8px;
		.icon-f {
			font-size: 13px;
			margin-right: 3px;
			vertical-align: text-bottom;
		}
	}
	+ .help-block {
		clear: left;
		background: none;
	}
	&.init-ok {
		.models-list {
			background: @form-tag-adder-bg;
			border-color: @form-tag-adder-border;
			color: @form-tag-adder-input-color;
			cursor: text;
			label {
				bottom: 0;
				cursor: text;
				display: block;
				left: 0;
				line-height: @form-tag-adder-tag-height;
				margin: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 1;
			}
			input {
				border: 0;
				background: @form-tag-adder-input-bg;
				display: block;
				//cursor: pointer;
				margin: 0;
				padding: 0;
				position: relative;
				transition: all 0.2s;
				width: 0;
				&.not-empty-val {
					box-shadow: 0 0 8px rgba(0, 0, 0, .1) inset;
				}
				&.not-empty-val,
				&.focus,
				&:focus {
					//box-shadow: 0 0 8px rgba(0, 0, 0, .1);
					border: 1px solid @form-tag-adder-tag-bg;
					outline: none;
					margin: 0 0 @body-out-padding/2;
					padding: @body-out-padding/2 @form-tag-adder-tag-height*0.33;
					width: 120px;
				}
			}
			button.add {
				cursor: pointer;
				display: block;
				font-weight: bold;
				padding: 0 @body-out-padding;
				&:focus,
				&:active {
					outline: none;
				}
			}
			input.focus,
			input:focus {
				border-radius: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				+ button.add {
					border-radius: 0;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
				html[dir='rtl'] & {
					border-radius: 0 4px 4px 0;
					+ button.add {
						border-radius: 4px 0 0 4px;
					}

				}
				&.inp-error {
					animation: error_focused 1.5s ease-out;
				}
			}
			input,
			button.add {
				height: @form-tag-adder-tag-height;
			}
			.model {
				transition: 0.15s background;
				&.focused,
				&.partial-focused {
					animation: tag_focused 1.5s ease-out;
				}
				@keyframes tag_focused {
					.animation_pulse(@form-tag-adder-tag-hover-bg, 0%);
				}
				&:hover,
				&.focused,
				&.partial-focused {
					background: @form-tag-adder-tag-hover-bg;
				}
				&:hover,
				&.focused {
					.del {
						right: 0;
						width: @form-tag-adder-tag-height;
						.icon-f {
							margin-left: @form-tag-adder-tag-height*0.67;
							opacity: 1;
						}
					}
				}
				&.error {
					background: @form-tag-adder-tag-error-bg;
					color: @form-tag-adder-tag-error-color;
				}
				.icf-warning {
					display: none;
					margin-right: 6px;
				}
				&.model-warning-3 {
					.button-variant(@btn-warning-color, @btn-warning-bg, @btn-warning-border);
					.icf-warning {
						display: unset;
					}
				}
				&.model-warning-4 {
					.button-variant(@btn-danger-color, @btn-danger-bg, @btn-danger-border);
					.icf-warning {
						display: unset;
					}
				}
				.validated,
				.edit,
				.del {
					display: block;
					
					.icon-f {
						.inline-block;
						font-size: 14px;
						height: @form-tag-adder-tag-height;
						line-height: @form-tag-adder-tag-height;
						transition: 0.15s all;
						vertical-align: text-bottom;
					}
				}
				.del {
					.icon-f {
						background: @form-tag-adder-tag-bg;
						border-bottom-left-radius: 4px;
						border-top-left-radius: 4px;
						margin-left: @form-tag-adder-tag-height;
						opacity: 0;
						width: @form-tag-adder-tag-height*0.33;
					}
					&:hover {
						color: @red-color;
					}
				}
				
				&.model-with-edit {
					padding-left: 5px + @form-tag-adder-tag-height;
					
					.edit {
						background: fadeOut(@text-color, 80%);
						left: 0;
						position: absolute;
						right: auto;
						top: 0;
						width: @form-tag-adder-tag-height;
						
						&:hover {
							.icon-f {
								transform: scale(1.2);
							}
						}
					}
				}

				&.model-with-validated {
					padding-left: 5px + @form-tag-adder-tag-height;

					.validated {
						background: fadeOut(@text-color, 80%);
						left: 0;
						position: absolute;
						right: auto;
						top: 0;
						width: @form-tag-adder-tag-height;
						
						&:hover {
							.icon-f {
								transform: scale(1.2);
							}
						}
					}
				}
				&.focused .del,
				.del:hover {
					.icon-f {
						font-size: 20px;
						margin-left: 0;
						width: @form-tag-adder-tag-height;
					}
				}
			}
		}
		+ .help-block,
		.models-selector-legend {
			opacity: 1;
			transition: opacity 0.2s;
			&.legend-error {
				animation: error_focused 1.5s ease-out;
			}
		}
		@keyframes error_focused {
			.animation_pulse(@red-color, 0%);
		}
	}
}

.tag-adder-pop {
	.x-popup-arrow {
		border-bottom-color: @form-tag-adder-tag-bg;
	}
	.x-popup-content {
		background: @form-tag-adder-tag-bg;
		padding: 5px 0;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		background: @form-tag-adder-tag-bg;
		color: @text-color;
		cursor: pointer;
		font-size: 15px;
		line-height: 20px;
		margin: 0;
		padding: 5px 10px;
		transition: 0.2s all;
		strong {
			color: @state-danger-text;
		}
		&:hover,
		&.focused {
			background: @form-tag-adder-tag-hover-bg;
		}
	}
}

.models-selector-legend-error-pop {
	.x-popup-arrow {
		border-top-color: @theme-color;
	}
	.x-popup-content {
		background: @theme-color;
		color: white;
		font-size: 14px;
		font-weight: bold;
		padding: 2px 10px;
	}
}

.toggle-models-selector-legend {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 5px;
	padding-top: 7px;

	.toggle__option {
		font-size: 14px;
		opacity: 0.5;

		&.is-on {
			opacity: 1;
		}
	}
	.checkbox.toggle {
		padding: 0;
		margin: 0 10px !important;
	}
}
