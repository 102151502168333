
.global-feed-channel-preview-pop{

	&.x-overlay.x-overlay-box {
		padding: 0 10px;

		.x-body {
			padding: 25px 15px 10px;
			@media (min-width: @screen-sm-min) {
				width: 600px;
			}
			@media (max-width: @screen-sm-max) {
				max-width: 600px;
			}
			background: @suggest-account-preview-pop-bg;
		}
	}

	.cover-loader {
		background: @premium-feed-cover-loader-bg;
		bottom: 0;
		color: @premium-feed-cover-loader-color;
		font-size: 80px;
		left: 0;
		padding: 20px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		z-index: 2;
	}

	.account-preview-header {
		display: flex;

		margin-bottom: 10px;
		height: 70px;
		overflow: hidden;

		.account-preview-title {
			display: flex;

			.account-preview-flag {
				margin-right: 10px;
			}
		}

		.account-preview-info {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			overflow: hidden;

			margin-left: 4px;
		}

		.page-link {
			.button-variant(@btn-default-color, @btn-default-bg, @btn-default-border);
			float: right;
			line-height: 28px;
			margin: 0 0 0 4px;
			padding: 0 12px;


			.icon-f {
				float: left;
				line-height: 26px;
				opacity: 0.3;
				padding-right: 6px;

				&.icf-crown {
					font-size: 1.3em;
					opacity: 0.6;
				}
			}
		}

		.prof-pic-n-page-link {

			&.with-pic {
				.profile-pic {
					height: 70px;
					float: left;
					width: 70px;
					
					img {
						height: 70px;
						object-fit: contain;
						object-position: center;
						width: 70px;
					}
				}

				& + h2,
				& ~ .membership-info ,
				& ~ .account-actions {
					margin-left: 80px;
				}
				& ~ .membership-info {
					width: calc(100% - 80px);
				}
			}
		}

		&.with-membership-info {
			height: 136px;

			.prof-pic-n-page-link {
				&.with-pic {
					& ~ .account-actions {
						margin-left: 0;
					}
				}
			}

			& + .mozaique {
				.max-height-100vh(201px);
			}
		}

		.membership-info {
			align-items: center;
			display: flex;
			width: 100%;
			
			& > * {
				display: inline-block;
				margin: 0 10px 0 0;
				vertical-align: top;
			}
			
			.icon-f {
				color: #777;
			}

			.icf-bar-chart {
				color: #666;
				font-size: 40px;
			}

			.membership-info-videos {
				text-align: center;
			}

			.membership-info-videos,
			.membership-info-other {
				flex-shrink: 2;
				overflow: hidden;

				p {
					overflow: hidden;
					margin: 0;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			.membership-info-detail {
				margin-right: 0;

				.icf-question-circle-o {
					cursor: pointer;
					font-size: 26px;

					.notouch &:hover {
						color: #fff;
					}
				}

				.membership-info-detail-hover {
					background: #212121;
					border: 10px solid @suggest-account-preview-pop-bg;
					box-shadow: 0 0 0 1px inset #444;
					display: none;
					left: 50%;
					max-width: 100%;
					padding: 10px 15px;
					position: absolute;
					top: 0;
					transform: translateX(-50%);
					z-index: 50;
					width: 450px;

					p {
						line-height: 20px;
						margin: 0;

						.icon-f {
							display: inline-block;
							text-align: center;
							width: 20px;
						}
					}

					.close {
						cursor: pointer;
						float: right;
						height: 30px;
						line-height: 30px;
						margin: -10px -15px 5px 5px;
						text-align: center;
						width: 30px;
					}
				}

				.membership-info-detail-hover.is-opened {
					display: block;
				}
			}
		}

		.account-actions {
			display: flex;
			justify-content: space-around;

			height: auto;
			max-height: none;

			.btn {
				display: flex;
				justify-content: center;
				align-items: center;

				overflow: hidden;
    			text-overflow: ellipsis;

				height: 30px;
				line-height: unset;
				margin: 0 0 2px;
				padding: 0 2px;
				position: relative;
				transition: none;
				width: 49%;
				float: left;
				margin-bottom: 0;

				.btn-text {
					color: @text-color;
					white-space: break-spaces;
					line-height: 14px;
				}
				.icon-f {
					color: @text-color;
					opacity: 1 !important;
				}

				&:after {
					bottom: 0;
					content: " ";
					display: block;
					left: 0;
					top: 0;
					width: 0;
				}

				&.sub {
					&:after {
						background: @premium-feed-account-sub-load-bg;
						color: @premium-feed-account-sub-color;
					}
				}

				&.reject {
					margin-left: 2%;
					&:after {
						background: @premium-feed-account-reject-load-bg;
						color: @premium-feed-account-reject-color;
					}
				}

				&:focus,
				&:active,
				&:focus:active {
					outline: none;
				}

				&.loading {
					&:after {
						position: absolute;
						transition: all 3s ease;
						z-index: 2;
						width: 100%;
					}
				}

				.label {
					display: inline-block;

					sup {
						font-size: 0.9em;
					}
				}

				.icon-f {
					float: left;
					line-height: 28px;
					opacity: 0.3;
					padding-right: 4px;

					&.icf-crown {
						font-size: 1.4em;
						opacity: 0.6;
					}
				}
			}
		}
	}

	.mozaique {
		overflow: auto;
		max-height: 300px;
		.max-height-100vh(135px);
		position: relative;
	}

	.loader {
		clear: both;
		text-align: center;
	}

	.thumbs-2-cols {
		.membership-detail {
			margin: 5px auto 15px;
			padding: 10px;
			font-size: 20px;
			max-width: 300px;
			position: relative;
			text-align: center;

			.membership-detail-left,
			.membership-detail-right {
				display: inline-block;
				padding: 10px 5px;

				& > * {
					display: block;
				}
			}
			.membership-detail-left {
				text-align: right;
			}
			.membership-detail-right {
				text-align: left;
			}

			&:after,
			&:before {
				bottom: 2%;
				content: '';
				left: 2%;
				position: absolute;
				top: 2%;
				transition: 0.2s all;
				right: 2%;
			}
			&:after {
				border-left: 2px solid transparent;
				border-right: 2px solid transparent;
				border-image: linear-gradient(180deg, #fff 0%, #fff 30%, transparent 30.01%, transparent 69.99%,  #fff 70%, #fff 100%) 10;
			}
			&:before {
				border-bottom: 2px solid transparent;
				border-top: 2px solid transparent;
				border-image: linear-gradient(90deg, #fff 0%, #fff 30%, transparent 30.01%, transparent 69.99%,  #fff 70%, #fff 100%) 10;
			}

			p {

				strong {
					float: left;
					padding-right: 10px;
					text-align: right;
					width: 46%;
				}
			}
		}
		.thumb-block {
			padding-bottom: 0;
			padding-top: 0;
			width: 50%;

			&:first-child:last-child {
				width: 100%;
			}

			.video-title {
				background-image: linear-gradient(0deg, @premium-feed-account-profile-name-bg 0%, transparent 100%);
				bottom: 0;
				color: @premium-feed-account-profile-name-color;
				display: block;
				font-size: 14px;
				font-weight: bold;
				left: 0;
				line-height: 20px;
				overflow: hidden;
				padding: 20px 4px 0;
				position: absolute;
				right: 0;
				text-align: center;
				text-overflow: ellipsis;
				.text-stroke-1px(fadeout(@premium-feed-account-profile-name-bg, 50%));
				white-space: nowrap;
				z-index: 2;
			}
		}
	}

	.account-preview-header .label {
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;

		line-height: 30px;
		margin: 0;

		color: @suggest-account-preview-pop-title-color;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/* existing/suggested model selection */
	&.model-video-preview {
		.xscroll-content {
			max-height: calc(var(--vh,1vh) * 100 - 135px);
			.thumbs-2-cols {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				overflow: visible;
				padding: 0;

				img {
					vertical-align: middle;
					border: 1px solid black;
					width: calc(50% - 2px);
					margin: 1px;
				}
			}
		}

		.account-actions {
			.disabled{
				display: none;
			}
			.edit,
			.assign {
				.label,
				.icon-f {
					font-size: 15px;
					padding: 2px;
					height: fit-content;
					width: fit-content;
					line-height: 1;
				}
			}
		}
	}

	@media (max-width: @screen-xs-max) {

		&.x-overlay.x-overlay-box {
			.x-body {
				padding-top: 15px;
			}
		}

		.mozaique {
			.max-height-100vh(125px);
		}

		.account-preview-header {

			.prof-pic-n-page-link {

				&.with-pic {
					float: left;

					.btn {
						overflow: hidden;
						margin: 4px 0;
						padding: 0 2px;
						text-overflow: ellipsis;

						.icon-f {
							padding-right: 3px;
						}
					}

					& + h2 {
						.label {
							overflow: hidden;
							max-height: 48px;
							word-break: break-all;
							white-space: normal;
							.line-clamp(2);
						}
					}

					& + h2,
					& ~ .membership-info ,
					& ~ .account-actions {
						margin-left: 70px;
					}
					& ~ .membership-info {
						width: calc(100% - 70px);
					}
				}
			}

			.membership-info {
				& > * {
					flex-grow: 2;
					margin: 0 5px 0 0;
				}

				.icf-bar-chart {
					display: none;
				}

				.membership-info-detail {
					.icf-question-circle-o {
						font-size: 20px;
					}
				}
			}

			&.with-membership-info {
				height: 130px;

				.prof-pic-n-page-link {
					.account-actions {
						clear: both;
					}

					&.with-pic {
						& ~ .account-actions {
							margin-left: 0;
						}

						& + h2 {
							align-items: center;
							display: flex;
							height: 48px;
							justify-content: center;
							padding-right: 15px;
						}
					}
				}

				& + .mozaique {
					.max-height-100vh(187px);
				}
			}

			@media @media-ratio-vertical {
				&.with-membership-info {
					height: auto;
					min-height: 130px;
					max-height: 150px;

					& + .mozaique {
						.max-height-100vh(207px);
					}
				}
				/*h2 + .membership-info {
					.line-clamp(2);
					overflow: hidden;
					white-space: normal;
					word-break: break-all;
				}*/
			}
		}

		.thumbs-2-cols {
			padding-right: 0;

			.thumb-block {
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
		}
	}

	@media (max-width: @screen-xxs-max) {
		&.x-overlay.x-overlay-box {
			.x-body {
				padding-left: 8px;
				padding-right: 8px;
			}
		}
	}
}