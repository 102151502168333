.facial-recognition {
	.desktop-main-min-height-minus-header-and-footer();
	h1 {
		margin: 30px 0 15px;
	}
	#similar-face-form {
		fieldset {
			margin: 20px auto 10px;
			max-width: 800px;
			overflow: hidden;
			div.form-group {
				float: left;
				margin: 0;
				&:first-child {
					margin-right: -1px;
					min-height: 50px;
					width: 100%;
					.content {
						min-height: 0;
					}
					label.control-label {
						cursor: pointer;
						display: block;
						font-size: 16px;
						margin: 0 0 4px;
						text-align: center;
						span {
							&:first-child {
								font-size: 16px;
								display: block;
								line-height: 24px;
								margin-bottom: 5px;
							}
							&.icf-upload {
								background: @red-color;
								border: 1px solid @gray-lighter;
								border-radius: 3px;
								color: white;
								display: block;
								font-size: 40px;
								height: 60px;
								line-height: 60px;
								margin: 0;
								padding: 0;
								text-align: center;
								transition: all 0.2s;
							}
						}
						&:hover {
							span {
								&.icf-upload {
									background: darken(@red-color, 10%);
								}
							}
						}
						&:active {
							span {
								&.icf-upload {
									box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) inset;
									line-height: 64px;
								}
							}
						}
					}
					.form-control-static {
						min-height: 0;
						height: 0;
						overflow: hidden;
						padding: 0;
					}
				}
				.help-block {
					margin: 0;
				}
			}
			.file-upload-submit {
				display: none;
				.input-group {
					.input-group-addon {
						height: 58px;
						padding: 0;
					}
					.type {
						border-right: 0;
						color: @red-color;
						font-size: 16px;
						padding: 6px 12px;
					}
					.file-upload-recap-contener {
						border-left: 0;
						border-right: 0;
						cursor: default;
						height: 60px;
						overflow: hidden;
						padding: 0 10px;
						position: relative;
						.file-upload-recap,
						.return-file-select {
							font-size: 16px;
							height: 60px;
							transition: all 0.25s ease-in-out;
							width: 100%;
						}
						.file-upload-recap {
							display: table-cell;
							line-height: 20px;
							left: 0;
							overflow: auto;
							padding: 0;
							position: relative;
							vertical-align: middle;
							word-break: break-all;
						}
						.return-file-select {
							font-weight: bold;
							left: 10px;
							line-height: 60px;
							opacity: 0;
							overflow: hidden;
							position: absolute;
							text-align: center;
							top: 0;
							bottom: 0;
							white-space: nowrap;
						}
						&:hover {
							cursor: pointer;
							.file-upload-recap,
							.return-file-select {
								transition: all 0.25s 0.3s ease-in-out;
							}
							.file-upload-recap {
								left: -40px;
								opacity: 0;
							}
							.return-file-select {
								opacity: 1;
								left: 0;
							}
						}
					}
					.btn {
						background: @theme-color;
						border-left: 0;
						color: white;
						font-size: 16px;
						height: 58px;
						margin: 0;
						padding: 0 20px;
					}
				}
			}
		}
		& + h2 {
			margin: 20px 0 20px;
		}
	}
	.face {
		h2 {
			background: @gray-lighter;
			padding: 5px 10px;
		}
	}
}
