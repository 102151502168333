
.geo-switch-pop {
	&.x-overlay {
		background: @geo-switch-body-bg;
		height: 100%;
		.height-100vh();
		max-height: 100%;
		.max-height-100vh();
		position: fixed;
		z-index: 80001;
		
		.x-body {
			background: @geo-switch-body-bg;
			font-size: 13px;
			overflow: auto;
			margin: 0 auto;
			padding: 20px;
		}
	}
	.filter {
		background: #fff;
		border-radius: 4px;
		display: block;
		margin: 0 auto 20px;
		max-width: 800px;
		overflow: hidden;
		width: 96%;
		label, input, button {
			background: transparent;
			border: 0;
			float: left;
			font-size: 16px;
			height: 40px;
			line-height: 40px;
		}
		input, button {
			&:active, &:focus {
				outline: none;
			}
		}
		label {
			margin: 0;
			width: calc(~"100% - 40px");
			.icon-f {
				color: #ddd;
				float: left;
				height: 40px;
				line-height: 40px;
				text-align: center;
				width: 40px;
			}
			input {
				color: #333;
				font-weight: normal;
				line-height: 28px;
				padding: 6px 0;
				width: calc(~"100% - 40px");
			}
		}
		button {
			color: #aaa;
			font-size: 13px;
			width: 40px;
			&:hover {
				color: #666;
			}
		}
	}
	.see-all {
		text-align: center;
		button {
			&:active, &:focus {
				outline: none;
			}
		}
	}
	.no-result {
		font-size: 16px;
		font-style: italic;
		opacity: 0.7;
		text-align: center;
	}
	ul {
		list-style-type: none;
		padding: 0;
		&.single-not-full-line {
			text-align: center;
			li {
				display: inline-block;
				float: none;
			}
		}
		li {
			min-height: 34px;
			padding: 2px 0;
			&.current {
				.btn {
					background: @geo-switch-active-bg;
				}
			}
			.btn {
				border: 0;
				font-weight: bold;
				line-height: 18px;
				margin: 0;
				max-width: 100%;
				overflow: hidden;
				padding: 6px 20px;
				text-align: left;
				text-decoration: none;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:hover {
					background: @geo-switch-hover-bg;
					text-decoration: none;
				}
				.flag-small {
					margin-right: 5px;
				}
			}
		}
	}
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
	.col-md-1-5 {
		width: 12.5%;
	}
}

@media (max-width: @screen-xs-max) {
	.geo-switch-pop {
		ul {
			li {
				.btn {
					padding: 11px 10px;
					font-size: 15px;
				}
			}
		}
	}
}