@import "../../../common/global_feed/global_feed";


#page.global_feed {
	.sub-suggests {
		.thumb-block {
			&.thumb-block-premium-feed-account {
				.thumb-under {
					.any-col-config({
						padding-top: 0;
					})
				}
				&.thumb-block-premium-feed-other-suggest {
					.thumb-inside {
						padding-bottom: calc(~"56.25% + 64px");
					}
				}
			}
		}
	}
}

#page.global_feed .sub-suggests .thumb-block-premium-feed-account .thumb-under,
.suggest-account-preview-pop .account-preview-header {
	.account-actions {
		.btn {
			&.btn-ticket {
				background-color: @red-color;
				border: 0;
				border-radius: 0;
				line-height: 30px;
				margin-left: 8px;
				margin-right: 8px;
				overflow: visible;
				padding: 0;
				width: calc(~"100% - 16px");
				
				.label {
					color: #fff;
					position: relative;
					z-index: 3;
				}
				
				.text {
					display: inline;
				}
				
				.icf-ticket-left-side,
				.icf-ticket-right-side {
					float: left;
					height: 30px;
					overflow: hidden;
					position: relative;
					width: 8px;
					
					.icf-ticket-wide {
						color: @red-color;
						font-size: 32px;
						line-height: 32px;
						margin-top: -1px;
						opacity: 1;
						padding-right: 0;
					}
				}
				
				&:hover {
					background-color: darken(@red-color, 5%);
					
					.icf-ticket-left-side,
					.icf-ticket-right-side {
						.icf-ticket-wide {
							color: darken(@red-color, 5%);
						}
					}
				}
				
				.icf-ticket-left-side {
					margin-left: -8px;
					
					&, .icf-ticket-wide {
						float: left;
					}
				}
				
				.icf-ticket-right-side {
					margin-right: -8px;
					
					&, .icf-ticket-wide {
						float: right;
					}
				}
				
				&.loading {
					.icf-ticket-left-side {
						.icf-ticket-wide {
							color: @premium-feed-account-sub-load-bg;
						}
					}
				}
			}
			
			&.reject {
				position: relative;
				
				.ticket-rip {
					//background-color: darkred;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					
					&:after,
					&:before {
						background-color: @premium-feed-account-reject-load-bg;
						bottom: 0;
						clip-path: polygon(0 0, 95% 0, 100% 12.5%, 95% 25%, 100% 37.5%, 95% 50%, 100% 62.5%, 95% 75%, 100% 87.5%, 95% 100%, 0 100%);
						content: "";
						left: 0;
						opacity: 0;
						position: absolute;
						top: 0;
						right: 48.75%;
					}
					
					&:before {
						clip-path: polygon(100% 0, 0 0, 5% 12.5%, 0 25%, 5% 37.5%, 0 50%, 5% 62.5%, 0 75%, 5% 87.5%, 0 100%, 100% 100%);
						left: 48.75%;
						right: 0;
					}
				}
				
				&.loading {
					background-color: rgba(0, 0, 0, 0);
					border-color: rgba(0, 0, 0, 0);
					transition: all 0s 2s;
					
					&:after {
						opacity: 0;
						transition: width 3s ease, opacity 0.2s 2s
					}
					
					.ticket-rip {
						&:after {
							animation: ticket-rip-left .9s 2s ease-in-out forwards;
							transform-origin: 100% 50%;
						}
						
						&:before {
							animation: ticket-rip-right 1s 2s ease-in-out forwards;
							transform-origin: 0 50%;
						}
					}
				}
				
				@keyframes ticket-rip-left {
					0% {
						opacity: 0;
					}
					5% {
						opacity: 1;
						transform: rotate(0) translateX(0);
					}
					10% {
						transform: rotate(3deg) translateX(-2%);
					}
					15% {
						transform: rotate(-3deg) translateX(2%);
					}
					20% {
						transform: rotate(3deg) translateX(-2%);
					}
					25% {
						transform: rotate(-3deg) translateX(2%);
					}
					30%, 40% {
						opacity: 1;
						transform: rotate(0) translateX(0);
					}
					100% {
						opacity: 0;
						transform: rotate(0) translateX(-30%);
					}
				}
				
				@keyframes ticket-rip-right {
					0% {
						opacity: 0;
					}
					5% {
						opacity: 1;
						transform: rotate(0) translateX(0);
					}
					10% {
						transform: rotate(3deg) translateX(-2%);
					}
					15% {
						transform: rotate(-3deg) translateX(2%);
					}
					20% {
						transform: rotate(3deg) translateX(-2%);
					}
					25% {
						transform: rotate(-3deg) translateX(2%);
					}
					30%, 40% {
						opacity: 1;
						transform: translateX(0);
					}
					100% {
						transform: translateX(30%);
						opacity: 0;
					}
				}
			}
			
			&.sub {
				.icf-crown {
					color: #fff;
				}
			}
		}
	}
}

.suggest-account-preview-pop {
	.account-preview-header {
		.account-actions {
			.btn {
				&.btn-ticket {
					width: calc(~"49% - 16px");
				}
			}
		}
	}
}