[dir="rtl"] {
	body {
		direction: ltr;
		> * {
			direction: rtl;
		}
	}
	.form-group {
		@media (min-width: @screen-lg-min) {
			.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
				&.control-label {
					float: right;
				}
			}
		}
		@media (min-width: @screen-md-min) {
			.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
				&.control-label {
					float: right;
				}
			}
		}
		
		@media (min-width: @screen-sm-min) {
			.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
				&.control-label {
					float: right;
				}
			}
		}
		
		.col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
			&.control-label {
				float: right;
			}
		}
	}
	
	.radio,
	.checkbox {
		label {
			padding-left: 0;
			padding-right: 20px;
		}
	}
	
	.radio input[type="radio"],
	.radio-inline input[type="radio"],
	.checkbox input[type="checkbox"],
	.checkbox-inline input[type="checkbox"] {
		margin-left: 0;
		margin-right: -20px;
	}
	
	.list-unstyled {
		padding-right: 0;
	}
	
	#page {
		&.action-video_upload,
		&.action-video_edit,
		&.action-verify_profile {
			#main {
				.form-horizontal {
					.instructions {
						&.warning {
							.title {
								.icon-f {
									margin-left: 6px;
									margin-right: 0;
								}
							}
						}
					}
				}
				
				.file-upload-inputs {
					.file-upload-text {
						input,
						select {
							border-left: 0;
							border-right: 1px solid @input-group-addon-border-color;
						}
						
						button {
							border-bottom-left-radius: @btn-border-radius-base;
							border-bottom-right-radius: 0;
							border-top-left-radius: @btn-border-radius-base;
							border-top-right-radius: 0;
							margin: 0 0 4px 2px;
						}
					}
				}
			}
		}
	}
	
	.input-group .form-control:first-child,
	.input-group-addon:first-child,
	.input-group-btn:first-child > .btn,
	.input-group-btn:first-child > .btn-group > .btn,
	.input-group-btn:first-child > .dropdown-toggle,
	.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
	.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
		.border-right-radius(@input-border-radius);
		.border-left-radius(0);
		
		&.input-sm {
			.border-right-radius(@input-border-radius-small);
		}
		
		&.input-lg {
			.border-right-radius(@input-border-radius-large);
		}
	}
	
	.input-group-addon:first-child {
		border-right: 0;
	}
	
	.input-group-btn:first-child > .btn,
	.input-group-btn:first-child > .btn-group {
		margin-left: -1px;
		margin-right: 0;
	}
	
	.input-group .form-control:last-child,
	.input-group-addon:last-child,
	.input-group-btn:last-child > .btn,
	.input-group-btn:last-child > .btn-group > .btn,
	.input-group-btn:last-child > .dropdown-toggle,
	.input-group-btn:first-child > .btn:not(:first-child),
	.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
		.border-left-radius(@input-border-radius);
		.border-right-radius(0);
		
		&.input-sm {
			.border-left-radius(@input-border-radius-small);
		}
		
		&.input-lg {
			.border-left-radius(@input-border-radius-large);
		}
	}
	
	.input-group-addon:last-child {
		border-left: 0;
	}
	
	.input-group-addon:first-child {
		border-left: 0;
		border-right: 1px solid @input-group-addon-border-color;
	}
	
	.input-group-addon:last-child {
		border-left: 1px solid @input-group-addon-border-color;
		border-right: 0;
	}
	
	.account-page {
		.tag-adder {
			.tag-adder-legend {
				float: right
			}
		}
	}
	
	.main-cat-switcher-popup.x-popup {
		.x-popup-content {
			ul {
				li {
					.btn {
						text-align: right;
						
						& > span {
							float: right;
						}
					}
					&.alert-toggle {
						.btn {
							.icon-f {
								margin-left: 4px;
								margin-right: 0;
							}
							.text {
								text-align: right;
							}
						}
					}
				}
			}
		}
	}
	#update-email-prefs_prefs_marketing {
		direction: rtl;
	}
}