@pay-icons-sprite-20: '../../../v3/img/skins/common/purchase_icons/pay_icons_sprite.20.png';
@pay-icons-sprite-64: '../../../v3/img/skins/common/purchase_icons/pay_icons_sprite.64.png';

span.pay-icon-20 {
	display: inline-block;

	.bg-YP {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -10px;
	}
	
	&.pi-XU {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -10px;
	}
	
	&.pi-WU {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -50px;
	}
	
	&.pi-WP {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -50px;
	}
	
	&.pi-WA {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -10px;
	}
	
	&.pi-VI {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -50px;
	}
	
	&.pi-VD {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -90px;
	}
	
	&.pi-US {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -90px;
	}
	
	&.pi-UL {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -90px;
	}
	
	&.pi-UI {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -10px;
	}
	
	&.pi-TR {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -50px;
	}
	
	&.pi-TO {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -90px;
	}
	
	&.pi-TNG {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -130px;
	}
	
	&.pi-TM {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -130px;
	}
	
	&.pi-TC {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -130px;
	}
	
	&.pi-SZ {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -130px;
	}
	
	&.pi-SX {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -10px;
	}
	
	&.pi-SW {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -50px;
	}
	
	&.pi-ST {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -90px;
	}
	
	&.pi-SS {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -130px;
	}
	
	&.pi-SR {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -170px;
	}
	
	&.pi-SQ {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -170px;
	}
	
	&.pi-SPAY {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -170px;
	}
	
	&.pi-SO {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -170px;
	}
	
	&.pi-SM {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -170px;
	}
	
	&.pi-SL {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -10px;
	}
	
	&.pi-SF {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -50px;
	}
	
	&.pi-SE {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -90px;
	}
	
	&.pi-SC {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -130px;
	}
	
	&.pi-SB {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -170px;
	}
	
	&.pi-RPD {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -210px;
	}
	
	&.pi-RO {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -210px;
	}
	
	&.pi-RD {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -210px;
	}
	
	&.pi-QRS {
		width: 20px; height: 7px;
		background: url(@pay-icons-sprite-20) -89px -370px;
	}
	
	&.pi-PX {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -210px;
	}
	
	&.pi-PPL {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -210px;
	}
	
	&.pi-PPAY {
		width: 19px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -370px;
	}
	
	&.pi-PP {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -210px;
	}
	
	&.pi-PFB {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -10px;
	}
	
	&.pi-PD {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -50px;
	}
	
	&.pi-PC {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -90px;
	}
	
	&.pi-OX {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -130px;
	}
	
	&.pi-MV {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -170px;
	}
	
	&.pi-ME {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -210px;
	}
	
	&.pi-MD {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -250px;
	}
	
	&.pi-MC {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -250px;
	}
	
	&.pi-LL {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -250px;
	}
	
	&.pi-LE {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -250px;
	}
	
	&.pi-KV {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -250px;
	}
	
	&.pi-KE {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -250px;
	}
	
	&.pi-JT {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -250px;
	}
	
	&.pi-JP {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -10px;
	}
	
	&.pi-JI {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -50px;
	}
	
	&.pi-JA {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -90px;
	}
	
	&.pi-IX {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -130px;
	}
	
	&.pi-IB {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -170px;
	}
	
	&.pi-IA {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -210px;
	}
	
	&.pi-I {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -250px;
	}
	
	&.pi-HC {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -290px;
	}
	
	&.pi-GRB {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -290px;
	}
	
	&.pi-GO {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -290px;
	}
	
	&.pi-GB {
		width: 20px; height: 15px;
		background: url(@pay-icons-sprite-20) -49px -370px;
	}
	
	&.pi-FPX {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -290px;
	}
	
	&.pi-FL {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -290px;
	}
	
	&.pi-EY {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -290px;
	}
	
	&.pi-EX {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -290px;
	}
	
	&.pi-EN {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -290px;
	}
	
	&.pi-EF {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -10px;
	}
	
	&.pi-EA {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -50px;
	}
	
	&.pi-DX {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -90px;
	}
	
	&.pi-CU {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -130px;
	}
	
	&.pi-CRY {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -170px;
	}
	
	&.pi-CR {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -210px;
	}
	
	&.pi-COD {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -250px;
	}
	
	&.pi-CI {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -290px;
	}
	
	&.pi-CA {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -10px -330px;
	}
	
	&.pi-BZ {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -50px -330px;
	}
	
	&.pi-BX {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -90px -330px;
	}
	
	&.pi-BW {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -130px -330px;
	}
	
	&.pi-BVL {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -170px -330px;
	}
	
	&.pi-BV {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -210px -330px;
	}
	
	&.pi-BST {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -250px -330px;
	}
	
	&.pi-BQL {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -290px -330px;
	}
	
	&.pi-BP {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -330px -330px;
	}
	
	&.pi-BL {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -10px;
	}
	
	&.pi-BE {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -50px;
	}
	
	&.pi-BC {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -90px;
	}
	
	&.pi-BB {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -130px;
	}
	
	&.pi-B {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -170px;
	}
	
	&.pi-AZ {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -210px;
	}
	
	&.pi-AW {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -250px;
	}
	
	&.pi-APP {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -290px;
	}
	
	&.pi-AE {
		width: 20px; height: 20px;
		background: url(@pay-icons-sprite-20) -370px -330px;
	}

}

span.pay-icon-64 {
	display: inline-block;
	.bg-YP {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -10px;
	}
	
	&.pi-XU {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -10px;
	}
	
	&.pi-WU {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -94px;
	}
	
	&.pi-WP {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -94px;
	}
	
	&.pi-WA {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -10px;
	}
	
	&.pi-VI {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -94px;
	}
	
	&.pi-VD {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -178px;
	}
	
	&.pi-US {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -178px;
	}
	
	&.pi-UL {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -178px;
	}
	
	&.pi-UI {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -10px;
	}
	
	&.pi-TR {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -94px;
	}
	
	&.pi-TO {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -178px;
	}
	
	&.pi-TNG {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -262px;
	}
	
	&.pi-TM {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -262px;
	}
	
	&.pi-TC {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -262px;
	}
	
	&.pi-SZ {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -262px;
	}
	
	&.pi-SX {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -10px;
	}
	
	&.pi-SW {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -94px;
	}
	
	&.pi-ST {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -178px;
	}
	
	&.pi-SS {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -262px;
	}
	
	&.pi-SR {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -346px;
	}
	
	&.pi-SQ {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -346px;
	}
	
	&.pi-SPAY {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -346px;
	}
	
	&.pi-SO {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -346px;
	}
	
	&.pi-SM {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -346px;
	}
	
	&.pi-SL {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -10px;
	}
	
	&.pi-SF {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -94px;
	}
	
	&.pi-SE {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -178px;
	}
	
	&.pi-SC {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -262px;
	}
	
	&.pi-SB {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -346px;
	}
	
	&.pi-RPD {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -430px;
	}
	
	&.pi-RO {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -430px;
	}
	
	&.pi-RD {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -430px;
	}
	
	&.pi-QRS {
		width: 64px; height: 23px;
		background: url(@pay-icons-sprite-64) -175px -766px;
	}
	
	&.pi-PX {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -430px;
	}
	
	&.pi-PPL {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -430px;
	}
	
	&.pi-PPAY {
		width: 61px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -766px;
	}
	
	&.pi-PP {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -430px;
	}
	
	&.pi-PFB {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -10px;
	}
	
	&.pi-PD {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -94px;
	}
	
	&.pi-PC {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -178px;
	}
	
	&.pi-OX {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -262px;
	}
	
	&.pi-MV {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -346px;
	}
	
	&.pi-ME {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -430px;
	}
	
	&.pi-MD {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -514px;
	}
	
	&.pi-MC {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -514px;
	}
	
	&.pi-LL {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -514px;
	}
	
	&.pi-LE {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -514px;
	}
	
	&.pi-KV {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -514px;
	}
	
	&.pi-KE {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -514px;
	}
	
	&.pi-JT {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -514px;
	}
	
	&.pi-JP {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -10px;
	}
	
	&.pi-JI {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -94px;
	}
	
	&.pi-JA {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -178px;
	}
	
	&.pi-IX {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -262px;
	}
	
	&.pi-IB {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -346px;
	}
	
	&.pi-IA {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -430px;
	}
	
	&.pi-I {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -514px;
	}
	
	&.pi-HC {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -598px;
	}
	
	&.pi-GRB {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -598px;
	}
	
	&.pi-GO {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -598px;
	}
	
	&.pi-GB {
		width: 64px; height: 49px;
		background: url(@pay-icons-sprite-64) -91px -766px;
	}
	
	&.pi-FPX {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -598px;
	}
	
	&.pi-FL {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -598px;
	}
	
	&.pi-EY {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -598px;
	}
	
	&.pi-EX {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -598px;
	}
	
	&.pi-EN {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -598px;
	}
	
	&.pi-EF {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -10px;
	}
	
	&.pi-EA {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -94px;
	}
	
	&.pi-DX {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -178px;
	}
	
	&.pi-CU {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -262px;
	}
	
	&.pi-CRY {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -346px;
	}
	
	&.pi-CR {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -430px;
	}
	
	&.pi-COD {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -514px;
	}
	
	&.pi-CI {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -598px;
	}
	
	&.pi-CA {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -10px -682px;
	}
	
	&.pi-BZ {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -94px -682px;
	}
	
	&.pi-BX {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -178px -682px;
	}
	
	&.pi-BW {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -262px -682px;
	}
	
	&.pi-BVL {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -346px -682px;
	}
	
	&.pi-BV {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -430px -682px;
	}
	
	&.pi-BST {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -514px -682px;
	}
	
	&.pi-BQL {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -598px -682px;
	}
	
	&.pi-BP {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -682px -682px;
	}
	
	&.pi-BL {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -10px;
	}
	
	&.pi-BE {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -94px;
	}
	
	&.pi-BC {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -178px;
	}
	
	&.pi-BB {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -262px;
	}
	
	&.pi-B {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -346px;
	}
	
	&.pi-AZ {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -430px;
	}
	
	&.pi-AW {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -514px;
	}
	
	&.pi-APP {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -598px;
	}
	
	&.pi-AE {
		width: 64px; height: 64px;
		background: url(@pay-icons-sprite-64) -766px -682px;
	}

}