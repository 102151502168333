@import './modules/dropdown.less';

.btn-custom(@color, @bg, @bg_hover, @border_hover, @bg_active, @border_active, @shadow) {
	&,
	&:hover,
	&:focus {
		color: @color;
	}
	&:hover {
		background-color: @bg_hover;
		border-color: @border_hover;
	}
	&:focus,
	&.focus {
		box-shadow: 0 0 0 3px @shadow;
	}
	&,
	&.disabled,
	&:disabled {
		background-color: @bg;
		border-color: @bg;
	}
	&:not([disabled]):not(.disabled):active,
	&:not([disabled]):not(.disabled).active,
	.show > &.dropdown-toggle {
		background-color: @bg_active;
		border-color: @border_active;
		box-shadow: 0 0 0 0 @shadow;
		color: @color;
	}
}

.btn-outline(@color, @border, @shadow) {
	background-color: transparent;
	background-image: none;
	border-color: @border;
	&,
	&:hover,
	&:focus {
		color: @color;
	}
	&:hover {
		background-color: @border;
		border-color: @border;
	}
	&:focus,
	&.focus {
		box-shadow: 0 0 0 3px @shadow;
	}
	&.disabled,
	&:disabled {
		color: @border;
		background-color: transparent;
	}
	&:not([disabled]):not(.disabled):active,
	&:not([disabled]):not(.disabled).active,
	.show > &.dropdown-toggle {
		color: @color;
		background-color: @border;
		border-color: @border;
		box-shadow: 0 0 0 0 @shadow;
	}
}

.bg_img_folder(@file) {
	background-image: url("../../../../v-00000000001/v3/img/skins/@{file}");
}

.bg_img_common_folder(@file) {
	.bg_img_folder("common/premium/@{file}");
}

@tour_bg_main_cats: gay, shemale, straight;
@tour_bg_main_cat_default: straight;
@tour_bg_ethnics: arabic, asian, black, indian, latin, white;
@tour_bg_ethnic_default: white;
@tour_bg_sections: 1, 2, 3, 4;


body#red-page {
	background-color: #1b1b1b;
	color: #717171;
	//font-family: "Hind", sans-serif;
	font-family: "Montserrat", sans-serif;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	overflow-x: hidden;
	padding: 0;
	position: relative;
	
	h1, h2, h3,
	.h1, .h2, .h3 {
		color: #fff;
		font-family: "Montserrat", sans-serif;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 1.5rem;
	}
	
	p {
		margin-top: 0;
		margin-bottom: 1rem;
	}
	
	a {
		background-color: transparent;
		color: #fff;
		transition: color 0.2s linear, background 0.1s linear;
		text-decoration: none;
		text-decoration-skip: objects;
		
		&:hover {
			color: @premium-highlight-color;
			text-decoration: none;
		}
	}
	
	img {
		max-width: 100%;
		height: auto;
		
		.img-blured & {
			filter: blur(0px);
		}
	}
	
	.nav-btn {
		.icon-f {
			display: none;
		}
	}
	
	.button {
		border: 2px solid transparent;
		border-radius: 1.5rem;
		display: inline-block;
		font-family: "Montserrat", sans-serif;
		font-size: 0.75rem;
		font-weight: bold;
		line-height: 1.25;
		padding: 0.8rem 2rem;
		text-align: center;
		transition: all 0.15s ease-in-out;
		user-select: none;
		vertical-align: middle;
		white-space: normal;
		
		&,
		&:hover,
		&:focus {
			color: #fff;
			text-decoration: none;
		}
		
		&:focus,
		&.focus {
			outline: 0;
			box-shadow: none;
		}
		
		&.disabled,
		&:disabled {
			opacity: .65;
		}
		
		&:active,
		&.active {
			background-image: none;
		}
	}
	
	.btn-primary {
		.btn-custom(#fff, #c72e26, #a72720, #9c241e, #9c241e, #91221c, rgba(199, 46, 38, 0.5));
	}
	
	.btn-danger {
		.button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
	}
	
	.btn-outline-secondary {
		.btn-outline(#fff, #404040, rgba(64, 64, 64, 0.5));
	}
	
	.btn-outline-success {
		.btn-outline(#fff, #00c851, rgba(0, 200, 81, 0.5));
	}
	
	.btn-link {
		font-weight: normal;
		border-radius: 0;
		
		&,
		&:hover,
		&:focus {
			color: #fff;
		}
		
		&,
		&:active,
		&.active,
		&:disabled {
			background-color: transparent;
		}
		
		&,
		&:focus,
		&:active {
			border-color: transparent;
			box-shadow: none;
		}
		
		&:hover {
			border-color: transparent;
		}
		
		&:hover,
		&:focus {
			color: @premium-highlight-color;
			text-decoration: none;
			background-color: transparent;
		}
		
		&:disabled {
			color: #6c757d;
			
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
	
	#header {
		background-color: rgba(27, 27, 27, 0.55);
		height: 80px;
		left: 0;
		right: 0;
		position: fixed;
		top: 0;
		transition: background-color 0.2s ease, top 0.8s ease, opacity 0.8s ease;
		z-index: 200;
		
		&.pinned {
			background-color: rgba(20, 20, 20, 0.95);
		}
		
		&.scrolled {
			top: -100px;
			opacity: 0;
		}
		
		#header-logo {
			float: left;
			
			a {
				display: block;
				padding-left: 5px;
			}
		}
		
		#header-nav {
			float: right;
			margin-top: 17px;
			top: 50%;
			
			a {
				float: left;
				
				&.nav-btn {
					&.btn-primary {
						margin-right: 10px;
					}
				}
			}
			
			#main-signin-btn, #main-signout-btn {
				margin-left: 16px;
				padding-left: 14px;
				padding-right: 14px;
				width: auto;
			}
			
			#main-signup-btn {
				width: auto;
				.mobile-only-show-inline-block {
					display: none
				}
				
				.btn__label {
					text-transform: uppercase;
				}
			}
			
			#main-cat-switcher {
				height: auto;
				margin-bottom: 0;
				margin-top: 0;
				padding-left: 14px;
				padding-right: 14px;
				
				.icon-f {
					display: inline;
					line-height: 0;
					vertical-align: sub;
				}
				
				.icf-caret-down {
					vertical-align: middle;
				}
				
				.icf-woman {
					font-size: 20px;
				}
				
				.icf-sexe-trans {
					font-size: 16px;
					margin-left: 2px;
				}
			}
			
			#language-switcher {
				display: block;
				height: 32px;
				width: 32px;
				margin: 7px 20px 0 16px;
			}
			
			#language-switcher-list {
				top: 57px;
				
				&:before {
					right: 31px;
				}
			}
		}
	}
	
	#red-page {
		position: relative;
		z-index: 90;
	}
	
	.main-slider__item--img-1,
	.main-slider__item--img-2,
	.main-slider__item--img-3,
	.main-slider__item--img-4 {
		.slider-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			z-index: 0;
		}
	}
	
	.img-blured & {
		.main-slider__item--img-1,
		.main-slider__item--img-2,
		.main-slider__item--img-3,
		.main-slider__item--img-4 {
			.slider-bg {
				filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='30');
				-webkit-filter:blur(20px);
				-moz-filter:blur(20px);
				-ms-filter:blur(20px);
				-o-filter:blur(20px);
				filter:url('#svgBlur');
				filter:blur(20px);
			}
		}
	}
	
	.main-slider__item--img-1 {
		position: relative;
		.slider-bg {
			background-position: 50% 50%;
		}
		
		&:before,
		&:after {
			bottom: 0;
			content: "";
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			z-index: 1;
		}
		
		&:before {
			top: 0;
			background-color: rgba(0, 0, 0, 0.4);
		}
		
		&:after {
			height: 50%;
			background-image: linear-gradient(to bottom, transparent, #1b1b1b);
		}
		
		.main-slider__layer {
			//min-height: 100vh;
			.min-height-100vh();
			padding: 94px 0 0;
			position: relative;
			z-index: 2;
			
			.main-slider-cn {
				text-align: center;
			}
			
			h1 {
				color: #fff;
				font-weight: 100;
				font-size: 120px;
				margin: .5rem 0 .1em;
				letter-spacing: 0;
				line-height: 1.2;
				
				span {
					display: block;
					font-size: 30px;
					margin: .5rem 0 0;
					
					.slide-bg-logo {
						max-width: 96%;
					}
				}
			}
			
			.button {
				margin-top: 20px;
				font-size: 20px;
			}
			
			.inter-wrap {
				background-color: #1b1b1b;
				background-color: rgba(11, 11, 11, 0.45);
				bottom: 0;
				margin-top: 16px;
				padding: 1rem;
				position: absolute;
				width: 100%;
				
				.inter {
					text-align: center;
					
					img {
						display: block;
						margin: 0 auto 20px;
						max-width: 100px;
					}
					
					h2 {
						font-size: 16px;
						margin-bottom: 5px;
						text-transform: uppercase;
					}
					
					p {
						color: #a6a6a6;
						line-height: 1.2;
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
	
	#main {
		margin: 0;
	}
	
	.main-slider__item--img-2 {
		.slider-bg {
			background-position: 100% 50%;
		}
	}
	
	.main-slider__item--img-3 {
		.slider-bg {
			background-position: 100% 50%;
		}
	}
	
	.main-slider__item--img-4 {
		.slider-bg {
			background-position: 100% 50%;
		}
	}
	
	a.learn-more {
		.bg_img_common_folder("down.png");
		background-position: center 26px;
		background-repeat: no-repeat;
		background-size: auto 27px;
		color: #fff;
		display: inline-block;
		font-size: 16px;
		line-height: 26px;
		margin: 1em;
		padding: 0 0 30px;
		text-decoration: none;
		width: 220px;
		
		&:hover {
			color: @premium-highlight-color;
			background-position: center 28px;
		}
	}

	.bloc-main-infos-row {
		display: grid;
		grid-template-columns: repeat(5, minmax(0, 1fr));
		justify-content: space-around;
		.bloc-main-infos-column {
			display: flex;
			flex-direction: column;
			text-align: center;
			padding-right: 15px;
			padding-left: 15px;
			width: 100%;

			img {
				display: block;
				margin: 0 auto 20px;
				max-width: 100px;
			}
			h2 {
				font-size: 16px;
				margin-bottom: 5px;
				text-transform: uppercase;
			}
			p {
				color: #a6a6a6;
				line-height: 1.2;
				margin-bottom: 1rem;
			}
		}
	}
	
	.section-content--gradient-overlay-left-black,
	.section-content--gradient-overlay-right {
		position: relative;
		
		&:before {
			background-image: linear-gradient(
				to left,
				transparent 0%,
				rgba(0, 0, 0, 0.28) 16%,
				rgba(0, 0, 0, 0.53) 25%,
				rgba(0, 0, 0, 0.73) 35%,
				rgba(0, 0, 0, 0.85) 45%,
				rgba(0, 0, 0, 0.96) 78%,
				#000 80%
			);
			bottom: 0;
			content: "";
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		
		& > * {
			position: relative;
			z-index: 2;
		}
	}
	
	.section-content--gradient-overlay-right {
		&:before {
			background-image: linear-gradient(
				to right,
				transparent 0%,
				rgba(0, 0, 0, 0.28) 5%,
				rgba(0, 0, 0, 0.73) 60%
			);
		}
	}
	
	#bloc-no-ads,
	#bloc-xv-originals,
	#bloc-hd-dl {
		padding: 12rem 0;
	}
	
	.noads-section,
	.originals-section,
	.hd-section {
		.row {
			display: block;
			display: flex;
			margin: 0 auto;
			max-width: 1320px;
			padding: 0;
			
			.col-xs-6 {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;
				width: auto;
			}
		}
		
		h3 {
			font-size: 80px;
			font-weight: 100;
			
			span {
				font-weight: bold;
			}
		}
		
		p {
			font-size: 25px;
			font-weight: bold;
		}
	}
	
	.noads-section,
	.originals-section,
	.hd-section,
	.section-bottom {
		a {
			font-size: 25px;
			margin-top: 2.5rem;
			
			&.learn-more {
				font-size: 16px;
				margin: 0;
				padding: 0 22px 0 0;
				background-size: auto 15px;
				width: auto;
				
				& {
					background-position: right 50%;
				}
				
				&:hover {
					background-position: right 80%;
					transition: background-position 0.15s linear;
				}
			}
		}
		
		.learn-more-div {
			margin: 2.5rem 1em 1em;
		}
	}
	
	.originals-section {
		text-align: right;
	}
	
	.section-bottom {
		background: #272727;
		padding-top: 120px;
		padding-bottom: 120px;
		
		.container {
			width: auto;
			max-width: 1580px;
		}
		
		.tb-pan {
			position: relative;
			width: 100%;
			
			h3 {
				background: #000;
				font-size: 22px;
				margin: 0;
				min-height: 100px;
				line-height: 60px;
				padding: 20px 40px;
				width: 100%;
				span {
					display: inline-block;
					line-height: 30px;
					vertical-align: middle;
				}
			}
		}
		
		.tb-pan-wall {
			overflow: hidden;
			width: 100%;
			& + p,
			& + p + p {
				font-size: 20px;
				padding: 8px 6px 0;
				margin-bottom: 0;
			}
		}
		
		.tb {
			float: left;
			padding: 1px;
			position: relative;
			width: 50%;
			
			img {
				opacity: 0.7;
				width: 100%;
			}
			
			p {
				background: rgba(0, 0, 0, 0.65);
				bottom: 0;
				color: #fff;
				font-weight: bold;
				left: 0;
				line-height: 1.2;
				margin: 0;
				padding: 8px 10px;
				position: absolute;
				width: 100%;
				
				span {
					overflow: hidden;
					display: block;
					.line-clamp(2);
				}
			}
			.video-hd-mark {
				position: absolute;
				right: 3px;
				top: 3px;
			}
			.duration {
				background: #444;
				bottom: 3px;
				top: auto;
			}
		}
		
		#between-tb-pans {
			margin-bottom: 70px;
			text-align: center;
			
			h3 {
				font-size: 28px;
				margin-bottom: 0;
			}
			img {
				max-width: 50%;
				padding: 50px 0 20px;
			}
		}
	}
	
	.start-section {
		background-color: #fff;
		color: #000;
		text-align: center;
		
		.container-fluid {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
		
		h3 {
			color: #000;
			font-size: 3rem;
			margin-bottom: 3rem;
			padding: 0 1rem;
		}
		
		a {
			font-size: 25px;
		}
	}
	
	#footer {
		font-size: inherit;
		margin: 0;
		text-align: center;
		
		.footer-widgets {
			border-top: 1px solid #222;
			position: relative;
			z-index: 2;
			padding: 48px 0;
		}
		
		.footer-copyright {
			padding: 42px 0;
			border-top: 1px solid rgba(51, 51, 51, 0.5);
			background-color: rgba(0, 0, 0, 0.1);
			position: relative;
			z-index: 2;
			
			.footer__logo {
				margin-bottom: 1rem;
			}
		}
	}
	
	.to-fadeInUp {
		opacity: 0;
	}
	
	.animated {
		animation-delay: 0s;
		animation-duration: 1s;
		animation-fill-mode: both;
	}
	
	@keyframes fadeInUp {
		from {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		
		to {
			opacity: 1;
			transform: none;
		}
	}
	
	@keyframes fadeInUp-delayed-1 {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		9% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	
	@keyframes fadeInUp-delayed-1-dur-6 {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		17% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	
	@keyframes fadeInUp-delayed-1-dur-7 {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		14% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	
	@keyframes fadeInUp-delayed-1-dur-8 {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		13% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	
	@keyframes fadeInUp-delayed-3 {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		23% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	
	@keyframes fadeInUp-delayed-5 {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		33% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	
	@keyframes fadeInUp-delayed-6 {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		38% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	
	@keyframes fadeInUp-delayed-7 {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		41% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
	
	.fadeInUp {
		opacity: 1;
		
		&.animated {
			animation-name: fadeInUp;
			
			&.fadeInUp-delay-1 {
				animation-name: fadeInUp-delayed-1;
				animation-duration: 1.1s;
				
				&.fadeInUp-dur-6 {
					animation-name: fadeInUp-delayed-1-dur-6;
					animation-duration: 0.7s;
				}
				
				&.fadeInUp-dur-7 {
					animation-name: fadeInUp-delayed-1-dur-7;
					animation-duration: 0.8s;
				}
				
				&.fadeInUp-dur-8 {
					animation-name: fadeInUp-delayed-1-dur-8;
					animation-duration: 0.9s;
				}
			}
			
			&.fadeInUp-delay-3 {
				animation-name: fadeInUp-delayed-3;
				animation-duration: 1.3s;
			}
			
			&.fadeInUp-delay-5 {
				animation-name: fadeInUp-delayed-5;
				animation-duration: 1.5s;
			}
			
			&.fadeInUp-delay-6 {
				animation-name: fadeInUp-delayed-6;
				animation-duration: 1.6s;
			}
			
			&.fadeInUp-delay-7 {
				animation-name: fadeInUp-delayed-7;
				animation-duration: 1.7s;
			}
		}
	}
}

body,
body#red-page {
	.x-overlay.x-overlay-box.social-login-info {
		backdrop-filter: blur(5px);
		font-family: Arial, sans-serif;
		
		.title {
			font-weight: bold;
		}
		
		ul {
			margin: 15px 10px 30px;
			max-width: 750px;
		}
		
		.social-login-icon {
			img {
				font-size: 56px;
				height: 1em;
				line-height: 1em;
				
				&.icon-google {
					padding: 0.1em;
					line-height: 0.8em;
				}
			}
		}
		
		.social-login-tos-accept {
			font-size: 14px;
			margin: 15px 10px 0;
			text-align: right;
			
			a {
				text-decoration: underline;
				
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.x-overlay.x-overlay-box, .account-page {
	
	#premium-popup-signin,
	#signin-popup-form {
		.hr {
			align-items: center;
			border: 0;
			display: flex;
			font-size: 13px;
			height: 1.5em;
			line-height: 1em;
			margin: 18px 0;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			
			&::before,
			&::after {
				content: "";
				height: 1px;
				width: 100%;
			}
			&::before {
				background: linear-gradient(to right, transparent, white);
			}
			&::after {
				background: linear-gradient(to right, white, transparent);
			}
			
			span {
				color: white;
				line-height: 1.5em;
				flex-shrink: 0;
				padding: 0 7px;
				position: relative;
			}
		}
		
		.social-login-block {
			display: flex;
			flex-direction: row;
			justify-content: center;
			
			.social-login-icon {
				margin-right: 25px;
				text-decoration: none;
				
				img {
					font-size: 44px;
					height: 1em;
					line-height: 1em;
					
					&.icon-google {
						padding: 0.1em;
						line-height: 0.8em;
					}
				}
				&:last-child {
					margin-right: 0;
				}
				
				&:hover {
					transform: scale(1.1);
				}
			}
		}
		.btn-link {
			& + .hr {
				margin-top: 0;
			}
		}
	}
	#signin-popup-form {
		.social-login-tos-accept {
			font-size: 14px;
		}
	}
	#premium-popup-signin {
		.social-login-tos-accept {
			font-size: 13px;
		}
	}
	&.premium-popup-form {
		background: rgba(255, 255, 255, 0);
		transition: background 0.3s;
		
		&.opened {
			background: rgba(0, 0, 0, 0.8);
			overflow: auto;
			position: fixed;
		}
		
		.tabbed-form-container {
			.tabbed-form {
				display: none;
			}
		}
		
		.x-body {
			background: none;
			font-size: 1rem;
			max-width: 650px;
			padding: 0;
			
			.x-content {
				background: #000;
				background-clip: padding-box;
				border: 5px solid rgba(255, 255, 255, 0.3);
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
				color: #fff;
				display: block;
				overflow-x: hidden;
				overflow-y: auto;
				padding: 0;
			}
			
			form {
				margin: .5em auto;
				
				input::placeholder {
					color: #666 !important;
				}
				
				input[type="text"],
				input[type="email"],
				input[type="tel"],
				input[type="password"],
				textarea,
				select {
					background-color: #fff;
					border: 1px solid transparent;
					border-radius: 0;
					box-sizing: border-box;
					color: #000;
					font-size: 17px;
					height: auto;
					line-height: 1.5;
					margin-bottom: 1.5rem;
					padding: .5rem;
					width: 100%;
					
					&:focus {
						box-shadow: none;
					}
				}
				
				&.custom-checkbox-form {
					.checkbox {
						&:not(.toggle) {
							input {
								background: #fff;
								color: #000;
								
								&::before {
									background: #fff;
									box-shadow: 0 0 1px 1px fadeOut(#202020, 50%);
								}
								
								&::after {
									color: #000;
								}
								
								&:focus {
									&::before {
										box-shadow: 0 0 1px 1px #202020;
									}
								}
								
								&:disabled {
									&::before {
										background: multiply(#fff, #666);
									}
									
									&::after {
										color: multiply(#000, #666);
									}
								}
								
								&.validator-error {
									&::before {
										box-shadow: 0 0 0 0.15em @red-color;
									}
								}
							}
						}
					}
				}
				
				select.no-value {
					color: #666;
				}
				
				.alert {
					margin-bottom: 2px;
					
					&.alert-danger a {
						color: @theme-color;
					}
				}
				
				> .alert {
					margin-bottom: 10px;
				}
				
				.form-group {
					margin-top: 0;
					margin-bottom: 0;
					
					.help-block:not(.error-block) {
						text-align: left;
						text-shadow: 0 0 2px #000;
					}
					
					label {
						display: none;
					}
					
					.checkbox {
						text-align: left;
						padding: 0;
						
						label {
							display: inline-block;
							font-size: 0.87em;
						}
					}
				}
				
				.form-field-signup-form_password {
					input[type="password"] {
						border-radius: .08rem .08rem 0 0;
					}
					
					.progress {
						margin-top: -1.5rem;
						margin-bottom: 1.5rem;
						border-radius: 0 0 .08rem .08rem;
					}
				}
				
				.form-control {
					&.visible-password {
						margin-bottom: 1.5rem;
						
						input {
							margin-bottom: 0;
							padding-right: 40px;
						}
						
						.progress {
							margin-top: 0;
							margin-bottom: -4px;
						}
					}
				}
				
				.form-field-signup-form_marketing_internal,
				.form-field-signup-form_tos_pp {
					margin-right: 5px;
					margin-left: 5px;
				}
				
				.form-field-signup-form_tos_pp + .form-field-signup-form_marketing_internal {
					margin-top: 10px;
				}
			}
			
			#premium-popup-form {
				display: block;
				margin: 0;
				outline: 0;
				padding: 0;
				pointer-events: auto;
				text-align: center;
				
				h2 {
					text-transform: uppercase;
					margin: .5em 0 0;
					font-size: 1.45rem;
					line-height: 1.5;
					text-shadow: 0 1px 1px rgba(0, 0, 0, .6);
				}
				
				// "Almost There !" header
				#premium-form-block {
					/*.card-payments-soon {
						font-size: 1.1rem;
						margin: 1.3rem 0 0.5rem;
						span {
							background: @theme-color;
							padding: 0.2rem 0.5rem;
							border-radius: 3px;
							font-weight: bold;
							display: inline-block;
						}
					}*/
					
					& > h2 {
						font-size: 1rem;
						
						// Needed to see the purchase button at all times, even if cryptos are displayed !
						@media (max-width: 427px) or (max-height: 624px) {
							display: none;
						}
					}
				}
				
				h2 span,
				h3 span {
					color: @theme-color;
				}
				
				h3 {
					max-width: 646px;
					margin: .5em auto 0;
					font-size: 1.17rem;
					line-height: 1.5;
				}
				
				p {
					margin: 1em 0;
					
					&.use-previous-card {
						margin: 2em 0 0;
						text-align: center;
						text-transform: uppercase;
						font-size: 16px;
					}
				}
				
				.form-buttons {
					margin: 0;
				}
				
				#xvideored-logo {
					max-width: 330px;
					margin: 10px auto 0;
					
					.embed-responsive {
						padding-bottom: 18.181818%;
					}
				}
				
				#premium-modes-block,
				#signin-form-block,
				#signup-form-block {
					margin-bottom: 1em;
					padding: 0 1rem;
				}
				
				#signin-form-block,
				#signup-form-block {
					button .trial {
						display: none;
					}
					
					&.prod-with-trial {
						button .notrial {
							display: none;
						}
						
						button .trial {
							display: inline;
						}
					}
				}

				#lostpassword-form-block {
					.social-login-text, .social-login-block {
						display: none;
					}
				}
				
				#signin-form-block {
					button {
						&.is-signinonly-init {
							.signin-only {
								display: none;
							}
							
							&.is-signin-only {
								font-size: 1.5rem;
								
								.not-signin-only {
									display: none;
								}
								
								.signin-only {
									display: inline;
								}
							}
						}
					}
				}
				
				#signin-form {
					&.is-signin-only {
						.premium-plans,
						.offer-title,
						.sep,
						.included {
							display: none;
						}
					}
				}
				
				#premium-form,
				#premium-form-one-click,
				#premium-form-one-click-vendo {
					button[type="submit"],
					.need-other-payment-method,
					.btn-ppv-video-buy-submit,
					.btn-channel-membership-buy-submit,
					a.btn {
						//width: 100%;
						margin-bottom: 5px;
					}
					
					a.btn {
						margin-top: 0;
					}
				}
				
				button[type="submit"],
				.need-other-payment-method,
				.btn-ppv-video-buy-submit,
				.btn-channel-membership-buy-submit,
				button.g-recaptcha,
				a.btn {
					border: none medium;
					border-radius: .3em;
					font-size: 1.4rem;
					font-weight: 700;
					line-height: 22px;
					overflow: hidden;
					margin: .5em 0;
					max-width: 100%;
					padding: .4em .7em;
					text-transform: uppercase;
					transform: scaleX(0.9); // for a better match of the HIND original font replaced with Montserrat
					white-space: normal;
					width: calc(~"100% - 40px");
				}
				
				.need-other-payment-method {
					text-transform: none;
				}
				
				a.btn {
					display: inline-block;
					text-decoration: none;
					
					&.btn-link {
						font-weight: normal;
						padding-left: 0.5em;
						padding-right: 0.5em;
						font-size: 1.2rem;
						text-decoration: underline;
						text-transform: none;
					}
					
					&.btn-sm {
						font-size: 1rem;
						padding: .5em 1em;
						line-height: 1;
						min-width: 50%;
						margin-top: .5em;
						background-color: #1da1f2;
						white-space: normal;
						
						&:hover,
						&:focus {
							background-color: #1980bf;
						}
						
						&.wi {
							position: relative;
							
							span {
								display: inline-block;
							}
							
							img {
								max-height: 1.2rem;
								vertical-align: middle;
								margin-top: -.2em;
								margin-right: .4em;
							}
						}
					}
				}
				
				#premium-modes {
					margin: 1em -5px;
					
					.col-xs-6,
					.col-xs-4,
					.col-xs-3 {
						padding-left: 5px;
						padding-right: 5px;
					}
					
					.mode {
						border: 0.2em solid #717171;
						background: @theme-color;
						cursor: pointer;
						height: 120px;
						line-height: 1.2;
						margin-bottom: 0.5em;
						padding: 0.5em;
						transition: border-color 0.15s ease;
						width: 100%;
						
						&:hover {
							border-color: #fff;
						}
						
						td {
							text-align: center;
							vertical-align: middle;
						}
						
						.label {
							background: #1da1f2;
							padding: 0;
							width: 26px;
							max-width: 26px;
							
							div {
								overflow: visible;
								width: 26px;
								height: 120px;
								position: relative;
								
								div {
									width: 120px;
									transform: rotate(270deg);
									position: absolute;
									top: 0;
									left: 2px;
								}
							}
						}
						
						.slogan {
							font-weight: bold;
						}
						
						.price,
						.slogan {
							padding: 10px;
							font-size: 1.4rem;
						}
						
						&.mode-no_ads {
							.label {
								background: #444;
							}
						}
					}
				}
				
				.alernate-options {
					margin: .5em auto 1em;
				}
				
				.login {
					color: #717171;
					display: block;
					font-size: 0.95em;
					line-height: 1;
					margin: 0.5em auto;
					padding: 0.3em 0.7em 0.3em;
					text-decoration: none;
					
					span {
						color: #fff;
					}
				}
				
				a.login:hover span {
					color: @premium-highlight-color;
				}
				
				.legal {
					font-size: .85rem;
					margin: 1em 0 0;
				}
				
				#premium-pending-spinner, #premium-pending-checking, #premium-pending-end {
					display: none;
				}
				
				#premium-summary {
					padding: 0 20px;
					display: inline-block;
					
					h2 {
						margin-bottom: 40px;
					}
					
					ul {
						margin-bottom: 50px;
					}
					
					p,
					li {
						font-size: 16px;
						text-align: left;
					}
					
					li {
						list-style: initial;
					}
					
					p.center {
						text-align: center;
					}
					
					.btn {
						white-space: normal;
					}
				}
				
				.form-field-signin-form_product {
					margin-top: 20px;
				}
				
				.choose-offer {
					font-size: 20px;
					margin: 0 0 10px;
				}
				
				.included {
					ul {
						display: inline-block;
						margin: 0;
						padding: 0;
					}
					
					li {
						display: block;
						line-height: 20px;
						margin: 0;
						padding: 0 0 0 30px;
						position: relative;
						text-align: left;
						
						&:before {
							.font-family-iconfont();
							content: @icf-caret-right;
							font-size: 14px;
							left: 5px;
							position: absolute;
							top: 3px;
						}
					}
				}
				
				.icf-ticket-red {
					color: @red-color;
					display: inline-block;
					font-size: 28px;
					margin: -4px 0;
					vertical-align: top;
				}
				
				.unlogged-txt {
					color: #717171;
					line-height: 20px;
					margin: 0 0 10px;
					
					a {
						text-decoration: none;
						white-space: nowrap;
						
						.notouch &:hover {
							color: @premium-highlight-color;
						}
					}
				}
			}
			
			#premium-popup-signin {
				display: block;
				position: relative;
				
				.tabbed-form-tabs {
					background-color: #191919;
					display: block;
					overflow: hidden;
					margin: 0;
					padding: 0;
					
					li {
						display: inline-block;
						float: left;
						margin: 0;
						padding: 0;
						
						a {
							background: #191919;
							border-right-width: 1px;
							border-right-style: solid;
							border: none;
							color: #8c8c8c;
							display: block;
							font-family: "Montserrat", sans-serif;
							font-size: .857rem;
							height: auto;
							line-height: 26px;
							padding: 15px 25px;
							text-decoration: none;
							
							&.active {
								background: #202020;
								color: #fff;
							}
							
							.icon-f {
								margin-right: 5px;
							}
						}
					}
				}
				
				.tabbed-form-container {
					padding: 1rem;
				}
			}
			
		}
		
		&.premium-popup-grey {
			.x-body {
				.x-close {
					right: 0;
					top: 0;
				}
				
				.x-content {
					background: #242424;
					border: 0;
				}
				
				form {
					input[type="text"],
					input[type="email"],
					input[type="tel"],
					input[type="password"],
					select {
						background: transparent;
						border: 0;
						border-bottom: 2px solid #404040;
						color: #fff;
						padding: .8rem 0;
						
						&:focus {
							border-bottom-color: #fff;
						}
					}
				}
			}
		}
		
	}
	
	&.premium-popup-form,
	&.purchase-form {
		.payment-types {
			display: flex;
			flex-wrap: wrap;
			@media (max-width: @screen-xs-max) {
				flex-direction: column;
			}
			justify-content: space-around;
			margin: 0;
			width: 100%;
			position: relative;
			z-index: 10;
		}
	}
}

#premium-popup-signin {
	h3.premium-expired {
		align-items: center;
		display: flex;
		font-size: 1em;
		font-weight: normal;
		margin: 0 auto 20px;
		max-width: 80%;
		text-align: center;
		
		.offer-title-framed {
			span {
				background: transparent;
				border-color: @premium-purchase-form-highlight-color;
				//color: @multiform-option-premium-color;
				margin-right: 16px;
			}
		}
	}
}

.premium-popup-form-detail-info-pop.x-popup {
	.x-popup-arrow {
		display: none;
	}
	
	.x-popup-content {
		background: #2c2c2c;
		border: 2px solid #fff;
		color: #fff;
		font-size: 16px;
	}
}

#premium-form-reasons {
	text-align: center;
	
	.r-ct {
		margin: 1em 0;
		
		.icon-f {
			margin: 0 auto;
			font-size: 5rem;
		}
		
		h3 {
			margin: .2em 0 .05em 0;
			text-shadow: 0 1px 1px rgba(0, 0, 0, .6);
			text-transform: uppercase;
			background-color: transparent;
			font-weight: 500;
			font-size: 1rem;
			line-height: 1.3;
		}
		
		p {
			margin: 0 0 1em;
			line-height: 1.3;
			font-size: 0.86rem;
		}
	}
}

.x-overlay .x-content #cancel-reason {
	width: 100%;
	color: #000;
}

.accepted-credit-cards {
	text-align: center;
	
	> img {
		display: inline-block;
		margin: 0 5px;
		padding: 2px;
		background: white;
		border-radius: 4px;
		height: 2rem;
	}
}

.terms-conditions {
	text-align: center;
	margin: -15px 0 20px;
}



#arony-pay-form {
	.form-horizontal {
		margin: 10px 0 30px;
	}
	
	
	.tos.form-group {
		.col-sm-2 {
			display: none;
		}
		
		#tos_container {
			width: 100%;
			
			.checkbox {
				margin: 0;
			}
			
			label {
				padding-left: 40px;
				position: relative;
				
				input {
					height: 18px;
					margin-left: -30px;
					margin-top: 2px;
					width: 18px;
				}
			}
		}
	}
	
	button {
		border-radius: 0.3em;
		font-size: 1.4rem;
		max-width: 100%;
		padding: .4em;
		text-transform: uppercase;
		white-space: normal;
		width: calc(~"100% - 40px");
	}
	
	@media (max-width: @screen-xs-max) {
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="password"],
		textarea,
		select {
			margin-bottom: 0;
		}
		
		.form-horizontal {
			& > .form-group {
				margin-bottom: 1rem;
			}
		}
	}
	
	@media (min-width: @screen-sm-min) {
		.form-horizontal {
			& > .form-group {
				align-items: center;
				display: flex;
				margin: 0;
				width: 100%;
				
				.col-sm-2:first-child {
					width: 25%;
					
					& + .col-sm-10 {
						width: 75%;
					}
				}
				
				& > .control-label {
					padding-top: 0;
					line-height: 1.2;
					width: 30%;
				}
				
				input[type="text"],
				input[type="email"],
				input[type="tel"],
				input[type="password"],
				textarea,
				select {
					margin-bottom: 0.6rem;
					margin-top: 0.6rem;
				}
				
				
				&:not(.card-expire) {
					.text-center {
						width: 100%;
					}
				}
			}
			.tos.form-group {
				#tos_container {
					.checkbox {
						margin-bottom: 0.6rem;
						margin-top: 0.6rem;
					}
				}
			}
		}
	}
}

.account-page.premium-iframe {
	background: #000;
	padding: 0;
	.x-overlay.x-overlay-box {
		padding: 0;
		.x-body {
			padding: 0 10px 10px;
		}
	}
	
	.goto-premium-link-cont {
		text-align: center;
		padding: 30px;
		
		.btn {
			margin: 0;
		}
	}
}

/* ==== min 768px = max 991px ==== */
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	body#red-page {
		.col-sm-6-half {
			clear: left;
		}
		
		.section-bottom {
			padding-top: 60px;
			padding-bottom: 60px;
			
			.container {
				max-width: 720px;
			}
		}
	}
}

/* ==== max 1199px ==== */
@media (max-width: @screen-md-max) {
	body#red-page {
		#header {
			#header-nav {
				margin-top: 23px;
				
				.nav-btn {
					text-align: center;
					border-radius: .2rem;
					padding: 0.4rem;
					margin-right: .3rem;
					
					&.signin {
						.icon-f {
							font-size: 16px;
						}
					}
					
					&.btn-primary {
						margin-right: 10px;
					}
					
					.icon-f {
						display: inline-block;
					}
					
					.nav-btn__label {
						display: none;
					}
				}
				
				#language-switcher {
					margin: -1px 10px 0 0;
				}
				
				#language-switcher-list {
					&:before {
						right: 21px;
					}
				}
			}
		}
		
		.section-bottom {
			.container {
				max-width: 960px;
			}
		}
	}
}

/* ==== max 870px = Intermediate small screens ==== */
@media (max-width: 870px) {
	.x-overlay.x-overlay-box {
		&.premium-popup-form {
			.x-body {
				#premium-popup-form {
					button[type="submit"],
					.need-other-payment-method,
					.btn-ppv-video-buy-submit,
					.btn-channel-membership-buy-submit,
					button.g-recaptcha,
					a.btn {
						font-size: 1.3rem
					}
					
					a.btn-link {
						font-size: 1rem
					}
					
					a.btn-sm {
						font-size: 1rem;
					}
				}
			}
		}
	}
	
	#premium-form-reasons {
		.r-ct {
			.icon-f {
				font-size: 4rem;
			}
			
			h3 {
				font-size: .85rem
			}
		}
	}
}

/* ==== max 767px = XVIDEOS ==== */
/* ==== max 991px = XNXX ==== */
@media (max-width: @screen-all-mobile) {
	body#red-page {
		#header {
			height: 50px;
			
			#header-logo {
				img {
					height: 50px;
				}
			}
			
			#header-nav {
				margin-top: 9px;
				
				.nav-btn {
					&.btn-primary {
						margin-right: .3rem;
					}
				}
				
				#language-switcher {
					padding: 10px 5px;
					width: 26px;
					margin-right: .3rem;
				}
				
				#language-switcher-list {
					top: 40px;
				}
			}
		}
		
		.main-slider__item--img-1 {
			.main-slider__layer {
				h1 {
					span {
						font-size: 20px;
					}
				}
				
				.button {
					font-size: 16px;
					margin: 0 0.5rem;
				}
				
				.inter-wrap {
					.inter {
						img {
							max-width: 80px;
						}
					}
				}
			}
		}
		
		.col-xs-6-half {
			clear: left;
		}
		
		.section-content--gradient-overlay-left-black {
			&:before {
				background-image: linear-gradient(to left, transparent 0%, #000 90%);
			}
		}
		
		.section-content--gradient-overlay-right {
			&:before {
				background-image: linear-gradient(to right, transparent 0%, #000 90%);
			}
		}
		
		#bloc-no-ads,
		#bloc-xv-originals,
		#bloc-hd-dl {
			padding: 4rem 0;
		}
		
		.noads-section,
		.originals-section,
		.hd-section {
			h3 {
				font-size: 50px;
			}
			
			p {
				font-size: 18px;
				color: #fff;
			}
			
			a {
				margin-top: 1.5rem;
				font-size: 19px;
			}
		}
		
		.section-bottom {
			.container {
				max-width: 540px;
			}
			
			padding-top: 40px;
			padding-bottom: 40px;
		}
		
		.footer-widgets {
			padding: 40px 0;
		}
	}
	
	.x-overlay.x-overlay-box {
		&.premium-popup-form {
			.x-body {
				#premium-popup-form {
					#premium-modes {
						.mode {
							.price,
							.slogan {
								font-size: 1em;
							}
						}
					}
				}
			}
		}
	}
}

/* ==== max 767px  ==== */
@media (max-width: 767px) {
	#premium-popup-form {
		h2 {
			font-size: 1rem;
			line-height: 1;
		}
		
		form {
			input[type="text"],
			input[type="email"],
			input[type="tel"],
			input[type="password"],
			select {
				font-size: 17px;
				height: 2.5rem;
				padding: 0 .5em;
			}
		}
		
		#premium-form .form-field-premium-form_cc_number input {
			background-position: right 0.5rem center;
			background-size: 154px auto;
			
			[dir=rtl] & {
				background-position: left 0.5rem center;
			}
		}
		
		#premium-modes {
			.col-xs-6,
			.col-xs-4,
			.col-xs-3 {
				width: 100%;
				float: none;
				margin-bottom: 0.5em;
			}
			
			.mode {
				height: 90px;
				
				.label {
					div {
						height: 90px;
						
						div {
							width: 90px;
						}
					}
				}
				
				.price, .slogan {
					font-size: 1.4rem;
				}
			}
		}
	}
}

/* ==== max 640px ==== */
@media (max-width: 640px) {
	.x-overlay.x-overlay-box {
		&.premium-popup-form {
			.x-body {
				#premium-popup-signin {
					.tabbed-form-tabs {
						li {
							display: block;
							float: none;
						}
					}
				}
				
				#premium-popup-form {
					.premium-popup-form-membership-header {
						&.with-included.with-main-pic {
							padding-right: 10px;
							padding-left: 10px;
							
							.title {
								padding-top: calc(~"20% + 10px");
							}
						}
					}
					
					button[type="submit"],
					.need-other-payment-method,
					.btn-ppv-video-buy-submit,
					.btn-channel-membership-buy-submit,
					button.g-recaptcha,
					a.btn {
						width: 100%;
					}
				}
			}
		}
	}
	
	#premium-form-reasons {
		.r-ct {
			margin: .5em 0 0;
			
			.icon-f {
				font-size: 2.5rem;
			}
			
			h3 {
				font-size: .80rem;
			}
		}
	}
}

/* ==== max 479px = XVIDEOS ==== */
@media (max-width: @screen-xxs-max) {
	body#red-page {
		#header {
			#header-nav {
				#main-signup-btn {
					.btn__label {
						display: none;
					}
					.mobile-only-show-inline-block {
						display: inline-block;
					}
				}
				
				#main-signin-btn {
					margin-left: 10px;
					padding-left: 8px;
					padding-right: 8px;
				}
				
				#main-cat-switcher {
					padding-left: 8px;
					padding-right: 8px;
				}
			}
		}
		
		.main-slider__item--img-1 {
			.main-slider__layer {
				h1 {
					span {
						font-size: 16px;
					}
				}
				
				.button {
					font-size: 14px;
					padding: .6rem 1.4rem;
				}
				
				.inter-wrap {
					.inter {
						img {
							max-width: 50px;
						}
					}
				}
			}
		}
		
		.noads-section,
		.originals-section,
		.hd-section {
			h3 {
				font-size: 30px;
			}
		}
		
		.start-section {
			h3 {
				font-size: 2rem;
			}
		}
	}
	
	.x-overlay.x-overlay-box {
		&.premium-popup-form {
			.x-body {
				#signin-popup-form {
					.btn-danger {
						min-width: 0;
					}
					
					#signup-form_step2_profilepic {
						.step2-profile-pic {
							margin: 0 auto 10px;
							float: none;
						}
					}
				}
				
				#premium-popup-form {
					padding: .5em;
					
					#xvideored-logo {
						max-width: 250px;
					}
					
					h3 {
						font-size: .7rem;
						margin-top: .35em;
						line-height: 1;
					}
					
					button[type="submit"],
					.need-other-payment-method,
					.btn-ppv-video-buy-submit,
					.btn-channel-membership-buy-submit,
					button.g-recaptcha,
					a.btn {
						font-size: 1rem;
						margin: .45em auto 0;
						padding: .6em;
					}
					
					a.btn-link {
						font-size: 0.8rem;
					}
					
					a.btn-sm {
						font-size: 0.9rem;
					}
					
					#premium-form {
						.form-field-premium-form_cc_number input {
							background-size: 116px auto;
						}
						
						.form-field-premium-form_cc_month.form-group,
						.form-field-premium-form_cc_year.form-group,
						.form-field-premium-form_cc_ccv.form-group {
							width: 33.33333%;
						}
					}
					
					.premium-plans {
						margin-bottom: 0.5em;
						
						.plan {
							display: block;
							
							&.with-saving {
								padding-top: 14px;
							}
						}
						
						.col-xs-12,
						.col-xs-6,
						.col-xs-4,
						.col-xs-3 {
							width: 100%;
							float: none;
							margin-bottom: 5px;
						}
					}
					
					#premium-modes {
						.mode {
							.price, .slogan {
								font-size: 0.9em;
								padding: 5px;
							}
						}
					}
					
					#xvideored-modal .t-inp .inp.date:first-child {
						float: none;
						width: 100%;
						padding-right: 0;
					}
					
					#xvideored-modal .t-inp .inp.cvv:last-child {
						float: none;
						width: 60%;
						padding-left: 0;
					}
					
					.unlogged-txt {
						margin-right: 20px;
					}
				}
				
				#premium-popup-signin {
					.tabbed-form-tabs {
						li {
							a {
								padding: 10px 25px;
							}
						}
					}
					
					.tabbed-form-container {
						padding: 0.2rem;
					}
				}
				
				
				.premium-popup-form-membership-header {
					&.with-main-pic {
						min-height: 110px;
					}
				}
			}
		}
	}
}

/* ==== max 359px ==== */
@media (max-width: 359px) {
	body#red-page {
		#header {
			#header-logo {
				img {
					padding: 5px;
				}
			}
		}
	}
}

/* ==== Purchase new style variables ==== */
@premium-form-body-bgcolor: #0e0e0e;
@premium-form-body-bordercolor: #2a2a2a;
@premium-form-content-padding: 16px;
@premium-form-available-bgcolor: #212121;
@premium-form-available-hover-bgcolor: #242424;
@premium-form-available-outline-hover: 2px solid fadeOut(@premium-purchase-form-highlight-color, 20%);
@premium-form-available-opacity: 1;
@premium-form-active-bgcolor: #4d4b4b;
@premium-form-valid-color: #319b00;
@premium-form-dimmed-bgcolor: @premium-form-available-bgcolor;
@premium-form-dimmed-opacity: .2;

@premium-form-body-radius: 30px;
@premium-form-element-radius: 9px;

@premium-form-special-color: #007cbf;
@plan-gap: 10px;


@import './form/premium_form_v/premium_v3.less';
@import './form/premium_form_v/premium_v1.less';
@import './form/premium_form_v/premium_v7.less';
//@import './form/purchase_multiform_backup_104153.less';//@todo #104153 // uncomment to go back to previous multiform config
