@message-left-color0:		@gray;
@message-right-color0:		@gray-light;
@message-left-color1:		@red-color;
@message-right-color1:		@gray-base;
@message-left-color2:		#eecccc;
@message-right-color2:		#eee;

.textcomplete-dropdown {
	z-index: @zindex-autocomplete!important;
}

#account-chat-private-container {
	margin-top: 10px;

	#account-chat-loading {
		text-align: center;
		font-weight: bold;
		font-size: 14px;
		padding: 40px;
		color: @red-color;
	}

	#chat-window {
		border-bottom: 1px solid @chat-box-title-bg;
		box-shadow: none;
		height: 600px;
		margin: auto;
		max-width: 100%!important;
		position: relative;
		right: 0;
		width: 100%;
		z-index: @zindex-chat-window-account!important;

		&.chat-hidden {
			width: 100%;
			height: 600px;
			.chat-title {
				.close {
					display: none;
				}
			}
			.row {
				display: block;
			}
			.chat-title-text {
				display:inline;
			}
		}

		.chat-title {
			cursor: auto;
			margin-left: 0;
			margin-right: 0;
			.close {
				display: none;
			}
		}

		.chat-message-form {
			width: 100%;
		}

		.row {
			top: 30px;
		}
	}

	.chat-home-tabs-text {
		@media (min-width: 1024px) {
			display: inline-block!important;
		}
	}
}

#chat-window {
	background: @gray-lighter;
	border: 1px solid @chat-box-title-bg;
	border-bottom: 0;
	bottom: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
	width: 80%;
	max-width: 450px;
	height: 100%;
	max-height: 480px;
	position: fixed;
	right: 0;
	z-index: @zindex-chat-window;

	.chat-header-messages {
		.chat-header-container {
			.chat_profile_name {
				display: flex;
				align-items: center;
				margin-right: 35px;
			}
			.chat_profile_link {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				line-height: 1;
				margin-right: 5px;
			}
			.chat_sheer_tip_btn {
				padding: 6px 11px;
				line-height: 1;
				border: none;
				border-radius: 0;
				.icf-money {
					vertical-align: middle;
				}
			}
		}
	}

	&.zoom1,
	&.zoom2 {
		width: 100%;
		right: 0;
	}

	&.zoom1 {
		font-size: 14px;
		min-width: 30%;
		max-width: 640px;
		max-height: ~"calc(80 * var(--vh, 1vh))";
		.chat_tab_container {
			&.chat_is_typing {
				.chat_group_tab_container {
					bottom:18px;
				}
			}
			.chat_typing {
				height: 18px;
				line-height: 16px;
			}
		}
		.chat-users {
			a {
				height:41px;
				&.friend_selected {
					height:44px;
				}
				&.chat_friend_typing {
					.chat_list_user_is_typing {
						margin-left: 43px;
						height: 14px;
						line-height: 14px;
						font-size: 12px;
						&.chat_list_user_is_typing_fav {
							margin-left: 60px;
						}
					}
					.chat_profile_name {
						height: 25px;
						line-height: 25px;
					}
					.chat_profile_name_favorite {
						margin-top: 5px;
					}
				}
			}
		}
		.chat_picture_status {
			width: 40px;
			height: 40px;
			background-size: 40px;
		}
		.chat_picture_filter {
			width: 40px;
			height: 40px;
			span {
				line-height:40px;
			}
		}
		.chat_status_nb_unread {
			margin-left: 33px;
		}
		.chat_status_online, .chat_status_offline {
			margin-top: 26px;
			margin-left: 34px;
			width:13px;
			height:13px;
		}
		.chat_profile_name {
			height:40px;
			line-height:40px;
			margin-left: 43px;
			font-size:16px;
		}
		.chat_profile_name_favorite {
			margin-top: 14px;
		}
		.chat_profile_favorite {
			margin-left: 43px;
		}
		.chat_profile_date {
			height:13px;
			font-size:10px;
		}
		.chat-header-messages {
			height: 41px;
			.chat-header-container {
				.chat_profile_name {
					font-size: 18px;
					height: 41px;
					line-height:41px;
				}
			}
		}
		.chat-messages {
			top: 41px;
		}
		
		#chat_friend_menu {
			top:40px;
			
			div.chat-header-menu-icon {
				height: 30px;
				.icon-f {
					width:35px;
					.chat_profile_add_favorite {
						margin-top: 3px;
					}
				}
				.chat-header-menu-text {
					font-size: 12px;
					line-height: 30px;
				}
			}
		}
		#chat_friend_menu_switch {
			font-size: 26px;
			right:5px;
		}
		
		.chat-message-form .emojionearea-editor {
			font-size: 14px;
		}
	}

	&.zoom2 {
		font-size: 16px;
		min-width: 70%;
		max-width: 800px;
		max-height: ~"calc(90 * var(--vh, 1vh))";
		.chat_tab_container {
			&.chat_is_typing {
				.chat_group_tab_container {
					bottom:20px;
				}
			}
			.chat_typing {
				height: 20px;
				line-height: 16px;
			}
		}
		.chat-users {
			a {
				height:46px;
				&.friend_selected {
					height:49px;
				}
				&.chat_friend_typing {
					.chat_list_user_is_typing {
						margin-left: 48px;
						height: 16px;
						line-height: 16px;
						font-size: 12px;
						&.chat_list_user_is_typing_fav {
							margin-left: 66px;
						}
					}
					.chat_profile_name {
						height: 28px;
						line-height: 28px;
					}
					.chat_profile_name_favorite {
						margin-top: 7px;
					}
				}
			}
		}
		.chat_picture_status {
			width: 45px;
			height: 45px;
			background-size: 45px;
		}
		.chat_picture_filter {
			width: 45px;
			height: 45px;
			span {
				line-height: 45px;
			}
			
		}
		.chat_status_nb_unread {
			margin-left: 38px;
		}
		.chat_status_online, .chat_status_offline {
			margin-top: 30px;
			margin-left: 38px;
			width:14px;
			height:14px;
		}
		.chat_profile_name {
			height:45px;
			line-height: 45px;
			margin-left: 48px;
			font-size: 17px;
		}
		.chat_profile_name_favorite {
			margin-top: 16px;
		}
		.chat_profile_favorite {
			margin-left: 48px;
		}
		.chat_profile_date {
			height:15px;
			font-size:11px;
		}
		.chat-header-messages {
			height: 46px;
			.chat-header-container {
				.chat_profile_name {
					font-size: 20px;
					height: 46px;
					line-height:46px;
				}
			}
		}
		.chat-messages {
			top: 46px;
		}
		
		#chat_friend_menu {
			top:45px;
			
			div.chat-header-menu-icon {
				height: 34px;
				.icon-f {
					width:40px;
					.chat_profile_add_favorite {
						margin-top: 5px;
					}
				}
				.chat-header-menu-text {
					font-size: 13px;
					line-height: 34px;
				}
			}
		}
		#chat_friend_menu_switch {
			font-size: 28px;
			right:8px;
		}
		
		.chat-message-form .emojionearea-editor {
			font-size: 16px;
		}
	}
	
	&.color1 {
		.chat-body {
			.chat_message_inner {
				color: @text-invert-color!important;
				a {
					color: @text-invert-color!important;
				}
			}
			
			.message_left {
				background-color: @message-left-color1!important;
				&:after {
					border-top-color: @message-left-color1!important;
				}
			}
			.message_right {
				background-color: @message-right-color1!important;
				&:after {
					border-right-color: @message-right-color1!important;
				}
			}
		}
	}
	
	&.color2 {
		.chat-body {
			.chat_message_inner {
				color: #000000!important;
				a {
					color: #000000!important;
				}
			}

			.message_left {
				background-color: @message-left-color2!important;
				&:after {
					border-top-color: @message-left-color2!important;
				}
			}
			.message_right {
				background-color: @message-right-color2!important;
				&:after {
					border-right-color: @message-right-color2!important;
				}
			}
		}
	}
	
	&.chat-totaly-hidden {
		display: none !important;
	}
	
	#chat_loading_overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 50;
		top: 30px !important;
		div {
			position: absolute;
			width: 100%;
		}
		.loading_back {
			height: 100%;
			background-color: @text-color;
			opacity: 0.25;
		}
		.loading_text {
			top: 40%;
			z-index:2;
			color: @red-color;
			text-align: center;
			font-size: 18px;
			font-weight: bold;
		}
	}
	.overlay-container {
		width: 100%;
		height: 100%;
		.x-overlay {
			top: 30px!important;
			height: auto;
			min-height: auto;
			
			display: flex;
			align-items: center;
			justify-content: center;
			
			.x-body {
				overflow: auto;

				width: auto;
				height: auto;
				background-color: #fff;
				color: #000;
				padding: 20px;

				a {
					color: #000;
				}
			}
		}
		
		.chat-overlay-photos-upload {
			height: auto!important;
			.x-body {
				width: 100%;
				height: 100%;
				padding: 20px;
				background: none;
			}
			.x-content {
				height: 100%;
				width: 100%;
				div {
					height: 100%;
					width: 100%;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						max-width:100%;
						max-height:100%;
						width: auto!important;
						border: 20px solid #fff;
					}
				}
			}
		}
		
		.chat-overlay-photos {
			height: auto!important;
			.x-content {
				height:100%;
				width:100%;

				#chat-container-photos {
					position:relative;
					width:100%;
					height:100%;
					margin:auto;
					paddding:8px;
					
					#chat-photos-container-img {
						position:absolute;
						z-index:10;
						top:0;
						bottom:30px;
						width:100%;
						display: flex;
						align-items: center;
						justify-content:center;
						
						#chat-photos-loader {
							
						}
						
						#chat-photos-slider {
							position:absolute;
							top:0;
							bottom:0;
							width:100%;
							height:100%;
							&.full-gallery {
								background-color:#000000;
								opacity:0.7;
							}
							#chat-photos-previous {
								height:100%;
								float:left;
								width:49%;
								cursor:pointer;
							}
							#chat-photos-next {
								height:100%;
								float:right;
								width:49%;
								cursor:pointer;
							}
						}
					}
					
					#chat-photos-container-bottom {
						position:absolute;
						z-index:10;
						width:100%;
						height:30px;
						bottom:0px;
						background: rgba(255, 255, 255, 0.6);
						color: #000000;
						font-weight:bold;
						#chat-photos-container-title {
							position:absolute;
							line-height:30px;
							left:5px;
							right:60px;
							text-align:left;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}
						#chat-photos-container-nb-pics {
							position:absolute;
							line-height:30px;
							width:55px;
							right:5px;
							text-align:right;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}
					}
					#chat-photos-preloader {
						display:none;
					}
				}
			}
			
			.chat-photos-img, .chat-photos-img-loader {
				max-height:100%;
				max-width:100%;
				width:auto;
				height:auto;
			}
			.chat-photos-img-text {
				z-index:20;
				position:absolute;
				font-size:15px;
				font-weight:bold;
				a {
					padding:8px;
				}
			}
		}

		.progress {
			position:relative;border:@text-color 1px solid;height:20px;line-height:20px;width:100%;
			.progress_bar {
				position:absolute;top:0;left:0;height: 100%;width:0;background-color: @red-color;
			}
			.progress_bar_text {
				position:absolute;top:0;left:0;height: 100%;width:100%;text-align:center;z-index:10;
				color:@text-color;
			}
		}
	}
	.chat-title {
		background: @chat-box-title-bg;
		color: @chat-box-title-color;
		cursor: pointer;
		font-weight: bold;
		line-height: 30px;
		overflow: hidden;
		margin: 0;
		padding: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		.close {
			float: right;
			font-size: 28px;
			font-weight: normal;
			height: 29px;
			margin: 0;
			padding: 5px 10px;
			transition: background 0.15s ease;
			&:hover {
				background: @red-color;
			}
			div {
				line-height: 4px;
			}
		}
		.chat-nb-users {
			margin-right: 10px;
			position:relative;
			cursor:pointer;
			float: right;
			.chat-nb-users-count {
				font-size:10px;
				font-weight:bold;
				line-height:10px;
				text-align: center;
			}
			.chat-nb-users-icon {
				font-size:14px;
				text-align:center;
				height:20px;
				width:16px;
				margin:auto;
			}
		}
		.chat-settings,
		.chat-icon-home {
			cursor:pointer;
			float: right;
			font-size: 20px;
			&,
			.icon-f,
			.icon-f:before{
				display: block;
				height:30px;
				line-height:30px;
				text-align: center;
				width:30px;
			}
			&:hover {
				color: @red-color;
			}
		}
		.chat-icon-home {
			font-size: 26px;
			position: relative;
			.badge {
				font-family: @font-family-sans-serif;
				font-size: 10px;
				margin-right:0;
				padding: 2px 5px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		span.badge {
			background: @theme-color;
			margin-right:5px;
			padding: 2px 6px;
			vertical-align: top;
			display: none;
			margin-right: 0;
			position: absolute;
			right: -3px;
			top: -3px;
		}
		.chat-icon {
			float: left;
			height: 30px;
			width: 30px;
			.icon-f{
				font-size: 24px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				width: 30px;
				&,
				&:before{
					display: block;
				}
			}
		}
		.chat-title-text{
			margin-left: 7px;
		}
	}
	#chat-settings-list {
		display:none;
		position:absolute;
		top:29px;
		right:0;
		background-color: @text-color;
		color: @text-invert-color;
		z-index: 291;
		font-size: 13px;
		padding: 4px;
		bottom:0;
		max-height:318px;
		overflow-y:auto;
		overflow-x:hidden;
		.chat-settings-option {
			height:22px;
			cursor:pointer;
			&:hover {
				color: @red-color;
			}
			&.separator {
				border-top: @gray-bg 1px solid;
			}
			.chat-settings-option-text {
				margin-right: 20px;
				float:left;
				white-space:nowrap;
				&.color {
					width: 12px;
					height: 12px;
					margin-top: 3px;
					border: @text-invert-color 1px solid;

					&.color0-left {
						background-color: @message-left-color0;
					}
					&.color0-right {
						background-color: @message-right-color0;
					}
					&.color1-left {
						background-color: @message-left-color1;
					}
					&.color1-right {
						background-color: @message-right-color1;
					}
					&.color2-left {
						background-color: @message-left-color2;
					}
					&.color2-right {
						background-color: @message-right-color2;
					}
				}
			}
			.chat-settings-option-icon {
				width:14px;
				height:14px;
				float: right;
				margin-top: 4px;
				position: absolute;
				right: 0;
			}
			.chat-settings-option-checked {
				background-position: @chat-settings-checked;
			}
			.chat-settings-option-unchecked {
				background-position: @chat-settings-unchecked;
			}
		}
	}
	#chat_friend_menu {
		display: none;
		position: absolute;
		right: 0;
		top:36px;
		z-index: 10;
		border: @chat-box-title-border 1px solid;
		border-top:0;
		border-right:0;
		background-color: @gray-lighter;

		div.chat-header-menu-icon {
			padding: 2px 5px 2px 2px;
			height: 29px;
			text-align:center;
			.icon-f {
				width:32px;
				float:left;
				.chat_profile_add_favorite {
					margin-top: 2px;
				}
			}
			.chat-header-menu-text {
				font-size: 11px;
				float:left;
				line-height: 29px;
			}
		}
	}
	#chat_friend_menu_switch {
		right: 2px;
		font-size: 24px;
		font-weight: bold;
		line-height: 20px;
	}
	.row {
		position: absolute;
		bottom: 0;
		top: 30px;
		left: 0;
		right: 0;
		margin: 0;
	}
	
	#chat-drag-file-overlay {
		z-index:50;
		text-align:center;
		display:none;
		position: absolute;
		bottom: 0;
		top: 0px;
		left: 0;
		right: 0;
		margin: 0;
		#chat-drag-file-overlay-opacity {
			width:100%;
			height:100%;
			background-color: #000000;
			opacity: 0.8;
		}
		#chat-drag-file-overlay-icon {
			position:absolute;top: 50%;height:30px;margin-top:-10px;font-size:30px;color:#FFFFFF;width:100%;
		}
	}
	
	.chat-messenger {
		border-right: 1px solid @chat-box-title-border;
		background: @text-invert-color;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		padding-left: 0;
		padding-right: 0;
		&.chat-home {
			.chat-header-messages {
				display:none;
			}
			.chat-messages {
				display:none;
			}
			.chat-message-form {
				display:none;
			}
			#chat-home {
				display: block;
			}
		}
	}
	.chat-header-messages {
		position: absolute;
		top: 0;
		height: 37px;
		width: 100%;
		border-bottom: @chat-box-title-border 1px solid;
		.chat-header-container {
			width: 100%;
			height: 100%;
			background-color: @gray-lighter;
			.chat_profile_name {
				font-weight: bold;
				font-size:16px;
				height:36px;
				line-height:36px;
				.chat_profile_name_favorite {
					margin-top:11px;
				}
			}
			.chat-header-icon {
				height: 35px;
				padding: 5px 3px 7px;
				position:absolute;
				background-color: @gray-lighter;
				top: 0;
				width:28px;
			}
			.chat-header-change-fav {
				right:56px;
			}
			.chat-header-delete-all {
				right:82px;
			}
			.chat-header-crypt-old {
				right: 30px;
				color: @red-color;
				.badge {
					position: absolute;
					right: 0;
					top: 1px;
					background: @red-color;
					height: 14px;
					line-height: 6px;
					font-size: 10px;
					padding: 3px;
				}
			}
			.chat-header-crypt-old-done {
				display:none;
			}
			.chat-header-warning-crypt .icon-f {
				color:#FFBC00;
			}
			.chat-header-block {
				right:2px;
			}
			.chat_profile_favorite {
				float:left;
			}
			.chat_profile_add_favorite {
				width:22px;
				height: 22px;
			}
		}
	}
	#chat-home {
		display:none;
		width: 100%;
		height: 100%;
		padding: 7px;
		background-color: @text-invert-color;
		text-align: center;
		overflow-y: auto;
		h3,h4 {
			margin-top: 20px;
		}
		p {
			margin-top: 20px;
		}

		div#chat-home-title {
			height: 60px;
			display: table;
			text-align: center;
			width: 100%;
			h3 {
				display: table-cell;
				vertical-align: middle;
			}
		}

		ul#chat-home-tabs {
			border-bottom: 1px solid @home-tab-bg;
			float: none;
			overflow: visible;
			padding: 0;
			margin: 0;
			margin-top: 10px;
			white-space: nowrap;
			list-style-type: none;
			display:none;
			text-align: left;
			height:31px;
			li {
				margin: 0 5px 0 0;
				padding: 0;
				white-space: nowrap;
				display: inline-block;
				border: 1px solid @home-tab-bg;
				border-bottom: 0;
				border-radius: 2px 2px 0 0;
				&.chat-selected .chat-home-tabs-icon{
					background: @text-invert-color!important;
					color:@text-color!important;
					border-bottom: 1px solid @text-invert-color!important;
				}
				&.chat-home-tabs-hide {
					display:none;
				}
				.chat-home-tabs-icon {
					text-decoration:none;
					line-height: 22px;
					height:30px;
					background: @home-tab-bg;
					color: @home-tab-color;
					margin: 0;
					overflow: hidden;
					padding: 3px 12px;
					cursor: pointer;
					&:hover {
						background: @text-invert-color;
						color:@text-color;
					}
					
					.icon-f {
						font-size: 18px;
						height: 14px;
						line-height: 14px;
						vertical-align: middle;
					}
					.chat-home-tabs-text {
						line-height: 18px;
						vertical-align:bottom;
						display: none;
					}
					.badge {
						font-family: Arial,sans-serif;
						font-size: 10px;
						background-color: @red-color;
						vertical-align: top;
					}
					
				}
			}
		}

		#chat-home-tabs-content {
			overflow-y: auto;
			position: absolute;
			bottom: 5px;
			top: 110px;
			width: 95%;
			.chat-home-tab-container {
				display:none;
				&.chat-selected {
					display: block!important;
				}

				&.chat-home-message-bottom {
					position: absolute;
					bottom: 0;
					left: 0;
					color: @gray-dark;
				}
			}
		}

		#chat-home-last-friend-request, #chat-home-last-friend-request-sent {
			margin-top: 10px;
			ul {
				width: 330px;
				max-width: 90%;
				margin: auto;
				padding:0;
				li {
					width:100%;
					height: 39px;
					text-align: left;
					padding:4px;
					clear: both;
					display:block;

					.chat-friend-request-image{
						float:left;
						width:32px;
						height:32px;
						line-height:32px;
						display: inline-block;
						img {
							width: 30px;
							border: @text-color 1px solid;
						}
					}
					.chat-friend-request-name {
						float:none;
						display: block;
						line-height: 30px;
						margin-left:5px;
						white-space: nowrap;
						overflow:hidden;
					}
					.chat-friend-request-button {
						float:right;
						display: inline-block;
						width: 30px;
						margin-top:1px;
						margin-left:4px;
						padding: 3px 0 2px 0;
						height: 28px;
						font-size:18px;
						width: 19px;
						color: @text-color !important;
						cursor: pointer;
					}
				}
			}
		}
	}
	.chat-messages {
		position: absolute;
		top: 36px;
		bottom: 54px;
		width: 100%;
	}
	.chat-message-form {
		bottom: 0;
		padding: 2px 5px 4px;
		position: absolute;
		width:100%;
		.input-group {
			word-break:break-all;
		}
		textarea {
			border-color: @chat-box-title-border;
			border-width: 1px 0 1px 1px;
			border-radius: 0;
			height: 48px;
			&:focus{
				background: darken(@input-bg, 10%);
				outline: none;
			}
		}
		.btn {
			background: @btn-default-bg;
			border: @chat-box-title-border 1px solid;
			border-radius: 0;
		}
		.btn.disabled {
			background: @btn-link-disabled-color;
		}
		.emojionearea-editor {
			font-size: 12px;
		}
		#textarea-group {
			position:relative;
			height:48px;
			#chat-send-file {
				border: 0;
				padding: 0;
				background-color: transparent;
				position:absolute;
				bottom:5px;
				right:8px;
				display: block;
				height: 14px;
				line-height: 14px;
				margin: 0;
				color:@gray-light;
				z-index:5;
				&:hover:not([disabled]) {
					cursor:pointer;
					color:@gray;
				}
				&[disabled] {
					color: transparent;
				}
			}
			#chat-file {
				display:none;
			}
		}
		.input-group {
			display: flex;
			width: 100%;
			
			#textarea-group {
				flex-grow: 1;
			}
			
			#chat-send-btn {
				flex: 0 1 auto;
				margin: 0 0 1px 2px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: auto;
			}
		}
	}
	#chat-users-lists {
		top: 0;
		position: absolute;
		bottom: 24px;
		right: 0;
		left: 0;
		overflow:auto;
	}
	.chat-users {
		margin-bottom: -1px;
		min-height: 1px;
		position: relative;
		z-index: 10;
		a {
			background-color: @text-invert-color;
			border: solid @gray-bg;
			border-width: 1px 0 1px 0;
			display: block;
			height: 37px;
			padding: 0;
			position: relative;
			text-decoration: none;
			z-index: 1;
			&.friend_selected {
				background: @gray-lighter;
				border-color: @chat-box-title-bg;
				z-index: 2;
				.chat_profile_name{
					font-weight: bold;
				}
			}
			&:hover{
				background: @gray-lighter;
			}
			&.chat_no_friends {
				text-align: center;
				cursor: default;
			}
			&.chat_back_to_friends {
				text-align: center;
			}
			&.chat_friend_offline {
				.chat_picture_filter {
					display:block;
				}
				.chat_profile_name {
					color:@gray-light!important;
				}
			}
			&#chat_get_more_friends_link {
				line-height: 34px;
			}
			&.chat_friend_typing {
				.chat_list_user_is_typing {
					display: block!important;
					margin-left: 38px;
					height: 12px;
					line-height: 12px;
					font-size: 11px;
					&.chat_list_user_is_typing_fav {
						margin-left: 56px;
					}
				}
				.chat_profile_name {
					height: 23px;
					line-height: 23px;
				}
				.chat_profile_name_favorite {
					margin-top: 5px;
				}
			}
			.chat_list_user_is_typing {
				display: none;
			}
		}
		&.friend_selected{
			z-index: 11;
		}
	}

	.chat_picture_status {
		float: left;
		width: 35px;
		height: 35px;
		background-size: 35px;
	}
	.chat_picture_filter {
		display:none;
		position:absolute;
		width: 35px;
		height: 35px;
		top:0;
		left:0;
		color: @text-color;
		line-height: 35px;
		overflow: hidden;
		span {
			background-color: @gray-bg;
			white-space: nowrap;
		}
	}
	.chat_status_nb_unread {
		position:relative;
		top: 0;
		height: 16px;
		z-index:5;
		.badge {
			float:right;
			padding: 2px 3px;
			min-width: 14px;
			background-color: @red-color;
		}
	}
	.chat_status_online, .chat_status_offline {
		margin-top: 22px;
		margin-left: 29px;
		border: @text-invert-color 1px solid;
		width: 12px;
		height: 12px;
		border-radius: 50%;
	}
	.chat_status_online {
		background-color: @brand-success;
	}
	.chat_status_offline {
		background-color: @gray-light;
	}
	.chat_profile_name {
		height:36px;
		line-height: 36px;
		margin-left: 38px;
		overflow: hidden;
		white-space: nowrap;
		color:@text-color;
		font-size: 15px;
	}
	.chat_profile_name_favorite {
		float:left;
		margin-top: 11px;
		margin-right:3px;
		width:14px;
		height: 14px;
	}
	.chat_profile_favorite {
		margin-left: 38px;
	}
	.chat_profile_date {
		height:12px;
		margin-left: 39px;
		text-align: right;
		font-size: 9px;
		color: @gray-light;
		font-weight: bold;
		&.not_read {
			color:@brand-warning;
		}
	}

	#chat-users-search {
		display:none;
		position:absolute;
		width:100%;
		top:0;
		left:0;
		bottom:28px;
	}
	#chat-users-search-close {
		display:none;
		height: 28px;
		position:absolute;
		left:0;
		width:100%;
		bottom:0;
	}
	#chat-users-search-input {
		text-align: center;
		position: absolute;
		bottom: 0;
		input {
			border-color: @chat-box-title-border;
			border-width: 1px 0 0 0;
			border-radius: 0;
			padding: 2px 2px 2px 4px;
			height: 24px;
		}
		button {
			border: 1px solid @chat-box-title-border;
			border-bottom: 0;
			border-right: 0;
			border-radius: 0;
			margin: 0;
			height: 24px;
			padding: 0px 2px;
		}
	}
	&.chat-hidden {
		width:auto;
		height:auto;
		min-width:0;
		.chat-title .close,
		.row, .chat-title-text, .chat-nb-users, .chat-settings, .chat-icon-home, .x-overlay, #chat_loading_overlay, .chat-title-link-debug {
			display: none;
		}
		.chat-title {
			span.badge {
				display: block;
			}
		}
	}

	#chat-side-column {
		padding-left: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}

	#chat_prefs_checkbox {
		padding-top: 6px;
		#chat_check_notif {
			display: none;
		}
	}

	.chat_tab_container {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		overflow: auto;

		&.chat_is_typing {
			.chat_group_tab_container {
				bottom:15px;
			}
			.chat_typing {
				display:block;
			}
		}
		.chat_typing {
			display:none;
			position:absolute;
			bottom:0;
			height: 15px;
			line-height: 13px;
			margin-left: 3px;
		}
	}
	.chat_group_tab_container {
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
		overflow: auto;
		.div_load_history {
			text-align: center;
			height: 30px;
			line-height: 40px;
		}
		.get_history {
			line-height: 40px;
			text-align: center;
			cursor:pointer;
			.icon.history {
				line-height: 18px;
				height: 20px;
				width: 16px;
			}
			span {
				padding-left: 4px;
			}
		}
		.cannot_decrypt_previous {
			color: @red-color;
			padding:5px;
			font-weight: bold;
			text-align: center;
		}
		#chat-profile-send-request {
			text-align: center;
			margin-top: 25%;
			padding: 10px;
		}
		li {
			width: 100%;
			margin-bottom: 5px;
			padding: 3px;
			.chat-body {
				.chat_message_inner {
					color: @text-invert-color;
					a {
						color: @text-invert-color;
					}
				}
				
				.header {
					text-align: left;
					.header_message_left {
						padding-left: 3px;
					}
					.header_message_right{
						padding-left: 23px;
					}
				}
				.chat_message_display{
					.chat_message_report {
						display:none;
					}
					
					.chat_message {
						overflow:hidden;
						position: relative;
						
						.chat_upload_image_div {
							position:relative;
							margin:3px 0;
							min-height:30px;
							text-align: center;
						}

						.chat_progress_bar {
							width:100%;
							margin-top:2px!important;
							height:22px;
						}

						.chat_upload_load_button {
							position:absolute;
							top:0;
							left:0;
							display:flex;
							justify-content:center;
							align-items:center;
							height:100%;
							width:100%;
							span {
								font-size:25px;
								cursor:pointer;
							}
						}
						.chat_upload_image_div.chat_upload_blured {
							img, video {
								opacity: 0.1;
							}
							.chat_upload_load_button {
								display:none;
							}
							
						}
						.chat_upload_blured_button {
							position:absolute;
							top:0;
							left:0;
							display:flex;
							justify-content:center;
							align-items:center;
							height:100%;
							width:100%;
							z-index: 8;
							span {
								font-size:25px;
								cursor:pointer;
							}
						}
						.chat_upload_blur_button {
							position:absolute;
							top:0;
							left:0;
							background: @text-color;
							color: @text-invert-color;
							padding: 3px;
							text-align: center;
							z-index:9;
							span {
								font-size:25px;
								cursor:pointer;
							}
						}
						.chat_upload_expire_in {
							margin-top: 4px;
						}
						.chat_upload_text {
							margin-top: 8px;
						}
						.chat-upload-download {
							position: absolute;
							right: 0px;
							top: 0px;
							background: @text-color;
							color: @text-invert-color;
							padding: 3px;
							text-align: center;
							z-index:9;
							
							div {
								font-weight: bold;
							}
							span {
								font-size: 24px;
								cursor: pointer;
							}
						}
						.icf-file-broken {
							margin:3px;
							font-size:25px;
						}
					}
					p.chat_message_system {
						color: @text-color!important;
						background-color: @text-invert-color;
						border: @text-color 1px solid;
						margin-left: 20px;
						margin-right: 20px;
						text-align: center;
						border-radius:15px;
						padding: 3px 8px;
					}
					div:first-child {
						p {
						}
					}
					div:last-child{
						p {
							position: relative;
							&:after{
								content: " ";
								border-style: solid;
								height: 0;
								position: absolute;
								top: 100%;
								width: 0;
							}
							
							&.message_left {
								&:after {
									border-color: @message-left-color0 transparent transparent transparent;
									border-width: 6px 6px 0 0;
									left: 10px;
								}
							}
							&.message_right {
								&:after {
									border-color: transparent @message-right-color0 transparent transparent;
									border-width: 0 6px 6px 0;
									right: 10px;
								}
							}
						}
					}

					.chat_message_gallery {
						text-align:center;
						min-height:20px;
						line-height:20px;
						margin-top:5px;
						img {
							max-height:250px;
							max-width:100%;
							cursor:pointer;
							&.chat_profile {
								height:auto;
								width:90px;
								max-width:none;
							}
						}
						
						& + .chat_message_gallery {
							margin-top: .75rem;
						}
					}
					
					.thumb-block {
						float: none;
						width: 100%;
						border: 0;
						padding:2px;
						.thumb {
							overflow: hidden;
						}
						.thumb-under {
							min-height: auto;
							p {
								font-size: 12px;
								padding: 0px 8px;
								max-height:none;
							}
						}
						.metadata {
							display: none;
						}
					}

				}
				.message_left {
					background-color: @message-left-color0;
					margin-right: 20px;
				}
				.message_right {
					background-color: @message-right-color0;
					margin-left: 20px;
				}
				p {
					line-height:1.2;
					overflow-wrap: break-word;
					padding: 5px 8px;
				}
				.message_multi {
					margin-top:-9px;
					p {
						border-top:0!important;
					}
				}
			}
			.chat-actions {
				width: 16px;
				padding-left: 4px;
				text-align:right;
				.chat_message_status_not_read {
					width: 12px;
					height: 12px;
					background-color: @brand-warning;
					border-radius: 50%;
					margin-left: 1px;
					margin-top: 4px;
				}
				.remove-message {
					display:none;
					height: 12px;
					width: 12px;
					margin-top: 6px;
					line-height: 10px;
					a {
						display: block;
						height: 100%;
						width: 100%;
						text-align: center;
						text-decoration: none;
						color: @red-color;
					}
					a:hover {
						text-decoration: none;
					}
				}
				.report-message {
					display:none;
					height: 12px;
					width: 12px;
					margin-top: 6px;
					line-height: 10px;
					a {
						display: block;
						height: 100%;
						width: 100%;
						text-align: center;
						text-decoration: none;
						color: @red-color;
					}
					a:hover {
						text-decoration: none;
					}
				}
			}
			&.chat-no-messages {
				text-align: center;
				line-height:25px;
			}
		}
	}

	#chat_pass_no_key_error {
		font-weight: bold;
		color: @red-color;
	}
	#chat_pass_no_key_form {
		input, button {
			color:#000000;
		}
	}
	#chat_pass_no_key_check_pass {
		display:none;
	}

	.emojionearea-editor {
		min-height:45px!important;
		max-height:45px!important;
		height:45px!important;
	}

	#chat-preloader {
		display:none;
	}

}

ul.dropdown-menu.textcomplete-dropdown[data-strategy="emojionearea"] {
	position:fixed!important;
	bottom:50px!important;
	top:auto!important;
}

.chat_dialog_report {
	.chat_dialog_head {
		font-size: 14px;
		margin-bottom: 8px;
	}
	.modal_error {
		color: @red-color;
		font-weight: bold;
	}
	p {
		text-align: center;
	}
	button {
		color:#000000!important;
	}
	textarea {
		height:90px;
		color: #000000;
	}
	.chat_dialog_label_textarea {
		float:left;
		line-height:90px;
		margin-right: 5px;
	}

}

.chat_xv_popup {
	button, textarea, select {
		margin:3px;
		color: #000000;
	}
	.chat_popup_buttons {
		text-align: center;
	}
	.chat_popup_button_red {
		background-color: @red-color;
		color:#FFFFFF;
	}
	ul.chat_popup_choice {
		padding-left: 0;
		padding-top: 5px;
		li {
			cursor: pointer;
		}
		li:hover {
			background-color: #d9d9d9;
			color: #000000;
		}
	}
	h4 {
		margin-bottom:10px;
		text-align: center;
	}
	.chat_delete_all {
		display: table;
		margin-bottom:10px;
		div {
			display: table-row;
			div {
				display:table-cell;
				vertical-align: middle;
				min-width: 34px;
				.icf-warning {
					font-size:2.2em;
					color: @red-color;
				}
			}
		}
	}

	.error {
		color: @red-color;
	}
}

.chat_user_has_to_subscribe {
	background-color: red;
	color: white;
}

.chat_user_has_to_subscribe span.sub-state-text:first-child {
	height: auto;
	padding: 0;
}
.chat_user_has_to_subscribe .user-subscribe {
	height: auto;
	padding: 1px 5px;
	vertical-align: middle;
	margin: 0 4px;
	border-radius: 4px;
}
.chat_user_has_to_subscribe .user-subscribe .loader {
	position: static;
	transform: none;
}

.chat_preview_link a {
	text-decoration: none;
}
.chat_preview_link_button {
	background-color: #de2600;
	color: white !important;
	font-weight: 700;
	text-decoration: none;
	align-items: center;
	text-align: center;
	display: flex;
	height: initial;
	justify-content: center;
	line-height: 1.25;
	margin-bottom: .5em;
	padding: .5em;
	
	.icon-f {
		margin-right: .25em;
	}
	
	&:hover {
		text-decoration: none;
	}
	
	&:not(.chat-content__button--disabled):hover {
		background-color: #ab1d00;
	}
	
	&--disabled,
	&--disabled:hover {
		cursor: default;
		opacity: .75;
		box-shadow: none !important;
	}
}

.chat-quote {
	padding-top: 6px;

	&__inner {
		border-left: 2px solid rgba(127, 127, 127, .75);
		margin-left: 8px;
		padding-left: 8px;
		padding-right: 8px;
	}
	
	&__metas {
		font-style: italic;
	}
	
	blockquote {
		border-width: 0;
		font-size: inherit;
		margin-bottom: 0;
		padding: 0;
	}
	p {
		padding: 0 0 4px 0 !important;
		
		&::after {
			content: none !important;
		}
	}
}

.chat_message .chat-content {
	padding: 0;
	
	&:first-child {
		padding-top: 3px;
	}
	
	&:last-child {
		padding-bottom: 3px;
	}
}
.chat-content {
	padding-left: 8px;
	padding-right: 8px;
	margin-top: 1em;
	
	&:first-child {
		margin-top: 0;
		padding-top: 8px;
	}
	
	&:last-child {
		padding-bottom: 8px;
	}
	
	&:first-child:last-child .chat-content__button {
		margin-bottom: 0;
	}
	
	a:focus {
		text-decoration: none;
	}
	
	&--loading .chat-content__message {
		display: none;
	}
	
	&--no-thumb .chat-content__thumb-wrapper {
		min-height: 133px;
		min-width: 200px;
	}
	
	&__no-thumb,
	&__thumb-wrapper,
	&__title {
		margin-bottom: 4px;
	}
	
	
	&__button {
		background-color: #de2600;
		color: white !important;
		font-weight: 700;
		text-decoration: none;
		align-items: center;
		text-align: center;
		display: flex;
		height: initial;
		justify-content: center;
		line-height: 1.25;
		padding: 0.5em;
		
		&:hover {
			text-decoration: none;
		}
		
		&:not(.chat-content__button--disabled):hover {
			background-color: #ab1d00;
		}
		
		&--disabled,
		&--disabled:hover {
			cursor: default;
			opacity: .75;
			box-shadow: none !important;
		}
	}
	
	&__message {
		margin-top: 1em;
		padding: .25em  0 0 !important;
	}
	
	&__no-thumb {
		align-items: center;
		background-color: #444;
		color: #fff;
		display: flex;
		flex-direction: column;
		margin-bottom: .75em;
		padding: 1em;
		text-align: center;

		span {
			background-color: #222;
			border-radius: 8px;
			font-size: 2em;
			margin-bottom: .25em;
			padding: .25em;
		}
	}
	
	&__thumb-wrapper {
		position: relative;
		overflow: hidden;
		padding: 0;
		
		&--layout-2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: 8px;
			& .chat-content__thumb-image {
				height: 100%;
				object-fit: cover;
			}
		}
	}
	&__thumb {
		display: block;
		height: 100%;
		position: relative;
		text-align: center;
		
		&--locked {
			overflow: hidden;
			
			&::after {
				content: "\e93c";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
				
				background-color: #222;
				border-radius: 8px;
				color: #fff;
				font-size: 2em;
				padding: .25em;
				
				font-family: iconfont !important;
				speak: never;
				font-style: normal;
				font-weight: 400;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				letter-spacing: 0;
				-webkit-font-feature-settings: "liga";
				-moz-font-feature-settings: "liga=1";
				-moz-font-feature-settings: "liga";
				-ms-font-feature-settings: "liga" 1;
				font-feature-settings: "liga";
				-webkit-font-variant-ligatures: discretionary-ligatures;
				-moz-font-variant-ligatures: discretionary-ligatures;
				font-variant-ligatures: discretionary-ligatures;
				-webkit-font-smoothing: antialiased;
				
			}
			img {
				filter: blur(10px);
			}
		}
		
		.thumb-block {
			padding: 0 !important;
			
			& .thumb a,
			& .thumb-purchase a {
				border: 0;
			}
			
			.thumb-inside {
				margin-bottom: 0;
			}
			
			.thumb-under {
				display: none;
			}
			
			.videopv .progress {
				display: none !important;
			}
		}
	}
	&__thumb-image {
		width: auto;
		max-width: 100%;
		height: auto;
		max-height: 50vh;
		margin: 0 auto;
	}
	&__thumb-count {
		cursor: default;
		color: #000;
		border-radius: 3px;
		font-size: .83em;
		height: 18px;
		line-height: 18px;
		margin-left: 4px;
		padding: 0 3px;
		position: absolute;
		z-index: 1;
		right: 5px;
		bottom: 5px;
		background-color: #aaa;
		display: inline-flex;
		align-items: center;
		
		.icon-f {
			margin-right: 4px;
			margin-top: 2px;
		}
	}
	
	&__title {
		margin-top: 1em;
		font-size: 1.125em;
		font-weight: 700;
	}
}

a.chat-content__thumb,
.chat-content__gallery .chat-content__thumb {
	cursor: pointer;
}

#popup_crypt_buttons {
	text-align: center;
}

#popup_chat_report {
	text-align: center;
	button {
		margin:3px;
		color: #000000;
	}
	select {
		color: #000000;
	}
	h4{
		margin-bottom: 5px;
	}
	p {
		margin-top:5px;
	}
}