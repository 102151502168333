.home #header-menu-toggle,
.premium-page .premium_home #header-menu-toggle {
	display: block;
}

.home {
	.premium-switch-page-dropdown {
		margin-top: 10px;
	}
}

.mozaique {
	.thumb-block {
		.select-thumbs {
			padding: 3px 10px 3px 10px;
			font-size: 12px;
		}
		.thumb-inside {
			.action-menu {
				font-size: 20px;
				height: 40px;
				line-height: 40px;
				width: 40px;
			}
		}
	}
	&.thumbs-5-cols {
		.thumb-block {
			.any-col-config({ .thumb-block-size-mixin(3); });
		}
	}
	&.thumbs-small {
		.thumb-block {
			.any-col-config({ .thumb-block-size-mixin(5); });
		}
	}
}

.pagination + #listing-settings {
	.listing-settings-float-mixin(-44px, 36px);
}

.stripe + #listing-settings,
.pagination + #listing-settings {
	.btn {
		.icon-f {
			display: inline-block;
			font-size: 18px;
			vertical-align: middle;
		}
		.label {
			display: none;
		}
	}
}

.site-settings {
	.label a {
		&.mobile-hide {
			display: none;
		}
		&.mobile-show {
			display: block;
		}
	}
}

#list-page li {
	width: 50%;
}

#tags,
.tags-list {
	li {
		width: 33.333%;
	}
	a {
		padding: 5px;
		font-size: 15px;
	}
}

.tab-form {
	.login {
		border-right: 0;
	}
	.create-account {
		border-top: 2px solid @gray-lighter;
		padding-top: 10px;
		margin-top: 10px;
		.text-center {
			text-align: right;
		}
	}
}

#profile-search-form {
	&.display-compact {
		.form-group {
			width: 50%;
			&:first-child {
				width: 100%;
			}
		}
	}
}

#create-account-frame {
	h2 {
		font-size: 28px;
	}
	
	legend {
		font-weight: normal;
	}
	
	.form-group {
		.control-label {
			float: none;
		}
	}
	
	.info-pannel-frame {
		padding-left: 0;
	}
	
	.info-pannel {
		padding-left: 10px;
		
		h3 {
			margin-bottom: 10px;
			font-size: 14px;
		}
		
		.advantage {
			margin: 5px 0;
			
			p {
				font-size: 12px;
				font-weight: normal;
			}
		}
		
		.btn-default {
			font-size: 14px;
			margin-top: 10px;
		}
	}
	
}

#signin-popup-form {
	#signup-form_step2_profilepic {
		.step2-profile-pic {
			margin: 0 auto 10px;
			width: 80px;
			float: none;
		}
	}
}

#footer {
	.botLinks {
		text-align: center;
		a {
			line-height: 2;
		}
		strong {
			color: @text-color;
		}
	}
}

.simple-dropdown {
	.niv1 {
		a {
			padding-left: 4px;
			padding-right: 4px;
		}
	}
}

.favlists {
	.favlists-actions {
		.favlists-actions-line-1 {
			display: flex;
			flex-basis: 100%;
		}
		
		label {
			font-size: 0;
			margin-right: 4px;
			max-width: calc(~"100% - 4px");
			
			* {
				font-size: 14px;
			}
			
			.icon-f {
				font-size: 16px;
			}
			
			&:last-child {
				margin-right: 0;
			}
			
			&.favlists-search {
				margin-left: 4px;
				
				.btn {
					margin-right: 0;
				}
			}
		}
		
		.select-label {
			margin: 0 4px 0 0;
			
			.favlists-nb-per-page-cont {
				margin: 0 4px 0 0;
			}
		}
		
		.favlists-show-more-filters {
			display: inline-block;
		}
		.favlists-actions-more {
			display: none;
			
			&.is-visible {
				background: @gray-bg;
				display: inline-block;
				display: flex;
				flex-wrap: wrap;
				margin: 10px 0 0;
				padding: 10px 15px 5px;
				width: 100%;
				
				& > * {
					margin-bottom: 5px;
				}
			}
		}
	}
}

.favlist-create,
.favlist-edit {
	.btn-back {
		margin: 0 0 26px;
		
		& + * {
			clear: both;
		}
	}
	
	.favlist-list-title {
		p {
			padding: 0 15px;
		}
	}
	
	.favlist-picto {
		&, &.icf-clock {
			font-size: 68px;
			left: auto;
			right: 20px;
			top: 0;
		}
	}
}

.favlist-edit {
	.voters-list {
		margin-top: 0;
	}
	
	.voters-list-title {
		margin: 0 10px 10px;
	}
}

.favlist-edit-list-menu {
	padding: 8px 10px 4px;
	
	&.is-closed {
		max-height: 60px;
	}
	
	//&.is-opened {
	//	max-height: 346px;
	//}
	
	.btn {
		font-size: 16px;
		font-weight: normal;
		margin: 0 6px 4px 0;
		padding: 6px 8px;
		
		.icon-f {
			font-size: 0.85em;
			margin-right: 0;
		}
	}
	
	.legend {
		padding: 6px 2px;
		margin: 0 8px 4px 0;
	}
}

.x-overlay {
	&.favlist-overlay {
		padding: 10px;
		
		.x-body {
			padding: 20px 15px 15px;
			
			.favlist-edit-list-menu {
				&.is-pined {
					left: 15px;
					top: 20px;
					width: calc(~"100% - 30px");
				}
			}
		}
	}
}