#x-messages,
#x-red-home-messages,
#x-ajax-tab-messages,
#x-fda-messages,
#x-home-messages {
	.x-message {
		background: @x-messages-bg;
		border: 1px solid @x-messages-border;
		color: @x-messages-color;
		font-size: 14px;
		overflow: hidden;
		margin: 3px @body-out-padding 0;
		padding: 10px 30px 10px 16px;
		position: relative;
		z-index: 1;
		html[dir='rtl'] & {
			padding: 10px 16px 10px 30px;
			.content {
				text-align: right;
			}
			.del,
			.reduce {
				left: 0;
				right: auto;
			}
		}
		.content {
			position: relative;
			text-align: left;
			z-index: 2;
			img {
				margin: 0 10px 0 0;
				vertical-align: text-top;
			}
			p {
				font-size: 14px;
			}
			ul {
				font-size: 13px;
				padding-left: 20px;
				li {
					list-style-type: disc;
				}
			}
			a,
			.link {
				color: @x-messages-color;
				cursor: pointer;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			& > *:last-child {
				margin-bottom: 0;
			}
		}
		.del,
		.reduce {
			display: block;
			opacity: 0.5;
			position: absolute;
			right: 0;
			text-decoration: none;
			top: 0;
			transition: all 0.2s;
			width: 30px;
			&:hover {
				opacity: 1;
			}
			.icon-f {
				color: @x-messages-color;
				display: block;
				font-size: 14px;
				line-height: 18px;
				margin: 10px 0;
				text-align: center;
				width: 100%;
			}
		}
		.reduce {
			.icon-f {
				font-size: 18px;
			}
		}
		.bg-icon {
			color: @x-messages-icon-bg;
			display: block;
			height: 44px;
			font-size: 50px;
			left: -10px;
			line-height: 44px;
			position: absolute;
			text-indent: -3px;
			top: 4px;
			width: 42px;
			z-index: 1;
			
			&.bg-img {
				background-position: center center;
				background-size: cover;
				border-radius: 50%;
			}
		}
		&.x-message-info,
		&.x-message-warning,
		&.x-message-question,
		&.x-message-error,
		&.x-message-success {
			padding-left: 40px;
		}
		&.x-message-info {
			.bg-icon {
				color: @x-messages-icon-bg-info;
			}
		}
		&.x-message-success {
			.bg-icon {
				color: @x-messages-icon-bg-success;
			}
		}
		&.x-message-error {
			font-weight: bold;
			&, a {
				color: @x-messages-color-error;
			}
			.bg-icon {
				color: @x-messages-icon-bg-error;
			}
		}
		&.x-message-warning {
			.bg-icon {
				color: @x-messages-icon-bg-warning;
			}
		}
	}
}

#x-red-home-messages,
#x-ajax-tab-messages,
#x-fda-messages,
#x-home-messages {
	margin-top: 8px;
	margin-bottom: 8px;
	
	.x-message {
		margin: 3px 0 0;
		&.x-message-info,
		&.x-message-warning,
		&.x-message-question,
		&.x-message-error,
		&.x-message-success {
			line-height: 20px;
			padding: 3px 25px 3px 10px;
		}
		.del,
		.reduce {
			width: 25px;
			.icon-f {
				font-size: 12px;
				line-height: 20px;
				margin: 3px 0;
			}
		}
		.bg-icon {
			display: none;
		}
	}
}
#x-red-home-messages,
#x-ajax-tab-messages {
	.x-message {
		.content {
			a {
				color: @text-color;
			}
		}
	}
}
#x-x-messages-btn {
	background: @text-color;
	border: 0;
	bottom: 0;
	height: 31px;
	padding: 0;
	position: fixed;
	right: 0;
	text-decoration: none;
	z-index: @zindex-x-message - 2;
	&:focus, &:active, &focus:active {
		outline: none;
	}
	&.x-x-messages-btn-0 {
		display: none;
	}
	&.chat-window-side {
		right: 40px;
	}
	.ic {
		color: @text-invert-color;
		cursor: pointer;
		display: block;
		font-size: 20px;
		height: 31px;
		line-height: 31px;
		position: relative;
		text-align: center;
		width: 32px;
		.icf-bell {
			display: inline-block;
			height: 31px;
			left: 0;
			line-height: 31px;
			position: absolute;
			text-decoration: none;
			top: 0;
			transition: all 0.3s ease-in-out;
			width: 32px;
		}
	}
	.badge {
		background: @theme-color;
		padding: 2px 6px;
		position: absolute;
		right: -3px;
		top: -3px;
	}
}

#x-messages {
	bottom: 34px;
	display: none;
	max-height: 80%;
	max-height: 80vh;
	//max-height: calc(~'100vh - 40px - '2 * @header-height);
	.max-height-100vh(40px + 2 * @header-height);
	max-width: 90%;
	padding: 0 0 0 10px;
	position: fixed;
	right: 0;
	width: 410px;
	z-index: @zindex-x-message - 3;
	//& > div {
	//	display: none;
	//}
	
	#x-messages-scrollable {
		background: linear-gradient(90deg,transparent, fadeout(@body-bg, 30%) 5%, @body-bg 60%);
		max-height: 100%;
		max-height: 80vh;
		//max-height: calc(~'100vh - 40px - '2 * @header-height);
		.max-height-100vh(40px + 2 * @header-height);
		overflow: auto;
		overflow-x: hidden;
		position: relative;
		
		//&:before {
		//	background: @body-bg;
		//	bottom: 10px;
		//	content: ' ';
		//	filter: blur(10px);
		//	left: 25px;
		//	position: absolute;
		//	right: 10px;
		//	top: 25px;
		//}
		
	}
	&.notif-only {
		#x-messages-scrollable {
			background: none;
		}
	}
	@media @media-ratio-horizontal {
		&,
		#x-messages-scrollable {
			//max-height: calc(~'100vh - 40px - ' @mobile-header-height);
			.max-height-100vh(40px + @mobile-header-height);
		}
	}

	&.opened {
		display: block;
		
		//& > div {
		//	display: block;
		//}
		
		.preview-container {
			display: none;
		}
	}
	
	.preview-container {
		display: none;
		padding: 8px 4px;
		position: relative;
		
		.x-message {
			&:after,
			.del,
			.reduce {
				display: none;
			}
		}
	}
	
	.preview-overlay {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
	}
	
	&.preview {
		bottom: 34px;
		display: block;
		
		.preview-container {
			display: block;
		}
	}
	
	.x-message {
		margin: 0 0 3px;
		&:last-child {
			margin-bottom: 0
		}
		
		&:after {
			background: @theme-color;
			border-radius: 50%;
			box-shadow: 0 0 2px 1px #fff;
			content: ' ';
			display: block;
			height: 14px;
			left: -2px;
			position: absolute;
			top: 2px;
			transform: scale(0);
			transition: 0.2s transform;
			width: 14px;
			z-index: 2;
		}
		
		&.unviewed {
			&:after {
				transform: scale(1);
			}
		}
		
		.reduce {
			display: block;
		}
	}
	
	.notif-sep {
		color: #fff;
		font-size: 16px;
		height: 26px;
		line-height: 26px;
		margin: 6px @body-out-padding 3px @body-out-padding+2px;
		position: relative;
		text-shadow: 0 0 3px #000;
		
		&:after {
			background: #fff;
			box-shadow: 0 0 2px #000;
			content: " ";
			display: block;
			height: 1px;
			position: absolute;
			right: @body-out-padding;
			top: 55%;
			width: calc(~'100% - 30px');
		}
	}
	.has-callback {
		.content {
			cursor: pointer;
		}
	}
	
	&:before,
	&:after {
		height: 50px;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: 2;
	}
	&.can-scroll-top {
		&:before {
			box-shadow: 0 10px 6px -7px #000;
			content: ' ';
			display: block;
			top: -50px;
		}
	}
	&.can-scroll-bottom {
		&:after {
			bottom: -50px;
			box-shadow: 0 -10px 6px -7px #000;
			content: ' ';
			display: block;
		}
	}
}

#x-messages-menu {
	background: @text-color;
	bottom: 0;
	display: none;
	height: 31px;
	padding: 0 2px 0 0;
	position: fixed;
	right: 32px;
	z-index: @zindex-x-message - 1;
	
	&.opened {
		display: block;
	}
	
	&.chat-window-side {
		right: 72px;
	}
	
	.btn,
	button {
		background: fade(@text-invert-color, 30%);
		border: 0;
		border-radius: 3px;
		color: @text-invert-color;
		display: inline-block;
		font-size: 14px;
		font-weight: normal;
		height: 22px;
		line-height: 22px;
		margin: 4px 0 0 6px;
		padding: 0 4px;
		vertical-align: top;
		
		&:focus, &:active, &focus:active {
			outline: none;
		}
		
		* {
			float: left;
			font-size: 16px;
			line-height: 22px;
		}
		
		//&.remove-btn,
		&.settings-btn {
			display: none;
		}
		
		/*&.remove-btn {
			.icf-trash {
				line-height: 20px;
			}
			
			span + span {
				font-size: 14px;
				margin: 0 4px 0 2px;
				vertical-align: middle;
			}
		}*/
		
		&.opened {
			background: @text-invert-color;
			color: @text-color;
		}
	}
	
	&.has-settings {
		button.settings-btn {
			display: inline-block;
		}
	}
	
	/*&.has-closable {
		button.remove-btn {
			display: inline-block;
		}
	}*/
}

#x-messages-menu-settings {
	background: @x-messages-bg;
	border: 1px solid @x-messages-border;
	bottom: 32px;
	color: @x-messages-color;
	display: none;
	max-width: 90%;
	padding: 8px 14px;
	position: fixed;
	right: 0;
	z-index: @zindex-x-message - 3;
	
	&.opened {
		display: block;
	}
	
	.close {
		background: none;
		border: 0;
		color: @x-messages-color;
		position: absolute;
		top: 4px;
		right: 2px;
		z-index: 2;
	}
	
	.title {
		opacity: 0.5;
		font-weight: bold;
		font-size: 16px;
	}
	
	.btn {
		display: block;
		margin: 4px 0 0;
		text-align: left;
		width: 100%;
		white-space: normal;
		
		&:first-child {
			margin: 0;
		}
		
		.alert-toggle {
			.checked {
				display: none;
			}
			
			.icon-f {
				float: left;
				line-height: 16px;
				margin-left: 0;
				margin-right: 4px;
				text-align: center;
				width: 16px;
			}
			
			.text {
				display: inline-block;
				float: none;
				line-height: 16px;
				text-align: left;
				width: calc(~'100% - 20px');
			}
		
			&.disabled-feat {
				.checked {
					display: inline;
				}
				
				.unchecked {
					display: none;
				}
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	#x-messages {
		//max-height: calc(~'100vh - 40px - '@mobile-header-height);
		.max-height-100vh(40px + @mobile-header-height);
		max-width: 100%;
		padding-left: 0;
		width: 100%;
		
		&.can-scroll-top {
			&:before {
				box-shadow: 0 2px 6px #000;
			}
		}
		
		&.can-scroll-bottom {
			&:after {
				box-shadow: 0 -2px 6px #000;
			}
		}
		
		#x-messages-scrollable {
			background: none;
			box-shadow: none;
			//max-height: calc(~'100vh - 40px - ' @mobile-header-height);
			.max-height-100vh(40px + @mobile-header-height);
			padding: 0 6px;
		}
		#x-messages-back {
			background: fadeout(@body-bg, 10%);
			height: calc(~"100% + 9px");
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			top: -6px;
			transition: opacity 0.4s;
			width: 100%;
		}
		
		&.opened {
			#x-messages-back {
				opacity: 1;
				
				.x-messages-opened & {
					//height: 100vh;
					.height-100vh();
					position: fixed;
					top: @mobile-header-height;
					
					@media @media-ratio-horizontal {
						top: 0;
					}
				}
				
				.x-messages-opened.video-page & {
					top: 0;
				}
			}
		}
	}
	
	#x-messages {
		&, #x-messages-scrollable {
			.x-messages-opened.video-page & {
				//max-height: calc(~'100vh - 40px');
				.max-height-100vh(40px);
			}
			@media @media-ratio-horizontal {
				//max-height: calc(~'100vh - 40px');
				.max-height-100vh(40px);
			}
		}
	}
	
	html body {
		&.x-messages-opened {
			&,
			&.video-page {
				overflow: hidden;
				position: fixed;
			}
		}
		
		@media @media-ratio-horizontal {
			&.x-messages-opened {
				&,
				&.video-page {
					overflow: hidden;
					position: fixed;
				}
			}
		}
	}
}

@media (max-width: @screen-xxs-max) {
	#x-messages{
		.x-message {
			padding: 5px 30px 5px 8px;
			
			&.x-message-info,
			&.x-message-warning,
			&.x-message-question,
			&.x-message-error,
			&.x-message-success{
				padding-left: 28px;
				.bg-icon{
					font-size: 34px;
					height: 30px;
					line-height: 30px;
					left: -6px;
					text-indent: -2px;
					width: 30px;
				}
			}
			.del,
			.reduce {
				.icon-f {
					font-size: 15px;
					margin: 7px 0;
				}
			}
		}
	}
}
