.inline-block() {
	display: -moz-inline-stack;
	display: inline-block;
	zoom: 1;
	*display: inline;
}

.max-height-100vh(@minus: ~"0px") {
	max-height: calc(~"100vh - @{minus}");
	max-height: calc(~"var(--vh, 1vh) * 100 - @{minus}");
}

.min-height-100vh(@minus: ~"0px") {
	min-height: calc(~"100vh - @{minus}");
	min-height: calc(~"var(--vh, 1vh) * 100 - @{minus}");
}

.height-100vh(@minus: ~"0px") {
	height: calc(~"100vh - @{minus}");
	height: calc(~"var(--vh, 1vh) * 100 - @{minus}");
}

.padding-right-vh(@ratio) {
	padding-right: (@ratio) * 1vh;
	padding-right: calc(~"var(--vh, 1vh) * @{ratio}");
}

.line-clamp(@lines) {
	display: -webkit-box;
	line-clamp: @lines;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: @lines;
}

.bg-rgba(@r, @g, @b, @a) {
	background: rgb(@r, @g, @b);
	background: rgba(@r, @g, @b, @a);
}

.text-border-1px(@txt-col) {
	text-shadow:	 1px	 1px	0		@txt-col,
					-1px	-1px	0		@txt-col,
					 1px	-1px	0		@txt-col,
					-1px	 1px	0		@txt-col;
}

.text-stroke-1px(@txt-col) {
	// Compatibility
	.text-border-1px(@txt-col);

	@supports (-webkit-text-stroke: 1px black) {
		text-shadow: none;
		-webkit-text-stroke: 1px @txt-col;
	}
}

.text-border-2px(@txt-col) {
	text-shadow:	 2px	 0		0		@txt-col,
					-2px	 0		0		@txt-col,
					 0		 2px	0		@txt-col,
					 0		-2px	0		@txt-col,
					 1px	 1px	0		@txt-col,
					-1px	-1px	0		@txt-col,
					 1px	-1px	0		@txt-col,
					-1px	 1px	0		@txt-col;
}

.text-border-2px-with-depth(@txt-col) {
	text-shadow:	 2px	 0		0		@txt-col,
					-2px	 0		0		@txt-col,
					 0		 2px	0		@txt-col,
					 0		-2px	0		@txt-col,
					 1px	 1px	0		@txt-col,
					-1px	-1px	0		@txt-col,
					 1px	-1px	0		@txt-col,
					-1px	 1px	0		@txt-col,
					 0		 0		30px	@txt-col,
					 0		 0		30px	@txt-col;
}

.dot-bg(@bg-color, @dot-color, @dot-size, @dot-space) {
	background:
		linear-gradient(90deg, @bg-color (@dot-space - @dot-size), transparent 1%) center,
		linear-gradient(@bg-color (@dot-space - @dot-size), transparent 1%) center,
		@dot-color;
	background-size: @dot-space @dot-space;
	background-position: center center;
}

.init-ok-btn() {
	opacity: 0.5;
	cursor: default;
	transition: opacity .2s;
	
	&.init-ok {
		opacity: 1;
		cursor: pointer;
		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			cursor: default;
		}
	}
}

.button-variant(@color; @background; @border) {
	color: @color;
	background-color: @background;
	border-color: @border;
	
	
	
	@background-too-dark: boolean(darken(@background, 10%) = @background);
	
	@background-focus:  if(@background-too-dark, lighten(@background, 20%), darken(@background, 10%));
	@background-hover:  @background-focus;
	@background-active: @background-focus;
	@background-active-focus: if(@background-too-dark, lighten(@background, 30%), darken(@background, 17%));
	
	@background-disabled: fade(@background, 80%);
	
	
	
	@border-too-dark: boolean(darken(@border, 25%) = @border);
	
	@border-focus: if(@border-too-dark, lighten(@border, 35%), darken(@border, 25%));
	@border-hover:  if(@border-too-dark, lighten(@border, 25%), darken(@border, 12%));
	@border-active:  @border-hover;
	@border-active-focus: @border-focus;
	
	&:focus,
	&.focus {
		color: @color;
		background-color: @background-focus;
		border-color: @border-focus;
	}
	&:hover {
		color: @color;
		background-color: @background-hover;
		border-color: @border-hover;
	}
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		color: @color;
		background-color: @background-active;
		border-color: @border-active;
		
		&:hover,
		&:focus,
		&.focus {
			color: @color;
			background-color: @background-active-focus;
			border-color: @border-active-focus;
		}
	}
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus,
		&.focus {
			background-color: @background-disabled;
			border-color: @border;
		}
		
		background-color: @background-disabled;
		cursor: default;
	}
	
	.badge {
		color: @background;
		background-color: @color;
	}
}

.line-clamp(@nb-lines) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: @nb-lines;
	overflow: hidden;
	white-space: normal;
}

.no-line-clamp-block() {
	display: block;
	-webkit-line-clamp: 999;
	overflow: initial;
	white-space: initial;
}