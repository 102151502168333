@post-in-space: 16px;
@post-goto-nextprev-w: 50px;

.post-blocks {
	&.mozaique {
		font-size: 0;
		
		.load {
			clear: both;
			display: block;
			padding: 20px;
			text-align: center;
			width: 100%;
			
			.icf-spinner {
				color: @gray-light;
				display: inline-block;
				font-size: 40px;
			}
		}
		
		.no-content {
			font-size: 20px;
			text-align: center;
			margin: 30px 0;
		}
		
		.thumb-block {
			display: inline-block;
			float: none;
			font-size: 12px;
			padding: 0 @post-in-space/3 @post-in-space;
			vertical-align: top;
			
			.p-date {
				margin-top: 20px;
			}
			
			.thumb-inside {
				margin-bottom: 5px;
				
				.thumb {
					a {
						border: 0;
					}
				}
				
				.p-comment {
					bottom: 0;
					cursor: pointer;
					font-size: 16px;
					max-width: 100%;
					left: 0;
					line-height: 24px;
					position: absolute;
					top: 0;
					width: 100%;
					
					.p-comment-content {
						.line-clamp(3);
					}
				}
			}
			&.post-comment {
				.thumb-inside {
					overflow: visible;
				}
			}
			
			.p-tb-comment {
				text-align: left;
				
				& + p {
					text-align: left;
				}
			}
		}
	}
}

.p-comment {
	overflow: visible;
	position: relative;
	text-align: center;
	
	// "-s1-" for "style #1"
	
	&:after {
		border-style: solid;
		border-color: @post-comment-s1-arrow transparent transparent transparent;
		border-width: .4em .6em 0;
		bottom: -.4em;
		content: " ";
		height: 0;
		left: 12.5%;
		margin-left: -.6em;
		position: absolute;
		width: 0;
	}
	
	.p-comment-centered {
		align-content: center;
		align-items: center;
		background: @post-comment-s1-bg-right;
		background-image: linear-gradient(135deg, @post-comment-s1-bg-left, @post-comment-s1-bg-right);
		border-radius: .3em;
		display: flex;
		justify-content: space-around;
		font-size: 16px;
		font-weight: bold;
		padding: 30px @post-in-space;
		position: relative;
		width: 100%;
		height : 100%;
	}
	
	.p-comment-content {
		color: @post-comment-s1-color;
		display: inline-block;
		position: relative;
		z-index: 2;
	}
}

#page .post-blocks.mozaique,
.tabFanClub-post-overlay {
	.p-date {
		color: @gray-light;
		font-size: 12px;
		height: 20px;
		line-height: 20px;
		margin-bottom: 4px;
		text-align: left;
		width: 100%;
		
		& > * {
			display: inline-block;
			vertical-align: top;
		}
		
		.icon-f {
			font-size: 20px;
			padding-right: @post-in-space / 2;
			vertical-align: top;
		}
	}
	
	
	
	.p-pics {
		border-radius: 8px;
		font-size: 0;
		overflow: hidden;
		
		.pic {
			display: inline-block;
			font-size: 12px;
			margin: 0;
			padding: 0;
			position: relative;
			width: 33.33%;
			z-index: 1;
			
			.thumb {
				height: 0;
				display: block;
				overflow: hidden;
				padding-bottom: 85.25%;
				position: relative;
				width: 100%;
			}
			
			.more {
				background-color: fadeout(@body-bg, 40%);
				color: fadeout(@text-color, 40%);
				font-size: 40px;
				height: 500px;
				left: 50%;
				line-height: 500px;
				position: absolute;
				transform: translateX(-50%) translateY(-50%);
				text-align: center;
				top: 50%;
				width: 500px;
				z-index: 1;
				
				& + img {
					opacity: 0.3;
				}
			}
		}
		
		a {
			background-position: center;
			background-size: cover;
			//border: 6px solid;
			//border-color: darken(@gray, 10%) @gray darken(@gray, 25%) darken(@gray, 5%);
			//box-shadow: 0 0 0 1.5px inset @gray-lighter, 0 0 10px 4px inset rgba(0, 0, 0, 0.5);
			display: block;
			left: 50%;
			min-height: 102%;
			min-width: 102%;
			position: absolute;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
		
		.icon-f {
			color: fadeout(#fff, 30%);
			font-size: 55px;
			left: 50%;
			position: absolute;
			text-decoration: none;
			text-shadow: 2px 2px 3px fadeout(#000, 50%);
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			z-index: 2;
		}
		
		&.p-pics-1-cols {
			.pic {
				width: 100%;
				
				.thumb {
					padding-bottom: 56.25%;
				}
			}
		}
		
		&.p-pics-2-cols {
			.pic {
				width: 50%;
				
				.thumb {
					padding-bottom: 115.5%;
				}
			}
		}
		
		&.p-pics-1-rows {
			&.p-pics-3-cols {
				.pic {
					&:first-child {
						float: left;
						width: 66.67%;
						
						.thumb {
							padding-bottom: 85.25%;
						}
					}
				}
			}
		}
		
		&.p-pics-2-rows {
			a {
				.first {
					font-size: 35px;
				}
			}
		}
	}
	
	.thumb-block {
		.thumb-under {
			min-height: 0;
		}
	}
}

body.tabFanClub-post-overlay-opened,
body.purchase-post-overlay-opened {
	overflow: hidden;
}

.tabFanClub-post-overlay,
.tabFanClub-pics-overlay {
	
	&.x-overlay.x-overlay-box {
		.max-height-100vh();
		padding-left: @post-goto-nextprev-w;
		padding-right: @post-goto-nextprev-w;
		position: fixed;
		overflow: auto;
		
		@media @media-ratio-horizontal and (max-width: @screen-sm-max) {
			.max-height-100vh(38px);
		}
	}
}

.tabFanClub-post-overlay {
	
	&.x-overlay.x-overlay-box {
		.x-body {
			background-color: @body-bg;
			border-radius: 4px;
			color: @text-color;
			max-width: 680px;
			margin: 10px auto;
			//overflow: hidden;
			padding: 20px 15px 10px;
			width: 100%;
			z-index: 1;
			
			.x-close {
				font-size: 20px;
				padding: 5px;
			}
			
			.post-content {
			}
			
			&.in-from-left {
				animation-duration: 200ms;
				animation-name: inFromLeft;
			}
			
			&.in-from-right {
				animation-duration: 200ms;
				animation-name: inFromRight;
			}
			
			&.out-to-left {
				transition: transform 200ms;
				transform: translateX(-100vw);
			}
			
			&.out-to-right {
				transition: transform 200ms;
				transform: translateX(100vw);
			}
			
			@keyframes inFromLeft {
				from {
					transform: translateX(-100vw);
				}
				
				to {
					transform: translateX(0);
				}
			}
			
			@keyframes inFromRight {
				from {
					transform: translateX(100vw);
				}
				
				to {
					transform: translateX(0);
				}
			}
		}
	}
	
	.p-date {
		margin-bottom: @post-in-space;
	}
	
	.thumb-block {
		border-bottom: 0;
		float: none;
		margin: @post-in-space 0 0;
		padding: 0;
		width: 100%;
	}
	
	.p-video {
		background: #000;
		height: 0;
		margin: @post-in-space 0;
		padding-bottom: 56.25%;
		position: relative;
		width: 100%;
		
		iframe {
			bottom: 0;
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
		}
	}
	
	.p-pop-comment {
		overflow: hidden;
		
		.p-comment {
			margin: @post-in-space auto @post-in-space;
			max-width: 400px;
			width: calc(~"100% - 20px");
		}
		
		& > p {
			font-size: 16px;
			margin: 0 auto;
			max-width: 400px;
		}
	}
	
	.p-pics {
		margin: 0 -@post-in-space/2 0 0;
		width: calc(~"100% + " @post-in-space / 2);
		
		.pic {
			margin: 0 @post-in-space/2 @post-in-space/2 0;
			width: calc(~"33.33% - " @post-in-space / 2);
		}
		
		&.p-pics-1-cols {
			.pic {
				width: calc(~"100% - " @post-in-space / 2);
			}
		}
		
		&.p-pics-2-cols {
			.pic {
				width: calc(~"50% - " @post-in-space / 2);
			}
		}
		
		&.p-pics-1-rows {
			&.p-pics-3-cols {
				.pic {
					&:first-child {
						width: calc(~"66.67% - " @post-in-space / 2);
					}
				}
			}
		}
	}
	
	.p-get-access-btn-container {
		padding: 30px 0 10px;
		text-align: center;
	}
	
	.p-get-access-btn {
		font-size: 20px;
		white-space: normal;
	}
	
	.goto-prev,
	.goto-next {
		background: none;
		border:	0;
		bottom: 0;
		cursor: pointer;
		display: block;
		padding: 0;
		position: absolute;
		right: 100%;
		top: 0;
		width: @post-goto-nextprev-w;
		
		&:focus, &:active, &:focus:active {
			outline: 0;
		}
		
		.icon-f {
			background-color: fadeout(@body-bg, 50%);
			border-radius: 4px;
			display: inline-block;
			font-size: 30px;
			padding: 10px 0;
		}
		.notouch &:hover {
			.icon-f {
				background-color: @body-bg;
			}
		}
	}
	.goto-next {
		left: 100%;
		right: auto;
	}
}

.tabFanClub-pics-overlay {
	&.x-overlay.x-overlay-box {
		align-items: center;
		display: flex;
	}
}

.tabFanClub-join-overlay {
	&.x-overlay.x-overlay-box {
		.x-body {
			max-width: 680px;
			width: 100%;
		}
	}
	
	img {
		margin-bottom: 20px;
		width: 100%;
	}
	
	.title {
		font-size: 24px;
		margin-bottom: 10px;
	}
	
	.join-btn {
		text-align: center;
		
		.btn {
			font-size: 30px;
			margin: 0;
		}
	}
}

/*
.purchase-content-pop {
	overflow: auto;
	.max-height-100vh();
	
	.x-content {
		background: #000;
		color: #fff;
		max-width: 610px;
		width: 100%;
	}
	
	img {
		max-width: 100%;
	}
	
	section {
		max-width: 610px;
		text-align: center;
		width: 100%;
		
		& + section {
			margin-top: 50px;
		}
		
		&.purchase-ppv {
			.video-thumb,
			.video-title {
				display: block;
				margin: 0 auto;
				max-width: 400px;
				text-align: center;
				width: 100%;
			}
		}
	}
	
	.banner {
		width: 100%;
		
		& + .contained {
			margin: -50px 10px 10px;
		}
	}
	
	.contained {
		float: right;
		width: 150px;
		position: relative;
		
		p {
			background: @red-color;
			border-radius: 3px;
			bottom: 3px;
			color: #fff;
			margin: 0;
			padding: 0 4px;
			position: absolute;
			right: 3px;
		}
	}
	
	.profile-title {
		font-size: 20px;
		text-align: left;
		
		strong {
			font-size: 24px;
		}
	}
	
	.btn-primary {
		clear: both;
		display: block;
		font-size: 22px;
		margin: 14px auto 0;
		max-width: 400px;
		width: 100%;
	}
	//310px thumb
}*/
